import React, { useState } from 'react';
import { Switch } from '@material-ui/core';
import axios from 'axios';

import Grid from '~/components/core/Atomic/Grid/Grid';
import Tooltip from '~/components/core/Atomic/Tooltip';
import SimpleSearchField from '~/components/core/SimpleSearchField';
import { useRoleRestriction } from '~/components/hooks/useRoleRestriction';

import { serverDateToLocal } from '../../DateTimeUtils';
import { CONFIGURATION_FEATURES_DATA } from '../../Types';
import { isUserSuperUser } from '../../UserUtils';
import { isFeatureEnabled, reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import { LoadingSwitch, SortableTable } from '../core';
import { CheckIcon } from '../icons';

import { useSysconfig } from './SystemConfigurationScreen';

import { useStyles } from '../../assets/styles';
import searchFieldStyles from '../core/SimpleSearchField/SimpleSearchField.module.scss';

export const FeaturesTableColumnsBase = [
  {
    id: 'display_name',
    numeric: false,
    label: 'Feature Name',
    specialCell: (feature) => (
      <Tooltip title={feature.name}>
        <span>{feature.display_name}</span>
      </Tooltip>
    ),
  },
  { id: 'category', numeric: false, label: 'Category' },
  { id: 'description', numeric: false, label: 'Description' },
];

export const checkIconSpecialCell = (key) => {
  return function specialCell(feature) {
    return feature[key] ? <CheckIcon /> : null;
  };
};

export const featureActivationData = (organization, featureName) => {
  const activation = organization.features?.find((feature) => feature.name === featureName);
  const data = {
    enabled: !!activation,
    date_activated: '',
    activated_by: '',
  };
  if (activation) {
    data.date_activated = serverDateToLocal(activation.date_activated);
    data.activated_by = activation.activated_by;
  }
  return data;
};

function FeaturesConfigurationTab() {
  const classes = useStyles();
  const { organization, reloadOrganization, isLoading, isError } = useSysconfig();
  const [filteredResults, setFilteredResults] = useState(null);

  const handleSearchChange = (search) =>
    setFilteredResults(
      search
        ? features.filter((feature) =>
            `${feature.display_name} ${feature.name}`.toLowerCase().includes(search.toLowerCase())
          )
        : features
    );

  async function handleFeatureToggle(featureName, value) {
    try {
      await axios.post(`/sysconfig/api/v1/features/toggle/${featureName}/organizations/${organization.id}`, {
        value,
      });
      await reloadOrganization();
    } catch (e) {
      await reportAxiosError(e);
    }
  }

  useRoleRestriction({
    errorMessage: 'tried accessing FeaturesConfigurationTab',
    roleValidators: [isUserSuperUser],
  });

  const features = Object.values(CONFIGURATION_FEATURES_DATA).sort((a, b) =>
    a.display_name.localeCompare(b.display_name)
  );

  const columnData = [
    ...FeaturesTableColumnsBase,
    { id: 'date_activated', numeric: false, label: 'Date Activated' },
    { id: 'activated_by', numeric: false, label: 'Activated By' },
    {
      id: 'editable_by_support',
      numeric: false,
      label: 'Editable By Support',
      specialCell: checkIconSpecialCell('editable_by_support'),
    },
    {
      id: 'enabled',
      numeric: false,
      label: '',
      specialCell: (feature) => (
        <Switch
          key={`${feature.name}-switch`}
          checked={isFeatureEnabled(organization, feature.name)}
          onChange={() => handleFeatureToggle(feature.name, !isFeatureEnabled(organization, feature.name))}
          className={classes.formsSwitch}
          size="small"
        />
      ),
    },
  ];

  const defaultOrderColumn = columnData.findIndex((column) => column.id === 'display_name');

  return (
    <CardDialog noCardTitle>
      <LoadingSwitch isError={isError} isLoading={isLoading}>
        <Grid container spacing={4} direction="column">
          <Grid container direction="row" xs={12} md={6} lg={3} style={{ margin: '48px 0 0 0' }}>
            <Grid item xs={10}>
              <div className={searchFieldStyles.searchInputContainer}>
                <SimpleSearchField placeholder="Search" onChange={handleSearchChange} defaultValue="" />
              </div>
            </Grid>
          </Grid>
          <Grid item>
            <SortableTable
              columns={columnData}
              rows={(filteredResults ?? features).map((row) => ({
                ...row,
                ...featureActivationData(organization, row.name),
                id: row.name,
              }))}
              stickyHeader
              defaultOrderColumn={defaultOrderColumn}
            />
          </Grid>
        </Grid>
      </LoadingSwitch>
    </CardDialog>
  );
}

export default FeaturesConfigurationTab;
