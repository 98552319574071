import { arrayOf, bool, number, oneOf, oneOfType, shape, string } from 'prop-types';

import { COUNTRIES_DICT, GLOBAL_ADDRESSES_OPTIONS, LOCATION_TYPES_DICT } from '../../../../../Types';

const countriesConfigurationForLocation = {
  id: oneOfType([string, number]),
  lob: string,
  location_type: oneOf(Object.keys(LOCATION_TYPES_DICT)).isRequired,
  configuration_select: oneOf(Object.keys(GLOBAL_ADDRESSES_OPTIONS)).isRequired,
  selected_countries: arrayOf(oneOf(Object.keys(COUNTRIES_DICT))),
};

const countriesConfiguration = {
  id: number,
  is_all_lobs: bool,
  countries_configuration_for_locations: arrayOf(shape(countriesConfigurationForLocation)),
};

export default {
  countriesConfigurationForLocation,
  countriesConfiguration,
};
