import React, { useState } from 'react';
import { Divider } from '@material-ui/core';
import _ from 'lodash';

import Button from '~/components/core/Atomic/Buttons/Button';
import { AddIcon } from '~/components/deprecatedMuiIcons';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import cn from '~/Utils/cn';

import { getGaClaimTypes } from '../../../../Utils';
import LobWizard from '../../../Admin/LobWizard';
import CardDialog from '../../../CardDialog';
import { LoadingSwitch } from '../../../core';
import useDataFetcher from '../../../useDataFetcher';
import { useSysconfig } from '../../SystemConfigurationScreen';

import LobsPlainList from './LobsPlainList';

import { useStyles } from '../../../../assets/styles';
import styles from './OrganizationLobs.module.scss';

const OrganizationLobs = () => {
  const { organization } = useSysconfig();
  const { lobConfigurationsDict = {} } = useLobConfiguration();
  const { isLoading, isError, data } = useDataFetcher(
    `/api/v1/organizations/${organization.id}/available_lobs_with_hidden_indication`
  );
  const classes = useStyles();
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const isProdOrg = organization?.organization_type === 'operational';
  const envLobConfigurations = isProdOrg
    ? _.pickBy(lobConfigurationsDict, (lob) => lob?.env_type === 'production')
    : lobConfigurationsDict;
  const gaLobs = [...getGaClaimTypes(), ...Object.keys(envLobConfigurations)];
  const lobOptionGaLobsIntersection = _.intersection(
    gaLobs,
    data?.map((lob) => lob.key)
  );

  const handleOpenWizard = () => {
    setIsWizardOpen(true);
  };

  const handleCloseWizard = () => {
    setIsWizardOpen(false);
  };

  return (
    <LoadingSwitch isLoading={isLoading} isError={isError}>
      {isWizardOpen ? (
        <LobWizard onClose={handleCloseWizard} />
      ) : (
        <div className={cn(classes.centeredFullScreenCardWrapper, styles.container)}>
          <CardDialog title="Organization Lines of Business">
            <div className={styles.lobsContainer}>
              <LobsPlainList organizationId={organization.id} />
            </div>
            {gaLobs.length > lobOptionGaLobsIntersection.length && (
              <>
                <Divider className={styles.divider} />
                <div className={styles.buttonContainer}>
                  <Button
                    color="primary"
                    startIcon={<AddIcon className={classes.leftButtonIcon} />}
                    onClick={handleOpenWizard}
                  >
                    Add line of business
                  </Button>
                </div>
              </>
            )}
          </CardDialog>
        </div>
      )}
    </LoadingSwitch>
  );
};

export default OrganizationLobs;
