import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';

import Grid from '~/components/core/Atomic/Grid/Grid';

import TextFieldFormik from '../../TextFieldFormik';
import { useSysconfig } from '../SystemConfigurationScreen';

import OrganizationEntityConfiguration from './OrganizationEntityConfiguration';

import styles, { useStyles } from '../../../assets/styles';

const OrganizationEmails = () => {
  const classes = useStyles();
  const { organization } = useSysconfig();
  const url = `/sysconfig/api/v1/organizations/${organization.id}/organization_emails`;

  const getExtractedData = (values) => ({ flow_key: values.flow_key, email: values.email });

  const columnData = [
    { id: 'flow_key', numeric: false, label: 'Flow Key' },
    { id: 'email', numeric: false, label: 'Email' },
  ];

  const getEntityInitialValues = (_) => ({
    flow_key: '',
    email: '',
  });

  const getValidationSchema = (_) => ({
    flow_key: Yup.string().required('Required'),
    email: Yup.string().required('Required').email(),
  });

  const getEditInputs = (_) => (
    <>
      <Grid item xs={12}>
        <TextFieldFormik id="flow_key" label="Flow Key" className={classes.textField} fullWidth />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormik id="email" label="Email" fullWidth className={classes.textField} />
      </Grid>
    </>
  );

  return (
    <OrganizationEntityConfiguration
      columnData={columnData}
      generateFetchUrl={() => url}
      editable={false}
      entityLabelSingular="Organization Email"
      entityLabelPlural="Organization Emails"
      getEditInputs={getEditInputs}
      getEntityInitialValues={getEntityInitialValues}
      getExtractedData={getExtractedData}
      getValidationSchema={getValidationSchema}
    />
  );
};

export default withStyles(styles)(OrganizationEmails);
