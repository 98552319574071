import type { ChangeEvent } from 'react';
import React, { useEffect, useRef } from 'react';
import { getIn, useFormikContext } from 'formik';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';

import Typography from '~/components/core/Atomic/Typography';
import CheckboxWithButtonWrapperFormik from '~/components/core/Formik/CheckboxWithButtonWrapperFormik';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import cn from '~/Utils/cn';
import executeOrDelegate from '~/Utils/executeOrDelegate';

import { getLobDescription, getLobIcon } from '../../../Utils/lobUtils';
import { ErrorHelperTextFormik } from '../../core/Formik/ErrorHelperTextFormik';
import useOrganization from '../../OrganizationContext';

import useLobOptions from './hooks/useLobOptions';

import styles from './lob.module.scss';

const BACKGROUND_COLOR = 'white';
const ALL_ITEM_VALUE = '__all__';
const DIRECTION = {
  ROW: 'row',
  COLUMN: 'column',
} as const;

type Direction = (typeof DIRECTION)[keyof typeof DIRECTION];

type Lobs = string[];

interface LobCheckboxMultiselectFormikProps {
  subOrganizationIds?: number[];
  lobsFieldId: string;
  allSelectedFieldId: string;
  label?: string;
  shouldIncludeLabel?: boolean;
  selectAllLabel?: string;
  onlyNewValues?: boolean;
  disableAllOption?: boolean;
  disabled?: boolean;
  disableOnEmptySubOrganizations?: boolean;
  onChange?: ({ lobs, selectAll }: { lobs: Lobs; selectAll: boolean }) => void;
  hiddenLobsToHideWhenNotSelected?: Lobs;
  showOrgLevelLobs?: boolean;
  direction?: Direction;
  beforeComponent?: ({ lob }: { lob: string }) => React.ReactNode;
  afterComponent?: ({ lob }: { lob: string }) => React.ReactNode;
  isAllLobs?: boolean;
  filterLobsFunc?: () => boolean;
  className?: string;
  handleChangeWrapper?: (changeFunc: () => void) => void; // accepts a change func that can be called or not after performing other logic
}

export const LobCheckboxMultiselectFormik: React.FC<LobCheckboxMultiselectFormikProps> = ({
  lobsFieldId,
  allSelectedFieldId, // TODO: handle implicit all?
  subOrganizationIds = [],
  disableAllOption = false,
  disableOnEmptySubOrganizations = false,
  disabled = false,
  onlyNewValues = false,
  onChange = noop,
  label = 'Lines of Business',
  shouldIncludeLabel,
  selectAllLabel = 'All',
  direction = DIRECTION.COLUMN,
  beforeComponent = null,
  afterComponent = null,
  hiddenLobsToHideWhenNotSelected = [], // will filter out these from list unless they are already chosen
  showOrgLevelLobs = false,
  filterLobsFunc,
  isAllLobs,
  className,
  handleChangeWrapper,
}) => {
  const { setFieldValue, values } = useFormikContext();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { subOrganizationEnabled, orgLevelSupportedLobs } = useOrganization();
  const { lobConfigurationsDict } = useLobConfiguration();
  const { lobOptions: lobOptionsForNonSubOrgsCreation, isLoading } = useLobOptions({
    subOrganizationIds,
    filterLobsFunc,
    isAllLobs,
  });
  const initialSelected = useRef(getIn(values, lobsFieldId, []));
  const initialAllSelected = useRef(getIn(values, allSelectedFieldId, false));

  const lobOptions: Lobs = showOrgLevelLobs ? orgLevelSupportedLobs : lobOptionsForNonSubOrgsCreation;
  const isRow = direction === DIRECTION.ROW;

  useEffect(() => {
    const currentValues = getIn(values, lobsFieldId);
    const updatedValues = intersection(currentValues, lobOptions);
    if (!isEqual(currentValues, updatedValues) && !isLoading) {
      executeOrDelegate({
        primaryTask: () => {
          setFieldValue(lobsFieldId, intersection(getIn(values, lobsFieldId), lobOptions));
        },
        wrapperFunction: handleChangeWrapper,
      });
    }
  }, [lobOptions, setFieldValue, values, lobsFieldId, isLoading, handleChangeWrapper]);

  const callOnChange = (lobs: Lobs, selectAll: boolean) => {
    if (onChange) {
      onChange({ lobs, selectAll });
    }
  };

  const setAllSelectedFieldValue = (value: boolean) => {
    if (allSelectedFieldId) {
      setFieldValue(allSelectedFieldId, value);
    }
  };

  const handleSelectionChange = () => {
    setAllSelectedFieldValue(false);
    callOnChange(getIn(values, lobsFieldId), false);
  };

  const handleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const newSelectAllValue = !getIn(values, allSelectedFieldId);
    const newLobsValue = newSelectAllValue
      ? []
      : onlyNewValues
      ? [...new Set([...initialSelected.current, ...getIn(values, lobsFieldId)])]
      : getIn(values, lobsFieldId);
    setFieldValue(lobsFieldId, newLobsValue);
    callOnChange(newLobsValue, newSelectAllValue);
  };

  const isDisabled =
    disabled || isLoading || (disableOnEmptySubOrganizations && subOrganizationEnabled && isEmpty(subOrganizationIds));

  return (
    <span className={`${styles.checkboxMultiselect} ${className}`}>
      {shouldIncludeLabel ? (
        <Typography variant="body2" style={{ marginBottom: '12px' }}>
          {label}
        </Typography>
      ) : null}
      <div className={cn({ [styles.rowContainer]: isRow })}>
        {!disableAllOption && (
          <span className={styles.checkboxContainer}>
            {beforeComponent && beforeComponent({ lob: ALL_ITEM_VALUE })}
            <CheckboxWithButtonWrapperFormik
              id={allSelectedFieldId}
              value={ALL_ITEM_VALUE}
              className={cn({ [styles.rowCheckbox]: isRow })}
              backgroundColor={BACKGROUND_COLOR}
              text={selectAllLabel}
              disabled={isDisabled || (initialAllSelected.current && onlyNewValues)}
              onChecked={handleSelectAll}
              handleChangeWrapper={handleChangeWrapper}
            />
            {afterComponent && afterComponent({ lob: ALL_ITEM_VALUE })}
          </span>
        )}
        {lobOptions
          .filter((lob) => !hiddenLobsToHideWhenNotSelected.includes(lob) || initialSelected.current.includes(lob))
          .map((lob) => {
            const disableNewLob =
              onlyNewValues &&
              (initialSelected.current.includes(lob) || (!disableAllOption && initialAllSelected.current));
            const disableWhileAll = onlyNewValues && !disableAllOption && getIn(values, allSelectedFieldId);

            return (
              <span key={lob} className={isRow ? styles.rowCheckboxContainer : styles.notRowCheckboxContainer}>
                {beforeComponent && beforeComponent({ lob })}
                <CheckboxWithButtonWrapperFormik
                  className={cn({ [styles.rowCheckbox]: isRow })}
                  id={lobsFieldId}
                  value={lob}
                  backgroundColor={BACKGROUND_COLOR}
                  icon={getLobIcon({ lob, lobConfigurationsDict })}
                  text={getLobDescription(lob, lobConfigurationsDict)}
                  disabled={isDisabled || disableNewLob || disableWhileAll}
                  onChecked={handleSelectionChange}
                  handleChangeWrapper={handleChangeWrapper}
                />
                {afterComponent && afterComponent({ lob })}
              </span>
            );
          })}
      </div>
      {allSelectedFieldId && <ErrorHelperTextFormik id={allSelectedFieldId} />}
      <ErrorHelperTextFormik id={lobsFieldId} />
    </span>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
LobCheckboxMultiselectFormik.DIRECTION = DIRECTION;
