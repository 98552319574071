import React from 'react';

import CardDialog from '~/components/CardDialog';
import type { InnerTabSingleProps } from '~/components/core/Layout/InnerTabs/InnerTabs';
import InnerTabs from '~/components/core/Layout/InnerTabs/InnerTabs';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import useOrganization from '~/components/OrganizationContext';
import type { StatReserveConfigurationTableProps } from '~/components/SystemConfiguration/ClaimConfiguration/StatReserveConfiguration/StatReserveConfigurationTable';
import StatReserveConfigurationTable from '~/components/SystemConfiguration/ClaimConfiguration/StatReserveConfiguration/StatReserveConfigurationTable';
import OperationsBreadcrumbs from '~/components/SystemConfiguration/OperationsBreadcrumbs';
import useDataFetcher from '~/components/useDataFetcher';
import { getLobDescription } from '~/Utils/lobUtils';

// based on model StatReserveConfigurationRow in server/stat_reserve/pydantic_models/stat_reserve_configuration_pydantic_models.py
export interface StatReserveConfiguration {
  id: number;
  coverage_config_id: number;
  coverage_key: string;
  coverage_display_name: string;
  // todo - add typings https://fivesigma.atlassian.net/browse/NGTPA-16850
  coverage_indemnity_sub_reserves_config: Record<string, unknown>;
  coverage_is_indemnity_sub_reserves_config_enabled: boolean;
  indemnity_reserve_amount: number;
  expenses_reserve_amount: number;
  // todo - add typings https://fivesigma.atlassian.net/browse/NGTPA-16850
  indemnity_sub_reserves_amounts: Record<string, unknown>;
  expenses_sub_reserves_amounts: Record<string, unknown>;
  lob: string;
}

// based on model StatReserveConfigurationResponse in server/stat_reserve/pydantic_models/stat_reserve_configuration_pydantic_models.py
export interface StatReserveConfigurationResponse {
  stat_reserve_configurations: StatReserveConfiguration[];
}

const StatReserveConfigurationPage = (): React.JSX.Element => {
  const { lobConfigurationsDict = {} } = useLobConfiguration();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organizationId, supportedClaimTypes } = useOrganization();
  const {
    isLoading,
    isError,
    data: statReserveConfigurationsResponse,
  }: { isLoading: boolean; isError: boolean; data: StatReserveConfigurationResponse | undefined } = useDataFetcher(
    `/api/v1/stat_reserve_configuration/organizations/${organizationId}/`,
    {}
  );

  const tabs: InnerTabSingleProps<StatReserveConfigurationTableProps>[] = supportedClaimTypes.map((lob: string) => {
    return {
      label: getLobDescription(lob, lobConfigurationsDict),
      url: lob,
      component: StatReserveConfigurationTable,
      props: {
        statReserveConfigurations: statReserveConfigurationsResponse?.stat_reserve_configurations
          ? statReserveConfigurationsResponse.stat_reserve_configurations.filter(
              (statReserveConfiguration: StatReserveConfiguration) => statReserveConfiguration.lob === lob
            )
          : [],
        isLoading,
        isError,
      },
    };
  });

  return (
    <div className="m-20">
      <OperationsBreadcrumbs currentTab="Reserve Configuration" />
      <CardDialog
        title="Reserve Configuration"
        subheader="Configure custom rules to automatically set initial reserves when opening a new exposure."
      >
        <InnerTabs tabs={tabs} />
      </CardDialog>
    </div>
  );
};

export default StatReserveConfigurationPage;
