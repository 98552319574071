import React from 'react';

import { ADMIN_ROUTES } from '../../../routesConstants';
import { CONFIGURATION_FEATURES_NAMES } from '../../../Types';
import { isFeatureEnabled } from '../../../Utils';
import ConfigurationScreenWithTabs from '../ConfigurationScreenWithTabs';
import { useSysconfig } from '../SystemConfigurationScreen';

import ClosingReasonConfigScreen from './ClosingReasonConfig/ClosingReasonConfigScreen';

const CLAIM_CONFIG_CONFIGURATION_SUBTITLE = 'Set up claim and exposure closing reasons.';

const ClaimConfigurationPage = () => {
  const { organization } = useSysconfig();
  const tabs = [
    {
      label: 'Closing Reasons',
      url: ADMIN_ROUTES.CLAIM_CONFIG.CLOSING_REASON,
      component: ClosingReasonConfigScreen,
      isEnabled: isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.NEW_CLOSING_REASON),
    },
  ];

  return (
    <ConfigurationScreenWithTabs
      tabs={tabs}
      title="Claim Configuration"
      subtitle={CLAIM_CONFIG_CONFIGURATION_SUBTITLE}
      operationTabName="Claim Configuration"
    />
  );
};

export default ClaimConfigurationPage;
export { CLAIM_CONFIG_CONFIGURATION_SUBTITLE };
