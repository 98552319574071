import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { noop } from 'lodash';

import cn from '~/Utils/cn';

import RadioButtonFormik from '../../../RadioButtonFormik';
import InnerCard from '../../Cards/InnerCard';

import { useStyles } from '../../../../assets/styles';
import styles from './radioButtonSection.module.scss';

const RadioButtonSectionFormik = ({ id, className, Component, value, onClick, disabled }) => {
  const classes = useStyles();
  const { setFieldValue } = useFormikContext();

  return (
    <InnerCard className={cn(styles.radioButton, styles.radioButtonSection, className)}>
      <span
        className={cn(classes.radioOrCheckboxWithIcon, styles.radioButtonSectionContainer, { disabled })}
        onClick={() => !disabled && setFieldValue(id, value)}
      >
        <RadioButtonFormik
          id={id}
          label={Component}
          labelClassName={styles.label}
          optionValue={value}
          disabled={disabled}
          size="small"
          onChange={noop}
          onClick={onClick}
        />
      </span>
    </InnerCard>
  );
};

RadioButtonSectionFormik.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  Component: PropTypes.node.isRequired,
  value: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default RadioButtonSectionFormik;
