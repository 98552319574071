const DUPLICATE_PAYMENTS_VERIFICATION_ACTION = {
  require_supervisor_approval: {
    desc: 'Require supervisor approval',
  },
  adjuster_confirmation: {
    desc: 'Adjuster confirmation during payment',
  },
} as const;

export default DUPLICATE_PAYMENTS_VERIFICATION_ACTION;
