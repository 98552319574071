import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { noop } from 'lodash';

import Button from '~/components/core/Atomic/Buttons/Button';
import cn from '~/Utils/cn';

import CardDialog from '../../../../../CardDialog';
import { Text } from '../../../../../core';
import { UsersSelectField } from '../../ProfileDialogs/UsersSelect/UsersSelectStep';
import { ProfilesTable } from '../ProfilesTable';

import dialogStyles from '../../ProfileDialogs/styles.module.scss';
import styles from './cells.module.scss';

export const NumberOfUsersCell = ({ row }) => {
  const numberOfUsers = React.useMemo(() => row[ProfilesTable.COLUMNS.number_of_users], [row]);
  const [isViewUsersDialogOpen, setIsViewUsersDialogOpen] = React.useState(false);
  const hasUsers = numberOfUsers > 0;

  return (
    <>
      <div
        className={cn(styles.UsersCellContainer, {
          [styles.hasUsers]: hasUsers,
        })}
      >
        <span onClick={hasUsers ? () => setIsViewUsersDialogOpen(true) : null}>
          <Text
            variant={Text.VARIANTS.SM}
            colorVariant={hasUsers ? Text.COLOR_VARIANTS.BUTTON_LINK : Text.COLOR_VARIANTS.DISABLED}
          >
            {hasUsers ? numberOfUsers : 'None'}
          </Text>
        </span>
      </div>
      {isViewUsersDialogOpen && (
        <ViewProfileUsersDialog onClose={() => setIsViewUsersDialogOpen(false)} profileId={row.id} />
      )}
    </>
  );
};

NumberOfUsersCell.propTypes = {
  row: PropTypes.object,
};

export const ViewProfileUsersDialog = ({ onClose, profileId }) => {
  const filterPredicate = (user) => user.profile?.id === profileId;

  return (
    <CardDialog
      isDialog
      open
      title="View Users"
      onClose={onClose}
      maxWidth="md"
      containerClassName={dialogStyles.dialogContentContainer}
      footerActions={
        <div className={dialogStyles.actionsContainer}>
          <Button variant="contained" color="primary" onClick={onClose}>
            Close
          </Button>
        </div>
      }
    >
      <div className={styles.viewUsersDialogContent}>
        {/* Formik is used to please the SearchWithOptionsFormik, needed to keep the same appearance as users select */}
        <Formik initialValues={{}} onSubmit={noop}>
          <UsersSelectField filterPredicate={filterPredicate} showOnly />
        </Formik>
      </div>
    </CardDialog>
  );
};

ViewProfileUsersDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  profileId: PropTypes.string.isRequired,
};
