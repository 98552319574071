import { merge, sortBy, uniqBy } from 'lodash';

import useOrganization from '../../../../../../OrganizationContext';
import useDataFetcher from '../../../../../../useDataFetcher';
import { useSysconfig } from '../../../../../SystemConfigurationScreen';

import { useConfiguration } from './useConfiguration';

const OTHER = 'other';

const useCauseLossCodes = () => {
  const { sub_organization_id: configSubOrgId } = useConfiguration();
  const { organizationId, incidentTypesDict, subOrganizationEnabled, subOrganizations } = useOrganization();

  const { organization } = useSysconfig();

  const {
    isLoading: isCoveragesLoading,
    isError: isCoveragesError,
    data: coveragesConfig,
  } = useDataFetcher(`/sysconfig/api/v1/organizations/${organizationId}/coverages`);

  const {
    isLoading: isCauseCodesLoading,
    isError: isCauseCodesError,
    data: causeOfLossData,
  } = useDataFetcher(`/api/v1/organizations/${organizationId}/cause_of_loss_codes`, {
    params: {
      sub_organization_id: configSubOrgId,
    },
  });

  const subTypesDict = {};
  const isConfiguredForOrgLevel = !subOrganizationEnabled || !configSubOrgId;

  const incidentSubTypesDict = getIncidentTypesDict({
    subOrganizations,
    subOrganizationId: configSubOrgId,
    organizationIncidentTypesDict: incidentTypesDict,
  });

  const supportedLobsAndLobIsoCodes = isConfiguredForOrgLevel
    ? organization?.supported_lobs
    : subOrganizations.find(({ id }) => id === configSubOrgId).supported_lobs;

  const supportedLobs = supportedLobsAndLobIsoCodes.map(({ lob }) => lob);
  const filteredCoverages =
    coveragesConfig
      ?.filter(({ lob }) => supportedLobs.includes(lob))
      .filter(
        ({ sub_organizations, is_org_level }) =>
          is_org_level || isConfiguredForOrgLevel || !!sub_organizations.find(({ id }) => id === configSubOrgId)
      ) || [];

  supportedLobs.forEach((lob) => {
    filteredCoverages.forEach(({ coverage_key }) => {
      if (!subTypesDict[lob]) {
        subTypesDict[lob] = {};
      }
      subTypesDict[lob][coverage_key] = getSupTypes(incidentSubTypesDict[lob]);
    });
  });

  return {
    isLoading: isCoveragesLoading || isCauseCodesLoading,
    isError: isCoveragesError || isCauseCodesError,
    supportedLobs,
    supportedLobsAndLobIsoCodes,
    coverages: filteredCoverages,
    subTypesDict,
    causeOfLossData: causeOfLossData || {},
  };
};

const getSupTypes = (incidentTypes) => {
  return sortBy(
    uniqBy(merge([].concat(...Object.values(incidentTypes).map(({ sub_types }) => sub_types))), 'id'),
    ({ id }) => (id === OTHER ? 1 : 0)
  );
};

const getIncidentTypesDict = ({ subOrganizationId, subOrganizations, organizationIncidentTypesDict }) => {
  if (!subOrganizationId) {
    return organizationIncidentTypesDict;
  }

  return getIncidentTypes(subOrganizations.find(({ id }) => id === subOrganizationId));
};

const getIncidentTypes = (subOrganization) => {
  const res = {};
  const lobIncidentConfigurationsDict = subOrganization.lob_incident_configurations_dict;

  Object.keys(lobIncidentConfigurationsDict).forEach((key) => {
    const types = {};
    const incidentTypes = lobIncidentConfigurationsDict[key]?.incident_details?.types || [];
    incidentTypes.forEach((type) => {
      types[type.id] = type;
    });
    res[key] = types;
  });
  return res;
};

export { useCauseLossCodes };
