import React from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';

import RadioButtonGroupFormik from '~/components/core/Formik/RadioButtonGroupFormik';
import SwitchFormik from '~/components/core/Formik/SwitchFormik';

import { CLAIM_SEARCH } from '../../../../../../../Types';

import { useStyles } from '../../../../../../../assets/styles';
import styles from '../claimSearchConfigurationDialog.module.scss';

const OPTIONS = {
  INSURED_ONLY: 'insured_only',
  ALL: 'all',
};

const TriggerSelection = ({ id, text }) => {
  const { setFieldValue, values } = useFormikContext();
  const classes = useStyles();

  const disabled = !getIn(values, id);

  return (
    <div className={styles.triggerContainer}>
      <SwitchFormik
        className={classes.formsSwitch}
        id={id}
        label={text}
        onChange={(event, switched) => {
          switched ? setFieldValue(id, OPTIONS.INSURED_ONLY) : setFieldValue(id, null);
        }}
        withShowOnlyText
        hideErrorGap
      />
      <RadioButtonGroupFormik
        id={id}
        className={styles.radioButtonGroup}
        btnClassName={styles.radioButtonOption}
        options={Object.keys(CLAIM_SEARCH.SUPPORTED_SCOPE).map((key) => ({
          optionValue: key,
          text: CLAIM_SEARCH.SUPPORTED_SCOPE[key].desc,
          disabled,
        }))}
      />
    </div>
  );
};

TriggerSelection.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default TriggerSelection;
