import React from 'react';
import { bool, func, instanceOf, string } from 'prop-types';
import { Switch } from '@material-ui/core';
import { noop } from 'lodash';

import cn from '~/Utils/cn';

import { serverDateToLocalMoment } from '../../../../../DateTimeUtils';
import WithConfirm from '../../../../ConfirmModal';

import CardDetails from './CardDetails';

import { useStyles } from '../../../../../assets/styles';
import styles from './card.module.scss';

const Card = ({
  is_active: isActive,
  onActiveChange = noop,
  name,
  disabled = false,
  created_at: createdAt,
  created_by: createdBy,
}) => {
  const classes = useStyles();

  return (
    <div className={styles.container}>
      <header className={styles.cardHeader}>
        <h4 className={cn(styles.cardTitle, { [styles.activeTitle]: isActive })}>{name}</h4>
        <WithConfirm
          title={isActive ? deactivationText.title(name) : activationText.title(name)}
          contentText={isActive ? deactivationText.content : activationText.content}
          primaryButtonName="Ok"
          shouldCloseOnPrimary
        >
          <Switch onClick={onActiveChange} checked={isActive} disabled={disabled} className={classes.formsSwitch} />
        </WithConfirm>
      </header>
      <footer className={styles.cardFooter}>
        <CardDetails title="Created date" value={serverDateToLocalMoment(createdAt)} />
        <CardDetails title="Created by" value={createdBy} />
      </footer>
    </div>
  );
};

const activationText = {
  title: (title) => `Are you sure you want to activate ${title}?`,
  content: 'Activating a new form won’t change forms that were already filled out',
};

const deactivationText = {
  title: (title) => `Are you sure you want to deactivate ${title}?`,
  content: 'Deactivating a form won’t change forms that were already filled out',
};

Card.propTypes = {
  id: string.isRequired,
  onActiveChange: func,
  name: string.isRequired,
  is_active: bool.isRequired,
  disabled: bool,
  created_at: instanceOf(Date).isRequired,
  created_by: string.isRequired,
};

export default Card;
