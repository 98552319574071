import React from 'react';
import { useFormikContext } from 'formik';
import { get } from 'lodash';

import FieldConfigurationCard from '../../FieldConfigurationCard/FieldConfigurationCard';
import ConfiguredFieldsSection from '../ConfiguredFieldsSection';

import SubSectionWrapper from './SubSectionWrapper';

const ID_PREFIX = 'configuration.involved_parties';

const ENTITIES_TYPES = {
  PERSON: 'person',
  PROPERTY: 'property',
};

const FIELDS_BY_ENTITY_TYPE = {
  [ENTITIES_TYPES.PERSON]: [
    'contact_id',
    'relationship_to_policyholder',
    'attorney_contact_id',
    'attorney_reference_number',
    'representative_contact_id',
  ],
  [ENTITIES_TYPES.PROPERTY]: [
    'property_description',
    'owner_contact_id',
    'attorney_contact_id',
    'attorney_reference_number',
  ],
};

const GeneralDetailsSubSection = ({ partyKey }: { partyKey: string }): JSX.Element => {
  const { values } = useFormikContext();

  const id = `${ID_PREFIX}.${partyKey}`;
  const entityType = get(values, `${id}.type`);

  return (
    <SubSectionWrapper title="General Details">
      {FIELDS_BY_ENTITY_TYPE[entityType].map((fieldId) => (
        <FieldConfigurationCard
          key={fieldId}
          idPrefix={id}
          fieldId={fieldId}
          disabledCollapse={false}
          backgroundColor={FieldConfigurationCard.BACKGROUND_COLOR.WHITE}
          disableCategories
        />
      ))}
      <ConfiguredFieldsSection fieldId={`${id}.configured_fields`} disabled={false} disableCategories />
    </SubSectionWrapper>
  );
};

export default GeneralDetailsSubSection;
