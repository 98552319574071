import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import AlertBanner from '~/components/core/AlertBanner';
import Grid from '~/components/core/Atomic/Grid/Grid';
import InnerCard from '~/components/core/Cards/InnerCard';
import SwitchFormik from '~/components/core/Formik/SwitchFormik';

import { CONFIGURATION_FEATURES_NAMES } from '../../../../../Types';
import { isFeatureEnabled } from '../../../../../Utils';
import { ArraySelectFieldFormik } from '../../../../core/Formik/NestedFieldFormik/ArraySelectFieldFormik';
import { AddIcon } from '../../../../icons';

import ConfirmDialogContent from './ConfirmDialogContent';

import { useStyles } from '../../../../../assets/styles';
import colors from '../../../../../assets/colors.module.scss';
import styles from './UserPermissionsDialogs.module.scss';

const ALERTS = {
  INITIAL_USER:
    'An initial user can’t be assigned to sub-organizations. If needed, you can do it later from the system configuration',
  DEFAULT: 'Users can only perform actions in sub-organizations they are assigned to',
};

export const SubOrgsSelect = ({ roles, organization, isWizardUser }) => {
  const classes = useStyles();
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const subOrgFeatureEnabled =
    organization && isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.ASSIGN_USER_TO_SUB_ORG);

  const onIsPartOfSubOrganizationChanged = (e) => {
    let isPartOfSubOrganization = !e.target.checked;

    setFieldValue('is_part_of_sub_organization', isPartOfSubOrganization);

    if (isPartOfSubOrganization) {
      const role = roles.find((role) => role.id === values.role_id);
      if (role?.role_type !== 'read_only' && !subOrgFeatureEnabled) {
        setFieldValue('role_id', '');
      }
    } else {
      setFieldValue('sub_organization_ids', []);
      setFieldTouched('sub_organization_ids', false);
    }
  };

  const subOrgIdToName = organization.sub_organizations
    ? organization.sub_organizations.reduce(
        (acc, subOrg) => ({
          ...acc,
          [subOrg.id]: subOrg.name,
        }),
        {}
      )
    : {};

  const isAllSubOrgs = !values.is_part_of_sub_organization;

  const isSystemConfigEditor = values.is_sys_config_editor;

  const subOrgSelectionOptions = Object.entries(subOrgIdToName).map(([subOrgId, subOrgName]) => ({
    value: parseInt(subOrgId),
    label: subOrgName,
  }));

  return (
    <>
      <div className={styles.innerCardContainer}>
        <InnerCard
          title="Assign to sub-organizations"
          className={styles.innerCard}
          titleClassName={styles.innerCardTitle}
        >
          <Grid container spacing={2} direction="column">
            <Grid item xs={12} spacing={2}>
              <AlertBanner
                note={isWizardUser ? ALERTS.INITIAL_USER : ALERTS.DEFAULT}
                alertType={AlertBanner.ALERT_TYPES.INFO}
                withIcon
              />
            </Grid>
            <Grid item xs={12} className={styles.switchContainer}>
              <SwitchFormik
                className={classes.formsSwitch}
                id="is_part_of_sub_organization"
                label="All Sub-organizations"
                onChange={onIsPartOfSubOrganizationChanged}
                disabled={isSystemConfigEditor || isWizardUser}
                reverse
              />
            </Grid>
            <Grid item xs={12}>
              {!isAllSubOrgs && !isSystemConfigEditor && (
                <ArraySelectFieldFormik
                  selectionOptions={subOrgSelectionOptions}
                  fieldId="sub_organization_ids"
                  label=""
                  addNewItemButtonLabel={
                    <>
                      <AddIcon className={classes.leftButtonIcon} iconColor={colors.buttonLink} />
                      ADD SUB-ORGANIZATION
                    </>
                  }
                  placeholder="Select Sub-organization"
                  onRemoveItemMessage={
                    <ConfirmDialogContent
                      title="Remove sub-organization?"
                      subtitle="The user will no longer have access to this sub-organization"
                    />
                  }
                />
              )}
            </Grid>
          </Grid>
        </InnerCard>
      </div>
    </>
  );
};

SubOrgsSelect.propTypes = {
  roles: PropTypes.array.isRequired,
  organization: PropTypes.object.isRequired,
  isWizardUser: PropTypes.bool.isRequired,
};
