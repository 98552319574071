import React from 'react';

import Chip from '~/components/core/Atomic/Chip/Chip';

import { useStyles } from '../../../../../assets/styles';

export const ConditionsSeparator = () => {
  const classes = useStyles();
  return <Chip size="small" label="And" className={classes.chip} />;
};
