import React from 'react';
import PropTypes from 'prop-types';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import Grid from '~/components/core/Atomic/Grid/Grid';
import InnerCard from '~/components/core/Cards/InnerCard';
import DialogFooterActions from '~/components/core/DialogFooterActions';
import SwitchFormik from '~/components/core/Formik/SwitchFormik';
import { getIsActive } from '~/components/SystemConfiguration/Integrations/utils';

import CardDialog from '../../../../../CardDialog';
import { Heading } from '../../../../../core';
import TextFieldFormik from '../../../../../TextFieldFormik';

const FIELD_IDS = {
  ENABLED: 'is_enabled',
  COMPANY_CODE: 'company_code',
  CLIENT_ID: 'client_id',
  CLIENT_SECRET: 'client_secret_output',
};

const SetupDialogInner = ({ onClose }) => {
  const classes = useStyles();
  const { isSubmitting, handleSubmit, values } = useFormikContext();

  const isTextFieldDisabled = isSubmitting || !values[FIELD_IDS.ENABLED];

  return (
    <CardDialog
      title="CCC Integration Setup"
      isDialog
      preventClose={isSubmitting}
      maxWidth="sm"
      fullWidth
      onClose={onClose}
      footerActions={
        <DialogFooterActions disabled={isSubmitting} onClickPrimary={handleSubmit} onClickSecondary={onClose} />
      }
    >
      <InnerCard className="p-24 pb-4">
        <Heading variant={Heading.TYPES.H3}>Activate CCC</Heading>
        <SwitchFormik
          checked={!!values[FIELD_IDS.ENABLED]}
          className={classes.formsSwitch}
          id={FIELD_IDS.ENABLED}
          label="Enable CCC Setup"
        />
      </InnerCard>
      <InnerCard className="p-24">
        <Heading variant={Heading.TYPES.H3} className="mb-24">
          Integration Technical Data
        </Heading>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <TextFieldFormik
              id={FIELD_IDS.COMPANY_CODE}
              label="Company Code"
              className={classes.textField}
              fullWidth
              disabled={isTextFieldDisabled}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldFormik
              id={FIELD_IDS.CLIENT_ID}
              label="Client ID"
              fullWidth
              className={classes.textField}
              disabled={isTextFieldDisabled}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldFormik
              id={FIELD_IDS.CLIENT_SECRET}
              label="Client Secret"
              fullWidth
              className={classes.textField}
              disabled={isTextFieldDisabled}
              type="password"
            />
          </Grid>
        </Grid>
      </InnerCard>
    </CardDialog>
  );
};

SetupDialogInner.propTypes = {
  isUpdate: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

const CCCSetupDialog = ({ handleSubmit, onClose, data }) => {
  const initialValues = {
    [FIELD_IDS.ENABLED]: data?.is_enabled || false,
    [FIELD_IDS.COMPANY_CODE]: data?.company_code || '',
    [FIELD_IDS.CLIENT_ID]: data?.client_id || '',
    [FIELD_IDS.CLIENT_SECRET]: data?.client_secret_output || '',
  };

  const getRequiredIfConfigurationsExists = (baseSchema) =>
    data.configurations?.find((config) => getIsActive(config)) ? baseSchema.required('Required') : baseSchema;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        [FIELD_IDS.ENABLED]: getRequiredIfConfigurationsExists(Yup.boolean().nullable()),
        [FIELD_IDS.COMPANY_CODE]: getRequiredIfConfigurationsExists(Yup.string().nullable()),
        [FIELD_IDS.CLIENT_ID]: getRequiredIfConfigurationsExists(Yup.string().nullable()),
        [FIELD_IDS.CLIENT_SECRET]: getRequiredIfConfigurationsExists(Yup.string()).nullable(),
      })}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await handleSubmit(values);
        } catch (error) {
          setSubmitting(false);
        }
      }}
    >
      <SetupDialogInner onClose={onClose} data={data} isUpdate={!!data.id} />
    </Formik>
  );
};

CCCSetupDialog.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default CCCSetupDialog;
