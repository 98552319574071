const ASSIGNMENT_CONDITION_OPERATOR_DICT = {
  is: {
    desc: 'Is',
    is_multi_key_value: false,
    is_multi_value: false,
  },
  is_not: {
    desc: 'Is Not',
    is_multi_key_value: false,
    is_multi_value: false,
  },
  in: {
    desc: 'In',
    is_multi_key_value: false,
    is_multi_value: true,
  },
  include: {
    desc: 'Include',
    is_multi_key_value: true,
    is_multi_value: true,
  },
  exclude: {
    desc: 'Exclude',
    is_multi_key_value: true,
    is_multi_value: false,
  },
} as const;

export default ASSIGNMENT_CONDITION_OPERATOR_DICT;
