import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Switch } from '@material-ui/core';
import axios from 'axios';
import { noop } from 'lodash';

import Typography from '~/components/core/Atomic/Typography';
import EmptyState from '~/components/core/EmptyState';

import { reportAxiosError } from '../../../../../../../Utils';
import { SortableTable } from '../../../../../../core';
import { PencilIcon } from '../../../../../../icons';
import { useSysconfig } from '../../../../../SystemConfigurationScreen';
import { serviceAccounts as serviceAccountsAPI } from '../routes';

import Secret from './Secret';

import { useStyles } from '../../../../../../../assets/styles';

const ServiceAccountsTable = ({ serviceAccounts = [], onUpdate = noop, onActionClick = noop }) => {
  const [isStatusLoading, setStatusLoading] = useState(false);
  const { organization } = useSysconfig();
  const classes = useStyles();

  const handleSwitchChange = async (switchAccountId, checked) => {
    try {
      setStatusLoading(true);
      await axios.put(
        serviceAccountsAPI(organization),
        {
          enabled: checked,
        },
        {
          params: { service_account_id: switchAccountId },
        }
      );
      onUpdate();
    } catch (error) {
      await reportAxiosError(error);
    }
    setStatusLoading(false);
  };

  const columns = [
    {
      id: 'name',
      label: 'Name',
    },
    {
      id: 'description',
      label: 'Description',
    },
    {
      id: 'key',
      label: 'Key',
      disableSort: true,
      specialCell: (service_account) => <Secret serviceAccountId={service_account.id} />,
    },
    {
      id: 'created_datetime',
      label: 'Key creation date',
    },
    {
      id: 'is_enabled',
      label: 'Status',
      specialCell: (switchAccount) =>
        isStatusLoading ? (
          <CircularProgress size={20} className={classes.inlineEditIcon} />
        ) : (
          <Switch
            checked={switchAccount.is_enabled}
            onChange={(e, checked) => handleSwitchChange(switchAccount.id, checked)}
            className={classes.formsSwitch}
          />
        ),
    },
    {
      id: 'actions',
      label: 'Actions',
      disableSort: true,
      specialCell: (serviceAccount) => (
        <PencilIcon className={classes.hoverableNonFilledIcon} onClick={() => onActionClick(serviceAccount)} />
      ),
    },
  ];

  return (
    <SortableTable
      rows={serviceAccounts}
      columns={columns}
      emptyStateComponent={
        <EmptyState
          subtitle={
            <Typography display="block" variant="subtitle1">
              No Service Accounts.
            </Typography>
          }
        />
      }
      order="desc"
      keepRowsOrder
    />
  );
};

ServiceAccountsTable.propTypes = {
  serviceAccounts: PropTypes.array,
  onActionClick: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default ServiceAccountsTable;
