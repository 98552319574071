const CLOSING_REASON_CATEGORIES = {
  opening_error: {
    display_name: 'Opening Error',
    sub_categories: {
      opened_manualy_by_mistake: {
        display_name: 'Opened Manually By Mistake',
      },
      duplicate: {
        display_name: 'Duplicate',
      },
      wrong_policy: {
        display_name: 'Wrong Policy',
      },
      opened_unnecessarily_automatically: {
        display_name: 'Opened Unnecessarily Automatically',
      },
      other: {
        display_name: 'Other',
      },
    },
  },
  denied_or_partly_denied: {
    display_name: 'Denied/ Partly Denied',
    sub_categories: {
      siu: {
        display_name: 'SIU',
      },
      policy_or_coverage_limites: {
        display_name: 'Policy/ Coverage Limites',
      },
      loss_outside_effective_policy_dates: {
        display_name: 'Loss Outside Effective Policy Dates',
      },
      no_valid_plan_or_policy_or_coverage: {
        display_name: 'No Valid Plan/ Policy/ Coverage',
      },
      liability_denied: {
        display_name: 'Liability Denied',
      },
      ineligible_item: {
        display_name: 'Ineligible Item',
      },
      other_coverage_available: {
        display_name: 'Other Coverage Available',
      },
      other: {
        display_name: 'Other',
      },
    },
  },
  legitimate_loss: {
    display_name: 'Legitimate Loss',
    sub_categories: {
      within_deductible: {
        display_name: 'Within Deductible',
      },
      record_only: {
        display_name: 'Record Only',
      },
      fully_paid: {
        display_name: 'Fully Paid',
      },
      partially_paid: {
        display_name: 'Partially Paid',
      },
      other: {
        display_name: 'Other',
      },
    },
  },
  abandoned: {
    display_name: 'Abandoned',
    sub_categories: {
      abandoned_by_insured: {
        display_name: 'Abandoned By Insured',
      },
      closed_for_inactivity: {
        display_name: 'Closed For Inactivity',
      },
      withdrawn: {
        display_name: 'Withdrawn',
      },
      settled_outside_of_this_insurance: {
        display_name: 'Settled Outside Of This Insurance',
      },
      non_cooperation: {
        display_name: 'Non-Cooperation',
      },
      record_only: {
        display_name: 'Record Only',
      },
      other: {
        display_name: 'Other',
      },
    },
  },
  other: {
    display_name: 'Other',
    sub_categories: {
      other: {
        display_name: 'Other',
      },
    },
  },
} as const;

export default CLOSING_REASON_CATEGORIES;
