import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import MenuItem from '~/components/core/Atomic/MenuItem';
import CancelButton from '~/components/core/Buttons/CancelButton';

import { TWILIO_CONFIGURATION } from '../../../../../Types';
import CardDialog from '../../../../CardDialog';
import { TextFieldFormik } from '../../../../TextFieldFormik';
import CONSTS from '../QueueManagementConstants';

import { useStyles } from '../../../../../assets/styles';
import styles from './EditWrapUpTimeConfiguration.module.scss';

const possibleTimes = TWILIO_CONFIGURATION.WRAP_UP_TIME.POSSIBLE_TIME_VALUES;
const possibleTimesList = Object.values(possibleTimes).map((t) => t.value);

const EditWrapUpTimeConfiguration = ({ configuration = {}, onSubmit, onCancel }) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        [CONSTS.WRAP_UP_TIME_SECONDS]: configuration[CONSTS.WRAP_UP_TIME_SECONDS] || undefined,
      }}
      validationSchema={Yup.object().shape({
        [CONSTS.WRAP_UP_TIME_SECONDS]: Yup.number()
          .required('Required')
          .integer()
          .oneOf(possibleTimesList, 'Invalid value'),
      })}
      onSubmit={async (values, formikProps) => {
        try {
          await onSubmit({ ...values, [CONSTS.WRAP_UP_TIME_ENABLED]: true });
          onCancel();
        } catch {
          formikProps.setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, handleSubmit }) => {
        return (
          <CardDialog
            title="Configure Wrap-up Time"
            isDialog
            maxWidth="sm"
            fullWidth
            onClose={onCancel}
            preventClose={isSubmitting}
          >
            <div className={styles.inputsContainer}>
              <TextFieldFormik id={CONSTS.WRAP_UP_TIME_SECONDS} select label="Wrap up time" fullWidth>
                {possibleTimesList.map((timeValue) => (
                  <MenuItem key={timeValue} value={timeValue}>
                    {possibleTimes[timeValue].desc}
                  </MenuItem>
                ))}
              </TextFieldFormik>
            </div>
            <div className={classes.buttonsContainer}>
              <CancelButton disabled={isSubmitting} onClick={onCancel} />
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

EditWrapUpTimeConfiguration.propTypes = {
  configuration: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default EditWrapUpTimeConfiguration;
