export const INVOLVED_PROPERTY_FIELD_IDS = [
  'owner_contact_id',
  'attorney_contact_id',
  'attorney_reference_number',
  'insurer_contact_id',
  'insurer_policy_number',
  'insurer_reference_number',
  'location',
];

export const INVOLVED_PERSON_FIELD_IDS = [
  'contact_id',
  'relationship_to_policyholder',
  'was_ambulance_needed',
  'was_hospitalized',
  'hospital_contact_id',
  'admission_datetime',
  'discharge_datetime',
  'attorney_contact_id',
  'attorney_reference_number',
  'representative_contact_id',
  'note',
  'legal_venue_state',
  'legal_venue_reason',
  'injuries_description',
  'insurer_contact_id',
  'insurer_reference_number',
  'insurer_policy_number',
];

export const INVOLVED_VEHICLE_FIELD_IDS = [
  'appraiser_contact_id',
  'auto_repair_facility_contact_id',
  'child_seats',
  'damage_areas',
  'damage_description',
  'damage_level',
  'did_vehicle_have_previous_damage',
  'does_the_vehicle_have_any_modifications',
  'is_car_safe_to_drive',
  'is_drivable',
  'is_total_loss',
  'is_tow',
  'is_vehicle_camera_active',
  'is_vehicle_camera_installed',
  'modification_description',
  'note',
  'point_of_impact',
  'previous_damage_description',
  'previous_damage_level',
  'scheduling_inspection_contact_id',
  'tower_contact_id',
  'vehicle_location',
  'vehicle_speed',
];

export const INVOLVED_DRIVER_FIELD_IDS = [
  'was_seat_belt_worn',
  'license_issuing_state',
  'license_number',
  'license_issue_date',
  'license_type',
  'years_of_driving_experience',
  'who_authorized_the_driver_to_drive_the_vehicle',
  'any_dvla_medical_conditions',
  'dvla_medical_conditions_description',
];

export const INVOLVED_PASSENGER_FIELD_IDS = ['was_seat_belt_worn', 'position'];

export const INVOLVED_NON_MOTORIST_FIELD_IDS = ['means_of_transportation'];

export const INVOLVED_OTHER_PROPERTY_FIELD_IDS = [
  'property_description',
  'damages',
  'damages_description',
  'is_stolen',
];

export const ITEMS_FIELD_IDS = [
  'category',
  'claimed_amount',
  'description',
  'purchase_date',
  'purchase_price',
  'quantity',
  'scheduled',
];

export const BODILY_INJURIES_FIELD_IDS = [
  'injury_type',
  'body_part',
  'other_body_part',
  'injury_severity',
  'description',
];
