import React from 'react';

import SvgIcon from '../core/SvgIcon';

const SearchIcon = (props) => (
  <SvgIcon fill="none" {...props}>
    <path d="M6.25 1.5a4.75 4.75 0 1 0 0 9.5 4.75 4.75 0 0 0 0-9.5ZM.5 6.25a5.75 5.75 0 1 1 11.5 0 5.75 5.75 0 0 1-11.5 0Z" />
    <path d="M9.609 9.609a.5.5 0 0 1 .707 0l3.038 3.037a.5.5 0 0 1-.708.708L9.61 10.316a.5.5 0 0 1 0-.707Z" />
  </SvgIcon>
);

export default SearchIcon;
