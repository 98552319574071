import React from 'react';
import { useFormikContext } from 'formik';

import Grid from '~/components/core/Atomic/Grid/Grid';

import { Text } from '../../../../core';
import TextFieldFormik from '../../../../TextFieldFormik';
import { useSysconfig } from '../../../SystemConfigurationScreen';

import { useStyles } from '../../../../../assets/styles';

export const LIMITS_FIELD_IDS = {
  INDEMNITY_RESERVES_LIMIT: 'indemnity_reserves_limit',
  INDEMNITY_PAYMENTS_LIMIT: 'indemnity_payments_limit',
  EXPENSES_RESERVES_LIMIT: 'expenses_reserves_limit',
  EXPENSES_PAYMENTS_LIMIT: 'expenses_payments_limit',
  RESERVES_LIMIT: 'reserves_limit',
  PAYMENTS_LIMIT: 'payments_limit',
};

const ApiLimits = () => {
  const classes = useStyles();
  const { organization } = useSysconfig();
  const { isSubmitting } = useFormikContext();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} />
      <Grid item xs={12}>
        <Text variant={Text.VARIANTS.LG} weight={Text.WEIGHTS.SEMI_BOLD} colorVariant={Text.COLOR_VARIANTS.BLACK}>
          Payments API limits
        </Text>
      </Grid>
      {organization.are_payment_limits_split ? (
        <>
          <Grid item xs={6}>
            <TextFieldFormik
              id={LIMITS_FIELD_IDS.INDEMNITY_RESERVES_LIMIT}
              label="Indemnity Reserves Limit"
              type="number"
              className={classes.textField}
              fullWidth
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldFormik
              id={LIMITS_FIELD_IDS.INDEMNITY_PAYMENTS_LIMIT}
              label="Indemnity Payments Limit"
              type="number"
              className={classes.textField}
              fullWidth
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldFormik
              id={LIMITS_FIELD_IDS.EXPENSES_RESERVES_LIMIT}
              label="Expenses Reserves limit"
              type="number"
              className={classes.textField}
              fullWidth
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldFormik
              id={LIMITS_FIELD_IDS.EXPENSES_PAYMENTS_LIMIT}
              label="Expenses Payments Limit"
              type="number"
              className={classes.textField}
              fullWidth
              disabled={isSubmitting}
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={6}>
            <TextFieldFormik
              id={LIMITS_FIELD_IDS.RESERVES_LIMIT}
              label="Reserves Limit"
              type="number"
              className={classes.textField}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldFormik
              id={LIMITS_FIELD_IDS.PAYMENTS_LIMIT}
              label="Payments Limit"
              type="number"
              className={classes.textField}
              fullWidth
            />
          </Grid>
          <Grid item xs={2} />
        </>
      )}
    </Grid>
  );
};

export default ApiLimits;
