import React from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';

import CollapsibleWrapper from '~/components/core/Collapsible/CollapsibleWrapper';
import cn from '~/Utils/cn';

import { ErrorHelperTextFormik } from '../../../../../core/Formik/ErrorHelperTextFormik';
import useFormikChangeListener from '../../../../../core/Formik/FormikChangeListener';
import { PERMISSION_VERBS_CONFIG } from '../../../../../core/Permissions/PermissionUtils';
import { getPermissionsFieldId, getSectionItems } from '../formUtils';

import NewPermissionActionTag from './NewPermissionActionTag';
import { PermissionVerbsButtonGroupFormik } from './PermissionVerbsButtonGroup';
import { sectionHeaderProptype } from './propTypes';

import styles from './styles.module.scss';

export const SectionHeaderCollapsibleTile = ({ sectionHeader, children, isCollapsible, viewOnly }) => {
  const { values, setValues, errors, touched } = useFormikContext();
  const [isOpen, setIsOpen] = React.useState(false);

  const formikFieldId = getPermissionsFieldId(sectionHeader.id);
  const isSectionValueForbidden = getIn(values, formikFieldId) === PERMISSION_VERBS_CONFIG.FORBIDDEN.optionValue;
  const [shouldShowChevron, setShouldShowChevron] = React.useState(isCollapsible && !isSectionValueForbidden);

  const setSectionItemsValues = (value) => {
    const sectionItems = getSectionItems(sectionHeader);
    const sectionItemsIds = sectionItems.map(({ id }) => id);
    const sectionItemsNewValues = Object.fromEntries(sectionItemsIds.map((id) => [id, value]));

    setValues({
      ...values,
      permissions: {
        ...values.permissions,
        ...sectionItemsNewValues,
      },
    });
  };

  useFormikChangeListener({
    listenForKeys: [formikFieldId],
    onChange: (newValues, previousValues) => {
      const value = getIn(newValues, formikFieldId);
      const previousValue = getIn(previousValues, formikFieldId);

      if (isCollapsible) {
        if (value === PERMISSION_VERBS_CONFIG.FORBIDDEN.optionValue) {
          setShouldShowChevron(false);
          setIsOpen(false);
          setSectionItemsValues(PERMISSION_VERBS_CONFIG.FORBIDDEN.optionValue);
        } else {
          if (previousValue === PERMISSION_VERBS_CONFIG.FORBIDDEN.optionValue) {
            setSectionItemsValues([]);
          }
          if (value) {
            setShouldShowChevron(true);
            if (getIn(touched, formikFieldId)) {
              setIsOpen(true);
            }
          }
        }
      }
    },
    runOnFirstRender: true,
  });

  const handleCollapseChange = () => setIsOpen(!isOpen);

  const hasError = getIn(errors, formikFieldId);

  return (
    <div className={styles.sectionHeaderContainer}>
      <CollapsibleWrapper
        title={sectionHeader.title}
        titleTag={<NewPermissionActionTag permissionActionConfig={sectionHeader} />}
        subtitle={sectionHeader.subtitle}
        summarySectionClassname={cn(styles.summarySection, { [styles.withError]: hasError })}
        noDivider
        actionCard
        openOnChevronClickOnly
        backgroundColor={CollapsibleWrapper.BACKGROUND_COLOR.WHITE}
        expansionMode={CollapsibleWrapper.EXPANSION_MODES.drawer}
        onCollapse={handleCollapseChange}
        open={isOpen}
        isCollapsible={shouldShowChevron}
        unmountOnExit={false}
        additionalHeaderContent={
          <div className={styles.additionalHeaderContent}>
            <ErrorHelperTextFormik id={formikFieldId} />
          </div>
        }
        actions={
          <PermissionVerbsButtonGroupFormik
            fieldId={formikFieldId}
            allowedVerbsIds={sectionHeader.allowedVerbs}
            viewOnly={viewOnly}
          />
        }
      >
        <div className={styles.sectionHeaderDrawerContainer}>{children}</div>
      </CollapsibleWrapper>
    </div>
  );
};

SectionHeaderCollapsibleTile.propTypes = {
  children: PropTypes.node.isRequired,
  sectionHeader: sectionHeaderProptype,
  isCollapsible: PropTypes.bool,
  viewOnly: PropTypes.bool,
};
