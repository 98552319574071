const LOSS_CAUSE_ISO_CODES = {
  PAPP: {
    policy_type: 'PERSONAL AUTO',
    coverages: {
      BODI: {
        BODI: 'BODILY INJURY',
        MPAY: 'MEDICAL PAYMENTS',
        NFLT: 'PIP',
        OTAU: 'OTHER AUTO',
        TRSM: 'TERRORISM',
      },
      GGKP: {},
      MPAY: {
        BODI: 'BODILY INJURY',
        MPAY: 'MEDICAL PAYMENTS',
        NFLT: 'NO FAULT',
        OTAU: 'OTHER AUTO',
      },
      NFLT: {
        BODI: 'BODILY INJURY',
        NFLT: 'PIP',
        OTAU: 'OTHER AUTO',
      },
      OTAU: {
        ANML: 'ANIMAL',
        OTAU: 'OTHER AUTO',
        TOWL: 'TOWING AND LABOR',
        TRSM: 'TERRORISM',
        VAMM: 'VANDALISM AND MALICIOUS MISCHIEF',
        BODI: 'BODILY INJURY',
        MPAY: 'MEDICAL PAYMENTS',
        NFLT: 'PIP',
      },
      PRPD: {
        OTAU: 'OTHER AUTO',
        PRPD: 'PROPERTY DAMAGE',
        TRSM: 'TERRORISM',
      },
      UNDR: {
        BODI: 'BODILY INJURY',
        OTAU: 'OTHER AUTO',
        PRPD: 'PROPERTY DAMAGE',
        TRSM: 'TERRORISM',
      },
      UNIN: {
        BODI: 'BODILY INJURY',
        OTAU: 'OTHER AUTO',
        PRPD: 'PROPERTY DAMAGE',
        TRSM: 'TERRORISM',
      },
      COLL: {
        COLL: 'COLLISION',
        OTAU: 'OTHER AUTO',
      },
      COMP: {
        ANML: 'ANIMAL',
        COMP: 'COMPREHENSIVE',
        FIRE: 'FIRE',
        FLOD: 'FLOOD',
        GLSS: 'GLASS',
        HAIL: 'HAIL',
        OTAU: 'OTHER AUTO',
        PTFT: 'PARTIAL THEFT',
        THFT: 'THEFT',
        TOWL: 'TOWING AND LABOR',
        TRSM: 'TERRORISM',
        VAMM: 'VANDALISM AND MALICIOUS  MISCHIEF',
      },
      GLSS: {
        GLSS: 'GLASS',
        OTAU: 'OTHER AUTO',
      },
      RENT: {
        OTAU: 'OTHER AUTO',
        RENT: 'RENTAL REIMBURSEMENT',
      },
      TOWL: {
        OTAU: 'OTHER AUTO',
        TOWL: 'TOWING AND LABOR',
      },
    },
  },
  CAPP: {
    policy_type: 'COMMERCIAL AUTO',
    coverages: {
      BODI: {
        BODI: 'BODILY INJURY',
        MPAY: 'MEDICAL PAYMENTS',
        NFLT: 'PIP',
        OTAU: 'OTHER AUTO',
        TRSM: 'TERRORISM',
      },
      GGKP: {
        COLL: 'COLLISION',
        OTAU: 'OTHER AUTO',
        PRPD: 'PROPERTY DAMAGE',
        THFT: 'THEFT',
        TRSM: 'TERRORISM',
        BODI: 'BODILY INJURY',
      },
      MPAY: {
        BODI: 'BODILY INJURY',
        MPAY: 'MEDICAL PAYMENTS',
        NFLT: 'PIP',
        OTAU: 'OTHER AUTO',
        TRSM: 'TERRORISM',
      },
      NFLT: {
        BODI: 'BODILY INJURY',
        NFLT: 'PIP',
        OTAU: 'OTHER AUTO',
      },
      OTAU: {
        ANML: 'ANIMAL',
        COLL: 'COLLISION',
        FIRE: 'FIRE',
        FLOD: 'FLOOD',
        GLSS: 'GLASS',
        OTAU: 'OTHER AUTO',
        RENT: 'RENTAL REIMBURSEMENT',
        THFT: 'THEFT',
        TOWL: 'TOWING AND LABOR',
        TRSM: 'TERRORISM',
        VAMM: 'VANDALISM AND MALICIOUS MISCHIEF',
        BODI: 'BODILY INJURY',
        MPAY: 'MEDICAL PAYMENTS',
        NFLT: 'PIP',
      },
      PRPD: {
        OTAU: 'OTHER AUTO',
        PRPD: 'PROPERTY DAMAGE',
        TRSM: 'TERRORISM',
      },
      UNDR: {
        BODI: 'BODILY INJURY',
        OTAU: 'OTHER AUTO',
        PRPD: 'PROPERTY DAMAGE',
        TRSM: 'TERRORISM',
      },
      UNIN: {
        BODI: 'BODILY INJURY',
        OTAU: 'OTHER AUTO',
        PRPD: 'PROPERTY DAMAGE',
        TRSM: 'TERRORISM',
      },
      COLL: {
        COLL: 'COLLISION',
        OTAU: 'OTHER AUTO',
      },
      COMP: {
        ANML: 'ANIMAL',
        COMP: 'COMPREHENSIVE',
        FIRE: 'FIRE',
        FLOD: 'FLOOD',
        GLSS: 'GLASS',
        HAIL: 'HAIL',
        OTAU: 'OTHER AUTO',
        PTFT: 'PARTIAL THEFT',
        THFT: 'THEFT',
        TOWL: 'TOWING AND LABOR',
        TRSM: 'TERRORISM',
        VAMM: 'VANDALISM AND MALICIOUS MISCHIEF',
      },
      GLSS: {
        GLSS: 'GLASS',
        OTAU: 'OTHER AUTO',
      },
      RENT: {
        OTAU: 'OTHER AUTO',
        RENT: 'RENTAL REIMBURSEMENT',
      },
      TOWL: {
        OTAU: 'OTHER AUTO',
        TOWL: 'TOWING AND LABOR',
      },
    },
  },
  PPHO: {
    policy_type: 'PERSONAL HOMEOWNERS',
    coverages: {
      HULL: {
        ACFT: 'AIRCRAFT',
        BURG: 'BURGLARY',
        CLPS: 'COLLAPSE',
        CNTP: 'CONTAMINATION/ POLLUTION',
        COLL: 'COLLISION',
        CRCK: 'CRACKING/RUPTURE',
        ELEC: 'ELECTRIC CURRENT',
        ERTH: 'EARTHQUAKE/MOVEMENT',
        EXPL: 'EXPLOSION',
        FIRE: 'FIRE',
        FLOD: 'FLOOD',
        FOBJ: 'FALLING OBJECTS',
        FREZ: 'FREEZING',
        GLSS: 'GLASS',
        HAIL: 'HAIL',
        LGHT: 'LIGHTNING',
        OTPR: 'OTHER',
        RIOT: 'RIOT',
        SINK: 'SINKING',
        SMOK: 'SMOKE',
        SNKL: 'SINKHOLE',
        THFT: 'THEFT',
        TRSM: 'TERRORISM',
        UPST: 'UPSET, OVER TURN',
        VAMM: 'VANDALISM & MALICIOUS MISCHIEF',
        VHCL: 'VEHICLES',
        VOLC: 'VOLCANIC ERUPTION',
        WATR: 'WATER',
        WIND: 'WIND',
        WISS: 'WEIGHT ICE/SNOW/SLEET',
      },
      MOTR: {
        ACFT: 'AIRCRAFT',
        CLPS: 'COLLAPSE',
        CNTP: 'CONTAMINATION/ POLLUTION',
        COLL: 'COLLISION',
        CRCK: 'CRACKING/RUPTURE',
        ELEC: 'ELECTRIC CURRENT',
        ERTH: 'EARTHQUAKE/MOVEMENT',
        EXPL: 'EXPLOSION',
        FIRE: 'FIRE',
        FLOD: 'FLOOD',
        FOBJ: 'FALLING OBJECTS',
        FREZ: 'FREEZING',
        HAIL: 'HAIL',
        LGHT: 'LIGHTNING',
        OTPR: 'OTHER',
        RIOT: 'RIOT',
        SINK: 'SINKING',
        SMOK: 'SMOKE',
        SNKL: 'SINKHOLE',
        THFT: 'THEFT',
        TRSM: 'TERRORISM',
        UPST: 'UPSET, OVER TURN',
        VAMM: 'VANDALISM & MALICIOUS MISCHIEF',
        VHCL: 'VEHICLES',
        VOLC: 'VOLCANIC ERUPTION',
        WATR: 'WATER',
        WIND: 'WIND',
        WISS: 'WEIGHT ICE/SNOW/SLEET',
      },
      MPAY: {
        BODI: 'BODILY INJURY',
        MPAY: 'MEDICAL PAYMENTS',
      },
      OTLB: {
        BISF: 'SLIP AND FALL',
        BODI: 'BODILY INJURY',
        DISC: 'DISCRIMINATION',
        DOGB: 'DOG BITE',
        ENVR: 'ENVIRONMENTAL',
        FALS: 'FALSE ARREST',
        LIAB: 'PROPERTY DAMAGE',
        MPAY: 'MEDICAL PAYMENTS',
        OTCA: 'OTHER',
        SLND: 'SLANDER',
        TRSM: 'TERRORISM',
        WEVC: 'WRONGFUL EVICTION',
      },
      PINJ: {
        BODI: 'BODILY INJURY',
        DISC: 'DISCRIMINATION',
        FALS: 'FALSE ARREST',
        HARS: 'HARASSMENT',
        LIBL: 'LIBEL',
        MPRS: 'MALICIOUS PROSECUTION',
        OTCA: 'OTHER',
        OTPI: 'OTHER PERSONAL INJURY',
        SLND: 'SLANDER',
        WEVC: 'WRONGFUL EVICTION',
        WTRM: 'WRONGFUL TERMINATION',
      },
      WCIL: {
        WCIL: 'INDEMNITY',
      },
      WCLL: {
        WCLL: 'LIABILITY',
      },
      LIAB: {
        BISF: 'SLIP AND FALL',
        BODI: 'BODILY INJURY',
        DISC: 'DISCRIMINATION',
        DOGB: 'DOG BITE',
        ENVR: 'ENVIRONMENTAL',
        FALS: 'FALSE ARREST',
        HARS: 'HARASSMENT',
        LIAB: 'PROPERTY DAMAGE',
        LIBL: 'LIBEL',
        MPAY: 'MEDICAL PAYMENTS',
        MPRS: 'MALICIOUS PROSECUTION',
        OTCA: 'OTHER',
        OTPI: 'OTHER PERSONAL INJURY',
        SLND: 'SLANDER',
        TRSM: 'TERRORISM',
        WEVC: 'WRONGFUL EVICTION',
        WTRM: 'WRONGFUL TERMINATION',
      },
      WCML: {
        WCML: 'MEDICAL',
      },
      WISS: {
        WISS: 'WEIGHT ICE/ SNOW/ SLEET',
      },
      ACFT: {
        ACFT: 'AIRCRAFT',
      },
      BURG: {
        BURG: 'BURGLARY',
      },
      CLPS: {
        CLPS: 'COLLAPSE',
      },
      CNTP: {
        CNTP: 'CONTAMINATION/ POLLUTION',
      },
      CRCK: {
        CRCK: 'CRACKING/RUPTURE',
      },
      ELEC: {
        ELEC: 'ELECTRIC CURRENT',
      },
      ERTH: {
        ERTH: 'EARTHQUAKE/MOVEMENT',
      },
      EXPL: {
        EXPL: 'EXPLOSION',
      },
      FDTY: {
        FDTY: 'FIDELITY',
      },
      FIRE: {
        FIRE: 'FIRE',
      },
      FLOD: {
        FLOD: 'FLOOD',
      },
      FOBJ: {
        FOBJ: 'FALLING OBJECTS',
      },
      FREZ: {
        FREZ: 'FREEZING',
      },
      GLSS: {
        GLSS: 'GLASS',
        OTAU: 'OTHER AUTO',
      },
      HAIL: {
        HAIL: 'HAIL',
      },
      LGHT: {
        LGHT: 'LIGHTNING',
      },
      LOBG: {
        LOBG: 'LOST BAGGAGE',
      },
      LVMT: {
        LVMT: 'LIVESTOCK/MORTALITY',
      },
      MOLD: {
        MOLD: 'MOLD',
      },
      MYSD: {
        MYSD: 'MYSTERIOUS DISAPPEARANCE',
      },
      OTPR: {
        ACFT: 'AIRCRAFT',
        BURG: 'BURGLARY',
        CLPS: 'COLLAPSE',
        CNTP: 'CONTAMINATION/ POLLUTION',
        COLL: 'COLLISION',
        CRCK: 'CRACKING',
        ELEC: 'ELECTRIC CURRENT',
        ERTH: 'EARTHQUAKE',
        EXPL: 'EXPLOSION',
        FDTY: 'FIDELITY',
        FIRE: 'FIRE',
        FLOD: 'FLOOD',
        FOBJ: 'FALLING OBJECTS',
        FREZ: 'FREEZING',
        HAIL: 'HAIL',
        LGHT: 'LIGHTNING',
        OTPR: 'OTHER PERILS',
        RIOT: 'RIOT',
        ROBB: 'ROBBERY',
        SINK: 'SINKING',
        SMOK: 'SMOKE',
        SNKL: 'SINKHOLE',
        THFT: 'THEFT',
        TRSM: 'TERRORISM',
        UPST: 'UPSET',
        VAMM: 'VANDALISM AND MALICIOUS MISCHIEF',
        VOLC: 'VOLCANIC ERUPTION',
        WIND: 'WIND',
        WISS: 'WEIGHT ICE/SNOW/SLEET',
      },
      RIOT: {
        RIOT: 'RIOT/CIVIL COMMOTION',
      },
      ROBB: {
        ROBB: 'ROBBERY',
      },
      SMOK: {
        SMOK: 'SMOKE',
      },
      SNKL: {
        SNKL: 'SINKHOLE',
      },
      THFT: {
        THFT: 'THEFT',
      },
      TRSM: {
        TRSM: 'TERRORISM',
      },
      VAMM: {
        VAMM: 'VANDALISM AND MALICIOUS MISCHIEF',
      },
      VHCL: {
        VHCL: 'VEHICLES',
      },
      VOLC: {
        VOLC: 'VOLCANIC ERUPTION',
      },
      WATR: {
        WATR: 'WATER',
      },
      WIND: {
        WIND: 'WIND',
      },
    },
  },
  CCGL: {
    policy_type: 'COMMERCIAL GENERAL LIABILITY',
    coverages: {
      MPAY: {
        BODI: 'BODILY INJURY',
        MPAY: 'MEDICAL PAYMENTS',
      },
      DRCT: {
        BODI: 'BODILY INJURY',
        DISC: 'DISCRIMINATION',
        FALS: 'FALSE ARREST',
        HARS: 'HARASSMENT',
        LIAB: 'PROPERTY DAMAGE',
        LIBL: 'LIBEL',
        MPRS: 'MALICIOUS PROSECUTION',
        OTCA: 'OTHER',
        OTPI: 'OTHER PERSONAL INJURY',
        SLND: 'SLANDER',
        WEVC: 'WRONGFUL EVICTION',
        WTRM: 'WRONGFUL TERMINATION',
      },
      EROM: {
        BODI: 'BODILY INJURY',
        LIAB: 'PROPERTY DAMAGE',
        OTCA: 'OTHER',
      },
      ERPL: {
        BODI: 'BODILY INJURY',
        DISC: 'DISCRIMINATION',
        HARS: 'HARASSMENT',
        LIBL: 'LIBEL',
        OTCA: 'OTHER',
        OTPI: 'OTHER PERSONAL INJURY',
        SLND: 'SLANDER',
        WTRM: 'WRONGFUL TERMINATION',
      },
      LIAB: {
        BISF: 'SLIP AND FALL',
        BODI: 'BODILY INJURY',
        DISC: 'DISCRIMINATION',
        DOGB: 'DOG BITE',
        ENVR: 'ENVIRONMENTAL',
        FALS: 'FALSE ARREST',
        HARS: 'HARASSMENT',
        LIAB: 'PROPERTY DAMAGE',
        LIBL: 'LIBEL',
        MPAY: 'MEDICAL PAYMENTS',
        MPRS: 'MALICIOUS PROSECUTION',
        OTCA: 'OTHER',
        OTPI: 'OTHER PERSONAL INJURY',
        SLND: 'SLANDER',
        TRSM: 'TERRORISM',
        WEVC: 'WRONGFUL EVICTION',
        WTRM: 'WRONGFUL TERMINATION',
      },
      OTLB: {
        BISF: 'SLIP AND FALL',
        BODI: 'BODILY INJURY',
        DISC: 'DISCRIMINATION',
        DOGB: 'DOG BITE',
        ENVR: 'ENVIRONMENTAL',
        FALS: 'FALSE ARREST',
        HARS: 'HARASSMENT',
        LIAB: 'PROPERTY DAMAGE',
        LIBL: 'LIBEL',
        MPAY: 'MEDICAL PAYMENTS',
        MPRS: 'MALICIOUS PROSECUTION',
        OTCA: 'OTHER',
        SLND: 'SLANDER',
        TRSM: 'TERRORISM',
        WEVC: 'WRONGFUL EVICTION',
        WTRM: 'WRONGFUL TERMINATION',
      },
      PINJ: {
        BODI: 'BODILY INJURY',
        DISC: 'DISCRIMINATION',
        FALS: 'FALSE ARREST',
        HARS: 'HARASSMENT',
        LIBL: 'LIBEL',
        MPRS: 'MALICIOUS PROSECUTION',
        OTCA: 'OTHER',
        OTPI: 'OTHER PERSONAL INJURY',
        SLND: 'SLANDER',
        WEVC: 'WRONGFUL EVICTION',
        WTRM: 'WRONGFUL TERMINATION',
      },
      POLL: {
        BODI: 'BODILY INJURY',
        ENVR: 'ENVIRONMENTAL',
        LIAB: 'PROPERTY DAMAGE',
        OTCA: 'OTHER',
        TRSM: 'TERRORISM',
      },
      PROD: {
        BODI: 'BODILY INJURY',
        LIAB: 'PROPERTY DAMAGE',
        OTCA: 'OTHER',
      },
    },
  },
  WCEL: {
    policy_type: 'WORKERS COMP AND EMPLOYERS LIABILITY',
    coverages: {
      WCIL: {
        WCIL: 'INDEMNITY',
      },
      WCLL: {
        WCLL: 'LIABILITY',
      },
      WCML: {
        WCML: 'MEDICAL',
      },
    },
  },
  PPOP: {
    policy_type: 'PERSONAL PROPERTY OTHER',
    coverages: {
      ACFT: {
        ACFT: 'AIRCRAFT',
      },
      BURG: {
        BURG: 'BURGLARY',
      },
      CLPS: {
        CLPS: 'COLLAPSE',
      },
      CNTP: {
        CNTP: 'CONTAMINATION/ POLLUTION',
      },
      CRCK: {
        CRCK: 'CRACKING/RUPTURE',
      },
      ELEC: {
        ELEC: 'ELECTRIC CURRENT',
      },
      ERTH: {
        ERTH: 'EARTHQUAKE/MOVEMENT',
      },
      EXPL: {
        EXPL: 'EXPLOSION',
      },
      FDTY: {
        FDTY: 'FIDELITY',
      },
      FIRE: {
        FIRE: 'FIRE',
      },
      FLOD: {
        FLOD: 'FLOOD',
      },
      FOBJ: {
        FOBJ: 'FALLING OBJECTS',
      },
      FREZ: {
        FREZ: 'FREEZING',
      },
      GLSS: {
        GLSS: 'GLASS',
      },
      HAIL: {
        HAIL: 'HAIL',
      },
      LGHT: {
        LGHT: 'LIGHTNING',
      },
      LOBG: {
        LOBG: 'LOST BAGGAGE',
      },
      LVMT: {
        LVMT: 'LIVESTOCK MORTALITY',
      },
      MOLD: {
        MOLD: 'MOLD',
      },
      MYSD: {
        MYSD: 'MYSTERIOUS DISAPPEARANCE',
      },
      RIOT: {
        RIOT: 'RIOT/CIVIL COMMOTION',
      },
      ROBB: {
        ROBB: 'ROBBERY',
      },
      SMOK: {
        SMOK: 'SMOKE',
      },
      SNKL: {
        SNKL: 'SINKHOLE',
      },
      THFT: {
        THFT: 'THEFT',
      },
      TRSM: {
        TRSM: 'TERRORISM',
      },
      VAMM: {
        VAMM: 'VANDALISM AND MALICIOUS MISCHIEF',
      },
      VHCL: {
        VHCL: 'VEHICLES',
      },
      VOLC: {
        VOLC: 'VOLCANIC ERUPTION',
      },
      WATR: {
        WATR: 'WATER',
      },
      WIND: {
        WIND: 'WIND',
      },
      WISS: {
        WISS: 'WEIGHT ICE/ SNOW/ SLEET',
      },
      HULL: {
        ACFT: 'AIRCRAFT',
        BURG: 'BURGLARY',
        CLPS: 'COLLAPSE',
        CNTP: 'CONTAMINATION/ POLLUTION',
        COLL: 'COLLISION',
        CRCK: 'CRACKING/RUPTURE',
        ELEC: 'ELECTRIC CURRENT',
        ERTH: 'EARTHQUAKE/MOVEMENT',
        EXPL: 'EXPLOSION',
        FIRE: 'FIRE',
        FLOD: 'FLOOD',
        FOBJ: 'FALLING OBJECTS',
        FREZ: 'FREEZING',
        GLSS: 'GLASS',
        HAIL: 'HAIL',
        LGHT: 'LIGHTNING',
        OTPR: 'OTHER',
        RIOT: 'RIOT',
        SINK: 'SINKING',
        SMOK: 'SMOKE',
        SNKL: 'SINKHOLE',
        THFT: 'THEFT',
        TRSM: 'TERRORISM',
        UPST: 'UPSET, OVER TURN',
        VAMM: 'VANDALISM & MALICIOUS MISCHIEF',
        VHCL: 'VEHICLES',
        VOLC: 'VOLCANIC ERUPTION',
        WATR: 'WATER',
        WIND: 'WIND',
        WISS: 'WEIGHT ICE/SNOW/SLEET',
      },
      MOTR: {
        ACFT: 'AIRCRAFT',
        CLPS: 'COLLAPSE',
        CNTP: 'CONTAMINATION/ POLLUTION',
        COLL: 'COLLISION',
        CRCK: 'CRACKING/RUPTURE',
        ELEC: 'ELECTRIC CURRENT',
        ERTH: 'EARTHQUAKE/MOVEMENT',
        EXPL: 'EXPLOSION',
        FIRE: 'FIRE',
        FLOD: 'FLOOD',
        FOBJ: 'FALLING OBJECTS',
        FREZ: 'FREEZING',
        HAIL: 'HAIL',
        LGHT: 'LIGHTNING',
        OTPR: 'OTHER',
        RIOT: 'RIOT',
        SINK: 'SINKING',
        SMOK: 'SMOKE',
        SNKL: 'SINKHOLE',
        THFT: 'THEFT',
        TRSM: 'TERRORISM',
        UPST: 'UPSET, OVER TURN',
        VAMM: 'VANDALISM & MALICIOUS MISCHIEF',
        VHCL: 'VEHICLES',
        VOLC: 'VOLCANIC ERUPTION',
        WATR: 'WATER',
        WIND: 'WIND',
        WISS: 'WEIGHT ICE/SNOW/SLEET',
      },
    },
  },
  CLBO: {
    policy_type: 'COMMERCIAL BUSINESS OWNERS',
    coverages: {
      NCVG: {
        NLOS: 'NO LOSS',
      },
      LIAB: {
        BISF: 'SLIP AND FALL',
        BODI: 'BODILY INJURY',
        DISC: 'DISCRIMINATION',
        DOGB: 'DOG BITE',
        ENVR: 'ENVIRONMENTAL',
        FALS: 'FALSE ARREST',
        HARS: 'HARASSMENT',
        LIAB: 'PROPERTY DAMAGE',
        LIBL: 'LIBEL',
        MPAY: 'MEDICAL PAYMENTS',
        MPRS: 'MALICIOUS PROSECUTION',
        OTCA: 'OTHER',
        OTPI: 'OTHER PERSONAL INJURY',
        SLND: 'SLANDER',
        TRSM: 'TERRORISM',
        WEVC: 'WRONGFUL EVICTION',
        WTRM: 'WRONGFUL TERMINATION',
      },
      MPAY: {
        BODI: 'BODILY INJURY',
        MPAY: 'MEDICAL PAYMENTS',
      },
      OTLB: {
        BISF: 'SLIP AND FALL',
        BODI: 'BODILY INJURY',
        DISC: 'DISCRIMINATION',
        DOGB: 'DOG BITE',
        ENVR: 'ENVIRONMENTAL',
        HARS: 'HARASSMENT',
        LIBL: 'LIBEL',
        MPAY: 'MEDICAL PAYMENTS',
        MPRS: 'MALICIOUS PROSECUTION',
        OTCA: 'OTHER',
        OTPI: 'OTHER PERSONAL INJURY',
        TRSM: 'TERRORISM',
        WEVC: 'WRONGFUL EVICTION',
        WTRM: 'WRONGFUL TERMINATION',
      },
      PINJ: {
        BODI: 'BODILY INJURY',
        DISC: 'DISCRIMINATION',
        FALS: 'FALSE ARREST',
        HARS: 'HARASSMENT',
        LIBL: 'LIBEL',
        MPRS: 'MALICIOUS PROSECUTION',
        OTCA: 'OTHER',
        OTPI: 'OTHER PERSONAL INJURY',
        SLND: 'SLANDER',
        WEVC: 'WRONGFUL EVICTION',
        WTRM: 'WRONGFUL TERMINATION',
      },
      POLL: {
        BODI: 'BODILY INJURY',
        ENVR: 'ENVIRONMENTAL',
        LIAB: 'PROPERTY DAMAGE',
        OTCA: 'OTHER',
      },
      PROD: {
        BODI: 'BODILY INJURY',
        ENVR: 'ENVIROMENTAL',
        LIAB: 'LIABLITY',
        OTCA: 'OTHER',
      },
    },
  },
  OLIB: {
    policy_type: 'OTHER LIABILITY',
    coverages: {
      NCVG: {
        NLOS: 'NO LOSS',
      },
      DRCT: {
        BODI: 'BODILY INJURY',
        DISC: 'DISCRIMINATION',
        FALS: 'FALSE ARREST',
        HARS: 'HARASSMENT',
        LIAB: 'PROPERTY DAMAGE',
        LIBL: 'LIBEL',
        MPRS: 'MALICIOUS PROSECUTION',
        OTCA: 'OTHER',
        OTPI: 'OTHER PERSONAL INJURY',
        SLND: 'SLANDER',
        WEVC: 'WRONGFUL EVICTION',
        WTRM: 'WRONGFUL TERMINATION',
      },
      EROM: {
        BODI: 'BODILY INJURY',
        LIAB: 'PROPERTY DAMAGE',
        OTCA: 'OTHER',
      },
      ERPL: {
        BODI: 'BODILY INJURY',
        DISC: 'DISCRIMINATION',
        HARS: 'HARASSMENT',
        LIBL: 'LIBEL',
        OTCA: 'OTHER',
        OTPI: 'OTHER PERSONAL INJURY',
        SLND: 'SLANDER',
        WTRM: 'WRONGFUL TERMINATION',
      },
      LIAB: {
        BISF: 'SLIP AND FALL',
        BODI: 'BODILY INJURY',
        DISC: 'DISCRIMINATION',
        DOGB: 'DOG BITE',
        ENVR: 'ENVIRONMENTAL',
        FALS: 'FALSE ARREST',
        HARS: 'HARASSMENT',
        LIAB: 'PROPERTY DAMAGE',
        LIBL: 'LIBEL',
        MPAY: 'MEDICAL PAYMENTS',
        MPRS: 'MALICIOUS PROSECUTION',
        OTCA: 'OTHER',
        OTPI: 'OTHER PERSONAL INJURY',
        SLND: 'SLANDER',
        TRSM: 'TERRORISM',
        WEVC: 'WRONGFUL EVICTION',
        WTRM: 'WRONGFUL TERMINATION',
      },
      OTLB: {
        BISF: 'SLIP AND FALL',
        BODI: 'BODILY INJURY',
        DISC: 'DISCRIMINATION',
        DOGB: 'DOG BITE',
        ENVR: 'ENVIRONMENTAL',
        FALS: 'FALSE ARREST',
        HARS: 'HARASSMENT',
        LIAB: 'PROPERTY DAMAGE',
        MPAY: 'MEDICAL PAYMENTS',
        OTCA: 'OTHER',
        OTPI: 'OTHER PERSONAL INJURY',
        SLND: 'SLANDER',
        TRSM: 'TERRORISM',
        WEVC: 'WRONGFUL EVICTION',
        WTRM: 'WRONGFUL TERMINATION',
      },
      PINJ: {
        BODI: 'BODILY INJURY',
        DISC: 'DISCRIMINATION',
        FALS: 'FALSE ARREST',
        HARS: 'HARASSMENT',
        LIBL: 'LIBEL',
        MPRS: 'MALICIOUS PROSECUTION',
        OTCA: 'OTHER',
        OTPI: 'OTHER PERSONAL INJURY',
        SLND: 'SLANDER',
        WEVC: 'WRONGFUL EVICTION',
        WTRM: 'WRONGFUL TERMINATION',
      },
      POLL: {
        BODI: 'BODILY INJURY',
        ENVR: 'ENVIRONMENTAL',
        LIAB: 'PROPERTY DAMAGE',
        OTCA: 'OTHER',
      },
      PROD: {
        BODI: 'BODILY INJURY',
        LIAB: 'PROPERTY DAMAGE',
        OTCA: 'OTHER',
      },
    },
  },
  PLIB: {
    policy_type: 'PERSONAL LIABILITY',
    coverages: {
      NCVG: {
        NLOS: 'NO LOSS',
      },
      DRCT: {
        BODI: 'BODILY INJURY',
        DISC: 'DISCRIMINATION',
        FALS: 'FALSE ARREST',
        HARS: 'HARASSMENT',
        LIAB: 'PROPERTY DAMAGE',
        LIBL: 'LIBEL',
        MPRS: 'MALICIOUS PROSECUTION',
        OTCA: 'OTHER',
        OTPI: 'OTHER PERSONAL INJURY',
        SLND: 'SLANDER',
        WEVC: 'WRONGFUL EVICTION',
        WTRM: 'WRONGFUL TERMINATION',
      },
      EROM: {
        BODI: 'BODILY INJURY',
        LIAB: 'PROPERTY DAMAGE',
        OTCA: 'OTHER',
      },
      ERPL: {
        BODI: 'BODILY INJURY',
        DISC: 'DISCRIMINATION',
        HARS: 'HARASSMENT',
        LIBL: 'LIBEL',
        OTCA: 'OTHER',
        OTPI: 'OTHER PERSONAL INJURY',
        SLND: 'SLANDER',
        WTRM: 'WRONGFUL TERMINATION',
      },
      LIAB: {
        BISF: 'SLIP AND FALL',
        BODI: 'BODILY INJURY',
        DISC: 'DISCRIMINATION',
        DOGB: 'DOG BITE',
        ENVR: 'ENVIRONMENTAL',
        FALS: 'FALSE ARREST',
        HARS: 'HARASSMENT',
        LIAB: 'PROPERTY DAMAGE',
        LIBL: 'LIBEL',
        MPAY: 'MEDICAL PAYMENTS',
        MPRS: 'MALICIOUS PROSECUTION',
        OTCA: 'OTHER',
        OTPI: 'OTHER PERSONAL INJURY',
        SLND: 'SLANDER',
        TRSM: 'TERRORISM',
        WEVC: 'WRONGFUL EVICTION',
        WTRM: 'WRONGFUL TERMINATION',
      },
      MPAY: {
        BODI: 'BODILY INJURY',
        MPAY: 'MEDICAL PAYMENTS',
      },
      OTLB: {
        BISF: 'SLIP AND FALL',
        BODI: 'BODILY INJURY',
        DISC: 'DISCRIMINATION',
        DOGB: 'DOG BITE',
        ENVR: 'ENVIRONMENTAL',
        FALS: 'FALSE ARREST',
        HARS: 'HARASSMENT',
        LIAB: 'PROPERTY DAMAGE',
        LIBL: 'LIBEL',
        MPAY: 'MEDICAL PAYMENTS',
        MPRS: 'MALICIOUS PROSECUTION',
        OTCA: 'OTHER',
        OTPI: 'OTHER PERSONAL INJURY',
        SLND: 'SLANDER',
        TRSM: 'TERRORISM',
        WEVC: 'WRONGFUL EVICTION',
        WTRM: 'WRONGFUL TERMINATION',
      },
      PINJ: {
        BODI: 'BODILY INJURY',
        DISC: 'DISCRIMINATION',
        FALS: 'FALSE ARREST',
        HARS: 'HARASSMENT',
        LIBL: 'LIBEL',
        MPRS: 'MALICIOUS PROSECUTION',
        OTCA: 'OTHER',
        OTPI: 'OTHER PERSONAL INJURY',
        SLND: 'SLANDER',
        WEVC: 'WRONGFUL EVICTION',
        WTRM: 'WRONGFUL TERMINATION',
      },
    },
  },
  CPMP: {
    policy_type: 'COMMERCIAL MULTIPERIL',
    coverages: {
      NCVG: {
        NLOS: 'NO LOSS',
      },
      DRCT: {
        BODI: 'BODILY INJURY',
        DISC: 'DISCRIMINATION',
        FALS: 'FALSE ARREST',
        HARS: 'HARASSMENT',
        LIAB: 'PROPERTY DAMAGE',
        LIBL: 'LIBEL',
        MPRS: 'MALICIOUS PROSECUTION',
        OTCA: 'OTHER',
        OTPI: 'OTHER PERSONAL INJURY',
        SLND: 'SLANDER',
        WEVC: 'WRONGFUL EVICTION',
        WTRM: 'WRONGFUL TERMINATION',
      },
      EROM: {
        BODI: 'BODILY INJURY',
        LIAB: 'PROPERTY DAMAGE',
        OTCA: 'OTHER',
      },
      ERPL: {
        BODI: 'BODILY INJURY',
        DISC: 'DISCRIMINATION',
        HARS: 'HARASSMENT',
        LIBL: 'LIBEL',
        OTCA: 'OTHER',
        OTPI: 'OTHER PERSONAL INJURY',
        SLND: 'SLANDER',
        WTRM: 'WRONGFUL TERMINATION',
      },
      LIAB: {
        BISF: 'SLIP AND FALL',
        BODI: 'BODILY INJURY',
        DISC: 'DISCRIMINATION',
        DOGB: 'DOG BITE',
        ENVR: 'ENVIRONMENTAL',
        FALS: 'FALSE ARREST',
        HARS: 'HARASSMENT',
        LIAB: 'PROPERTY DAMAGE',
        LIBL: 'LIBEL',
        MPAY: 'MEDICAL PAYMENTS',
        MPRS: 'MALICIOUS PROSECUTION',
        OTCA: 'OTHER',
        OTPI: 'OTHER PERSONAL INJURY',
        SLND: 'SLANDER',
        TRSM: 'TERRORISM',
        WEVC: 'WRONGFUL EVICTION',
        WTRM: 'WRONGFUL TERMINATION',
      },
      MPAY: {
        BODI: 'BODILY INJURY',
        MPAY: 'MEDICAL PAYMENTS',
      },
      OTLB: {
        BISF: 'SLIP AND FALL',
        BODI: 'BODILY INJURY',
        DISC: 'DISCRIMINATION',
        DOGB: 'DOG BITE',
        ENVR: 'ENVIRONMENTAL',
        FALS: 'FALSE ARREST',
        HARS: 'HARASSMENT',
        LIAB: 'PROPERTY DAMAGE',
        LIBL: 'LIBEL',
        MPAY: 'MEDICAL PAYMENTS',
        MPRS: 'MALICIOUS PROSECUTION',
        OTCA: 'OTHER',
        OTPI: 'OTHER PERSONAL INJURY',
        SLND: 'SLANDER',
        TRSM: 'TERRORISM',
        WEVC: 'WRONGFUL EVICTION',
        WTRM: 'WRONGFUL TERMINATION',
      },
      PINJ: {
        BODI: 'BODILY INJURY',
        DISC: 'DISCRIMINATION',
        FALS: 'FALSE ARREST',
        HARS: 'HARASSMENT',
        LIBL: 'LIBEL',
        MPRS: 'MALICIOUS PROSECUTION',
        OTCA: 'OTHER',
        OTPI: 'OTHER PERSONAL INJURY',
        SLND: 'SLANDER',
        WEVC: 'WRONGFUL EVICTION',
        WTRM: 'WRONGFUL TERMINATION',
      },
      POLL: {
        BODI: 'BODILY INJURY',
        ENVR: 'ENVIRONMENTAL',
        LIAB: 'PROPERTY DAMAGE',
        OTCA: 'OTHER',
        TRSM: 'TERRORISM',
      },
      PROD: {
        BODI: 'BODILY INJURY',
        LIAB: 'PROPERTY DAMAGE',
        OTCA: 'OTHER',
      },
      PTCI: {
        BODI: 'BODILY INJURY',
        LIAB: 'PROPERTY DAMAGE',
        OTCA: 'OTHER',
        TRSM: 'TERRORISM',
      },
      ACFT: {
        ACFT: 'AIRCRAFT',
      },
      BURG: {
        BURG: 'BURGLARY',
      },
      CLPS: {
        CLPS: 'COLLAPSE',
      },
      CNTP: {
        CNTP: 'CONTAMINATION/ POLLUTION',
      },
      COLL: {
        COLL: 'COLLISION',
      },
      CRCK: {
        CRCK: 'CRACKING/RUPTURE',
      },
      ELEC: {
        ELEC: 'ELECTRIC CURRENT',
      },
      ERTH: {
        ERTH: 'EARTHQUAKE/MOVEMENT',
      },
      EXPL: {
        EXPL: 'EXPLOSION',
      },
      FDTY: {
        FDTY: 'FIDELITY',
      },
      FIRE: {
        FIRE: 'FIRE',
      },
      FLOD: {
        FLOD: 'FLOOD',
      },
      FOBJ: {
        FOBJ: 'FALLING OBJECTS',
      },
      FREZ: {
        FREZ: 'FREEZING',
      },
      GLSS: {
        GLSS: 'GLASS',
      },
      HAIL: {
        HAIL: 'HAIL',
      },
      LGHT: {
        LGHT: 'LIGHTNING',
      },
      LOBG: {
        LOBG: 'LOST BAGGAGE',
      },
      LVMT: {
        LVMT: 'LIVESTOCK MORTALITY',
      },
      MOLD: {
        MOLD: 'MOLD',
      },
      MYSD: {
        MYSD: 'MYSTERIOUS DISAPPEARANCE',
      },
      RIOT: {
        RIOT: 'RIOT/CIVIL COMMOTION',
      },
      ROBB: {
        ROBB: 'ROBBERY',
      },
      SINK: {
        SINK: 'SINKING',
      },
      SMOK: {
        SMOK: 'SMOKE',
      },
      SNKL: {
        SNKL: 'SINKHOLE',
      },
      SRTY: {
        SRTY: 'SURETY',
      },
      THFT: {
        THFT: 'THEFT',
      },
      TRSM: {
        TRSM: 'TERRORISM',
      },
      UPST: {
        UPST: 'UPSET, OVERTURN',
      },
      VAMM: {
        VAMM: 'VANDALISM AND MALICIOUS MISCHIEF',
      },
      VHCL: {
        VHCL: 'VEHICLES',
      },
      VOLC: {
        VOLC: 'VOLCANIC ERUPTION',
      },
      WATR: {
        WATR: 'WATER',
      },
      WIND: {
        WIND: 'WIND',
      },
      WISS: {
        WISS: 'WEIGHT ICE/SNOW/SLEET',
      },
      OTPR: {
        ACFT: 'AIRCRAFT',
        BURG: 'BURGLARY',
        CLPS: 'COLLAPSE',
        CNTP: 'CONTAMINATION/ POLLUTION',
        COLL: 'COLLISION',
        CRCK: 'CRACKING',
        ELEC: 'ELECTRIC CURRENT',
        ERTH: 'EARTHQUAKE',
        EXPL: 'EXPLOSION',
        FDTY: 'FIDELITY',
        FIRE: 'FIRE',
        FLOD: 'FLOOD',
        FOBJ: 'FALLING OBJECTS',
        FREZ: 'FREEZING',
        HAIL: 'HAIL',
        LGHT: 'LIGHTNING',
        OTPR: 'OTHER',
        RIOT: 'RIOT',
        ROBB: 'ROBBERY',
        SINK: 'SINKING',
        SMOK: 'SMOKE',
        SNKL: 'SINKHOLE',
        THFT: 'THEFT',
        TRSM: 'TERRORISM',
        UPST: 'UPSET, OVER TURN',
        VAMM: 'VANDALISM AND MALICIOUS MISCHIEF',
        VOLC: 'VOLCANIC ERUPTION',
        WIND: 'WIND',
        WISS: 'WEIGHT ICE/SNOW/SLEET',
      },
      HULL: {
        ACFT: 'AIRCRAFT',
        BURG: 'BURGLARY',
        CLPS: 'COLLAPSE',
        CNTP: 'CONTAMINATION/ POLLUTION',
        COLL: 'COLLISION',
        CRCK: 'CRACKING/RUPTURE',
        ELEC: 'ELECTRIC CURRENT',
        ERTH: 'EARTHQUAKE/MOVEMENT',
        EXPL: 'EXPLOSION',
        FIRE: 'FIRE',
        FLOD: 'FLOOD',
        FOBJ: 'FALLING OBJECTS',
        FREZ: 'FREEZING',
        GLSS: 'GLASS',
        HAIL: 'HAIL',
        LGHT: 'LIGHTNING',
        OTPR: 'OTHER',
        RIOT: 'RIOT',
        SINK: 'SINKING',
        SMOK: 'SMOKE',
        SNKL: 'SINKHOLE',
        THFT: 'THEFT',
        TRSM: 'TERRORISM',
        UPST: 'UPSET, OVER TURN',
        VAMM: 'VANDALISM & MALICIOUS MISCHIEF',
        VHCL: 'VEHICLES',
        VOLC: 'VOLCANIC ERUPTION',
        WATR: 'WATER',
        WIND: 'WIND',
        WISS: 'WEIGHT ICE/SNOW/SLEET',
      },
      MOTR: {
        ACFT: 'AIRCRAFT',
        CLPS: 'COLLAPSE',
        CNTP: 'CONTAMINATION/ POLLUTION',
        COLL: 'COLLISION',
        CRCK: 'CRACKING/RUPTURE',
        ELEC: 'ELECTRIC CURRENT',
        ERTH: 'EARTHQUAKE/MOVEMENT',
        EXPL: 'EXPLOSION',
        FIRE: 'FIRE',
        FLOD: 'FLOOD',
        FOBJ: 'FALLING OBJECTS',
        FREZ: 'FREEZING',
        HAIL: 'HAIL',
        LGHT: 'LIGHTNING',
        OTPR: 'OTHER',
        RIOT: 'RIOT',
        SINK: 'SINKING',
        SMOK: 'SMOKE',
        SNKL: 'SINKHOLE',
        THFT: 'THEFT',
        TRSM: 'TERRORISM',
        UPST: 'UPSET, OVER TURN',
        VAMM: 'VANDALISM & MALICIOUS MISCHIEF',
        VHCL: 'VEHICLES',
        VOLC: 'VOLCANIC ERUPTION',
        WATR: 'WATER',
        WIND: 'WIND',
        WISS: 'WEIGHT ICE/SNOW/SLEET',
      },
    },
  },
} as const;

export default LOSS_CAUSE_ISO_CODES;
