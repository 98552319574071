import React from 'react';

import SvgIcon from '../core/SvgIcon';

const ResetPasswordIcon = (props) => (
  <SvgIcon viewBox="0 0 24 24" fill="none" {...props}>
    <path d="M16.6176 10H20.5V6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M19.5 15.5C19 17 17.4552 20.3536 13.4204 20.8362C11.8915 21.1647 10.3066 20.9968 8.86629 20.3536C7.42593 19.7105 6.19477 18.621 5.32856 17.2231C4.46235 15.8251 4 14.1814 4 12.5C4 10.8186 4.46235 9.1749 5.32856 7.77693C6.19477 6.37897 7.42593 5.2895 8.86629 4.64636C10.3066 4.00322 11.8915 3.8353 13.4204 4.16384C14.9492 4.49237 16.3534 5.30261 17.4552 6.49205L20.5 9.76648"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.4558 11H10.0441C9.92225 11 9.82349 11.1119 9.82349 11.25V14.75C9.82349 14.8881 9.92225 15 10.0441 15H14.4558C14.5777 15 14.6764 14.8881 14.6764 14.75V11.25C14.6764 11.1119 14.5777 11 14.4558 11Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.7941 11V10C10.7941 9.73478 10.9475 9.48043 11.2205 9.29289C11.4935 9.10536 11.8638 9 12.2499 9C12.6361 9 13.0064 9.10536 13.2794 9.29289C13.5524 9.48043 13.7058 9.73478 13.7058 10V11"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default ResetPasswordIcon;
