import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { get } from 'lodash';

import Grid from '~/components/core/Atomic/Grid/Grid';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';

import { CLAIM_TYPES_CONFIGURATION, LOB_ISO_CODES } from '../../../../Types';
import { isLocaleRegionIsUs } from '../../../../Utils';
import AutocompleteFormik from '../../../AutocompleteFormik';
import CardDialog from '../../../CardDialog';
import CheckboxFormik from '../../../CheckboxFormik';
import useFormikChangeListener from '../../../core/Formik/FormikChangeListener';
import useOrganization from '../../../OrganizationContext';
import { useSysconfig } from '../../../SystemConfiguration/SystemConfigurationScreen';
import TextFieldFormik from '../../../TextFieldFormik';
import LobRadioFormik from '../../../TPA/LOB/LobRadioFormik';

import { useStyles } from '../../../../assets/styles';
import wizardStyles from '../../WizardUtils/WizardUtils.module.scss';
import styles from './LobStep.module.scss';

const LobStep = ({ organizationSupportedLobs }) => {
  const classes = useStyles();
  const { values, isSubmitting, setFieldValue } = useFormikContext();
  const { organization } = useSysconfig();
  const { claimNumberingScheme } = useOrganization();
  const { lobConfigurationsDict } = useLobConfiguration();

  const isClaimsMadeSupported = get(lobConfigurationsDict, [values?.lob?.name, 'is_claims_made_supported'], false);

  const subOrgsIds = organization.sub_organizations.map((subOrg) => subOrg.id);
  const disabled = isSubmitting || values.lob.name === '';

  useFormikChangeListener({
    listenForKeys: ['lob.name'],
    onChange: (newValues, _) => {
      const lob = get(newValues, 'lob.name');
      const isClaimsMadeSupportedLob = get(lobConfigurationsDict, [lob, 'is_claims_made_supported'], false);

      setFieldValue('lob.is_claims_made', isClaimsMadeSupportedLob);
    },
    runOnFirstRender: true,
  });

  return (
    <div className={wizardStyles.cardContainer}>
      <CardDialog title="Select Line of Business">
        <div className={styles.lobsContainer}>
          <LobRadioFormik
            subOrganizationIds={subOrgsIds}
            lobFieldId="lob.name"
            btnClassName={styles.btnClassName}
            disabled={isSubmitting}
            isAllLobs
            filterLobsFunc={(lob) =>
              CLAIM_TYPES_CONFIGURATION[lob]?.is_ga !== false &&
              !organizationSupportedLobs.some((orgSupportedLob) => orgSupportedLob.key === lob)
            }
          />
        </div>
        <Grid container spacing={4}>
          {!organization.sub_organizations_enabled && claimNumberingScheme.lob_prefix_toggle && (
            <Grid item xs={6}>
              <TextFieldFormik
                id="lob.claim_identifier"
                label="Set Line of Business Identifier"
                fullWidth
                className={classes.textField}
                disabled={disabled}
              />
            </Grid>
          )}
          {!organization.sub_organizations_enabled && isLocaleRegionIsUs() && (
            <>
              <Grid item xs={6}>
                <AutocompleteFormik
                  id="lob.lob_iso_code"
                  label="Line of Business ISO code"
                  options={Object.keys(LOB_ISO_CODES)}
                  getOptionLabel={(option) => `${LOB_ISO_CODES[option]} (${option})`}
                  sortAlphabetic
                  disabled={disabled}
                  className={classes.textField}
                  fullWidth
                />
              </Grid>

              {isClaimsMadeSupported && (
                <Grid item xs={6}>
                  <CheckboxFormik id="lob.is_claims_made" label="Claim Made Policy" disabled={disabled} fullWidth />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </CardDialog>
    </div>
  );
};

LobStep.propTypes = {
  organizationSupportedLobs: PropTypes.array.isRequired,
};

export default LobStep;
