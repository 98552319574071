import type { ReactElement, ReactNode } from 'react';
import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import { useStyles } from '~/assets/styles';
import SwitchPanel from '~/components/core/ConfigPanel/SwitchPanel';
import TooltipIcon from '~/components/core/TooltipIcon';
import { PencilIcon } from '~/components/icons';
import InlineIconButton from '~/components/InlineIconButton';

export interface EditConfigurationDialogComponentProps<T> {
  configuration?: T;
  onSubmit?: (values: Partial<T>) => Promise<void> | void;
  onCancel?: () => void;
}

export interface ConfigurableOptionProps<T> {
  isEnabled?: boolean;
  onChangeToggle: (isEnabled: boolean) => Promise<void> | void;
  configurationView: ReactNode;
  EditConfigurationDialogComponent?: <T>(props: EditConfigurationDialogComponentProps<T>) => ReactElement;
  onSubmitDialog?: (values: Partial<T>) => Promise<void> | void;
  configuration?: T | undefined;
  showOnly?: boolean;
}

const ConfigurableOption = <T,>({
  isEnabled,
  onChangeToggle,
  configurationView,
  EditConfigurationDialogComponent,
  onSubmitDialog,
  configuration,
  showOnly,
}: ConfigurableOptionProps<T>): ReactElement => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const classes = useStyles();

  const handleStatusChangeClicked = () => {
    if (!isEnabled && EditConfigurationDialogComponent && isEmpty(configuration)) {
      setIsDialogOpen(true);
      return;
    }

    onChangeToggle(!isEnabled);
  };

  return (
    <div className="mt-20">
      <SwitchPanel
        checked={!!isEnabled}
        onChange={handleStatusChangeClicked}
        showOnly={showOnly}
        label={
          <div className="flex flex-grow flex-nowrap">
            <div className="flex-grow">{configurationView}</div>
            {isEnabled && EditConfigurationDialogComponent && !isEmpty(configuration) ? (
              <div>
                <TooltipIcon title="Edit Configuration">
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/*@ts-ignore*/}
                  <InlineIconButton
                    icon={PencilIcon}
                    className={classes.hoverableNonFilledIcon}
                    onClick={() => setIsDialogOpen(true)}
                    disabled={showOnly}
                  />
                </TooltipIcon>
              </div>
            ) : null}
          </div>
        }
      />

      {isDialogOpen && EditConfigurationDialogComponent && (
        <EditConfigurationDialogComponent<T>
          configuration={configuration}
          onSubmit={onSubmitDialog}
          onCancel={() => setIsDialogOpen(false)}
        />
      )}
    </div>
  );
};

export default ConfigurableOption;
