import React from 'react';
import PropTypes from 'prop-types';

import { useCms } from '../../../hooks/useCms';
import ArrayMultiselectFieldWithChipsFormik from '../../ArrayMultiselectFieldWithChipsFormik';

const ALL_FAKE_ID = 0;
const ALL_OPTION = {
  id: ALL_FAKE_ID,
  label: 'All (inc. future sub-orgs)',
  value: ALL_FAKE_ID,
};

const SubOrgMultiSelectWithChipsFormik = ({ id, label, showOnly = false, disabled = false }) => {
  const { userOrganization } = useCms();
  const { sub_organizations } = userOrganization;
  const subOrganizations =
    sub_organizations?.map((sub_org) => ({
      id: sub_org?.id,
      label: sub_org?.name,
      value: sub_org?.id,
    })) || [];

  return (
    <ArrayMultiselectFieldWithChipsFormik
      showOnly={showOnly}
      disabled={disabled}
      id={id}
      label={label}
      options={subOrganizations}
      allOption={ALL_OPTION}
    />
  );
};

SubOrgMultiSelectWithChipsFormik.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  showOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};

SubOrgMultiSelectWithChipsFormik.ALL_OPTION = ALL_OPTION;

export const isAllSubOrganizationsSelected = (ids) => ids.includes(SubOrgMultiSelectWithChipsFormik.ALL_OPTION.id);

export default SubOrgMultiSelectWithChipsFormik;
