import React from 'react';
import axios from 'axios';

import { reportAxiosError } from '../../../Utils';
import useDataFetcher from '../../useDataFetcher';
import { useSysconfig } from '../SystemConfigurationScreen';

import CoveragesConfigurations from './CoveragesConfigurations';

const ExposureConfigurationToolBackendWrapper = () => {
  const {
    organization,
    organizationOperationalDetails: { supportedClaimTypes, coverageConfiguration },
    reloadOperationalDetails,
  } = useSysconfig();

  const baseCoveragesRoute = `/sysconfig/api/v1/organizations/${organization.id}/coverages`;
  const { isLoading, isError, data: coverages, reloadData: reloadCoverages } = useDataFetcher(baseCoveragesRoute);

  const baseSharedCoveragesRoute = `/sysconfig/api/v1/organizations/${organization.id}/shared_coverages`;
  const {
    isLoadingShared,
    isErrorShared,
    data: sharedCoverages,
    reloadData: reloadSharedCoverages,
  } = useDataFetcher(baseSharedCoveragesRoute);

  const callAPI = async (method, url, values) => {
    await axios({ method, url, data: values });
  };

  const callAPIWithCatch = async (method, url, values) => {
    try {
      await callAPI(method, url, values);
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const handleCreateCoverage = async (values) => {
    await callAPIWithCatch('post', baseCoveragesRoute, values);
    await reloadCoverages();
  };

  const handleUpdateCoverage = async (values, id) => {
    await callAPIWithCatch('patch', `${baseCoveragesRoute}/${id}`, values);
    await reloadCoverages();
  };

  const handleCreateSharedCoverage = async (values) => {
    await callAPIWithCatch('post', baseSharedCoveragesRoute, values);
    await reloadSharedCoverages();
  };

  const handleUpdateSharedCoverage = async (values, id) => {
    await callAPIWithCatch('patch', `${baseSharedCoveragesRoute}/${id}`, values);
    await reloadSharedCoverages();
  };

  const handleToggleGeneralExpenses = async () => {
    try {
      await axios.post(`${baseCoveragesRoute}/configuration/general_expenses_config`, {
        is_general_expenses_disabled: !coverageConfiguration?.is_general_expenses_disabled,
      });
      await reloadOperationalDetails();
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const handleUpdateCoverageConfiguration = async (fieldName, checked) => {
    try {
      await axios.patch(`${baseCoveragesRoute}/configuration`, { [fieldName]: checked });
      await reloadOperationalDetails();
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const fetchKeys = (payload) =>
    axios.get(`/sysconfig/api/v1/organizations/${organization.id}/coverages/keys_used`, { params: payload });

  const handleFetchUsedSharedCoveragesKeys = ({ shared_coverage_config_ids_to_ignore }) =>
    fetchKeys({
      shared_coverage_config_ids_to_ignore,
    });

  const handleFetchUsedCoveragesKeys = ({ coverage_config_ids_to_ignore }) =>
    fetchKeys({
      coverage_config_ids_to_ignore,
    });

  const handleFetchCoverages = async ({ subOrganizationIds, lob }) => {
    const { data } = await axios.get(`/api/v1/organizations/${organization.id}/coverage_configs`, {
      params: { sub_organization_ids: subOrganizationIds, lob },
    });
    return data;
  };

  return (
    <CoveragesConfigurations
      isLoading={(isLoading && !coverages) || isLoadingShared}
      isError={isError || isErrorShared}
      organization={organization}
      supportedClaimTypes={supportedClaimTypes}
      coverages={coverages}
      sharedCoverages={sharedCoverages}
      coverageConfiguration={coverageConfiguration}
      onUpdateCoverage={handleUpdateCoverage}
      onCreateCoverage={handleCreateCoverage}
      onUpdateSharedCoverage={handleUpdateSharedCoverage}
      onCreateSharedCoverage={handleCreateSharedCoverage}
      onToggleGeneralExpenses={handleToggleGeneralExpenses}
      onUpdateCoverageConfiguration={handleUpdateCoverageConfiguration}
      fetchUsedCoveragesKeys={handleFetchUsedCoveragesKeys}
      fetchUsedSharedCoveragesKeys={handleFetchUsedSharedCoveragesKeys}
      fetchCoverages={handleFetchCoverages}
      errorHandler={reportAxiosError}
    />
  );
};
export { ExposureConfigurationToolBackendWrapper };
