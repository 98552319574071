import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';

import Button from '~/components/core/Atomic/Buttons/Button';
import CancelButton from '~/components/core/Buttons/CancelButton';

import CardDialog from '../../../../CardDialog';
import mixpanel from '../../../../CmsMain/mixpanel';
import { ConfirmModal } from '../../../../ConfirmModal';
import { Text } from '../../../../core';
import ConfirmDialogContent from '../../../Users/UserDialogs/UserPermissionsSection/ConfirmDialogContent';
import { safeMixpanelTrack } from '../MixpanelEvents';

import { useSetAllTouched } from './formUtils';

import styles from './styles.module.scss';

export const ProfileDialogBase = ({
  title,
  subtitle,
  onClose,
  mixpanelConfig,
  topActions,
  withActions,
  children,
  primaryWithConfirmProps,
  viewOnly,
  setAllTouchedOnSubmit,
}) => {
  const { isSubmitting, submitForm, touched, validateForm } = useFormikContext();
  const [closeConfirmModalOpen, setCloseConfirmModalOpen] = React.useState(false);
  const [submitConfirmModalOpen, setSubmitConfirmModalOpen] = React.useState(false);

  const setAllTouched = useSetAllTouched();

  const handleClose = React.useCallback(() => {
    if (!viewOnly && !isEmpty(touched)) {
      setCloseConfirmModalOpen(true);
    } else {
      onClose();
    }
  }, [onClose, touched, viewOnly]);

  const handleCloseConfirm = React.useCallback(
    (element) => {
      if (mixpanelConfig) {
        mixpanel.track(mixpanelConfig?.closeConfirmEventName, {
          ...mixpanelConfig?.closeConfirmAdditionalData,
          element,
        });
      }
      setCloseConfirmModalOpen(false);
      onClose();
    },
    [mixpanelConfig, onClose]
  );

  const handleSubmitClick = React.useCallback(async () => {
    if (setAllTouchedOnSubmit) {
      setAllTouched();
    }
    if (mixpanelConfig?.submitConfirmDialogPresentedEventName) {
      safeMixpanelTrack(
        mixpanelConfig.submitConfirmDialogPresentedEventName,
        mixpanelConfig.submitConfirmDialogPresentedAdditionalData
      );
    }
    const errors = await validateForm();
    if (!primaryWithConfirmProps?.disableConfirm && isEmpty(errors)) {
      setSubmitConfirmModalOpen(true);
    }
  }, [setAllTouchedOnSubmit, setAllTouched, mixpanelConfig, primaryWithConfirmProps?.disableConfirm, validateForm]);

  const handleSubmitConfirm = async () => {
    setSubmitConfirmModalOpen(false);
    await submitForm();
  };

  const actions = React.useMemo(
    () =>
      withActions && (
        <div className={styles.actionsContainer}>
          <CancelButton disabled={isSubmitting} onClick={handleClose} />
          <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmitClick}>
            Save
          </Button>
        </div>
      ),
    [handleClose, handleSubmitClick, isSubmitting, withActions]
  );

  return (
    <>
      <CardDialog
        isDialog
        open
        title={title}
        action={topActions}
        subheader={
          subtitle && (
            <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR} colorVariant={Text.COLOR_VARIANTS.SECONDARY}>
              {subtitle}
            </Text>
          )
        }
        maxWidth="md"
        onClose={handleClose}
        containerClassName={styles.dialogContentContainer}
        footerActions={actions}
      >
        {children}
      </CardDialog>
      <ConfirmModal
        title=""
        onClose={() => setCloseConfirmModalOpen(false)}
        isOpen={closeConfirmModalOpen}
        primaryButtonName="Yes"
        onPrimaryBtnClick={handleCloseConfirm}
        maxWidth="lg"
        contentText={
          <ConfirmDialogContent
            title="Are you sure?"
            subtitle="You have unsaved changes. Are you sure you want to leave?"
          />
        }
      />
      {primaryWithConfirmProps && (
        <ConfirmModal
          title=""
          onClose={() => setSubmitConfirmModalOpen(false)}
          isOpen={submitConfirmModalOpen}
          primaryButtonName={primaryWithConfirmProps.primaryButtonName}
          onPrimaryBtnClick={handleSubmitConfirm}
          maxWidth={primaryWithConfirmProps.maxWidth}
          contentText={primaryWithConfirmProps.contentText}
        />
      )}
    </>
  );
};

ProfileDialogBase.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onClose: PropTypes.func,
  mixpanelConfig: PropTypes.shape({
    source: PropTypes.string,
    closeConfirmAdditionalData: PropTypes.object,
    closeConfirmEventName: PropTypes.string,
    submitConfirmDialogPresentedEventName: PropTypes.string,
    submitConfirmDialogPresentedAdditionalData: PropTypes.object,
  }),
  withActions: PropTypes.bool,
  topActions: PropTypes.node,
  children: PropTypes.node,
  primaryWithConfirmProps: PropTypes.object,
  viewOnly: PropTypes.bool,
  setAllTouchedOnSubmit: PropTypes.bool,
};
