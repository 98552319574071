import React from 'react';
import PropTypes from 'prop-types';

import AlertBanner from '~/components/core/AlertBanner';
import Grid from '~/components/core/Atomic/Grid/Grid';
import InnerCard from '~/components/core/Cards/InnerCard';

import CardDialog from '../../../../../CardDialog';
import { FsButton, SortableTable, Text } from '../../../../../core';
import LoadingIndicator from '../../../../../LoadingIndicator';
import useDataFetcher from '../../../../../useDataFetcher';
import { useSysconfig } from '../../../../SystemConfigurationScreen';

import styles from '../index.module.scss';

function getExportsWebhook(organization) {
  const port = window.location.port === 80 || !window.location.port ? '' : `:${window.location.port}`;
  return `https://${window.location.hostname}${port}/webhook/xactanalysis/${organization.external_id}/export`;
}

function XactAnalysisInfoDialog({ onClose, config, showConfigCreatedBanner }) {
  const { organization } = useSysconfig();

  const { isLoading, isError, data } = useDataFetcher(
    `/api/v1/xactanalysis/${organization.id}/configurations/${config.id}/export_webhook_credentials`
  );

  const operationalDetails = [
    {
      field: 'Exports Webhook URL',
      value: getExportsWebhook(organization),
    },
    {
      field: 'Exports Webhook Username',
      value: isLoading ? '...' : isError ? '<ERROR: failed to retrieve integration information>' : data.export_username,
      isLoading,
    },
    {
      field: 'Exports Webhook Password',
      value: isLoading ? '...' : isError ? '<ERROR: failed to retrieve integration information>' : data.export_password,
      isLoading,
    },
  ];

  const Actions = (
    <div className={styles.actionsContainer}>
      <FsButton variant="contained" color="primary" onClick={onClose}>
        OK
      </FsButton>
    </div>
  );

  return (
    <CardDialog
      containerClassName={styles.xactAnalysisDialog}
      title="Carrier Profile Operational Details"
      isDialog
      maxWidth="md"
      fullWidth
      onClose={onClose}
      footerActions={Actions}
    >
      {showConfigCreatedBanner && (
        <AlertBanner
          alertType={AlertBanner.ALERT_TYPES.DONE}
          note="New Carrier Profile was created successfully."
          withIcon
        />
      )}
      <InnerCard className={styles.withoutBackground}>
        <Grid container>
          <Grid item xs={12}>
            <Text variant={Text.VARIANTS.LG} weight={Text.WEIGHTS.SEMI_BOLD} className={styles.subtitle}>
              Exports Webhook Information
            </Text>
            <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
              For XactAnalysis to be able to send updates (exports) to Five Sigma, please send the following parameters
              to XactAnalysis staff, so they can configure the carrier&apos;s environment.
            </Text>
          </Grid>
          <Grid item xs={12}>
            <SortableTable
              columns={[
                {
                  id: 'field',
                  numeric: false,
                  label: 'Field',
                },
                {
                  id: 'value',
                  numeric: false,
                  label: 'Value',
                  specialCell: (item) =>
                    !item.isLoading ? (
                      item.value
                    ) : (
                      <Text variant={Text.VARIANTS.SM}>
                        <LoadingIndicator />
                      </Text>
                    ),
                },
              ]}
              hideHeader
              disableSortByUser
              keepRowsOrder
              rows={operationalDetails}
            />
          </Grid>
        </Grid>
      </InnerCard>
    </CardDialog>
  );
}

XactAnalysisInfoDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
  showConfigCreatedBanner: PropTypes.bool,
};

export default XactAnalysisInfoDialog;
