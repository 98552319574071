import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getIn, useFormikContext } from 'formik';
import _ from 'lodash';

import { reportAxiosError } from '../../../../Utils';
import useFormikChangeListener from '../../../core/Formik/FormikChangeListener';
import { MultiSelectTextFieldFormik } from '../../../TextFieldFormik';
import { useSysconfig } from '../../SystemConfigurationScreen';

import { FIELD_IDS } from './AddOrEditRuleDialog';

import { useStyles } from '../../../../assets/styles';

const InvolvedParties = ({ subOrganizationIds, lob, coverage, involvedPartiesFieldId, disabled, setIsLoading }) => {
  const { organization } = useSysconfig();
  const [optionsDict, setOptionsDict] = useState([]);
  const { setFieldValue, setFieldTouched, values } = useFormikContext();
  const classes = useStyles();

  const updateOptions = useCallback(
    (newOptions) => {
      setOptionsDict(newOptions);

      const currentValues = getIn(values, involvedPartiesFieldId);
      const intersection = _.intersection(Object.keys(newOptions), currentValues);
      if (!_.isEqual(currentValues, intersection)) {
        setFieldValue(involvedPartiesFieldId, intersection);
        setFieldTouched(involvedPartiesFieldId, false);
      }
    },
    [involvedPartiesFieldId, setFieldTouched, setFieldValue, values]
  );

  const loadOptions = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`/api/v1/automatic_exposures/${organization.id}/involved_parties_options`, {
        params: {
          sub_organization_ids: subOrganizationIds,
          lob,
          coverage,
        },
      });
      updateOptions(response.data);
    } catch (error) {
      await reportAxiosError(error);
    } finally {
      setIsLoading(false);
    }
  }, [coverage, lob, organization.id, setIsLoading, subOrganizationIds, updateOptions]);

  useFormikChangeListener({
    listenForKeys: [FIELD_IDS.COVERAGE_KEY],
    onChange: async () => {
      if (coverage) {
        await loadOptions();
      } else {
        updateOptions([]);
      }
    },
    runOnFirstRender: true,
  });

  return (
    <MultiSelectTextFieldFormik
      id={involvedPartiesFieldId}
      label="Involved Entities"
      options={Object.keys(optionsDict)}
      renderValue={(values) => values.map((value) => optionsDict[value]).join(', ')}
      className={classes.textField}
      fullWidth
      renderOption={(key) => optionsDict[key]}
      disabled={!coverage || _.isEmpty(optionsDict) || disabled}
      withOptionChips
    />
  );
};

InvolvedParties.propTypes = {
  subOrganizationIds: PropTypes.array,
  lob: PropTypes.string,
  coverage: PropTypes.string.isRequired,
  involvedPartiesFieldId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};

export default InvolvedParties;
