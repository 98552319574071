import React from 'react';
import PropTypes from 'prop-types';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';
import CancelButton from '~/components/core/Buttons/CancelButton';
import RadioButtonGroupFormik from '~/components/core/Formik/RadioButtonGroupFormik';
import SubOrgMultiSelectWithChipsFormik from '~/components/core/Formik/SubOrgMultiSelectWithChipsFormik';
import SwitchFormik from '~/components/core/Formik/SwitchFormik';
import TooltipIcon from '~/components/core/TooltipIcon';
import { InfoIcon } from '~/components/icons';
import { isFeatureEnabled } from '~/Utils';

import AdjusterSelectTextFieldFormik from '../../../Adjuster/AdjusterSelectTextFieldFormik';
import { MIXPANEL_EVENTS } from '../../../pocs/mixpanel';
import { CONFIGURATION_FEATURES_NAMES, DATA_EXPORT } from '../../../Types';
import CardDialog from '../../CardDialog';
import mixpanel from '../../CmsMain/mixpanel';
import AlertBanner from '../../core/AlertBanner';
import { useSysconfig } from '../SystemConfigurationScreen';

import { ATTENTION_NOTE, NON_CLAIM_COMMUNICATION_ATTENTION_NOTE } from './consts.json';

import { useStyles } from '../../../assets/styles';

const OneTimeExportDialog = ({ onClose, onSubmit }) => {
  const { organization } = useSysconfig();
  return (
    <Formik
      initialValues={{
        sub_organization_ids: [],
        format: DATA_EXPORT.EXPORT_FORMAT.csv.key,
        user_id: '',
        file_name_prefix: '',
        include_non_claim_communications: false,
      }}
      validationSchema={Yup.object().shape({
        sub_organization_ids: Yup.array().test('sub_orgs_ids', 'Required', (value) => {
          return organization.sub_organizations_enabled ? value?.length > 0 : true;
        }),
        format: Yup.string().required('Required'),
        user_id: Yup.string().required('Required'),
      })}
      enableReinitialize
      onSubmit={async (values) => {
        if (values?.sub_organization_ids?.includes(SubOrgMultiSelectWithChipsFormik.ALL_OPTION.id)) {
          values.sub_organization_ids = [];
          values.is_all_sub_orgs = true;
        }
        const submittedSuccessfully = await onSubmit(values);
        if (!submittedSuccessfully) {
          // reportAxiosError should have already been called in onSubmit
          return;
        }
        onClose();
        mixpanel.track(MIXPANEL_EVENTS.ONE_TIME_DATA_EXPORT_EMAIL_SUBMITTED, {
          sub_organization: values.sub_organization_id,
          format: values.format,
        });
      }}
    >
      <OneTimeExportDialogInner onClose={onClose} />
    </Formik>
  );
};

OneTimeExportDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const OneTimeExportDialogInner = ({ onClose }) => {
  const { organization, users } = useSysconfig();
  const filteredUsers = users.filter((user) => user.role.role_type !== 'system');
  const classes = useStyles();
  const { isSubmitting, handleSubmit } = useFormikContext();
  const { sub_organizations_enabled } = organization;

  const isAttachNonCommunicationEnabled = isFeatureEnabled(
    organization,
    CONFIGURATION_FEATURES_NAMES.DATA_EXPORT_ATTACH_NON_CLAIM_COMMUNICATION
  );

  return (
    <CardDialog
      isDialog
      title="One Time Email Export"
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      preventClose={isSubmitting}
    >
      <Grid container spacing={4} className="flex items-end">
        {sub_organizations_enabled && (
          <Grid item xs={6}>
            <Typography display="block" variant="subtitle1" className={classes.h4}>
              Sub Organizations
            </Typography>
            <SubOrgMultiSelectWithChipsFormik
              id="sub_organization_ids"
              label="Sub Organization"
              showOnly={isSubmitting}
              disabled={isSubmitting}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography display="block" variant="subtitle1" className={classes.h4}>
            Format
          </Typography>
          <RadioButtonGroupFormik
            id="format"
            direction="row"
            options={
              Object.keys(DATA_EXPORT.EXPORT_FORMAT).map((key) => ({
                text: DATA_EXPORT.EXPORT_FORMAT[key].desc,
                optionValue: DATA_EXPORT.EXPORT_FORMAT[key].key,
              })) || []
            }
            showOnly={isSubmitting}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography display="block" variant="subtitle1" className={classes.h4}>
            Email Address
          </Typography>
          <AdjusterSelectTextFieldFormik
            id="user_id"
            label="Select System User"
            className={classes.textField}
            fullWidth
            disabled={isSubmitting}
            sortAlphabetic
            allUsers={filteredUsers}
          />
        </Grid>
        <Grid item xs={12}>
          {isAttachNonCommunicationEnabled ? (
            <div className="inline-flex items-baseline">
              <SwitchFormik
                className={classes.formsSwitch}
                id="include_non_claim_communications"
                label="Include communications not attached to a claim in DE files"
                disabled={isSubmitting}
              />
              <TooltipIcon
                placement="top-start"
                arrow
                title="Communications not attached to any claim may contain sub-organization specific information."
              >
                <InfoIcon className="ml-12" size={18} />
              </TooltipIcon>
            </div>
          ) : null}
          <AlertBanner
            title="Please Note"
            note={isAttachNonCommunicationEnabled ? NON_CLAIM_COMMUNICATION_ATTENTION_NOTE : ATTENTION_NOTE}
            trackAlt="Scheduled Export Attention Note"
            alertType={AlertBanner.ALERT_TYPES.INFO}
          />
        </Grid>
        <div className={classes.buttonsContainer}>
          <CancelButton disabled={isSubmitting} onClick={onClose} />
          <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
            SEND
          </Button>
        </div>
      </Grid>
    </CardDialog>
  );
};

OneTimeExportDialogInner.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default OneTimeExportDialog;
