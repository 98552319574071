import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import Button from '~/components/core/Atomic/Buttons/Button';
import Typography from '~/components/core/Atomic/Typography';
import CancelButton from '~/components/core/Buttons/CancelButton';

import CardDialog from '../../../../CardDialog';
import { FIELD_IDS } from '../PhoneConfigurationTab';
import VoiceMessageConfigurationDialog from '../VoiceMessageConfigurationDialog/VoiceMessageConfigurationDialog';
import VoiceMessageDetailsPreview from '../VoiceMessageDetailsPreview/VoiceMessageDetailsPreview';

const FORM_IDS = {
  VOICEMAIL_TEXT_OR_RECORDED_MESSAGE: 'voicemail_text_or_recorded_message',
  VOICEMAIL_GREETING_TEXT: 'voicemail_greeting_text',
  VOICEMAIL_VOICE_FILE: 'voicemail_voice_file',
  OUT_OF_OFFICE_TEXT_OR_RECORDED_MESSAGE: 'out_of_office_text_or_recorded_message',
  OUT_OF_OFFICE_GREETING_TEXT: 'out_of_office_greeting_text',
  OUT_OF_OFFICE_VOICE_FILE: 'out_of_office_voice_file',
};

const EditVoicemailConfigDialog = ({ configuration, onCancel, onSubmit }) => {
  const classes = useStyles();
  const [allowSavingVoicemailWithoutNewFile, setAllowSavingVoicemailWithoutNewFile] = React.useState(
    !!configuration?.voicemail?.permanent_audio_file_url
  );

  const [allowSavingOutOfOfficeWithoutNewFile, setAllowSavingOutOfOfficeWithoutNewFile] = React.useState(
    !!configuration?.outOfOffice?.permanent_audio_file_url
  );

  const handleSubmit = async (values, formikProps) => {
    try {
      const formData = new FormData();
      formData.append('voicemail_configuration_type', values[FORM_IDS.VOICEMAIL_TEXT_OR_RECORDED_MESSAGE]);
      formData.append('voicemail_file', values[FORM_IDS.VOICEMAIL_VOICE_FILE]);
      formData.append('voicemail_text_message', values[FORM_IDS.VOICEMAIL_GREETING_TEXT]);
      formData.append('out_of_office_configuration_type', values[FORM_IDS.OUT_OF_OFFICE_TEXT_OR_RECORDED_MESSAGE]);
      formData.append('out_of_office_file', values[FORM_IDS.OUT_OF_OFFICE_VOICE_FILE]);
      formData.append('out_of_office_text_message', values[FORM_IDS.OUT_OF_OFFICE_GREETING_TEXT]);
      await onSubmit(formData);
      onCancel();
    } catch {
      formikProps.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={
        isEmpty(configuration)
          ? {
              [FORM_IDS.VOICEMAIL_TEXT_OR_RECORDED_MESSAGE]: 'text_message',
              [FORM_IDS.VOICEMAIL_GREETING_TEXT]: '',
              [FORM_IDS.VOICEMAIL_VOICE_FILE]: undefined,
              [FORM_IDS.OUT_OF_OFFICE_TEXT_OR_RECORDED_MESSAGE]: 'text_message',
              [FORM_IDS.OUT_OF_OFFICE_GREETING_TEXT]: '',
              [FORM_IDS.OUT_OF_OFFICE_VOICE_FILE]: undefined,
            }
          : {
              [FORM_IDS.VOICEMAIL_TEXT_OR_RECORDED_MESSAGE]: configuration?.voicemail?.configuration_type,
              [FORM_IDS.VOICEMAIL_GREETING_TEXT]: configuration?.voicemail?.message_text || '',
              [FORM_IDS.VOICEMAIL_VOICE_FILE]: undefined,
              [FORM_IDS.OUT_OF_OFFICE_TEXT_OR_RECORDED_MESSAGE]: configuration?.outOfOffice?.configuration_type,
              [FORM_IDS.OUT_OF_OFFICE_GREETING_TEXT]: configuration?.outOfOffice?.message_text || '',
              [FORM_IDS.OUT_OF_OFFICE_VOICE_FILE]: undefined,
            }
      }
      validationSchema={Yup.object().shape({
        [FORM_IDS.VOICEMAIL_TEXT_OR_RECORDED_MESSAGE]: Yup.string()
          .oneOf(['text_message', 'uploaded_text_message'])
          .required(),
        [FORM_IDS.VOICEMAIL_GREETING_TEXT]: Yup.string().when([FORM_IDS.VOICEMAIL_TEXT_OR_RECORDED_MESSAGE], {
          is: 'text_message',
          then: Yup.string().required('Please enter a message'),
        }),
        [FORM_IDS.VOICEMAIL_VOICE_FILE]: Yup.mixed().when([FORM_IDS.VOICEMAIL_TEXT_OR_RECORDED_MESSAGE], {
          is: 'uploaded_text_message',
          then: allowSavingVoicemailWithoutNewFile ? Yup.mixed() : Yup.mixed().required('Please select a file'),
        }),
        [FORM_IDS.OUT_OF_OFFICE_TEXT_OR_RECORDED_MESSAGE]: Yup.string()
          .oneOf(['text_message', 'uploaded_text_message'])
          .required(),
        [FORM_IDS.OUT_OF_OFFICE_GREETING_TEXT]: Yup.string().when([FORM_IDS.OUT_OF_OFFICE_TEXT_OR_RECORDED_MESSAGE], {
          is: 'text_message',
          then: Yup.string().required('Please enter a message'),
        }),
        [FORM_IDS.OUT_OF_OFFICE_VOICE_FILE]: Yup.mixed().when([FORM_IDS.OUT_OF_OFFICE_TEXT_OR_RECORDED_MESSAGE], {
          is: 'uploaded_text_message',
          then: allowSavingOutOfOfficeWithoutNewFile ? Yup.mixed() : Yup.mixed().required('Please select a file'),
        }),
      })}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, handleSubmit }) => (
        <CardDialog
          title="Voicemail Configuration"
          isDialog
          maxWidth="sm"
          fullWidth
          onClose={onCancel}
          preventClose={isSubmitting}
        >
          <VoiceMessageConfigurationDialog
            title="Default voicemail message"
            voiceMessageConfiguration={configuration?.voicemail}
            textAreaLabel="Voicemail greeting"
            textAreaId={FORM_IDS.VOICEMAIL_GREETING_TEXT}
            voiceFileInputId={FORM_IDS.VOICEMAIL_VOICE_FILE}
            radioButtonGroupId={FORM_IDS.VOICEMAIL_TEXT_OR_RECORDED_MESSAGE}
            allowedTokens={{
              adjuster_name: {
                desc: 'Adjuster Name',
              },
            }}
            onReplaceExistingRecording={() => {
              setAllowSavingVoicemailWithoutNewFile(false);
            }}
          />
          <div className="mt-12" />
          <VoiceMessageConfigurationDialog
            title="Out of office voicemail message"
            voiceMessageConfiguration={configuration?.outOfOffice}
            textAreaLabel="Out of office greeting"
            textAreaId={FORM_IDS.OUT_OF_OFFICE_GREETING_TEXT}
            voiceFileInputId={FORM_IDS.OUT_OF_OFFICE_VOICE_FILE}
            radioButtonGroupId={FORM_IDS.OUT_OF_OFFICE_TEXT_OR_RECORDED_MESSAGE}
            allowedTokens={{
              adjuster_name: {
                desc: 'Adjuster Name',
              },
              date_of_return: {
                desc: 'Date of Return',
              },
            }}
            onReplaceExistingRecording={() => {
              setAllowSavingOutOfOfficeWithoutNewFile(false);
            }}
          />

          <div className={classes.buttonsContainer}>
            <CancelButton disabled={isSubmitting} onClick={onCancel} />
            <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </CardDialog>
      )}
    </Formik>
  );
};

EditVoicemailConfigDialog.propTypes = {
  configuration: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

const VoicemailConfigView = ({ twilioConfiguration }) => {
  return (
    <>
      <Typography variant="subtitle1">Configure voicemail settings</Typography>
      <Typography variant="caption">
        Upload a voice message or add a text message for the voicemail greeting played when you are busy on another call
        or out of office.
      </Typography>
      {twilioConfiguration[FIELD_IDS.VOICEMAIL_MESSAGE_ENABLED] &&
      !isEmpty(twilioConfiguration[FIELD_IDS.VOICEMAIL_MESSAGE_CONFIGURATION]) ? (
        <div className="mt-20">
          <VoiceMessageDetailsPreview
            configurationType={twilioConfiguration[FIELD_IDS.VOICEMAIL_MESSAGE_CONFIGURATION].configuration_type}
            messageTextTitle="Voicemail by text message"
            messageText={twilioConfiguration[FIELD_IDS.VOICEMAIL_MESSAGE_CONFIGURATION].message_text}
            uploadedTextMessageTitle="Voicemail by recording"
            audioUrl={twilioConfiguration[FIELD_IDS.VOICEMAIL_MESSAGE_CONFIGURATION].permanent_audio_file_url}
          />
          <div className="mt-12" />
          <VoiceMessageDetailsPreview
            configurationType={twilioConfiguration[FIELD_IDS.OUT_OF_OFFICE_MESSAGE_CONFIGURATION].configuration_type}
            messageTextTitle="Out of office by text message"
            messageText={twilioConfiguration[FIELD_IDS.OUT_OF_OFFICE_MESSAGE_CONFIGURATION].message_text}
            uploadedTextMessageTitle="Out of office  by recording"
            audioUrl={twilioConfiguration[FIELD_IDS.OUT_OF_OFFICE_MESSAGE_CONFIGURATION].permanent_audio_file_url}
          />
        </div>
      ) : null}
    </>
  );
};

VoicemailConfigView.propTypes = {
  twilioConfiguration: PropTypes.object.isRequired,
};

export { EditVoicemailConfigDialog, VoicemailConfigView };
