const basePath = (organizationId) => `/sysconfig/api/v1/organizations/${organizationId}/permissions/profiles`;
const baseProfile = (organizationId, profileId) => `${basePath(organizationId)}/${profileId}`;

export const PROFILES_ROUTES = {
  ACTIVE: (organizationId) => `${basePath(organizationId)}`,
  CREATE: basePath,
  UPDATE: baseProfile,
  DELETE: baseProfile,
  STATUS: (organizationId, profileId) => `${baseProfile(organizationId, profileId)}/status`,
  USERS: (organizationId, profileId) => `${baseProfile(organizationId, profileId)}/users`,
};
