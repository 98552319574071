import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';
import { AddIcon } from '~/components/deprecatedMuiIcons';

import { reportAxiosError } from '../../../Utils';
import CardDialog from '../../CardDialog';
import WithConfirm from '../../ConfirmModal';
import { SortableTable } from '../../core';
import PencilIcon from '../../icons/PencilIcon';
import InlineIconButton from '../../InlineIconButton';
import LoadingIndicator from '../../LoadingIndicator';
import useDataFetcher from '../../useDataFetcher';

import styles, { useStyles } from '../../../assets/styles';

const OrganizationEntityConfiguration = ({
  entityLabelSingular,
  entityLabelPlural,
  generateFetchUrl,
  generateUpdateUrl = generateFetchUrl,
  generateDeleteUrl = generateFetchUrl,
  getExtractedData,
  columnData,
  getEntityInitialValues,
  getValidationSchema,
  getEditInputs,
  creatable = true,
  editable = false,
  deletable = true,
}) => {
  const [showEditEntityDialog, setShowEditEntityDialog] = useState(false);
  const [entityToEdit, setEntityToEdit] = useState(null);
  const classes = useStyles();

  const { isLoading, isError, data: entities, reloadData: reloadEntities } = useDataFetcher(generateFetchUrl());
  if (isLoading || isError) {
    return <LoadingIndicator isError={isError} />;
  }

  const editEntityDialogState = (isDialogOpen, entity) => {
    setEntityToEdit(isDialogOpen ? entity : null);
    setShowEditEntityDialog(isDialogOpen);
  };

  const handleEditEntityRequest = (entity) => editEntityDialogState(true, entity);
  const handleEditEntityCancel = () => editEntityDialogState(false, null);
  const handleEditEntitySave = async (values) => {
    const method = entityToEdit ? axios.put : axios.post;
    const url = entityToEdit ? generateUpdateUrl(values) : generateFetchUrl();
    await handleEntityAction(method, url, values);
  };
  const handleEntityDelete = async (values) => {
    await handleEntityAction(axios.delete, generateDeleteUrl(values), values);
  };

  const handleEntityAction = async (axiosMethod, url, values) => {
    try {
      const data = getExtractedData(values);
      const payload = axiosMethod === axios.delete ? { data } : data;
      await axiosMethod(url, payload);
      await reloadEntities();
      editEntityDialogState(false, null);
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  const EditCell = (entity, isHover) => (
    <span style={{ visibility: isHover ? 'visible' : 'hidden' }}>
      {editable && (
        <InlineIconButton
          icon={PencilIcon}
          className={classes.textIcon}
          onClick={() => handleEditEntityRequest(entity)}
        />
      )}
      {deletable && (
        <WithConfirm title={`Delete ${entityLabelSingular}?`} primaryButtonName="Delete" shouldCloseOnPrimary={true}>
          <InlineIconButton icon={DeleteIcon} className={classes.textIcon} onClick={() => handleEntityDelete(entity)} />
        </WithConfirm>
      )}
    </span>
  );

  const columnDataWithActions = [...columnData, { id: 'edit', disableSort: true, specialCell: EditCell }];

  const ActionButton = creatable ? (
    <Button color="primary" onClick={() => setShowEditEntityDialog(true)}>
      <AddIcon className={classes.leftButtonIcon} />
      Add {entityLabelSingular}
    </Button>
  ) : null;

  return (
    <>
      <CardDialog title={entityLabelPlural} action={ActionButton}>
        <SortableTable columns={columnDataWithActions} rows={entities} stickyHeader />
      </CardDialog>

      {showEditEntityDialog && (
        <EditEntityDialogStyled
          entityLabelSingular={entityLabelSingular}
          getEntityInitialValues={getEntityInitialValues}
          getValidationSchema={getValidationSchema}
          getEditInputs={getEditInputs}
          entity={entityToEdit}
          onSave={handleEditEntitySave}
          onCancel={handleEditEntityCancel}
        />
      )}
    </>
  );
};

OrganizationEntityConfiguration.propTypes = {
  generateFetchUrl: PropTypes.func.isRequired,
  generateUpdateUrl: PropTypes.func,
  generateDeleteUrl: PropTypes.func,
  entityLabelSingular: PropTypes.string.isRequired,
  entityLabelPlural: PropTypes.string.isRequired,
  getExtractedData: PropTypes.func.isRequired,
  columnData: PropTypes.array.isRequired,
  getEntityInitialValues: PropTypes.func.isRequired,
  getValidationSchema: PropTypes.func.isRequired,
  getEditInputs: PropTypes.func.isRequired,
  creatable: PropTypes.bool,
  editable: PropTypes.bool,
  deletable: PropTypes.bool,
};

const EditEntityDialog = ({
  entity,
  entityLabelSingular,
  getEntityInitialValues,
  getValidationSchema,
  getEditInputs,
  onSave,
  onCancel,
}) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={getEntityInitialValues(entity)}
      validationSchema={Yup.object().shape(getValidationSchema(entity))}
      enableReinitialize
      onSubmit={(values, formikProps) => {
        onSave(values).catch(() => {
          formikProps.setSubmitting(false);
        });
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit, errors } = formikProps;
        const title = entity ? `Edit ${entityLabelSingular}` : `Add ${entityLabelSingular}`;

        return (
          <CardDialog isDialog title={title} maxWidth="sm" fullWidth onClose={onCancel} preventClose={isSubmitting}>
            <Grid container>
              {getEditInputs(entity, formikProps)}
              <Grid item xs={12}>
                <div className={classes.buttonsContainer}>
                  <CancelButton disabled={isSubmitting} onClick={onCancel} />
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting || Object.keys(errors).length > 0}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </div>
              </Grid>
            </Grid>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

EditEntityDialog.propTypes = {
  entity: PropTypes.object.isRequired,
  entityLabelSingular: PropTypes.string.isRequired,
  getEntityInitialValues: PropTypes.object.isRequired,
  getValidationSchema: PropTypes.func.isRequired,
  getEditInputs: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const EditEntityDialogStyled = withStyles(styles)(EditEntityDialog);

export default withStyles(styles)(OrganizationEntityConfiguration);
