import React from 'react';
import PropTypes from 'prop-types';
import { Formik, useFormikContext } from 'formik';
import { snakeCase } from 'lodash';
import * as Yup from 'yup';

import Grid from '~/components/core/Atomic/Grid/Grid';
import DialogFooterActions from '~/components/core/DialogFooterActions';

import { reportAxiosError } from '../../../Utils';
import CardDialog from '../../CardDialog';
import { Text } from '../../core';
import { useShouldDisableFormikField } from '../../hooks/useShouldDisableFormikField';
import { TextFieldFormik, useSetDefaultFieldsOnChange } from '../../TextFieldFormik';

import { useStyles } from '../../../assets/styles';

const CoverageGroupDialog = ({ coverageGroup, onSubmit, onClose, fetchUsedCoverageGroupKeys }) => {
  return (
    <Formik
      initialValues={{
        display_name: coverageGroup?.display_name || '',
        coverage_group_key: coverageGroup?.coverage_group_key || '',
        description: coverageGroup?.description || '',
      }}
      validationSchema={Yup.object().shape({
        display_name: Yup.string().max(50).required('Required'),
        coverage_group_key: Yup.string()
          .max(50)
          .required('Required')
          .test('coverage_group_key_unique', 'Must be unique', async function (value) {
            const keysUsedRet = await fetchUsedCoverageGroupKeys({
              ids_to_ignore: coverageGroup ? [coverageGroup.id] : undefined,
            });
            return !keysUsedRet.data.includes(value);
          }),
        description: Yup.string().max(250),
      })}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values, coverageGroup?.id);
          onClose();
        } catch (error) {
          await reportAxiosError(error);
          setSubmitting(false);
        }
      }}
    >
      <CoverageGroupDialogInner coverageGroup={coverageGroup} onClose={onClose} />
    </Formik>
  );
};

CoverageGroupDialog.propTypes = {
  coverageGroup: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  fetchUsedCoverageGroupKeys: PropTypes.func.isRequired,
};

export const CoverageGroupDialogInner = ({ coverageGroup, onClose }) => {
  const classes = useStyles();
  const { isSubmitting, handleSubmit, values, setFieldTouched } = useFormikContext();

  useSetDefaultFieldsOnChange(
    values.display_name,
    {
      coverage_group_key: snakeCase(values.display_name),
    },
    () => ['coverage_group_key'].forEach((field) => setFieldTouched(field, true)),
    !!coverageGroup,
    true
  );

  const shouldDisableFieldFunction = useShouldDisableFormikField();

  return (
    <CardDialog
      isDialog
      title={`${coverageGroup ? 'Edit' : 'Add '} Coverage Group`}
      fullWidth
      maxWidth="xs"
      onClose={onClose}
      preventClose={isSubmitting}
      footerActions={
        <DialogFooterActions disabled={isSubmitting} onClickPrimary={handleSubmit} onClickSecondary={onClose} />
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Text weight={Text.WEIGHTS.SEMI_BOLD}>Details</Text>
        </Grid>
        <Grid item xs={6}>
          <TextFieldFormik
            id="display_name"
            label="Group Name"
            disabled={shouldDisableFieldFunction()}
            className={classes.formTextField}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldFormik
            id="coverage_group_key"
            label="Coverage Group Key"
            disabled={shouldDisableFieldFunction(coverageGroup)}
            className={classes.formTextField}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldFormik
            id="description"
            label="Description"
            disabled={shouldDisableFieldFunction()}
            className={classes.formTextField}
            fullWidth
          />
        </Grid>
      </Grid>
    </CardDialog>
  );
};

CoverageGroupDialogInner.propTypes = {
  coverageGroup: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

export default CoverageGroupDialog;
