import type { ChangeEvent } from 'react';
import React from 'react';
import { getIn, useFormikContext } from 'formik';
import isArray from 'lodash/isArray';

import executeOrDelegate from '~/Utils/executeOrDelegate';

import CheckboxWithIcon from '../CheckboxWithIcon';

interface CheckboxWithButtonWrapperFormikProps {
  icon?: React.ReactNode;
  text: string;
  value: string;
  disabled?: boolean;
  id: string;
  onChecked?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeWrapper?: (func: () => void) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any; // For other props - should be removed after validating no unknown props are passed
}

const CheckboxWithButtonWrapperFormik: React.FC<CheckboxWithButtonWrapperFormikProps> = ({
  icon,
  text,
  value,
  disabled,
  id,
  onChecked,
  handleChangeWrapper, // accepts a change func that can be called or not after performing other logic
  ...rest
}) => {
  const { setFieldValue, values } = useFormikContext();

  const onClick = (event: ChangeEvent<HTMLInputElement>) => {
    executeOrDelegate({
      primaryTask: () => {
        const existingValues = getIn(values, id);

        if (isArray(existingValues)) {
          const currValue = [...existingValues];
          const checked = !(currValue.indexOf(value) > -1);
          if (checked) {
            currValue.push(value);
          } else {
            currValue.splice(currValue.indexOf(value), 1);
          }

          setFieldValue(id, currValue);
        } else {
          setFieldValue(id, !existingValues);
        }

        if (onChecked) {
          onChecked(event);
        }
      },
      wrapperFunction: handleChangeWrapper,
    });
  };

  const isChecked = (value: string) => {
    const existingValues = getIn(values, id);

    if (isArray(existingValues)) {
      return existingValues.indexOf(value) > -1;
    }

    return existingValues;
  };

  return (
    <CheckboxWithIcon
      icon={icon}
      text={text}
      disabled={disabled}
      checked={isChecked(value)}
      onChange={(event: ChangeEvent<HTMLInputElement>) => !disabled && onClick(event)}
      {...rest}
    />
  );
};

export default CheckboxWithButtonWrapperFormik;
