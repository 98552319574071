import React from 'react';

import mixpanel from '~/components/CmsMain/mixpanel';
import { FNOL_MIXPANEL_EVENTS } from '~/pocs/mixpanel';

import ConfigurationPage, { InternalConfiguration } from '../ConfigurationPage/ConfigurationPage';
import type { IncidentConfigurationSummary } from '../ConfigurationPage/types';

import copies from './FnolConfigurationScreen/copies.json';
import FnolConfigurationScreen from './FnolConfigurationScreen/FnolConfigurationScreen';

const trackMixpanelEvent = (IncidentConfiguration: IncidentConfigurationSummary) => {
  mixpanel.track(FNOL_MIXPANEL_EVENTS.EDIT_FNOL_CONFIGURATION_CLICKED, {
    incident_configuration_id: IncidentConfiguration?.id,
    sub_organization_id: IncidentConfiguration?.sub_organization_id,
  });
};

const FnolConfiguration: React.FC = () => {
  return (
    <ConfigurationPage
      configurationTitle="FNOL Configuration"
      configurationSubtitle={copies.configuration_subtitle}
      externalAdminRoute="/fnol_configuration"
      ConfigurationScreen={FnolConfigurationScreen}
      searchPlaceholder="Search FNOL"
      counterSuffix="FNOLs"
      displayName="FNOL Name"
      trackMixpanelEvent={trackMixpanelEvent}
    />
  );
};

const InternalFnolConfiguration: React.FC = () => (
  <div className="absolute inset-0 overflow-scroll">
    <InternalConfiguration
      configurationTitle="FNOL Configuration"
      configurationSubtitle={copies.configuration_subtitle}
      externalAdminRoute="/fnol_configuration"
      ConfigurationScreen={FnolConfigurationScreen}
      searchPlaceholder="Search FNOL"
      counterSuffix="FNOLs"
      displayName="FNOL Name"
      trackMixpanelEvent={trackMixpanelEvent}
    />
  </div>
);

export { InternalFnolConfiguration };
export default FnolConfiguration;
