import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import Grid from '~/components/core/Atomic/Grid/Grid';

import { reportErrorInProductionOrThrow } from '../../../../Utils';
import { ErrorHelperTextFormik } from '../../../core/Formik/ErrorHelperTextFormik';
import useOrganization from '../../../OrganizationContext';
import { ExposureConfigurationTool } from '../../../SystemConfiguration/ExposureConfiguration/ExposureConfiguration';
import { useSysconfig } from '../../../SystemConfiguration/SystemConfigurationScreen';
import useDataFetcher from '../../../useDataFetcher';

import wizardStyles from '../../WizardUtils/WizardUtils.module.scss';

const ExposureConfigurationToolLobWizardFormikWrapper = ({ supportedLob, hideExposureConfigurationFilters }) => {
  const { values, setFieldValue } = useFormikContext();
  const { organization } = useSysconfig();
  const { subOrganizations, coverageConfiguration } = useOrganization();
  const {
    isLoading,
    isError,
    data: existingCoverageKeys,
  } = useDataFetcher(`/sysconfig/api/v1/organizations/${organization.id}/coverages/keys_used`);

  const prepareCoverages = (coverages) => {
    return coverages?.map((coverage) => ({
      ...coverage,
      sub_organizations: coverage.sub_organization_ids.map((subOrgId) => {
        return organization.sub_organizations?.find((subOrg) => subOrg.id === subOrgId);
      }),
    }));
  };

  const prepareSharedCoverages = (sharedCoverages, coverages) => {
    return prepareCoverages(sharedCoverages).map((sc) => ({
      ...sc,
      coverage_config_display_names: sc.coverage_config_ids
        .map((key) => {
          return coverages.find((c) => c.coverage_key === key)?.display_name;
        })
        .filter(Boolean),
    }));
  };

  const handleCreate = (coveragesPropKey, data) => {
    const newCoverages = [...values[coveragesPropKey], data];
    setFieldValue(coveragesPropKey, newCoverages);
  };

  const handleCreateCoverage = (data) => {
    handleCreate('coverages', data);
  };

  const handleCreateSharedCoverage = (data) => {
    handleCreate('shared_coverages', data);
  };

  const handleUpdate = (coveragesPropKey, coverageKeyPropKey, data) => {
    const { coverage_key: coverageKey } = data;
    const coverageIndex = values[coveragesPropKey].findIndex((c) => c[coverageKeyPropKey] === coverageKey);
    setFieldValue(`${coveragesPropKey}[${coverageIndex}]`, data);
  };

  const handleUpdateCoverage = (data) => {
    handleUpdate('coverages', 'coverage_key', data);
  };

  const handleUpdateSharedCoverage = (data) => {
    handleUpdate('shared_coverages', 'shared_coverage_key', data);
  };

  const handleDelete = (coveragesPropKey, coverageKeyPropKey, data) => {
    const { [coverageKeyPropKey]: coverageKey } = data;
    const updatedCoverages = values[coveragesPropKey].filter(({ [coverageKeyPropKey]: k }) => k !== coverageKey);
    setFieldValue(coveragesPropKey, updatedCoverages);
  };

  const handleDeleteCoverage = (data) => {
    handleDelete('coverages', 'coverage_key', data);
  };

  const handleDeleteSharedCoverage = (data) => {
    handleDelete('shared_coverages', 'shared_coverage_key', data);
  };

  const doesCoverageIncludeSubOrgsIdsPredicate = (subOrgIds) => {
    return (c) => subOrgIds.every((id) => c.sub_organization_ids?.includes(id));
  };

  const handleFetchUsedKeys = ({ coverage_config_ids_to_ignore }) => {
    const coverageKeys = (values['coverages'] || []).map((c) => c['coverage_key']);
    const sharedCoverageKeys = (values['shared_coverages'] || []).map((c) => c['shared_coverage_key']);
    return {
      data: [...existingCoverageKeys, ...coverageKeys, ...sharedCoverageKeys].filter(
        (k) => !coverage_config_ids_to_ignore?.includes(k)
      ),
    };
  };

  const handleFetchUsedCoveragesKeys = ({ coverage_config_ids_to_ignore }) => {
    return handleFetchUsedKeys({
      coverage_config_ids_to_ignore,
    });
  };

  const handleFetchUsedSharedCoveragesKeys = ({ shared_coverage_config_ids_to_ignore }) => {
    return handleFetchUsedKeys({
      coverage_config_ids_to_ignore: shared_coverage_config_ids_to_ignore,
    });
  };

  const handleFetchCoverages = ({ subOrganizationIds, lob }) => {
    let filteredCoverages = [...values.coverages];
    if (subOrganizationIds?.length > 0) {
      filteredCoverages = filteredCoverages.filter(doesCoverageIncludeSubOrgsIdsPredicate(subOrganizationIds));
    }
    if (lob) {
      filteredCoverages = filteredCoverages.filter((c) => c.lob === lob);
    }

    return filteredCoverages;
  };

  return (
    <div className={wizardStyles.cardContainer}>
      <ExposureConfigurationTool
        isLoading={isLoading}
        isError={isError}
        organization={{ ...organization, sub_organizations: subOrganizations }}
        supportedClaimTypes={[supportedLob]}
        coverages={prepareCoverages(values.coverages)}
        sharedCoverages={prepareSharedCoverages(values.shared_coverages, values.coverages)}
        coverageConfiguration={coverageConfiguration}
        onUpdateCoverage={handleUpdateCoverage}
        onCreateCoverage={handleCreateCoverage}
        onUpdateSharedCoverage={handleUpdateSharedCoverage}
        onCreateSharedCoverage={handleCreateSharedCoverage}
        onDeleteCoverage={handleDeleteCoverage}
        onDeleteSharedCoverage={handleDeleteSharedCoverage}
        enableRemovingExistingCoverages
        fetchUsedCoveragesKeys={handleFetchUsedCoveragesKeys}
        fetchUsedSharedCoveragesKeys={handleFetchUsedSharedCoveragesKeys}
        fetchCoverages={handleFetchCoverages}
        errorHandler={reportErrorInProductionOrThrow}
        hideExposureConfigurationFilters={hideExposureConfigurationFilters}
        wizardLob={supportedLob}
      />
      <Grid container direction="row" alignItems="center">
        <Grid item xs={3} />
        <Grid item xs={6}>
          <ErrorHelperTextFormik id="coverages" style={{ textAlign: 'center' }} />
        </Grid>
        <Grid item xs={3} />
      </Grid>
    </div>
  );
};

ExposureConfigurationToolLobWizardFormikWrapper.propTypes = {
  supportedLob: PropTypes.array,
  hideExposureConfigurationFilters: PropTypes.bool,
};

export default ExposureConfigurationToolLobWizardFormikWrapper;
