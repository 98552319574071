import axios from 'axios';

import { ORG_UNITS_ROUTES } from '~/components/SystemConfiguration/Users/OrganizationUnits/routes';
import { reportAxiosError } from '~/Utils';

export const getUnits = async ({ organizationId }) => {
  const ORG_UNITS_URL = ORG_UNITS_ROUTES.GET({ organizationId });
  try {
    const response = await axios.get(ORG_UNITS_URL);
    return response.data;
  } catch (error) {
    reportAxiosError(error);
    throw error;
  }
};

export const createUnit = async ({ organizationId, data }) => {
  const ORG_UNITS_URL = ORG_UNITS_ROUTES.CREATE({ organizationId });
  try {
    const response = await axios.post(ORG_UNITS_URL, data);
    return response.data;
  } catch (error) {
    reportAxiosError(error);
    throw error;
  }
};

export const updateUnit = async ({ organizationId, unitId, data }) => {
  const ORG_UNITS_URL = ORG_UNITS_ROUTES.UPDATE({ organizationId, unitId });
  try {
    const response = await axios.put(ORG_UNITS_URL, data);
    return response.data;
  } catch (error) {
    reportAxiosError(error);
    throw error;
  }
};

export const deleteUnit = async ({ organizationId, unitId }) => {
  const ORG_UNITS_URL = ORG_UNITS_ROUTES.DELETE({ organizationId, unitId });
  try {
    const response = await axios.delete(ORG_UNITS_URL);
    return response.data;
  } catch (error) {
    reportAxiosError(error);
    throw error;
  }
};

export const setUnitActivation = async ({ organizationId, unitId, isActive, memberUserIds }) => {
  const ORG_UNITS_URL = ORG_UNITS_ROUTES.SET_ACTIVE({ organizationId, unitId });
  try {
    const response = await axios.post(ORG_UNITS_URL, { is_active: isActive, member_user_ids: memberUserIds });
    return response.data;
  } catch (error) {
    reportAxiosError(error);
    throw error;
  }
};
