import React from 'react';
import PropTypes from 'prop-types';
import { Formik, useFormikContext } from 'formik';

import Button from '~/components/core/Atomic/Buttons/Button';
import Typography from '~/components/core/Atomic/Typography';
import CancelButton from '~/components/core/Buttons/CancelButton';

import { stepPropTypesShape } from '../Steps/steps';

import { useStyles } from '../../../../../../../assets/styles';
import styles from '../claimSearchConfigurationDialog.module.scss';

const CheckBannerStepDialogContent = ({ step, initialValues, onSubmit, onCancel }) => (
  <Formik
    initialValues={initialValues}
    validationSchema={step.validationSchema}
    onSubmit={async (values, { setSubmitting }) => {
      try {
        await onSubmit(values);
      } catch (error) {
        setSubmitting(false);
        throw error;
      }
    }}
  >
    <CheckBannerDialogInner onCancel={onCancel} step={step} />
  </Formik>
);

CheckBannerStepDialogContent.propTypes = {
  step: stepPropTypesShape.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export const CheckBannerDialogInner = ({ step, onCancel }) => {
  const classes = useStyles();
  const { handleSubmit, isSubmitting } = useFormikContext();

  return (
    <>
      <div className={styles.innerStepTitleContainer}>
        <Typography display="block" variant="h6">
          {step.title}
        </Typography>
        {step.subtitle && <div className={styles.innerStepSubtitle}>{step.subtitle}</div>}
      </div>
      <div className={styles.innerStepContent}>{step.dialogContentComponent}</div>
      <div className={classes.buttonsContainer}>
        <CancelButton disabled={isSubmitting} onClick={onCancel} />
        <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
          Done
        </Button>
      </div>
    </>
  );
};

CheckBannerDialogInner.propTypes = {
  step: stepPropTypesShape.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CheckBannerStepDialogContent;
