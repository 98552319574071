import React from 'react';

import SvgIcon from '../core/SvgIcon';

const ConfirmStepIcon = (props) => (
  <SvgIcon viewBox="0 0 23 16" stroke="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M22.5303 0.46967C22.8232 0.762563 22.8232 1.23744 22.5303 1.53033L8.53033 15.5303C8.23744 15.8232 7.76256 15.8232 7.46967 15.5303L0.46967 8.53033C0.176777 8.23744 0.176777 7.76256 0.46967 7.46967C0.762563 7.17678 1.23744 7.17678 1.53033 7.46967L8 13.9393L21.4697 0.46967C21.7626 0.176777 22.2374 0.176777 22.5303 0.46967Z" />
  </SvgIcon>
);

export default ConfirmStepIcon;
