import React, { useState } from 'react';
import _ from 'lodash';

import { getUnits } from '~/components/SystemConfiguration/Users/OrganizationUnits/unitsApi';
import {
  buildLeaderToUnitsDict,
  buildUnitsToSubUnitsDict,
  buildUserToUnitDict,
  isOrgUnitsConfigurationFeatureEnabled,
} from '~/components/SystemConfiguration/Users/OrganizationUnits/Utils';

export const useOrganizationUnits = ({ organization }) => {
  const [units, setUnits] = React.useState([]);
  const [isUnitsLoading, setIsUnitsLoading] = useState(true);
  const [isUnitsError, setIsUnitsError] = useState(false);
  const isOrgUnitsFeatureEnabled = React.useMemo(
    () => isOrgUnitsConfigurationFeatureEnabled({ organization }),
    [organization]
  );
  const userToUnitDict = React.useMemo(() => buildUserToUnitDict({ units }), [units]);
  const leaderToUnitsDict = React.useMemo(() => buildLeaderToUnitsDict({ units }), [units]);
  const unitsToSubUnitsDict = React.useMemo(() => buildUnitsToSubUnitsDict({ units }), [units]);
  const unitsDict = React.useMemo(() => _.keyBy(units, 'id'), [units]);

  const reloadUnits = React.useCallback(
    async (setLoadingState = false) => {
      if (setLoadingState) {
        setIsUnitsLoading(true);
      }
      try {
        const units = await getUnits({ organizationId: organization.id });
        units.sort((a, b) => a.name.localeCompare(b.name));
        setUnits(units);
      } catch (e) {
        setIsUnitsError(true);
      } finally {
        if (setLoadingState) {
          setIsUnitsLoading(false);
        }
      }
    },
    [organization.id]
  );

  React.useEffect(() => {
    reloadUnits(true);
  }, [reloadUnits]);

  const getActiveUnitsAndSelectedUnit = React.useCallback(
    /**
     * Get all active units, and the selected unit. If the selected unit is
     * deactivated, its name will be appended with "(Deactivated)". Due to this,
     * the name of the selected unit may be different from the name in the units
     * list. This means that the return value of this function should not be used
     * as Formik's initial values.
     *
     * @param {string | undefined} selectedUnitId The ID of the selected unit.
     * @returns {{[Number]: Object}} An object containing the active units by ID.
     */
    (selectedUnitId) => {
      if (isUnitsError || isUnitsLoading) {
        return {};
      }
      const activeUnitsById = _.pickBy(unitsDict, (unit) => unit.is_active);
      // If the selected unit is deactivated, add it to the list so it shows up in the dropdown
      if (selectedUnitId && !activeUnitsById[selectedUnitId]) {
        const unit = unitsDict[selectedUnitId];
        activeUnitsById[selectedUnitId] = { ...unit, name: `${unit.name} (Deactivated)` };
      }
      return activeUnitsById;
    },
    [isUnitsError, isUnitsLoading, unitsDict]
  );

  return {
    units,
    unitsDict,
    isUnitsLoading,
    isUnitsError,
    isOrgUnitsFeatureEnabled,
    userToUnitDict,
    leaderToUnitsDict,
    unitsToSubUnitsDict,
    reloadUnits,
    getActiveUnitsAndSelectedUnit,
  };
};
