import React, { useState } from 'react';
import axios from 'axios';
import { isEmpty } from 'lodash';

import { INTEGRATION_STATUS_DICT } from '../../../../../Types';
import { reportAxiosError } from '../../../../../Utils';
import CardDialog from '../../../../CardDialog';
import { LoadingSwitch, Text } from '../../../../core';
import { PencilIcon } from '../../../../icons';
import InlineIconButton from '../../../../InlineIconButton';
import useOrganization from '../../../../OrganizationContext';
import OperationsBreadcrumbs from '../../../OperationsBreadcrumbs';
import ConfigurationTable from '../../ConfigurationTable';
import { useClaimSearch } from '../hooks/useClaimSearch';

import ClaimSearchConfigurationDialog, { FIELD_IDS } from './ConfigurationDialog';

import { useStyles } from '../../../../../assets/styles';
import styles from './claimSearchExternalAdmin.module.scss';

const SCOPE = {
  ALL_SUB_ORGANIZATIONS: 'all',
  PER_SUB_ORGANIZATIONS: 'per_sub_organization',
};

const STATUS_NOTE = {
  [INTEGRATION_STATUS_DICT.in_progress]: 'Please complete updating the required data to start reporting',
  [INTEGRATION_STATUS_DICT.pending]: 'Contact your solution manager',
};

const ExternalAdminClaimSearch = () => {
  const classes = useStyles();
  const { isLoading, isError, reloadData: reloadConfigurations, claimSearchOrgConfig } = useClaimSearch();
  const [selectedConfiguration, setSelectedConfiguration] = useState(null);

  const { organizationId } = useOrganization();

  let scope;

  if (claimSearchOrgConfig?.is_org_level_configuration) {
    scope = SCOPE.ALL_SUB_ORGANIZATIONS;
  } else if (claimSearchOrgConfig?.is_org_level_configuration === false) {
    scope = SCOPE.PER_SUB_ORGANIZATIONS;
  }

  const additionalColumnsArray = [
    {
      id: 'note',
      numeric: false,
      label: 'Note',
      disableSort: true,
      specialCell: (configuration) => STATUS_NOTE[configuration?.status],
    },
    {
      id: 'actions',
      numeric: false,
      label: 'Actions',
      disableSort: true,
      align: 'right',
      specialCell: (configuration) => (
        <InlineIconButton
          tooltipTitle="Edit"
          icon={PencilIcon}
          className={`${classes.textIcon} ${classes.marginedIcon} ${classes.hoverableNonFilledIcon}`}
          onClick={() => setSelectedConfiguration(configuration)}
          wrapWithSpan
        />
      ),
    },
  ];

  const handleUpdate = async ({ [FIELD_IDS.CAUSE_LOSS_CODES.BASE_ID]: causeOfLossCodes, ...configurationValues }) => {
    await Promise.all([handleUpdateConfiguration(configurationValues), handleUpdateCauseOfLoss(causeOfLossCodes)]);
    await reloadConfigurations();
    setSelectedConfiguration(null);
  };

  const handleUpdateConfiguration = async (configurationValues) => {
    try {
      if (isEmpty(configurationValues)) {
        return;
      }

      await axios.post(
        `/api/v1/claim_search/configuration/${organizationId}`,
        { ...configurationValues, configuration_id: selectedConfiguration.configuration_id },
        { params: { sub_organization_id: selectedConfiguration.sub_organization_id } }
      );
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
  };

  const handleUpdateCauseOfLoss = async (colValues) => {
    try {
      if (isEmpty(colValues)) {
        return;
      }

      await axios.put(`/api/v1/organizations/${organizationId}/cause_of_loss_codes`, colValues, {
        params: { sub_organization_id: selectedConfiguration.sub_organization_id },
      });
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
  };

  return (
    <>
      <LoadingSwitch isError={isError} isLoading={isLoading}>
        <OperationsBreadcrumbs currentTab="ClaimSearch" />
        <CardDialog title="ClaimSearch" containerClassName={styles.claimSearchExternalAdmin}>
          <Text variant="sm" weight="regular">
            Enable integration with ClaimSearch.
          </Text>
          {!isLoading && (
            <ConfigurationTable
              configurations={claimSearchOrgConfig?.configurations}
              additionalColumnsArray={additionalColumnsArray}
              forceOrganizationLevel={scope === SCOPE.ALL_SUB_ORGANIZATIONS}
            />
          )}
          {selectedConfiguration && (
            <ClaimSearchConfigurationDialog
              configurationValues={selectedConfiguration}
              onSubmit={handleUpdate}
              onCancel={() => setSelectedConfiguration(null)}
            />
          )}
        </CardDialog>
      </LoadingSwitch>
    </>
  );
};

export default ExternalAdminClaimSearch;
