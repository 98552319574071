const ASSIGNMENT_RULE_ENTITY_DICT = {
  claims: {
    desc: 'Claims',
  },
  exposures: {
    desc: 'Exposures',
  },
} as const;

export default ASSIGNMENT_RULE_ENTITY_DICT;
