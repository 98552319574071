import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import * as Yup from 'yup';

import RoundEmptyStateWrapper from '~/components/core/EmptyState/RoundEmptyStateWrapper';

import { MessageWithIllustration } from '../../core';
import { ConfirmStepIcon, LobWizardIcon } from '../../icons';
import useOrganization from '../../OrganizationContext';
import { useSysconfig } from '../../SystemConfiguration/SystemConfigurationScreen';
import { coverageConfigurationValidation, lobIdentifierValidation, onNextWizardValidation } from '../WizardUtils/Utils';

import ExposureConfigurationToolLobWizardFormikWrapper from './ExposureConfigurationStep';
import LobStep from './LobStep';
import SubOrganizationStep from './SubOrganizationStep';
import { copies } from './Utils';

export const WizardSteps = ({ organizationSupportedLobs, formikContext, subOrgsWithClaimIdentifiers }) => {
  const { claimNumberingScheme } = useOrganization();
  const { organization } = useSysconfig();

  return [
    {
      key: 'welcome',
      label: 'Welcome',
      content: (
        <MessageWithIllustration
          illustration={
            <RoundEmptyStateWrapper>
              <LobWizardIcon />
            </RoundEmptyStateWrapper>
          }
          title={copies.WELCOME_STEP_TITLE}
          text={copies.WELCOME_STEP_CONTENT}
        />
      ),
      onNext: noop,
      onBack: noop,
      disableNext: false,
      disableBack: false,
    },
    {
      key: 'lob',
      label: 'Select Line of Business',
      content: <LobStep organizationSupportedLobs={organizationSupportedLobs} />,
      onNext: () => onNextWizardValidation(formikContext),
      onBack: noop,
      disableNext: false,
      disableBack: false,
      validationSchema: Yup.object().shape({
        lob: Yup.object().shape({
          name: Yup.string().required('Required'),
          claim_identifier: Yup.string().when('name', {
            is: () => !organization.sub_organizations_enabled && claimNumberingScheme.lob_prefix_toggle,
            then: lobIdentifierValidation().test(
              'is-unique',
              'Claim Identifier has to be unique per all Lines of Business.',
              (value) => {
                if (!organization.sub_organizations_enabled && claimNumberingScheme.lob_prefix_toggle) {
                  return !organizationSupportedLobs.map((lob) => lob.claim_identifier).includes(value);
                }
                return true;
              }
            ),
          }),
          lob_iso_code: Yup.string(),
          is_claims_made: Yup.bool(),
        }),
      }),
    },
    {
      key: 'sub_organizations',
      label: 'Sub-Organizations',
      content: <SubOrganizationStep />,
      onNext: () => onNextWizardValidation(formikContext),
      onBack: noop,
      disableNext: false,
      disableBack: false,
      validationSchema: Yup.lazy((values) =>
        Yup.object().shape({
          sub_orgs_with_claim_identifiers: Yup.object().shape(
            Object.fromEntries(
              organization?.sub_organizations?.map((subOrg) => [
                subOrg.id,
                Yup.object().shape({
                  id: Yup.number().required('Required'),
                  is_enabled: Yup.bool(),
                  claim_identifier: Yup.string().when('is_enabled', {
                    is: (is_enabled) =>
                      is_enabled && claimNumberingScheme.lob_prefix_toggle && organization.sub_organizations_enabled,
                    then: lobIdentifierValidation().test(
                      'is-unique',
                      'Claim Identifier has to be unique within the sub organization’s lob’s Claim Identifiers.',
                      (value) =>
                        !Object.values(values.sub_orgs_with_claim_identifiers)?.some((subOrgW) => {
                          if (subOrgW.claim_identifier === value && subOrgW.id === subOrg.id) {
                            return;
                          }
                          return subOrgW.claim_identifier === value;
                        })
                    ),
                  }),
                  lob_iso_code: Yup.string(),
                  is_claims_made: Yup.bool(),
                }),
              ]) ?? []
            )
          ),
        })
      ),
      isDisabled: !organization.sub_organizations_enabled,
    },
    {
      key: 'exposures',
      label: 'Exposures Configuration',
      content: (
        <ExposureConfigurationToolLobWizardFormikWrapper
          supportedLob={formikContext?.values.lob.name}
          hideExposureConfigurationFilters
        />
      ),
      onNext: () => onNextWizardValidation(formikContext),
      onBack: noop,
      disableNext: false,
      disableBack: false,
      validationSchema: Yup.object().shape({
        coverages: coverageConfigurationValidation(),
      }),
      isDisabled:
        organization.sub_organizations_enabled &&
        subOrgsWithClaimIdentifiers &&
        !Object.values(subOrgsWithClaimIdentifiers).some(
          (subOrgWithClaimIdentifier) => subOrgWithClaimIdentifier.is_enabled
        ),
    },
    {
      key: 'confirm',
      label: 'Confirm',
      content: (
        <MessageWithIllustration
          illustration={
            <RoundEmptyStateWrapper>
              <ConfirmStepIcon />
            </RoundEmptyStateWrapper>
          }
          title={copies.CONFIRM_STEP_TITLE}
          text={copies.CONFIRM_STEP_CONTENT}
        />
      ),
      onNext: noop,
      onBack: noop,
      disableNext: false,
      disableBack: false,
    },
  ].filter((tab) => !tab.isDisabled);
};

WizardSteps.propTypes = {
  organizationSupportedLobs: PropTypes.array.isRequired,
  formikContext: PropTypes.object.isRequired,
  subOrgsWithClaimIdentifiers: PropTypes.array,
};
