import React from 'react';
import PropTypes from 'prop-types';

import AlertBanner from '~/components/core/AlertBanner';
import SearchWithOptionsFormik from '~/components/core/Formik/SearchWithOptionsFormik/SearchWithOptionsFormik';

import { ROLES_TYPES_DICT } from '../../../../../../Types';
import { Text } from '../../../../../core';
import useOrganization from '../../../../../OrganizationContext';
import { useSysconfig } from '../../../../SystemConfigurationScreen';
import { ProfileSummary } from '../ProfileSummary/ProfileSummary';

import styles from './styles.module.scss';

export const UsersSelectField = ({ filterPredicate, showOnly }) => {
  const { users } = useSysconfig();
  const { subOrganizations } = useOrganization();

  const subOrgIdToName = React.useMemo(
    () =>
      subOrganizations
        ? subOrganizations.reduce((acc, subOrg) => {
            acc[subOrg.id] = subOrg.name;
            return acc;
          }, {})
        : {},
    [subOrganizations]
  );

  const getUserSubOrgNames = React.useCallback(
    (user) =>
      user.sub_organization_ids
        .filter((subOrgId) => subOrgIdToName[subOrgId])
        .map((subOrgId) => subOrgIdToName[subOrgId]),
    [subOrgIdToName]
  );

  const subOrgNamesByUser = React.useMemo(() => {
    return users.reduce((acc, user) => {
      acc[user.id] = getUserSubOrgNames(user)?.join(', ');
      return acc;
    }, {});
  }, [users, getUserSubOrgNames]);

  const selectOptionsEntries = React.useMemo(
    () =>
      users
        .filter((user) => !ROLES_TYPES_DICT[user.role.role_type].is_hidden)
        .filter(filterPredicate ?? (() => true))
        .map((user) => ({
          id: user.id,
          entryTitle: `${user.first_name} ${user.last_name}`,
          entrySubtitle: user.profile?.display_name ?? 'Not Assigned',
          secondaryAction: subOrgNamesByUser[user.id],
          isDisabled: false,
        })),
    [filterPredicate, users, subOrgNamesByUser]
  );

  return (
    <SearchWithOptionsFormik
      className={styles.selectInner}
      mainFieldId="users"
      isSelectAllEnabled
      label="Search Organization User"
      textMaxWidth="250px"
      options={[
        {
          entries: selectOptionsEntries,
          title: '',
          subtitle: '',
        },
      ]}
      showOnly={showOnly}
    />
  );
};

UsersSelectField.propTypes = {
  filterPredicate: PropTypes.func,
  showOnly: PropTypes.bool,
};

export const UsersSelectStep = ({ title = 'Assign Users (Optional)', noBanner }) => {
  return (
    <div className={styles.userSelectStepContainer}>
      {title && (
        <div className={styles.titleContainer}>
          <ProfileSummary variant={ProfileSummary.VARIANTS.SLIM} />
          <Text variant={Text.VARIANTS.LG} weight={Text.WEIGHTS.SEMI_BOLD} colorVariant={Text.COLOR_VARIANTS.BLACK}>
            {title}
          </Text>
        </div>
      )}
      {!noBanner && (
        <div className={styles.alertContainer}>
          <AlertBanner
            note="When assigning users to a new permission profile, their current profile will change."
            withIcon
            alertType={AlertBanner.ALERT_TYPES.WARNING}
          />
        </div>
      )}
      <UsersSelectField />
    </div>
  );
};

UsersSelectStep.propTypes = {
  title: PropTypes.string,
  noBanner: PropTypes.bool,
};
