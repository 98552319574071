import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';

import Stepper from '~/components/core/Stepper';

import mixpanel from '../../../../CmsMain/mixpanel';
import ConfirmDialogContent from '../../../Users/UserDialogs/UserPermissionsSection/ConfirmDialogContent';
import { PERMISSIONS_MIXPANEL_EVENTS, PERMISSIONS_MIXPANEL_SOURCES, safeMixpanelTrack } from '../MixpanelEvents';

import { ProfileSummary } from './ProfileSummary/ProfileSummary';
import { UsersSelectStep } from './UsersSelect/UsersSelectStep';
import { FORM_FIELDS, useSetAllTouched } from './formUtils';
import { ProfilePermissionsForm } from './ProfilePermissionsForm';

import styles from './styles.module.scss';

export const ProfileDialogStepper = ({ onClose, onStepChange, steps, mixpanelConfig }) => {
  const { validateForm, submitForm, values } = useFormikContext();

  const stepsArray = steps ?? Object.values(ProfileDialogStepper.STEPS);
  const [currentStep, setCurrentStep] = React.useState(stepsArray[0]);

  const setAllTouched = useSetAllTouched();

  const handleStepChange = React.useCallback(
    (nextStep, nextStepIndex) => {
      setCurrentStep(ProfileDialogStepper.getStepByIndex(nextStepIndex));

      safeMixpanelTrack(mixpanelConfig.stepChangeEvent, {
        profile_name: values[FORM_FIELDS.display_name],
        default_permissions: values[FORM_FIELDS.default_permission],
        source: mixpanelConfig.source,
        stage: currentStep.id,
        element: nextStepIndex > currentStep.index ? 'next_button' : 'back_button',
      });

      onStepChange(nextStep, nextStepIndex);
    },
    [currentStep.id, currentStep.index, mixpanelConfig.source, mixpanelConfig.stepChangeEvent, onStepChange, values]
  );

  const handleNext = React.useCallback(async () => {
    if (currentStep?.shouldValidate) {
      setAllTouched();
      const errors = await validateForm();
      return isEmpty(errors);
    } else {
      return true;
    }
  }, [currentStep, setAllTouched, validateForm]);

  const numberOfUsers = values[FORM_FIELDS.users]?.length ?? 0;

  const handleOnCompletePopupClick = React.useCallback(
    (action) => {
      mixpanel.track(PERMISSIONS_MIXPANEL_EVENTS.assign_user_create_new_profile, {
        source: PERMISSIONS_MIXPANEL_SOURCES.CREATE_PROFILE,
        assigned_users: numberOfUsers,
        action,
      });
    },
    [numberOfUsers]
  );

  const memoizedComponent = React.useMemo(() => {
    return (
      <div className={styles.dialogStepper}>
        <Stepper
          onComplete={async () => {
            await submitForm();
          }}
          contentClassName={styles.stepperContent}
          headerClassName={styles.dialogStepperHeader}
          backBtnText="Back"
          nextBtnText="Next"
          finishBtnText="Create"
          firstStepBackBtnText="Cancel"
          firstStepNextBtnText="Next"
          onFirstStepBack={onClose}
          onStepChange={handleStepChange}
          withConfirmOnFirstBack
          withConfirmOnFirstBackProps={{
            title: '',
            primaryButtonName: 'Yes',
            secondaryButtonName: 'Cancel',
            maxWidth: 'lg',
            contentText: (
              <ConfirmDialogContent
                title="Are you sure?"
                subtitle="You have unsaved changes. Are you sure you want to leave?"
              />
            ),
          }}
          withConfirmOnComplete
          withConfirmOnCompleteProps={{
            title: '',
            primaryButtonName: 'Yes',
            secondaryButtonName: 'Cancel',
            maxWidth: 'lg',
            contentText: (
              <ConfirmDialogContent
                title="Assign Users?"
                subtitle="This action removes the user from their current profile and adds the user to a new profile."
              />
            ),
            onSecondaryBtnClick: () => handleOnCompletePopupClick('cancel'),
            postOnClick: () => handleOnCompletePopupClick('confirm'),
            disableConfirm: !numberOfUsers,
          }}
        >
          {stepsArray.map((step) => (
            <Stepper.Step onNext={handleNext} label={step.label} key={step.id}>
              <div className={styles.dialogStepContainer}>
                <step.component />
              </div>
            </Stepper.Step>
          ))}
        </Stepper>
      </div>
    );
  }, [handleStepChange, numberOfUsers, stepsArray, submitForm, onClose, handleOnCompletePopupClick, handleNext]);

  return memoizedComponent;
};

ProfileDialogStepper.propTypes = {
  onClose: PropTypes.func.isRequired,
  onStepChange: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  mixpanelConfig: PropTypes.shape({
    source: PropTypes.string,
    stepChangeEvent: PropTypes.string,
  }),
};

ProfileDialogStepper.STEPS = {
  profile_configuration: {
    index: 0,
    label: 'Profile Configuration',
    id: 'profile_configuration',
    shouldValidate: true,
    component: ProfilePermissionsForm,
  },
  assign_users: {
    index: 1,
    label: 'Assign users',
    id: 'assign_users',
    shouldValidate: false,
    component: UsersSelectStep,
  },
  create_profile: {
    index: 2,
    label: 'Create a Profile',
    id: 'create_profile',
    shouldValidate: true,
    component: ProfileSummary,
  },
};

ProfileDialogStepper.getStepByIndex = (index) => {
  const step = Object.values(ProfileDialogStepper.STEPS).find((step) => step.index === index);
  if (!step) {
    throw new Error(`No step found for index ${index}`);
  }
  return step;
};
