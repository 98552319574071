const ASSIGNMENT_RULE_METHOD_DICT = {
  round_robin: {
    desc: 'Round Robin',
  },
  static: {
    desc: 'Static',
  },
} as const;

export default ASSIGNMENT_RULE_METHOD_DICT;
