import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';

import DialogFooterActions from '~/components/core/DialogFooterActions';

import CardDialog from '../../../CardDialog';
import { useSysconfig } from '../../SystemConfigurationScreen';

import { getPermissionsFeatureUserSchemaOverrides, getUserValidationFields } from './UserDialogUtils';
import { UserFragment } from './UserFragment';

import styles from '../../../../assets/styles';

const UserEditDialog = ({
  classes,
  user,
  roles,
  organizationExternalId,
  onSaveUserDetails,
  onEditCancel,
  users,
  units,
  displayLobs,
  displaySuperUser,
  displaySystemConfig,
  isNew,
  overrideOrganizationValue,
  isWizardUser,
}) => {
  const initialEmail = user.email;
  const { organization: sysconfigOrganization } = useSysconfig();

  const organization = overrideOrganizationValue ?? sysconfigOrganization;

  return (
    <Formik
      initialValues={{
        is_sys_config_editor: false,
        ...user,
        mobile_phone_number: user.mobile_phone_number || '',
        work_phone_number: user.work_phone_number || '',
        is_part_of_sub_organization: user.sub_organization_ids?.length > 0,
        lobs: user.lobs || [],
      }}
      validationSchema={Yup.object().shape({
        ...getUserValidationFields(users, initialEmail, isNew),
        ...getPermissionsFeatureUserSchemaOverrides(organization),
      })}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        try {
          await onSaveUserDetails(values);
        } catch (error) {
          formikProps.setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog
            isDialog={true}
            title="Edit User"
            maxWidth="sm"
            onClose={onEditCancel}
            preventClose={isSubmitting}
            footerActions={
              <DialogFooterActions
                disabled={isSubmitting}
                onClickPrimary={handleSubmit}
                onClickSecondary={onEditCancel}
              />
            }
          >
            <UserFragment
              {...formikProps}
              classes={classes}
              organizationExternalId={organizationExternalId}
              roles={roles}
              units={units}
              unitsLedByUser={user.unitsLedByUser}
              displayLobs={displayLobs}
              displaySuperUser={displaySuperUser}
              displaySystemConfig={displaySystemConfig}
              isNew={isNew}
              overrideOrganizationValue={overrideOrganizationValue}
              isWizardUser={isWizardUser}
            />
          </CardDialog>
        );
      }}
    </Formik>
  );
};

UserEditDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  organizationExternalId: PropTypes.string.isRequired,
  onSaveUserDetails: PropTypes.func.isRequired,
  onEditCancel: PropTypes.func.isRequired,
  users: PropTypes.array,
  units: PropTypes.array,
  displayLobs: PropTypes.bool,
  displaySuperUser: PropTypes.bool,
  displaySystemConfig: PropTypes.bool,
  isNew: PropTypes.bool,
  overrideOrganizationValue: PropTypes.object,
  isWizardUser: PropTypes.bool,
};

const UserEditDialogStyled = withStyles(styles)(UserEditDialog);

export { UserEditDialogStyled as UserEditDialog };
