type VoidFunc = () => void;

const executeOrDelegate = ({
  primaryTask,
  wrapperFunction,
}: {
  primaryTask: VoidFunc;
  wrapperFunction?: (callback: VoidFunc) => void;
}): void => {
  if (wrapperFunction) {
    wrapperFunction(primaryTask);
  } else {
    primaryTask();
  }
};

export default executeOrDelegate;
