import React from 'react';
import PropTypes from 'prop-types';

import StatusChip from '~/components/core/StatusChip';
import SortableTable from '~/components/core/Tables/SortableTable';

import { serverDateTimeToLocalDate } from '../../../../DateTimeUtils';
import useOrganization from '../../../OrganizationContext';

const NavigatorOrganizationTableCard = (props) => {
  const { navigatorConfigurations, additionalColumnsArray } = props;
  const { subOrganizationEnabled } = useOrganization();

  const columns = [
    {
      id: 'name',
      numeric: false,
      label: subOrganizationEnabled ? 'Sub Organization Name' : 'Organization Name',
      specialCell: (navigatorConfiguration) => navigatorConfiguration.name,
    },
    {
      id: 'lastUpdate',
      numeric: false,
      label: 'Last Update',
      disableSort: true,
      specialCell: (navigatorConfiguration) =>
        navigatorConfiguration.last_updated_datetime
          ? serverDateTimeToLocalDate(navigatorConfiguration.last_updated_datetime)
          : undefined,
    },
    {
      id: 'status',
      numeric: false,
      label: 'Status',
      disableSort: true,
      specialCell: (navigatorConfiguration) => (
        <>
          <StatusChip withIcon status={navigatorConfiguration.navigator_status.toUpperCase()} />
        </>
      ),
    },
  ];

  columns.push(...additionalColumnsArray);

  return (
    <SortableTable
      rows={navigatorConfigurations}
      columns={columns}
      defaultOrderColumn={columns.findIndex((column) => column.id === 'name')}
      order="desc"
      stickyHeader
      autoPaginateRowsPerPage={5}
    />
  );
};

NavigatorOrganizationTableCard.propTypes = {
  navigatorConfigurations: PropTypes.array,
  additionalColumnsArray: PropTypes.array,
};

export default NavigatorOrganizationTableCard;
