import PropTypes from 'prop-types';

export const ProfilePermissionProptypesShape = PropTypes.shape({
  id: PropTypes.string,
  profile_id: PropTypes.string,
  permission_action: PropTypes.string,
  permission_verbs: PropTypes.arrayOf(PropTypes.string),
  created_at: PropTypes.string,
  deleted_at: PropTypes.string,
});

export const ProfileProptypesShape = PropTypes.shape({
  id: PropTypes.string,
  external_id: PropTypes.string,
  display_name: PropTypes.string,
  profile_permissions: PropTypes.arrayOf(ProfilePermissionProptypesShape),
  organization_id: PropTypes.string,
  defaults: PropTypes.arrayOf(PropTypes.string),
  created_at: PropTypes.string,
  created_by: PropTypes.string,
  deleted_at: PropTypes.string,
  description: PropTypes.string,
  last_updated_by: PropTypes.string,
  last_updated: PropTypes.string,
  number_of_users: PropTypes.number,
});
