import React from 'react';
import PropTypes from 'prop-types';
import { Formik, getIn, useFormikContext } from 'formik';
import { pickBy, snakeCase } from 'lodash';
import * as Yup from 'yup';

import { colorPalette, useStyles } from '~/assets/styles';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Typography from '~/components/core/Atomic/Typography';
import DialogFooterActions from '~/components/core/DialogFooterActions';
import { DEFAULT_MOI_METHODS, MOI_EXPERTISE_TYPES } from '~/Types';
import { subOrgIdToNameDict } from '~/Utils';

import CardDialog from '../../CardDialog';
import { useShouldDisableFormikField } from '../../hooks/useShouldDisableFormikField';
import { MultiSelectTextFieldFormik, TextFieldFormik, useSetDefaultFieldsOnChange } from '../../TextFieldFormik';
import { CoveragesMultiselectFormik } from '../../TPA/Coverages/CoveragesMultiselectFormik';
import LobRadioFormik from '../../TPA/LOB/LobRadioFormik';

export const MoiMethodDialog = ({
  moiMethod,
  onSubmit,
  onClose,
  organization,
  supportedClaimTypes,
  fetchUsedMoiMethodKeys,
  errorHandler,
  overrideDisabled,
}) => {
  const getSubOrganizationIdsInitialValues = (moiMethod) => {
    const ALL = ['all'];

    if (!moiMethod) {
      return ALL;
    }

    if (moiMethod.is_org_level) {
      return ALL;
    }

    return moiMethod.sub_organization_ids;
  };

  return (
    <Formik
      initialValues={{
        display_name: moiMethod?.display_name || '',
        key: moiMethod?.key || '',
        method_type_key: moiMethod?.method_type_key || '',
        sub_organization_ids: getSubOrganizationIdsInitialValues(moiMethod),
        moi_expertises: moiMethod?.moi_expertises || [],
        coverage_keys: moiMethod?.coverages || [],
        lob: moiMethod?.lob || '',
        is_active: moiMethod?.is_active || false,
      }}
      validationSchema={Yup.object().shape({
        display_name: Yup.string().max(50).required('Required'),
        key: Yup.string()
          .max(50)
          .required('Required')
          .test('moi_method_key_unique', 'Must be unique', async function (value) {
            if (moiMethod) {
              // keys are immutable, only verify on creation
              return true;
            }
            const usedMoiKeys = await fetchUsedMoiMethodKeys();
            return !usedMoiKeys.includes(value);
          }),
        method_type_key: Yup.string().required('Required'),
        moi_expertises: Yup.array().required('Required').min(1, 'Required'),
        sub_organization_ids: Yup.array().test('sub_orgs_ids', 'Required', function (value) {
          return organization.sub_organizations_enabled ? value.length > 0 : true;
        }),
        lob: Yup.string().required('Required').oneOf(supportedClaimTypes, 'Invalid line of business'),
        is_active: Yup.boolean(),
      })}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const isAllSubOrgs = values.sub_organization_ids.indexOf('all') > -1;
          await onSubmit(
            {
              ...values,
              is_org_level: isAllSubOrgs,
              sub_organization_ids: isAllSubOrgs ? [] : values.sub_organization_ids,
            },
            moiMethod?.id
          );
          onClose();
        } catch (error) {
          await errorHandler(error);
          setSubmitting(false);
        }
      }}
    >
      <MoiMethodDialogInner
        organization={organization}
        moiMethod={moiMethod}
        onClose={onClose}
        overrideDisabled={overrideDisabled}
      />
    </Formik>
  );
};

MoiMethodDialog.propTypes = {
  moiMethod: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  supportedClaimTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  fetchUsedMoiMethodKeys: PropTypes.func.isRequired,
  errorHandler: PropTypes.func.isRequired,
  overrideDisabled: PropTypes.bool,
};

export const MoiMethodDialogInner = ({ moiMethod, onClose, organization, overrideDisabled }) => {
  const classes = useStyles();
  const { isSubmitting, handleSubmit, values, setFieldTouched, errors, touched, initialValues } = useFormikContext();
  const subOrgIdToName = subOrgIdToNameDict(organization);
  const isAllSubOrgs = values.sub_organization_ids.indexOf('all') > -1;
  const lobSelected = values['lob'];

  const lobSpecificMethodTypes = lobSelected ? DEFAULT_MOI_METHODS?.lobSelected || {} : {};
  const methodTypes = { ...DEFAULT_MOI_METHODS['all'], lobSpecificMethodTypes };
  const expertiseAvailable = pickBy(
    MOI_EXPERTISE_TYPES,
    (v, _) => !v.included_claim_types || (lobSelected && v.included_claim_types.includes(lobSelected))
  );
  useSetDefaultFieldsOnChange(
    values.display_name,
    {
      key: snakeCase(values.display_name),
    },
    () => ['key'].forEach((field) => setFieldTouched(field, true))
  );

  const shouldDisableFieldFunction = useShouldDisableFormikField(overrideDisabled);
  const mandatorySubOrgs = moiMethod ? (moiMethod.is_org_level ? ['all'] : initialValues.sub_organization_ids) : [];

  return (
    <CardDialog
      isDialog
      title={`${moiMethod ? 'Edit' : 'Add '} MOI Method`}
      fullWidth
      maxWidth="md"
      onClose={onClose}
      preventClose={isSubmitting}
      footerActions={
        <DialogFooterActions disabled={isSubmitting} onClickPrimary={handleSubmit} onClickSecondary={onClose} />
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ marginBottom: 6 }}>
          <div>
            <Typography
              display="block"
              variant="subtitle2"
              style={{ marginBottom: 10, fontWeight: 'bold', color: colorPalette.text.primary }}
            >
              Select Line of Business
            </Typography>
            <div style={{ display: 'flex' }}>
              <LobRadioFormik lobFieldId="lob" showOrgLevelLobs />
            </div>
          </div>
        </Grid>
        {organization.sub_organizations_enabled && (
          <Grid item xs={12} container>
            <Grid item xs={4}>
              <div>
                <Typography
                  display="block"
                  variant="subtitle2"
                  style={{ fontWeight: 'bold', color: colorPalette.text.primary }}
                >
                  Select Sub Organizations
                </Typography>
                <div>
                  <MultiSelectTextFieldFormik
                    id="sub_organization_ids"
                    disabled={shouldDisableFieldFunction(moiMethod?.is_org_level)}
                    options={
                      isAllSubOrgs ? ['all'] : ['all'].concat(organization.sub_organizations.map((subOrg) => subOrg.id))
                    }
                    renderValue={() => ''}
                    renderOption={(subOrgId) =>
                      subOrgId === 'all' ? 'All (including future sub-organizations)' : subOrgIdToName[subOrgId]
                    }
                    withOptionChips
                    disabledOptions={mandatorySubOrgs}
                    fullWidth
                    emptyChoiceValue="all"
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        )}
        <Grid item xs={6}>
          <TextFieldFormik
            id="method_type_key"
            label="Method Type Key"
            disabled={shouldDisableFieldFunction(moiMethod || !lobSelected)}
            className={classes.formTextField}
            select
            fullWidth
          >
            {Object.keys(methodTypes).map((k) => (
              <MenuItem key={k} value={k}>
                {methodTypes[k].desc}
              </MenuItem>
            ))}
          </TextFieldFormik>
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <MultiSelectTextFieldFormik
            id="moi_expertises"
            label="Expertises"
            disabled={shouldDisableFieldFunction(moiMethod || !lobSelected)}
            options={Object.keys(expertiseAvailable)}
            renderValue={() => ''}
            renderOption={(e) => expertiseAvailable[e].desc}
            className={classes.formTextField}
            withOptionChips
            fullWidth
          />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <CoveragesMultiselectFormik
            lobs={values.lob ? [values.lob] : []}
            label="For Coverages"
            subOrganizationIds={isAllSubOrgs ? [] : values.sub_organization_ids}
            coveragesFieldId="coverage_keys"
            disabled={shouldDisableFieldFunction(moiMethod)}
            disableGeneral={true}
            useOrgLevelCoveragesOnly={false}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography display="block" variant="subtitle2" style={{ paddingTop: '8px', fontWeight: 'bold' }}>
            Details
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <div>
            <TextFieldFormik
              id="display_name"
              label="Display Name"
              helperText={
                'The selected name will appear in the UI & reports' +
                (getIn(errors, 'display_name') && getIn(touched, 'display_name')
                  ? ' - ' + getIn(errors, 'display_name')
                  : '')
              }
              disabled={shouldDisableFieldFunction()}
              className={classes.formTextField}
              fullWidth
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <TextFieldFormik
              id="key"
              label="Key"
              helperText={
                'The selected key will be used in api calls' +
                (getIn(errors, 'key') && getIn(touched, 'key') ? ' - ' + getIn(errors, 'key') : '')
              }
              disabled={shouldDisableFieldFunction(moiMethod)}
              className={classes.formTextField}
              fullWidth
            />
          </div>
        </Grid>
      </Grid>
    </CardDialog>
  );
};

MoiMethodDialogInner.propTypes = {
  moiMethod: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  overrideDisabled: PropTypes.bool,
};
