import * as Yup from 'yup';

export const getPreProcessSubReservesConfigArray = (subReservesConfigObject) => {
  return Object.entries(subReservesConfigObject).map(([type_key, { desc, is_hidden }]) => ({
    type_key,
    desc,
    is_hidden,
  }));
};

export const getPreProcessedSubReservesBeforeSubmit = (subReservesArray) => {
  return subReservesArray.reduce((preProcessedObject, element) => {
    preProcessedObject[element.type_key] = { desc: element.desc, is_hidden: element.is_hidden };
    return preProcessedObject;
  }, {});
};

export const getSubReservesTypesNestedScheme = () => {
  return Yup.object().shape({
    type_key: Yup.string().required('Required'),
    desc: Yup.string().required('Required'),
    is_hidden: Yup.boolean(),
  });
};
