import React from 'react';

import { useSysconfig } from '../SystemConfigurationScreen';

import SpecialUsersContainer from './SpecialUsersContainer';

const OrganizationSpecialUsers = () => {
  const { organization } = useSysconfig();
  const url = `/sysconfig/api/v1/organizations/${organization.id}/special_users`;

  return (
    <SpecialUsersContainer
      generateFetchUrl={() => url}
      editable
      entityLabelSingular="Special User"
      entityLabelPlural="Special Users"
    />
  );
};

export default OrganizationSpecialUsers;
