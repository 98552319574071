import * as Yup from 'yup';

import { ORG_UNIT_TYPE, UNIT_FIELDS } from '../consts';
import { getUnitMembersIds, getUnitType } from '../Utils';

export const getUnitFields = ({ unit = {} }) => {
  return {
    [UNIT_FIELDS.id]: unit[UNIT_FIELDS.id] || '',
    [UNIT_FIELDS.name]: unit[UNIT_FIELDS.name] || '',
    [UNIT_FIELDS.description]: unit[UNIT_FIELDS.description] || '',
    [UNIT_FIELDS.parentUnitId]: unit[UNIT_FIELDS.parentUnitId] || '',
    [UNIT_FIELDS.leaderUserId]: unit[UNIT_FIELDS.leaderUserId] || '',
    [UNIT_FIELDS.members]: getUnitMembersIds({ unit }),
    [UNIT_FIELDS.unitType]: getUnitType({ unit }),
  };
};
export const getUnitValidationFields = () => {
  return {
    [UNIT_FIELDS.name]: Yup.string().required('Required'),
    [UNIT_FIELDS.unitType]: Yup.string().required('Required'),
    [UNIT_FIELDS.parentUnitId]: Yup.number().when(UNIT_FIELDS.unitType, {
      is: ORG_UNIT_TYPE.SUB_UNIT,
      then: Yup.number().required('Parent Unit must be set for sub-unit type'),
      otherwise: Yup.number(),
    }),
    [UNIT_FIELDS.leaderUserId]: Yup.string().required('Required'),
  };
};

export const isUserSelected = ({ members, userId }) => {
  return !!members.find((id) => id === userId);
};
