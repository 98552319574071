import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Breadcrumbs } from '@material-ui/core';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';

import WithConfirm from '~/components/ConfirmModal';
import { Text } from '~/components/core';

import copies from './copies.json';

interface ConfigurationBreadcrumbsProps {
  onCancel: () => void;
  externalAdminRoute: string;
  configurationName: string;
}

const ConfigurationBreadcrumbs: React.FC<ConfigurationBreadcrumbsProps> = ({
  onCancel,
  externalAdminRoute,
  configurationName,
}) => {
  const match = useRouteMatch();
  const formikProps = useFormikContext();
  const values: { display_name?: string } = formikProps?.values || {};
  const currentTab = values?.display_name || '';

  const isInsideOperationsTab = match.url.split('/').includes('operations');
  const externalAdminUrl = match.url.replace(externalAdminRoute, '');

  return (
    <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
      <Link
        color="inherit"
        to={isInsideOperationsTab ? '/sysconfig/operations' : externalAdminUrl}
        className="text-slate-900 no-underline"
      >
        {isInsideOperationsTab ? 'Operations' : 'Organization Details'}
      </Link>
      <WithConfirm
        title={copies.exit_confirm.title}
        contentText={copies.exit_confirm.text}
        primaryButtonName="YES, LEAVE"
        shouldCloseOnPrimary
        centerDialog
        disableConfirm={isEmpty(formikProps?.touched)}
      >
        <Link color="inherit" onClick={onCancel} className="text-slate-900 no-underline" to={{}}>
          {configurationName}
        </Link>
      </WithConfirm>

      {currentTab && (
        <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.SEMI_BOLD}>
          {currentTab}
        </Text>
      )}
    </Breadcrumbs>
  );
};

export default ConfigurationBreadcrumbs;
