import React from 'react';

import Typography from '~/components/core/Atomic/Typography';

import { PERFORMANCE_QA } from '../../../../../../../Types';
import { ADJUDICATION_CATEGORY, PAYMENTS_CATEGORY, PREDEFINED_TYPE, TIMELINESS_CATEGORY } from '../../constants';
import { getPerformanceQuestions } from '../../utils';
import Questions from '../Questions';

import styles from '../sections.module.scss';

const TIMELINESS_QUESTIONS = getPerformanceQuestions(PREDEFINED_TYPE, TIMELINESS_CATEGORY);
const PAYMENTS_QUESTIONS = getPerformanceQuestions(PREDEFINED_TYPE, PAYMENTS_CATEGORY);
const ADJUDICATION_QUESTIONS = getPerformanceQuestions(PREDEFINED_TYPE, ADJUDICATION_CATEGORY);

const Predefined = () => (
  <div>
    <Typography display="block" className={styles.sectionTitle}>
      Select predefined Yes/No questions.
    </Typography>
    <Questions questions={TIMELINESS_QUESTIONS} categoryName={PERFORMANCE_QA.CATEGORIES.TIMELINESS.desc} />
    <Questions questions={PAYMENTS_QUESTIONS} categoryName={PERFORMANCE_QA.CATEGORIES.PAYMENTS.desc} />
    <Questions questions={ADJUDICATION_QUESTIONS} categoryName={PERFORMANCE_QA.CATEGORIES.ADJUDICATION.desc} />
  </div>
);

export default Predefined;
