import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Grid from '~/components/core/Atomic/Grid/Grid';

import SwitchPanel from '../../../core/ConfigPanel/SwitchPanel';
import TooltipIcon from '../../../core/TooltipIcon';
import { PencilIcon } from '../../../icons';
import InlineIconButton from '../../../InlineIconButton';

import { useStyles } from '../../../../assets/styles';
import styles from './QueueManagementTab.module.scss';

const QueueManagementConfigurableOption = ({
  isInitiated,
  isEnabled,
  onChangeToggle,
  configurationView,
  EditConfigurationDialogComponent,
  onSubmitDialog,
  configuration,
  showOnly,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const classes = useStyles();

  const handleStatusChangeClicked = () => {
    if (!isInitiated && EditConfigurationDialogComponent) {
      setIsDialogOpen(true);
      return;
    }

    onChangeToggle(!isEnabled);
  };

  return (
    <>
      <SwitchPanel
        className={styles.switchPanel}
        checked={isEnabled}
        onChange={handleStatusChangeClicked}
        showOnly={showOnly}
        label={
          <Grid container wrap="nowrap">
            <Grid item style={{ flexGrow: 1 }}>
              {configurationView}
            </Grid>
            {isEnabled && EditConfigurationDialogComponent && isInitiated ? (
              <Grid item>
                <TooltipIcon title="Edit Configuration">
                  <InlineIconButton
                    icon={PencilIcon}
                    className={classes.hoverableNonFilledIcon}
                    onClick={() => setIsDialogOpen(true)}
                    disabled={showOnly}
                  />
                </TooltipIcon>
              </Grid>
            ) : null}
          </Grid>
        }
      />

      {isDialogOpen && (
        <EditConfigurationDialogComponent
          configuration={configuration}
          onSubmit={onSubmitDialog}
          onCancel={() => setIsDialogOpen(false)}
        />
      )}
    </>
  );
};

QueueManagementConfigurableOption.propTypes = {
  isInitiated: PropTypes.bool,
  isEnabled: PropTypes.bool,
  onChangeToggle: PropTypes.func,
  configurationView: PropTypes.node,
  EditConfigurationDialogComponent: PropTypes.elementType,
  onSubmitDialog: PropTypes.func,
  configuration: PropTypes.object,
  showOnly: PropTypes.bool,
};

export default QueueManagementConfigurableOption;
