import React from 'react';
import PropTypes from 'prop-types';
import { Formik, useFormikContext } from 'formik';
import { isEmpty, noop, pickBy } from 'lodash';
import * as Yup from 'yup';

import AlertBanner from '~/components/core/AlertBanner';
import Grid from '~/components/core/Atomic/Grid/Grid';
import DialogFooterActions from '~/components/core/DialogFooterActions';

import CardDialog from '../../../../../../CardDialog';
import { getAllSearchableContactRoles } from '../../../../../../communications/ContactUtils';
import ContactTextFieldFormik from '../../../../../../ContactTextFieldFormik';
import { Text } from '../../../../../../core';
import TextFieldFormik from '../../../../../../TextFieldFormik';
import { useSysconfig } from '../../../../../SystemConfigurationScreen';
import { LIMITS_FIELD_IDS } from '../../ApiLimits';

import { PermissionSetCollapsibleTile } from './VendorPermissionCollapsibleTile/PermissionSetCollapsibleTile';
import {
  buildApiPermissionBools,
  buildEventBools,
  DEFAULT_API_PERMISSIONS,
  DEFAULT_EVENTS,
  FIELD_IDS,
  PERMISSION_SETS,
} from './Utils';

import { useStyles } from '../../../../../../../assets/styles';
import styles from '../vendorApiProfile.module.scss';

const INFO_NOTE =
  'Set up the service providers permissions. The Assignment permissions are enabled by default and cannot be changed.';

const VendorApiProfileDialogInner = ({ isNew, onClose = noop }) => {
  const classes = useStyles();
  const { isSubmitting, handleSubmit } = useFormikContext();
  const { organization, organizationOperationalDetails } = useSysconfig();

  return (
    <CardDialog
      title={`${isNew ? 'Add' : 'Edit'} Service Provider Profile`}
      isDialog
      preventClose={isSubmitting}
      maxWidth="md"
      fullWidth
      onClose={onClose}
      containerClassName={styles.dialogContentContainer}
      footerActions={
        <DialogFooterActions disabled={isSubmitting} onClickPrimary={handleSubmit} onClickSecondary={onClose} />
      }
    >
      <div className={styles.profileFormContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ContactTextFieldFormik
              id={FIELD_IDS.CONTACT}
              label="Contact"
              acceptedRoles={getAllSearchableContactRoles(
                organizationOperationalDetails?.organizationContactRolesDict
              ).concat(['user'])} // to avoid re-creation of already existing CMS-users, show the contacts here (although adding them to the claim will have no visible effect)
              fullWidth
              fixedSearchResults
              outOfContactsContextSearch
              overrideOfContactsContextSearchOrganizationId={organization.id}
              disabled={isSubmitting}
              showOnly={!isNew}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldFormik
              id={FIELD_IDS.CALLBACK_URL}
              label="Webhook Address (Callback URL)"
              className={classes.textField}
              fullWidth
              disabled={isSubmitting}
            />
          </Grid>
          {isNew ? (
            <Grid item xs={12} className={styles.alertBanner}>
              <AlertBanner alertType={AlertBanner.ALERT_TYPES.INFO} title="Please Note" note={INFO_NOTE} withIcon />
            </Grid>
          ) : (
            <Grid item xs={12} className={styles.alertBannerPlaceHolder} />
          )}
        </Grid>
        <div className={styles.sectionsContainer}>
          <Text variant={Text.VARIANTS.LG} weight={Text.WEIGHTS.SEMI_BOLD} colorVariant={Text.COLOR_VARIANTS.BLACK}>
            Permission Sets
          </Text>
          {PERMISSION_SETS.map((permissionSet) => (
            <PermissionSetCollapsibleTile
              key={permissionSet.title}
              permissionSet={permissionSet}
              isStartOpen={isNew}
              ChildComponent={permissionSet.ChildComponent}
            />
          ))}
        </div>
      </div>
    </CardDialog>
  );
};

VendorApiProfileDialogInner.propTypes = {
  isNew: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

const VendorApiProfileDialog = ({ vendorApiProfile = {}, onClose = noop, handleFormSubmit = noop }) => (
  <Formik
    initialValues={{
      [FIELD_IDS.CONTACT_ID]: vendorApiProfile?.contact?.id || '',
      [FIELD_IDS.CONTACT_FULL_NAME]: vendorApiProfile?.contact?.full_name || '',
      [FIELD_IDS.CONTACT]: vendorApiProfile?.contact || '',
      [FIELD_IDS.API_PERMISSION_BOOLS]: buildApiPermissionBools(
        !isEmpty(vendorApiProfile) ? vendorApiProfile.api_permissions : DEFAULT_API_PERMISSIONS
      ),
      [FIELD_IDS.CALLBACK_URL]: vendorApiProfile?.callback_url || '',
      [FIELD_IDS.EVENT_BOOLS]: buildEventBools(!isEmpty(vendorApiProfile) ? vendorApiProfile.events : DEFAULT_EVENTS),
      [LIMITS_FIELD_IDS.INDEMNITY_RESERVES_LIMIT]: vendorApiProfile?.service_account?.indemnity_reserves_limit || 0,
      [LIMITS_FIELD_IDS.INDEMNITY_PAYMENTS_LIMIT]: vendorApiProfile?.service_account?.indemnity_payments_limit || 0,
      [LIMITS_FIELD_IDS.EXPENSES_RESERVES_LIMIT]: vendorApiProfile?.service_account?.expenses_reserves_limit || 0,
      [LIMITS_FIELD_IDS.EXPENSES_PAYMENTS_LIMIT]: vendorApiProfile?.service_account?.expenses_payments_limit || 0,
      [LIMITS_FIELD_IDS.RESERVES_LIMIT]: vendorApiProfile?.service_account?.reserves_limit || 0,
      [LIMITS_FIELD_IDS.PAYMENTS_LIMIT]: vendorApiProfile?.service_account?.payments_limit || 0,
    }}
    validationSchema={Yup.object().shape({
      [FIELD_IDS.CONTACT_ID]: Yup.number().required('Required'),
      [FIELD_IDS.API_PERMISSION_BOOLS]: Yup.object(),
      [FIELD_IDS.CALLBACK_URL]: Yup.string().required('Required'),
      [FIELD_IDS.EVENT_BOOLS]: Yup.object(),
      [LIMITS_FIELD_IDS.INDEMNITY_PAYMENTS_LIMIT]: Yup.number().nullable(),
      [LIMITS_FIELD_IDS.INDEMNITY_RESERVES_LIMIT]: Yup.number().nullable(),
      [LIMITS_FIELD_IDS.EXPENSES_PAYMENTS_LIMIT]: Yup.number().nullable(),
      [LIMITS_FIELD_IDS.EXPENSES_RESERVES_LIMIT]: Yup.number().nullable(),
      [LIMITS_FIELD_IDS.PAYMENTS_LIMIT]: Yup.number().nullable(),
      [LIMITS_FIELD_IDS.RESERVES_LIMIT]: Yup.number().nullable(),
    })}
    onSubmit={async (values, formikProps) => {
      await handleFormSubmit({
        [FIELD_IDS.CONTACT_ID]: values[FIELD_IDS.CONTACT_ID],
        api_permissions: Object.keys(pickBy(values[FIELD_IDS.API_PERMISSION_BOOLS], (v) => v === true)),
        [FIELD_IDS.CALLBACK_URL]: values[FIELD_IDS.CALLBACK_URL],
        events: Object.keys(pickBy(values[FIELD_IDS.EVENT_BOOLS], (v) => v === true)),
        limits: {
          [LIMITS_FIELD_IDS.INDEMNITY_PAYMENTS_LIMIT]: values[LIMITS_FIELD_IDS.INDEMNITY_PAYMENTS_LIMIT],
          [LIMITS_FIELD_IDS.INDEMNITY_RESERVES_LIMIT]: values[LIMITS_FIELD_IDS.INDEMNITY_RESERVES_LIMIT],
          [LIMITS_FIELD_IDS.EXPENSES_PAYMENTS_LIMIT]: values[LIMITS_FIELD_IDS.EXPENSES_PAYMENTS_LIMIT],
          [LIMITS_FIELD_IDS.EXPENSES_RESERVES_LIMIT]: values[LIMITS_FIELD_IDS.EXPENSES_RESERVES_LIMIT],
          [LIMITS_FIELD_IDS.PAYMENTS_LIMIT]: values[LIMITS_FIELD_IDS.PAYMENTS_LIMIT],
          [LIMITS_FIELD_IDS.RESERVES_LIMIT]: values[LIMITS_FIELD_IDS.RESERVES_LIMIT],
        },
      });
      formikProps.setSubmitting(false);
    }}
  >
    <VendorApiProfileDialogInner isNew={isEmpty(vendorApiProfile)} onClose={onClose} />
  </Formik>
);

VendorApiProfileDialog.propTypes = {
  onClose: PropTypes.func,
  handleFormSubmit: PropTypes.func,
  vendorApiProfile: PropTypes.object,
};

export default VendorApiProfileDialog;
