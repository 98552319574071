import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Grid from '~/components/core/Atomic/Grid/Grid';

import { NOTIFICATIONS_RULES_TIMEFRAMES } from '../../../../../Types';
import { ShowOnlyTextField } from '../../../../TextFieldFormik';
import { TextCell } from '../TableCells/TextCell';
import { getConditionsWithGroupForSelectedCriteria, joinDescriptionsOfObjectsList } from '../utils';

import { useStyles } from '../../../../../assets/styles';

export const ViewConditionDetails = ({ condition, conditionsConfig }) => {
  const classes = useStyles();
  const conditionsWithGroupForSelectedCriteria = getConditionsWithGroupForSelectedCriteria(
    conditionsConfig[condition.criteria_key]
  );
  const conditionKey = condition.condition_key;
  const conditionKeyTypesMap = _.keyBy(conditionsWithGroupForSelectedCriteria[conditionKey]?.textValues || {}, 'key');
  const conditionKeyValueType = conditionsWithGroupForSelectedCriteria[conditionKey]?.additionalValueType;

  return (
    <Grid container spacing={2} style={{ marginTop: '5px' }}>
      <Grid item xs={3}>
        <ShowOnlyTextField
          classes={classes}
          showOnlyValueComponent={<TextCell value={conditionsConfig[condition.criteria_key].description} />}
          label="Object"
        />
      </Grid>
      {condition.criteria_key && (
        <>
          {conditionsConfig[condition.criteria_key].has_type && (
            <Grid item xs={2} style={{ marginBottom: '5px' }}>
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={<TextCell value={joinDescriptionsOfObjectsList(condition.criteria_types)} />}
                label="Type"
              />
            </Grid>
          )}
          {conditionsWithGroupForSelectedCriteria[conditionKey] && (
            <Grid item xs={3} style={{ marginBottom: '5px' }}>
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={<TextCell value={conditionsWithGroupForSelectedCriteria[conditionKey]?.desc} />}
                label="Condition"
              />
            </Grid>
          )}
          {conditionKeyValueType === 'text' && (
            <Grid item xs={2}>
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={
                  <TextCell
                    value={conditionKeyTypesMap[condition.condition_text_value]?.desc || condition.condition_text_value}
                  />
                }
                label="Value"
              />
            </Grid>
          )}
          {conditionKeyValueType === 'numeric' && (
            <Grid item xs={2}>
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={<TextCell value={condition.condition_numeric_value} />}
                label="Number"
              />
            </Grid>
          )}
          {conditionKeyValueType === 'schedule' && (
            <Grid item xs={2}>
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={<TextCell value={condition.condition_schedule_value} />}
                label="Number"
              />
            </Grid>
          )}
          {conditionKeyValueType === 'schedule' && (
            <Grid item xs={2}>
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={
                  <TextCell value={NOTIFICATIONS_RULES_TIMEFRAMES[condition.condition_schedule_timeframe_value].desc} />
                }
                label="Time Frame"
              />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

ViewConditionDetails.propTypes = {
  condition: PropTypes.object.isRequired,
  conditionsConfig: PropTypes.object.isRequired,
};
