import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Grid from '~/components/core/Atomic/Grid/Grid';

import { Heading } from '../../../core';
import { LobMultiSelect } from '../../../TPA/LOB/LobMultiselect';
import SubOrganizationMultiSelect from '../../../TPA/SubOrganizations/SubOrganizationMultiSelect';
import { useSysconfig } from '../../SystemConfigurationScreen';

const FilterRules = ({ onFilterChanged }) => {
  const { organization } = useSysconfig();
  const [selectedSubOrgIds, setSelectedSubOrgIds] = useState([]);
  const [selectedLobs, setSelectedLobs] = useState([]);

  useEffect(() => {
    onFilterChanged({
      subOrgIds: selectedSubOrgIds,
      lobs: selectedLobs,
    });
  }, [selectedSubOrgIds, selectedLobs, onFilterChanged]);

  const handleSubOrgChanged = (subOrgIds) => {
    setSelectedSubOrgIds(subOrgIds);
  };
  const handleLobsChanged = (lobs) => {
    setSelectedLobs(lobs);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Heading variant={Heading.TYPES.H4}>Filters</Heading>
        <Grid container spacing={4}>
          {organization.sub_organizations_enabled && (
            <Grid item xs={4}>
              <SubOrganizationMultiSelect onChange={handleSubOrgChanged} />
            </Grid>
          )}
          <Grid item xs={4}>
            <LobMultiSelect subOrganizationIds={selectedSubOrgIds} onChange={handleLobsChanged} />
          </Grid>
          <Grid item xs={4} />
        </Grid>
      </Grid>
    </Grid>
  );
};

FilterRules.propTypes = {
  onFilterChanged: PropTypes.func.isRequired,
};

export default FilterRules;
