import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { isEmpty } from 'lodash';

import { useStyles } from '~/assets/styles';
import AlertBanner from '~/components/core/AlertBanner';
import Button from '~/components/core/Atomic/Buttons/Button';
import { ThreeDotsHorizontalIcon } from '~/components/icons';
import { INTEGRATION_STATUS_DICT } from '~/Types';
import { reportAxiosError } from '~/Utils';
import cn from '~/Utils/cn';

import CardDialog from '../../../../CardDialog';
import { LoadingSwitch } from '../../../../core';
import FsIconButton from '../../../../core/FsWrappers/FsIconButton/FsIconButton';
import { useSysconfig } from '../../../SystemConfigurationScreen';
import ConfigurationTable from '../../ConfigurationTable';
import { useCCC } from '../hooks/useCCC.jsx';

import CCCInfoDialog from './InfoDialog';
import CCCSetupDialog from './SetupDialog';

const InternalAdminCCCWrapper = () => {
  const { organization } = useSysconfig();
  const [setupFormOpen, setSetupFormOpen] = useState(false);
  const [isInfoDialogOpen, setInfoDialogOpen] = useState(false);
  const [infoDialogConfig, setInfoDialogConfig] = useState();
  const {
    isLoading,
    isError,
    isEnabled,
    reloadData: reloadConfigurations,
    cccOrgConfig,
    isConfigurationExists,
  } = useCCC();

  const handleSetupSubmit = async (values) => {
    try {
      await axios({
        method: cccOrgConfig?.id ? 'put' : 'post',
        url: `/api/v1/ccc_integration_configuration/${organization.id}${
          cccOrgConfig?.id ? '/' + cccOrgConfig?.id : ''
        }`,
        data: values,
      });
      await reloadConfigurations();
      handleCloseSetupForm();
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
  };

  const handleSetupFormClick = () => {
    setSetupFormOpen(true);
  };

  const handleCloseSetupForm = () => {
    setSetupFormOpen(false);
  };

  const handleMoreDetailsClick = (config) => {
    setInfoDialogOpen(true);
    setInfoDialogConfig(config);
  };

  const webhookDetailsColumn = {
    id: 'actions',
    numeric: false,
    label: 'Actions',
    disableSort: true,
    align: 'right',
    specialCell: (config) => (
      <FsIconButton
        icon={ThreeDotsHorizontalIcon}
        tooltipText="More details"
        onClick={() => handleMoreDetailsClick(config)}
        disabled={config.status !== INTEGRATION_STATUS_DICT['active']}
      />
    ),
  };

  return (
    <LoadingSwitch isLoading={isLoading} isError={isError}>
      <CardDialog noCardTitle>
        <EnableCCCButton onSetupFormClick={handleSetupFormClick} isEnabled={isConfigurationExists} />
        {isEnabled &&
          !isEmpty(cccOrgConfig) &&
          (!cccOrgConfig?.company_code || !cccOrgConfig?.client_id || !cccOrgConfig?.client_secret) && (
            <div className="mb-40">
              <AlertBanner
                alertType={AlertBanner.ALERT_TYPES.WARNING}
                note="To enalbe CCC integration, make sure all setup data has been updated"
                withIcon
              />
            </div>
          )}
        <ConfigurationTable
          configurations={cccOrgConfig?.configurations || []}
          additionalColumnsArray={cccOrgConfig ? [webhookDetailsColumn] : []}
        />
      </CardDialog>
      {setupFormOpen && (
        <CCCSetupDialog data={cccOrgConfig} handleSubmit={handleSetupSubmit} onClose={handleCloseSetupForm} />
      )}
      {isInfoDialogOpen && cccOrgConfig && (
        <CCCInfoDialog config={infoDialogConfig} onClose={() => setInfoDialogOpen(false)} />
      )}
    </LoadingSwitch>
  );
};

const EnableCCCButton = ({ onSetupFormClick, isEnabled }) => {
  const classes = useStyles();

  return isEnabled ? (
    <Button variant="contained" className={cn(classes.cancelButton, 'mb-40 mt-40')} onClick={onSetupFormClick}>
      Update Setup Data
    </Button>
  ) : (
    <Button color="primary" variant="contained" className="mb-40 mt-40" onClick={onSetupFormClick}>
      Enable CCC Integration
    </Button>
  );
};

EnableCCCButton.propTypes = {
  onSetupFormClick: PropTypes.func.isRequired,
  isEnabled: PropTypes.bool,
};

export default InternalAdminCCCWrapper;
