import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik } from 'formik';
import { omit } from 'lodash';

import AlertBanner from '~/components/core/AlertBanner';

import { reportAxiosError } from '../../../../../Utils';
import mixpanel from '../../../../CmsMain/mixpanel';
import { useSysconfig } from '../../../SystemConfigurationScreen';
import { PERMISSIONS_MIXPANEL_EVENTS } from '../MixpanelEvents';
import { PROFILES_ROUTES } from '../routes';

import { getFormValidationScheme, profileToInitialValues, saveProfileWithConfirmProps } from './formUtils';
import { ProfileDialogBase } from './ProfileDialogBase';
import { ProfilePermissionsForm } from './ProfilePermissionsForm';

import styles from './styles.module.scss';

export const EditProfileDialog = ({ profile, onClose, reloadData, source }) => {
  const { organization: sysConfigOrganization } = useSysconfig();

  React.useEffect(() => {
    mixpanel.track(PERMISSIONS_MIXPANEL_EVENTS.edit_permission_profile, {
      source,
      profile_id: profile.id,
      profile_name: profile.display_name,
      default_permission: profile.defaults,
      number_of_users: profile.number_of_users,
    });
  }, [profile, source]);

  const handleSubmit = React.useCallback(
    async (data) => {
      await axios.patch(PROFILES_ROUTES.UPDATE(sysConfigOrganization.id, profile.id), data);
    },
    [profile.id, sysConfigOrganization.id]
  );

  const validationScheme = React.useMemo(
    () =>
      getFormValidationScheme({
        organizationId: sysConfigOrganization.id,
        currentProfileName: profile.display_name,
      }),
    [profile.display_name, sysConfigOrganization.id]
  );

  return (
    <Formik
      initialValues={omit(profileToInitialValues(profile), ['users'])}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await handleSubmit(values);
          await reloadData();
          onClose();
        } catch (e) {
          await reportAxiosError(e);
        }
        setSubmitting(false);
      }}
      validationSchema={validationScheme}
      validateOnChange={false}
      validateOnBlur={false}
    >
      <ProfileDialogBase
        onClose={onClose}
        title={EditProfileDialog.DIALOG_TEXTS.title}
        withActions
        primaryWithConfirmProps={saveProfileWithConfirmProps}
        setAllTouchedOnSubmit
      >
        <div className={styles.alertContainer}>
          <EditProfileAlertBanner />
        </div>
        <ProfilePermissionsForm profile={profile} />
      </ProfileDialogBase>
    </Formik>
  );
};

EditProfileDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  reloadData: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  source: PropTypes.string.isRequired,
};

EditProfileDialog.DIALOG_TEXTS = {
  title: 'Edit Permissions Profile',
  subtitle: 'This action will change the permissions setting for all the users that are assigned to this profile',
};

export const EditProfileAlertBanner = () => (
  <AlertBanner note={EditProfileDialog.DIALOG_TEXTS.subtitle} alertType={AlertBanner.ALERT_TYPES.WARNING} withIcon />
);
