import React from 'react';
import PropTypes from 'prop-types';

import { getLobDescription, getLobIcon } from '~/Utils/lobUtils';

import { LoadingSwitch, PlainList } from '../../../../core';
import useDataFetcher from '../../../../useDataFetcher';

const LobsPlainList = ({ organizationId }) => {
  const {
    isLoading,
    isError,
    data = [],
  } = useDataFetcher(`/api/v1/organizations/${organizationId}/available_lobs_with_hidden_indication`);
  const { data: lobConfigurationsDict = {} } = useDataFetcher(
    `/api/v1/organizations/${organizationId}/lob_display_configurations`
  );

  return (
    <LoadingSwitch isLoading={isLoading} isError={isError}>
      <PlainList
        rows={data?.map(({ key: lob, id, is_hidden }) => ({
          node: getLobDescription(lob, lobConfigurationsDict),
          disabled: is_hidden,
          icon: getLobIcon({ lob, lobConfigurationsDict }),
          id,
        }))}
      />
    </LoadingSwitch>
  );
};

LobsPlainList.propTypes = {
  organizationId: PropTypes.number,
};

export default LobsPlainList;
