import React from 'react';
import { useFormikContext } from 'formik';

import StateMultiselectFormikProps from '~/components/core/Formik/StatesMultiselectFormik/StateMultiSelectFormik';
import { ADVANCED_TEMPLATES_SEARCH_KEYS } from '~/components/GenericTemplates/utils/genericTemplatesUtils';
import useOrganization from '~/components/OrganizationContext';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import { CoveragesMultiselectFormik } from '~/components/TPA/Coverages/CoveragesMultiselectFormik';
import LobMultiselectFormik from '~/components/TPA/LOB/LobMultiselectFormik';
import SubOrganizationMultiselectFormik from '~/components/TPA/SubOrganizations/SubOrganizationMultiselectFormik';
import { isOrganizationUsOrg } from '~/Utils';
import cn from '~/Utils/cn';

const GenericTemplateAdvancedSearchFiltersFormik: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { supportedClaimTypes } = useOrganization();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization } = useSysconfig();
  const { isSubmitting } = useFormikContext();

  const subOrganizationIds = organization.sub_organizations.map((sub_org: { id: number }) => sub_org.id);

  const isOrgUsOrg = isOrganizationUsOrg(organization);

  return (
    <>
      <div className={cn('mb-32 grid gap-32', isOrgUsOrg ? 'grid-cols-4' : 'grid-cols-3')}>
        <div>
          <CoveragesMultiselectFormik
            coveragesFieldId={ADVANCED_TEMPLATES_SEARCH_KEYS.COVERAGE_KEYS}
            lobs={supportedClaimTypes}
            label="Select Coverages"
            subOrganizationIds={subOrganizationIds}
            disabled={isSubmitting}
            disableGeneral
          />
        </div>
        {isOrgUsOrg ? (
          <div>
            <StateMultiselectFormikProps
              statesFieldId={ADVANCED_TEMPLATES_SEARCH_KEYS.STATES}
              disabled={isSubmitting}
            />
          </div>
        ) : null}
        <div>
          <SubOrganizationMultiselectFormik
            subOrganizationsFieldId={ADVANCED_TEMPLATES_SEARCH_KEYS.SUB_ORGANIZATION_IDS}
            disabled={isSubmitting}
          />
        </div>
        <div>
          <LobMultiselectFormik
            lobFieldId={ADVANCED_TEMPLATES_SEARCH_KEYS.LOBS}
            disabled={isSubmitting}
            subOrganizationIds={organization.sub_organizations_enabled ? subOrganizationIds : []}
          />
        </div>
      </div>
    </>
  );
};

export default GenericTemplateAdvancedSearchFiltersFormik;
