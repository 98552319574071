import React from 'react';

import { useAdjustersData } from './Hooks/useAdjustersData';
import { OrganizationAdjusters } from './OrganizationAdjusters/OrganizationAdjusters';

export function OrganizationAdjustersAdmin() {
  const { adjusters, isError, isLoading, reloadAdjusters } = useAdjustersData();

  return (
    <OrganizationAdjusters
      adjusters={adjusters}
      isError={isError}
      isLoading={isLoading}
      reloadAdjusters={reloadAdjusters}
    />
  );
}
