import React, { useState } from 'react';

import CardDialog from '~/components/CardDialog';
import { Heading } from '~/components/core';
import AddRoleButton from '~/components/SystemConfiguration/ContactRoles/AddRoleButton';
import ContactRolesList from '~/components/SystemConfiguration/ContactRoles/ContactRolesList';
import RolesFilter from '~/components/SystemConfiguration/ContactRoles/RolesFilter';
import OperationsBreadcrumbs from '~/components/SystemConfiguration/OperationsBreadcrumbs';

const ContactRolesConfiguration: React.FC = () => {
  const [rolesSearchTerm, setRolesSearchTerm] = useState('');
  const [showOnlyCustomRoles, setShowOnlyCustomRoles] = useState(false);

  return (
    <CardDialog noCardTitle>
      <OperationsBreadcrumbs currentTab="Contact Roles" />
      <div className="grid grid-cols-2 items-center">
        <Heading className="mt-20">Contact Roles</Heading>
        <AddRoleButton className="justify-self-end" />
      </div>
      <RolesFilter
        onSearchTermChanged={(term) => setRolesSearchTerm(term)}
        onShowOnlyCustomRolesChanged={(showOnly) => setShowOnlyCustomRoles(showOnly)}
      />
      <ContactRolesList searchTerm={rolesSearchTerm} showOnlyCustomRoles={showOnlyCustomRoles} />
    </CardDialog>
  );
};

export default ContactRolesConfiguration;
