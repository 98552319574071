import PropTypes from 'prop-types';

export const unitShape = {
  id: PropTypes.number,
  organization_id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  leader_user_name: PropTypes.string,
  parent_unit_name: PropTypes.string,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      member_user_id: PropTypes.string,
      member_user_name: PropTypes.string,
    })
  ),
  is_active: PropTypes.bool,
};
