import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import CancelButton from '~/components/core/Buttons/CancelButton';
import SearchWithOptionsFormik from '~/components/core/Formik/SearchWithOptionsFormik/SearchWithOptionsFormik';

import CardDialog from '../../../../CardDialog';
import { SortableTable } from '../../../../core';
import HoverActionField from '../../../../HoverActionField';

import { useStyles } from '../../../../../assets/styles';

const MAIN_FIELD_ID = 'user_sids';

const EditAdjustersInQueue = ({ queue, users, onSubmit, onCancel }) => {
  const classes = useStyles();

  const options = [
    {
      entries: users.map((user) => ({
        id: user.sid,
        entryTitle: user.name,
        isDisabled: false,
      })),
    },
  ];

  return (
    <Formik
      initialValues={{
        [MAIN_FIELD_ID]: queue.workers.map((worker) => worker.sid),
      }}
      validationSchema={Yup.object().shape({
        [MAIN_FIELD_ID]: Yup.array().of(Yup.string()),
      })}
      onSubmit={async (values, formikProps) => {
        try {
          await onSubmit({ queue_sid: queue.queue_sid, user_sids: values.user_sids });
          onCancel();
        } catch {
          formikProps.setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, handleSubmit }) => {
        return (
          <CardDialog
            title={`Assign Adjusters to Queue ${queue.queue_name}`}
            isDialog
            maxWidth="sm"
            fullWidth
            onClose={onCancel}
            preventClose={isSubmitting}
          >
            <SearchWithOptionsFormik
              isSelectAllEnabled={false}
              options={options}
              mainFieldId={MAIN_FIELD_ID}
              label="Search Queues"
            />
            <div className={classes.buttonsContainer}>
              <CancelButton disabled={isSubmitting} onClick={onCancel} />
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

EditAdjustersInQueue.propTypes = {
  queue: PropTypes.object,
  users: PropTypes.array,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

const queueViewColumnsData = (setDialogData) => [
  { id: 'queue_name', label: 'Queue name' },
  {
    id: 'workers_count',
    label: 'Assigned Users',
    specialCell: (row) => {
      return (
        <HoverActionField
          onAction={() => {
            setDialogData(row);
          }}
          permanent
        >
          {row.workers_count}
        </HoverActionField>
      );
    },
  },
];

const QueueViewTable = ({ data, onSubmit }) => {
  const [dialogData, setDialogData] = useState(false);

  return (
    <>
      <CardDialog title="Queue View" maxWidth="lg">
        <div>
          <SortableTable columns={queueViewColumnsData(setDialogData)} rows={data.queues} />
        </div>
      </CardDialog>
      {dialogData ? (
        <EditAdjustersInQueue
          queue={dialogData}
          users={data.users}
          onCancel={() => setDialogData(null)}
          onSubmit={onSubmit}
        />
      ) : null}
    </>
  );
};

QueueViewTable.propTypes = {
  data: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default QueueViewTable;
