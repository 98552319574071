import React from 'react';

import { BODILY_INJURIES_FIELD_IDS, INVOLVED_NON_MOTORIST_FIELD_IDS, INVOLVED_PERSON_FIELD_IDS } from '../constants';

import InvolvedEntitySection from './InvolvedEntitySection';

const InvolvedNonMotoristSection = (): JSX.Element => (
  <InvolvedEntitySection
    entityId="involved_non_motorist"
    entityDesc="Involved Non Motorist"
    baseInvolvedFields={INVOLVED_PERSON_FIELD_IDS}
    involvedFields={INVOLVED_NON_MOTORIST_FIELD_IDS}
    itemsIdPrefix="bodily_injuries"
    itemsFieldIds={BODILY_INJURIES_FIELD_IDS}
    withIcdCodes
  />
);

export default InvolvedNonMotoristSection;
