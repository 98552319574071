const COMMUNICATION_TEMPLATE_ACTION = {
  SENDER_TYPE: {
    CLAIM_OWNER: 'Claim owner',
    EXPOSURE_OWNER: 'Exposure owner',
    CLAIM_OWNER_SUPERVISOR: 'Claim owner supervisor',
    EXPOSURE_OWNER_SUPERVISOR: 'Exposure owner supervisor',
  },
  RECIPIENT: {
    INSURED: 'Insured',
    CLAIMANT: 'Claimant',
    PREFERRED_CONTACT: 'Preferred contact',
    ATTORNEY: 'Attorney',
  },
  CHANNEL: {
    SMS: 'SMS',
    EMAIL: 'Email',
  },
} as const;

export default COMMUNICATION_TEMPLATE_ACTION;
