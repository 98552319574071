import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import Grid from '~/components/core/Atomic/Grid/Grid';

import SwitchPanel from '../../../../core/ConfigPanel/SwitchPanel';
import TooltipIcon from '../../../../core/TooltipIcon';
import { PencilIcon } from '../../../../icons';
import InlineIconButton from '../../../../InlineIconButton';

import { PAYMENTS_CONFIG_FIELDS } from './constants';

import { useStyles } from '../../../../../assets/styles';

const ConfigSwitch = ({
  title,
  configKey,
  paymentsConfiguration = {},
  onChange = noop,
  disabled = true,
  hasAdditionalFields = false,
  AdditionalFieldsDialog,
  children,
  isInitialized = true,
  isSelected = !!paymentsConfiguration?.[configKey],
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const classes = useStyles();

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleSubmitDialog = async (values) => {
    try {
      await onChange({ ...values, [configKey]: true }, true);
      handleCancelDialog();
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const handleCancelDialog = () => {
    setIsDialogOpen(false);
  };

  const handleSwitchChanged = async () => {
    if (!isInitialized && !isSelected && AdditionalFieldsDialog) {
      handleOpenDialog();
    } else {
      await onChange({ [configKey]: !isSelected });
    }
  };

  return (
    <Grid item xs={4}>
      <SwitchPanel
        checked={!!isSelected}
        className={classes.switchPanel}
        size="small"
        onChange={handleSwitchChanged}
        showOnly={disabled}
        label={
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container wrap="nowrap">
                <Grid item style={{ flexGrow: 1 }}>
                  {title}
                </Grid>
                {isSelected && hasAdditionalFields ? (
                  <Grid item>
                    <TooltipIcon title="Edit Configuration">
                      <InlineIconButton
                        icon={PencilIcon}
                        className={classes.hoverableNonFilledIcon}
                        onClick={handleOpenDialog}
                        disabled={disabled}
                      />
                    </TooltipIcon>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            {isSelected && children && (
              <Grid item xs={12}>
                {children}
              </Grid>
            )}
          </Grid>
        }
      />
      {isDialogOpen && AdditionalFieldsDialog && (
        <AdditionalFieldsDialog
          paymentsConfiguration={paymentsConfiguration}
          disabled={disabled}
          onSubmit={handleSubmitDialog}
          onCancel={handleCancelDialog}
        />
      )}
    </Grid>
  );
};

ConfigSwitch.propTypes = {
  configKey: PropTypes.oneOf(Object.values(PAYMENTS_CONFIG_FIELDS)).isRequired,
  paymentsConfiguration: PropTypes.object,
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  hasAdditionalFields: PropTypes.bool,
  AdditionalFieldsDialog: PropTypes.func,
  children: PropTypes.node,
  isInitialized: PropTypes.bool,
  isSelected: PropTypes.bool,
};

export default ConfigSwitch;
