import React from 'react';
import PropTypes from 'prop-types';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';
import CancelButton from '~/components/core/Buttons/CancelButton';
import InnerCard from '~/components/core/Cards/InnerCard';
import SwitchFormik from '~/components/core/Formik/SwitchFormik';
import cn from '~/Utils/cn';

import CardDialog from '../../../../../CardDialog';
import WithConfirm from '../../../../../ConfirmModal';
import TextFieldFormik from '../../../../../TextFieldFormik';

import { useStyles } from '../../../../../../assets/styles';
import styles from '../claimSearchInternalAdmin.module.scss';

const FIELD_IDS = {
  ENABLED: 'enable_claim_search_setup',
  AGENCY_ID: 'agency_id',
  USER_ID: 'customer_login_id',
  PASSWORD: 'customer_password',
  REQUEST_URL: 'request_url',
  RESPONSE_URL: 'response_url',
};

const SetupDialogInner = ({ onClose, isUpdate }) => {
  const classes = useStyles();
  const { isSubmitting, handleSubmit, values } = useFormikContext();

  const isTextFieldDisabled = isSubmitting || !values[FIELD_IDS.ENABLED];

  const SaveBtn = (
    <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
      Save
    </Button>
  );

  return (
    <CardDialog
      containerClassName={styles.claimSearchDialog}
      title="ClaimSearch Integration Setup"
      isDialog
      preventClose={isSubmitting}
      maxWidth="sm"
      fullWidth
      onClose={onClose}
    >
      <InnerCard className={cn(styles.sectionContainer, styles.fixAccessibilityPadding)}>
        <Grid container>
          <Grid item xs={12}>
            <Typography display="block" variant="subtitle1" className={styles.subtitle}>
              Activate ClaimSearch
            </Typography>
            <Grid item xs={12}>
              <SwitchFormik
                checked={!!values[FIELD_IDS.ENABLED]}
                className={classes.formsSwitch}
                id={FIELD_IDS.ENABLED}
                label="Enable ClaimSearch Setup"
              />
            </Grid>
          </Grid>
        </Grid>
      </InnerCard>
      <InnerCard className={styles.sectionContainer}>
        <Grid container>
          <Typography display="block" variant="subtitle1" className={styles.subtitle}>
            Integration Technical Data
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <TextFieldFormik
                id={FIELD_IDS.AGENCY_ID}
                label="Agency Id"
                className={classes.textField}
                fullWidth
                disabled={isTextFieldDisabled}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <TextFieldFormik
                id={FIELD_IDS.USER_ID}
                label="user ID"
                fullWidth
                className={cn(classes.textField, styles.textField)}
                disabled={isTextFieldDisabled}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldFormik
                id={FIELD_IDS.PASSWORD}
                label="Password"
                fullWidth
                className={cn(classes.textField, styles.textField)}
                disabled={isTextFieldDisabled}
                type="password"
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <TextFieldFormik
                id={FIELD_IDS.REQUEST_URL}
                label="Request URL"
                fullWidth
                className={cn(classes.textField, styles.textField)}
                disabled={isTextFieldDisabled}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldFormik
                id={FIELD_IDS.RESPONSE_URL}
                label="Response URL"
                fullWidth
                className={cn(classes.textField, styles.textField)}
                disabled={isTextFieldDisabled}
              />
            </Grid>
          </Grid>
        </Grid>
      </InnerCard>
      <div className={classes.buttonsContainer}>
        <CancelButton disabled={isSubmitting} onClick={onClose} />
        {isUpdate ? (
          <WithConfirm
            title={<div className={styles.confirmTitle}>Are you sure you want to Switch?</div>}
            contentText="Switching mode will reset existing definitions"
            primaryButtonName="Yes, Switch"
            shouldCloseOnPrimary
          >
            {SaveBtn}
          </WithConfirm>
        ) : (
          SaveBtn
        )}
      </div>
    </CardDialog>
  );
};

SetupDialogInner.propTypes = {
  isUpdate: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

const ClaimSearchSetupDialog = ({ handleSubmit, onClose, data }) => {
  const initialValues = {
    [FIELD_IDS.ENABLED]: data.enable_claim_search_setup,
    [FIELD_IDS.AGENCY_ID]: data.agency_id,
    [FIELD_IDS.USER_ID]: data.customer_login_id,
    [FIELD_IDS.PASSWORD]: data.customer_password,
    [FIELD_IDS.RESPONSE_URL]: data.response_url,
    [FIELD_IDS.REQUEST_URL]: data.request_url,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        [FIELD_IDS.REQUEST_URL]: Yup.string().url('Must be a valid URL').nullable(),
        [FIELD_IDS.RESPONSE_URL]: Yup.string().url('Must be a valid URL').nullable(),
      })}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await handleSubmit(values);
        } catch (error) {
          setSubmitting(false);
        }
      }}
    >
      <SetupDialogInner onClose={onClose} data={data} isUpdate={!!data.id} />
    </Formik>
  );
};

ClaimSearchSetupDialog.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default ClaimSearchSetupDialog;
