const COVERAGES_ISO_CODES = {
  ACDD: 'ACCIDENTAL DEATH AND DISMEMBERMENT',
  ACFT: 'AIRCRAFT',
  BODI: 'BODILY INJURY',
  BURG: 'BURGLARY',
  CLPS: 'COLLAPSE',
  CNTP: 'CONTAMINATION/ POLLUTION',
  COLL: 'COLLISION',
  COMP: 'COMPREHENSIVE',
  CRCK: 'CRACKING/RUPTURE',
  DISB: 'DISABILITY',
  DRCT: 'DIRECTORS AND OFFICERS',
  ELEC: 'ELECTRIC CURRENT',
  EROM: 'ERRORS AND OMISSIONS',
  ERPL: 'EMPLOYERS RELATED PRACTICE',
  ERTH: 'EARTHQUAKE/MOVEMENT',
  EXPL: 'EXPLOSION',
  FDTY: 'FIDELITY',
  FIRE: 'FIRE',
  FLOD: 'FLOOD',
  FOBJ: 'FALLING OBJECTS',
  FREZ: 'FREEZING',
  GGKP: 'GARAGEKEEPERS',
  GLSS: 'GLASS',
  HAIL: 'HAIL',
  HEAL: 'HEALTH',
  HULL: 'HULL',
  LGHT: 'LIGHTNING',
  LGTC: 'LONG TERM CARE',
  LIAB: 'LIABILITY',
  LIFE: 'LIFE',
  LOBG: 'LOST BAGGAGE',
  LVMT: 'LIVESTOCK/MORTALITY',
  MOLD: 'MOLD',
  MPAY: 'MEDICAL PAYMENTS',
  MOTR: 'MOTOR',
  MYSD: 'MYSTERIOUS DISAPPEARANCE',
  NFLT: 'PIP/NO FAULT',
  OTAU: 'OTHER AUTO',
  OTLB: 'OTHER LIABILITY',
  OTPR: 'OTHER PROPERTY',
  PINJ: 'PERSONAL INJURY',
  POLL: 'POLLUTION',
  PROD: 'PRODUCTS AND COMPLETED OPERATIONS',
  PRPD: 'PROPERTY DAMAGE',
  PTCI: 'PROTECTION AND INDEMNITY',
  RENT: 'RENTAL REIMBURSEMENT',
  RIOT: 'RIOT/CIVIL COMMOTION',
  ROBB: 'ROBBERY',
  SMOK: 'SMOKE',
  SNKL: 'SINKHOLE',
  THFT: 'THEFT',
  TRSM: 'TERRORISM',
  TOWL: 'TOWING AND LABOR',
  UNDR: 'UNDERINSURED MOTORIST',
  UNIN: 'UNINSURED MOTORIST',
  VAMM: 'VANDALISM AND MALICIOUS MISCHIEF',
  VHCL: 'VEHICLES',
  VOLC: 'VOLCANIC ERUPTION',
  WATR: 'WATER',
  WCIL: 'INDEMNITY',
  WCLL: 'LIABILITY',
  WCML: 'MEDICAL',
  WIND: 'WIND',
  WISS: 'WEIGHT ICE/ SNOW/ SLEET',
} as const;

export default COVERAGES_ISO_CODES;
