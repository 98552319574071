import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import CardDialog from '~/components/CardDialog';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';
import SwitchFormik from '~/components/core/Formik/SwitchFormik';
import { PAYMENTS_CONFIG_FIELDS } from '~/components/SystemConfiguration/Tabs/PaymentsConfiguration/PaymentConfigTab/constants';
import { NumericTextFieldFormik } from '~/components/TextFieldFormik';

const FIELD_IDS = {
  FUZZY_SCORE: PAYMENTS_CONFIG_FIELDS.OFAC_FUZZY_MATCH_SCORE,
  FUZZY_ENABLE: PAYMENTS_CONFIG_FIELDS.OFAC_FUZZY_MATCH_ENABLED,
  ADDRESS_ENABLE: PAYMENTS_CONFIG_FIELDS.OFAC_IS_SEND_ADDRESS_FIELDS_ENABLED,
};

const OfacScreenConfigurationDialog = ({ paymentsConfiguration, disabled = false, onCancel, onSubmit }) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        [FIELD_IDS.FUZZY_ENABLE]: !!paymentsConfiguration?.[FIELD_IDS.FUZZY_ENABLE],
        [FIELD_IDS.ADDRESS_ENABLE]: !!paymentsConfiguration?.[FIELD_IDS.ADDRESS_ENABLE],
        [FIELD_IDS.FUZZY_SCORE]: paymentsConfiguration?.[FIELD_IDS.FUZZY_SCORE] || 100,
      }}
      validationSchema={Yup.object().shape({
        [FIELD_IDS.FUZZY_SCORE]: Yup.number()
          .typeError('Please enter a valid number')
          .required('This field is required')
          .min(1, 'Number must be greater than or equal to 1')
          .max(100, 'Number must be less than or equal to 100')
          .integer('Number must be an integer'),
      })}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        try {
          await onSubmit(values);
        } catch (error) {
          formikProps.setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, values }) => {
        return (
          <CardDialog title="OFAC Configuration" isDialog onClose={onCancel} preventClose={disabled} maxWidth="xs">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <SwitchFormik className={classes.formsSwitch} id={FIELD_IDS.ADDRESS_ENABLE} label="Enable Address" />
              </Grid>
              <Grid item xs={12}>
                <SwitchFormik className={classes.formsSwitch} id={FIELD_IDS.FUZZY_ENABLE} label="Enable Fuzzy Match" />
              </Grid>
              <Grid item xs={12}>
                <NumericTextFieldFormik
                  id={FIELD_IDS.FUZZY_SCORE}
                  fullWidth
                  className={classes.textField}
                  label="score"
                  disabled={!values[FIELD_IDS.FUZZY_ENABLE]}
                />
              </Grid>
            </Grid>
            <div className={classes.buttonsContainer}>
              <CancelButton disabled={disabled} onClick={onCancel} />
              <Button variant="contained" color="primary" disabled={disabled} onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

OfacScreenConfigurationDialog.propTypes = {
  paymentsConfiguration: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default OfacScreenConfigurationDialog;
