import React, { useState } from 'react';

import PencilIcon from '../../../../../icons/PencilIcon';
import InlineIconButton from '../../../../../InlineIconButton';
import BaseConfigurationSection from '../BaseConfigurationSection';

import IncidentTypesAndSubTypesDialog from './IncidentTypesAndSubTypesDialog';
import IncidentTypesAndSubTypesPreview from './IncidentTypesAndSubTypesPreview';

import { useStyles } from '../../../../../../assets/styles';

const IncidentTypesAndSubTypesSection = () => {
  const classes = useStyles();
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  return (
    <BaseConfigurationSection
      title="Incident Types and Sub-types"
      subtitle={<div className="p-12" />}
      noGridContainer
      hideActionsOnCollapse
      actions={
        <div className="flex justify-end">
          <InlineIconButton
            icon={PencilIcon}
            className={classes.hoverableNonFilledIcon}
            onClick={(e) => {
              setEditDialogOpen(true);
              e.stopPropagation();
            }}
            tooltipTitle="Edit Incident Types and Sub-types"
          />
        </div>
      }
      isSection
    >
      <IncidentTypesAndSubTypesPreview />
      {editDialogOpen && <IncidentTypesAndSubTypesDialog onClose={() => setEditDialogOpen(false)} />}
    </BaseConfigurationSection>
  );
};

export default IncidentTypesAndSubTypesSection;
