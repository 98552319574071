import React from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';

import { Text } from '../../../../../core';
import { ContactIcon } from '../../../../../icons';

import styles from './cells.module.scss';

export const UserSpecialCell = ({ userName }) => {
  return (
    userName && (
      <span className={styles.userCellContainer}>
        <div className={styles.iconContainer}>
          <ContactIcon />
        </div>
        <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
          {capitalize(userName)}
        </Text>
      </span>
    )
  );
};

UserSpecialCell.propTypes = {
  userName: PropTypes.string,
};
