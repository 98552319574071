const PERFORMANCE_QA = {
  CATEGORIES: {
    TIMELINESS: {
      desc: 'Timeliness',
    },
    ADJUDICATION: {
      desc: 'Adjudication',
    },
    PAYMENTS: {
      desc: 'Payments',
    },
    CUSTOMER_EXPERIENCE: {
      desc: 'Customer Experience',
    },
  },
  TYPES: {
    AUTOMATIC: {
      desc: 'Automatic',
    },
    PREDEFINED: {
      desc: 'Predefined',
    },
    FREE_TEXT: {
      desc: 'Free Text',
    },
  },
  SLA_TYPES: {
    hours: {
      desc: 'Hours',
    },
    days: {
      desc: 'Days',
    },
    business_days: {
      desc: 'Business Days',
    },
    months: {
      desc: 'Months',
    },
  },
  QUESTIONS: {
    claimant_contacted_timely: {
      type: 'AUTOMATIC',
      category: 'TIMELINESS',
      template: 'Was the claimant or attorney contacted within $sla_value $sla_time?',
    },
    appraiser_assigned_timely: {
      type: 'AUTOMATIC',
      category: 'TIMELINESS',
      template: 'Was the appraiser assigned within $sla_value $sla_time ?',
    },
    payment_issued_after_estimate_timely: {
      type: 'AUTOMATIC',
      category: 'TIMELINESS',
      template: 'Was the payment issued within $sla_value $sla_time of receiving the estimate?',
    },
    medical_bills_sent_review_timely: {
      type: 'AUTOMATIC',
      category: 'TIMELINESS',
      template: 'Medical Bills sent to Bill-Review within $sla_value $sla_time?',
    },
    medical_bills_paid_after_eob_timely: {
      type: 'AUTOMATIC',
      category: 'TIMELINESS',
      template: 'Medical Bills paid within $sla_value $sla_time of receipt of the EOB?',
    },
    deductible_applied: {
      type: 'AUTOMATIC',
      category: 'PAYMENTS',
      template: 'Deductible applied to payment?',
    },
    initial_investigation_complete: {
      type: 'PREDEFINED',
      category: 'TIMELINESS',
      template: 'Was the initial investigation completed within 30 days?',
    },
    payment_documentation: {
      type: 'PREDEFINED',
      category: 'PAYMENTS',
      template: 'Did each payment include the appropriate documentation?',
    },
    handler_confirm_coverage: {
      type: 'PREDEFINED',
      category: 'ADJUDICATION',
      template: 'Did the Handler confirm coverage?',
    },
    correct_policy_number_period: {
      type: 'PREDEFINED',
      category: 'ADJUDICATION',
      template: 'Correct Policy Number and Period?',
    },
    handler_confirm_qualified_control: {
      type: 'PREDEFINED',
      category: 'ADJUDICATION',
      template: 'Did the handler review the file to confirm if it was a qualified Control File?',
    },
    handler_confirm_qualified_siu: {
      type: 'PREDEFINED',
      category: 'ADJUDICATION',
      template: 'Did the handler review the file to confirm if it was a qualified SIU File?',
    },
    appropriate_acknowledgement: {
      type: 'PREDEFINED',
      category: 'ADJUDICATION',
      template: 'Were the appropriate acknowledgement letters sent?',
    },
    appropriate_exposures: {
      type: 'PREDEFINED',
      category: 'ADJUDICATION',
      template: 'Were the appropriate exposures opened?',
    },
    appropriate_record_statements: {
      type: 'PREDEFINED',
      category: 'ADJUDICATION',
      template: 'Did the handler secure the appropriate Recorded Statements?',
    },
    execute_releases_attach_file: {
      type: 'PREDEFINED',
      category: 'ADJUDICATION',
      template: 'Properly executed releases attached to the file?',
    },
    liability_assessment: {
      type: 'PREDEFINED',
      category: 'ADJUDICATION',
      template: 'Was the liability assessment appropriate based on the investigation?',
    },
    loss_date_time_record: {
      type: 'PREDEFINED',
      category: 'ADJUDICATION',
      template: 'Were the correct loss date and time recorded in the file?',
    },
    iso_report: {
      type: 'PREDEFINED',
      category: 'ADJUDICATION',
      template: 'ISO report performed and reviewed?',
    },
    medicare_eligibility: {
      type: 'PREDEFINED',
      category: 'ADJUDICATION',
      template: 'Medicare Eligibility verified?',
    },
    subrogation_indication: {
      type: 'PREDEFINED',
      category: 'ADJUDICATION',
      template: 'Did subrogation indicate and pursued?',
    },
    subrogation_recovery: {
      type: 'PREDEFINED',
      category: 'ADJUDICATION',
      template: 'Subrogation recovery secured or abandonment explained',
    },
    insured_contacted_timely: {
      type: 'PREDEFINED',
      category: 'CUSTOMER_EXPERIENCE',
      template: 'Did the handler contact the insured within 24 hours?',
    },
    appropriate_acknowledgment_letters: {
      type: 'PREDEFINED',
      category: 'CUSTOMER_EXPERIENCE',
      template: 'Were the appropriate acknowledgment letters sent?',
    },
    file_reflect_customers_informed: {
      type: 'PREDEFINED',
      category: 'CUSTOMER_EXPERIENCE',
      template: 'Does the file reflect that the handler kept the customers informed throughout the claims process?',
    },
  },
} as const;

export default PERFORMANCE_QA;
