import { isEmpty } from 'lodash';
import * as Yup from 'yup';

import { isOrgUnitsConfigurationFeatureEnabled } from '~/components/SystemConfiguration/Users/OrganizationUnits/Utils';

import { isProductionEnv, yupPhoneValidation } from '../../../../Utils';
import { isPermissionsEnabled } from '../../../core/Permissions/PermissionUtils';

const getUserFields = (subOrgsEnabled, organization, isWizardUser) => {
  const permissionsEnabled = organization && isPermissionsEnabled(organization);
  const orgUnitsConfigurationEnabled = organization && isOrgUnitsConfigurationFeatureEnabled({ organization });

  return {
    first_name: '',
    last_name: '',
    email: '',
    is_claims_email_username_manually_set: false,
    claims_email_username: '',
    mobile_phone_number: '',
    work_phone_number: '',
    role_id: '',
    is_sys_config_editor: false,
    is_part_of_sub_organization: isWizardUser && permissionsEnabled ? false : !!subOrgsEnabled,
    is_super_user: false,
    sub_organization_ids: [],
    lobs: [],
    ...(permissionsEnabled ? { profile_id: '', role_id: '' } : {}),
    ...(orgUnitsConfigurationEnabled ? { unit_id: '' } : {}),
  };
};

const getUserValidationFields = (users, initialEmail, isNew) => {
  return {
    first_name: Yup.string().required('Required'),
    last_name: Yup.string().required('Required'),
    role_id: Yup.number().required('Required'),
    email: Yup.string()
      .nullable()
      .email()
      .required('Required')
      .test('email-exists', 'User with this email already exists', (email) => {
        if (!users) {
          return true;
        }
        return !users.some((user) => {
          const userEmail = user.email?.toLowerCase();
          // second condition used to prevent validation of the existing user's email
          if (!userEmail || (initialEmail && userEmail === initialEmail.toLowerCase())) {
            return;
          }
          return userEmail === email?.toLowerCase();
        });
      }),
    mobile_phone_number: yupPhoneValidation,
    work_phone_number: Yup.string().max(32).required('Required'), // We're not using yupPhoneValidation because TSC put here string like '(555) 123456 option #3'
    is_super_user: Yup.bool().required('Required'),
    is_sys_config_editor: Yup.bool().nullable(),
    is_part_of_sub_organization: Yup.bool(),
    sub_organization_ids: Yup.array().when('is_part_of_sub_organization', {
      is: true,
      then: Yup.array().min(1, 'Required'),
      otherwise: Yup.array().max(0),
    }),
    lobs: Yup.array(),
    claims_email_username: Yup.string()
      .nullable()
      .required('Required')
      .test('unique-claims-email-username', 'Username already exists in the organization', (claims_email_username) => {
        if (isEmpty(users) || !isNew) {
          return true;
        }

        const currentUserInitialValues = initialEmail && users.find((user) => user.email === initialEmail);
        if (claims_email_username === currentUserInitialValues?.claims_email_username) {
          return true;
        }

        return !users.some(
          (user) =>
            user.claims_email_username &&
            user.claims_email_username.toLowerCase() === claims_email_username?.toLowerCase()
        );
      }),
  };
};

const getPermissionsFeatureUserSchemaOverrides = (organization) =>
  organization && isPermissionsEnabled(organization)
    ? {
        sub_organization_ids: Yup.array().when('is_part_of_sub_organization', {
          is: true,
          then: Yup.array()
            .of(Yup.number().typeError('Please select a sub-organization'))
            .min(1, 'Add at least one sub-organization'),
          otherwise: Yup.array().max(0),
        }),
        profile_id: Yup.mixed().test({
          name: 'profile_id',
          message: 'Please select a profile',
          // value can be either a string if in the org wizard context, or a number if using db profiles
          test: (value) => Boolean(value) || value === 0,
        }),
      }
    : {};

const getUserDefaultClaimsEmailUserName = (userEmail, organizationExternalId) => {
  let defaultClaimsEmailUserName = userEmail.split('@')[0]?.toLowerCase();
  if (!isProductionEnv()) {
    defaultClaimsEmailUserName += `_${organizationExternalId}`;
  }

  return defaultClaimsEmailUserName;
};

export {
  getPermissionsFeatureUserSchemaOverrides,
  getUserDefaultClaimsEmailUserName,
  getUserFields,
  getUserValidationFields,
};
