import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import CardDialog from '../../../../../CardDialog';

import SubReservesTypesDialog from './SubReservesTypesDialog';
import {
  getPreProcessedSubReservesBeforeSubmit,
  getPreProcessSubReservesConfigArray,
  getSubReservesTypesNestedScheme,
} from './subReservesUtils';

const ExpensesSubReservesContainer = ({ paymentsConfiguration, disabled, onCancel, onSubmit }) => {
  const CONFIG_FIELD_ID = 'expenses_sub_reserves_config';

  const handleSubmitSubReserves = async (values) => {
    const formattedValues = {
      ...values,
      [CONFIG_FIELD_ID]: getPreProcessedSubReservesBeforeSubmit(values[CONFIG_FIELD_ID]),
    };
    await onSubmit(formattedValues);
  };

  return (
    <Formik
      initialValues={{
        expenses_sub_reserves_config: getPreProcessSubReservesConfigArray(
          paymentsConfiguration?.expenses_sub_reserves_config ?? {}
        ),
      }}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        try {
          await handleSubmitSubReserves(values);
        } catch (error) {
          formikProps.setSubmitting(false);
        }
      }}
      validationSchema={Yup.object().shape({
        expenses_sub_reserves_config: Yup.array()
          .of(getSubReservesTypesNestedScheme())
          .test('unique-keys-and-desc', 'The keys and names have to be unique', (subReservesConfigs) => {
            const typeKeysArray = subReservesConfigs.map((subReservesConfig) => subReservesConfig.type_key);
            const typeKeysSet = new Set(typeKeysArray);

            const typeDescArray = subReservesConfigs.map((subReservesConfig) => subReservesConfig.desc);
            const typeDescSet = new Set(typeDescArray);

            return typeKeysArray.length === typeKeysSet.size && typeDescArray.length === typeDescSet.size;
          })
          .min(1, 'At least 1 is required'),
      })}
    >
      {(formikProps) => {
        const { handleSubmit } = formikProps;

        return (
          <CardDialog
            isDialog
            title="Expenses Sub Reserves Types"
            maxWidth="sm"
            fullWidth
            onClose={onCancel}
            preventClose={disabled}
          >
            <SubReservesTypesDialog handleSubmit={handleSubmit} handleCancel={onCancel} fieldId={CONFIG_FIELD_ID} />
          </CardDialog>
        );
      }}
    </Formik>
  );
};

ExpensesSubReservesContainer.propTypes = {
  paymentsConfiguration: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default ExpensesSubReservesContainer;
