import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import CancelButton from '~/components/core/Buttons/CancelButton';

import { ROLES_TYPES_DICT } from '../../../Types';
import CardDialog from '../../CardDialog';
import WithConfirm from '../../ConfirmModal';
import DisableableToolTip from '../../DisableableTooltip';
import TextFieldFormik from '../../TextFieldFormik';
import { useSysconfig } from '../SystemConfigurationScreen';

import styles from '../../../assets/styles';

const roleFields = {
  name: '',
  role_type: '',
};

const roleValidationFields = {
  name: Yup.string().required('Required'),
  role_type: Yup.string().required('Required'),
};

const RoleFragment = (props) => {
  const { classes, buttonsComponent } = props;
  const { organization } = useSysconfig();
  const availableRoles = { ...ROLES_TYPES_DICT };

  if (!organization.supported_claim_types.includes('wc_claim')) {
    delete availableRoles.osha_rep;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <TextFieldFormik id="name" label="Name" className={classes.textField} />
      </Grid>
      <Grid item xs={6}>
        <TextFieldFormik id="role_type" select label="type" className={classes.textField} fullWidth>
          {Object.keys(availableRoles)
            .filter((role) => !availableRoles[role].is_hidden)
            .map((type) => (
              <MenuItem key={type} value={type}>
                {availableRoles[type].desc}
              </MenuItem>
            ))}
        </TextFieldFormik>
      </Grid>
      {buttonsComponent && (
        <Grid item xs={12}>
          <div className={classes.buttonsContainer}>{buttonsComponent}</div>
        </Grid>
      )}
    </Grid>
  );
};

RoleFragment.propTypes = {
  classes: PropTypes.object.isRequired,
  buttonsComponent: PropTypes.object.isRequired,
};

const RoleEditDialog = (props) => {
  const { classes, role, onSaveRoleDetails, onEditCancel, onDeleteRole } = props;
  const isDeleteDisabled = role.users_count !== 0;

  return (
    <Formik
      initialValues={{ ...role }}
      validationSchema={Yup.object().shape({
        ...roleValidationFields,
      })}
      enableReinitialize
      onSubmit={(values, formikProps) => {
        onSaveRoleDetails(values).catch(() => {
          formikProps.setSubmitting(false);
        });
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        const buttonsComponentEdit = (
          <Fragment>
            <CancelButton disabled={isSubmitting} onClick={onEditCancel} />
            <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
              Save
            </Button>
          </Fragment>
        );

        const dialogAction = (
          <DisableableToolTip disabled={isDeleteDisabled} title={"Can't delete role. Users are assigned to this role"}>
            <WithConfirm title="Delete Role?" primaryButtonName="Delete" shouldCloseOnPrimary={true}>
              <IconButton onClick={onDeleteRole} disabled={isSubmitting || isDeleteDisabled}>
                <DeleteIcon />
              </IconButton>
            </WithConfirm>
          </DisableableToolTip>
        );

        return (
          <CardDialog
            isDialog={true}
            title="Edit Role"
            maxWidth="sm"
            onClose={onEditCancel}
            preventClose={isSubmitting}
            action={dialogAction}
          >
            <RoleFragment {...formikProps} classes={classes} buttonsComponent={buttonsComponentEdit} />
          </CardDialog>
        );
      }}
    </Formik>
  );
};

RoleEditDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  role: PropTypes.object.isRequired,
  onSaveRoleDetails: PropTypes.func.isRequired,
  onEditCancel: PropTypes.func.isRequired,
  onDeleteRole: PropTypes.func.isRequired,
};

const RoleEditDialogStyled = withStyles(styles)(RoleEditDialog);

const RoleAddDialog = (props) => {
  const { classes, onAddRole, onCancel } = props;

  return (
    <Formik
      initialValues={{ ...roleFields }}
      validationSchema={Yup.object().shape({
        ...roleValidationFields,
      })}
      enableReinitialize
      onSubmit={(values, formikProps) => {
        onAddRole(values).catch(() => {
          formikProps.setSubmitting(false);
        });
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        const buttonsComponentEdit = (
          <Fragment>
            <CancelButton disabled={isSubmitting} onClick={onCancel} />
            <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
              Add
            </Button>
          </Fragment>
        );

        return (
          <CardDialog isDialog={true} title="Add Role" maxWidth="sm" onClose={onCancel} preventClose={isSubmitting}>
            <RoleFragment {...formikProps} classes={classes} buttonsComponent={buttonsComponentEdit} />
          </CardDialog>
        );
      }}
    </Formik>
  );
};

RoleAddDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onAddRole: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const RoleAddDialogStyled = withStyles(styles)(RoleAddDialog);

export { RoleAddDialogStyled as RoleAddDialog, RoleEditDialogStyled as RoleEditDialog };
