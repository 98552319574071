import React from 'react';

import SvgIcon from '../core/SvgIcon';

const LobWizardIcon = (props) => (
  <SvgIcon viewBox="0 0 32 32" stroke="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M6 18.25C6.41421 18.25 6.75 18.5858 6.75 19V26.25H25.25V19C25.25 18.5858 25.5858 18.25 26 18.25C26.4142 18.25 26.75 18.5858 26.75 19V27C26.75 27.4142 26.4142 27.75 26 27.75H6C5.58579 27.75 5.25 27.4142 5.25 27V19C5.25 18.5858 5.58579 18.25 6 18.25Z" />
    <path d="M10.25 22C10.25 21.5858 10.5858 21.25 11 21.25H21C21.4142 21.25 21.75 21.5858 21.75 22C21.75 22.4142 21.4142 22.75 21 22.75H11C10.5858 22.75 10.25 22.4142 10.25 22Z" />
    <path d="M11.1506 15.2042C11.2579 14.8041 11.6692 14.5668 12.0692 14.674L21.7192 17.2615C22.1193 17.3688 22.3567 17.7801 22.2494 18.1802C22.1421 18.5803 21.7308 18.8176 21.3308 18.7103L11.6808 16.1228C11.2807 16.0156 11.0433 15.6043 11.1506 15.2042Z" />
    <path d="M13.7625 8.87507C13.9696 8.51633 14.4283 8.39337 14.787 8.60044L23.4495 13.6004C23.8083 13.8075 23.9312 14.2662 23.7242 14.6249C23.5171 14.9837 23.0584 15.1066 22.6997 14.8996L14.0372 9.89956C13.6784 9.69249 13.5555 9.23382 13.7625 8.87507Z" />
    <path d="M17.9443 3.44623C18.2372 3.15334 18.712 3.15334 19.0049 3.44623L26.0674 10.5087C26.3603 10.8016 26.3603 11.2765 26.0674 11.5694C25.7745 11.8623 25.2997 11.8623 25.0068 11.5694L17.9443 4.50689C17.6514 4.214 17.6514 3.73913 17.9443 3.44623Z" />
  </SvgIcon>
);

export default LobWizardIcon;
