const DATA_EXPORT = {
  DAYS: {
    '0': {
      desc: 'Monday',
      abbr: 'Mo',
    },
    '1': {
      desc: 'Tuesday',
      abbr: 'Tu',
    },
    '2': {
      desc: 'Wednesday',
      abbr: 'We',
    },
    '3': {
      desc: 'Thursday',
      abbr: 'Th',
    },
    '4': {
      desc: 'Friday',
      abbr: 'Fr',
    },
    '5': {
      desc: 'Saturday',
      abbr: 'Sa',
    },
    '6': {
      desc: 'Sunday',
      abbr: 'Su',
    },
  },
  TARGET_TYPE: {
    email: 'Email',
    s3_bucket: 'S3',
    gcs_bucket: 'GCS',
    sftp: 'SFTP',
  },
  EXPORT_FORMAT: {
    csv: {
      key: 'csv',
      desc: 'CSV',
      extension: 'csv',
    },
    excel: {
      key: 'excel',
      desc: 'Excel',
      extension: 'xlsx',
    },
    parquet: {
      key: 'parquet',
      desc: 'Parquet',
      extension: 'parquet',
    },
  },
} as const;

export default DATA_EXPORT;
