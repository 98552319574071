const basePath = ({ organizationId }) => `/sysconfig/api/v1/organizations/${organizationId}/org_units`;
export const ORG_UNITS_ROUTES = {
  GET: basePath,
  CREATE: basePath,
  UPDATE: ({ unitId, organizationId }) => `${basePath({ organizationId })}/${unitId}`,
  DELETE: ({ unitId, organizationId }) => `${basePath({ organizationId })}/${unitId}`,
  SET_ACTIVE: ({ unitId, organizationId }) => `${basePath({ organizationId })}/${unitId}/set_active`,
  RELATED_CONFIGURATIONS: ({ unitId, organizationId }) =>
    `${basePath({ organizationId })}/${unitId}/related_configurations`,
};
