import React from 'react';
import PropTypes from 'prop-types';

import { ConfirmModal } from '../../../ConfirmModal';
import { LoadingSwitch } from '../../../core';
import useDataFetcher from '../../../useDataFetcher';
import { useSysconfig } from '../../SystemConfigurationScreen';

const DeleteReasonDialog = ({ onClose, onSubmit, reasonToDelete }) => {
  const { organization } = useSysconfig();
  const { isLoading, isError, data } = useDataFetcher(
    `/api/v1/organizations/${organization.id}/closing_reason_configs/${reasonToDelete.db_id}/is_in_use/${reasonToDelete.type}`
  );

  const isInUse = data?.is_in_use;

  const Model = () => {
    if (isInUse) {
      return (
        <ConfirmModal
          isOpen={!!reasonToDelete}
          title="Closing Reason Cannot be Deleted"
          contentText="This closing reason is currently in use and cannot be deleted."
          onClose={onClose}
          primaryButtonName="OK"
          onPrimaryBtnClick={onClose}
          removeSecondaryButton
          centerDialog
        />
      );
    } else {
      return (
        <ConfirmModal
          isOpen={!!reasonToDelete}
          title="Delete Closing Reason?"
          contentText="Deleting this closing reason will remove it permanently and you will need to create a new reason if required."
          onClose={onClose}
          primaryButtonName="Yes"
          onPrimaryBtnClick={onSubmit}
          centerDialog
        />
      );
    }
  };

  return (
    <LoadingSwitch isLoading={isLoading} isError={isError}>
      <Model />
    </LoadingSwitch>
  );
};

DeleteReasonDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  reasonToDelete: PropTypes.object.isRequired,
};

export default DeleteReasonDialog;
