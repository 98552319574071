import React, { useState } from 'react';
import axios from 'axios';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';
import SwitchFormik from '~/components/core/Formik/SwitchFormik';

import { reportAxiosError } from '../../../../../../Utils';
import LoadingIndicator from '../../../../../LoadingIndicator';
import { TextFieldFormik } from '../../../../../TextFieldFormik';
import useDataFetcher from '../../../../../useDataFetcher';
import { useSysconfig } from '../../../../SystemConfigurationScreen';

import PolicyAPIDataForm from './PolicyAPIDataForm';

import { useStyles } from '../../../../../../assets/styles';

const PolicyAPIConfig = () => {
  const classes = useStyles();
  const { organization, organizationOperationalDetails, reloadOperationalDetails } = useSysconfig();

  const [policyAPIDataFormWizardOpen, setPolicyAPIDataFormOpen] = useState(false);
  const [policyAPIDataFormEditWizardOpen, setPolicyAPIDataFormEditOpen] = useState(false);

  const {
    isLoading,
    isError,
    data: policyAPIConfig,
    reloadData: reloadPolicyAPIConfig,
  } = useDataFetcher(`/sysconfig/api/v1/organizations/${organization.id}/policy_api_config`);

  const urlPolicyAPIConfig = `/sysconfig/api/v1/organizations/${organization.id}/policy_api_config`;

  if (isLoading || isError) {
    return <LoadingIndicator isError={isError} />;
  }

  const getEntityInitialValues = {
    base_url: policyAPIConfig?.base_url || '',
    auth_token: policyAPIConfig?.auth_token || '',
    policy_search_component_description: policyAPIConfig?.policy_search_component_description || '',
    enabled: policyAPIConfig?.enabled || false,
  };

  const getValidationSchema = () => ({
    base_url: Yup.string().required('Required'),
    auth_token: Yup.string().required('Required'),
    policy_search_component_description: Yup.string().required('Required'),
    enabled: Yup.boolean(),
  });

  const getPayload = (values) => {
    return {
      base_url: values.base_url,
      auth_token: values.auth_token,
      policy_search_component_description: values.policy_search_component_description,
      enabled: values.enabled,
    };
  };

  const handleSave = async (values) => {
    try {
      const payload = getPayload(values);
      await axios.post(urlPolicyAPIConfig, payload);
      await reloadOperationalDetails();
      reloadPolicyAPIConfig();
      setPolicyAPIDataFormOpen(false);
    } catch (error) {
      reportAxiosError(error);
    }
  };

  const handleEditSave = async (values) => {
    try {
      const payload = getPayload(values);
      await axios.put(urlPolicyAPIConfig, payload);
      await reloadOperationalDetails();
      reloadPolicyAPIConfig();
      setPolicyAPIDataFormEditOpen(false);
    } catch (error) {
      reportAxiosError(error);
    }
  };

  const getEditInputs = (isWizardOpen, firstTitle, secondTitle, thirdTitle, switchTitle) => (
    <>
      <Grid item xs={12}>
        <Typography display="block" variant="subtitle2" style={{ fontWeight: 700, marginBottom: '10px' }}>
          {switchTitle}
        </Typography>
      </Grid>
      <Grid item xs={isWizardOpen ? 8 : 4}>
        <SwitchFormik className={classes.formsSwitch} id="enabled" label="" disabled={!isWizardOpen} withShowOnlyText />
      </Grid>

      <Grid item xs={12}>
        <Typography display="block" variant="subtitle2" style={{ fontWeight: 700, marginBottom: '10px' }}>
          {firstTitle}
        </Typography>
      </Grid>
      <Grid item xs={isWizardOpen ? 12 : 6}>
        <TextFieldFormik
          id="base_url"
          placeholder="Fill in the base URL"
          className={classes.textField}
          fullWidth
          disabled={!isWizardOpen}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography display="block" variant="subtitle2" style={{ fontWeight: 700, marginBottom: '10px' }}>
          {secondTitle}
        </Typography>
      </Grid>
      <Grid item xs={isWizardOpen ? 8 : 4}>
        <TextFieldFormik
          id="auth_token"
          placeholder="Fill in the auth token"
          className={classes.textField}
          fullWidth
          disabled={!isWizardOpen}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography display="block" variant="subtitle2" style={{ fontWeight: 700, marginBottom: '10px' }}>
          {thirdTitle}
        </Typography>
      </Grid>
      <Grid item xs={isWizardOpen ? 8 : 4}>
        <TextFieldFormik
          id="policy_search_component_description"
          placeholder="Fill in the policy search component description"
          className={classes.textField}
          fullWidth
          disabled={!isWizardOpen}
        />
      </Grid>
    </>
  );

  return (
    <Grid container spacing={0} direction="column" style={{ paddingTop: '6px' }}>
      <Grid
        item
        x={6}
        style={{ display: 'inline-flex', alignItems: 'center', paddingTop: '37px', paddingLeft: '32px' }}
      >
        {organizationOperationalDetails.isPolicyAPIEnabled ? (
          <PolicyAPIDataForm
            title="Policy API Configurations"
            firstTitle="Base URL"
            secondTitle="Auth Token"
            thirdTitle="Policy Search Component Description"
            switchTitle="Enabled"
            wizardOpen={false}
            getEntityInitialValues={getEntityInitialValues}
            getValidationSchema={getValidationSchema}
            getEditInputs={getEditInputs}
            onSave={handleSave}
            openEdit={() => setPolicyAPIDataFormEditOpen(true)}
            onCancel={() => setPolicyAPIDataFormOpen(false)}
          />
        ) : (
          <Grid>
            <Typography
              display="block"
              variant="subtitle2"
              style={{ fontSize: '20px', fontWeight: 800, marginBottom: '35px' }}
            >
              Policy API
            </Typography>
            <Button
              color="primary"
              variant="contained"
              style={{ paddingTop: '20px ', paddingBottom: '20px' }}
              onClick={() => setPolicyAPIDataFormOpen(true)}
            >
              Enable Policy API
            </Button>
          </Grid>
        )}
      </Grid>
      {policyAPIDataFormWizardOpen && (
        <PolicyAPIDataForm
          title="New Policy API Configuration"
          firstTitle="Base URL"
          secondTitle="Auth Token"
          thirdTitle="Policy Search Component Description"
          switchTitle="Enabled"
          wizardOpen={policyAPIDataFormWizardOpen}
          getEntityInitialValues={getEntityInitialValues}
          getValidationSchema={getValidationSchema}
          getEditInputs={getEditInputs}
          onSave={handleSave}
          onCancel={() => setPolicyAPIDataFormOpen(false)}
        />
      )}
      {policyAPIDataFormEditWizardOpen && (
        <PolicyAPIDataForm
          title="Set A New Policy API Configuration"
          firstTitle="Base URL"
          secondTitle="Auth Token"
          thirdTitle="Policy Search Component Description"
          switchTitle="Enabled"
          wizardOpen={policyAPIDataFormEditWizardOpen}
          getEntityInitialValues={getEntityInitialValues}
          getValidationSchema={getValidationSchema}
          getEditInputs={getEditInputs}
          onSave={handleEditSave}
          onCancel={() => setPolicyAPIDataFormEditOpen(false)}
        />
      )}
    </Grid>
  );
};

export default PolicyAPIConfig;
