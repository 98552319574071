import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import CardDialog from '~/components/CardDialog';
import Grid from '~/components/core/Atomic/Grid/Grid';
import DialogFooterActions from '~/components/core/DialogFooterActions';
import TextFieldFormik from '~/components/TextFieldFormik';

import { getIsActiveOrPending } from '../../../utils';

const ConfigurationDialog = ({ handleClose, handleSubmit, activeConfiguration }) => {
  const classes = useStyles();

  const initialValues = {
    claim_office_branch_code: activeConfiguration.claim_office_branch_code || '',
    note: activeConfiguration.note || '',
  };

  const getRequiredWhenPendingActivationOrStartedReportingSchema = (baseSchema) => {
    return getIsActiveOrPending(activeConfiguration?.status) ? baseSchema.required('Required') : baseSchema;
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        claim_office_branch_code: getRequiredWhenPendingActivationOrStartedReportingSchema(Yup.string().nullable()),
      })}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await handleSubmit(values);
          await handleClose();
        } catch (error) {
          setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog
            title="CCC Integration Setup"
            isDialog
            preventClose={isSubmitting}
            maxWidth="sm"
            fullWidth
            footerActions={
              <DialogFooterActions
                disabled={isSubmitting}
                onClickPrimary={handleSubmit}
                onClickSecondary={handleClose}
              />
            }
          >
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextFieldFormik
                  id="claim_office_branch_code"
                  label="Claim office branch code"
                  fullWidth
                  className={classes.textField}
                  disabled={isSubmitting}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldFormik
                  id="note"
                  label="Note"
                  fullWidth
                  className={classes.textField}
                  disabled={isSubmitting}
                />
              </Grid>
            </Grid>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

ConfigurationDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  activeConfiguration: PropTypes.object.isRequired,
};

export default ConfigurationDialog;
