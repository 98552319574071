import useOrganization from '../../../../OrganizationContext';
import useDataFetcher from '../../../../useDataFetcher';
import { useSysconfig } from '../../../SystemConfigurationScreen';

const ROUTES = {
  getConfiguration: (organizationId) => `/api/v1/ccc_integration_configuration/${organizationId}`,
};

export const useCCC = () => {
  const { organization } = useSysconfig();
  const isSysConfig = !!organization;
  const { organizationId, isCccEnabled } = useOrganization();

  const { isLoading, isError, data, reloadData } = useDataFetcher(ROUTES.getConfiguration(organizationId, isSysConfig));

  return {
    isConfigurationExists: !!data?.id,
    isEnabled: isCccEnabled,
    isLoading,
    isError,
    cccOrgConfig: data ?? {},
    reloadData,
  };
};
