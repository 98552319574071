import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { reportAxiosError } from '../../../../../Utils';
import CardDialog from '../../../../CardDialog';
import { Caption, FsButton, FsIconButton, Heading, LoadingSwitch, Text } from '../../../../core';
import { EyeIcon, PencilIcon } from '../../../../icons';
import useDataFetcher from '../../../../useDataFetcher';
import { useSysconfig } from '../../../SystemConfigurationScreen';

import OneIncConfigurationFormDialog from './ConfigurationFormDialog';

import styles from './oneIncInternalAdmin.module.scss';

const Detail = ({ label, text, isSecret = false }) => {
  const [isHidden, setIsHidden] = useState(true);
  return (
    <div className={styles.detail}>
      <Caption variant={Caption.VARIANTS.LABEL}>{label}</Caption>
      <div className={styles.secret}>
        <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
          {isSecret && isHidden ? '..........' : text}
        </Text>
        {isSecret ? <FsIconButton icon={EyeIcon} onClick={() => setIsHidden((prev) => !prev)} /> : null}
      </div>
    </div>
  );
};

Detail.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isSecret: PropTypes.string,
};
const InternalAdminOneInc = () => {
  const [configurationFormOpen, setConfigurationFormOpen] = useState(false);
  const { organization } = useSysconfig();
  const { isLoading, isError, data, reloadData } = useDataFetcher(
    `/api/v1/one_inc/config/organizations/${organization.id}`
  );

  const isEnabled = !!data?.id;

  const handleSubmitOneIncConfiguration = async (values) => {
    try {
      await axios.put(`/api/v1/one_inc/config/organizations/${organization.id}`, values);
      await reloadData();
      setConfigurationFormOpen(false);
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
  };
  return (
    <LoadingSwitch isLoading={isLoading} isError={isError}>
      <CardDialog noCardTitle containerClassName={styles.oneIncInternalAdmin}>
        {isEnabled ? (
          <>
            <div className={styles.containerWithBorder}>
              <div className={styles.sectionTitle}>
                <Heading variant={Heading.TYPES.H4}>One Inc Details</Heading>
                <FsIconButton
                  icon={PencilIcon}
                  onClick={() => {
                    setConfigurationFormOpen(true);
                  }}
                />
              </div>
              <div className={styles.sectionDetails}>
                <Detail label="Username" text={data?.username} />
                <Detail label="Password" text={data?.password} isSecret />
                <Detail label="URL" text={data?.url} />
              </div>
            </div>
            <div className={styles.containerWithBorder}>
              <div className={styles.sectionTitle}>
                <Heading variant={Heading.TYPES.H4}>Webhook</Heading>
              </div>
              <div className={styles.sectionDetails}>
                <Detail label="Username" text={data?.webhook_username} />
                <Detail label="Password" text={data?.webhook_password} isSecret />
                <Detail label="Webhook - URL" text={data?.webhook_url} />
              </div>
            </div>
          </>
        ) : (
          <FsButton
            color="primary"
            variant="contained"
            className={styles.button}
            onClick={() => {
              setConfigurationFormOpen(true);
            }}
          >
            Enable OneInc Integration
          </FsButton>
        )}
      </CardDialog>
      {configurationFormOpen ? (
        <OneIncConfigurationFormDialog
          onSubmit={handleSubmitOneIncConfiguration}
          configuration={data}
          onClose={() => {
            setConfigurationFormOpen(false);
          }}
        />
      ) : null}
    </LoadingSwitch>
  );
};

export default InternalAdminOneInc;
