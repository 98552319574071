import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import CancelButton from '~/components/core/Buttons/CancelButton';
import SearchWithOptionsFormik from '~/components/core/Formik/SearchWithOptionsFormik/SearchWithOptionsFormik';

import { TWILIO_ADJUSTER_STATUS_DICT } from '../../../../../Types';
import CardDialog from '../../../../CardDialog';
import { SortableTable } from '../../../../core';
import HoverActionField from '../../../../HoverActionField';

import { useStyles } from '../../../../../assets/styles';

const MAIN_FIELD_ID = 'possible_queues';

const EditAdjusterPossibleQueue = ({ user, queues, onSubmit, onCancel }) => {
  const classes = useStyles();

  const options = [
    {
      entries: queues.map((q) => ({
        id: q.queue_name,
        entryTitle: q.queue_name,
        isDisabled: false,
      })),
    },
  ];

  return (
    <Formik
      initialValues={{
        [MAIN_FIELD_ID]: user.possible_queues,
      }}
      validationSchema={Yup.object().shape({
        [MAIN_FIELD_ID]: Yup.array().of(Yup.string()),
      })}
      onSubmit={async (values, formikProps) => {
        try {
          await onSubmit({
            worker_sid: user.sid,
            possible_queues: values[MAIN_FIELD_ID],
          });
          onCancel();
        } catch {
          formikProps.setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, handleSubmit }) => {
        return (
          <CardDialog
            title={`Assign Queues to Adjuster ${user.name}`}
            isDialog
            maxWidth="sm"
            fullWidth
            onClose={onCancel}
            preventClose={isSubmitting}
          >
            <SearchWithOptionsFormik
              isSelectAllEnabled={false}
              options={options}
              mainFieldId={MAIN_FIELD_ID}
              label="Search Adjusters"
            />
            <div className={classes.buttonsContainer}>
              <CancelButton disabled={isSubmitting} onClick={onCancel} />
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

EditAdjusterPossibleQueue.propTypes = {
  user: PropTypes.object,
  queues: PropTypes.array,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

const adjusterViewColumnsData = ({ routingEnabled, setDialogData }) => [
  { id: 'name', label: 'Adjuster' },
  {
    id: 'activity',
    label: 'Status',
    specialCell: (row) => TWILIO_ADJUSTER_STATUS_DICT[row.activity]?.desc,
  },

  {
    id: 'possible_queues',
    label: 'Queues',
    specialCell: (row) => {
      return (
        <HoverActionField
          onAction={() => {
            setDialogData(row);
          }}
          permanent
        >
          {row.possible_queues.join(', ')}
        </HoverActionField>
      );
    },
  },
  ...(routingEnabled
    ? [
        {
          id: 'default_queue',
          label: 'Default queue',
        },
      ]
    : []),
];

const AdjusterViewTable = ({ data, routingEnabled, onSubmit }) => {
  const [dialogData, setDialogData] = useState(false);

  return (
    <>
      <CardDialog title="Adjuster View" maxWidth="lg">
        <div>
          <SortableTable columns={adjusterViewColumnsData({ routingEnabled, setDialogData })} rows={data.users} />
        </div>
      </CardDialog>
      {dialogData ? (
        <EditAdjusterPossibleQueue
          user={dialogData}
          queues={data.queues}
          onCancel={() => setDialogData(null)}
          onSubmit={onSubmit}
        />
      ) : null}
    </>
  );
};

AdjusterViewTable.propTypes = {
  data: PropTypes.object,
  onSubmit: PropTypes.func,
  routingEnabled: PropTypes.bool.isRequired,
};

export default AdjusterViewTable;
