import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { get, isEmpty } from 'lodash';

import { Text } from '~/components/core';
import Grid from '~/components/core/Atomic/Grid/Grid';

import CardDialog from '../../../../CardDialog';

import ArrayFieldFormik from './NestedFieldFormik/ArrayFieldFormik';
import SwitchOrDelete from './SwitchOrDelete';

const NESTED_FIELD_CONFIG = [
  { id: 'desc', label: 'Display name', type: 'text' },
  { id: 'id', label: 'Key', type: 'text', disableEditAfterInitialSet: true },
  {
    id: 'active',
    label: '',
    type: 'custom',
    customComponent: SwitchOrDelete,
  },
];
const INNER_FIELD_INITIAL_VALUES = { id: '', desc: '', active: true };

const OptionsCard = ({ idPrefix, fieldId }) => {
  const { values, isSubmitting, setFieldValue } = useFormikContext();
  const id = idPrefix ? `${idPrefix}.${fieldId}` : fieldId;
  const type = get(values, `${id}.type`, '');
  const isOptionsField = ['select', 'multiselect'].includes(type);
  const title = <Text variant={Text.VARIANTS.SM}>{`${type === 'select' ? 'Single' : 'Multi'} Select Options`}</Text>;

  // Set default value for options active to true - only on first render
  useEffect(() => {
    const options = get(values, `${id}.options`, []);
    if (isEmpty(options)) {
      setFieldValue(`${id}.options`, [INNER_FIELD_INITIAL_VALUES]);
    } else {
      options.forEach((option, index) => {
        if (option?.active !== false) {
          setFieldValue(`${id}.options.${index}.active`, true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isOptionsField) {
    return <></>;
  }

  return (
    <Grid item xs={12}>
      <CardDialog noCardTitle contentStyle={{ padding: '16px' }}>
        <ArrayFieldFormik
          fieldId={`${id}.options`}
          label={title}
          innerObjectConfig={NESTED_FIELD_CONFIG}
          disabled={isSubmitting}
          initialNewItemValues={INNER_FIELD_INITIAL_VALUES}
          columnSize={8}
        />
      </CardDialog>
    </Grid>
  );
};

OptionsCard.propTypes = {
  idPrefix: PropTypes.string,
  fieldId: PropTypes.string.isRequired,
};

export default OptionsCard;
