import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';

import DialogFooterActions from '~/components/core/DialogFooterActions';

import CardDialog from '../../../CardDialog';
import useOrganization from '../../../OrganizationContext';
import { useSysconfig } from '../../SystemConfigurationScreen';

import { getPermissionsFeatureUserSchemaOverrides, getUserFields, getUserValidationFields } from './UserDialogUtils';
import { UserFragment } from './UserFragment';

import styles from '../../../../assets/styles';

const UserAddDialog = ({
  classes,
  onAddUser,
  onCancel,
  roles,
  users,
  units,
  organizationExternalId,
  displayLobs,
  displaySuperUser,
  displaySystemConfig,
  overrideOrganizationValue,
  isWizardUser,
}) => {
  const { organization: sysConfigOrganization } = useSysconfig();
  const { subOrganizationEnabled } = useOrganization();

  const organization = overrideOrganizationValue ?? sysConfigOrganization;

  return (
    <Formik
      initialValues={{
        ...getUserFields(organization?.sub_organizations_enabled ?? subOrganizationEnabled, organization, isWizardUser),
        organization_id: organization?.id,
      }}
      validationSchema={Yup.object().shape({
        ...getUserValidationFields(users, null, true),
        ...getPermissionsFeatureUserSchemaOverrides(organization),
      })}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        try {
          await onAddUser(values);
          formikProps.resetForm();
        } catch {
          formikProps.setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog
            isDialog={true}
            title="Add User"
            maxWidth="sm"
            onClose={onCancel}
            preventClose={isSubmitting}
            footerActions={
              <DialogFooterActions
                disabled={isSubmitting}
                onClickPrimary={handleSubmit}
                onClickSecondary={onCancel}
                primaryLabel="Add"
              />
            }
          >
            <UserFragment
              {...formikProps}
              classes={classes}
              roles={roles}
              units={units}
              organizationExternalId={organizationExternalId}
              isNew
              displayLobs={displayLobs}
              displaySuperUser={displaySuperUser}
              displaySystemConfig={displaySystemConfig}
              overrideOrganizationValue={overrideOrganizationValue}
              isWizardUser={isWizardUser}
            />
          </CardDialog>
        );
      }}
    </Formik>
  );
};

UserAddDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onAddUser: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  users: PropTypes.array,
  units: PropTypes.array,
  organizationExternalId: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  displayLobs: PropTypes.bool,
  displaySuperUser: PropTypes.bool,
  displaySystemConfig: PropTypes.bool,
  overrideOrganizationValue: PropTypes.object,
  isWizardUser: PropTypes.bool,
};

const UserAddDialogStyled = withStyles(styles)(UserAddDialog);

export { UserAddDialogStyled as UserAddDialog };
