import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import CancelButton from '~/components/core/Buttons/CancelButton';

import CardDialog from '../../../CardDialog';
import { FsButton } from '../../../core';
import TextFieldFormik from '../../../TextFieldFormik';
import useAssignments from '../../AssignmentsContext';

import { useStyles } from '../../../../assets/styles';

const FIELD_IDS = {
  MAX_DAILY_EXPOSURE_ASSIGNMENTS: 'max_daily_exposure_assignments',
  MAX_DAILY_CLAIMS_ASSIGNMENTS: 'max_daily_claim_assignments',
};

const MaxDailyAssignmentsDialog = ({ handleClose, handleSubmit }) => {
  const { assignmentsConfigurations } = useAssignments();
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        [FIELD_IDS.MAX_DAILY_EXPOSURE_ASSIGNMENTS]: assignmentsConfigurations?.max_daily_exposure_assignments || '',
        [FIELD_IDS.MAX_DAILY_CLAIMS_ASSIGNMENTS]: assignmentsConfigurations?.max_daily_claim_assignments || '',
      }}
      validationSchema={Yup.object().shape({
        [FIELD_IDS.MAX_DAILY_EXPOSURE_ASSIGNMENTS]: Yup.number()
          .required('Required')
          .min(1, 'Value must be greater than zero'),
        [FIELD_IDS.MAX_DAILY_CLAIMS_ASSIGNMENTS]: Yup.number()
          .required('Required')
          .min(1, 'Value must be greater than zero'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await handleSubmit(values);
          await handleClose();
        } catch (error) {
          setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog isDialog onClose={handleClose} title="Max Assignments Per Day" fullWidth maxWidth="xs">
            <div className="flex space-x-40">
              <div className="w-full">
                <TextFieldFormik
                  id={FIELD_IDS.MAX_DAILY_EXPOSURE_ASSIGNMENTS}
                  label="Max Exposures per day"
                  className={classes.textField}
                  fullWidth
                  disabled={isSubmitting}
                />
              </div>
              <div className="w-full">
                <TextFieldFormik
                  id={FIELD_IDS.MAX_DAILY_CLAIMS_ASSIGNMENTS}
                  label="Max Claims per day"
                  className={classes.textField}
                  fullWidth
                  disabled={isSubmitting}
                />
              </div>
            </div>

            <div className={classes.buttonsContainer}>
              <CancelButton disabled={isSubmitting} onClick={handleClose} />
              <FsButton variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Save
              </FsButton>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

MaxDailyAssignmentsDialog.propTypes = {
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default MaxDailyAssignmentsDialog;
