import React from 'react';
import PropTypes from 'prop-types';

import useDataFetcher from '~/components/useDataFetcher';

import { useSysconfig } from '../../SystemConfigurationScreen';
import { ORG_UNITS_ROUTES } from '../OrganizationUnits/routes';

const UnitRelatedConfigurationsContext = React.createContext();

export const UnitRelatedConfigurationsProvider = ({ children, unitId }) => {
  const { organization } = useSysconfig();
  const { isLoading, isError, data } = useDataFetcher(
    ORG_UNITS_ROUTES.RELATED_CONFIGURATIONS({ organizationId: organization.id, unitId })
  );
  const configs = data?.configurations;
  return (
    <UnitRelatedConfigurationsContext.Provider
      value={{
        isUnitConfigsLoading: isLoading,
        isUnitConfigsError: isError,
        unitConfigs: configs,
        hasAnyConfigs: !!Object.keys(configs ?? {}).length,
      }}
    >
      {children}
    </UnitRelatedConfigurationsContext.Provider>
  );
};

UnitRelatedConfigurationsProvider.propTypes = {
  children: PropTypes.node,
  organizationId: PropTypes.number,
  unitId: PropTypes.number,
};

export const useUnitRelatedConfigurations = () => {
  const context = React.useContext(UnitRelatedConfigurationsContext);
  if (context === undefined) {
    throw new Error('useUnitRelatedConfigurations must be used within a UnitRelatedConfigurationsProvider');
  }
  return context;
};
