import React from 'react';
import PropTypes from 'prop-types';
import { Formik, useFormikContext } from 'formik';
import { noop } from 'lodash';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';

import CardDialog from '../../../../../../CardDialog';
import TextFieldFormik from '../../../../../../TextFieldFormik';
import ApiLimits, { LIMITS_FIELD_IDS } from '../../ApiLimits';

import { useStyles } from '../../../../../../../assets/styles';

const FIELD_IDS = {
  NAME: 'name',
  DESCRIPTION: 'description',
};

const EditServiceAccountInner = ({ onClose = noop }) => {
  const classes = useStyles();
  const { isSubmitting, handleSubmit } = useFormikContext();

  return (
    <CardDialog
      title="Edit service account"
      isDialog
      preventClose={isSubmitting}
      maxWidth="sm"
      fullWidth
      onClose={onClose}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextFieldFormik
            id={FIELD_IDS.NAME}
            label="Name"
            className={classes.textField}
            fullWidth
            disabled={isSubmitting}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldFormik
            id={FIELD_IDS.DESCRIPTION}
            label="Description"
            className={classes.textField}
            fullWidth
            disabled={isSubmitting}
          />
        </Grid>
        <ApiLimits />
      </Grid>
      <div className={classes.buttonsContainer}>
        <CancelButton disabled={isSubmitting} onClick={onClose} />
        <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
          Save
        </Button>
      </div>
    </CardDialog>
  );
};

EditServiceAccountInner.propTypes = {
  onClose: PropTypes.func,
};

const EditServiceAccountDialog = ({
  serviceAccount = {},
  serviceAccountNames = [],
  onClose = noop,
  handleFormSubmit = noop,
}) => (
  <Formik
    initialValues={{
      [FIELD_IDS.NAME]: serviceAccount?.name || '',
      [FIELD_IDS.DESCRIPTION]: serviceAccount?.description || '',
      [LIMITS_FIELD_IDS.INDEMNITY_RESERVES_LIMIT]: serviceAccount?.indemnity_reserves_limit || 0,
      [LIMITS_FIELD_IDS.INDEMNITY_PAYMENTS_LIMIT]: serviceAccount?.indemnity_payments_limit || 0,
      [LIMITS_FIELD_IDS.EXPENSES_RESERVES_LIMIT]: serviceAccount?.expenses_reserves_limit || 0,
      [LIMITS_FIELD_IDS.EXPENSES_PAYMENTS_LIMIT]: serviceAccount?.expenses_payments_limit || 0,
      [LIMITS_FIELD_IDS.RESERVES_LIMIT]: serviceAccount?.reserves_limit || 0,
      [LIMITS_FIELD_IDS.PAYMENTS_LIMIT]: serviceAccount?.payments_limit || 0,
    }}
    validationSchema={Yup.object().shape({
      [FIELD_IDS.NAME]: Yup.string()
        .required('Required')
        .notOneOf(
          serviceAccountNames.filter((name) => name !== serviceAccount?.name),
          'The Service Account name should be unique'
        ),
      [FIELD_IDS.DESCRIPTION]: Yup.string().required('Required'),
      [FIELD_IDS.DESCRIPTION]: Yup.string().required('Required'),
      [LIMITS_FIELD_IDS.INDEMNITY_PAYMENTS_LIMIT]: Yup.number().nullable(),
      [LIMITS_FIELD_IDS.INDEMNITY_RESERVES_LIMIT]: Yup.number().nullable(),
      [LIMITS_FIELD_IDS.EXPENSES_PAYMENTS_LIMIT]: Yup.number().nullable(),
      [LIMITS_FIELD_IDS.EXPENSES_RESERVES_LIMIT]: Yup.number().nullable(),
      [LIMITS_FIELD_IDS.PAYMENTS_LIMIT]: Yup.number().nullable(),
      [LIMITS_FIELD_IDS.RESERVES_LIMIT]: Yup.number().nullable(),
    })}
    onSubmit={async (values, formikProps) => {
      await handleFormSubmit(values);
      formikProps.setSubmitting(false);
    }}
  >
    <EditServiceAccountInner onClose={onClose} />
  </Formik>
);

EditServiceAccountDialog.propTypes = {
  onClose: PropTypes.func,
  handleFormSubmit: PropTypes.func,
  serviceAccount: PropTypes.object,
  serviceAccountNames: PropTypes.arrayOf(PropTypes.string),
};

export default EditServiceAccountDialog;
