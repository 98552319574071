const NOTIFICATIONS_RULES_TIMEFRAMES = {
  hours: {
    desc: 'Hours',
    is_available_for_time_based: false,
  },
  days: {
    desc: 'Days',
    is_available_for_time_based: true,
  },
  business_days: {
    desc: 'Business Days',
    is_available_for_time_based: true,
  },
  months: {
    desc: 'Months',
    is_available_for_time_based: true,
  },
} as const;

export default NOTIFICATIONS_RULES_TIMEFRAMES;
