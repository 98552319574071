import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { get } from 'lodash';

import Grid from '~/components/core/Atomic/Grid/Grid';
import SwitchFormik from '~/components/core/Formik/SwitchFormik';

import CheckboxFormik from '../../../../CheckboxFormik';
import useFormikChangeListener from '../../../../core/Formik/FormikChangeListener';
import FsTooltip from '../../../../core/FsWrappers/FsTooltip/FsTooltip';
import TextFieldFormik from '../../../../TextFieldFormik';
import FieldConfigurationCard from '../FieldConfigurationCard/FieldConfigurationCard';

import BaseConfigurationSection from './BaseConfigurationSection';
import ConfiguredFieldsSection from './ConfiguredFieldsSection';

import { useStyles } from '../../../../../assets/styles';

const ID_PREFIX = 'configuration.incident_details.pre_defined_fields';
const KEY = 'supplementary_information';
const SECTION_ID = `${ID_PREFIX}.${KEY}`;
const AUTO_SPECIFIC_ADDITIONAL_PATH = 'auto_incident_additional';
const SUPPLEMENTARY_BASE_FIELDS = [
  'did_anyone_take_photos_or_video',
  'photographer_contact_id',
  'is_other_policy_covering',
  'is_notification_only',
  'was_cctv_footage',
  'can_take_photos_of_damage',
  'other_policy_description',
];
const AUTO_SPECIFIC_SUPPLEMENTARY_INFORMATION_FIELDS = [
  'was_replacement_car_rented',
  'replacement_car_rental_date',
  'are_convictions_or_claims_in_the_last_5_years',
  'convictions_or_claim_description',
  'is_policyholder_occupation_right',
  'policyholder_current_occupation',
  'does_policyholder_second_occupation_exist',
  'policyholder_second_occupation',
];

const isMandatoryFieldsExists = (values) =>
  values?.lob === 'auto_claim'
    ? isSomeFieldsMandatory(values) || isSomeAutoSpecificFieldsMandatory(values)
    : isSomeFieldsMandatory(values);

const isSomeFieldsMandatory = (values) =>
  SUPPLEMENTARY_BASE_FIELDS.some((fieldId) => get(values, `${ID_PREFIX}.${fieldId}.mandatory`, false));

const isSomeAutoSpecificFieldsMandatory = (values) =>
  AUTO_SPECIFIC_SUPPLEMENTARY_INFORMATION_FIELDS.some((fieldId) =>
    get(values, `${ID_PREFIX}.${AUTO_SPECIFIC_ADDITIONAL_PATH}.${fieldId}.mandatory`, false)
  );

const SupplementaryInformationSection = () => {
  const { values, setFieldValue } = useFormikContext();
  const lob = get(values, 'lob');
  const title = get(values, `${SECTION_ID}.desc`);
  const classes = useStyles();
  const [isHidden, setIsHidden] = useState(false);
  const isAutoLob = lob === 'auto_claim';

  // Enforce all supplementary section to be hidden if the section is show is set to false
  useFormikChangeListener({
    listenForKeys: [`${SECTION_ID}.active`],
    onChange: (values, _) => {
      !get(values, `${SECTION_ID}.active`, false) ? setIsHidden(true) : setIsHidden(false);
    },
    runOnFirstRender: true,
  });

  // Enforce the section to be mandatory if any of the supplementary fields are mandatory
  useFormikChangeListener({
    listenForKeys: SUPPLEMENTARY_BASE_FIELDS.forEach((fieldId) => `${ID_PREFIX}.${fieldId}.mandatory`),
    onChange: (values, _) => {
      if (isMandatoryFieldsExists(values)) {
        setFieldValue(`${SECTION_ID}.mandatory`, true);
      }
    },
    runOnFirstRender: true,
  });

  return (
    <BaseConfigurationSection title={title} subtitle={`Key: ${KEY}`} isHidden={isHidden} isSection>
      <Grid item xs={6}>
        <TextFieldFormik id={`${SECTION_ID}.desc`} label="Display Name" fullWidth />
      </Grid>
      <Grid item xs={6}>
        <TextFieldFormik id={SECTION_ID} value={KEY} label="Key" fullWidth disabled />
      </Grid>
      <Grid item xs={12}>
        <Grid container className="flex items-center">
          <Grid item xs={2}>
            <SwitchFormik id={`${SECTION_ID}.active`} label="Show" className={classes.formsSwitch} hideErrorGap />
          </Grid>
          <Grid item xs={10}>
            {isMandatoryFieldsExists(values) ? (
              <FsTooltip arrow title="One or more inner fields are required">
                <CheckboxFormik id={`${SECTION_ID}.mandatory`} label="Required field for claim submission" disabled />
              </FsTooltip>
            ) : (
              <CheckboxFormik
                id={`${SECTION_ID}.mandatory`}
                label="Required field for claim submission"
                onChange={() =>
                  setFieldValue(`${SECTION_ID}.mandatory`, !get(values, `${SECTION_ID}.mandatory`, false))
                }
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      {SUPPLEMENTARY_BASE_FIELDS.map((fieldId) => (
        <FieldConfigurationCard key={fieldId} idPrefix={ID_PREFIX} fieldId={fieldId} disabledCollapse={isHidden} />
      ))}
      {isAutoLob &&
        AUTO_SPECIFIC_SUPPLEMENTARY_INFORMATION_FIELDS.map((fieldId) => (
          <FieldConfigurationCard
            key={fieldId}
            idPrefix={`${ID_PREFIX}.${AUTO_SPECIFIC_ADDITIONAL_PATH}`}
            fieldId={fieldId}
            disabledCollapse={isHidden}
          />
        ))}
      <ConfiguredFieldsSection
        fieldId="configuration.incident_details.configured_fields"
        section="supplementary_info"
        disabled={isHidden}
      />
    </BaseConfigurationSection>
  );
};

export default SupplementaryInformationSection;
