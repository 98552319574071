import React from 'react';
import PropTypes from 'prop-types';

import RadioButtonGroupFormik from '~/components/core/Formik/RadioButtonGroupFormik';

import styles from './styles.module.scss';

export const VendorPermissionButtonGroupFormik = ({ fieldId, viewOnly }) => {
  const options = [
    {
      text: 'Yes',
      optionValue: true,
    },
    {
      text: 'No',
      optionValue: false,
    },
  ];

  return (
    <div className={styles.buttonGroupContainer}>
      <RadioButtonGroupFormik
        id={fieldId}
        label=""
        options={options}
        buttonVariant={RadioButtonGroupFormik.BUTTON_VARIANT.DEFAULT}
        btnClassName={styles.buttonGroup}
        labelClassName={styles.buttonGroup}
        withErrorHelper={false}
        viewOnly={viewOnly}
      />
    </div>
  );
};

VendorPermissionButtonGroupFormik.propTypes = {
  fieldId: PropTypes.string.isRequired,
  viewOnly: PropTypes.bool,
};
