import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '../../../../../core';

import styles from './styles.module.scss';

export const InnerSectionContainer = ({ title, children }) => (
  <div className={styles.innerSectionContainer}>
    <div className={styles.innerSectionTitleContainer}>
      <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.SEMI_BOLD} colorVariant={Text.COLOR_VARIANTS.BLACK}>
        {title}
      </Text>
    </div>
    <div>{children}</div>
  </div>
);

InnerSectionContainer.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
