import axios from 'axios';

import { reportAxiosError } from '../../../../Utils';

const getCountriesRoute = (organizationId) => `/api/v1/organizations/${organizationId}/countries_configuration`;
const getLocationRoute = (organizationId) => `/api/v1/organizations/${organizationId}/countries_configuration/location`;

const getMultipleCountiresRoute = (organizationId) =>
  `/sysconfig/api/v1/organizations/${organizationId}/multiple_countries/configuration`;

export const updateCountriesConfiguration = async ({ updatedData, organizationId, subOrganizationId }) => {
  try {
    await axios.put(getCountriesRoute(organizationId), updatedData, {
      params: { sub_organization_id: subOrganizationId },
    });
  } catch (error) {
    await reportAxiosError(error);
    throw error;
  }
};

export const getCountriesConfiguration = async ({ organizationId, subOrganizationId }) => {
  try {
    const { data } = await axios.get(getCountriesRoute(organizationId), {
      params: {
        sub_organization_id: subOrganizationId,
      },
    });
    return data;
  } catch (error) {
    await reportAxiosError(error);
    throw error;
  }
};

export const updateCountryLocation = async ({
  organizationId,
  subOrganizationId,
  locationType,
  lob,
  countries,
  countriesSelection,
}) => {
  try {
    await axios.put(
      getLocationRoute(organizationId),
      {
        countries_selection: countriesSelection,
        countries,
      },
      {
        params: {
          sub_organization_id: subOrganizationId,
          location_type: locationType,
          lob,
        },
      }
    );
  } catch (error) {
    await reportAxiosError(error);
    throw error;
  }
};

export const toggleIsMulptipleCountires = async ({ organizationId, isEnabled }) => {
  try {
    await axios.patch(getMultipleCountiresRoute(organizationId), {
      is_enabled: isEnabled,
    });
  } catch (error) {
    await reportAxiosError(error);
    throw error;
  }
};
