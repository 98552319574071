import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';

import Checkbox from '~/components/core/Atomic/Checkboxes/Checkbox';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';
import RadioWithButtonWrapper from '~/components/core/RadioWithButtonWrapper';

import CardDialog from '../../../CardDialog';
import WithConfirm from '../../../ConfirmModal';
import { LoadingSwitch } from '../../../core';
import { InfoIcon } from '../../../icons';
import InlineIconButton from '../../../InlineIconButton';
import useOrganization from '../../../OrganizationContext';
import SubOrganizationSelect from '../../../TPA/SubOrganizations/SubOrganizationSelect';
import OperationsBreadcrumbs from '../../OperationsBreadcrumbs';

import AllLineOfBusiness from './AllLineOfBusiness';
import {
  getCountriesConfiguration,
  toggleIsMulptipleCountires,
  updateCountriesConfiguration,
  updateCountryLocation,
} from './api';
import PerLineOfBusiness from './PerLineOfBusiness';

import styles from './supportedLocations.module.scss';

const SupportedLocations = ({ displayBreadcrumbs, displayTitle }) => {
  const { subOrganizationEnabled, organizationId } = useOrganization();
  const [countriesConfiguration, setCountriesConfiguration] = useState();
  const [scope, setScope] = useState();
  const [subOrganizationId, setSubOrganizationId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const loadData = useCallback(async () => {
    setIsLoading(true);

    const data = await getCountriesConfiguration({ organizationId, subOrganizationId });

    if (!data) {
      setIsError(true);
    }
    setCountriesConfiguration(data);
    setIsLoading(false);

    return data;
  }, [organizationId, subOrganizationId]);

  useEffect(() => {
    if (subOrganizationEnabled && !subOrganizationId) {
      return;
    }

    const fetchData = async () => {
      const { is_all_lobs } = await loadData();

      if (is_all_lobs) {
        setScope(SCOPE.ALL_LINE_OF_BUSINESS);
      } else if (is_all_lobs === false) {
        setScope(SCOPE.PER_LINE_OF_BUSINESS);
      }
    };
    fetchData();
  }, [loadData, subOrganizationEnabled, subOrganizationId]);

  const updateScope = async (value) => {
    if (!Object.values(SCOPE).includes(value)) {
      throw new Error('Unsupported Scope value');
    }

    await updateCountriesConfiguration({
      organizationId,
      subOrganizationId,
      updatedData: { is_all_lobs: value === SCOPE.ALL_LINE_OF_BUSINESS },
    });
    setScope(value);
    await loadData();
  };

  const updateLocation = async ({ locationType, lob, ...rest }) => {
    await updateCountryLocation({
      organizationId,
      subOrganizationId,
      locationType,
      lob,
      ...rest,
    });
    await loadData();
  };

  const scopeRadioBtn = ({ value, text, tooltip }) => {
    const disabled = isLoading || (subOrganizationEnabled && !subOrganizationId);

    const radioWithButtonWrapper = (
      <RadioWithButtonWrapper
        className={styles.scopeRadioBtn}
        value={value}
        text={text}
        disabled={disabled}
        checked={scope === value}
        icon={
          <InlineIconButton
            className={styles.scopeRadioBtnIcon}
            icon={InfoIcon}
            disabled={disabled}
            tooltipTitle={tooltip}
            onClick={noop}
            wrapWithSpan
          />
        }
        iconLocation={RadioWithButtonWrapper.POSITIONS.END}
        onChange={async () => await updateScope(value)}
      />
    );

    return isEmpty(scope) ? (
      radioWithButtonWrapper
    ) : (
      <WithConfirm
        title={<span className={styles.confirmTitleDialog}>Are you sure you want to switch ?</span>}
        contentText="Switching scopes will reset existing definitions"
        primaryButtonName="Ok"
        shouldCloseOnPrimary
        triggerMethod="onChange"
      >
        {radioWithButtonWrapper}
      </WithConfirm>
    );
  };

  return (
    <CardDialog noCardTitle containerClassName={styles.supportedLocations}>
      {displayBreadcrumbs && <OperationsBreadcrumbs currentTab="Supported Locations" />}
      <div className="flex flex-col items-start">
        {!displayBreadcrumbs && (
          <div>
            <MultipleCountriesEnabledCheckbox />
          </div>
        )}
        <Grid container>
          {displayTitle && (
            <>
              <Grid item xs={12} className={styles.supportedLocationsTitle}>
                <Typography variant="subtitle1">SUPPORTED LOCATIONS</Typography>
              </Grid>
              <Grid item xs={12} className={styles.configureSupportSubtitle}>
                Configure the supported locations per each location category
              </Grid>
            </>
          )}
          {subOrganizationEnabled && (
            <Grid item className={styles.selectionContainer}>
              <Typography variant="subtitle2" className={styles.selectionTitle}>
                Choose Sub Organization to Configure
              </Typography>
              <SubOrganizationSelect
                onChange={(value) => {
                  if (value !== subOrganizationId) {
                    setSubOrganizationId(value);
                  }
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} className={styles.selectionContainer}>
            <Typography variant="subtitle2" className={styles.selectionTitle}>
              Define scope
            </Typography>
            {scopeRadioBtn({
              value: SCOPE.ALL_LINE_OF_BUSINESS,
              text: 'All Lines of Business',
              tooltip: 'Define unified location settings for current and future LOBs',
            })}
            {scopeRadioBtn({
              value: SCOPE.PER_LINE_OF_BUSINESS,
              text: 'Per Line of Business',
              tooltip: 'Define the location settings per each LOB separately',
            })}
          </Grid>
          <Grid item xs={12}>
            <LoadingSwitch isLoading={isLoading} isError={isError}>
              {scope === SCOPE.ALL_LINE_OF_BUSINESS && (
                <AllLineOfBusiness countriesConfiguration={countriesConfiguration} onUpdate={updateLocation} />
              )}
              {scope === SCOPE.PER_LINE_OF_BUSINESS && (
                <PerLineOfBusiness
                  countriesConfiguration={countriesConfiguration}
                  onUpdate={updateLocation}
                  subOrganizationId={subOrganizationId}
                />
              )}
            </LoadingSwitch>
          </Grid>
        </Grid>
      </div>
    </CardDialog>
  );
};

SupportedLocations.propTypes = {
  displayBreadcrumbs: PropTypes.bool,
  displayTitle: PropTypes.bool,
};

const SCOPE = {
  ALL_LINE_OF_BUSINESS: 'all',
  PER_LINE_OF_BUSINESS: 'per_line_of_business',
};

const MultipleCountriesEnabledCheckbox = () => {
  const { isMultipleCountriesEnabled, organizationId, reloadOperationalDetails } = useOrganization();

  const toggleIsMultipleCountries = async (isEnabled) => {
    await toggleIsMulptipleCountires({ organizationId, isEnabled });
    reloadOperationalDetails && (await reloadOperationalDetails());
  };

  return (
    <Checkbox
      onChange={(e, checked) => toggleIsMultipleCountries(checked)}
      checked={isMultipleCountriesEnabled}
      label="Multiple Countries enabled"
      labelPosition="right"
      className="my-4"
    />
  );
};

export default SupportedLocations;
