import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Switch } from '@material-ui/core';

import { useStyles } from '../../../../../../../assets/styles';

export function AsyncSwitch({ checked, onChange, disabled }) {
  const classes = useStyles();
  const [isActionRunning, setIsActionRunning] = useState(false);

  const handleChange = async (e) => {
    setIsActionRunning(true);
    await onChange(e);
    setIsActionRunning(false);
  };

  return (
    <Switch
      checked={checked}
      onChange={handleChange}
      className={classes.formsSwitch}
      disabled={disabled || isActionRunning}
    />
  );
}

AsyncSwitch.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
