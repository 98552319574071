const NOTIFICATIONS_RULES_RECIPIENTS = {
  claim_owner: {
    desc: 'Claim owner',
  },
  exposure_owner: {
    desc: 'Exposure owner',
    scope: 'exposure',
  },
  claim_owner_supervisor: {
    desc: "Claim owner's {manager_term}",
  },
  exposure_owner_supervisor: {
    desc: "Exposure owner's {manager_term}",
    scope: 'exposure',
  },
  organization_unit_leader: {
    desc: 'Unit Leader',
  },
} as const;

export default NOTIFICATIONS_RULES_RECIPIENTS;
