import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik, useFormikContext } from 'formik';
import { omit, pick } from 'lodash';

import cn from '~/Utils/cn';

import { reportAxiosError, reportErrorInProductionOrThrow } from '../../../../../Utils';
import { ConfirmModal } from '../../../../ConfirmModal';
import { FsIconButton } from '../../../../core';
import { ContactPlusIcon, PencilIcon, TrashIcon } from '../../../../icons';
import { useSysconfig } from '../../../SystemConfigurationScreen';
import { PERMISSIONS_MIXPANEL_EVENTS, PERMISSIONS_MIXPANEL_SOURCES, safeMixpanelTrack } from '../MixpanelEvents';
import { useDeleteProfileDialog } from '../ProfileHooks/useDeleteProfileDialog';
import { useProfileStatus } from '../ProfileHooks/useProfileStatus';
import { PROFILES_ROUTES } from '../routes';

import { UsersSelectStep } from './UsersSelect/UsersSelectStep';
import { EditProfileAlertBanner } from './EditProfileDialog';
import { EditUsersDialog } from './EditUsersDialog';
import { FORM_FIELDS, getFormValidationScheme, profileToInitialValues, saveProfileWithConfirmProps } from './formUtils';
import { ProfileDialogBase } from './ProfileDialogBase';
import { ProfilePermissionsForm } from './ProfilePermissionsForm';

import styles from './styles.module.scss';

const EDIT_ACTIONS = {
  USERS: 'users',
  PROFILE: 'profile',
};

export const ViewProfileDialog = ({ profile, onClose, onDelete, reloadData, hideActions }) => {
  const { organization: sysConfigOrganization } = useSysconfig();

  const { profileStatus, reloadProfileStatus } = useProfileStatus(profile);

  const [isEditingProfile, setIsEditingProfile] = React.useState(false);
  const [isEditingUsers, setIsEditingUsers] = React.useState(false);

  const handleSubmit = async (data) => {
    let payload = {};
    let url;
    if (isEditingUsers) {
      payload.users = data.users;
      url = PROFILES_ROUTES.USERS(sysConfigOrganization.id, profile.id);
    } else if (isEditingProfile) {
      payload = pick(data, [
        FORM_FIELDS.display_name,
        FORM_FIELDS.description,
        FORM_FIELDS.default_permission,
        FORM_FIELDS.permissions,
      ]);
      url = PROFILES_ROUTES.UPDATE(sysConfigOrganization.id, profile.id);
    } else {
      reportErrorInProductionOrThrow('Invalid submit, form is not editing anything');
    }

    await axios.patch(url, payload);
    await Promise.all([reloadData(), reloadProfileStatus()]);

    setIsEditingProfile(false);
    setIsEditingUsers(false);
  };

  const getInitialValues = () => {
    if (isEditingUsers) {
      return pick(profileToInitialValues(profile), ['users']);
    } else if (isEditingProfile) {
      return omit(profileToInitialValues(profile), ['users']);
    } else {
      return profileToInitialValues(profile);
    }
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await handleSubmit(values);
        } catch (e) {
          await reportAxiosError(e);
        }
        setSubmitting(false);
      }}
      validateOnChange={false}
      enableReinitialize
      validationSchema={
        isEditingUsers || hideActions
          ? undefined
          : getFormValidationScheme({
              organizationId: sysConfigOrganization.id,
              currentProfileName: profile.display_name,
            })
      }
      validateOnBlur={false}
    >
      <ViewProfileDialogInner
        profile={profile}
        onClose={onClose}
        onDelete={onDelete}
        reloadData={reloadData}
        isEditingProfile={isEditingProfile}
        setIsEditingProfile={setIsEditingProfile}
        isEditingUsers={isEditingUsers}
        setIsEditingUsers={setIsEditingUsers}
        profileStatus={profileStatus}
        reloadProfileStatus={reloadProfileStatus}
        hideActions={hideActions}
      />
    </Formik>
  );
};

ViewProfileDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  reloadData: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  hideActions: PropTypes.bool,
};

const ViewProfileDialogInner = ({
  profile,
  onClose,
  onDelete,
  isEditingProfile,
  setIsEditingProfile,
  isEditingUsers,
  setIsEditingUsers,
  profileStatus,
  reloadProfileStatus,
  hideActions,
}) => {
  const { resetForm } = useFormikContext();
  const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] = React.useState(false);
  const isEditing = isEditingProfile || isEditingUsers;

  const handleClose = React.useCallback(() => {
    if (isEditing) {
      setIsEditingProfile(false);
      setIsEditingUsers(false);
      resetForm();
    } else {
      onClose();
    }
  }, [isEditing, onClose, resetForm, setIsEditingProfile, setIsEditingUsers]);

  const handleDelete = React.useCallback(async () => {
    await reloadProfileStatus();
    setIsDeleteConfirmDialogOpen(true);
  }, [reloadProfileStatus]);

  const handleEdit = (actionType) => {
    if (actionType === EDIT_ACTIONS.PROFILE) {
      setIsEditingProfile(true);
      safeMixpanelTrack(PERMISSIONS_MIXPANEL_EVENTS.edit_permission_profile, {
        profile_id: profile.id,
        profile_name: profile.display_name,
        default_permission: profile.defaults,
        number_of_users: profile.number_of_users,
        source: PERMISSIONS_MIXPANEL_SOURCES.VIEW_PROFILE,
      });
    } else if (actionType === EDIT_ACTIONS.USERS) {
      setIsEditingUsers(true);
      safeMixpanelTrack(PERMISSIONS_MIXPANEL_EVENTS.permissions_profile_add_users, {
        profile_id: profile.id,
        profile_name: profile.display_name,
        default_permission: profile.defaults,
        number_of_users: profile.number_of_users,
        source: PERMISSIONS_MIXPANEL_SOURCES.VIEW_PROFILE,
      });
    }
  };

  const actions = (
    <>
      <FsIconButton icon={PencilIcon} onClick={() => handleEdit(EDIT_ACTIONS.PROFILE)} />
      <FsIconButton icon={TrashIcon} onClick={handleDelete} />
      <FsIconButton icon={ContactPlusIcon} onClick={() => handleEdit(EDIT_ACTIONS.USERS)} />
    </>
  );

  const getInnerComponent = () => {
    if (isEditingUsers) {
      return <UsersSelectStep title="Assign Users" />;
    } else if (isEditingProfile) {
      return (
        <>
          <div className={cn(styles.alertContainer, styles.inViewDialog)}>
            <EditProfileAlertBanner />
          </div>
          <ProfilePermissionsForm profile={profile} />
        </>
      );
    } else {
      return <ProfilePermissionsForm viewOnly profile={profile} />;
    }
  };

  const getConfirmDialogProps = () => {
    if (isEditingProfile) {
      return saveProfileWithConfirmProps;
    } else if (isEditingUsers) {
      return EditUsersDialog.confirmDialogProps;
    } else {
      return undefined;
    }
  };

  const getDialogMixpanelConfig = () => {
    if (isEditingUsers) {
      return {
        source: PERMISSIONS_MIXPANEL_SOURCES.TABLE,
        submitConfirmDialogPresentedEventName:
          PERMISSIONS_MIXPANEL_EVENTS.permission_profile_assign_users_pop_up_presented,
      };
    }
  };

  return (
    <>
      <ProfileDialogBase
        onClose={handleClose}
        title={`${profile.display_name} Profile`}
        viewOnly={!isEditing}
        topActions={!hideActions && !isEditing ? actions : undefined}
        withActions={!hideActions && isEditing}
        primaryWithConfirmProps={getConfirmDialogProps()}
        mixpanelConfig={getDialogMixpanelConfig()}
        setAllTouchedOnSubmit={isEditingProfile}
      >
        <div className={styles.viewDialogContent}>{getInnerComponent()}</div>
      </ProfileDialogBase>
      {isDeleteConfirmDialogOpen && (
        <DeleteConfirmDialog
          profile={profile}
          profileStatus={profileStatus}
          onClose={() => setIsDeleteConfirmDialogOpen(false)}
          onConfirm={() => {
            setIsDeleteConfirmDialogOpen(false);
            onDelete(profile);
          }}
        />
      )}
    </>
  );
};

ViewProfileDialogInner.propTypes = {
  onClose: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  isEditingProfile: PropTypes.bool.isRequired,
  setIsEditingProfile: PropTypes.func.isRequired,
  isEditingUsers: PropTypes.bool.isRequired,
  setIsEditingUsers: PropTypes.func.isRequired,
  profileStatus: PropTypes.object.isRequired,
  reloadProfileStatus: PropTypes.func.isRequired,
  hideActions: PropTypes.bool,
};

const DeleteConfirmDialog = ({ profile, profileStatus, onClose, onConfirm }) => {
  const { content, removeSecondaryButton, primaryButtonName, secondaryButtonName, secondaryButtonProps } =
    useDeleteProfileDialog({ profile, profileStatus });

  return (
    <ConfirmModal
      title=""
      onClose={onClose}
      isOpen
      primaryButtonName={primaryButtonName}
      secondaryButtonName={secondaryButtonName}
      secondaryButtonProps={secondaryButtonProps}
      onPrimaryBtnClick={onConfirm}
      maxWidth="lg"
      contentText={content}
      removeSecondaryButton={removeSecondaryButton}
    />
  );
};

DeleteConfirmDialog.propTypes = {
  profile: PropTypes.object.isRequired,
  profileStatus: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
