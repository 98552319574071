import React from 'react';

import { INVOLVED_PROPERTY_FIELD_IDS, INVOLVED_VEHICLE_FIELD_IDS } from '../constants';

import InvolvedEntitySection from './InvolvedEntitySection';

const InvolvedVehicleSection = (): JSX.Element => (
  <InvolvedEntitySection
    entityId="involved_vehicle"
    entityDesc="Involved Vehicle"
    baseInvolvedFields={INVOLVED_PROPERTY_FIELD_IDS}
    involvedFields={INVOLVED_VEHICLE_FIELD_IDS}
  />
);

export default InvolvedVehicleSection;
