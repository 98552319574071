import React from 'react';
import PropTypes from 'prop-types';

import { FsTooltip, Text } from '../../../../../../../core';
import { ErrorHelperTextFormik } from '../../../../../../../core/Formik/ErrorHelperTextFormik';
import { InfoIcon } from '../../../../../../../icons';
import { getVendorPermissionFieldId } from '../Utils';

import { permissionPropType } from './propTypes';
import { VendorPermissionButtonGroupFormik } from './VendorPermissionButtonGroup';

import styles from './styles.module.scss';

export const PermissionItemTile = ({ permission, viewOnly }) => {
  const formikFieldId = getVendorPermissionFieldId(permission);
  const permissionTitle = `${permission.type.toUpperCase()}: ${permission.name}`;

  return (
    <div className={styles.innerSectionItemContainer}>
      <div className={styles.titleSection}>
        <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.MEDIUM} colorVariant={Text.COLOR_VARIANTS.BLACK}>
          {permissionTitle}
        </Text>
        <span className={styles.infoIconContainer}>
          {permission.subtitle && (
            <FsTooltip title={permission.subtitle}>
              <InfoIcon size={20} />
            </FsTooltip>
          )}
        </span>
        <ErrorHelperTextFormik id={formikFieldId} />
      </div>
      <div className={styles.actionsContainer}>
        <VendorPermissionButtonGroupFormik
          viewOnly={viewOnly || permission.is_read_only}
          fieldId={formikFieldId}
          allowedVerbsIds={permission.allowedVerbs}
        />
      </div>
    </div>
  );
};

PermissionItemTile.propTypes = {
  permission: permissionPropType.isRequired,
  viewOnly: PropTypes.bool,
};
