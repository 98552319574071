import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, useFormikContext } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';

import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';

import CardDialog from '../../../../../CardDialog';
import { FsButton, Heading, Text } from '../../../../../core';
import { PencilIcon } from '../../../../../icons';
import InlineIconButton from '../../../../../InlineIconButton';
import TextFieldFormik, { NumericTextFieldFormik } from '../../../../../TextFieldFormik';

import { useStyles } from '../../../../../../assets/styles';
import styles from './EventSettings.module.scss';

const FIELDS = {
  TRIES: 'notify_after_x_tries',
  EMAILS: 'emails',
};

const RetryNotification = ({ client, onSubmit }) => {
  const classes = useStyles();
  const [isNotificationDialogOpen, setNotificationDialogOpen] = useState(false);

  const handleOpenNotificationDialog = () => {
    setNotificationDialogOpen(true);
  };

  const handleCloseNotificationDialog = () => {
    setNotificationDialogOpen(false);
  };

  const initialValues = {
    [FIELDS.TRIES]: client?.[FIELDS.TRIES] || '',
    [FIELDS.EMAILS]: client?.[FIELDS.EMAILS] || '',
  };

  const validationSchema = Yup.object().shape({
    [FIELDS.TRIES]: Yup.number().required('Required').min(0, 'Must be 1 or higher').max('100', 'Up to 100 tries'),
    [FIELDS.EMAILS]: Yup.string()
      .required('Required')
      .test({
        name: 'emails',
        test(value) {
          if (!value) {
            return false;
          }

          const firstInvalidEmail = value
            .split(',')
            .map((email) => email.trim())
            .filter((v) => !_.isEmpty(v))
            .find((v) => !Yup.string().email().isValidSync(v));

          return !firstInvalidEmail
            ? true
            : this.createError({
                message: `The email address '${firstInvalidEmail}' is invalid.`,
              });
        },
      }),
  });

  const handleSubmit = async (values, formikProps) => {
    try {
      await onSubmit(values);
    } catch (error) {
      formikProps.setSubmitting(false);
    }
    handleCloseNotificationDialog();
  };

  return (
    <div>
      <Heading variant={Heading.TYPES.H3} className={styles.triesHeader}>
        Retry Notifications
      </Heading>
      <InlineIconButton className={classes.inlineEditIcon} icon={PencilIcon} onClick={handleOpenNotificationDialog} />
      {client.notify_after_x_tries && (
        <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
          {`After ${client.notify_after_x_tries} ${client.notify_after_x_tries === 1 ? 'try' : 'tries'} send email to ${
            client.emails
          }`}
        </Text>
      )}
      {isNotificationDialogOpen ? (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {(formikProps) => {
            const handleClear = async () => {
              await handleSubmit(
                {
                  [FIELDS.TRIES]: null,
                  [FIELDS.EMAILS]: null,
                },
                formikProps
              );
            };
            return <InnerRetryNotification onCancel={handleCloseNotificationDialog} onClear={handleClear} />;
          }}
        </Formik>
      ) : null}
    </div>
  );
};

RetryNotification.propTypes = {
  client: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default RetryNotification;

const InnerRetryNotification = ({ onCancel, onClear }) => {
  const classes = useStyles();
  const { isSubmitting, handleSubmit } = useFormikContext();

  return (
    <CardDialog
      isDialog
      title="Retry Notification"
      fullWidth
      onClose={onCancel}
      preventClose={isSubmitting}
      className={styles.retry}
    >
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <NumericTextFieldFormik
            id={FIELDS.TRIES}
            label="Send Notification After"
            className={classes.textField}
            fullWidth
            disabled={isSubmitting}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldFormik
            id={FIELDS.EMAILS}
            label="Send Notification To"
            className={classes.textField}
            fullWidth
            disabled={isSubmitting}
            helperText="Comma separated emails"
          />
        </Grid>
        <Grid item xs={6}>
          <FsButton onClick={onClear} disabled={isSubmitting} variant="text">
            CLEAR NOTIFICATIONS
          </FsButton>
        </Grid>
        <Grid item xs={6}>
          <div className={`${classes.buttonsContainer} ${styles.retryConfirmationButtons}`}>
            <CancelButton disabled={isSubmitting} onClick={onCancel} />
            <FsButton variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
              Save
            </FsButton>
          </div>
        </Grid>
      </Grid>
    </CardDialog>
  );
};

InnerRetryNotification.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};
