export const buildUser = ({ user, userToUnitDict, leaderToUnitsDict }) => ({
  ...user,
  ...(user.is_removed
    ? { username: `${user.username} (Deactivated)`, styles: { color: '#00000061', pointerEvents: 'none' } }
    : {}),
  unit: userToUnitDict[user.id],
  unit_id: userToUnitDict[user.id]?.id,
  unitsLedByUser: leaderToUnitsDict[user.id] || [],
});

export const prepareValuesForSave = ({ values }) => {
  const data = {
    ...values,
    ...(values
      ? {
          first_name: values?.first_name?.trim(),
          last_name: values?.last_name?.trim(),
          email: values?.email?.trim(),
        }
      : {}),
  };
  delete data.unitsLedByUser;
  delete data.unit;
  return data;
};
