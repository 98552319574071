const NOTIFICATIONS_ENTITY_STATUSES = {
  open: {
    desc: 'Open',
  },
  close: {
    desc: 'Close',
  },
  all: {
    desc: 'All',
  },
} as const;

export default NOTIFICATIONS_ENTITY_STATUSES;
