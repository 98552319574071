import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { get } from 'lodash';

import Grid from '~/components/core/Atomic/Grid/Grid';
import SwitchFormik from '~/components/core/Formik/SwitchFormik';

import CheckboxFormik from '../../../../CheckboxFormik';

import { useStyles } from '../../../../../assets/styles';

const ShowRequiredPanel = ({ id, label }) => {
  const classes = useStyles();
  const { values } = useFormikContext();
  const isActive = get(values, `${id}.active`, false);
  const isRequired = get(values, `${id}.mandatory`, false);

  return (
    <Grid item xs={12}>
      <div className="mx-0 my-4 w-full rounded-md border border-solid border-slate-600 p-16">
        <div className="flex flex-nowrap items-center justify-between space-x-12">
          <div className="flex-grow">{label}</div>
          <div className="flex items-center">
            <SwitchFormik
              id={`${id}.active`}
              className={classes.formsSwitch}
              size="small"
              label="Show"
              hideErrorGap
              disabled={isRequired}
            />
            <CheckboxFormik id={`${id}.mandatory`} label="Required" containerClassName="pl-20" disabled={!isActive} />
          </div>
        </div>
      </div>
    </Grid>
  );
};

ShowRequiredPanel.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default ShowRequiredPanel;
