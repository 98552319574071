import React from 'react';
import PropTypes from 'prop-types';

import colors from '~/assets/colors.module.scss';
import { Text } from '~/components/core';
import { EyeSlashIcon } from '~/components/icons';

const HiddenIndication = ({ isActive = false }) => (
  <div className="h-20 w-[90px] pr-20">
    {!isActive ? (
      <div className="flex items-center justify-center">
        <EyeSlashIcon iconColor={colors.secondaryText} disabled />
        <i className="ml-4">
          <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR} colorVariant={Text.COLOR_VARIANTS.SECONDARY}>
            Hidden
          </Text>
        </i>
      </div>
    ) : (
      <></>
    )}
  </div>
);

HiddenIndication.propTypes = {
  isActive: PropTypes.bool,
};

export default HiddenIndication;
