import React from 'react';
import { useFormikContext } from 'formik';
import { get, isEmpty } from 'lodash';

import { BODILY_INJURIES_FIELD_IDS } from '../constants';

import SubSectionWrapper from './SubSectionWrapper';

const ID_PREFIX = 'configuration.involved_parties';
const LOSS_OBJECT_ID = 'bodily_injury_loss';
const LOSS_OBJECT_FIELD_IDS = [
  'was_ambulance_needed',
  'was_hospitalized',
  'hospital_contact_id',
  'admission_datetime',
  'discharge_datetime',
  'legal_venue_state',
  'legal_venue_reason',
  'injuries_description',
  'note',
];

const BodilyInjurySubSection = ({ partyKey }: { partyKey: string }): JSX.Element => {
  const { values } = useFormikContext();

  const isAllowed = get(values, `${ID_PREFIX}.${partyKey}.is_allowed_bodily_injury`, false);
  const id = `${ID_PREFIX}.${partyKey}.${LOSS_OBJECT_ID}`;
  const title = get(values, `${id}.desc`);

  if (!isAllowed || isEmpty(get(values, id, {}))) {
    return <></>;
  }

  return (
    <SubSectionWrapper
      id={id}
      idPrefix={`${ID_PREFIX}.${partyKey}`}
      title={title}
      sectionKey={LOSS_OBJECT_ID}
      lossSubSectionQuestionId="is_injured"
      lossObjectFieldIds={LOSS_OBJECT_FIELD_IDS}
      lossItemsFieldIds={BODILY_INJURIES_FIELD_IDS}
      lossItemsFieldIdPrefix="bodily_injuries"
      lossItemsSectionDesc="Injuries"
      withIcdCodes
    />
  );
};

export default BodilyInjurySubSection;
