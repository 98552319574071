import React from 'react';

import type { IconProps } from '~/components/icons/types';

import SvgIcon from '../core/SvgIcon';

const CopyFileIcon: React.FC<IconProps> = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" disableStrokeColor iconColor={iconColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.18934 5.68934C4.47064 5.40804 4.85217 5.25 5.25 5.25H12.75C12.9489 5.25 13.1397 5.32902 13.2803 5.46967L17.0303 9.21967C17.171 9.36032 17.25 9.55109 17.25 9.75V20.25C17.25 20.6478 17.092 21.0294 16.8107 21.3107C16.5294 21.592 16.1478 21.75 15.75 21.75H5.25C4.85218 21.75 4.47065 21.592 4.18934 21.3107C3.90804 21.0294 3.75 20.6478 3.75 20.25V6.75C3.75 6.35217 3.90804 5.97064 4.18934 5.68934ZM12.4393 6.75H5.25L5.25 20.25H15.75V10.0607L12.4393 6.75Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.18934 2.68934C7.47064 2.40804 7.85217 2.25 8.25 2.25H15.75C15.9489 2.25 16.1397 2.32902 16.2803 2.46967L20.0303 6.21967C20.171 6.36032 20.25 6.55109 20.25 6.75V17.25C20.25 17.6478 20.092 18.0294 19.8107 18.3107C19.5294 18.592 19.1478 18.75 18.75 18.75H16.5C16.0858 18.75 15.75 18.4142 15.75 18C15.75 17.5858 16.0858 17.25 16.5 17.25H18.75V7.06066L15.4393 3.75L8.25 3.75V6C8.25 6.41421 7.91421 6.75 7.5 6.75C7.08579 6.75 6.75 6.41421 6.75 6V3.75C6.75 3.35217 6.90804 2.97064 7.18934 2.68934Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 14.25C7.5 13.8358 7.83579 13.5 8.25 13.5H12.75C13.1642 13.5 13.5 13.8358 13.5 14.25C13.5 14.6642 13.1642 15 12.75 15H8.25C7.83579 15 7.5 14.6642 7.5 14.25Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 17.25C7.5 16.8358 7.83579 16.5 8.25 16.5H12.75C13.1642 16.5 13.5 16.8358 13.5 17.25C13.5 17.6642 13.1642 18 12.75 18H8.25C7.83579 18 7.5 17.6642 7.5 17.25Z"
      fill={iconColor}
    />
  </SvgIcon>
);

export default CopyFileIcon;
