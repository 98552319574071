import React from 'react';

import { ShowOnlyCheckbox } from '~/components/CheckboxFormik';
import Grid from '~/components/core/Atomic/Grid/Grid';
import InnerCard from '~/components/core/Cards/InnerCard';
import CollapsibleWrapper from '~/components/core/Collapsible/CollapsibleWrapper';
import { ShowOnlySwitch } from '~/components/core/Formik/SwitchFormik';
import {
  CONTACT_API_SUFFIX,
  CONTACT_CONFIG_SUFFIX,
} from '~/components/SystemConfiguration/FnolConfiguration/constants';
import HiddenIndication from '~/components/SystemConfiguration/FnolConfiguration/FnolConfigurationScreen/HiddenIndication/HiddenIndication';

import SystemFieldType from './SystemFieldType';
import type { SystemField } from './types';

const getDisplayKey = (displayKey: string) => {
  return displayKey.endsWith(CONTACT_CONFIG_SUFFIX)
    ? displayKey.replace(CONTACT_CONFIG_SUFFIX, CONTACT_API_SUFFIX)
    : displayKey;
};

interface SystemFieldConfigurationCardProps {
  field: SystemField;
}

const SystemFieldConfigurationCard: React.FC<SystemFieldConfigurationCardProps> = ({ field }) => {
  return (
    <Grid item xs={12}>
      <CollapsibleWrapper
        title={field.desc}
        subtitle={`Key: ${getDisplayKey(field.id)}`}
        actionCard
        noBorderLine
        noDivider
        actions={
          <div>
            <HiddenIndication isActive={field.active} />
          </div>
        }
        withActionsContainerFullWidth={false}
        defaultState={false}
      >
        <InnerCard>
          <Grid container className="flex items-center" spacing={3}>
            <Grid item xs={6}>
              <SystemFieldType field={field} />
            </Grid>
            <Grid item xs={2} className="MuiGrid-item !pl-0">
              <ShowOnlySwitch showOnlyValueComponent={field.active} label="Show" />
            </Grid>
            <Grid item xs={2}>
              <ShowOnlyCheckbox showOnlyValueComponent={field.mandatory} label="Required" />
            </Grid>
            <Grid item xs={2} />
          </Grid>
        </InnerCard>
      </CollapsibleWrapper>
    </Grid>
  );
};

export default SystemFieldConfigurationCard;
