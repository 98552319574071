import React from 'react';
import propTypes from 'prop-types';
import { useFormikContext } from 'formik';

import CheckboxFormik from '../../../../CheckboxFormik';

export const SystemConfigCheckbox = ({ disabled }) => {
  const { values } = useFormikContext();
  return (
    <CheckboxFormik
      id="is_sys_config_editor"
      label="Is System Configuration Editor"
      disabled={values.is_part_of_sub_organization || disabled}
      fullWidth
    />
  );
};

SystemConfigCheckbox.propTypes = {
  disabled: propTypes.bool,
};

export const SuperUserCheckbox = () => <CheckboxFormik id="is_super_user" label="Is Super User" fullWidth />;
