import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '../../../../../core';

export const ProfileNameCell = ({ profileName }) => {
  return (
    <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.MEDIUM}>
      {profileName}
    </Text>
  );
};

ProfileNameCell.propTypes = {
  profileName: PropTypes.string,
};
