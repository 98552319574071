import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import Button from '~/components/core/Atomic/Buttons/Button';
import Typography from '~/components/core/Atomic/Typography';
import CancelButton from '~/components/core/Buttons/CancelButton';

import CardDialog from '../../../../CardDialog';
import { FIELD_IDS } from '../PhoneConfigurationTab';
import VoiceMessageConfigurationDialog from '../VoiceMessageConfigurationDialog/VoiceMessageConfigurationDialog';
import VoiceMessageDetailsPreview from '../VoiceMessageDetailsPreview/VoiceMessageDetailsPreview';

const FORM_IDS = {
  OUTBOUND_TEXT_OR_RECORDED_MESSAGE: 'outbound_text_or_recorded_message',
  OUTBOUND_GREETING_TEXT: 'outbound_greeting_text',
  OUTBOUND_VOICE_FILE: 'outbound_voice_file',
};

const EditOutboundGreetingConfigDialog = ({ configuration, onCancel, onSubmit }) => {
  const classes = useStyles();
  const [allowSavingWithoutNewFile, setAllowSavingWithoutNewFile] = React.useState(
    !!configuration?.permanent_audio_file_url
  );

  const handleSubmit = async (values, formikProps) => {
    try {
      const formData = new FormData();
      formData.append('configuration_type', values[FORM_IDS.OUTBOUND_TEXT_OR_RECORDED_MESSAGE]);
      formData.append('file', values[FORM_IDS.OUTBOUND_VOICE_FILE]);
      formData.append('text_message', values[FORM_IDS.OUTBOUND_GREETING_TEXT]);
      await onSubmit(formData);
      onCancel();
    } catch {
      formikProps.setSubmitting(false);
    }
  };
  return (
    <Formik
      initialValues={
        isEmpty(configuration)
          ? {
              [FORM_IDS.OUTBOUND_TEXT_OR_RECORDED_MESSAGE]: 'text_message',
              [FORM_IDS.OUTBOUND_GREETING_TEXT]: '',
              [FORM_IDS.OUTBOUND_VOICE_FILE]: undefined,
            }
          : {
              [FORM_IDS.OUTBOUND_TEXT_OR_RECORDED_MESSAGE]: configuration.configuration_type,
              [FORM_IDS.OUTBOUND_GREETING_TEXT]: configuration.message_text || '',
              [FORM_IDS.OUTBOUND_VOICE_FILE]: undefined,
            }
      }
      validationSchema={Yup.object().shape({
        [FORM_IDS.OUTBOUND_TEXT_OR_RECORDED_MESSAGE]: Yup.string()
          .oneOf(['text_message', 'uploaded_text_message'])
          .required(),
        [FORM_IDS.OUTBOUND_GREETING_TEXT]: Yup.string().when([FORM_IDS.OUTBOUND_TEXT_OR_RECORDED_MESSAGE], {
          is: 'text_message',
          then: Yup.string().required('Please enter a message'),
        }),
        [FORM_IDS.OUTBOUND_VOICE_FILE]: Yup.mixed().when([FORM_IDS.OUTBOUND_TEXT_OR_RECORDED_MESSAGE], {
          is: 'uploaded_text_message',
          then: allowSavingWithoutNewFile ? Yup.mixed() : Yup.mixed().required('Please select a file'),
        }),
      })}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, handleSubmit }) => {
        return (
          <CardDialog
            title="Configure outbound phone greeting"
            isDialog
            maxWidth="sm"
            fullWidth
            onClose={onCancel}
            preventClose={isSubmitting}
          >
            <VoiceMessageConfigurationDialog
              voiceMessageConfiguration={configuration}
              textAreaLabel="Outbound call greeting"
              radioButtonGroupId={FORM_IDS.OUTBOUND_TEXT_OR_RECORDED_MESSAGE}
              textAreaId={FORM_IDS.OUTBOUND_GREETING_TEXT}
              voiceFileInputId={FORM_IDS.OUTBOUND_VOICE_FILE}
              onReplaceExistingRecording={() => {
                setAllowSavingWithoutNewFile(false);
              }}
            />
            <div className={classes.buttonsContainer}>
              <CancelButton disabled={isSubmitting} onClick={onCancel} />
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

EditOutboundGreetingConfigDialog.propTypes = {
  configuration: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

const OutboundGreetingConfigView = ({ twilioConfiguration }) => {
  return (
    <>
      <Typography variant="subtitle1">Configure outbound phone greeting</Typography>
      <Typography variant="caption">
        Upload a voice message or add a text message for the outbound phone greeting that the customer hears when
        receiving a call.
      </Typography>
      {twilioConfiguration[FIELD_IDS.OUTBOUND_CALL_MESSAGE_ENABLED] &&
      !isEmpty(twilioConfiguration[FIELD_IDS.OUTBOUND_CALL_MESSAGE_CONFIGURATION]) ? (
        <div className="mt-20">
          <VoiceMessageDetailsPreview
            configurationType={twilioConfiguration[FIELD_IDS.OUTBOUND_CALL_MESSAGE_CONFIGURATION].configuration_type}
            messageTextTitle="Voicemail by text message"
            messageText={twilioConfiguration[FIELD_IDS.OUTBOUND_CALL_MESSAGE_CONFIGURATION].message_text}
            uploadedTextMessageTitle="Voicemail by recording"
            audioUrl={twilioConfiguration[FIELD_IDS.OUTBOUND_CALL_MESSAGE_CONFIGURATION].permanent_audio_file_url}
          />
        </div>
      ) : null}
    </>
  );
};

OutboundGreetingConfigView.propTypes = {
  twilioConfiguration: PropTypes.object.isRequired,
};

export { EditOutboundGreetingConfigDialog, OutboundGreetingConfigView };
