import React, { useState } from 'react';
import { Switch } from '@material-ui/core';
import axios from 'axios';
import { isEmpty } from 'lodash';

import { useStyles } from '~/assets/styles';
import AlertBanner from '~/components/core/AlertBanner';
import { reportAxiosError } from '~/Utils';

import CardDialog from '../../../../CardDialog';
import { LoadingSwitch, Text } from '../../../../core';
import { PencilIcon } from '../../../../icons';
import InlineIconButton from '../../../../InlineIconButton';
import useOrganization from '../../../../OrganizationContext';
import OperationsBreadcrumbs from '../../../OperationsBreadcrumbs';
import ConfigurationTable from '../../ConfigurationTable';
import { getIsNotActiveOrInProgress } from '../../utils';
import { useCCC } from '../hooks/useCCC';

import CccConfigurationDialog from './ConfigurationDialog';

const ALERT_NOTE = 'To activate CCC integration first use the edit button to add the claim office branch code';

const SCOPE = {
  ALL_SUB_ORGANIZATIONS: 'all',
  PER_SUB_ORGANIZATIONS: 'per_sub_organization',
};

const ExternalAdminCcc = () => {
  const classes = useStyles();
  const { isLoading, isError, reloadData: reloadConfigurations, cccOrgConfig } = useCCC();
  const [selectedConfiguration, setSelectedConfiguration] = useState(null);
  const [isUpdatingEnabled, setIsUpdatingEnabled] = useState(false);

  const { organizationId } = useOrganization();
  const handleUpsertCccConfiguration = async (values, configuration_id, sub_organization_id) => {
    try {
      if (isEmpty(values)) {
        return;
      }

      setIsUpdatingEnabled(true);
      const isUpdating = configuration_id && configuration_id !== 'default';
      await axios({
        url: `/api/v1/ccc_integration_configuration/${organizationId}/ccc_configuration${
          isUpdating ? '/' + configuration_id : ''
        }`,
        method: isUpdating ? 'put' : 'post',
        data: values,
        params: { sub_organization_id },
      });
      await reloadConfigurations();
      setIsUpdatingEnabled(false);
      setSelectedConfiguration(null);
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  let scope;

  if (cccOrgConfig?.is_org_level_configuration) {
    scope = SCOPE.ALL_SUB_ORGANIZATIONS;
  } else if (cccOrgConfig?.is_org_level_configuration === false) {
    scope = SCOPE.PER_SUB_ORGANIZATIONS;
  }
  const additionalColumnsArray = [
    {
      id: 'claim_office_branch_code',
      numeric: false,
      label: 'Claim Office Branch Code',
    },
    {
      id: 'note',
      numeric: false,
      label: 'Note',
    },
    {
      id: 'is_enabled',
      numeric: false,
      label: 'Enabled',
      specialCell: (config) => (
        <Switch
          checked={!!config?.is_enabled}
          className={classes.formsSwitch}
          size="small"
          onChange={async () =>
            await handleUpsertCccConfiguration(
              { is_enabled: !config?.is_enabled },
              config.ccc_configuration_id,
              config.sub_organization_id
            )
          }
          disabled={!config.status || getIsNotActiveOrInProgress(config.status) || isUpdatingEnabled}
        />
      ),
    },
    {
      id: 'actions',
      numeric: false,
      label: 'Actions',
      disableSort: true,
      align: 'right',
      specialCell: (config) => (
        <InlineIconButton
          tooltipTitle="Edit"
          icon={PencilIcon}
          className={`${classes.textIcon} ${classes.marginedIcon} ${classes.hoverableNonFilledIcon}`}
          onClick={() => setSelectedConfiguration(config)}
          wrapWithSpan
        />
      ),
    },
  ];

  return (
    <LoadingSwitch isError={isError} isLoading={isLoading}>
      <OperationsBreadcrumbs currentTab="ccc" />
      <CardDialog title="CCC">
        <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
          Enable integration with CCC.
        </Text>
        <div className="mb-20 mt-20">
          <AlertBanner alertType={AlertBanner.ALERT_TYPES.WARNING} note={ALERT_NOTE} withIcon />
        </div>
        {!isLoading && (
          <ConfigurationTable
            configurations={cccOrgConfig?.configurations}
            additionalColumnsArray={additionalColumnsArray}
            forceOrganizationLevel={scope === SCOPE.ALL_SUB_ORGANIZATIONS}
          />
        )}
        {selectedConfiguration && (
          <CccConfigurationDialog
            activeConfiguration={selectedConfiguration}
            handleSubmit={async (values) =>
              await handleUpsertCccConfiguration(
                values,
                selectedConfiguration?.ccc_configuration_id,
                selectedConfiguration?.sub_organization_id
              )
            }
            handleClose={() => setSelectedConfiguration(null)}
          />
        )}
      </CardDialog>
    </LoadingSwitch>
  );
};

export default ExternalAdminCcc;
