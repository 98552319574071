import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useStyles } from '~/assets/styles';
import CardDialog from '~/components/CardDialog';
import { MultiSelectFilter } from '~/components/core';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import styles from '~/components/SystemConfiguration/Tabs/PaymentsConfiguration/PaymentsConfigurationForm.module.scss';
import { isUserAdjuster } from '~/UserUtils';

import { getUserIdsWithImplicitApprovalPermission, isUserWithImplicitApprovalPermission } from '../utils';

const SelectManagersConfigurationDialog = ({
  configurationKey,
  cardTitle,
  inputLabel,
  paymentsConfiguration,
  onCancel,
  onSubmit,
  preselectManagers = false,
  disabled = false,
}) => {
  const { users } = useSysconfig();
  const classes = useStyles();
  const selectedUsers = paymentsConfiguration?.[configurationKey] || [];

  const [selectedUserIds, setSelectedUserIds] = useState(
    selectedUsers.concat(preselectManagers ? getUserIdsWithImplicitApprovalPermission(users) : [])
  );

  const usersToApproveIds = users
    .filter((user) => isUserAdjuster(user) || isUserWithImplicitApprovalPermission(user))
    .map((user) => user.id);
  const usersDict = _.keyBy(users, 'id');

  const handleSubmit = () => {
    onSubmit({ [configurationKey]: selectedUserIds });
  };

  return (
    <CardDialog title={cardTitle} isDialog maxWidth="md" fullWidth onClose={onCancel} preventClose={disabled}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <MultiSelectFilter
            onChange={setSelectedUserIds}
            className={styles.filterContainer}
            label={inputLabel}
            options={usersToApproveIds}
            renderValue={() => ''}
            renderOption={(id) => usersDict[id]?.username}
            value={selectedUserIds}
            withOptionChips
            renderOptionDisabled={(id) =>
              preselectManagers ? isUserWithImplicitApprovalPermission(usersDict[id]) : false
            }
          />
        </Grid>
      </Grid>
      <div className={classes.buttonsContainer}>
        <CancelButton disabled={disabled} onClick={onCancel} />
        <Button variant="contained" color="primary" disabled={disabled} onClick={handleSubmit}>
          Save
        </Button>
      </div>
    </CardDialog>
  );
};

SelectManagersConfigurationDialog.propTypes = {
  paymentsConfiguration: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  configurationKey: PropTypes.string.isRequired,
  cardTitle: PropTypes.string.isRequired,
  inputLabel: PropTypes.string.isRequired,
  preselectManagers: PropTypes.bool,
};

export default SelectManagersConfigurationDialog;
