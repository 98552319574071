export const CONTACT_CONFIG_SUFFIX = 'contact_id';
export const CONTACT_API_SUFFIX = 'contact_ref_id';
export const CONFIGURED_FIELDS_PATHS = ['configuration.incident_details.configured_fields'];
export const MAX_CONTACT_FIELDS = 4;
export const MAX_LOCATION_FIELDS = 4;
export const CONTACT_DB_VALID_VALUES = [
  'configurable_contact_1',
  'configurable_contact_2',
  'configurable_contact_3',
  'configurable_contact_4',
];

export const LOCATION_DB_VALID_VALUES = [
  'configurable_location_1',
  'configurable_location_2',
  'configurable_location_3',
  'configurable_location_4',
];

export const DB_FIELDS_VALID_VALUES = {
  contact: CONTACT_DB_VALID_VALUES,
  location: LOCATION_DB_VALID_VALUES,
};

export const BOOL4_TYPES = ['yes_no', 'yes_no_na', 'yes_no_unknown', 'yes_no_na_unknown'];
export const SELECT_TYPES = ['select', 'multiselect'];
