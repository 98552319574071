import React from 'react';

import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import { getLobDescription, getLobIcon } from '~/Utils/lobUtils';

const LobCell: React.FC<{ lob: string }> = ({ lob }) => {
  const { lobConfigurationsDict } = useLobConfiguration();

  return (
    <div className="inline-flex align-middle">
      <span className="mr-12">{getLobIcon({ lob, lobConfigurationsDict })}</span>
      <span>{getLobDescription(lob, lobConfigurationsDict)}</span>
    </div>
  );
};

export default LobCell;
