import React from 'react';
import axios from 'axios';

import { useOrganizationUnits } from '~/components/SystemConfiguration/Users/hooks/useOrganizationUnits';
import UsersManagementTab from '~/components/SystemConfiguration/Users/UsersManagementTab';
import { prepareValuesForSave } from '~/components/SystemConfiguration/Users/Utils';

import { isUserSuperUser } from '../../UserUtils';
import { reportAxiosError } from '../../Utils';
import { useCms } from '../hooks/useCms';

import OrganizationRoles from './OrganizationRoles';
import SubOrganizations from './SubOrganizations';
import { useSysconfig } from './SystemConfigurationScreen';

import { useStyles } from '../../assets/styles';

function ITConfigurationTab() {
  const { organization, reloadUsers, roles, reloadRoles } = useSysconfig();
  const { reloadUnits } = useOrganizationUnits({
    organization,
  });
  const classes = useStyles();
  const { user } = useCms();

  const usersRoute = `/sysconfig/api/v1/organizations/${organization.id}/users`;
  const rolesRoute = `/sysconfig/api/v1/organizations/${organization.id}/roles`;

  const opTypeConfiguration = {
    updateUser: { axiosCommand: axios.put, url: (userId) => `${usersRoute}/${userId}` },
    deleteUser: { axiosCommand: axios.delete, url: (userId) => `${usersRoute}/${userId}` },
    resetPassword: { axiosCommand: axios.post, url: (userId) => `${usersRoute}/${userId}/password` },
    unlockUser: { axiosCommand: axios.post, url: (userId) => `${usersRoute}/${userId}/unlock_user` },
    lockUser: { axiosCommand: axios.post, url: (userId) => `${usersRoute}/${userId}/lock_user` },
    addUser: { axiosCommand: axios.post, url: () => usersRoute },
    updateRole: { axiosCommand: axios.put, url: (roleId) => `${rolesRoute}/${roleId}` },
    deleteRole: { axiosCommand: axios.delete, url: (roleId) => `${rolesRoute}/${roleId}` },
    addRole: { axiosCommand: axios.post, url: () => `${rolesRoute}` },
  };

  const handleITOperation = async (opType, userId, values) => {
    const command = opTypeConfiguration[opType];
    const data = prepareValuesForSave({ values });
    try {
      await command['axiosCommand'](command['url'](userId), data);
      await reloadUsers();
      await reloadRoles();
      await reloadUnits();
    } catch (error) {
      reportAxiosError(error);
    }
  };

  return (
    <>
      <div className="flex w-full flex-col gap-20">
        <div className="m-12 w-full">
          <UsersManagementTab />
        </div>
        {(isUserSuperUser(user) || organization.sub_organizations_enabled) && (
          <div className="m-12 w-full">
            <SubOrganizations />
          </div>
        )}
        {isUserSuperUser(user) && (
          <div className="m-12 w-full">
            <OrganizationRoles
              classes={classes}
              roles={roles}
              onUpdateRole={(roleId, values) => handleITOperation('updateRole', roleId, values)}
              onDeleteRole={(roleId, values) => handleITOperation('deleteRole', roleId, values)}
              onAddRole={(values) => handleITOperation('addRole', undefined, values)}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default ITConfigurationTab;
