import React from 'react';
import axios from 'axios';
import * as Yup from 'yup';

import { capitalize, reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import { SortableTable } from '../core';
import { useCurrencyFormatter } from '../CurrencyFormatterContext';
import HoverChangeField from '../HoverChangeField';

import { useSysconfig } from './SystemConfigurationScreen';

import { useStyles } from '../../assets/styles';

function OrganizationDefaultReserves() {
  const { organization, reloadOrganization } = useSysconfig();
  const classes = useStyles();
  const { currencyFormatter } = useCurrencyFormatter();

  async function handleUpdateDefaultReserve(claimType, coverageType, indemnity, expense) {
    try {
      await axios.put(`/sysconfig/api/v1/organizations/${organization.id}/reserve_defaults`, {
        claim_type: claimType,
        coverage_type: coverageType,
        indemnity,
        expense,
      });
      await reloadOrganization();
    } catch (e) {
      reportAxiosError(e);
      throw e;
    }
  }

  const columnData = [
    {
      id: 'coverage_type',
      label: 'Coverage',
      specialCell: (entry) =>
        entry.coverage_type
          .split('_')
          .map((s) => capitalize(s))
          .join(' '),
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'indemnity',
      label: 'Default Indemnity Reserve',
      disableSort: true,
      specialCell: (entry) => (
        <HoverChangeField
          name="indemnity"
          value={entry.indemnity}
          validationSchema={Yup.number().required()}
          specialFieldType="monetary"
          label="Default Indemnity Reserve"
          onUpdate={({ indemnity }) =>
            handleUpdateDefaultReserve(entry.claim_type, entry.coverage_type, indemnity, entry.expense)
          }
          width="250px"
        >
          {currencyFormatter.format(entry.indemnity)}
        </HoverChangeField>
      ),
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'expense',
      label: 'Default Expense Reserve',
      disableSort: true,
      specialCell: (entry) => (
        <HoverChangeField
          name="expense"
          value={entry.expense}
          validationSchema={Yup.number().required()}
          specialFieldType="monetary"
          label="Default Expense Reserve"
          onUpdate={({ expense }) =>
            handleUpdateDefaultReserve(entry.claim_type, entry.coverage_type, entry.indemnity, expense)
          }
          width="250px"
        >
          {currencyFormatter.format(entry.expense)}
        </HoverChangeField>
      ),
    },
  ];

  const defaultReservesConfigToList = (d, claimType) =>
    Object.keys(d).map((covType) => ({
      id: `${claimType}_${covType}`,
      claim_type: claimType,
      coverage_type: covType,
      indemnity: d[covType][0],
      expense: d[covType][1],
    }));

  if (!organization.configuration || !organization.configuration.reserve_defaults) {
    return null;
  }

  return (
    <CardDialog title="Organization Default Reserves">
      {organization.configuration.reserve_defaults.home_claim && (
        <div className={classes.cardDivRow}>
          <CardDialog title="Home">
            <SortableTable
              rows={defaultReservesConfigToList(organization.configuration.reserve_defaults.home_claim, 'home_claim')}
              columns={columnData}
            />
          </CardDialog>
        </div>
      )}
      {organization.configuration.reserve_defaults.auto_claim && (
        <div className={classes.cardDivRow}>
          <CardDialog title="Auto">
            <SortableTable
              rows={defaultReservesConfigToList(organization.configuration.reserve_defaults.auto_claim, 'auto_claim')}
              columns={columnData}
            />
          </CardDialog>
        </div>
      )}
    </CardDialog>
  );
}

export default OrganizationDefaultReserves;
