import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Switch } from '@material-ui/core';
import axios from 'axios';
import { isEmpty } from 'lodash';

import { useStyles } from '~/assets/styles';
import AlertBanner from '~/components/core/AlertBanner';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import cn from '~/Utils/cn';

import { reportAxiosError } from '../../../../../Utils';
import CardDialog from '../../../../CardDialog';
import { LoadingSwitch } from '../../../../core';
import { useSysconfig } from '../../../SystemConfigurationScreen';
import ConfigurationTable from '../../ConfigurationTable';
import { getIsActiveOrPending } from '../../utils';
import { useClaimSearch } from '../hooks/useClaimSearch';

import ClaimSearchSetupDialog from './SetupDialog';

import styles from './claimSearchInternalAdmin.module.scss';

const InternalAdminClaimSearchWrapper = () => {
  const { organization } = useSysconfig();
  const classes = useStyles();
  const [setupFormOpen, setSetupFormOpen] = useState(false);
  const [isUpdatingReportingStatus, setIsUpdatingReportingStatus] = useState(false);
  const {
    isLoading,
    isError,
    isEnabled,
    reloadData: reloadConfigurations,
    claimSearchOrgConfig,
    isConfigurationExists,
  } = useClaimSearch();

  const shouldDisableStartReporting = ({ status }) => !getIsActiveOrPending(status) || isUpdatingReportingStatus;

  const handleSetupSubmit = async (values) => {
    try {
      await axios.put(`/api/v1/claim_search/configuration/${organization.id}/integration`, values);
      await reloadConfigurations();
      handleCloseSetupForm();
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
  };

  const handleSetupFormClick = () => {
    setSetupFormOpen(true);
  };

  const handleCloseSetupForm = () => {
    setSetupFormOpen(false);
  };

  const handleReportingUpdate = async ({ configuration_id }, status) => {
    try {
      setIsUpdatingReportingStatus(true);
      await axios.put(`/api/v1/claim_search/configuration/${organization.id}/report/${configuration_id}`, null, {
        params: { is_reporting: status },
      });
      await reloadConfigurations();
      setIsUpdatingReportingStatus(false);
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const additionalColumnsArray = [
    {
      id: 'is_sending_active',
      numeric: false,
      label: 'Start reporting to ClaimSearch',
      align: 'right',
      disableSort: true,
      specialCell: (config) => (
        <Switch
          checked={!!config?.is_reporting}
          className={classes.formsSwitch}
          size="small"
          onChange={() => handleReportingUpdate(config, !config.is_reporting)}
          disabled={shouldDisableStartReporting(config)}
        />
      ),
    },
  ];

  return (
    <LoadingSwitch isLoading={isLoading} isError={isError}>
      <CardDialog noCardTitle containerClassName={styles.claimSearchInternalAdmin}>
        <EnableClaimSearchButton onSetupFormClick={handleSetupFormClick} isEnabled={isConfigurationExists} />
        {isEnabled &&
          !isEmpty(claimSearchOrgConfig) &&
          (isEmpty(claimSearchOrgConfig.agency_id) ||
            isEmpty(claimSearchOrgConfig.customer_login_id) ||
            isEmpty(claimSearchOrgConfig.customer_password) ||
            isEmpty(claimSearchOrgConfig.response_url) ||
            isEmpty(claimSearchOrgConfig.request_url)) && (
            <div className={styles.warningContainer}>
              <Grid item xs={4}>
                <AlertBanner
                  alertType={AlertBanner.ALERT_TYPES.WARNING}
                  note="To start reporting, make sure all setup data has been updated"
                  withIcon
                />
              </Grid>
            </div>
          )}
        <ConfigurationTable
          configurations={claimSearchOrgConfig?.configurations}
          additionalColumnsArray={additionalColumnsArray}
        />
      </CardDialog>
      {setupFormOpen && (
        <ClaimSearchSetupDialog
          data={claimSearchOrgConfig}
          handleSubmit={handleSetupSubmit}
          onClose={handleCloseSetupForm}
        />
      )}
    </LoadingSwitch>
  );
};

const EnableClaimSearchButton = ({ onSetupFormClick, isEnabled }) => {
  const classes = useStyles();

  return isEnabled ? (
    <Button variant="contained" className={cn(classes.cancelButton, styles.button)} onClick={onSetupFormClick}>
      Update Setup Data
    </Button>
  ) : (
    <Button color="primary" variant="contained" className={styles.button} onClick={onSetupFormClick}>
      Enable ClaimSearch Integration
    </Button>
  );
};

EnableClaimSearchButton.propTypes = {
  onSetupFormClick: PropTypes.func.isRequired,
  isEnabled: PropTypes.bool,
};

export default InternalAdminClaimSearchWrapper;
