import React from 'react';

import InnerTabs from '../../core/Layout/InnerTabs/InnerTabs';
import { useSysconfig } from '../../SystemConfiguration/SystemConfigurationScreen';
import { isOrgUnitsConfigurationFeatureEnabled } from '../Users/OrganizationUnits/Utils';

import { OrganizationUnitsTab } from './OrganizationUnits/OrganizationUnitsTab';
import UsersTab from './UsersTab';

const UsersManagementTab = () => {
  const { organization: SysconfigOrganization } = useSysconfig();
  const isFeatureEnabled = isOrgUnitsConfigurationFeatureEnabled({ organization: SysconfigOrganization });

  const tabs = [
    {
      key: 'users',
      label: 'Users',
      url: 'users',
      component: UsersTab,
    },
    {
      key: 'units',
      label: 'Units',
      url: 'units',
      component: () => OrganizationUnitsTab({ className: 'h-[calc(100vh-175px)]' }),
      disableTab: !isFeatureEnabled,
    },
  ];

  return (
    <>
      <InnerTabs tabs={tabs} renderContainerClassName="bg-white" />
    </>
  );
};

export default UsersManagementTab;
