import React from 'react';
import { Link, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ADMIN_ROUTES } from '../../routesConstants';

import OrganizationLobs from './InnerConfigurations/OrganizationLobs';
import InsuredProperties from './SpecialAssociations/InsuredProperties';
import OrganizationEmails from './SpecialAssociations/OrganizationEmails';
import OrganizationSpecialContacts from './SpecialAssociations/OrganizationSpecialContacts';
import OrganizationSpecialUsers from './SpecialAssociations/OrganizationSpecialUsers';
import OrganizationSpecialUsersList from './SpecialAssociations/OrganizationSpecialUsersList';
import { OrganizationAdjustersAdmin } from './Tabs/AdjusterManagement/';
import SupportedLocationsInternalAdmin from './Tabs/SupportedLocations/InternalAdmin';
import CatastropheEvents from './CatastropheEvents';

const links = [
  { label: 'Adjusters', link: ADMIN_ROUTES.ORGANIZATION.ADJUSTERS, component: OrganizationAdjustersAdmin },
  { label: 'Insured Properties', link: ADMIN_ROUTES.ORGANIZATION.INSURED_PROPERTIES, component: InsuredProperties },
  { label: 'Organization Emails', link: ADMIN_ROUTES.ORGANIZATION.ORGANIZATION_EMAILS, component: OrganizationEmails },
  { label: 'Special Users', link: ADMIN_ROUTES.ORGANIZATION.SPECIAL_USERS, component: OrganizationSpecialUsers },
  {
    label: 'Special Contacts',
    link: ADMIN_ROUTES.ORGANIZATION.SPECIAL_CONTACTS,
    component: OrganizationSpecialContacts,
  },
  {
    label: 'Special User Lists',
    link: ADMIN_ROUTES.ORGANIZATION.SPECIAL_USER_LISTS,
    component: OrganizationSpecialUsersList,
  },
  {
    label: 'Organization Countries',
    link: ADMIN_ROUTES.ORGANIZATION.ORGANIZATION_COUNTRIES,
    component: SupportedLocationsInternalAdmin,
  },
  {
    label: 'Organization Line of Business',
    link: ADMIN_ROUTES.ORGANIZATION.ORGANIZATION_LINES_OF_BUSINESSES,
    component: OrganizationLobs,
  },
  { label: 'Catastrophic Events', link: ADMIN_ROUTES.ORGANIZATION.CATASTROPHIC_EVENTS, component: CatastropheEvents },
];

const OrganizationTab = () => {
  const match = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route strict exact path={match.path} component={() => <OrganizationSpecialAssociationLinks links={links} />} />
        {links.map((item) => (
          <Route key={item.link} path={`${match.path}/${item.link}`} component={item.component} />
        ))}
        <Redirect to={match.path} />
      </Switch>
    </div>
  );
};

const OrganizationSpecialAssociationLinks = ({ links }) => {
  const match = useRouteMatch();

  return (
    <ul>
      {links.map((item) => (
        <li key={item.link}>
          <Link to={`${match.url}/${item.link}`}>{item.label}</Link>
        </li>
      ))}
    </ul>
  );
};

OrganizationSpecialAssociationLinks.propTypes = {
  links: PropTypes.array.isRequired,
};

export default OrganizationTab;
