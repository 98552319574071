import React from 'react';
import { getIn, useFormikContext } from 'formik';

import { Text } from '../../../../../../../core';
import { CheckIcon, DashIcon } from '../../../../../../../icons';
import { getVendorPermissionFieldId } from '../Utils';

import { permissionSetPropType } from './propTypes';

import colors from '../../../../../../../../assets/colors.module.scss';
import styles from './styles.module.scss';

const PermissionSetCollapsibleTileSummary = ({ permissionSet }) => {
  const { values } = useFormikContext();

  const permissionsEnabledNum = permissionSet.permissions.filter(
    (permission) => getIn(values, getVendorPermissionFieldId(permission)) === true
  ).length;
  const permissionsNum = permissionSet.permissions.length;
  const checked = permissionsEnabledNum > 0;

  return (
    <div className={styles.permissionsSummaryWrapper}>
      <div className={styles.iconContainer}>
        {checked ? <CheckIcon iconColor={colors.buttonSelected} /> : <DashIcon iconColor={colors.textDisabled} />}
      </div>
      <Text
        className={styles.label}
        variant={Text.VARIANTS.SM}
        weight={checked ? Text.WEIGHTS.SEMI_BOLD : Text.WEIGHTS.REGULAR}
        colorVariant={checked ? Text.COLOR_VARIANTS.PRIMARY : Text.COLOR_VARIANTS.DISABLED}
      >
        {checked ? `${permissionsEnabledNum} / ${permissionsNum}` : 'No permissions'}
      </Text>
    </div>
  );
};

PermissionSetCollapsibleTileSummary.propTypes = {
  permissionSet: permissionSetPropType.isRequired,
};

export default PermissionSetCollapsibleTileSummary;
