import React from 'react';

import type { ConfigurationField } from '~/components/SystemConfiguration/ConfigurationPage/types';
import BaseConfigurationSection from '~/components/SystemConfiguration/FnolConfiguration/FnolConfigurationScreen/Sections/BaseConfigurationSection';

import SystemFieldConfigurationCard from '../../SystemFieldConfigurationCard/SystemFieldConfigurationCard';

interface SystemFieldsSectionProps {
  fields: ConfigurationField[];
  subtitle: string;
}

const SystemFieldsSection: React.FC<SystemFieldsSectionProps> = ({ fields, subtitle }) => {
  return (
    <BaseConfigurationSection
      title={`System Fields (${fields.length})`}
      subtitle={subtitle}
      withActionsContainerFullWidth={false}
    >
      {fields.map((field) => (
        <SystemFieldConfigurationCard field={field} key={field.id} />
      ))}
    </BaseConfigurationSection>
  );
};

export default SystemFieldsSection;
