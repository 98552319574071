export const copies = {
  WELCOME_STEP_TITLE: 'Welcome to the New Line of Business Wizard',
  WELCOME_STEP_CONTENT: `This wizard allows you to set up a new line of business for the organization.
    If you are planning to create a new sub organization to support the new line of business, please create the sub organization before starting this wizard.`,
  WELCOME_CANCEL: 'CANCEL',
  WELCOME_START: "LET'S START",
  END_SCREEN_BUTTON: 'DONE',
  CONFIRM_STEP_TITLE: 'Confirm',
  CONFIRM_STEP_CONTENT:
    'Verify your settings before proceeding. After adding a line of business, you cannot edit its settings.',
  BACK: 'PREVIOUS STEP',
  NEXT: 'NEXT STEP',
  CREATE_LOB: 'ADD LINE OF BUSINESS',
};
