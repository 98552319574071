const TWILIO_CONFIGURATION = {
  QUEUE_ROUTING: {
    POSSIBLE_TIME_VALUES: {
      '30': {
        desc: '30 seconds',
        value: 30,
      },
      '45': {
        desc: '45 seconds',
        value: 45,
      },
      '60': {
        desc: '1 minute',
        value: 60,
      },
      '90': {
        desc: '1:30 minutes',
        value: 90,
      },
      '120': {
        desc: '2 minutes',
        value: 120,
      },
      '180': {
        desc: '3 minutes',
        value: 180,
      },
      '240': {
        desc: '4 minutes',
        value: 240,
      },
    },
  },
  WRAP_UP_TIME: {
    POSSIBLE_TIME_VALUES: {
      '15': {
        desc: '15 seconds',
        value: 15,
      },
      '30': {
        desc: '30 seconds',
        value: 30,
      },
      '45': {
        desc: '45 seconds',
        value: 45,
      },
      '60': {
        desc: '1 minute',
        value: 60,
      },
      '75': {
        desc: '1:15 minute',
        value: 75,
      },
      '90': {
        desc: '1:30 minutes',
        value: 90,
      },
    },
  },
} as const;

export default TWILIO_CONFIGURATION;
