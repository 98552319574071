import React from 'react';

import { BODILY_INJURIES_FIELD_IDS, INVOLVED_PASSENGER_FIELD_IDS, INVOLVED_PERSON_FIELD_IDS } from '../constants';

import InvolvedEntitySection from './InvolvedEntitySection';

const InvolvedPassengerSection = (): JSX.Element => (
  <InvolvedEntitySection
    entityId="involved_passenger"
    entityDesc="Involved Passenger"
    baseInvolvedFields={INVOLVED_PERSON_FIELD_IDS}
    involvedFields={INVOLVED_PASSENGER_FIELD_IDS}
    itemsIdPrefix="bodily_injuries"
    itemsFieldIds={BODILY_INJURIES_FIELD_IDS}
    withIcdCodes
  />
);

export default InvolvedPassengerSection;
