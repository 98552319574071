import { mapValues } from 'lodash';
import * as Yup from 'yup';

import { CUSTOM_QUESTIONS_KEY, FORM_NAME_KEY, QUESTIONS_KEY, TOTAL_ALLOWED_QUESTIONS } from './constants';

export const getSchema = ({ formNames }) =>
  Yup.object().shape({
    [FORM_NAME_KEY]: Yup.string()
      .required('Required')
      .min(1, 'The form name cannot be empty')
      .notOneOf(formNames, 'The Form name should be unique'),
    [CUSTOM_QUESTIONS_KEY]: Yup.lazy((obj) =>
      Yup.object(
        mapValues(obj, () =>
          Yup.object({
            text: Yup.string().required('Required'),
            category: Yup.string().required('Required'),
          })
        )
      )
    ),
    [QUESTIONS_KEY]: Yup.lazy((obj) =>
      Yup.object(
        mapValues(obj, () =>
          Yup.object({
            selected: Yup.boolean(),
            is_sla: Yup.boolean(),
            sla_type: Yup.string().when(['selected', 'is_sla'], {
              is: true,
              then: (schema) => schema.required('Required'),
            }),
            sla_value: Yup.number()
              .positive('The number should be bigger than 0')
              .max(1000, 'Please provide a smaller number')
              .when(['selected', 'is_sla'], {
                is: true,
                then: (schema) => schema.required('Required'),
              }),
          })
        )
      )
    ),
    total: Yup.boolean().test(
      'max-questions-sum',
      'The total amount of questions is bigger than the permitted max.',
      (value, ctx) => {
        const questionsLength = Object.values(ctx.parent[QUESTIONS_KEY]).filter(({ selected }) => selected).length;
        const customQuestionsLength = Object.values(ctx.parent[CUSTOM_QUESTIONS_KEY]).length;
        return questionsLength + customQuestionsLength <= TOTAL_ALLOWED_QUESTIONS;
      }
    ),
    preview_selected: Yup.boolean().test('min-questions-sum', 'No question selected', (value, ctx) => {
      if (!value) {
        return true;
      }
      const questionsLength = Object.values(ctx.parent[QUESTIONS_KEY]).filter(({ selected }) => selected).length;
      const customQuestionsLength = Object.values(ctx.parent[CUSTOM_QUESTIONS_KEY]).length;

      return questionsLength + customQuestionsLength > 0;
    }),
  });
