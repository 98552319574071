import React from 'react';
import { useFormikContext } from 'formik';

import Typography from '~/components/core/Atomic/Typography';
import cn from '~/Utils/cn';

import { QuestionIcon } from '../../../../../icons';
import { TOTAL_ALLOWED_QUESTIONS } from '../constants';
import { getTotalSelected } from '../utils';

import styles from './formFooter.module.scss';

const Footer = () => {
  const { values, errors } = useFormikContext();
  const footerErrors = !!(errors.preview_selected || errors.total);

  const totalSelectedLength = getTotalSelected(values);

  return (
    <Typography display="block" component="span">
      <div className={cn(styles.container, { [styles.error]: footerErrors })}>
        <QuestionIcon size={20} className={styles.icon} iconColor={styles.iconColor} />
        Chosen Questions<b>&nbsp;{totalSelectedLength}</b>/{TOTAL_ALLOWED_QUESTIONS}
        {TOTAL_ALLOWED_QUESTIONS === totalSelectedLength && (
          <Typography display="block" component="span">
            &nbsp; - you may define a maximum of {TOTAL_ALLOWED_QUESTIONS} questions per form.
          </Typography>
        )}
      </div>
    </Typography>
  );
};

export default Footer;
