import React from 'react';
import { getIn, useFormikContext } from 'formik';

import { LobMultiSelect } from '~/components/TPA/LOB/LobMultiselect';

interface LobMultiselectFormikProps {
  lobFieldId: string;
  subOrganizationIds?: number[];
  disabled?: boolean;
  label?: string;
}

const LobMultiselectFormik: React.FC<LobMultiselectFormikProps> = ({
  lobFieldId,
  subOrganizationIds = [],
  disabled = false,
  label = 'Line of Business',
}) => {
  const { setFieldValue, setFieldTouched, touched, errors } = useFormikContext();
  const defaultOnChange = (lobs: string[]) => {
    setFieldValue(lobFieldId, lobs ? lobs : []);
  };

  return (
    <LobMultiSelect
      subOrganizationIds={subOrganizationIds}
      disabled={disabled}
      label={label}
      onChange={defaultOnChange}
      textFieldProps={{
        error: getIn(errors, lobFieldId) && getIn(touched, lobFieldId),
        helperText: getIn(errors, lobFieldId) && getIn(touched, lobFieldId) && getIn(errors, lobFieldId),
        onBlur: () => setFieldTouched(lobFieldId, true),
      }}
    />
  );
};

export default LobMultiselectFormik;
