import React, { useState } from 'react';

import useOrganization from '~/components/OrganizationContext';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import {
  adminSiteInfoUrl,
  tableauSiteDataUrl,
} from '~/components/SystemConfiguration/TableauAccounts/tableauAccountsRoutes';
import useDataFetcher from '~/components/useDataFetcher';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';

const useTableauAccounts = ({ isInternalAdmin }) => {
  const { organizationId, tableauAccountsConfiguration = {} } = useOrganization();
  const { organization } = useSysconfig();
  const isSelfServiceFeatureEnabled = isFeatureEnabled(
    organization,
    CONFIGURATION_FEATURES_NAMES.FF_TABLEAU_SELF_SERVICE
  );
  const {
    data: siteData,
    isLoading: isSiteDataLoading,
    reloadData,
  } = useDataFetcher(
    isInternalAdmin ? adminSiteInfoUrl(organizationId) : tableauSiteDataUrl(organizationId),
    {},
    isSelfServiceFeatureEnabled
  );
  const [isLoading, setIsLoading] = useState(isSiteDataLoading);
  const [viewerCapacity, setViewerCapacity] = useState(0);
  const [explorerCapacity, setExplorerCapacity] = useState(0);

  const isEnabled = tableauAccountsConfiguration?.is_tableau_accounts_enabled || false;
  const maxLimit = React.useMemo(() => {
    return isSelfServiceFeatureEnabled
      ? viewerCapacity + explorerCapacity
      : tableauAccountsConfiguration?.tableau_accounts_max_limit;
  }, [
    viewerCapacity,
    explorerCapacity,
    isSelfServiceFeatureEnabled,
    tableauAccountsConfiguration?.tableau_accounts_max_limit,
  ]);

  React.useEffect(() => {
    if (siteData) {
      setViewerCapacity(siteData?.tier_viewer_capacity);
      setExplorerCapacity(siteData?.tier_explorer_capacity);
      setIsLoading(isSiteDataLoading);
    }
  }, [siteData, isSiteDataLoading]);

  return { isLoading, maxLimit, isEnabled, isSelfServiceFeatureEnabled, reloadData, viewerCapacity, explorerCapacity };
};
export default useTableauAccounts;
