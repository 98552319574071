const NOTIFICATIONS_SCOPES = {
  claim: {
    desc: 'Claim',
  },
  exposure: {
    desc: 'Exposure',
  },
} as const;

export default NOTIFICATIONS_SCOPES;
