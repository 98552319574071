import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { get, snakeCase } from 'lodash';

import { useStyles } from '~/assets/styles';
import SwitchFormik from '~/components/core/Formik/SwitchFormik';
import { TrashIcon } from '~/components/icons';
import InlineIconButton from '~/components/InlineIconButton';
import { useSetDefaultFieldsOnChange } from '~/components/TextFieldFormik';
import cn from '~/Utils/cn';

const SwitchOrDelete = ({ id, isExistingValue, onDelete, ...rest }) => {
  const classes = useStyles();
  const { values, touched } = useFormikContext();
  const baseId = id?.split('.active')?.[0];
  const idTouched = get(touched, `${baseId}.id`, false);

  // Handle default value for keys
  useSetDefaultFieldsOnChange(
    get(values, `${baseId}.desc`),
    {
      [`${baseId}.id`]: snakeCase(get(values, `${baseId}.desc`)),
    },
    null,
    isExistingValue || idTouched
  );

  if (!isExistingValue) {
    return (
      <div className="flex items-center justify-center p-16">
        <InlineIconButton
          icon={TrashIcon}
          tooltipTitle="Remove"
          iconStyle={{ display: 'flex' }}
          className={cn(classes.textIcon, classes.hoverableNonFilledIcon)}
          onClick={onDelete}
          hideErrorGap
          {...rest}
        />
      </div>
    );
  }

  return (
    <SwitchFormik
      id={id}
      className={classes.formsSwitch}
      color="primary"
      labelStyle={{ margin: '10px 0 0 0' }}
      {...rest}
    />
  );
};

SwitchOrDelete.propTypes = {
  id: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  isExistingValue: PropTypes.bool.isRequired,
};

export default SwitchOrDelete;
