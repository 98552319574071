import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import MenuItem from '~/components/core/Atomic/MenuItem';
import CancelButton from '~/components/core/Buttons/CancelButton';

import { TWILIO_CONFIGURATION } from '../../../../../Types';
import CardDialog from '../../../../CardDialog';
import { TextFieldFormik } from '../../../../TextFieldFormik';
import CONSTS from '../QueueManagementConstants';

import { useStyles } from '../../../../../assets/styles';
import styles from './EditQueueRoutingConfiguration.module.scss';

const possibleTimes = TWILIO_CONFIGURATION.QUEUE_ROUTING.POSSIBLE_TIME_VALUES;
const possibleTimesList = Object.values(possibleTimes).map((t) => t.value);

const EditQueueRoutingConfiguration = ({ configuration = {}, onSubmit, onCancel }) => {
  const classes = useStyles();
  const possibleQueues = configuration.possibleQueues || [];

  return (
    <Formik
      initialValues={{
        [CONSTS.DIRECT_CALL_TIMEOUT_SECONDS]: configuration[CONSTS.DIRECT_CALL_TIMEOUT_SECONDS] || undefined,
        [CONSTS.DEFAULT_QUEUE_SID]: configuration[CONSTS.DEFAULT_QUEUE_SID] || undefined,
      }}
      validationSchema={Yup.object().shape({
        [CONSTS.DIRECT_CALL_TIMEOUT_SECONDS]: Yup.number()
          .required('Required')
          .integer()
          .oneOf(possibleTimesList, 'Invalid value'),
        [CONSTS.DEFAULT_QUEUE_SID]: Yup.string()
          .required('Required')
          .oneOf(
            possibleQueues.map((q) => q.queue_sid),
            'Invalid value'
          ),
      })}
      onSubmit={async (values, formikProps) => {
        try {
          await onSubmit({ ...values, [CONSTS.DDI_ROUTING_ENABLED]: true });
          onCancel();
        } catch {
          formikProps.setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, handleSubmit }) => {
        return (
          <CardDialog
            title="Set-up Queue Routing"
            isDialog
            maxWidth="sm"
            fullWidth
            onClose={onCancel}
            preventClose={isSubmitting}
          >
            <div className={styles.inputsContainer}>
              <TextFieldFormik id={CONSTS.DIRECT_CALL_TIMEOUT_SECONDS} select label="No answer timeout" fullWidth>
                {possibleTimesList.map((timeValue) => (
                  <MenuItem key={timeValue} value={timeValue}>
                    {possibleTimes[timeValue].desc}
                  </MenuItem>
                ))}
              </TextFieldFormik>
              <TextFieldFormik
                id={CONSTS.DEFAULT_QUEUE_SID}
                select
                label="Organization Level Default Queue"
                fullWidth
                SelectProps={{
                  renderValue: (value) => {
                    return possibleQueues.find((q) => q.queue_sid === value)?.queue_name;
                  },
                }}
              >
                {possibleQueues.map((queue) => (
                  <MenuItem key={queue.queue_sid} value={queue.queue_sid}>
                    {queue.queue_name}
                  </MenuItem>
                ))}
              </TextFieldFormik>
            </div>
            <div className={classes.buttonsContainer}>
              <CancelButton disabled={isSubmitting} onClick={onCancel} />
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

EditQueueRoutingConfiguration.propTypes = {
  configuration: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default EditQueueRoutingConfiguration;
