import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import _ from 'lodash';

import AlertBanner from '~/components/core/AlertBanner';

export const useLobsSubOrgsIntersectionCheck = (organization) => {
  const { values } = useFormikContext();

  const getSelectedSubOrgs = () => {
    if (organization.sub_organizations_enabled) {
      return values.sub_organization_ids?.includes('all')
        ? organization.sub_organizations
        : organization.sub_organizations.filter((subOrg) => values.sub_organization_ids?.includes(subOrg.id));
    }
  };

  const availableLobsForSubOrgs = organization.sub_organizations_enabled
    ? _.intersection(...getSelectedSubOrgs().map(({ supported_lobs }) => supported_lobs.map(({ lob }) => lob))).flat()
    : null;

  return {
    filterLobsFunction: (lob) => (availableLobsForSubOrgs ? availableLobsForSubOrgs.includes(lob) : true),
    availableLobsForSubOrgs,
  };
};

export const NoAvailableLobs = ({ organization }) => {
  const { values } = useFormikContext();
  const { availableLobsForSubOrgs } = useLobsSubOrgsIntersectionCheck(organization);

  return (
    <>
      {values.sub_organization_ids?.length > 0 && availableLobsForSubOrgs?.length === 0 && (
        <AlertBanner
          note="The selected sub organizations have no shared line of business"
          withIcon
          alertType={AlertBanner.ALERT_TYPES.INFO}
        />
      )}
    </>
  );
};

NoAvailableLobs.propTypes = {
  organization: PropTypes.object.isRequired,
};
