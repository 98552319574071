import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '~/components/core/Atomic/Tooltip';
import Typography from '~/components/core/Atomic/Typography';
import TextWithChips from '~/components/core/TextWithChips';

import { useSysconfig } from '../../../SystemConfigurationScreen';

import { useStyles } from '../../../../../assets/styles';

export const TitleAndContentWithTokens = ({ title, content }) => {
  const classes = useStyles();
  const {
    organizationOperationalDetails: { notificationsTokens },
  } = useSysconfig();

  return (
    <div style={{ width: '250px' }}>
      <Tooltip title={title ? <TextWithChips text={title} tokensValues={notificationsTokens} /> : ''}>
        <Typography component="div" style={{ fontWeight: 400, fontSize: '14px' }} className={classes.overflowText}>
          <TextWithChips text={title} tokensValues={notificationsTokens} />
        </Typography>
      </Tooltip>
      <Tooltip title={content ? <TextWithChips text={content} tokensValues={notificationsTokens} /> : ''}>
        <Typography component="div" style={{ fontSize: '10px', color: '#606060' }} className={classes.overflowText}>
          <TextWithChips text={content} tokensValues={notificationsTokens} />
        </Typography>
      </Tooltip>
    </div>
  );
};

TitleAndContentWithTokens.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
};
