import { reportErrorInProductionOrThrow } from '../../../../Utils';
import mixpanel from '../../../CmsMain/mixpanel';

export const PERMISSIONS_MIXPANEL_EVENTS = {
  view_profile_users: 'view_profile_users',
  view_permissions_profile: 'view_permissions_profile',
  create_permissions_profile_clicked: 'create_permissions_profile_clicked',
  cancel_create_permissions_profile: 'cancel_create_permissions_profile',
  permissions_profile_created: 'permissions_profile_created',
  assign_user_create_new_profile: 'assign_user_create_new_profile',
  create_permissions_profile_back_next_clicked: 'create_permissions_profile_back_next_clicked',
  save_permission_profile_as_draft: 'save_permission_profile_as_draft',
  edit_permission_profile_draft: 'edit_permission_profile_draft',
  delete_permission_profile_draft: 'delete_permission_profile_draft',
  edit_permission_profile: 'edit_permission_profile',
  duplicate_permission_profile: 'duplicate_permission_profile',
  delete_permission_profile_clicked: 'delete_permission_profile_clicked',
  delete_permission_profile_assigned_users_error_presented: 'delete_permission_profile_assigned_users_error_presented',
  delete_permission_profile_already_deleted_error_presented:
    'delete_permission_profile_already_deleted_error_presented',
  delete_permission_profile_confirm_presented: 'delete_permission_profile_confirm_presented',
  permission_profile_deleted: 'permission_profile_deleted',
  permissions_profile_add_users: 'permissions_profile_add_users',
  permission_profile_assign_users_pop_up_presented: 'permission_profile_assign_users_pop_up_presented',
  replace_permissions_profile: 'replace_permissions_profile',
};

export const PERMISSIONS_MIXPANEL_SOURCES = {
  TABLE: 'profiles_table',
  CREATE_PROFILE: 'create_profile',
  VIEW_PROFILE: 'view_profile',
};

export const safeMixpanelTrack = (event, data) => {
  try {
    mixpanel.track(event, data);
  } catch (e) {
    reportErrorInProductionOrThrow(e);
  }
};
