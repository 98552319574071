import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import CardDialog from '~/components/CardDialog';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';
import RadioButtonGroupFormik from '~/components/core/Formik/RadioButtonGroupFormik';
import { DUPLICATE_PAYMENTS_VERIFICATION_ACTION } from '~/Types';

const EditDuplicatePaymentsConfigurationDialog = ({ paymentsConfiguration, disabled = false, onCancel, onSubmit }) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        duplicate_payments_level_of_verification:
          paymentsConfiguration?.duplicate_payments_level_of_verification || 'claim',
        duplicate_payments_action: paymentsConfiguration?.duplicate_payments_action || 'adjuster_confirmation',
      }}
      validationSchema={Yup.object().shape({
        duplicate_payments_level_of_verification: Yup.string().oneOf(['claim', 'exposure']),
        duplicate_payments_action: Yup.string().oneOf(Object.keys(DUPLICATE_PAYMENTS_VERIFICATION_ACTION)),
      })}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        try {
          await onSubmit(values);
        } catch (error) {
          formikProps.setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit }) => {
        return (
          <CardDialog
            title="Enable duplicate verification"
            isDialog
            maxWidth="md"
            fullWidth
            onClose={onCancel}
            preventClose={disabled}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <RadioButtonGroupFormik
                  label="Identify duplicate payments per:"
                  id="duplicate_payments_level_of_verification"
                  direction="row"
                  options={[
                    {
                      text: 'Claim',
                      optionValue: 'claim',
                    },
                    {
                      text: 'Exposure',
                      optionValue: 'exposure',
                    },
                  ]}
                  variant="subtitle1"
                />
              </Grid>
              <Grid item xs={12}>
                <RadioButtonGroupFormik
                  label="When a duplicate payment is identified:"
                  id="duplicate_payments_action"
                  direction="row"
                  options={Object.entries(DUPLICATE_PAYMENTS_VERIFICATION_ACTION).map(([key, value]) => ({
                    optionValue: key,
                    text: value.desc,
                  }))}
                  variant="subtitle1"
                />
              </Grid>
            </Grid>
            <div className={classes.buttonsContainer}>
              <CancelButton disabled={disabled} onClick={onCancel} />
              <Button variant="contained" color="primary" disabled={disabled} onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

EditDuplicatePaymentsConfigurationDialog.propTypes = {
  paymentsConfiguration: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default EditDuplicatePaymentsConfigurationDialog;
