import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

import MenuItem from '~/components/core/Atomic/MenuItem';

import CardDialog from '../../CardDialog';
import { Text } from '../../core';
import HoverActionField from '../../HoverActionField';
import useDataFetcher from '../../useDataFetcher';

function OrganizationAdjusterSelect({ organization, id, label, adjuster, onSelectAdjuster, hideLabel, showOnly }) {
  const { isLoading, data: possibleAdjusters } = useDataFetcher(
    `/sysconfig/api/v1/organizations/${organization.id}/possible_adjusters`
  );
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <>
      <HoverActionField onAction={(e) => setPopoverAnchorEl(e.currentTarget)} disabled={showOnly}>
        {!hideLabel && <Text variant={Text.VARIANTS.SM}>{label}</Text>}
        {adjuster && adjuster.username}
      </HoverActionField>

      <CardDialog
        isPopover
        open={Boolean(popoverAnchorEl)}
        anchorEl={popoverAnchorEl}
        title={`Select ${label}`}
        onClose={() => setPopoverAnchorEl(undefined)}
      >
        <TextField
          id={id}
          label={label}
          select
          value={adjuster ? adjuster.id : 0}
          onChange={async (e) => {
            setIsSubmitting(true);
            await onSelectAdjuster(e.target.value);
            setPopoverAnchorEl(undefined);
            setIsSubmitting(false);
          }}
          style={{ width: 200 }}
          disabled={isLoading || isSubmitting}
        >
          {possibleAdjusters &&
            possibleAdjusters.map((adjuster) => (
              <MenuItem key={adjuster.id} value={adjuster.id}>
                {adjuster.username}
              </MenuItem>
            ))}
        </TextField>
      </CardDialog>
    </>
  );
}

OrganizationAdjusterSelect.propTypes = {
  organization: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  hideLabel: PropTypes.bool,
  adjuster: PropTypes.object,
  onSelectAdjuster: PropTypes.func.isRequired,
  showOnly: PropTypes.bool,
};

export default OrganizationAdjusterSelect;
