import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';

import CardDialog from '../../../../../CardDialog';

import { useStyles } from '../../../../../../assets/styles';

const PolicyAPIDataForm = ({
  title,
  firstTitle,
  secondTitle,
  thirdTitle,
  switchTitle,
  wizardOpen,
  getEntityInitialValues,
  getValidationSchema,
  getEditInputs,
  onSave,
  onCancel,
  openEdit,
}) => {
  const classes = useStyles();
  return (
    <Formik
      initialValues={getEntityInitialValues}
      validationSchema={Yup.object().shape(getValidationSchema())}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSave(values);
        } catch (e) {
          setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit, errors } = formikProps;
        return (
          <CardDialog isDialog={wizardOpen} title={title} maxWidth="sm" fullWidth preventClose={isSubmitting}>
            <Grid container>
              {getEditInputs(wizardOpen, firstTitle, secondTitle, thirdTitle, switchTitle)}
              {wizardOpen ? (
                <Grid item xs={12}>
                  <div className={classes.buttonsContainer}>
                    <CancelButton disabled={isSubmitting} onClick={onCancel} />
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting || Object.keys(errors).length > 0}
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  </div>
                </Grid>
              ) : (
                <Grid container style={{ marginTop: '20px' }}>
                  <Button
                    style={{ marginLeft: '16px' }}
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting || Object.keys(errors).length > 0}
                    onClick={openEdit}
                  >
                    Edit
                  </Button>
                </Grid>
              )}
            </Grid>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

PolicyAPIDataForm.propTypes = {
  title: PropTypes.string.isRequired,
  firstTitle: PropTypes.string.isRequired,
  secondTitle: PropTypes.string.isRequired,
  thirdTitle: PropTypes.string.isRequired,
  switchTitle: PropTypes.string.isRequired,
  openEdit: PropTypes.func,
  wizardOpen: PropTypes.bool.isRequired,
  getEntityInitialValues: PropTypes.object.isRequired,
  getValidationSchema: PropTypes.func.isRequired,
  getEditInputs: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default PolicyAPIDataForm;
