import { INTEGRATION_STATUS_DICT } from '~/Types';

export const getIsActiveOrPending = (configurationStatus) =>
  [INTEGRATION_STATUS_DICT.pending, INTEGRATION_STATUS_DICT.active].includes(configurationStatus);

export const getIsActive = (configurationStatus) => [INTEGRATION_STATUS_DICT.active].includes(configurationStatus);

export const getIsNotActiveOrInProgress = (configurationStatus) =>
  [INTEGRATION_STATUS_DICT.not_active, INTEGRATION_STATUS_DICT.in_progress].includes(configurationStatus);

export const getRequiredWhenActiveOrPendingSchema = (configurationStatus, baseSchema) =>
  getIsActiveOrPending(configurationStatus) ? baseSchema.required('Required') : baseSchema;
