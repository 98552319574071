import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, Formik, getIn, useFormikContext } from 'formik';
import { isEmpty, noop } from 'lodash';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Typography from '~/components/core/Atomic/Typography';
import CancelButton from '~/components/core/Buttons/CancelButton';
import InnerCard from '~/components/core/Cards/InnerCard';
import { AddIcon } from '~/components/deprecatedMuiIcons';

import { GLOBAL_ADDRESSES_OPTIONS, LOCATION_TYPES_DICT } from '../../../../../../Types';
import CardDialog from '../../../../../CardDialog';
import CountryAutocompleteFormik from '../../../../../CountryAutocompleteFormik';
import { TrashIcon_Deprecated } from '../../../../../icons';
import TextFieldFormik from '../../../../../TextFieldFormik';
import propTypes from '../../propTypes';

import { useStyles } from '../../../../../../assets/styles';
import styles from './editCountryLocationDialog.module.scss';

const FIELD_IDS = {
  GEOGRAPHIC_COVERAGES: 'geographic_coverages',
  COUNTRIES_SELECTION: 'countries_selection',
  COUNTRIES: 'countries',
  LOBS: 'lobs',
  POLICY_CLASSIFICATION_REGEX: 'policy_classification_regex',
  CLAIM_IDENTIFIER: 'claim_identifier',
};

const MULTIPLE_GLOBAL_ADDRESSES_KEY = 'multiple';

const EditCountryLocationInner = ({ onClose, supportedLocation }) => {
  const classes = useStyles();
  const { isSubmitting, handleSubmit, values } = useFormikContext();

  const { location_type: locationType } = supportedLocation;

  return (
    <CardDialog
      title="Edit Organization Countries Configuration"
      containerClassName={styles.editOrgCountriesContainer}
      isDialog
      preventClose={isSubmitting}
      maxWidth="sm"
      fullWidth
      onClose={onClose}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} className={styles.locationType}>
          <Typography variant="subtitle1">{LOCATION_TYPES_DICT[locationType]}</Typography>
        </Grid>

        <Grid item xs={6} className={styles.countriesSelection}>
          <TextFieldFormik
            id={FIELD_IDS.GEOGRAPHIC_COVERAGES}
            label="Geographic Coverages"
            className={classes.textField}
            select
            fullWidth
          >
            {Object.keys(GLOBAL_ADDRESSES_OPTIONS).map((optionKey) => (
              <MenuItem key={optionKey} value={optionKey}>
                {GLOBAL_ADDRESSES_OPTIONS[optionKey]}
              </MenuItem>
            ))}
          </TextFieldFormik>
        </Grid>
      </Grid>
      {getIn(values, FIELD_IDS.GEOGRAPHIC_COVERAGES) === MULTIPLE_GLOBAL_ADDRESSES_KEY && (
        <InnerCard className={styles.countriesWrapper}>
          <Grid container>
            <Grid item xs={12}>
              <Typography display="block" variant="subtitle2">
                Choose Countries
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <CountrySelections />
            </Grid>
          </Grid>
        </InnerCard>
      )}

      <div className={classes.buttonsContainer}>
        <CancelButton disabled={isSubmitting} onClick={onClose} />
        <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
          Save
        </Button>
      </div>
    </CardDialog>
  );
};

const CountrySelections = () => {
  const classes = useStyles();
  const { values, isSubmitting } = useFormikContext();

  return (
    <FieldArray
      name={FIELD_IDS.COUNTRIES}
      render={({ remove, push }) => {
        const countries = getIn(values, FIELD_IDS.COUNTRIES);
        return (
          <>
            {countries.map((countryCode, idx) => {
              const id = `${FIELD_IDS.COUNTRIES}.${idx}`;

              return (
                <Grid container key={id} className={styles.countrySelectWrapper}>
                  <Grid item xs={10}>
                    <CountryAutocompleteFormik
                      id={id}
                      label="Country"
                      fullWidth
                      countriesConfigurationKey="contact_location"
                    />
                  </Grid>
                  {idx > 0 && (
                    <Grid item xs={2}>
                      <IconButton
                        className={styles.removeCountryBtn}
                        onClick={() => remove(idx)}
                        disabled={isSubmitting}
                      >
                        <TrashIcon_Deprecated />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              );
            })}
            <Button
              className={styles.addCountryBtn}
              color="primary"
              onClick={() => push(undefined)}
              disabled={isSubmitting}
            >
              <AddIcon className={classes.leftButtonIcon} />
              Add Country
            </Button>
          </>
        );
      }}
    />
  );
};

EditCountryLocationInner.propTypes = {
  onClose: PropTypes.func.isRequired,
  supportedLocation: PropTypes.shape(propTypes.countriesConfigurationForLocation),
};

const EditCountryLocationDialog = ({ onUpdate = noop, onClose = noop, supportedLocation = {} }) => {
  const { configuration_select: selectedCoverages, selected_countries: countries } = supportedLocation;
  const { location_type: locationType, lob } = supportedLocation;

  return (
    <Formik
      initialValues={{
        [FIELD_IDS.GEOGRAPHIC_COVERAGES]: selectedCoverages,
        [FIELD_IDS.COUNTRIES]: isEmpty(countries) ? [undefined] : countries,
      }}
      validationSchema={Yup.object().shape({
        [FIELD_IDS.COUNTRIES]: Yup.array().when(FIELD_IDS.GEOGRAPHIC_COVERAGES, {
          is: MULTIPLE_GLOBAL_ADDRESSES_KEY,
          then: Yup.array().of(
            Yup.string()
              .nullable()
              .required('Please select a value')
              .test('unique value', 'Country already exists', (value, ctx) => {
                return ctx.parent.findIndex((currentVal) => currentVal === value) === ctx.options.index;
              })
          ),
        }),
      })}
      enableReinitialize
      onSubmit={async (values) => {
        await onUpdate({
          locationType,
          lob,
          countriesSelection: getIn(values, FIELD_IDS.GEOGRAPHIC_COVERAGES),
          countries: getIn(values, FIELD_IDS.COUNTRIES).filter((country) => country),
        });
      }}
    >
      <EditCountryLocationInner onClose={onClose} supportedLocation={supportedLocation} />
    </Formik>
  );
};

EditCountryLocationDialog.propTypes = {
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
  supportedLocation: PropTypes.shape(propTypes.countriesConfigurationForLocation),
};

export default EditCountryLocationDialog;
