import React, { useState } from 'react';
import { Switch } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Tooltip } from '@mui/material';
import axios from 'axios';

import { useStyles } from '~/assets/styles';
import AlertBanner from '~/components/core/AlertBanner';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import SetNumericValueInnerCard from '~/components/core/SetNumericValueInnerCard';
import {
  adminSelfService,
  adminSiteInfoUrl,
  enableUrl,
  maxLimitUrl,
} from '~/components/SystemConfiguration/TableauAccounts/tableauAccountsRoutes';
import useTableauAccounts from '~/components/SystemConfiguration/TableauAccounts/useTableauAccounts';
import useDataFetcher from '~/components/useDataFetcher';
import { reportAxiosError } from '~/Utils';

import WithConfirm from '../../ConfirmModal';
import { FsButton, Heading, LoadingSwitch, Text } from '../../core';
import useOrganization from '../../OrganizationContext';

import TableauAccountsTable from './TableauAccountsTable';

import styles from './index.module.scss';

export const HEADER = 'TABLEAU ACCOUNTS MANAGER';
export const SUB_HEADER = 'Manage Tableau account access';
export const ENABLE_TEXT = 'Enable Tableau User Management';

const TableauAccounts = () => {
  const classes = useStyles();
  const { reloadOperationalDetails, organizationId } = useOrganization();
  const {
    isLoading: isSiteDataLoading,
    maxLimit,
    isEnabled,
    reloadData,
    viewerCapacity,
    explorerCapacity,
    isSelfServiceFeatureEnabled,
  } = useTableauAccounts({ isInternalAdmin: true });

  const [isSaveLoading, setIsSaveLoading] = useState(isSiteDataLoading);
  const [isInitSelfServiceLoading, setIsInitSelfServiceLoading] = useState(false);
  const isLoading = isSiteDataLoading && isSaveLoading;
  const shouldFetchMainProject = isEnabled && isSelfServiceFeatureEnabled;
  const { data: mainProject, reloadData: reloadMainProject } = useDataFetcher(
    `${adminSelfService(organizationId)}/main_project`,
    {},
    shouldFetchMainProject
  );
  const disableSelfServiceConfiguration = mainProject || isInitSelfServiceLoading;
  const toggleEnableTableauAccounts = async (enable) => {
    try {
      setIsSaveLoading(true);
      await axios.post(enableUrl(organizationId), { enable });
      await reloadOperationalDetails();
      setIsSaveLoading(false);
    } catch (error) {
      setIsSaveLoading(false);
      reportAxiosError(error);
    }
  };

  const enableTableauAccounts = async () => {
    await toggleEnableTableauAccounts(true);
  };

  const disableTableauAccounts = async () => {
    await toggleEnableTableauAccounts(false);
  };

  const setTableauAccountsMaxLimit = async (tableau_accounts_max_limit) => {
    try {
      setIsSaveLoading(true);
      await axios.post(maxLimitUrl(organizationId), { tableau_accounts_max_limit });
      await reloadOperationalDetails();
      setIsSaveLoading(false);
    } catch (error) {
      setIsSaveLoading(false);
      reportAxiosError(error);
    }
  };

  const setTableauLicenseCapacityLimit = async ({ tierViewerCapacity = null, tierExplorerCapacity = null }) => {
    try {
      setIsSaveLoading(true);
      await axios.post(adminSiteInfoUrl(organizationId), {
        tier_viewer_capacity: tierViewerCapacity,
        tier_explorer_capacity: tierExplorerCapacity,
      });
      await reloadData();
      setIsSaveLoading(false);
    } catch (error) {
      setIsSaveLoading(false);
      reportAxiosError(error);
    }
  };

  const onInitSelfService = React.useCallback(async () => {
    setIsInitSelfServiceLoading(true);
    try {
      await axios.post(`${adminSelfService(organizationId)}/init`, {});
      await reloadMainProject();
    } catch (error) {
      reportAxiosError(error);
    }
    setIsInitSelfServiceLoading(false);
  }, [organizationId, setIsInitSelfServiceLoading, reloadMainProject]);

  return (
    <LoadingSwitch isLoading={isLoading}>
      <Grid container className={styles.tableauAccountsContainer}>
        <Grid item xs={12}>
          <Heading>{HEADER}</Heading>
        </Grid>
        <Grid item xs={12}>
          <Text className={styles.headerLine} variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
            {SUB_HEADER}
          </Text>
        </Grid>
        <Grid item xs={12} className={styles.headerLine}>
          {!isEnabled ? (
            <FsButton
              color={FsButton.COLORS.primary}
              variant={FsButton.VARIANTS.contained}
              onClick={enableTableauAccounts}
            >
              {ENABLE_TEXT}
            </FsButton>
          ) : (
            <Text className={styles.headerLine} variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
              <WithConfirm
                title="Delete Tableau Accounts?"
                contentText="Deleting this user from account access list will disable the user from seeing Tableau features."
                primaryButtonName="YES, DISABLE"
                shouldCloseOnPrimary={true}
                triggerMethod="onChange"
                secondaryButtonName="NO"
                newSecondaryButton
                centerDialog
              >
                <Switch
                  checked={isEnabled}
                  className={classes.formsSwitch}
                  size="small"
                  onChange={disableTableauAccounts}
                />
              </WithConfirm>
              <span className={styles.label}>Enabled</span>
            </Text>
          )}
        </Grid>
        {isEnabled && !maxLimit && (
          <Grid item xs={12}>
            <AlertBanner
              alertType={AlertBanner.ALERT_TYPES.INFO}
              note="Set the maximum number of users with account access before adding users to the list."
              withIcon
            />
          </Grid>
        )}
        {isEnabled && !isSelfServiceFeatureEnabled && (
          <SetNumericValueInnerCard
            id="tableau_accounts_max_limit"
            value={maxLimit}
            label="Account maximum"
            title="Maximum users with account access"
            onSubmit={async (values) => {
              await setTableauAccountsMaxLimit(values?.tableau_accounts_max_limit);
            }}
          />
        )}

        {isEnabled && isSelfServiceFeatureEnabled && (
          <div className="flex flex-col gap-20">
            <div className="flex gap-20">
              <SetNumericValueInnerCard
                id="viewerCapacity"
                value={viewerCapacity}
                label="Max Viewer Limit"
                title="Maximum users with Viewer access"
                onSubmit={async (values) => {
                  await setTableauLicenseCapacityLimit({ tierViewerCapacity: values?.viewerCapacity });
                }}
              />
              <SetNumericValueInnerCard
                id="explorerCapacity"
                value={explorerCapacity}
                label="Max Explorer Limit"
                title="Maximum users with Explorer access"
                onSubmit={async (values) => {
                  await setTableauLicenseCapacityLimit({ tierExplorerCapacity: values?.explorerCapacity });
                }}
              />
            </div>
            <div className="flex items-center gap-20">
              <Tooltip
                title={
                  disableSelfServiceConfiguration && !isInitSelfServiceLoading
                    ? 'Self Service already configured for this organization'
                    : 'Clicking this button will create the necessary self service projects and permission groups on tableau site.'
                }
                placement="top"
              >
                <span className="w-[calc(50%-10px)]">
                  <Button
                    onClick={onInitSelfService}
                    variant="outlined"
                    size="small"
                    className="w-full"
                    disabled={disableSelfServiceConfiguration}
                  >
                    {disableSelfServiceConfiguration ? 'Self Service configured' : 'Init Self Service'}
                  </Button>
                </span>
              </Tooltip>

              {isInitSelfServiceLoading && <CircularProgress size={20} />}
              <span />
            </div>
          </div>
        )}

        {isEnabled && <TableauAccountsTable maxLimit={maxLimit} />}
      </Grid>
    </LoadingSwitch>
  );
};

export default TableauAccounts;
