import React from 'react';

import { ADMIN_ROUTES } from '~/routesConstants';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';

import ConfigurationScreenWithTabs from '../ConfigurationScreenWithTabs';
import { useSysconfig } from '../SystemConfigurationScreen';

import AssignmentsTab from './Assignments/AssignmentsTab';
import NotificationsConfigurationTool from './Notifications/NotificationsConfigurationTool';
import AutomaticCommunications from './Communications';
import AutomaticExposures from './Exposures';

const AUTOMATIC_RULES_CONFIGURATION_SUBTITLE =
  'Set up custom rules in no-code engines which automatically trigger actions to automate processes within the claims management system.';

const AutomaticRulesConfiguration = () => {
  const { organization } = useSysconfig();

  const tabs = [
    {
      label: 'Exposures',
      url: ADMIN_ROUTES.AUTOMATION.EXPOSURES,
      component: AutomaticExposures,
      isEnabled: isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.AUTOMATIC_EXPOSURES_RULES),
    },
    {
      label: 'Communications',
      url: ADMIN_ROUTES.AUTOMATION.COMMUNICATIONS,
      component: AutomaticCommunications,
      isEnabled: isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.AUTOMATIC_COMMUNICATIONS_RULES),
    },
    {
      label: 'Assignments',
      url: ADMIN_ROUTES.AUTOMATION.ASSIGNMENTS,
      component: AssignmentsTab,
      isEnabled: isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.ASSIGNMENTS_CONFIGURATION),
    },
    {
      label: 'Notifications',
      url: ADMIN_ROUTES.AUTOMATION.NOTIFICATIONS,
      component: NotificationsConfigurationTool,
      isEnabled: true,
    },
  ];

  return (
    <ConfigurationScreenWithTabs
      tabs={tabs}
      title="AUTOMATIC RULES CONFIGURATION"
      subtitle={AUTOMATIC_RULES_CONFIGURATION_SUBTITLE}
      operationTabName="Automatic Rules Configuration"
    />
  );
};

export default AutomaticRulesConfiguration;
export { AUTOMATIC_RULES_CONFIGURATION_SUBTITLE };
