import React, { useState } from 'react';

import Button from '~/components/core/Atomic/Buttons/Button';
import EmailsDisplay from '~/components/core/EmailsDisplay/EmailsDisplay';
import { useRoleRestriction } from '~/components/hooks/useRoleRestriction';

import { ROLES_TYPES_DICT } from '../../Types';
import { isUserSuperUser } from '../../UserUtils';
import CardDialog from '../CardDialog';
import { SortableTable } from '../core';
import { useCms } from '../hooks/useCms';

import CommunicationsIntegrationsAccountIndication from './Communications/CommunicationsIntegrationsAccountIndication';
import AddTwilioPhoneNumberDialog from './Communications/TwilioPhoneNumber/AddTwilioPhoneNumberDialog';
import { useSysconfig } from './SystemConfigurationScreen';

import { useStyles } from '../../assets/styles';

function OrganizationCommunicationsTab() {
  const { users, reloadUsers, organizationOperationalDetails } = useSysconfig();
  const { user } = useCms();
  const classes = useStyles();

  const [addingUserPhone, setAddingUserPhone] = useState(undefined);

  const isSuperUser = isUserSuperUser(user);

  useRoleRestriction({
    errorMessage: 'tried accessing OrganizationCommunicationsTab',
    roleValidators: [isUserSuperUser],
  });

  const columnData = [
    { id: 'username', numeric: false, label: 'Name' },
    { id: 'title', numeric: false, label: 'Title' },
    {
      id: 'twilio_phone_number',
      numeric: false,
      label: 'Twilio Phone Number',
      specialCell: (user) => (
        <>
          {user?.twilio_phone_number ? (
            <span>{user.twilio_phone_number}</span>
          ) : (
            <>
              {organizationOperationalDetails.isTwilioEnabled && (
                <Button color="primary" onClick={() => setAddingUserPhone(user)}>
                  + Add
                </Button>
              )}
            </>
          )}
        </>
      ),
    },
    {
      id: 'claims_email',
      numeric: false,
      label: 'Claims Email',
      specialCell: (user) => {
        return user?.claim_emails ? (
          <EmailsDisplay
            email_addresses={user.claim_emails}
            colorVariant={EmailsDisplay.COLOR_VARIANTS.PRIMARY}
            variant={EmailsDisplay.VARIANTS.SM}
            weight={EmailsDisplay.WEIGHTS.REGULAR}
          />
        ) : null;
      },
    },
  ];

  return (
    <>
      <CommunicationsIntegrationsAccountIndication />
      <div className={classes.cardDivRow}>
        <CardDialog title="Users">
          <SortableTable
            columns={columnData}
            rows={users.filter((user) => !ROLES_TYPES_DICT[user.role.role_type].is_hidden)}
            stickyHeader
          />
        </CardDialog>

        {addingUserPhone && (
          <AddTwilioPhoneNumberDialog
            user={addingUserPhone}
            onEdit={async () => {
              await reloadUsers();
              setAddingUserPhone(undefined);
            }}
            onClose={() => setAddingUserPhone(undefined)}
            isSuperUser={isSuperUser}
          />
        )}
      </div>
    </>
  );
}

export default OrganizationCommunicationsTab;
