import React from 'react';
import _ from 'lodash';
import * as Yup from 'yup';

import {
  CONFIGURATION_FEATURES_NAMES,
  COUNTRY_TO_STATE_MAP,
  NOTIFICATIONS_ENTITY_STATUSES,
  NOTIFICATIONS_RULES_RECIPIENTS,
  NOTIFICATIONS_RULES_TIMEFRAMES,
  NOTIFICATIONS_SCOPES,
} from '../../../../Types';
import { isFeatureEnabled } from '../../../../Utils';
import { useCms } from '../../../hooks/useCms';
import {
  MandatoryNotificationIcon,
  RecommendedNotificationIcon,
  SelfConfiguredNotificationIcon,
} from '../../../icons/notifications';

export const RULE_TYPE_OPTIONS = {
  mandatory: {
    title: 'Mandatory',
    icon: MandatoryNotificationIcon,
  },
  optional: {
    title: 'Recommended',
    icon: RecommendedNotificationIcon,
  },
  self_configured: {
    title: 'Self-configured',
    icon: SelfConfiguredNotificationIcon,
  },
};

export const ALL_OPTION = { desc: 'All', key: 'all' };

export const useNotificationConfigurationTool = () => {
  const { userOrganization } = useCms();

  const managerTerm = React.useMemo(() => {
    return isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.ORG_HIERARCHY_WORKFLOWS)
      ? 'unit leader'
      : 'supervisor';
  }, [userOrganization]);

  const formatManagerTerm = React.useCallback(
    (recipientTypeDescription) => recipientTypeDescription.replace('{manager_term}', managerTerm),
    [managerTerm]
  );

  const recipientTypes = React.useMemo(
    () =>
      Object.fromEntries(
        Object.entries(NOTIFICATIONS_RULES_RECIPIENTS).map(([key, value]) => [
          key,
          { ...value, desc: formatManagerTerm(value.desc) },
        ])
      ),
    [formatManagerTerm]
  );

  return {
    recipientTypes,
    formatManagerTerm,
  };
};

export const getScopeTypeDescription = (scopeType, scope, incidentTypesMap, coveragesMap) => {
  if (scope === 'claim') {
    return incidentTypesMap[scopeType] || '';
  } else if (scope === 'exposure') {
    return coveragesMap[scopeType] || '';
  } else {
    throw Error(`Unknown notification scope ${scope} in NotificationConfigurationTool`);
  }
};

export const INITIAL_SEARCH_FILTER = {
  searchNotificationText: '',
  selectedRuleType: [],
  selectedSubOrgs: [],
  selectedScope: [],
  selectedScopeType: [],
  selectedRecipient: [],
  hideDisabled: false,
};

export const NOTIFICATION_TYPE = {
  MANDATORY: 'mandatory',
  OPTIONAL: 'optional',
  SELF_CONFIGURED: 'self_configured',
};

export const ruleToInitialValue = (rule) => ({
  id: rule?.id || '',
  lob: rule?.lob || '',
  scope: rule?.scope || '',
  scope_status: rule?.scope_status || '',
  scope_types: rule?.scope_types || [ALL_OPTION],
  event_key: rule?.event_key || null,
  event_criteria_types: rule?.event_criteria_types || [ALL_OPTION],
  is_immediate: rule?.is_immediate || '',
  schedule: !rule || rule.is_immediate ? 'immediate' : 'custom',
  schedule_number: rule?.schedule_number,
  schedule_timeframe: rule?.schedule_timeframe,
  recipient: rule?.recipient || '',
  organization_unit_id: rule?.organization_unit_id,
  rule_name: rule?.rule_name || '',
  title: rule?.title || '',
  content: rule?.content || '',
  sub_organization_ids: rule?.sub_organization_ids || [],
  conditions: rule?.conditions || [],
  is_all_states: rule?.is_all_states ?? true,
  states: rule?.states ?? [],
});

export const statesValidationSchema = {
  states: Yup.array(Yup.string()).when('is_all_states', (isAllStates, currSchema) => {
    if (!isAllStates) {
      return currSchema.min(1, 'At least one state is required if a notification is defined for specific states');
    }
    return currSchema;
  }),
};

export const getValidationSchemaShape = (existingRuleNames, conditions, subOrgsRequired, recipientTypes) => ({
  lob: Yup.string().required('Required'),
  scope: Yup.string().required('Required').oneOf(Object.keys(NOTIFICATIONS_SCOPES), 'Invalid entity'),
  scope_status: Yup.string()
    .required('Required')
    .oneOf(Object.keys(NOTIFICATIONS_ENTITY_STATUSES), 'Invalid entity status'),
  scope_types: Yup.array().min(1, 'At least one type is required'),
  event_key: Yup.object().required('Required').nullable(),
  event_criteria_types: Yup.array().when('event_key', (eventKey, currSchema) => {
    if (eventKey && eventKey.has_type) {
      return currSchema.min(1, 'At least one type is required');
    }
    return currSchema;
  }),
  schedule_number: Yup.number()
    .when('schedule', {
      is: 'custom',
      then: Yup.number().required('Required'),
    })
    .nullable(),
  schedule_timeframe: Yup.string()
    .when('schedule', {
      is: 'custom',
      then: Yup.string().required('Required').oneOf(Object.keys(NOTIFICATIONS_RULES_TIMEFRAMES), 'Invalid timeframe'),
    })
    .nullable(),
  recipient: Yup.string().required('Required').oneOf(Object.keys(recipientTypes), 'Invalid recipient'),
  organization_unit_id: Yup.number().when('recipient', {
    is: 'organization_unit_leader',
    then: Yup.number().typeError('Required').required('Required'),
    otherwise: Yup.number().nullable(),
  }),
  rule_name: Yup.string().required('Required').notOneOf(existingRuleNames, 'Must be unique'),
  title: Yup.string().max(100),
  content: Yup.string().max(250),
  sub_organization_ids: subOrgsRequired ? Yup.array().min(1, 'Required') : Yup.array(),
  conditions: Yup.array(
    Yup.object().shape({
      criteria_key: Yup.string().required('Required'),
      criteria_types: Yup.array().when('criteria_key', (criteriaKey, currSchema) => {
        if (criteriaKey && conditions[criteriaKey] && conditions[criteriaKey].has_type) {
          return currSchema.min(1, 'At least one type is required');
        }
        return currSchema;
      }),
      condition_key: Yup.string()
        .when('criteria_key', (criteriaKey, currSchema) => {
          if (criteriaKey && conditions[criteriaKey].conditions.length) {
            return currSchema.required('Required');
          }
          return currSchema;
        })
        .nullable(),
      condition_text_value: Yup.string()
        .when(['criteria_key', 'condition_key'], (criteriaKey, conditionKey, currSchema) => {
          if (criteriaKey && conditions[criteriaKey] && conditions[criteriaKey].conditions.length) {
            const conditionsWithGroupAttributes = getConditionsWithGroupForSelectedCriteria(conditions[criteriaKey]);
            if (conditionsWithGroupAttributes[conditionKey]?.additionalValueType === 'text') {
              return currSchema.required('Required');
            }
          }
          return currSchema;
        })
        .nullable(),
      condition_numeric_value: Yup.number()
        .when(['criteria_key', 'condition_key'], (criteriaKey, conditionKey, currSchema) => {
          if (criteriaKey && conditions[criteriaKey] && conditions[criteriaKey].conditions.length) {
            const conditionsWithGroupAttributes = getConditionsWithGroupForSelectedCriteria(conditions[criteriaKey]);
            if (conditionsWithGroupAttributes[conditionKey]?.additionalValueType === 'numeric') {
              return currSchema.required('Required');
            }
          }
          return currSchema;
        })
        .nullable(),
      condition_schedule_value: Yup.number()
        .when(['criteria_key', 'condition_key'], (criteriaKey, conditionKey, currSchema) => {
          if (criteriaKey && conditions[criteriaKey] && conditions[criteriaKey].conditions.length) {
            const conditionsWithGroupAttributes = getConditionsWithGroupForSelectedCriteria(conditions[criteriaKey]);
            if (conditionsWithGroupAttributes[conditionKey]?.additionalValueType === 'schedule') {
              return currSchema.required('Required');
            }
          }
          return currSchema;
        })
        .nullable(),
      condition_schedule_timeframe_value: Yup.string()
        .when(['criteria_key', 'condition_key'], (criteriaKey, conditionKey, currSchema) => {
          if (criteriaKey && conditions[criteriaKey] && conditions[criteriaKey].conditions.length) {
            const conditionsWithGroupAttributes = getConditionsWithGroupForSelectedCriteria(conditions[criteriaKey]);
            if (conditionsWithGroupAttributes[conditionKey]?.additionalValueType === 'schedule') {
              return currSchema.required('Required');
            }
          }
          return currSchema;
        })
        .nullable(),
    })
  ).when('event_key', (eventKey, currSchema) => {
    if (eventKey && eventKey.additional_conditions_are_mandatory) {
      return currSchema.min(1, 'At least one additional condition must be added');
    }
    return currSchema;
  }),
  ...statesValidationSchema,
});

export const mapToKeyAndFilterAll = (list) => list.map((type) => type.key).filter((typeKey) => typeKey !== 'all');

export const convertFormikValuesToServerValues = (values, organization) => {
  const is_all_sub_orgs = values.sub_organization_ids.indexOf('all') > -1 || !organization.sub_organizations_enabled;
  return {
    ...values,
    event_key: values.event_key.key,
    event_criteria_types: mapToKeyAndFilterAll(values.event_criteria_types),
    is_all_sub_orgs,
    sub_organization_ids: is_all_sub_orgs ? [] : values.sub_organization_ids,
    scope_types: mapToKeyAndFilterAll(values.scope_types),
    is_immediate: values.schedule === 'immediate',
    conditions: values.conditions.map((condition) => ({
      ...condition,
      criteria_types: mapToKeyAndFilterAll(condition.criteria_types),
    })),
  };
};

export const convertServerConditionToFormikValues = (condition, conditions) => {
  const criteriaTypesWithoutAll = splitListAndFindObjects(
    condition.criteria_types,
    conditions[condition.criteria_key].types
  );

  return {
    ...condition,
    criteria_types: criteriaTypesWithoutAll.length ? criteriaTypesWithoutAll : [ALL_OPTION],
  };
};

export const splitListAndFindObjects = (joinedKeys, objectsList) => {
  const keysList = joinedKeys ? joinedKeys.split(', ') : [];
  return keysList.map((key) => objectsList.find((object) => object.key.trim() === key.trim()));
};

export const convertServerRuleToFormikValues = (rule, conditions, events, incidentTypesMap, coveragesMap) => {
  const scopeTypesWithoutAll =
    rule.scope_types.length &&
    rule.scope_types.split(', ').map((type) => ({
      key: type,
      desc: getScopeTypeDescription(type, rule.scope, incidentTypesMap, coveragesMap),
    }));
  const selectedEventConfig = events.find((event) => rule.event_key && event.key === rule.event_key) || {};
  const eventCriteriaTypesWithoutAll = splitListAndFindObjects(rule.event_criteria_types, selectedEventConfig.types);

  return {
    ...rule,
    event_key: events.find((event) => event.key === rule.event_key),
    sub_organization_ids: rule.is_all_sub_orgs ? ['all'] : rule.sub_organization_ids,
    scope_types: scopeTypesWithoutAll.length ? scopeTypesWithoutAll : [ALL_OPTION],
    event_criteria_types: eventCriteriaTypesWithoutAll.length ? eventCriteriaTypesWithoutAll : [ALL_OPTION],
    schedule: rule.is_immediate ? 'immediate' : 'custom',
    conditions: rule.conditions.map((condition) => convertServerConditionToFormikValues(condition, conditions)),
    organization_unit_id: rule.organization_unit_id,
  };
};

export const CONDITION_FIELD_NAME = 'conditions';

export const BASE_CONDITION_FIELD = {
  criteria_key: '',
  criteria_types: [ALL_OPTION],
  condition_key: null,
  condition_text_value: null,
  condition_numeric_value: null,
  condition_schedule_value: null,
  condition_schedule_timeframe_value: null,
};

export const getConditionsWithGroupForSelectedCriteria = (selectedCriteria) =>
  selectedCriteria?.conditions?.reduce((merged, conditionsGroup) => {
    return {
      ...merged,
      ..._.mapValues(conditionsGroup.options, (option) => ({
        ...option,
        additionalValueType: conditionsGroup.condition_value_type,
        textValues: conditionsGroup.text_condition_values,
      })),
    };
  }, {}) || {};

export const joinDescriptionsOfObjectsList = (objectsList) => objectsList.map((item) => item.desc).join(', ');

export const STATES_SELECT_OPTIONS = Object.entries(COUNTRY_TO_STATE_MAP.US)
  .filter(([key]) => Boolean(key))
  .map(([key, value]) => ({ value: key, label: value, id: key }))
  .sort((a, b) => a.label.localeCompare(b.label));
