import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { Chip } from '@mui/material';
import axios from 'axios';
import { Formik, getIn, useFormikContext } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Tooltip from '~/components/core/Atomic/Tooltip';
import Typography from '~/components/core/Atomic/Typography';
import CancelButton from '~/components/core/Buttons/CancelButton';
import ThreeDotsMenu from '~/components/core/ThreeDotsMenu';
import { AddIcon } from '~/components/deprecatedMuiIcons';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';

import { ASSIGNMENTS_MIXPANEL_EVENTS } from '../../../../pocs/mixpanel';
import {
  ASSIGNMENT_CONDITION_KEY_DICT,
  ASSIGNMENT_CONDITION_OPERATOR_DICT,
  ASSIGNMENT_RULE_ENTITY_DICT,
  ASSIGNMENT_RULE_METHOD_DICT,
  CONFIGURATION_FEATURES_NAMES,
} from '../../../../Types';
import { isFeatureEnabled, reportAxiosError, stringCmp, subOrgIdToNameDict } from '../../../../Utils';
import { getLobDescription } from '../../../../Utils/lobUtils';
import CardDialog from '../../../CardDialog';
import mixpanel from '../../../CmsMain/mixpanel';
import WithConfirm from '../../../ConfirmModal';
import { Heading, LoadingSwitch, SortableTable } from '../../../core';
import InnerTabs from '../../../core/Layout/InnerTabs/InnerTabs';
import {
  ArrowDownwardIcon,
  ArrowUpwardIcon,
  InfoIcon,
  PencilIcon,
  RemoveIcon,
  TrashIcon_Deprecated,
} from '../../../icons';
import InlineIconButton from '../../../InlineIconButton';
import LoadingIndicator from '../../../LoadingIndicator';
import OverflowTextWithToolTip from '../../../OverflowTextWithToolTip';
import { MultiSelectTextFieldFormik, TextFieldFormik } from '../../../TextFieldFormik';
import LobRadioFormik from '../../../TPA/LOB/LobRadioFormik';
import SubOrganizationMultiselectFormik from '../../../TPA/SubOrganizations/SubOrganizationMultiselectFormik';
import useDataFetcher from '../../../useDataFetcher';
import useAssignments, { AssignmentsContextProvider } from '../../AssignmentsContext';
import OperationsBreadcrumbs from '../../OperationsBreadcrumbs';
import { useSysconfig } from '../../SystemConfigurationScreen';

import { AssignmentDefaultsControls } from './AssignmentDefaultsControls';

import { useStyles } from '../../../../assets/styles';
import styles from './AssignmentsTab.module.scss';

const EnableAssignmentsButton = ({ onAssignmentsEnabled }) => {
  const { organization } = useSysconfig();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const enableAssignments = async () => {
    try {
      setIsSubmitting(true);
      await axios.post(`/api/v1/assignments/${organization.id}/enable`);
      await onAssignmentsEnabled();
    } catch (error) {
      await reportAxiosError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Button
      color="primary"
      variant="contained"
      style={{ marginLeft: 24 }}
      disabled={isSubmitting}
      onClick={enableAssignments}
    >
      Enable Assignments
    </Button>
  );
};

EnableAssignmentsButton.propTypes = {
  onAssignmentsEnabled: PropTypes.func.isRequired,
};

const AssignmentsTab = () => {
  const { organization } = useSysconfig();

  const Header = () => (
    <>
      {!isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.AUTOMATIC_EXPOSURES_RULES) && (
        <>
          <OperationsBreadcrumbs currentTab="Assignment Configuration" />
          <Heading variant={Heading.TYPES.H2}>General Assignments Configuration</Heading>
        </>
      )}
    </>
  );

  return (
    <>
      <CardDialog noCardTitle>
        <Header />
        <AssignmentsContextProvider>
          <AssignmentsTabInner />
        </AssignmentsContextProvider>
      </CardDialog>
    </>
  );
};

const AssignmentsTabInner = () => {
  const { areAssignmentsEnabled, isLoading, isError, reloadDefaults, reloadEnabled } = useAssignments();

  const tabs = [
    { label: 'Rules', url: 'rules', component: AssignmentRulesTab },
    { label: 'Groups', url: 'groups', component: AssignmentGroupsTab },
  ];

  const handleAssignmentsEnabled = async () => {
    try {
      await reloadDefaults();
      await reloadEnabled();
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  return (
    <LoadingSwitch isLoading={isLoading} isError={isError}>
      <>
        {!areAssignmentsEnabled ? <EnableAssignmentsButton onAssignmentsEnabled={handleAssignmentsEnabled} /> : null}
        {areAssignmentsEnabled ? (
          <>
            <AssignmentDefaultsControls />
            <InnerTabs tabs={tabs} />
          </>
        ) : null}
      </>
    </LoadingSwitch>
  );
};

const AssignmentRulesTab = () => {
  const { organization } = useSysconfig();
  const classes = useStyles();
  const {
    isLoading,
    isError,
    data: rules,
    reloadData: reloadRules,
  } = useDataFetcher(`/api/v1/assignments/${organization.id}/rules`);
  const [ruleDialogOpen, setRuleDialogOpen] = useState(false);
  const [ruleToEdit, setRuleToEdit] = useState(null);
  const { lobConfigurationsDict } = useLobConfiguration();

  if (isLoading || isError) {
    return <LoadingIndicator isError={isError} />;
  }

  const getMixpanelEventParams = (values) => {
    return {
      rule_name: values?.display_name,
      sub_organizations: values?.sub_organization_ids,
      lob: values?.lob,
      entity: values?.entity,
      method: values?.method,
      assignee: values?.assignee,
    };
  };

  const addRule = () => {
    setRuleDialogOpen(true);
    mixpanel.track(ASSIGNMENTS_MIXPANEL_EVENTS.ADD_ASSIGNMENT_RULE_CLICKED);
  };

  const handleEditRule = (rule) => {
    setRuleToEdit(rule);
    setRuleDialogOpen(true);

    mixpanel.track(ASSIGNMENTS_MIXPANEL_EVENTS.EDIT_ASSIGNMENT_RULE_CLICKED, getMixpanelEventParams(rule));
  };

  const handleDeleteRuleClicked = (rule) => {
    mixpanel.track(ASSIGNMENTS_MIXPANEL_EVENTS.DELETE_ASSIGNMENT_RULE_CLICKED, getMixpanelEventParams(rule));
  };

  const onClose = () => {
    setRuleToEdit(null);
    setRuleDialogOpen(false);
  };

  const callAPI = async (method, url, values) => {
    await axios({ method, url, data: values });
    await reloadRules();
  };

  const callAPIWithCatch = async (method, url, values) => {
    try {
      await callAPI(method, url, values);
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const handleDeleteRule = async (rule) => {
    await callAPIWithCatch('delete', `/api/v1/assignments/${organization.id}/rules/${rule.id}`);

    mixpanel.track(ASSIGNMENTS_MIXPANEL_EVENTS.ASSIGNMENT_RULE_DELETED, getMixpanelEventParams(rule));
  };

  const handleMoveRuleUp = async (rule) => {
    await callAPIWithCatch('post', `/api/v1/assignments/${organization.id}/rules/${rule.id}/up`);

    mixpanel.track(ASSIGNMENTS_MIXPANEL_EVENTS.ASSIGNMENT_RULE_MOVED_UP_IN_ORDER, getMixpanelEventParams(rule));
  };

  const handleMoveRuleDown = async (rule) => {
    await callAPIWithCatch('post', `/api/v1/assignments/${organization.id}/rules/${rule.id}/down`);

    mixpanel.track(ASSIGNMENTS_MIXPANEL_EVENTS.ASSIGNMENT_RULE_MOVED_DOWN_ORDER, getMixpanelEventParams(rule));
  };

  const handleCreateRule = async (values) => {
    await callAPI('post', `/api/v1/assignments/${organization.id}/rules`, values);

    mixpanel.track(ASSIGNMENTS_MIXPANEL_EVENTS.ASSIGNMENT_RULE_ADDED, getMixpanelEventParams(values));
  };

  const onUpdateRule = async (values, id) => {
    await callAPI('put', `/api/v1/assignments/${organization.id}/rules/${id}`, values);

    mixpanel.track(ASSIGNMENTS_MIXPANEL_EVENTS.ASSIGNMENT_RULE_EDITED, getMixpanelEventParams(values));
  };

  const actionOptions = (rule) => [
    {
      onClick: () => handleEditRule(rule),
      node: 'Edit',
      key: 'editRule',
    },
    {
      onClick: () => handleDeleteRule(rule),
      withConfirmProps: {
        title: 'Delete Rule?',
        contentText: `Are you sure you want to delete "${rule.display_name}"?`,
        primaryButtonName: 'Delete',
      },
      onOpenConfirmModal: () => handleDeleteRuleClicked(rule),
      disabled: false,
      node: 'Delete',
      key: 'deleteRule',
    },
  ];

  const columnData = [
    { id: 'display_name', label: 'Name' },
    { id: 'lob', label: 'Line of Business', specialCell: (rule) => getLobDescription(rule.lob, lobConfigurationsDict) },
    { id: 'entity', label: 'Entity', specialCell: (rule) => ASSIGNMENT_RULE_ENTITY_DICT[rule.entity]['desc'] },
    { id: 'method', label: 'Method', specialCell: (rule) => ASSIGNMENT_RULE_METHOD_DICT[rule.method]['desc'] },
    { id: 'assignee_desc', label: 'Assignee' },
    {
      id: 'actions',
      disableSort: true,
      label: 'Actions',
      width: '90px',
      specialCell: (rule) => (
        <div className={classes.containerCentered}>
          <span style={{ marginRight: 10 }}>
            <InlineIconButton
              icon={ArrowUpwardIcon}
              className={classes.hoverableIcon}
              onClick={() => handleMoveRuleUp(rule)}
              disabled={rule.id === rules[0].id}
            />
          </span>
          <InlineIconButton
            icon={ArrowDownwardIcon}
            className={classes.hoverableIcon}
            onClick={() => handleMoveRuleDown(rule)}
            disabled={rule.id === rules[rules.length - 1].id}
          />
          <ThreeDotsMenu options={actionOptions(rule)} />
        </div>
      ),
    },
  ];

  if (organization.sub_organizations_enabled) {
    columnData.splice(1, 0, {
      id: 'sub_organization_ids',
      label: 'Sub Organizations',
      specialCell: (rule) => {
        if (rule.is_all_sub_orgs) {
          return 'All';
        }

        const subOrgIdToName = subOrgIdToNameDict(organization);
        return rule.sub_organization_ids.map((subOrgId) => subOrgIdToName[subOrgId]).join(', ');
      },
    });
  }

  return (
    <Grid container direction="column">
      <div className={styles.tableBanner}>
        <Grid container direction="row" justify="space-between">
          <Grid item>
            <span style={{ display: 'flex' }}>
              <InfoIcon size={20} iconColor="#606060" />
              <Typography variant="body1" style={{ color: '#606060', paddingLeft: 5 }}>
                Rules will be applied in the order that they are presented{' '}
              </Typography>
            </span>
          </Grid>
          <Grid item>
            <Button color="primary" onClick={addRule}>
              <AddIcon />
              Add Rule
            </Button>
          </Grid>
        </Grid>
      </div>
      <Grid item xs={12}>
        <SortableTable columns={columnData} rows={rules} disableSortByUser keepRowsOrder />
      </Grid>
      {ruleDialogOpen && (
        <EditAssignmentRuleDialog
          onSubmit={ruleToEdit ? onUpdateRule : handleCreateRule}
          onClose={onClose}
          rule={ruleToEdit}
          existingRuleNames={rules.filter((rule) => rule.id !== ruleToEdit?.id).map((rule) => rule.display_name)}
        />
      )}
    </Grid>
  );
};

const EditAssignmentRuleDialog = ({ rule, onSubmit, onClose, existingRuleNames = [] }) => {
  const classes = useStyles();

  const {
    organization,
    organizationOperationalDetails: { supportedClaimTypes },
  } = useSysconfig();

  return (
    <Formik
      initialValues={{
        id: rule?.id || '',
        display_name: rule?.display_name || '',
        is_all_sub_orgs: rule?.is_all_sub_orgs || false,
        sub_organization_ids: rule?.sub_organization_ids || [],
        lob: rule?.lob || '',
        entity: rule?.entity || '',
        method: rule?.method || '',
        assignee: rule?.assignee || '',
        conditions: rule?.conditions || [],
      }}
      validationSchema={Yup.object().shape({
        display_name: Yup.string().required('Required').notOneOf(existingRuleNames, 'Must be unique'),
        is_all_sub_orgs: Yup.bool(),
        sub_organization_ids: Yup.array(),
        lob: Yup.string().required('Required').oneOf(supportedClaimTypes, 'Invalid line of business'),
        entity: Yup.string().required('Required').oneOf(Object.keys(ASSIGNMENT_RULE_ENTITY_DICT), 'Invalid entity'),
        method: Yup.string().required('Required').oneOf(Object.keys(ASSIGNMENT_RULE_METHOD_DICT), 'Invalid method'),
        assignee: Yup.mixed().required('Required'),
        conditions: Yup.array().of(
          Yup.object().shape({
            key: Yup.string().required('Required').oneOf(Object.keys(ASSIGNMENT_CONDITION_KEY_DICT), 'Invalid type'),
            operator: Yup.string()
              .required('Required')
              .when('key', {
                is: (k) => ASSIGNMENT_CONDITION_KEY_DICT[k]?.is_multi_key_value,
                then: Yup.string().oneOf(
                  Object.keys(ASSIGNMENT_CONDITION_OPERATOR_DICT).filter(
                    (o) => ASSIGNMENT_CONDITION_OPERATOR_DICT[o].is_multi_key_value
                  ),
                  'Invalid type'
                ),
                otherwise: Yup.string().oneOf(
                  Object.keys(ASSIGNMENT_CONDITION_OPERATOR_DICT).filter(
                    (o) => !ASSIGNMENT_CONDITION_OPERATOR_DICT[o].is_multi_key_value
                  ),
                  'Invalid type'
                ),
              }),
            value: Yup.string().when('operator', {
              is: (o) => !ASSIGNMENT_CONDITION_OPERATOR_DICT[o]?.is_multi_value,
              then: Yup.string().required('Required'),
              otherwise: Yup.string().strip(),
            }),
            values: Yup.array().when('operator', {
              is: (o) => ASSIGNMENT_CONDITION_OPERATOR_DICT[o]?.is_multi_value,
              then: Yup.array().of(Yup.string()).min(1, 'Required').required('Required'),
              otherwise: Yup.array().strip(),
            }),
          })
        ),
      })}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        try {
          await onSubmit(values, rule?.id);
          onClose();
        } catch (error) {
          await reportAxiosError(error);
          formikProps.setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit, values } = formikProps;

        return (
          <CardDialog
            isDialog
            title={rule ? 'Edit Rule' : 'Add Rule'}
            fullWidth
            maxWidth="sm"
            onClose={onClose}
            preventClose={isSubmitting}
          >
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <div>
                  <TextFieldFormik id="display_name" label="Rule Name" className={classes.formTextField} fullWidth />
                </div>
              </Grid>
              <Grid item xs={6} />
              {organization.sub_organizations_enabled && (
                <>
                  <Grid item xs={6} style={{ marginBottom: 24 }}>
                    <SubOrganizationMultiselectFormik
                      subOrganizationsFieldId="sub_organization_ids"
                      allSelectedFieldId="is_all_sub_orgs"
                      selectAllLabel="All (inc. future sub-orgs)"
                      showAllInRenderedSelectedOptions
                      shouldDisplayAllOption
                    />
                  </Grid>
                  <Grid item xs={6} />
                </>
              )}
              <Grid item xs={8}>
                <div style={{ marginBottom: 24 }}>
                  <Typography
                    display="block"
                    variant="subtitle2"
                    style={{ marginBottom: 10, fontWeight: 'bold', color: '#202020' }}
                  >
                    Line of Business
                  </Typography>
                  <LobRadioFormik
                    lobFieldId="lob"
                    subOrganizationIds={
                      values.sub_organization_ids.length
                        ? values.sub_organization_ids
                        : organization.sub_organizations.map((so) => so.id)
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={6}>
                <div>
                  <TextFieldFormik id="entity" label="Entity" className={classes.formTextField} fullWidth select>
                    {Object.keys(ASSIGNMENT_RULE_ENTITY_DICT).map((entity) => (
                      <MenuItem key={entity} value={entity}>
                        {ASSIGNMENT_RULE_ENTITY_DICT[entity]['desc']}
                      </MenuItem>
                    ))}
                  </TextFieldFormik>
                </div>
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <div>
                  <TextFieldFormik id="method" label="Method" className={classes.formTextField} fullWidth select>
                    {Object.keys(ASSIGNMENT_RULE_METHOD_DICT).map((method) => (
                      <MenuItem key={method} value={method}>
                        {ASSIGNMENT_RULE_METHOD_DICT[method]['desc']}
                      </MenuItem>
                    ))}
                  </TextFieldFormik>
                </div>
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={12}>
                <RuleConditions id={values.id} lob={values.lob} entity={values.entity} />
              </Grid>
              <Grid item xs={6}>
                <div>
                  <RuleAssigneeSelect id={values.id} entity={values.entity} method={values.method} />
                </div>
              </Grid>
              <Grid item xs={6} />
            </Grid>
            <div className={classes.buttonsContainer}>
              <CancelButton disabled={isSubmitting} onClick={onClose} />
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

EditAssignmentRuleDialog.propTypes = {
  rule: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  existingRuleNames: PropTypes.array,
};

const RuleConditions = ({ id, lob, entity }) => {
  const { values, setFieldValue } = useFormikContext();
  const { organization } = useSysconfig();
  const [options, setOptions] = useState({});
  const [isLoadingOptions, setIsLoadingOptions] = useState(!!id);

  useEffect(() => {
    (async () => {
      if (lob && entity) {
        setIsLoadingOptions(true);
        try {
          const params = {
            is_all_sub_orgs: getIn(values, 'is_all_sub_orgs'),
            sub_org_ids: getIn(values, 'sub_organization_ids'),
            lob,
            entity,
          };
          const { data } = await axios.get(`/api/v1/assignments/${organization.id}/condition_options`, { params });
          setOptions(data);
        } catch (error) {
          await reportAxiosError(error);
        } finally {
          setIsLoadingOptions(false);
        }
      }
    })();
  }, [organization.id, lob, entity, values]);

  useEffect(() => {
    if (!lob && values.conditions.length) {
      setFieldValue('conditions', []);
    }
  }, [lob, setFieldValue, values.conditions.length]);

  const addCondition = () => {
    setFieldValue('conditions', [...values.conditions, { key: '', operator: '', value: '', values: [] }]);
  };

  const removeCondition = (index) => {
    const conditions = values.conditions;
    setFieldValue('conditions', [...conditions.slice(0, index), ...conditions.slice(index + 1, conditions.length)]);
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Grid container spacing={0}>
          <Typography display="block" variant="subtitle2" style={{ fontWeight: 'bold', color: '#202020' }}>
            Conditions
          </Typography>
          {(Object.keys(options).length > 0 || (values.conditions.length > 0 && !isLoadingOptions)) &&
            values.conditions.map((condition, index) => (
              <Grid key={`condition_${index}`} item xs={12}>
                <RuleCondition
                  conditionId={`conditions[${index}]`}
                  lob={lob}
                  options={options}
                  isLoadingOptions={isLoadingOptions}
                  onRemoveCondition={() => removeCondition(index)}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button
          color="primary"
          onClick={addCondition}
          disabled={!lob || !entity || !Object.keys(options).length}
          style={{ marginLeft: -12 }}
        >
          <AddIcon />
          Add Condition
        </Button>
      </Grid>
    </Grid>
  );
};

RuleConditions.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lob: PropTypes.string,
  entity: PropTypes.string,
};

const ConditionValuesSelect = ({ options, isLoadingOptions, conditionKey, conditionOperator, conditionPrefix }) => {
  const classes = useStyles();
  const conditionValuesDict = options[conditionKey] || {};

  const props = {
    label: 'Value(s)',
    className: classes.formTextField,
    fullWidth: true,
    disabled: !conditionKey || !conditionOperator || isLoadingOptions || !Object.keys(conditionValuesDict).length,
  };
  if (ASSIGNMENT_CONDITION_OPERATOR_DICT[conditionOperator]?.is_multi_value) {
    const displayValueFunc = (key) => conditionValuesDict[key]?.desc;
    return (
      <MultiSelectTextFieldFormik
        id={`${conditionPrefix}.values`}
        {...props}
        options={Object.keys(conditionValuesDict)}
        renderValue={(selected) => selected.map((key) => displayValueFunc(key)).join(', ')}
        renderOption={displayValueFunc}
        sortAlphabetic
      />
    );
  }

  return (
    <TextFieldFormik id={`${conditionPrefix}.value`} {...props} select>
      {Object.keys(conditionValuesDict)
        .sort((option1, option2) => stringCmp(conditionValuesDict[option1].desc, conditionValuesDict[option2].desc))
        .map((key) => (
          <MenuItem key={key} value={key}>
            {conditionValuesDict[key].desc}
          </MenuItem>
        ))}
    </TextFieldFormik>
  );
};

ConditionValuesSelect.propTypes = {
  options: PropTypes.object.isRequired,
  isLoadingOptions: PropTypes.bool.isRequired,
  conditionKey: PropTypes.string,
  conditionOperator: PropTypes.string,
  conditionPrefix: PropTypes.string.isRequired,
};

const RuleCondition = ({ lob, conditionId, options, isLoadingOptions, onRemoveCondition }) => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const condition = getIn(values, conditionId);

  useEffect(() => {
    const key = condition.key;

    if (!condition.key || (!condition.value && !condition.values)) {
      return;
    }

    if (condition.key && !Object.keys(options).includes(key)) {
      setFieldValue(conditionId, { key: '', operator: '', value: '', values: [] });
    } else {
      if (condition.value && !Object.keys(options[key]).includes(condition.value)) {
        setFieldValue(`${conditionId}.value`, '');
      } else if (condition.values && !condition.values.every((value) => Object.keys(options[key]).includes(value))) {
        setFieldValue(`${conditionId}.values`, []);
      }
    }
  }, [condition.key, condition.value, condition.values, conditionId, options, setFieldValue]);

  useEffect(() => {
    if (!condition.operator || (!condition.value && !condition.values)) {
      return;
    }

    if (ASSIGNMENT_CONDITION_OPERATOR_DICT[condition.operator].is_multi_value && condition.value) {
      setFieldValue(`${conditionId}.value`, '');
    } else if (!ASSIGNMENT_CONDITION_OPERATOR_DICT[condition.operator].is_multi_value && condition.values.length > 0) {
      setFieldValue(`${conditionId}.values`, []);
    }
  }, [condition.operator, condition.value, condition.values, conditionId, setFieldValue]);

  useEffect(() => {
    if (!condition.key || !condition.operator) {
      return;
    }

    if (
      ASSIGNMENT_CONDITION_KEY_DICT[condition.key].is_multi_key_value !==
      ASSIGNMENT_CONDITION_OPERATOR_DICT[condition.operator].is_multi_key_value
    ) {
      setFieldValue(`${conditionId}.operator`, '');
    }
  }, [condition.key, condition.operator, conditionId, setFieldValue]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <TextFieldFormik
          id={`${conditionId}.key`}
          label="Type"
          className={classes.formTextField}
          fullWidth
          select
          disabled={isLoadingOptions}
        >
          {Object.keys(ASSIGNMENT_CONDITION_KEY_DICT).map((key) =>
            options[key] ? (
              <MenuItem key={key} value={key}>
                {ASSIGNMENT_CONDITION_KEY_DICT[key]?.lob_desc?.[lob] || ASSIGNMENT_CONDITION_KEY_DICT[key].desc}
              </MenuItem>
            ) : null
          )}
        </TextFieldFormik>
      </Grid>
      <Grid item xs={2}>
        <TextFieldFormik
          id={`${conditionId}.operator`}
          label="Operator"
          className={classes.formTextField}
          fullWidth
          select
          disabled={isLoadingOptions || !condition.key}
        >
          {Object.keys(ASSIGNMENT_CONDITION_OPERATOR_DICT)
            .filter(
              (o) =>
                condition.key &&
                ASSIGNMENT_CONDITION_OPERATOR_DICT[o].is_multi_key_value ===
                  ASSIGNMENT_CONDITION_KEY_DICT[condition.key].is_multi_key_value
            )
            .map((o) => (
              <MenuItem key={o} value={o}>
                {ASSIGNMENT_CONDITION_OPERATOR_DICT[o]['desc']}
              </MenuItem>
            ))}
        </TextFieldFormik>
      </Grid>
      <Grid item xs={5}>
        <ConditionValuesSelect
          conditionPrefix={conditionId}
          conditionKey={condition.key}
          conditionOperator={condition.operator}
          options={options}
          isLoadingOptions={isLoadingOptions}
        />
      </Grid>
      <Grid item xs={1}>
        <div style={{ marginTop: 25 }}>
          <InlineIconButton icon={TrashIcon_Deprecated} className={classes.textIcon} onClick={onRemoveCondition} />
        </div>
      </Grid>
    </Grid>
  );
};

RuleCondition.propTypes = {
  options: PropTypes.object.isRequired,
  isLoadingOptions: PropTypes.bool.isRequired,
  conditionId: PropTypes.string.isRequired,
  lob: PropTypes.string.isRequired,
  onRemoveCondition: PropTypes.func.isRequired,
};

const RuleAssigneeSelect = ({ id, method, entity }) => {
  const LABEL = 'Assignee';
  const classes = useStyles();
  const { organization } = useSysconfig();
  const { values, setFieldValue } = useFormikContext();
  const [previousParams, setPreviousParams] = useState(null);
  const [assigneeOptions, setAssigneeOptions] = useState([]);
  const [isLoadingAssigneeOptions, setIsLoadingAssigneeOptions] = useState(false);
  const [initialLoadOptionsDone, setInitialLoadOptionsDone] = useState(false);

  useEffect(() => {
    (async () => {
      const params = {
        method,
        entity,
      };
      if (method && entity && JSON.stringify(params) !== JSON.stringify(previousParams)) {
        setIsLoadingAssigneeOptions(true);
        try {
          const { data } = await axios.get(`/api/v1/assignments/${organization.id}/rules/assignee_options`, {
            params,
          });

          setPreviousParams(params);

          if (values.assignee && !data.find((o) => o.key === values.assignee)) {
            setFieldValue('assignee', '');
          }

          setAssigneeOptions(data);
          setInitialLoadOptionsDone(true);
        } catch (error) {
          await reportAxiosError(error);
        } finally {
          setIsLoadingAssigneeOptions(false);
        }
      }
    })();
  }, [organization.id, method, entity, values.assignee, setFieldValue, previousParams]);

  const props = {
    label: LABEL,
    className: classes.formTextField,
    fullWidth: true,
  };

  if (id && !initialLoadOptionsDone) {
    // Wait for the loaded results - otherwise there'll be an error that the value is not an option
    return <TextField {...props} disabled />;
  }

  return (
    <>
      <TextFieldFormik
        id="assignee"
        {...props}
        select
        disabled={isLoadingAssigneeOptions || !assigneeOptions.length || !entity || !method}
        className={classes.formTextFieldNoErrorSpacing}
      >
        {assigneeOptions.map((o) => (
          <MenuItem key={o.key} value={o.key}>
            {o.display_name}
          </MenuItem>
        ))}
      </TextFieldFormik>
      {entity && method === 'round_robin' && assigneeOptions.length === 0 && (
        <Typography className={classes.formUnderInputErrorText}>
          Please create a group to choose an assignee for round robin method
        </Typography>
      )}
    </>
  );
};

RuleAssigneeSelect.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  entity: PropTypes.string,
  method: PropTypes.string,
};

const AssignmentGroupsTab = () => {
  const { organization } = useSysconfig();
  const classes = useStyles();
  const {
    isLoading,
    isError,
    data: groups,
    reloadData: reloadGroups,
  } = useDataFetcher(`/api/v1/assignments/${organization.id}/groups`);
  const [groupDialogOpen, setGroupDialogOpen] = useState(false);
  const [groupToEdit, setGroupToEdit] = useState(null);

  if (isLoading || isError) {
    return <LoadingIndicator isError={isError} />;
  }

  const getMixpanelEventParams = (values) => {
    return {
      group_name: values?.display_name,
      users: values?.user_ids,
    };
  };

  const addGroup = () => {
    setGroupDialogOpen(true);

    mixpanel.track(ASSIGNMENTS_MIXPANEL_EVENTS.ADD_ASSIGNMENT_GROUP_CLICKED);
  };

  const editGroup = (group) => {
    setGroupToEdit(group);
    setGroupDialogOpen(true);

    mixpanel.track(ASSIGNMENTS_MIXPANEL_EVENTS.EDIT_ASSIGNMENT_GROUP_CLICKED, getMixpanelEventParams(group));
  };

  const onClose = () => {
    setGroupToEdit(null);
    setGroupDialogOpen(false);
  };

  const onDeleteGroup = async (group) => {
    try {
      await axios.delete(`/api/v1/assignments/${organization.id}/groups/${group.id}`, { data: { deleteRules: true } });

      mixpanel.track(ASSIGNMENTS_MIXPANEL_EVENTS.ASSIGNMENT_GROUP_DELETED, getMixpanelEventParams(group));
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const onCreateGroup = async (values) => {
    await axios.post(`/api/v1/assignments/${organization.id}/groups`, values);
    await reloadGroups();

    mixpanel.track(ASSIGNMENTS_MIXPANEL_EVENTS.ASSIGNMENT_GROUP_ADDED, getMixpanelEventParams(values));
  };

  const onUpdateGroup = async (values, id) => {
    await axios.put(`/api/v1/assignments/${organization.id}/groups/${id}`, values);
    await reloadGroups();

    mixpanel.track(ASSIGNMENTS_MIXPANEL_EVENTS.ASSIGNMENT_GROUP_EDITED, getMixpanelEventParams(values));
  };

  const handleOpenDeleteConfirmModal = (group) => {
    mixpanel.track(ASSIGNMENTS_MIXPANEL_EVENTS.DELETE_ASSIGNMENT_GROUP_CLICKED, getMixpanelEventParams(group));
  };

  const columnData = [
    { id: 'display_name', label: 'Group Name' },
    {
      id: 'connected_rules',
      label: 'Connected Rules',
      specialCell: (group) => (
        <>
          {group.rule_names.length > 5 ? (
            <Tooltip title={group.rule_names.join(', ')}>
              <span>{group.rule_names.length} Rules</span>
            </Tooltip>
          ) : (
            group.rule_names.join(', ')
          )}
        </>
      ),
    },
    {
      id: 'actions',
      disableSort: true,
      label: 'Actions',
      width: '70px',
      specialCell: (group) => (
        <>
          <span style={{ marginRight: 10 }}>
            <InlineIconButton
              icon={PencilIcon}
              className={classes.hoverableNonFilledIcon}
              onClick={() => editGroup(group)}
            />
          </span>
          <WithConfirm
            title="Delete Group?"
            contentText={`Are you sure you want to delete "${group.display_name}"?${
              group.rule_names.length > 0 ? ' This action will delete all associated rules as well.' : ''
            }`}
            primaryButtonName="Delete"
            postOnClick={async () => await reloadGroups()}
            onOpenConfirmModal={() => handleOpenDeleteConfirmModal(group)}
          >
            <InlineIconButton
              icon={TrashIcon_Deprecated}
              className={classes.hoverableIcon}
              onClick={async () => await onDeleteGroup(group)}
            />
          </WithConfirm>
        </>
      ),
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Button color="primary" onClick={addGroup} style={{ float: 'right', marginBottom: 14 }}>
          <AddIcon />
          Add Group
        </Button>
      </Grid>
      <Grid item xs={12}>
        <SortableTable columns={columnData} rows={groups} />
      </Grid>
      {groupDialogOpen && (
        <EditAssignmentGroupDialog
          onSubmit={groupToEdit ? onUpdateGroup : onCreateGroup}
          onClose={onClose}
          group={groupToEdit}
          existingGroupNames={groups.filter((group) => group.id !== groupToEdit?.id).map((group) => group.display_name)}
        />
      )}
    </Grid>
  );
};

const EditAssignmentGroupDialog = ({ group, onSubmit, onClose, existingGroupNames = [] }) => {
  const classes = useStyles();

  const { adjustersDict } = useAssignments();

  return (
    <Formik
      initialValues={{
        display_name: group?.display_name || '',
        user_ids: group
          ? _.chain(group.user_ids)
              .sortBy([(user_id) => adjustersDict[user_id].username])
              .value()
          : [],
      }}
      validationSchema={Yup.object().shape({
        display_name: Yup.string().required('Required').notOneOf(existingGroupNames, 'Must be unique'),
        user_ids: Yup.array().required('Required').min(1, 'Required'),
      })}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        try {
          await onSubmit(values, group?.id);
          await onClose();
        } catch (error) {
          await reportAxiosError(error);
          formikProps.setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit, values, setFieldValue } = formikProps;

        const removeUser = (index) => {
          const ids = values.user_ids;
          setFieldValue('user_ids', [...ids.slice(0, index), ...ids.slice(index + 1, ids.length)]);
        };

        return (
          <CardDialog
            isDialog
            title={group ? 'Edit Group' : 'Add Group'}
            fullWidth
            maxWidth="xs"
            onClose={onClose}
            preventClose={isSubmitting}
          >
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <div>
                  <TextFieldFormik id="display_name" label="Group Name" className={classes.formTextField} fullWidth />
                </div>
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={8}>
                <div>
                  <MultiSelectTextFieldFormik
                    id="user_ids"
                    label="Users"
                    options={Object.keys(adjustersDict)}
                    renderValue={() => ''}
                    renderOption={(user_id) => adjustersDict[user_id].username}
                    className={classes.formTextFieldNoErrorSpacing}
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={12}>
                {values.user_ids.map((user_id, index) => (
                  <Chip
                    key={user_id}
                    className={classes.chip}
                    label={
                      <OverflowTextWithToolTip maxWidth="220px">
                        {adjustersDict[user_id].username}
                        <IconButton style={{ padding: 4, fontSize: 5 }} title="Clear" onClick={() => removeUser(index)}>
                          <RemoveIcon fontSize="small" />
                        </IconButton>
                      </OverflowTextWithToolTip>
                    }
                  />
                ))}
              </Grid>
            </Grid>
            <div className={classes.buttonsContainer}>
              <CancelButton disabled={isSubmitting} onClick={onClose} />
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

EditAssignmentGroupDialog.propTypes = {
  group: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  existingGroupNames: PropTypes.array,
};

export default AssignmentsTab;
