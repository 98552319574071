import { useEffect, useState } from 'react';

import { ROLES_TYPES_DICT } from '../../../../../Types';
import useDataFetcher from '../../../../useDataFetcher';
import { useSysconfig } from '../../../SystemConfigurationScreen';
import { LICENSE_EXPIRATION_STATUS } from '../consts';
import { getLicenseStatus } from '../utils';

export function useAdjustersData() {
  const { organization } = useSysconfig();
  const [adjusters, setAdjusters] = useState([]);

  const {
    isLoading,
    isError,
    data: rawUsers,
    reloadData: reloadAdjusters,
  } = useDataFetcher(`/sysconfig/api/v1/organizations/${organization.id}/users`, {
    params: { include_deactivated: true, include_licenses: true },
  });

  useEffect(() => {
    if (!rawUsers?.length) return;

    const enrichedAdjusters = rawUsers
      .filter((user) => ROLES_TYPES_DICT[user.role.role_type].is_adjuster)
      .map((adjuster) => {
        const { licenses } = adjuster;
        let expiredLicenses = 0;
        let expiringLicenses = 0;

        licenses.forEach((license) => {
          const state = getLicenseStatus(license.expiration_date);
          if (state === LICENSE_EXPIRATION_STATUS.EXPIRED) expiredLicenses++;
          else if (state === LICENSE_EXPIRATION_STATUS.EXPIRING) expiringLicenses++;
        });

        return { ...adjuster, expiredLicenses, expiringLicenses };
      });

    setAdjusters(enrichedAdjusters);
  }, [rawUsers]);

  return { adjusters, isLoading, isError, reloadAdjusters };
}
