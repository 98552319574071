import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik } from 'formik';
import { get } from 'lodash';

import mixpanel from '~/components/CmsMain/mixpanel';
import { FsIndexPanel, LoadingSwitch } from '~/components/core';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import useDataFetcher from '~/components/useDataFetcher';
import { FNOL_MIXPANEL_EVENTS } from '~/pocs/mixpanel';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled, reportAxiosError } from '~/Utils';

import SectionWrapper from '../../../Fnol/NewFnolUI/SectionWrapper';
import ConfigurationBreadcrumbs from '../../ConfigurationPage/ConfigurationBreadcrumbs';
import { setContactConfiguredFieldsKeysInValues } from '../../ConfigurationPage/utils';
import fnolConfigurationSchema from '../fnolConfigurationValidationSchema';
import setOnTheFlyFnolConfigurationMigrations, { removeStaticFields } from '../onTheFlyConfigurationMigrations';

import FnolPrompt from './FnolPrompt/FnolPrompt';
import Header from './Header/Header';
import { getLobSpecificAdditionalInfoSections } from './Sections/AdditionalInformationSections/LobSpecificSections';
import InvolvedDriverSection from './Sections/AutoInvolvedSections/InvolvedDriverSection';
import InvolvedNonMotoristSection from './Sections/AutoInvolvedSections/InvolvedNonMotoristSection';
import InvolvedPassengerSection from './Sections/AutoInvolvedSections/InvolvedPassengerSection';
import InvolvedPropertySection from './Sections/AutoInvolvedSections/InvolvedPropertySection';
import InvolvedVehicleSection from './Sections/AutoInvolvedSections/InvolvedVehicleSection';
import FilesSection from './Sections/FilesSection';
import GeneralDetailsSection from './Sections/GeneralDetailsSection';
import GeneralInvolvedPartySection from './Sections/GeneralInvolvedPartySection';
import IncidentDetailsSection from './Sections/IncidentDetailsSection';
import IncidentTypesAndSubTypesSection from './Sections/IncidentTypesAndSubTypesSection/IncidentTypesAndSubTypesSection';
import InvolvedPartiesSection from './Sections/InvolvedPartiesSection';
import WitnessesSection from './Sections/WitnessesSection';
import FnolConfigurationFooter from './FnolConfigurationFooter';

const getInvolvedPartiesSections = (fnolConfiguration, organization) => {
  const involvedEnabled = isFeatureEnabled(
    organization,
    CONFIGURATION_FEATURES_NAMES.FNOL_CONFIGURATION_INVOLVED_ENTITIES
  );

  if (!involvedEnabled) {
    return [
      {
        id: 'involved_parties',
        title: 'Involved Parties',
        node: <InvolvedPartiesSection />,
      },
    ];
  }

  if (fnolConfiguration?.lob === 'auto_claim') {
    return [
      {
        id: 'involved_parties',
        title: 'Involved Parties',
        isCollapsible: true,
        children: [
          {
            id: 'involved_vehicle',
            title: 'Involved Vehicle',
            node: <InvolvedVehicleSection />,
          },
          {
            id: 'involved_driver',
            title: 'Involved Driver',
            node: <InvolvedDriverSection />,
          },
          {
            id: 'involved_passenger',
            title: 'Involved Passenger',
            node: <InvolvedPassengerSection />,
          },
          {
            id: 'involved_non_motorist',
            title: 'Involved Non Motorist',
            node: <InvolvedNonMotoristSection />,
          },
          {
            id: 'involved_generic_property',
            title: 'Involved Property',
            node: <InvolvedPropertySection />,
          },
        ],
      },
    ];
  }

  const involvedParties = get(fnolConfiguration, 'configuration.involved_parties', {});
  const sortedInvolvedKeys = Object.keys(involvedParties)?.sort((a, b) =>
    involvedParties[a]?.is_first_party === involvedParties[b].is_first_party
      ? 0
      : involvedParties[a].is_first_party
      ? -1
      : 1
  );

  return [
    {
      id: 'involved_parties',
      title: 'Involved Parties',
      isCollapsible: true,
      children: sortedInvolvedKeys.map((partyKey) => {
        const party = involvedParties[partyKey];
        const titlePrefix = party?.is_first_party ? 'First Party' : 'Third Party';
        const title = `${titlePrefix} - ${party?.desc}`;
        return {
          id: partyKey,
          title,
          node: <GeneralInvolvedPartySection partyKey={partyKey} />,
        };
      }),
    },
  ];
};

const getFilesSection = (organization) => {
  const filesEnabled = isFeatureEnabled(
    organization,
    CONFIGURATION_FEATURES_NAMES.FNOL_CONFIGURATION_INVOLVED_ENTITIES
  );

  return filesEnabled ? [{ id: 'documents', title: 'Files', node: <FilesSection /> }] : [];
};

const FnolConfigurationScreen = ({ incidentConfigurationId, subOrgId, onCancel, onSubmit }) => {
  const { organization } = useSysconfig();

  const incidentConfigurationsRoute = `/sysconfig/api/v1/organizations/${organization.id}/incident_configurations/${incidentConfigurationId}`;
  const { isLoading, isError, data: fnolConfiguration = {} } = useDataFetcher(`${incidentConfigurationsRoute}`, {});

  const trackMixpanelEvent = () => {
    mixpanel.track(FNOL_MIXPANEL_EVENTS.SAVE_FNOL_CONFIGURATION_CLICKED, {
      incident_configuration_id: incidentConfigurationId,
      sub_organization_id: subOrgId,
    });
  };

  const handleSubmitFnolConfiguration = async (values) => {
    try {
      trackMixpanelEvent();
      values = setContactConfiguredFieldsKeysInValues(values);
      await axios.patch(incidentConfigurationsRoute, values);
      await onSubmit();
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  return (
    <LoadingSwitch isLoading={isLoading} isError={isError}>
      <Formik
        initialValues={setOnTheFlyFnolConfigurationMigrations({ ...fnolConfiguration, sub_organization_id: subOrgId })}
        enableReinitialize
        validationSchema={fnolConfigurationSchema(fnolConfiguration)}
        onSubmit={async (values, formikProps) => {
          const { setSubmitting } = formikProps;
          try {
            setSubmitting(true);
            values = removeStaticFields(values);
            await handleSubmitFnolConfiguration(values);
          } catch (error) {
            setSubmitting(false);
          }
        }}
      >
        <>
          <FnolPrompt />
          <div className="fixed z-10 w-full bg-slate-200 p-12">
            <ConfigurationBreadcrumbs
              configurationName="FNOL Configuration"
              externalAdminRoute="/fnol_configuration"
              onCancel={onCancel}
            />
          </div>
          {/* Gap between start of sections */}
          {Array.from(Array(2).keys()).map((item, index) => (
            <SectionWrapper key={index} />
          ))}
          <Header />
          <div className="bg-slate-200 pt-32">
            <div>
              <FsIndexPanel
                sections={[
                  {
                    id: 'general_details',
                    title: 'General Details',
                    node: <GeneralDetailsSection />,
                  },
                  {
                    id: 'incident_types',
                    title: 'Incident Types & Sub-Types',
                    node: <IncidentTypesAndSubTypesSection />,
                  },
                  {
                    id: 'incident_details',
                    title: 'Incident Details',
                    node: <IncidentDetailsSection />,
                  },
                  ...getInvolvedPartiesSections(fnolConfiguration, organization),
                  ...getLobSpecificAdditionalInfoSections(fnolConfiguration?.lob),
                  { id: 'witnesses', title: 'Witnesses', node: <WitnessesSection /> },
                  ...getFilesSection(organization),
                ]}
                panelTitle="FNOL Content"
                SectionWrapperComponent={SectionWrapper}
                sectionContainerClassName="scroll-mt-40"
                handleScrollTopOffset={40}
              />
              {/* Gap between end of sections and the footer */}
              {Array.from(Array(16).keys()).map((item, index) => (
                <SectionWrapper key={index} />
              ))}
            </div>
          </div>
          <FnolConfigurationFooter onCancel={onCancel} />
        </>
      </Formik>
    </LoadingSwitch>
  );
};

FnolConfigurationScreen.propTypes = {
  incidentConfigurationId: PropTypes.number.isRequired,
  subOrgId: PropTypes.number,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default FnolConfigurationScreen;
