import type { ReactElement } from 'react';
import React from 'react';
import type { FormikHelpers } from 'formik';
import { Formik } from 'formik';
import { noop } from 'lodash';
import * as Yup from 'yup';

import CardDialog from '~/components/CardDialog';
import DialogFooterActions from '~/components/core/DialogFooterActions';
import type { EditConfigurationDialogComponentProps } from '~/components/core/Molecules/ConfigurableOption';
import type { NotesConfiguration } from '~/components/SystemConfiguration/NotesConfiguration/types';
import TextFieldFormik from '~/components/TextFieldFormik';
import { convertSecondsToHours } from '~/DateTimeUtils';
import { reportErrorInProductionOrThrow } from '~/Utils';

const convertHoursToSeconds = (hours: number) => hours * 60 * 60;

const NoteEditTimeDialog = ({
  configuration,
  onCancel = noop,
  onSubmit,
}: EditConfigurationDialogComponentProps<NotesConfiguration>): ReactElement => {
  const handleSubmit = async (
    values: Partial<NotesConfiguration>,
    formikHelpers: FormikHelpers<Partial<NotesConfiguration>>
  ) => {
    try {
      if (!onSubmit) {
        reportErrorInProductionOrThrow('No onSubmit function for NoteEditTimeDialog');
        return;
      }
      await onSubmit({
        note_edit_time_in_seconds: values.note_edit_time_in_hours
          ? convertHoursToSeconds(Number(values.note_edit_time_in_hours))
          : 0,
        is_edit_note_enabled: true,
      });
      onCancel();
    } catch {
      formikHelpers.setSubmitting(false);
    }
  };

  const validationSchema = Yup.object().shape({
    note_edit_time_in_hours: Yup.number()
      .required('Required')
      .max(48, 'Maximum value for edit time is 48 hours')
      .nullable(),
  });

  return (
    <Formik
      initialValues={{
        note_edit_time_in_hours: configuration?.note_edit_time_in_seconds
          ? convertSecondsToHours(Number(configuration?.note_edit_time_in_seconds))
          : 24,
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, handleSubmit }) => {
        return (
          <CardDialog
            title="Note edit time"
            isDialog
            maxWidth="sm"
            fullWidth
            onClose={onCancel}
            preventClose={isSubmitting}
            footerActions={
              <DialogFooterActions
                primaryLabel="Save"
                onClickPrimary={handleSubmit}
                onClickSecondary={onCancel}
                disabled={isSubmitting}
              />
            }
          >
            <TextFieldFormik
              id="note_edit_time_in_hours"
              label="Note edit time (hours)"
              fullWidth
              showOnly={isSubmitting}
            />
          </CardDialog>
        );
      }}
    </Formik>
  );
};

export default NoteEditTimeDialog;
