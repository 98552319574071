import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';
import _ from 'lodash';

import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';

import { Heading } from '../../../core';
import { TrashIcon } from '../../../icons';
import InlineIconButton from '../../../InlineIconButton';
import { TextFieldFormik, useSetDefaultFieldsOnChange } from '../../../TextFieldFormik';

import ConditionValueField, { MULTI_SELECT_OPERATORS } from './ConditionValueField/ConditionValueField';
import { CONDITION_FIELD_IDS } from './Conditions';

import { useStyles } from '../../../../assets/styles';
import styles from './automaticConditions.module.scss';

const Condition = ({ title, conditionFieldId, conditionOptions, disabled, onRemoveCondition }) => {
  const { values, setFieldValue } = useFormikContext();
  const classes = useStyles();

  const conditionKey = getIn(values, `${conditionFieldId}.${CONDITION_FIELD_IDS.CONDITION_KEY}`);
  const conditionValue1 = getIn(values, `${conditionFieldId}.${CONDITION_FIELD_IDS.VALUE_1}`);
  const conditionValue2 = getIn(values, `${conditionFieldId}.${CONDITION_FIELD_IDS.VALUE_2}`);
  const conditionOperator1 = getIn(values, `${conditionFieldId}.${CONDITION_FIELD_IDS.OPERATOR_1}`);
  const conditionOperator2 = getIn(values, `${conditionFieldId}.${CONDITION_FIELD_IDS.OPERATOR_2}`);

  const getSelectedConditionOption = () => {
    return !_.isEmpty(conditionOptions) && !_.isEmpty(conditionKey)
      ? conditionOptions.find((o) => o.key === conditionKey)
      : null;
  };

  const getSelectedValue1Option = () => {
    return !_.isEmpty(selectedConditionOption) && !_.isEmpty(conditionValue1)
      ? selectedConditionOption.operands.find((o) => o.value === conditionValue1)
      : null;
  };

  const isOperatorMultiselect = useCallback((operatorKey) => {
    return MULTI_SELECT_OPERATORS.includes(operatorKey);
  }, []);

  const selectedConditionOption = getSelectedConditionOption();
  const selectedValue1Option = getSelectedValue1Option();

  const getInitialValue1 = useCallback(() => {
    return MULTI_SELECT_OPERATORS.includes(selectedConditionOption?.operators.operator_1[0]?.key) ? [] : '';
  }, [selectedConditionOption]);

  const getInitialValue2 = useCallback(() => {
    return MULTI_SELECT_OPERATORS.includes(selectedConditionOption?.operators.operator_2[0]?.key) ? [] : '';
  }, [selectedConditionOption]);

  const getInitialOperator1 = useCallback(() => {
    return selectedConditionOption?.operators.operator_1.length === 1
      ? selectedConditionOption.operators.operator_1[0].key
      : '';
  }, [selectedConditionOption]);

  const getInitialOperator2 = useCallback(() => {
    return selectedConditionOption?.operators.operator_2.length === 1
      ? selectedConditionOption.operators.operator_2[0].key
      : '';
  }, [selectedConditionOption]);

  useEffect(() => {
    if (selectedConditionOption?.operators.operator_1.length === 1) {
      setFieldValue(`${conditionFieldId}.${CONDITION_FIELD_IDS.OPERATOR_1}`, getInitialOperator1());
    }

    if (selectedConditionOption?.operators.operator_2.length === 1) {
      setFieldValue(`${conditionFieldId}.${CONDITION_FIELD_IDS.OPERATOR_2}`, getInitialOperator2());
    }
  }, [conditionFieldId, setFieldValue, getInitialOperator1, getInitialOperator2, selectedConditionOption]);

  useSetDefaultFieldsOnChange(conditionOperator1, {
    [`${conditionFieldId}.${CONDITION_FIELD_IDS.OPERATOR_2}`]: getInitialOperator2(),
    [`${conditionFieldId}.${CONDITION_FIELD_IDS.VALUE_1}`]: getInitialValue1(),
  });

  useSetDefaultFieldsOnChange(conditionValue1, {
    [`${conditionFieldId}.${CONDITION_FIELD_IDS.VALUE_2}`]: getInitialValue2(),
  });

  useEffect(() => {
    if (
      (isOperatorMultiselect(conditionOperator2) && !Array.isArray(conditionValue2)) ||
      (!isOperatorMultiselect(conditionOperator2) && Array.isArray(conditionValue2))
    ) {
      setFieldValue(`${conditionFieldId}.${CONDITION_FIELD_IDS.VALUE_2}`, getInitialValue2());
    }
  }, [conditionOperator2, getInitialValue2, conditionValue2, setFieldValue, isOperatorMultiselect, conditionFieldId]);

  const value1FieldBaseValues = {
    id: `${conditionFieldId}.${CONDITION_FIELD_IDS.VALUE_1}`,
    label: selectedConditionOption?.value_1_desc || '',
    disabled: !selectedConditionOption || disabled,
  };

  const value2FieldBaseValues = {
    id: `${conditionFieldId}.${CONDITION_FIELD_IDS.VALUE_2}`,
    label: selectedConditionOption?.value_2_desc || '',
    disabled: !selectedValue1Option || disabled,
  };

  return (
    <Grid container className={styles.automaticCondition} spacing={2}>
      <Grid item xs={11}>
        <Heading variant={Heading.TYPES.H4} className={styles.sectionHeader}>
          {title}
        </Heading>
      </Grid>
      <Grid item xs={1}>
        <InlineIconButton
          icon={TrashIcon}
          iconColor="currentColor"
          className={`${classes.textIcon} ${styles.deleteButton}`}
          onClick={onRemoveCondition}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <TextFieldFormik
          id={`${conditionFieldId}.${CONDITION_FIELD_IDS.CONDITION_KEY}`}
          label="Object"
          className={classes.formTextField}
          fullWidth
          select
          disabled={disabled}
        >
          {conditionOptions.map((conditionOption) => (
            <MenuItem key={conditionOption.key} value={conditionOption.key}>
              {conditionOption.desc}
            </MenuItem>
          ))}
        </TextFieldFormik>
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={6}>
        {selectedConditionOption ? (
          <ConditionValueField
            conditionOperator={conditionOperator1}
            baseFieldProps={value1FieldBaseValues}
            operands={selectedConditionOption.operands}
          />
        ) : null}
      </Grid>
      {selectedConditionOption && selectedConditionOption.is_multi_value && (
        <Grid item xs={6}>
          {selectedValue1Option ? (
            <ConditionValueField
              conditionOperator={conditionOperator2}
              baseFieldProps={value2FieldBaseValues}
              operands={selectedValue1Option.sub_operands}
            />
          ) : null}
        </Grid>
      )}
    </Grid>
  );
};

Condition.propTypes = {
  conditionOptions: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  conditionFieldId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onRemoveCondition: PropTypes.func.isRequired,
};

export default Condition;
