import React, { useState } from 'react';
import querystring from 'query-string';

import CardDialog from '~/components/CardDialog';
import { LoadingSwitch, Text } from '~/components/core';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import useDataFetcher from '~/components/useDataFetcher';

import ConfigurationBreadcrumbs from './ConfigurationBreadcrumbs';
import ConfigurationFilters from './ConfigurationFilters';
import ConfigurationsTable from './ConfigurationsTable';
import { FILTERS_DEFAULT } from './constants';
import type {
  ConfigurationScreenProps,
  Filters,
  IncidentConfigurationSummary,
  IncidentConfigurationSummaryList,
} from './types';

const paramsSerializer = (params: querystring.ParsedQuery<string | number | boolean | null | undefined>) =>
  querystring.stringify(params, { arrayFormat: 'none' });

type ConfigurationPageProps = {
  configurationTitle: string;
  configurationSubtitle: string;
  externalAdminRoute: string;
  ConfigurationScreen: React.FC<ConfigurationScreenProps>;
  searchPlaceholder: string;
  counterSuffix: string;
  displayName: string;
  trackMixpanelEvent: (incidentConfiguration: IncidentConfigurationSummary) => void;
};

const ConfigurationPage: React.FC<ConfigurationPageProps> = ({
  configurationTitle,
  configurationSubtitle,
  externalAdminRoute,
  ConfigurationScreen,
  searchPlaceholder,
  counterSuffix,
  displayName,
  trackMixpanelEvent,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization } = useSysconfig();
  const [incidentConfigIdToEdit, setIncidentConfigIdToEdit] = useState<number | null>(null);

  const [filters, setFilters] = useState<Filters>({ ...FILTERS_DEFAULT });

  const { searchText, selectedLob, subOrganizationIds } = filters;

  const baseIncidentConfigurationsRoute = `/sysconfig/api/v1/organizations/${organization.id}/incident_configurations`;
  const {
    isLoading,
    isError,
    data: incidentConfigSummaries,
    reloadData: reloadIncidentConfigSummaries,
  }: {
    isLoading: boolean;
    isError: boolean;
    data: IncidentConfigurationSummaryList;
    reloadData: () => void;
  } = useDataFetcher(`${baseIncidentConfigurationsRoute}/summary`, {
    params: { lob: selectedLob, sub_organization_ids: subOrganizationIds },
    paramsSerializer,
  });

  const filteredIncidentConfigSummaries = (incidentConfigSummaries || []).filter((ic: { display_name: string }) =>
    ic.display_name.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleOnCancel = () => {
    setIncidentConfigIdToEdit(null);
  };

  const handleOnSubmit = async () => {
    await reloadIncidentConfigSummaries();
    handleOnCancel();
  };

  return (
    <>
      {!incidentConfigIdToEdit && (
        <div className="p-12">
          <ConfigurationBreadcrumbs
            externalAdminRoute={externalAdminRoute}
            configurationName={configurationTitle}
            onCancel={handleOnCancel}
          />
        </div>
      )}
      {!incidentConfigIdToEdit ? (
        <CardDialog title={configurationTitle}>
          <Text variant="small" weight="regular">
            {configurationSubtitle}
          </Text>
          <ConfigurationFilters filters={filters} setFilters={setFilters} searchPlaceholder={searchPlaceholder} />
          <LoadingSwitch isLoading={isLoading} isError={isError}>
            <ConfigurationsTable
              incidentConfigSummaries={filteredIncidentConfigSummaries}
              onEditIncidentConfig={setIncidentConfigIdToEdit}
              counterSuffix={counterSuffix}
              displayName={displayName}
              trackMixpanelEvent={trackMixpanelEvent}
            />
          </LoadingSwitch>
        </CardDialog>
      ) : (
        <ConfigurationScreen
          incidentConfigurationId={incidentConfigIdToEdit}
          subOrgId={
            incidentConfigSummaries?.find((ic: { id: number }) => ic.id === incidentConfigIdToEdit)?.sub_organization_id
          }
          onCancel={handleOnCancel}
          onSubmit={handleOnSubmit}
        />
      )}
    </>
  );
};

const InternalConfiguration: React.FC<ConfigurationPageProps> = ({
  configurationTitle,
  configurationSubtitle,
  externalAdminRoute,
  ConfigurationScreen,
  searchPlaceholder,
  counterSuffix,
  displayName,
  trackMixpanelEvent,
}) => (
  <div className="absolute inset-0 overflow-scroll">
    <ConfigurationPage
      configurationTitle={configurationTitle}
      configurationSubtitle={configurationSubtitle}
      externalAdminRoute={externalAdminRoute}
      ConfigurationScreen={ConfigurationScreen}
      searchPlaceholder={searchPlaceholder}
      counterSuffix={counterSuffix}
      displayName={displayName}
      trackMixpanelEvent={trackMixpanelEvent}
    />
  </div>
);

export { InternalConfiguration };
export default ConfigurationPage;
