import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@material-ui/core';
import axios from 'axios';

import { useStyles } from '~/assets/styles';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';
import { reportAxiosError } from '~/Utils';

import LoadingIndicator from '../../../LoadingIndicator';
import { useSysconfig } from '../../SystemConfigurationScreen';

const GridItem = ({ enabled, title, onClick, isLoading, buttonText }) => {
  const classes = useStyles();

  return (
    <Grid item x={6} style={{ display: 'inline-flex', alignItems: 'center', paddingTop: '16px', paddingLeft: '32px' }}>
      {enabled ? (
        <>
          <Icon color="primary">check_circle</Icon>
          <Typography variant="subtitle1" style={{ padding: '8px' }}>
            {title}
          </Typography>
        </>
      ) : (
        <Button
          color="primary"
          variant="contained"
          style={{ display: 'inline-block' }}
          onClick={onClick}
          disabled={isLoading}
        >
          {isLoading && <LoadingIndicator size={24} className={classes.buttonProgress} />}
          {buttonText}
        </Button>
      )}
    </Grid>
  );
};

GridItem.propTypes = {
  enabled: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  buttonText: PropTypes.string.isRequired,
};

const CommunicationsIntegrationsAccountIndication = () => {
  const { organization, organizationOperationalDetails, reloadOperationalDetails } = useSysconfig();

  const [isLoading, setIsLoading] = useState(false);
  const enableOrganizationTwilioAccount = async () => {
    try {
      setIsLoading(true);
      await axios.post(`/api/v1/twilio_configuration/organizations/${organization.id}/enable`, {});
      await reloadOperationalDetails();
    } catch (error) {
      reportAxiosError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const enableOrganizationDyteAccount = async () => {
    try {
      setIsLoading(true);
      await axios.post(`/api/v1/video_calls_dyte_configuration/organizations/${organization.id}/enable`, {});
      await reloadOperationalDetails();
    } catch (error) {
      reportAxiosError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const enableTwilioQueues = async () => {
    try {
      setIsLoading(true);
      await axios.post(`/api/v1/twilio_configuration/organizations/${organization.id}/enable_queues`, {});

      await reloadOperationalDetails();
      setIsLoading(false);
    } catch (error) {
      reportAxiosError(error);
    }
  };

  return (
    <Grid container spacing={0} direction="column">
      <GridItem
        enabled={organizationOperationalDetails.isTwilioEnabled}
        title="Twilio account enabled"
        onClick={enableOrganizationTwilioAccount}
        isLoading={isLoading}
        buttonText="Enable Organization Twilio Account"
      />

      {organizationOperationalDetails.isTwilioEnabled ? (
        <GridItem
          enabled={organizationOperationalDetails.twilioConfiguration.is_queues_enabled}
          title="Twilio queues enabled"
          onClick={enableTwilioQueues}
          isLoading={isLoading}
          buttonText="Enable Twilio Queues"
        />
      ) : null}

      <GridItem
        enabled={organizationOperationalDetails.isDyteVideoInitialized}
        title="Video calls enabled"
        onClick={enableOrganizationDyteAccount}
        isLoading={isLoading}
        buttonText="Enable Organization Video Calls"
      />
    </Grid>
  );
};

export default CommunicationsIntegrationsAccountIndication;
