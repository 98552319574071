const ASSIGNMENT_CONDITION_KEY_DICT = {
  coverage: {
    desc: 'Coverage',
    is_multi_key_value: false,
  },
  exposures: {
    desc: 'Exposures',
    is_multi_key_value: true,
  },
  incident: {
    desc: 'Incident',
    lob_desc: {
      home_claim: 'Peril',
    },
    is_multi_key_value: false,
  },
  incident_subtypes: {
    desc: 'Incident and sub-incident',
    is_multi_key_value: false,
  },
  fnol_created: {
    desc: 'FNOL Origin',
    is_multi_key_value: false,
  },
} as const;

export default ASSIGNMENT_CONDITION_KEY_DICT;
