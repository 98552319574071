import ApiLimits from '../../ApiLimits';

export const FIELD_IDS = {
  CONTACT_ID: 'contact_id',
  CONTACT_FULL_NAME: 'contact_full_name',
  CONTACT: 'contact',
  API_PERMISSION_BOOLS: 'api_permission_bools',
  CALLBACK_URL: 'callback_url',
  EVENT_BOOLS: 'event_bools',
};

export const PERMISSION_SETS = [
  {
    title: 'Assignments',
    subtitle: 'Ability to assign service provider via API and retrieve updated on assignment progress',
    permissions: [
      { id: 'new_moi_assigned_event', name: 'New Assignment Update', type: 'Webhook', is_read_only: true },
      { id: 'update_assignment', name: 'MOI Assignment Update', type: 'POST', is_read_only: true },
      {
        id: 'exposure_involved_details_updated_event',
        name: 'Exposure Involved Update',
        type: 'Webhook',
        is_read_only: true,
      },
    ],
  },
  {
    title: 'Retrieve Exposure Details',
    subtitle: 'Enable the service provider to retrieve information regarding the exposure handling progress',
    permissions: [
      { id: 'get_exposure_info', name: 'Exposure', type: 'GET' },
      { id: 'get_exposure_documents', name: 'Exposure Documents', type: 'GET' },
    ],
  },
  {
    title: 'Retrieve Claim Details',
    subtitle: 'Enable the service provider to retrieve information regarding the claim handling progress',
    permissions: [
      { id: 'get_claim_info', name: 'Incident details', type: 'GET' },
      { id: 'incident_details_updated_event', name: 'Incident details updated', type: 'Webhook' },
      { id: 'involved_details_updated_event', name: 'Claim Involved Update', type: 'Webhook' },
      { id: 'involved_added', name: 'New Involved Added', type: 'Webhook' },
      { id: 'get_claim_documents', name: 'Claim Documents', type: 'GET' },
      { id: 'new_document_added_event', name: 'New Document Added', type: 'Webhook' },
      { id: 'get_policy_info', name: 'Policy', type: 'GET' },
      { id: 'get_siu', name: 'SIU', type: 'GET' },
    ],
  },
  {
    title: 'Update Exposure',
    subtitle: 'Enable the service provider to update the CMS information regarding the exposure handling progress',
    permissions: [
      { id: 'post_close_exposure', name: 'Close Exposure', type: 'POST' },
      // { id: 'patch_exposure_involved', name: 'Involved', type: 'PATCH' },  // not yet implemented - NGTPA-7441
    ],
  },
  {
    title: 'Update Claim Details',
    subtitle: 'Enable the service provider to update the CMS information regarding the claim handling progress',
    permissions: [
      { id: 'patch_incident_details', name: 'Incident Details', type: 'PATCH' },
      { id: 'post_involved', name: 'Involved', type: 'POST' },
      { id: 'patch_involved', name: 'Involved', type: 'PATCH' },
      { id: 'upload_claim_document', name: 'Document(s)', type: 'POST' },
      { id: 'post_note', name: 'Note', type: 'POST' },
      { id: 'post_reminder', name: 'Reminder', type: 'POST' },
      { id: 'put_siu', name: 'SIU Update', type: 'PUT' },
    ],
  },
  {
    title: 'Handle a Claim',
    subtitle: 'Enable the service provider to handle a claim it was assigned to.',
    additionalDescription:
      'These APIs and webhooks will only apply if the service provider is assigned as a general, claim-level assignee.',
    permissions: [
      { id: 'post_exposures', name: 'New Exposure', type: 'POST' },
      { id: 'close_claim', name: 'Close Claim', type: 'POST' },
      { id: 'new_exposure_created_event', name: 'Exposure Created', type: 'WEBHOOK' },
    ],
  },
  {
    title: 'Payments and Reserves',
    subtitle: 'Enable the service provider to make payments and updates reserves',
    permissions: [
      { id: 'reserves_update', name: 'Update Reserves', type: 'PUT' },
      { id: 'make_payments', name: 'Make Payments', type: 'POST' },
    ],
    ChildComponent: ApiLimits,
  },
];

export const DEFAULT_API_PERMISSIONS = ['update_assignment'];
export const DEFAULT_EVENTS = ['new_moi_assigned_event', 'exposure_involved_details_updated_event'];

export const buildApiPermissionBools = (apiPermissions) => {
  let apiPermissionBools = {};
  PERMISSION_SETS.forEach((permissionSet) => {
    permissionSet.permissions.forEach((permission) => {
      if (permission.type.toLowerCase() === 'webhook') {
        return;
      }
      apiPermissionBools[permission.id] = apiPermissions.includes(permission.id);
    });
  });
  return apiPermissionBools;
};

export const buildEventBools = (events) => {
  let eventBools = {};
  PERMISSION_SETS.forEach((permissionSet) => {
    permissionSet.permissions.forEach((permission) => {
      if (permission.type.toLowerCase() !== 'webhook') {
        return;
      }
      eventBools[permission.id] = events.includes(permission.id);
    });
  });
  return eventBools;
};

export const getVendorPermissionFieldId = (permission) => {
  if (permission.type.toLowerCase() === 'webhook') {
    return `${FIELD_IDS.EVENT_BOOLS}.${permission.id}`;
  } else {
    return `${FIELD_IDS.API_PERMISSION_BOOLS}.${permission.id}`;
  }
};
