import COMMUNICATION_TEMPLATE_ACTION from '~/server_shared/generated-types/COMMUNICATION_TEMPLATE_ACTION';

type RecipientKeys = keyof typeof COMMUNICATION_TEMPLATE_ACTION.RECIPIENT;
type SenderKeys = keyof typeof COMMUNICATION_TEMPLATE_ACTION.SENDER_TYPE;

export const recipientBaseKey = COMMUNICATION_TEMPLATE_ACTION.RECIPIENT;
export const senderBaseKey = COMMUNICATION_TEMPLATE_ACTION.SENDER_TYPE;

export const recipientByScope: {
  claim: RecipientKeys[];
  exposure: RecipientKeys[];
} = {
  claim: ['INSURED', 'PREFERRED_CONTACT'],
  exposure: ['INSURED', 'CLAIMANT', 'PREFERRED_CONTACT', 'ATTORNEY'],
};

export const senderByScope: {
  claim: SenderKeys[];
  exposure: SenderKeys[];
} = {
  claim: ['CLAIM_OWNER', 'CLAIM_OWNER_SUPERVISOR'],
  exposure: ['CLAIM_OWNER', 'EXPOSURE_OWNER', 'CLAIM_OWNER_SUPERVISOR', 'EXPOSURE_OWNER_SUPERVISOR'],
};
