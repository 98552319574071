import React from 'react';
import PropTypes from 'prop-types';

import { FsTooltip, Text } from '../../../../../core';
import { ErrorHelperTextFormik } from '../../../../../core/Formik/ErrorHelperTextFormik';
import { InfoIcon } from '../../../../../icons';
import { getPermissionsFieldId } from '../formUtils';

import NewPermissionActionTag from './NewPermissionActionTag';
import { PermissionVerbsButtonGroupFormik } from './PermissionVerbsButtonGroup';
import { innerSectionItemProptype } from './propTypes';

import styles from './styles.module.scss';

export const InnerSectionItemTile = ({ innerSectionItem, viewOnly }) => {
  const formikFieldId = getPermissionsFieldId(innerSectionItem.id);

  return (
    <div className={styles.innerSectionItemContainer}>
      <div className={styles.titleSection}>
        <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.MEDIUM} colorVariant={Text.COLOR_VARIANTS.BLACK}>
          {innerSectionItem.title}
        </Text>
        <NewPermissionActionTag permissionActionConfig={innerSectionItem} />
        <span className={styles.infoIconContainer}>
          <FsTooltip title={innerSectionItem.subtitle}>
            <InfoIcon size={20} />
          </FsTooltip>
        </span>
        <ErrorHelperTextFormik id={formikFieldId} />
      </div>
      <div className={styles.actionsContainer}>
        <PermissionVerbsButtonGroupFormik
          viewOnly={viewOnly}
          fieldId={formikFieldId}
          allowedVerbsIds={innerSectionItem.allowedVerbs}
        />
      </div>
    </div>
  );
};

InnerSectionItemTile.propTypes = {
  innerSectionItem: innerSectionItemProptype.isRequired,
  viewOnly: PropTypes.bool,
};
