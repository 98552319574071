import React, { useState } from 'react';
import { Switch } from '@material-ui/core';
import axios from 'axios';
import { isEmpty } from 'lodash';

import Button from '~/components/core/Atomic/Buttons/Button';
import Chip from '~/components/core/Atomic/Chip/Chip';
import Tooltip from '~/components/core/Atomic/Tooltip';
import Typography from '~/components/core/Atomic/Typography';
import EmptyState from '~/components/core/EmptyState';
import SuccessMessageDialog from '~/components/core/SuccessMessageDialog';
import { AddIcon } from '~/components/deprecatedMuiIcons';

import { getAmericanDateFormat, localTimeToViewTime } from '../../../DateTimeUtils';
import { MIXPANEL_EVENTS } from '../../../pocs/mixpanel';
import { CONFIGURATION_FEATURES_NAMES, DATA_EXPORT } from '../../../Types';
import { isFeatureEnabled, reportAxiosError, subOrgIdToNameDict } from '../../../Utils';
import CardDialog from '../../CardDialog';
import mixpanel from '../../CmsMain/mixpanel';
import WithConfirm from '../../ConfirmModal';
import { SortableTable } from '../../core';
import { InfoIcon, PencilIcon, TrashIcon_Deprecated } from '../../icons';
import InlineIconButton from '../../InlineIconButton';
import LoadingIndicator from '../../LoadingIndicator';
import useDataFetcher from '../../useDataFetcher';
import OperationsBreadcrumbs from '../OperationsBreadcrumbs';
import { useSysconfig } from '../SystemConfigurationScreen';

import OneTimeExportDialog from './OneTimeExportDialog';
import ScheduledExportDialog from './ScheduledExportDialog';

import { useStyles } from '../../../assets/styles';

const ScheduledDataExportPage = () => {
  const [showOneTimeExportDialog, setShowOneTimeExportDialog] = useState(false);
  const [showExportDialog, setShowExportDialog] = useState(false);
  const [editRowIDExportDialog, setEditRowIDExportDialog] = useState(null);
  const [showSuccessOneTimeExportDialog, setShowSuccessOneTimeExportDialog] = useState(false);

  const {
    organization,
    organizationOperationalDetails: { isNotesAndNotificationsEnabled },
    reloadOperationalDetails,
  } = useSysconfig();
  const { sub_organizations_enabled } = organization;
  const {
    isLoading,
    isError,
    data: scheduledExports,
    reloadData: reloadScheduledExports,
  } = useDataFetcher(`/sysconfig/api/v1/organizations/${organization.id}/scheduled_export`);
  const classes = useStyles();

  const isAttachNonCommunicationEnabled = isFeatureEnabled(
    organization,
    CONFIGURATION_FEATURES_NAMES.DATA_EXPORT_ATTACH_NON_CLAIM_COMMUNICATION
  );

  if (isLoading || isError) {
    return <LoadingIndicator isError={isError} />;
  }

  const handleCreateOneTimeExport = async (values) => {
    try {
      await axios.post(`/sysconfig/api/v1/organizations/${organization.id}/scheduled_export/one_time`, values);
      setShowSuccessOneTimeExportDialog(true);
      setShowOneTimeExportDialog(false);
      return true;
    } catch (error) {
      reportAxiosError(error);
      return false;
    }
  };

  const handleCreateScheduledExport = async (values) => {
    try {
      await axios.post(`/sysconfig/api/v1/organizations/${organization.id}/scheduled_export`, values);
      setShowExportDialog(false);
      await reloadScheduledExports();
    } catch (error) {
      reportAxiosError(error);
    }
  };

  const handleDeleteScheduledExport = async (id) => {
    try {
      await axios.delete(`/sysconfig/api/v1/organizations/${organization.id}/scheduled_export/${id}`);
      await reloadScheduledExports();
    } catch (error) {
      reportAxiosError(error);
    }
  };

  const handleUpdateScheduledExport = async (values) => {
    try {
      await axios.patch(`/sysconfig/api/v1/organizations/${organization.id}/scheduled_export/${values.id}`, values);
      await reloadScheduledExports();
    } catch (error) {
      reportAxiosError(error);
    }
  };

  const handleSwitchIsActive = async (is_active_data, scheduledDataExportId) => {
    try {
      await axios.post(
        `/sysconfig/api/v1/organizations/${organization.id}/scheduled_export/${scheduledDataExportId}/is_active`,
        is_active_data
      );
      await reloadScheduledExports();
    } catch (error) {
      reportAxiosError(error);
    }
  };

  const handleToggleNotesAndNotifications = async () => {
    try {
      await axios.post(
        `/sysconfig/api/v1/organizations/${organization.id}/scheduled_export/toggle_notes_and_notifications`,
        { is_notes_and_notifications_enabled: !isNotesAndNotificationsEnabled }
      );
      await reloadOperationalDetails();
      mixpanel.track(MIXPANEL_EVENTS.DATA_EXPORT_INCLUDE_NOTES_AND_NOTIFICATIONS_CLICKED, {
        status: !isNotesAndNotificationsEnabled,
      });
    } catch (error) {
      reportAxiosError(error);
    }
  };

  let columnData = [
    { id: 'id', isHidden: true, numeric: true },
    { id: 'display_name', label: 'Subscribe Name' },
    {
      id: 'target_type',
      label: 'Target',
      specialCell: (row) => (row.target_type ? DATA_EXPORT.TARGET_TYPE[row.target_type] : ''),
    },
    {
      id: 'export_format',
      label: 'Format',
      specialCell: (row) => (row.export_format ? DATA_EXPORT.EXPORT_FORMAT[row.export_format].desc : ''),
    },
    {
      id: 'creation_date',
      label: 'Create Date',
      specialCell: (row) => (row.creation_date ? getAmericanDateFormat(row.creation_date) : ''),
    },
    {
      id: 'end_date',
      label: 'End Date',
      specialCell: (row) => (row.end_date ? getAmericanDateFormat(row.end_date) : ''),
    },
    {
      id: 'export_days',
      label: 'Repeats on',
      specialCell: (row) =>
        row.export_days ? row.export_days.map((day) => DATA_EXPORT.DAYS[day].abbr).join(', ') : '',
    },
    {
      id: 'export_hour',
      label: 'Repeats at',
      specialCell: (row) => (row.export_hour ? localTimeToViewTime(row.export_hour) : ''),
    },
    {
      id: 'sub_organization_ids',
      numeric: false,
      label: 'Sub-Organizations',
      isHidden: !sub_organizations_enabled,
      specialCell: (config) => {
        if (config?.is_all_sub_orgs) {
          return <Chip size="small" label="All" className={classes.chip} />;
        }

        return (
          <>
            {config.sub_organization_ids?.map((subOrgId) => (
              <Chip
                key={subOrgId}
                size="small"
                label={subOrgIdToNameDict(organization)[subOrgId]}
                className={classes.chip}
              />
            ))}
          </>
        );
      },
    },
    {
      id: 'include_non_claim_communications',
      label: 'Non claim communications',
      isHidden: !isAttachNonCommunicationEnabled,
      specialCell: (row) => (row.include_non_claim_communications ? 'Included' : 'Not Included'),
    },
    {
      id: 'is_active',
      label: 'Active Subscribe',
      specialCell: (row) => (
        <span>
          <Switch
            checked={row.is_active}
            className={classes.formsSwitch}
            size="small"
            onChange={async () => {
              await handleSwitchIsActive({ is_active: !row.is_active }, row.id);
              mixpanel.track(MIXPANEL_EVENTS.DATA_EXPORT_SUBSCRIPTION_DE_ACTIVATED, {
                status: !row.is_active,
              });
            }}
          />
        </span>
      ),
    },
    {
      id: 'edit',
      label: 'Actions',
      disableSort: true,
      specialCell: (row) => (
        <>
          <InlineIconButton
            tooltipTitle="Edit"
            icon={PencilIcon}
            className={`${classes.textIcon} ${classes.marginedIcon} ${classes.hoverableNonFilledIcon}`}
            onClick={() => {
              setEditRowIDExportDialog(row.id);
              setShowExportDialog(true);
              mixpanel.track(MIXPANEL_EVENTS.DATA_EXPORT_SUBSCRIPTION_RULE_EDITED);
            }}
            wrapWithSpan
          />
          <WithConfirm
            key={row.id}
            title="Delete this subscription rule?"
            contentText="If you delete this subscription rule, data will no longer be delivered."
            primaryButtonName="Yes, Delete"
            shouldCloseOnPrimary={true}
          >
            <InlineIconButton
              tooltipTitle="Delete"
              icon={TrashIcon_Deprecated}
              className={`${classes.textIcon} ${classes.marginedIcon} ${classes.hoverableNonFilledIcon}`}
              onClick={async () => {
                await handleDeleteScheduledExport(row.id);
                mixpanel.track(MIXPANEL_EVENTS.DATA_EXPORT_SUBSCRIPTION_DELETED);
              }}
              wrapWithSpan
            />
          </WithConfirm>
        </>
      ),
    },
  ];

  return (
    <>
      <CardDialog noCardTitle>
        <OperationsBreadcrumbs currentTab="Data Export" />
        <CardDialog title="DATA EXPORT">
          <Typography variant="body1">Create a one-time data export or schedule recurring exports.</Typography>
          <div style={{ paddingTop: '14px', display: 'flex', alignItems: 'center' }}>
            <WithConfirm
              title="Are you sure?"
              contentText="Notes contain static data and will not reflect changes made after the notes are created"
              primaryButtonName="Yes"
              shouldCloseOnPrimary={true}
              disableConfirm={isNotesAndNotificationsEnabled}
            >
              <Switch
                checked={isNotesAndNotificationsEnabled}
                onClick={handleToggleNotesAndNotifications}
                className={classes.formsSwitch}
                size="small"
              />
            </WithConfirm>
            <span style={{ paddingLeft: '4px' }}>Include notes & notifications in Data Exports files</span>
            <Tooltip
              placement="top-start"
              arrow
              title="Notes contain static data and will not reflect changes made after the notes were created"
            >
              <span style={{ marginLeft: '8px', marginTop: '4px' }}>
                <InfoIcon size={18} />
              </span>
            </Tooltip>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }} className={classes.rightButtonIcon}>
            <Button
              color="primary"
              onClick={() => {
                setShowOneTimeExportDialog(true);
                mixpanel.track(MIXPANEL_EVENTS.ONE_TIME_DATA_EXPORT_EMAIL_CLICKED);
              }}
            >
              One Time Email Export
            </Button>
            <Button
              color="primary"
              onClick={() => {
                setShowExportDialog(true);
                mixpanel.track(MIXPANEL_EVENTS.NEW_DATA_EXPORT_SUBSCRIPTION_RULE_CLICKED);
              }}
            >
              <AddIcon />
              Add New Subscription Rule
            </Button>
          </div>
          {!isEmpty(scheduledExports) ? (
            <SortableTable defaultOrderColumn={0} columns={columnData} rows={scheduledExports} />
          ) : (
            <EmptyState />
          )}
          {showOneTimeExportDialog && (
            <OneTimeExportDialog
              onClose={() => setShowOneTimeExportDialog(false)}
              onSubmit={handleCreateOneTimeExport}
            />
          )}
          {showSuccessOneTimeExportDialog && (
            <SuccessMessageDialog
              title="Success"
              subtitle="In a few minutes the email will be sent with the desired information"
              onClose={() => setShowSuccessOneTimeExportDialog(false)}
            />
          )}
          {showExportDialog && (
            <ScheduledExportDialog
              onClose={() => {
                setShowExportDialog(false);
                setEditRowIDExportDialog(null);
              }}
              onSubmit={editRowIDExportDialog ? handleUpdateScheduledExport : handleCreateScheduledExport}
              scheduledExports={scheduledExports}
              rowIDToEdit={editRowIDExportDialog}
            />
          )}
        </CardDialog>
      </CardDialog>
    </>
  );
};

export default ScheduledDataExportPage;
