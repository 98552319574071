import type { FormikValues } from 'formik';
import { get, set } from 'lodash';

import { CONFIGURED_FIELDS_PATHS, CONTACT_API_SUFFIX, CONTACT_CONFIG_SUFFIX } from './constants';

export const setContactConfiguredFieldsKeysInValues = (values: FormikValues): FormikValues => {
  CONFIGURED_FIELDS_PATHS.forEach((path) => {
    const configuredFields = get(values, path, []);
    configuredFields.forEach((field: { type: string; id: string }, idx: number) => {
      if (field.type === 'contact' && field.id?.endsWith(CONTACT_API_SUFFIX)) {
        set(values, `${path}[${idx}].id`, field.id.replace(CONTACT_API_SUFFIX, CONTACT_CONFIG_SUFFIX));
      }
    });
  });

  return values;
};
