const INVOLVED_DICT_PER_CLAIM_TYPE = {
  auto_claim: {
    listed_first_party_vehicle: {
      desc: 'Listed First Party Vehicle',
      is_unspecified: false,
      loss_type: 'property_damage',
    },
    unlisted_first_party_vehicle: {
      desc: 'Unlisted First Party Vehicle',
      is_unspecified: false,
      loss_type: 'property_damage',
    },
    first_party_pedestrian: {
      desc: 'First Party Pedestrian',
      is_unspecified: false,
      loss_type: 'bodily_injury',
    },
    first_party_driver: {
      desc: 'First Party Driver',
      is_unspecified: false,
      loss_type: 'bodily_injury',
    },
    first_party_passenger: {
      desc: 'First Party Passenger',
      is_unspecified: false,
      loss_type: 'bodily_injury',
    },
    third_party_passenger: {
      desc: 'Third Party Passenger',
      is_unspecified: false,
      loss_type: 'bodily_injury',
    },
    third_party_driver: {
      desc: 'Third Party Driver',
      is_unspecified: false,
      loss_type: 'bodily_injury',
    },
    third_party_vehicle: {
      desc: 'Third Party Vehicle',
      is_unspecified: false,
      loss_type: 'property_damage',
    },
    third_party_non_motorist: {
      desc: 'Third Party Non Motorist',
      is_unspecified: false,
      loss_type: 'bodily_injury',
    },
    third_party_property_damage: {
      desc: 'Third Party Property Damage',
      is_unspecified: false,
      loss_type: 'property_damage',
    },
    first_party_unspecified_property_damage: {
      desc: 'First Party Unspecified Property Damage',
      is_unspecified: true,
      loss_type: 'property_damage',
    },
    first_party_unspecified_pecuniary_loss: {
      desc: 'First Party Unspecified Pecuniary Loss',
      is_unspecified: true,
      loss_type: 'pecuniary_loss',
    },
  },
  home_claim: {
    first_party_unspecified_property_damage: {
      desc: 'First Party Unspecified Property Damage',
      is_unspecified: true,
      loss_type: 'property_damage',
    },
    first_party_unspecified_pecuniary_loss: {
      desc: 'First Party Unspecified Pecuniary Loss',
      is_unspecified: true,
      loss_type: 'pecuniary_loss',
    },
    third_party_damaged_property: {
      desc: 'Third Party Property Damage',
      is_unspecified: false,
      loss_type: 'property_damage',
    },
    third_party_involved_person: {
      desc: 'Third Party Involved Person',
      is_unspecified: false,
      loss_type: 'bodily_injury',
    },
  },
  pet_claim: {
    first_party_pet: {
      desc: 'First Party Pet',
      is_unspecified: false,
      loss_type: 'property_damage',
    },
  },
} as const;

export default INVOLVED_DICT_PER_CLAIM_TYPE;
