import { useHistory } from 'react-router-dom';

import { reportErrorInProductionOrThrow } from '../../Utils';

import { useCms } from './useCms';
/**
 * check if user has access to the page, otherwise redirect to /
 * @param {string} errorMessage
 * @param {Function|Function[]} roleValidators
 * @returns {JSX.Element}
 */
export const useRoleRestriction = ({ errorMessage, roleValidators }) => {
  const { user } = useCms();
  const history = useHistory();
  const isAllowed = (Array.isArray(roleValidators) ? roleValidators : [roleValidators]).every((validator) =>
    validator(user)
  );
  if (!isAllowed) {
    reportErrorInProductionOrThrow(`User: ${user.username} -  id:${user.id} ${errorMessage}`);
    history.push('/');
  }
};
