import React from 'react';

import OrganizationSpecialContacts from './OrganizationSpecialContacts';

function Vendors() {
  return (
    <>
      <OrganizationSpecialContacts />
    </>
  );
}

export default Vendors;
