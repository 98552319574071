import React from 'react';
import PropTypes from 'prop-types';

import CollapsibleWrapper from '~/components/core/Collapsible/CollapsibleWrapper';
import cn from '~/Utils/cn';

import { PermissionItemTile } from './PermissionItemTile';
import PermissionSetCollapsibleTileSummary from './PermissionSetCollapsibleTileSummary';
import { permissionSetPropType } from './propTypes';

import styles from './styles.module.scss';

export const PermissionSetCollapsibleTile = ({ permissionSet, isStartOpen, ChildComponent }) => {
  const [isOpen, setIsOpen] = React.useState(isStartOpen);

  const handleCollapseChange = () => setIsOpen(!isOpen);

  return (
    <div className={styles.sectionHeaderContainer}>
      <CollapsibleWrapper
        title={permissionSet.title}
        actions={!isOpen ? <PermissionSetCollapsibleTileSummary permissionSet={permissionSet} /> : null}
        subtitle={permissionSet.subtitle}
        summarySectionClassname={cn(styles.summarySection)}
        noDivider
        actionCard
        backgroundColor={CollapsibleWrapper.BACKGROUND_COLOR.WHITE}
        expansionMode={CollapsibleWrapper.EXPANSION_MODES.drawer}
        onCollapse={handleCollapseChange}
        open={isOpen}
        isCollapsible
        unmountOnExit={false}
        additionalSubtitleOnlyWhenOpen={permissionSet.additionalDescription}
      >
        {ChildComponent && <ChildComponent />}
        <div className={styles.sectionHeaderDrawerContainer}>
          <div className={styles.innerSectionContainer}>
            {permissionSet.permissions.map((permission) => (
              <PermissionItemTile key={permission.name + permission.type} permission={permission} />
            ))}
          </div>
        </div>
      </CollapsibleWrapper>
    </div>
  );
};

PermissionSetCollapsibleTile.propTypes = {
  permissionSet: permissionSetPropType.isRequired,
  isStartOpen: PropTypes.bool,
  ChildComponent: PropTypes.node,
};
