import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Button from '~/components/core/Atomic/Buttons/Button';
import LoadingSwitch from '~/components/core/Loading/LoadingSwitch';
import { AddIcon } from '~/components/deprecatedMuiIcons';
import GenericTemplateBuilderContainer from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplateBuilder/GenericTemplateBuilderContainer';
import GenericTemplateCardContainer from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplateCard/GenericTemplateCardContainer';
import GenericTemplateAdvancedSearchContainerFormik from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplateSearch/GenericTemplateAdvancedSearch/GenericTemplateAdvancedSearchContainerFormik';
import GenericTemplateBaseSearchContainer from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplateSearch/GenericTemplateBaseSearchContainer';
import GenericTemplatesTable from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplatesTable';
import type {
  AdvancedSearchFilterTypes,
  PaginationProps,
  TemplateFormSubmitValuesProps,
  TemplateTypeKey,
} from '~/components/GenericTemplates/types';
import { DEFAULT_TEMPLATES_PAGINATION_PROPS } from '~/components/GenericTemplates/utils/genericTemplatesUtils';
import { GENERIC_TEMPLATES_ROUTES } from '~/components/GenericTemplates/utils/routes';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import useDataFetcher from '~/components/useDataFetcher';
import { getAxiosParamsSerializer, reportAxiosError } from '~/Utils';
import cn from '~/Utils/cn';

const FETCH_PARAMS_SERIALIZER = getAxiosParamsSerializer('none');

export interface GenericTemplatesTableContainerPropsType {
  templateType: TemplateTypeKey;
  shouldDisplayType: boolean;
  isCreateTemplateEnabled: boolean;
}

const GenericTemplatesTableContainer: React.FC<GenericTemplatesTableContainerPropsType> = ({
  templateType,
  shouldDisplayType,
  isCreateTemplateEnabled,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization } = useSysconfig();

  const [paginationProps, setPaginationProps] = useState(DEFAULT_TEMPLATES_PAGINATION_PROPS);
  const [templates, setTemplates] = useState([]);
  const [templatesCount, setTemplatesCount] = useState(0);
  const [searchParams, setSearchParams] = useState({
    template_type: templateType,
    template_name: '',
  });
  const [isCreateTemplateDialogOpen, setIsCreateTemplateDialogOpen] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState(0);

  const { data, isLoading, isError, reloadData } = useDataFetcher(
    GENERIC_TEMPLATES_ROUTES.GET_TEMPLATES(organization.id),
    {
      params: {
        ...paginationProps,
        ...searchParams,
        page: paginationProps.page + 1,
      },
      paramsSerializer: FETCH_PARAMS_SERIALIZER,
    }
  );

  useEffect(() => {
    setTemplates(data?.generic_templates_metadata);
    setTemplatesCount(data?.count);
  }, [data]);

  const handleSelectTemplate = (template_id: number) => {
    setSelectedTemplateId(template_id);
  };

  const handleUpdatePaginationProps = (updatedPaginationProps: PaginationProps) => {
    setPaginationProps(updatedPaginationProps);
  };

  const handleTemplatesSearchByName = (event: { target: { value: string } }) => {
    setSearchParams({
      ...searchParams,
      ...DEFAULT_TEMPLATES_PAGINATION_PROPS,
      template_name: event.target.value,
      template_type: templateType,
    });
  };

  const handleAdvancedTemplatesSearch = (values: AdvancedSearchFilterTypes) => {
    setSearchParams({
      ...searchParams,
      ...DEFAULT_TEMPLATES_PAGINATION_PROPS,
      ...values,
      template_type: templateType,
    });
  };

  const handleOpenCreateTemplateDialog = () => {
    setIsCreateTemplateDialogOpen(true);
  };

  const handleCloseCreateTemplateDialog = () => {
    setIsCreateTemplateDialogOpen(false);
  };

  const handleSubmitTemplate = async (values: TemplateFormSubmitValuesProps) => {
    try {
      await axios.post(GENERIC_TEMPLATES_ROUTES.CREATE_TEMPLATE(organization.id), values);
      await reloadData();
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
  };

  return (
    <div>
      <div className="m-12 grid grid-cols-2 justify-between p-12">
        <GenericTemplateBaseSearchContainer handleChange={handleTemplatesSearchByName} />
        {isCreateTemplateEnabled ? (
          <div className="flex items-center justify-end">
            <Button color="primary" disabled={false} onClick={handleOpenCreateTemplateDialog}>
              <AddIcon />
              Add template
            </Button>
          </div>
        ) : null}
      </div>
      <GenericTemplateAdvancedSearchContainerFormik handleSubmit={handleAdvancedTemplatesSearch} />
      <div className={cn('grid gap-4', selectedTemplateId ? 'grid-cols-12' : 'grid-cols-1')}>
        <div className="col-span-8">
          <LoadingSwitch isLoading={isLoading} isError={isError}>
            <GenericTemplatesTable
              templates={templates}
              templatesCount={templatesCount}
              onSelectTemplate={handleSelectTemplate}
              onUpdatePaginationProps={handleUpdatePaginationProps}
              paginationProps={paginationProps}
              shouldDisplayType={shouldDisplayType}
              selectedTemplateId={selectedTemplateId}
              reloadTable={reloadData}
            />
          </LoadingSwitch>
        </div>
        {selectedTemplateId ? (
          <div className="col-span-4">
            <GenericTemplateCardContainer templateId={selectedTemplateId} reloadTable={reloadData} />
          </div>
        ) : null}
        {isCreateTemplateDialogOpen ? (
          <GenericTemplateBuilderContainer
            templateType={templateType}
            handleClose={handleCloseCreateTemplateDialog}
            handleSubmit={handleSubmitTemplate}
          />
        ) : null}
      </div>
    </div>
  );
};

export default GenericTemplatesTableContainer;
