import React from 'react';
import PropTypes from 'prop-types';

import RadioButtonGroupFormik from '~/components/core/Formik/RadioButtonGroupFormik';

import { PERMISSION_VERBS_CONFIG } from '../../../../../core/Permissions/PermissionUtils';

import styles from './styles.module.scss';

export const PermissionVerbsButtonGroupFormik = ({ fieldId, allowedVerbsIds, onSelectionChange, viewOnly }) => {
  const handleButtonClick = (value) => {
    if (onSelectionChange) {
      onSelectionChange(value);
    }
  };

  const options = Object.values(PERMISSION_VERBS_CONFIG).map((verbConfig) => ({
    text: verbConfig.displayName,
    optionValue: verbConfig.optionValue,
    disabled: !allowedVerbsIds.includes(verbConfig.id),
  }));

  return (
    <div className={styles.buttonGroupContainer}>
      <RadioButtonGroupFormik
        id={fieldId}
        label=""
        options={options}
        onClick={handleButtonClick}
        buttonVariant={RadioButtonGroupFormik.BUTTON_VARIANT.DEFAULT}
        btnClassName={styles.buttonGroup}
        labelClassName={styles.buttonGroup}
        withErrorHelper={false}
        viewOnly={viewOnly}
      />
    </div>
  );
};

PermissionVerbsButtonGroupFormik.propTypes = {
  fieldId: PropTypes.string.isRequired,
  allowedVerbsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelectionChange: PropTypes.func,
  viewOnly: PropTypes.bool,
};
