import React from 'react';
import axios from 'axios';

import { reportErrorInProductionOrThrow } from '../../../../../Utils';
import { useCms } from '../../../../hooks/useCms';
import { ProfilesTable } from '../ProfilesTable/ProfilesTable';
import { PROFILES_ROUTES } from '../routes';

/**
 * @param profile
 * @returns {{profileStatus: {number_of_users: number, is_deleted: boolean, has_users: boolean}, reloadProfileStatus: ((function(): Promise<void>)|*)}}
 */
export const useProfileStatus = (profile) => {
  const { userOrganization } = useCms();

  const [profileStatus, setProfileStatus] = React.useState({
    is_deleted: false,
    has_users: Boolean(profile[ProfilesTable.COLUMNS.number_of_users]),
    number_of_users: profile[ProfilesTable.COLUMNS.number_of_users],
  });

  const reloadProfileStatus = React.useCallback(async () => {
    try {
      const { data } = await axios.get(PROFILES_ROUTES.STATUS(userOrganization.id, profile.id));
      setProfileStatus(data);
    } catch (e) {
      await reportErrorInProductionOrThrow(e);
    }
  }, [profile.id, userOrganization.id]);

  return { profileStatus, reloadProfileStatus };
};
