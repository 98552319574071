const LOSS_TYPES = {
  bodily_injury: {
    desc: 'Bodily Injury',
  },
  pecuniary_loss: {
    desc: 'Pecuniary Loss',
  },
  property_damage: {
    desc: 'Damaged Property',
  },
} as const;

export default LOSS_TYPES;
