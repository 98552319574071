const MOI_EXPERTISE_TYPES = {
  appraiser: {
    desc: 'Appraiser',
  },
  approved_body_shop: {
    desc: 'Approved Body Shop',
    included_claim_types: ['auto_claim'],
  },
  attorney: {
    desc: 'Attorney',
  },
  car_rental: {
    desc: 'Car Rental',
    included_claim_types: ['auto_claim'],
  },
  claim_adjusting: {
    desc: 'Claim Adjusting',
  },
  contractor: {
    desc: 'Contractor',
  },
  engineer: {
    desc: 'Engineer',
  },
  field_adjuster: {
    desc: 'Field Adjuster',
  },
  forensics: {
    desc: 'Forensics',
  },
  investigator: {
    desc: 'Investigator',
  },
  leak_detection_expert: {
    desc: 'Leak Detection Expert',
    included_claim_types: ['home_claim'],
  },
  legal_services: {
    desc: 'Legal Services',
  },
  medical_center: {
    desc: 'Medical Center',
  },
  medical_examiner: {
    desc: 'Medical Examiner',
  },
  medical_services: {
    desc: 'Medical Services',
  },
  mitigation_services: {
    desc: 'Mitigation Services',
    included_claim_types: ['home_claim'],
  },
  other: {
    desc: 'Other',
  },
  siu: {
    desc: 'SIU',
  },
  tow_lot: {
    desc: 'Tow Lot',
    included_claim_types: ['auto_claim'],
  },
  unapproved_body_shop: {
    desc: 'Unapproved Body Shop',
    included_claim_types: ['auto_claim'],
  },
  windscreen: {
    desc: 'Windscreen',
    included_claim_types: ['auto_claim'],
  },
  road_services: {
    desc: 'Road Services',
    included_claim_types: ['auto_claim'],
  },
} as const;

export default MOI_EXPERTISE_TYPES;
