import React from 'react';

import copies from '../copies.json';

import BaseConfigurationSection from './BaseConfigurationSection';

const InvolvedPartiesSection = () => {
  return (
    <BaseConfigurationSection
      title="Involved Parties"
      subtitle={copies.involved_parties.subtitle}
      isSection
      isCollapsible={false}
      withActionsContainerFullWidth={false}
    />
  );
};

export default InvolvedPartiesSection;
