import React from 'react';

import copies from '../copies.json';
import FieldConfigurationCard from '../FieldConfigurationCard/FieldConfigurationCard';

import BaseConfigurationSection from './BaseConfigurationSection';
import ConfiguredFieldsSection from './ConfiguredFieldsSection';

const ID_PREFIX = 'configuration.incident_details.pre_defined_fields';

const IncidentDetailsSection = () => {
  return (
    <BaseConfigurationSection title="Incident Details" subtitle="Key: incident_details" isSection>
      <FieldConfigurationCard idPrefix={ID_PREFIX} fieldId="date_of_loss" forceRequired />
      <FieldConfigurationCard idPrefix={ID_PREFIX} fieldId="time_of_loss" />
      <FieldConfigurationCard idPrefix={ID_PREFIX} fieldId="reporter_contact_id" />
      <FieldConfigurationCard idPrefix={ID_PREFIX} fieldId="preferred_contact_id" />
      <FieldConfigurationCard idPrefix={ID_PREFIX} fieldId="loss_location" />
      <FieldConfigurationCard idPrefix={ID_PREFIX} fieldId="description" />
      <FieldConfigurationCard idPrefix={ID_PREFIX} fieldId="special_internal_note" />
      <FieldConfigurationCard
        idPrefix={ID_PREFIX}
        fieldId="cat_code_name"
        infoTooltipText={copies.cat_code_name_tooltip}
      />
      <ConfiguredFieldsSection fieldId="configuration.incident_details.configured_fields" section="incident_details" />
    </BaseConfigurationSection>
  );
};

export default IncidentDetailsSection;
