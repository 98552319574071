const PAYMENT_ADDITIONAL_REQUIRED_FIELDS = {
  phone_number: {
    desc: 'Phone Number Field',
  },
  email: {
    desc: 'Email Field',
  },
  date_of_birth: {
    desc: 'Date of Birth',
  },
} as const;

export default PAYMENT_ADDITIONAL_REQUIRED_FIELDS;
