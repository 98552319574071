import React from 'react';
import type { FormikValues } from 'formik';
import { useFormikContext } from 'formik';
import { get } from 'lodash';

import BaseConfigurationSection from '~/components/SystemConfiguration/FnolConfiguration/FnolConfigurationScreen/Sections/BaseConfigurationSection';
import ConfiguredFieldsSection from '~/components/SystemConfiguration/FnolConfiguration/FnolConfigurationScreen/Sections/ConfiguredFieldsSection';

import copies from '../copies.json';

import SystemFieldsSection from './SystemFieldsSection/SystemFieldsSection';

const ID_PREFIX = 'configuration.policy.covered_objects';

const getSystemFields = (coveredObject: { desc: string; type: string }) => {
  const displayNameField = {
    id: 'display_name',
    desc: coveredObject?.desc?.replace('Covered ', ''),
    type: 'string',
    mandatory: true,
    active: true,
  };
  const contactField = {
    id: 'contact_id',
    desc: coveredObject?.type === 'property' ? 'Owner' : 'Name',
    type: 'contact',
    mandatory: true,
    active: true,
  };
  return coveredObject?.type === 'property' ? [displayNameField, contactField] : [contactField];
};

const GeneralCoveredObjectSection: React.FC = () => {
  const { values } = useFormikContext<FormikValues>();
  const coveredObject = get(values, 'configuration.policy.covered_objects[0]', {});

  return (
    <BaseConfigurationSection
      title={coveredObject.desc}
      subtitle={`Key: ${coveredObject.id}`}
      isSection
      noGridContainer
    >
      <SystemFieldsSection fields={getSystemFields(coveredObject)} subtitle={copies.system_fields_subtitle} />
      <ConfiguredFieldsSection fieldId={`${ID_PREFIX}.[0].configured_fields`} disabled={false} disableCategories />
    </BaseConfigurationSection>
  );
};

export default GeneralCoveredObjectSection;
