import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import Button from '~/components/core/Atomic/Buttons/Button';
import CollapsibleWrapper from '~/components/core/Collapsible/CollapsibleWrapper';
import GenericTemplateAdvancedSearchFiltersFormik from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplateSearch/GenericTemplateAdvancedSearch/GenericTemplateAdvancedSearchFiltersFormik';
import type { AdvancedSearchFilterTypes } from '~/components/GenericTemplates/types';
import { ADVANCED_TEMPLATES_SEARCH_KEYS } from '~/components/GenericTemplates/utils/genericTemplatesUtils';
import cn from '~/Utils/cn';

interface GenericTemplatesAdvancedSearchContainerProps {
  handleSubmit: (values: AdvancedSearchFilterTypes) => void;
}

const GenericTemplateAdvancedSearchContainerFormik: React.FC<GenericTemplatesAdvancedSearchContainerProps> = ({
  handleSubmit,
}) => {
  const classes = useStyles();

  const [isTemplatesAdvancedSearchOpen, setIsTemplatesAdvancedSearchOpen] = useState(false);

  return (
    <div className="mt-32">
      <CollapsibleWrapper
        title="Search Templates"
        actionCard
        noBorder
        open={isTemplatesAdvancedSearchOpen}
        onCollapse={() => setIsTemplatesAdvancedSearchOpen((prevOpen) => !prevOpen)}
        unmountOnExit={false}
        isActionCardDrawer={false}
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            coverage_keys: [],
            states: [],
            sub_organization_ids: [],
            lobs: [],
          }}
          validationSchema={Yup.object().shape({
            [ADVANCED_TEMPLATES_SEARCH_KEYS.COVERAGE_KEYS]: Yup.array().of(Yup.string()),
            [ADVANCED_TEMPLATES_SEARCH_KEYS.STATES]: Yup.array().of(Yup.string()),
            [ADVANCED_TEMPLATES_SEARCH_KEYS.SUB_ORGANIZATION_IDS]: Yup.array().of(Yup.number()),
            [ADVANCED_TEMPLATES_SEARCH_KEYS.LOBS]: Yup.array().of(Yup.string()),
          })}
          onSubmit={(values: AdvancedSearchFilterTypes) => {
            handleSubmit(values);
          }}
        >
          {({ handleSubmit, resetForm, isSubmitting }) => (
            <div className="grid">
              <GenericTemplateAdvancedSearchFiltersFormik />
              <div className="flex gap-12">
                <div className="mt-4">
                  <Button
                    onClick={() => resetForm()}
                    variant="contained"
                    className={classes.cancelButton}
                    disabled={isSubmitting}
                  >
                    Clear
                  </Button>
                </div>
                <div>
                  <Button
                    className={cn(classes.button, 'm-4, ml-12')}
                    onClick={() => handleSubmit()}
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Search
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </CollapsibleWrapper>
    </div>
  );
};

export default GenericTemplateAdvancedSearchContainerFormik;
