import React from 'react';

import Typography from '~/components/core/Atomic/Typography';
import Text from '~/components/core/TextComponents/Text';
import cn from '~/Utils/cn';

import copies from '../copies.json';

interface HeaderProps {
  title?: string;
  subtitle?: string;
}

const Header: React.FC<HeaderProps> = ({ title = copies.header.title, subtitle = copies.header.subtitle }) => {
  return (
    <div className="ml-4 w-full bg-slate-200 p-12 pb-20 pt-[12px]">
      <Typography display="block" variant="h6" className={cn('text-xl')}>
        {title}
      </Typography>
      <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.SM} className={cn('pt-12')}>
        {subtitle}
      </Text>
    </div>
  );
};

export default Header;
