import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, TextField } from '@material-ui/core';

import { useStyles } from '~/assets/styles';
import Button from '~/components/core/Atomic/Buttons/Button';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Chip from '~/components/core/Atomic/Chip/Chip';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';
import SwitchPanel from '~/components/core/ConfigPanel/SwitchPanel';
import EmptyState from '~/components/core/EmptyState';
import RoundEmptyStateWrapper from '~/components/core/EmptyState/RoundEmptyStateWrapper';
import { AddIcon } from '~/components/deprecatedMuiIcons';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import { isDevOrLocal, subOrgIdToNameDict } from '~/Utils';
import { getLobDescription, getLobIcon } from '~/Utils/lobUtils';

import CardDialog from '../../CardDialog';
import { LoadingSwitch, MultiSelectFilter, SortableTable } from '../../core';
import { CheckIcon, PencilIcon, PolicyIcon, RemoveIcon, TrashIcon } from '../../icons';
import InlineIconButton from '../../InlineIconButton';
import OperationsBreadcrumbs from '../OperationsBreadcrumbs';

import { MoiMethodDialog } from './MoiMethodDialog';

const MoiConfigurationTool = ({
  isLoading,
  isError,
  organization,
  supportedClaimTypes,
  moiConfiguration,
  moiMethods,
  onUpdateMoiMethod,
  onCreateMoiMethod,
  onDeleteMoiMethod,
  onToggleClaimLevelMoi,
  onToggleMultipleExposuresMoi,
  enableRemovingExistingMoiMethods,
  fetchUsedMoiMethodKeys,
  errorHandler,
  wizardLob,
  overrideDisabled,
}) => {
  const [searchText, setSearchText] = useState('');
  const [selectedLOB, setSelectedLOB] = useState([]);
  const [selectedSubOrgs, setSelectedSubOrgs] = useState([]);
  const [showMoiMethodDialog, setShowMoiMethodDialog] = useState(false);
  const [moiMethodToEdit, setMoiMethodToEdit] = useState(null);
  const subOrgIdToName = subOrgIdToNameDict(organization);
  const { organizationOperationalDetails } = useSysconfig();
  const { lobConfigurationsDict = {} } = useLobConfiguration();
  const claimLevelMoiEnabled = moiConfiguration?.claim_level_moi_enabled;
  const multipleExposuresMoiEnabled = moiConfiguration?.multiple_exposures_moi_enabled;

  const shouldAllowAddEdit = isDevOrLocal();

  const { subOrganizations } = organizationOperationalDetails;
  const classes = useStyles();

  const isMoiMethodFitFilters = useCallback(
    (moiMethod) => {
      const doesMatchText =
        (moiMethod.display_name?.toLowerCase() || '').includes(searchText.toLowerCase()) ||
        (moiMethod.key?.toLowerCase() || '').includes(searchText?.toLowerCase());
      const doesMatchLOB = selectedLOB.length === 0 || selectedLOB.includes(moiMethod.lob);
      const doesMatchSubOrgs =
        selectedSubOrgs.length === 0 ||
        moiMethod.is_org_level ||
        selectedSubOrgs.every((subOrg) => moiMethod.sub_organization_ids.includes(subOrg.id));

      return doesMatchText && doesMatchLOB && doesMatchSubOrgs;
    },
    [searchText, selectedLOB, selectedSubOrgs]
  );

  const onClose = () => {
    setMoiMethodToEdit(null);
    setShowMoiMethodDialog(false);
  };

  const convertServerMoiMethodToFormikValues = (moiMethod) => {
    return {
      ...moiMethod,
      sub_organization_ids: moiMethod.is_org_level ? ['all'] : moiMethod.sub_organization_ids,
    };
  };

  const yesOption = <CheckIcon width={18} height={18} iconColor="#909090" />;

  const actionsCell = ({ onDelete, onEdit }) => ({
    id: 'actions',
    numeric: false,
    label: 'Actions',
    specialCell: (config) => (
      <div>
        {shouldAllowAddEdit && (
          <InlineIconButton
            icon={PencilIcon}
            tooltipTitle="Edit"
            className={`${classes.textIcon} ${classes.marginedIcon} ${classes.hoverableNonFilledIcon}`}
            onClick={() => onEdit(config)}
            wrapWithSpan
          />
        )}
        {onDelete && enableRemovingExistingMoiMethods && (
          <InlineIconButton
            icon={TrashIcon}
            tooltipTitle="Delete"
            className={`${classes.textIcon} ${classes.marginedIcon} ${classes.hoverableNonFilledIcon}`}
            onClick={() => onDelete(config)}
            wrapWithSpan
          />
        )}
      </div>
    ),
  });

  const columnsData = [
    {
      id: 'display_name',
      numeric: false,
      label: 'Display Name',
      specialCell: (moiMethod) => moiMethod.display_name,
    },
    {
      id: 'key',
      numeric: false,
      label: 'Key',
      specialCell: (moiMethod) => moiMethod.key,
    },
    {
      id: 'method_type_key',
      numeric: false,
      label: 'Method Type Key',
      specialCell: (moiMethod) => <TextCell value={moiMethod.method_type_key} />,
    },
    {
      id: 'sub_organizations',
      numeric: false,
      label: 'Sub-Organizations',
      specialCell: (moiMethod) => {
        return moiMethod.is_org_level ? (
          <Chip key="all" size="small" label="All" />
        ) : (
          moiMethod.sub_organization_ids?.map((currSubOrgId) => (
            <Chip
              key={currSubOrgId}
              size="small"
              label={subOrganizations.find((subOrg) => subOrg.id === currSubOrgId)?.name}
              className={classes.chip}
            />
          ))
        );
      },
    },
    {
      id: 'lob',
      numeric: false,
      label: 'LOB',
      specialCell: (moiMethod) => <TextCell value={getLobDescription(moiMethod.lob, lobConfigurationsDict)} />,
    },
    {
      id: 'is_active',
      numeric: false,
      label: 'Active',
      specialCell: (moiMethod) => (moiMethod.is_active ? yesOption : ''),
    },
    actionsCell({
      onDelete: onDeleteMoiMethod,
      onEdit: (config) => {
        setMoiMethodToEdit(convertServerMoiMethodToFormikValues(config));
        setShowMoiMethodDialog(true);
      },
    }),
  ].filter((column) => column.id !== 'sub_organizations' || organization.sub_organizations_enabled);

  const moiMethodsFiltered = moiMethods?.filter(isMoiMethodFitFilters);

  const shouldShowLobFilter = (supportedClaimTypes?.length ?? 0) > 1;

  const addMoiMethodButton = (text, onClick) =>
    shouldAllowAddEdit ? (
      <Button color="primary" onClick={onClick} style={{ marginRight: 10, float: 'right', marginBottom: '14px' }}>
        <AddIcon className={classes.leftButtonIcon} />
        {text}
      </Button>
    ) : null;

  const getEmptyStateComponent = (text, onClick) => (
    <EmptyState
      subtitle="No MOI methods added yet"
      illustration={
        <RoundEmptyStateWrapper>
          <PolicyIcon />
        </RoundEmptyStateWrapper>
      }
      buttonComponent={addMoiMethodButton(text, onClick)}
    />
  );

  return (
    <LoadingSwitch isLoading={isLoading || !moiMethods} isError={isError}>
      <CardDialog noCardTitle>
        <OperationsBreadcrumbs currentTab="MOI Configuration" />
        <Typography variant="subtitle1" style={{ padding: '14px 0px', fontSize: '18px' }}>
          MOI Configuration
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ marginTop: '15px', marginBottom: '20px' }}>
            <Typography
              display="block"
              variant="subtitle2"
              style={{ paddingTop: '8px', fontWeight: 'bold', fontSize: '16px' }}
            >
              General Settings
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <SwitchPanel
              label="Multiple Exposure Assignment"
              checked={multipleExposuresMoiEnabled}
              onChange={onToggleMultipleExposuresMoi}
            />
          </Grid>
          <Grid item xs={3}>
            <SwitchPanel
              label="General (Claim) Assignment"
              checked={claimLevelMoiEnabled}
              onChange={onToggleClaimLevelMoi}
            />
          </Grid>
          <Grid item xs={6} />
        </Grid>
        <Grid container spacing={2} style={{ marginTop: '20px' }}>
          {moiMethods?.length > 0 && (
            <>
              <Grid item xs={12} style={{ marginTop: '15px', marginBottom: '20px' }}>
                <Typography
                  display="block"
                  variant="subtitle2"
                  style={{ paddingTop: '8px', fontWeight: 'bold', fontSize: '16px' }}
                >
                  Filters
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  className={classes.formTextField}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  InputProps={{
                    placeholder: 'Search MOI Method',
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton title="Clear" onClick={() => setSearchText('')}>
                          <RemoveIcon className={classes.hoverableNonStrokedIcon} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={10} />
              <Grid container spacing={4}>
                {shouldShowLobFilter && (
                  <>
                    <Grid item xs={2}>
                      <MultiSelectFilter
                        label="By LOB"
                        value={selectedLOB}
                        onChange={setSelectedLOB}
                        options={supportedClaimTypes}
                        renderOption={(lob) => (
                          <>
                            <div style={{ display: 'flex' }}>
                              <span style={{ marginRight: '10px' }}>{getLobIcon({ lob, lobConfigurationsDict })}</span>
                              <Typography display="block" variant="body1">
                                {getLobDescription(lob, lobConfigurationsDict)}
                              </Typography>
                            </div>
                          </>
                        )}
                        withOptionChips
                      />
                    </Grid>
                    <Grid item xs={1} />
                  </>
                )}
                {organization.sub_organizations_enabled && (
                  <Grid item xs={2}>
                    <MultiSelectFilter
                      label="By Sub-organization"
                      value={selectedSubOrgs}
                      onChange={setSelectedSubOrgs}
                      options={organization.sub_organizations.map((subOrg) => subOrg.id)}
                      renderOption={(subOrgId) => subOrgIdToName[subOrgId]}
                      withOptionChips
                    />
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </Grid>
        {moiMethods?.length > 0 && addMoiMethodButton('ADD MOI METHOD', () => setShowMoiMethodDialog(true))}
        <Grid item xs={12} style={{ marginTop: '15px', marginBottom: '20px' }}>
          {moiMethods?.length > 0 && (
            <Typography style={{ paddingTop: '8px', fontWeight: 'bold', fontSize: '16px' }}>MOI Methods</Typography>
          )}
        </Grid>
        <SortableTable
          columns={columnsData}
          rows={moiMethodsFiltered}
          stickyHeader
          maxHeight="50vh"
          emptyStateComponent={getEmptyStateComponent('ADD MOI METHOD', () => setShowMoiMethodDialog(true))}
        />

        {showMoiMethodDialog && (
          <MoiMethodDialog
            organization={organization}
            supportedClaimTypes={supportedClaimTypes}
            moiMethod={moiMethodToEdit}
            onClose={onClose}
            onSubmit={moiMethodToEdit ? onUpdateMoiMethod : onCreateMoiMethod}
            fetchUsedMoiMethodKeys={fetchUsedMoiMethodKeys}
            errorHandler={errorHandler}
            wizardLob={wizardLob}
            overrideDisabled={overrideDisabled}
          />
        )}
      </CardDialog>
    </LoadingSwitch>
  );
};

MoiConfigurationTool.propTypes = {
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  organization: PropTypes.object,
  supportedClaimTypes: PropTypes.arrayOf(PropTypes.string),
  moiConfiguration: PropTypes.object,
  moiMethods: PropTypes.arrayOf(PropTypes.object),
  onUpdateMoiMethod: PropTypes.func.isRequired,
  onCreateMoiMethod: PropTypes.func.isRequired,
  onDeleteMoiMethod: PropTypes.func,
  enableRemovingExistingMoiMethods: PropTypes.bool,
  fetchUsedMoiMethodKeys: PropTypes.func.isRequired,
  errorHandler: PropTypes.func.isRequired,
  hideExposureConfigurationFilters: PropTypes.bool,
  wizardLob: PropTypes.string,
  overrideDisabled: PropTypes.bool,
  onToggleClaimLevelMoi: PropTypes.bool,
  onToggleMultipleExposuresMoi: PropTypes.bool,
};

const TextCell = ({ value }) => <span style={{ textTransform: 'capitalize' }}>{value}</span>;

TextCell.propTypes = {
  value: PropTypes.string,
};

export { MoiConfigurationTool };
