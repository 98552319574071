import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import CardDialog from '~/components/CardDialog';
import Button from '~/components/core/Atomic/Buttons/Button';
import CancelButton from '~/components/core/Buttons/CancelButton';
import SwitchFormik from '~/components/core/Formik/SwitchFormik';
import { NumericTextFieldFormik } from '~/components/TextFieldFormik';

const ClosedExposureLimitDialogFormik = ({
  paymentsConfiguration,
  limitType,
  switchFormikId,
  limitFormikId,
  disabled = false,
  onCancel,
  onSubmit,
}) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        [switchFormikId]: !!paymentsConfiguration?.[switchFormikId],
        [limitFormikId]: paymentsConfiguration?.[limitFormikId] || '',
      }}
      validationSchema={Yup.object().shape({
        [switchFormikId]: Yup.boolean(),
        [limitFormikId]: Yup.number().when(switchFormikId, {
          is: true,
          then: Yup.number().typeError('Must be a number').positive('Must be a positive limit').required('Required'),
        }),
      })}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        try {
          await onSubmit(values);
        } catch (error) {
          formikProps.setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, values }) => {
        return (
          <CardDialog
            title={`Closed exposures ${limitType} payment limit`}
            isDialog
            onClose={onCancel}
            preventClose={disabled}
            maxWidth="sm"
          >
            <div>
              <SwitchFormik
                className={classes.formsSwitch}
                id={switchFormikId}
                label="Enable payment limit for closed exposures"
              />
            </div>
            <div>
              <NumericTextFieldFormik
                id={limitFormikId}
                fullWidth
                className={classes.textField}
                label="Limit"
                disabled={!values[switchFormikId]}
              />
            </div>
            <div className={classes.buttonsContainer}>
              <CancelButton disabled={disabled} onClick={onCancel} />
              <Button variant="contained" color="primary" disabled={disabled} onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

ClosedExposureLimitDialogFormik.propTypes = {
  paymentsConfiguration: PropTypes.object.isRequired,
  limitType: PropTypes.string.isRequired,
  switchFormikId: PropTypes.string.isRequired,
  limitFormikId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default ClosedExposureLimitDialogFormik;
