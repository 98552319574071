import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { JsonEditor } from 'jsoneditor-react';
import * as Yup from 'yup';

import Grid from '~/components/core/Atomic/Grid/Grid';

import TextFieldFormik from '../../TextFieldFormik';
import { useSysconfig } from '../SystemConfigurationScreen';

import OrganizationEntityConfiguration from './OrganizationEntityConfiguration';

import styles, { useStyles } from '../../../assets/styles';

const InsuredProperties = () => {
  const classes = useStyles();
  const { organization } = useSysconfig();

  const getUrl = `/sysconfig/api/v1/organizations/${organization.id}/insured_properties`;
  const generateUpdateDeleteUrl = (prop) => `${getUrl}/${prop.id}`;

  const getExtractedData = (values) => ({
    name: values.name,
    code: values.code,
    insured_property_extra: values.insured_property_extra,
    id: values.id,
  });

  const columnData = [
    { id: 'name', numeric: false, label: 'Name' },
    { id: 'code', numeric: false, label: 'Code' },
    {
      id: 'insured_property_extra',
      numeric: false,
      label: 'Extra',
      specialCell: (insuredProperty) => JSON.stringify(insuredProperty.insured_property_extra),
    },
  ];

  const getEntityInitialValues = (insured_property) => ({
    id: insured_property ? insured_property.id : null,
    name: insured_property ? insured_property.name : '',
    code: insured_property ? insured_property.code : '',
    insured_property_extra: insured_property ? insured_property.insured_property_extra : {},
  });

  const getValidationSchema = (_) => ({
    name: Yup.string().required('Required'),
    code: Yup.string().required('Required'),
    insured_property_extra: Yup.object(),
  });

  const getEditInputs = (_, formikProps) => {
    const { values, setFieldValue, setFieldError } = formikProps;
    return (
      <>
        <Grid item xs={12}>
          <TextFieldFormik id="name" label="Name" className={classes.textField} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <TextFieldFormik id="code" label="Code" fullWidth className={classes.textField} />
        </Grid>
        <Grid item xs={12}>
          <JsonEditor
            value={values?.insured_property_extra || {}}
            onChange={(value) => {
              setFieldValue('insured_property_extra', value);
            }}
            mode="text"
            allowedModes={['text', 'tree']}
            onValidationError={(errors) => {
              if (errors?.length) {
                setFieldError('insured_property_extra', errors[0].message);
              }
            }}
          />
        </Grid>
      </>
    );
  };

  // TODO: enable creation and deletion only after adding location support

  return (
    <OrganizationEntityConfiguration
      columnData={columnData}
      creatable={false}
      deletable={false}
      generateFetchUrl={() => getUrl}
      generateUpdateUrl={generateUpdateDeleteUrl}
      generateDeleteUrl={generateUpdateDeleteUrl}
      editable
      entityLabelSingular="Insured Property"
      entityLabelPlural="Insured Properties"
      getEditInputs={getEditInputs}
      getEntityInitialValues={getEntityInitialValues}
      getExtractedData={getExtractedData}
      getValidationSchema={getValidationSchema}
    />
  );
};

export default withStyles(styles)(InsuredProperties);
