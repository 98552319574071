import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { noop } from 'lodash';

import CardDialog from '~/components/CardDialog';
import DialogFooterActions from '~/components/core/DialogFooterActions';
import SearchWithOptionsFormik from '~/components/core/Formik/SearchWithOptionsFormik/SearchWithOptionsFormik';

import { buildSearchOption, getProfileName } from '../Utils';

export const MembersDialog = ({ unit, usersDict, onClose }) => {
  const selectOptionsEntries = React.useMemo(() => {
    return [
      ...unit.members.map((member) =>
        buildSearchOption({
          id: member.member_user_id,
          entryTitle: member.member_user_name,
          entrySubtitle: getProfileName({ user: usersDict[member.member_user_id] }),
        })
      ),
    ].sort((a, b) => a.entryTitle.localeCompare(b.entryTitle));
  }, [unit, usersDict]);

  return (
    <CardDialog
      isDialog={true}
      title="View Members"
      maxWidth="sm"
      onClose={onClose}
      fullWidth
      footerActions={<DialogFooterActions onClickSecondary={onClose} showPrimary={false} secondaryLabel="Close" />}
    >
      <Formik initialValues={{}} onSubmit={noop} validateOnChange={false}>
        <SearchWithOptionsFormik
          mainFieldId="users"
          isSelectAllEnabled
          label="Search Unit member"
          options={[
            {
              entries: selectOptionsEntries,
              title: '',
              subtitle: '',
            },
          ]}
          showOnly
        />
      </Formik>
    </CardDialog>
  );
};

MembersDialog.propTypes = {
  unit: PropTypes.shape({
    leader_user_id: PropTypes.string,
    leader_user_name: PropTypes.string,
    members: PropTypes.arrayOf(
      PropTypes.shape({
        member_user_id: PropTypes.string,
        member_user_name: PropTypes.string,
      })
    ),
  }),
  usersDict: PropTypes.shape({}),
  onClose: PropTypes.func,
};
