import React from 'react';
import { useFormikContext } from 'formik';
import { get } from 'lodash';

import { useStyles } from '~/assets/styles';
import Grid from '~/components/core/Atomic/Grid/Grid';
import SwitchFormik from '~/components/core/Formik/SwitchFormik';

import BaseConfigurationSection from './BaseConfigurationSection';

const TITLE = 'Files';
const KEY = 'documents';
const SECTION_ID = `configuration.${KEY}`;

const FilesSection = (): JSX.Element => {
  const classes = useStyles();
  const { values } = useFormikContext();
  const isHidden = !get(values, `${SECTION_ID}.active`, false);

  return (
    <BaseConfigurationSection title={TITLE} subtitle={`Key: ${KEY}`} isHidden={isHidden} isSection>
      <Grid item xs={2}>
        <SwitchFormik id={`${SECTION_ID}.active`} label="Show" className={classes.formsSwitch} hideErrorGap />
      </Grid>
      <Grid item xs={10} />
    </BaseConfigurationSection>
  );
};

export default FilesSection;
