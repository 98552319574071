import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';

function SectionWithColoredIndent({ children, colorPalette, colorType }) {
  const theme = useTheme();
  const backgroundColor = theme.palette[colorPalette]?.[colorType] || theme.palette.primary.main;

  return (
    <div style={{ borderLeft: `1px solid ${backgroundColor}` }}>
      <div style={{ marginLeft: '20px' }}>{children}</div>
    </div>
  );
}

SectionWithColoredIndent.propTypes = {
  children: PropTypes.node.isRequired,
  colorPalette: PropTypes.string.isRequired,
  colorType: PropTypes.string.isRequired,
};

export default SectionWithColoredIndent;
