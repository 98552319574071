import React from 'react';
import PropTypes from 'prop-types';

import { ADMIN_ROUTES } from '../../../routesConstants';
import ConfigurationScreenWithTabs from '../ConfigurationScreenWithTabs';

import CoveragesGroups from './CoveragesGroups';
import { ExposureConfigurationTool } from './ExposureConfiguration';

const COVERAGES_CONFIGURATIONS_SUBTITLE = 'Set up coverages and coverage groups.';

const CoveragesConfigurations = (props) => {
  const tabs = [
    {
      label: 'Coverages',
      url: ADMIN_ROUTES.COVERAGES.COVERAGES,
      component: () => <ExposureConfigurationTool {...props} />,
      isEnabled: true,
    },
    {
      label: 'Coverage Groups',
      url: ADMIN_ROUTES.COVERAGES.COVERAGES_GROUPS,
      component: CoveragesGroups,
      isEnabled: true,
    },
  ];

  return (
    <ConfigurationScreenWithTabs
      tabs={tabs}
      title="Coverage Configuration"
      subtitle={COVERAGES_CONFIGURATIONS_SUBTITLE}
      operationTabName="Coverage Configuration"
    />
  );
};

CoveragesConfigurations.propTypes = {
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  organization: PropTypes.object,
  supportedClaimTypes: PropTypes.arrayOf(PropTypes.string),
  coverages: PropTypes.arrayOf(PropTypes.object),
  sharedCoverages: PropTypes.arrayOf(PropTypes.object),
  coverageConfiguration: PropTypes.object,
  onUpdateCoverage: PropTypes.func.isRequired,
  onCreateCoverage: PropTypes.func.isRequired,
  onUpdateSharedCoverage: PropTypes.func.isRequired,
  onCreateSharedCoverage: PropTypes.func.isRequired,
  onToggleGeneralExpenses: PropTypes.func.isRequired,
  onUpdateCoverageConfiguration: PropTypes.func.isRequired,
  onDeleteCoverage: PropTypes.func,
  onDeleteSharedCoverage: PropTypes.func,
  enableRemovingExistingCoverages: PropTypes.bool,
  fetchUsedCoveragesKeys: PropTypes.func.isRequired,
  fetchUsedSharedCoveragesKeys: PropTypes.func.isRequired,
  fetchCoverages: PropTypes.func.isRequired,
  errorHandler: PropTypes.func.isRequired,
  hideExposureConfigurationFilters: PropTypes.bool,
  wizardLob: PropTypes.string,
  overrideDisabled: PropTypes.bool,
};

export default CoveragesConfigurations;
