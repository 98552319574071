import React from 'react';

import { useSysconfig } from '../SystemConfigurationScreen';

import SpecialUsersContainer from './SpecialUsersContainer';

const OrganizationSpecialUsersList = () => {
  const { organization } = useSysconfig();
  const url = `/sysconfig/api/v1/organizations/${organization.id}/special_user_lists`;
  const generateUpdateDeleteUrl = (prop) => `${url}/${prop.id}`;

  return (
    <SpecialUsersContainer
      generateFetchUrl={() => url}
      generateDeleteUrl={generateUpdateDeleteUrl}
      editable={false}
      entityLabelSingular="Special User"
      entityLabelPlural="Special User Lists"
    />
  );
};

export default OrganizationSpecialUsersList;
