import React from 'react';
import { string } from 'prop-types';

import styles from './card.module.scss';

const CardDetails = ({ title, value }) => (
  <div className={styles.cardDetails}>
    <span className={styles.cardDetailsTitle}>{title}</span>
    <span>{value}</span>
  </div>
);

CardDetails.propTypes = {
  title: string,
  value: string,
};

export default CardDetails;
