import React from 'react';

import SvgIcon from '../core/SvgIcon';

const ArrowUpwardIcon = (props) => (
  <SvgIcon stroke="none" {...props}>
    <path d="M2.66663 7.99996L3.60663 8.93996L7.33329 5.21996V13.3333H8.66663V5.21996L12.3866 8.94663L13.3333 7.99996L7.99996 2.66663L2.66663 7.99996Z" />
  </SvgIcon>
);

export default ArrowUpwardIcon;
