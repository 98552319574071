const CATASTROPHE_TYPES = [
  'Tornado',
  'Hurricane',
  'Hailstorm',
  'Wildfire',
  'Flood',
  'Wind',
  'Winter-Storm',
  'Earthquake',
  'Other',
] as const;

export default CATASTROPHE_TYPES;
