import React, { useState } from 'react';
import axios from 'axios';

import SwitchPanel from '~/components/core/ConfigPanel/SwitchPanel';

import { reportAxiosError } from '../../../../Utils';
import { Text } from '../../../core';
import ConfigPanel from '../../../core/ConfigPanel/ConfigPanel';
import TooltipIcon from '../../../core/TooltipIcon';
import { PencilIcon } from '../../../icons';
import InlineIconButton from '../../../InlineIconButton';
import useAssignments from '../../AssignmentsContext';
import { useSysconfig } from '../../SystemConfigurationScreen';
import { isLicensesEnabled } from '../../Tabs/AdjusterManagement/utils';

import { ConfigPanelLabel } from './ConfigPanelLabel';
import { FallbackAdjusterDialog } from './FallbackUserDialog';
import LoadBalancingConfigurationDialog from './LoadBalancingConfigurationDialog';
import MaxDailyAssignmentsDialog from './MaxDailyAssignmentsDialog';

import { useStyles } from '../../../../assets/styles';

export const AssignmentDefaultsControls = () => {
  const classes = useStyles();
  const { organization } = useSysconfig();
  const [isSelectAdjusterDialogOpen, setIsSelectAdjusterDialogOpen] = useState(false);
  const [isLoadBalancingDialogOpen, setIsLoadBalancingDialogOpen] = useState(false);
  const [isMaxDailyAssignmentsDialogOpen, setIsMaxDailyAssignmentsDialogOpen] = useState(false);
  const maxDailyAssignmentsUrl = `/api/v1/assignments/${organization.id}/max_daily_assignments`;
  const { defaultAdjusterName, reloadDefaults, isLicenseCorrelationEnabled, assignmentsConfigurations } =
    useAssignments();

  const handleToggleLicenseCorrelation = async (state) => {
    try {
      await axios.patch(`/api/v1/assignments/${organization.id}/defaults`, { is_license_correlation_enabled: state });
    } catch (error) {
      await reportAxiosError(error);
    } finally {
      await reloadDefaults();
    }
  };

  const handleOpenLoadBalancingConfigDialog = () => {
    setIsLoadBalancingDialogOpen(true);
  };

  const handleCloseLoadBalancingConfigDialog = () => {
    setIsLoadBalancingDialogOpen(false);
  };

  const handleDisableLoadBalancing = async () => {
    try {
      await axios.patch(`/api/v1/assignments/${organization.id}/load_balancing`, { is_load_balancing_enabled: false });
    } catch (error) {
      await reportAxiosError(error);
    } finally {
      await reloadDefaults();
    }
  };

  const handleSubmitLoadBalancingConfigurations = async (values) => {
    try {
      await axios.patch(`/api/v1/assignments/${organization.id}/load_balancing`, values);
      await reloadDefaults();
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
  };

  const handleOpenMaxDailyAssignmentsDialog = () => {
    setIsMaxDailyAssignmentsDialogOpen(true);
  };

  const handleCloseMaxDailyAssignmentsDialog = () => {
    setIsMaxDailyAssignmentsDialogOpen(false);
  };

  const handleDisableMaxDailyAssignments = async () => {
    try {
      await axios.patch(maxDailyAssignmentsUrl, {
        is_daily_max_enabled: false,
      });
      await reloadDefaults();
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const handleSubmitMaxDailyAssignments = async (values) => {
    try {
      await axios.patch(maxDailyAssignmentsUrl, { is_daily_max_enabled: true, ...values });
      await reloadDefaults();
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
  };

  return (
    <>
      <div className="mt-24 flex justify-center gap-20">
        <div className="flex-1">
          <ConfigPanel
            label={
              <ConfigPanelLabel
                title="Fallback User"
                tooltip="claims will be assigned to this assignee if no rules are applicable"
                info={defaultAdjusterName}
                action={
                  <div>
                    <TooltipIcon title="Edit Fallback User">
                      <InlineIconButton
                        icon={PencilIcon}
                        className={classes.hoverableNonFilledIcon}
                        onClick={() => setIsSelectAdjusterDialogOpen(true)}
                      />
                    </TooltipIcon>
                  </div>
                }
              />
            }
          />
        </div>
        {isLicensesEnabled(organization) ? (
          <div className="flex-1">
            <SwitchPanel
              checked={isLicenseCorrelationEnabled}
              onChange={(event) => {
                handleToggleLicenseCorrelation(event.target.checked);
              }}
              label={
                <ConfigPanelLabel
                  title="License Correlation"
                  tooltip="When license correlation is enabled, claims and exposures can be assigned only to adjusters with a relevant state license. When no licensed adjuster exists, the claim is sent to the Fallback User."
                  info={
                    <Text variant={Text.VARIANTS.XS} weight={Text.WEIGHTS.SEMI_BOLD}>
                      {isLicenseCorrelationEnabled ? 'Enabled' : 'Disabled'}
                    </Text>
                  }
                />
              }
            />
          </div>
        ) : null}
        <div className="flex-1">
          <SwitchPanel
            checked={assignmentsConfigurations?.is_load_balancing_enabled}
            onChange={(event) => {
              if (event.target.checked) {
                handleOpenLoadBalancingConfigDialog();
              } else {
                handleDisableLoadBalancing();
              }
            }}
            label={
              <ConfigPanelLabel
                title="Workload Balance"
                tooltip="When enabled assignment rules will balance the workload based on the number of newly assigned claims and exposures within a look-back period."
                info={
                  assignmentsConfigurations?.is_load_balancing_enabled
                    ? `Look back period: ${assignmentsConfigurations?.look_back_period_amount} ${assignmentsConfigurations?.look_back_period_type}`
                    : 'Disabled'
                }
                action={
                  <>
                    {assignmentsConfigurations?.is_load_balancing_enabled ? (
                      <div>
                        <TooltipIcon title="Edit Workload Balance">
                          <InlineIconButton
                            icon={PencilIcon}
                            className={classes.hoverableNonFilledIcon}
                            onClick={handleOpenLoadBalancingConfigDialog}
                          />
                        </TooltipIcon>
                      </div>
                    ) : null}
                  </>
                }
              />
            }
          />
        </div>
        <div className="flex-1">
          <SwitchPanel
            checked={assignmentsConfigurations?.is_daily_max_enabled}
            onChange={(event) => {
              if (event.target.checked) {
                handleOpenMaxDailyAssignmentsDialog();
              } else {
                handleDisableMaxDailyAssignments();
              }
            }}
            label={
              <ConfigPanelLabel
                title="Max Assignments Per Day"
                tooltip="When reaching the maximum capacity for all the adjusters in the assignment group the claim/exposure will be checked on the next rule."
                info={
                  assignmentsConfigurations?.is_daily_max_enabled
                    ? `Max Exposures: ${assignmentsConfigurations.max_daily_exposure_assignments}
                        Max Claims: ${assignmentsConfigurations.max_daily_claim_assignments}`
                    : 'Disabled'
                }
                action={
                  <>
                    {assignmentsConfigurations?.is_daily_max_enabled ? (
                      <div>
                        <TooltipIcon title="Edit Max Assignments Per Day">
                          <InlineIconButton
                            icon={PencilIcon}
                            className={classes.hoverableNonFilledIcon}
                            onClick={handleOpenMaxDailyAssignmentsDialog}
                          />
                        </TooltipIcon>
                      </div>
                    ) : null}
                  </>
                }
              />
            }
          />
        </div>
      </div>
      {isSelectAdjusterDialogOpen ? (
        <FallbackAdjusterDialog onClose={() => setIsSelectAdjusterDialogOpen(false)} />
      ) : null}
      {isLoadBalancingDialogOpen ? (
        <LoadBalancingConfigurationDialog
          handleClose={handleCloseLoadBalancingConfigDialog}
          handleSubmit={handleSubmitLoadBalancingConfigurations}
        />
      ) : null}
      {isMaxDailyAssignmentsDialogOpen ? (
        <MaxDailyAssignmentsDialog
          handleClose={handleCloseMaxDailyAssignmentsDialog}
          handleSubmit={handleSubmitMaxDailyAssignments}
        />
      ) : null}
    </>
  );
};
