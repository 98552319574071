import React from 'react';
import { useFormikContext } from 'formik';
import { get, isEmpty } from 'lodash';

import SubSectionWrapper from './SubSectionWrapper';

const ID_PREFIX = 'configuration.involved_parties';
const LOSS_OBJECT_ID = 'property_damage';
const LOSS_OBJECT_FIELD_IDS = [
  'location',
  'property_damages',
  'is_property_stolen',
  'property_damages_description',
  'note',
];

const PropertyLossSubSection = ({ partyKey }: { partyKey: string }): JSX.Element => {
  const { values } = useFormikContext();

  const isAllowed = get(values, `${ID_PREFIX}.${partyKey}.is_allowed_property_damage`, false);
  const id = `${ID_PREFIX}.${partyKey}.${LOSS_OBJECT_ID}`;
  const title = get(values, `${id}.desc`);

  if (!isAllowed || isEmpty(get(values, id, {}))) {
    return <></>;
  }

  return (
    <SubSectionWrapper
      id={id}
      idPrefix={`${ID_PREFIX}.${partyKey}`}
      title={title}
      sectionKey={LOSS_OBJECT_ID}
      lossSubSectionQuestionId="is_property_damaged"
      lossObjectFieldIds={LOSS_OBJECT_FIELD_IDS}
    />
  );
};

export default PropertyLossSubSection;
