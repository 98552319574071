import { isEmpty, uniqBy } from 'lodash';
import * as Yup from 'yup';

const configuredFieldSelectOptionSchema = Yup.object({
  id: Yup.string().required('Required'),
  desc: Yup.string().required('Required'),
  active: Yup.boolean().default(true),
});

const CONFIGURED_FIELD_TYPES = [
  'pre_defined_fields',
  'pre_defined_fields_array',
  'boolean',
  'contact',
  'date',
  'location',
  'monetary',
  'multiline_string',
  'multiselect',
  'number',
  'select',
  'string',
  'time',
  'us_state_select',
  'year',
  'yes_no_na_unknown',
];

const configuredFieldSchema = Yup.object({
  id: Yup.string()
    .required('Required')
    .when('type', {
      is: 'contact',
      then: Yup.string()
        .required('Required')
        .matches(/(contact_ref_id|contact_id)$/, 'Must end with "contact_ref_id"'),
      otherwise: Yup.string()
        .required('Required')
        .matches(/^(?!.*(contact_ref_id|contact_id)$)/, 'Cannot end with "contact_ref_id" or "contact_id"'),
    }),
  desc: Yup.string().required('Required'),
  type: Yup.string().oneOf(CONFIGURED_FIELD_TYPES).required('Required'),
  options: Yup.array()
    .when('type', {
      is: (type) => ['select', 'multiselect'].includes(type),
      then: Yup.array().of(configuredFieldSelectOptionSchema).min(1, 'At least one option is required'),
      otherwise: Yup.array().nullable(),
    })
    .test('unique-ids', 'Keys must be unique', (value) =>
      isEmpty(value) ? true : uniqBy(value, 'id')?.length === value?.length
    ),
  location_type: Yup.string().nullable(),
  active: Yup.boolean().default(true),
  mandatory: Yup.boolean().default(false),
  claim_closing_mandatory: Yup.boolean().default(false),
  db_field: Yup.string().nullable(),
});

const predefinedFieldBaseSchema = Yup.object({
  desc: Yup.string().required('Required'),
  active: Yup.boolean(),
  mandatory: Yup.boolean(),
  db_field: Yup.string().nullable(),
  geo_specific: Yup.string().oneOf(['US', 'GB']).nullable(),
  incident_sub_type_categories: Yup.array().of(Yup.string()).nullable(),
  dynamic: Yup.boolean().nullable(),
});

const preDefinedFieldsPredefinedFieldSchema = predefinedFieldBaseSchema.shape({
  type: Yup.string().oneOf(['pre_defined_fields']),
});

const booleanPredefinedFieldSchema = predefinedFieldBaseSchema.shape({
  type: Yup.string().oneOf(['boolean']),
});

const contactPredefinedFieldSchema = predefinedFieldBaseSchema.shape({
  type: Yup.string().oneOf(['contact']),
  accepted_roles: Yup.array().of(Yup.string()).nullable(),
});

const datePredefinedFieldSchema = predefinedFieldBaseSchema.shape({
  type: Yup.string().oneOf(['date']),
});

const yearPredefinedFieldSchema = predefinedFieldBaseSchema.shape({
  type: Yup.string().oneOf(['year']),
});

const locationPredefinedFieldSchema = predefinedFieldBaseSchema.shape({
  type: Yup.string().oneOf(['location']),
  location_type: Yup.string().nullable(),
});

const multilineStringPredefinedFieldSchema = predefinedFieldBaseSchema.shape({
  type: Yup.string().oneOf(['multiline_string', 'string']),
});

const multilineOrSelectPredefinedFieldSchema = predefinedFieldBaseSchema.shape({
  type: Yup.string().oneOf(['multiline_string', 'string', 'select']),
  options: Yup.array()
    .when('type', {
      is: (type) => ['select'].includes(type),
      then: Yup.array().of(configuredFieldSelectOptionSchema).min(1, 'At least one option is required'),
      otherwise: Yup.array().nullable(),
    })
    .test('unique-ids', 'Keys must be unique', (value) =>
      isEmpty(value) ? true : uniqBy(value, 'id')?.length === value?.length
    ),
});

const monetaryPredefinedFieldSchema = predefinedFieldBaseSchema.shape({
  type: Yup.string().oneOf(['monetary']),
});

const numberPredefinedFieldSchema = predefinedFieldBaseSchema.shape({
  type: Yup.string().oneOf(['number']),
});

const multiselectPredefinedFieldSchema = predefinedFieldBaseSchema.shape({
  type: Yup.string().oneOf(['multiselect']),
  options: Yup.array()
    .of(configuredFieldSelectOptionSchema)
    .nullable()
    .test('unique-ids', 'Keys must be unique', (value) =>
      isEmpty(value) ? true : uniqBy(value, 'id')?.length === value?.length
    ),
});

const stringOrSelectPredefinedFieldSchema = predefinedFieldBaseSchema.shape({
  type: Yup.string().oneOf(['string', 'select']),
  options: Yup.array()
    .when('type', {
      is: (type) => ['select'].includes(type),
      then: Yup.array().of(configuredFieldSelectOptionSchema).min(1, 'At least one option is required'),
      otherwise: Yup.array().nullable(),
    })
    .test('unique-ids', 'Keys must be unique', (value) =>
      isEmpty(value) ? true : uniqBy(value, 'id')?.length === value?.length
    ),
});

const selectOrStringPredefinedFieldSchema = stringOrSelectPredefinedFieldSchema;

const dynamicSelectPredefinedFieldSchema = stringOrSelectPredefinedFieldSchema.shape({
  options: Yup.array().nullable(),
});

const timePredefinedFieldSchema = predefinedFieldBaseSchema.shape({
  type: Yup.string().oneOf(['time']),
});

const yesNoNaUnknownPredefinedFieldSchema = predefinedFieldBaseSchema.shape({
  type: Yup.string().oneOf(['yes_no_na_unknown']),
  disable_na: Yup.boolean().default(false),
  disable_unknown: Yup.boolean().default(false),
});

const usStateSelectPredefinedField = predefinedFieldBaseSchema.shape({
  type: Yup.string().oneOf(['us_state_select']),
});

const fieldDependentSchema = (fieldKey, schema) =>
  Yup.object()
    .nullable()
    .when(fieldKey, {
      is: (val) => !!val,
      then: schema,
    });

const incidentSubTypeSchema = Yup.object({
  id: Yup.string().required('Required'),
  desc: Yup.string().required('Required'),
  active: Yup.boolean().nullable(),
  category: Yup.string().nullable(),
});

const incidentTypeSchema = Yup.object({
  id: Yup.string().required('Required'),
  desc: Yup.string().required('Required'),
  active: Yup.boolean().nullable(),
  sub_types: Yup.array()
    .of(incidentSubTypeSchema)
    .min(1, 'At least one sub type is required')
    .test('unique-ids', 'Keys must be unique', (value) => uniqBy(value, 'id')?.length === value?.length),
});

const AutoClaimPoliceAndFireDepartmentPredefinedFields = predefinedFieldBaseSchema.shape({
  desc: Yup.string().nullable(),
  were_police_involved: yesNoNaUnknownPredefinedFieldSchema,
  police_crime_reference_number: stringOrSelectPredefinedFieldSchema,
  police_department_contact_id: contactPredefinedFieldSchema,
  police_report: stringOrSelectPredefinedFieldSchema,
  did_speak_with_officer: yesNoNaUnknownPredefinedFieldSchema,
  was_ticket_received: yesNoNaUnknownPredefinedFieldSchema,
  violation_title: stringOrSelectPredefinedFieldSchema,
  police_description: stringOrSelectPredefinedFieldSchema,
  were_fire_department_involved: yesNoNaUnknownPredefinedFieldSchema,
  fire_department_description: stringOrSelectPredefinedFieldSchema,
  were_any_hazardous_good_being_carried: yesNoNaUnknownPredefinedFieldSchema,
  pending_prosecutions_or_investigations_exists: yesNoNaUnknownPredefinedFieldSchema,
});

const AutoClaimAccidentFollowUpPredefinedFields = predefinedFieldBaseSchema.shape({
  desc: Yup.string().nullable(),
  journey_purpose: selectOrStringPredefinedFieldSchema,
  weather_conditions: selectOrStringPredefinedFieldSchema,
  direction_ph_vehicle_was_going: stringOrSelectPredefinedFieldSchema,
  first_party_way_sign_or_a_headlight: multilineStringPredefinedFieldSchema,
  street_condition: stringOrSelectPredefinedFieldSchema,
  was_ambulance_called: yesNoNaUnknownPredefinedFieldSchema,
  was_first_party_driver_using_the_phone: yesNoNaUnknownPredefinedFieldSchema,
  were_first_party_vehicle_lights_on: yesNoNaUnknownPredefinedFieldSchema,
  was_anyone_under_the_influence_of_drugs_alcohol: yesNoNaUnknownPredefinedFieldSchema,
});

const AutoClaimTheftFollowUpPredefinedFields = predefinedFieldBaseSchema.shape({
  desc: Yup.string().nullable(),
  date_vehicle_last_seen: datePredefinedFieldSchema,
  time_vehicle_last_seen: timePredefinedFieldSchema,
  vehicle_last_seen_location: locationPredefinedFieldSchema,
  keys_location: locationPredefinedFieldSchema,
  vehicle_windows_closed_and_secured: yesNoNaUnknownPredefinedFieldSchema,
  vehicle_fitted_with_security_devices: yesNoNaUnknownPredefinedFieldSchema,
  security_devices_description: multilineStringPredefinedFieldSchema,
  vehicle_fitted_with_tracking_devices: yesNoNaUnknownPredefinedFieldSchema,
  tracking_devices_description: multilineStringPredefinedFieldSchema,
  is_vehicle_for_sale: yesNoNaUnknownPredefinedFieldSchema,
  were_belongings_stolen_from_vehicle: yesNoNaUnknownPredefinedFieldSchema,
  belongings_stolen: multilineStringPredefinedFieldSchema,
  discovered_the_loss_contact_id: contactPredefinedFieldSchema,
  crime_reference_number: stringOrSelectPredefinedFieldSchema,
  num_of_keys_received_on_purchase: numberPredefinedFieldSchema,
  current_num_of_keys: numberPredefinedFieldSchema,
  was_vehicle_found_with_keys: yesNoNaUnknownPredefinedFieldSchema,
  date_noticed_vehicle_missing: datePredefinedFieldSchema,
  time_noticed_vehicle_missing: timePredefinedFieldSchema,
  person_liable_for_the_theft_is_known: yesNoNaUnknownPredefinedFieldSchema,
  note: multilineStringPredefinedFieldSchema,
});

const AutoClaimFaultAssessmentPredefinedFields = predefinedFieldBaseSchema.shape({
  desc: Yup.string().nullable(),
  first_party_fault_assessment: selectOrStringPredefinedFieldSchema,
  claim_handler_fault_assessment: selectOrStringPredefinedFieldSchema,
  percentage_of_insured_liability: selectOrStringPredefinedFieldSchema,
  is_indemnity_review_needed: yesNoNaUnknownPredefinedFieldSchema,
  is_fraud_review_needed: yesNoNaUnknownPredefinedFieldSchema,
  liability_summary: multilineOrSelectPredefinedFieldSchema,
});

const AutoIncidentAdditionalPredefinedFieldsSchema = Yup.object({
  was_replacement_car_rented: booleanPredefinedFieldSchema,
  replacement_car_rental_date: datePredefinedFieldSchema,
  are_convictions_or_claims_in_the_last_5_years: yesNoNaUnknownPredefinedFieldSchema,
  convictions_or_claim_description: stringOrSelectPredefinedFieldSchema,
  is_policyholder_occupation_right: yesNoNaUnknownPredefinedFieldSchema,
  policyholder_current_occupation: stringOrSelectPredefinedFieldSchema,
  does_policyholder_second_occupation_exist: yesNoNaUnknownPredefinedFieldSchema,
  policyholder_second_occupation: stringOrSelectPredefinedFieldSchema,
  police_and_fire_department: AutoClaimPoliceAndFireDepartmentPredefinedFields,
  accident_follow_up: AutoClaimAccidentFollowUpPredefinedFields,
  theft_follow_up: AutoClaimTheftFollowUpPredefinedFields,
  fault_assessment: AutoClaimFaultAssessmentPredefinedFields,
});

const incidentDetailsPredefinedFieldsBaseSchema = (lob) =>
  Yup.object({
    supplementary_information: preDefinedFieldsPredefinedFieldSchema,
    date_of_loss: datePredefinedFieldSchema,
    time_of_loss: timePredefinedFieldSchema,
    preferred_contact_id: contactPredefinedFieldSchema,
    reporter_contact_id: contactPredefinedFieldSchema,
    special_internal_note: multilineStringPredefinedFieldSchema,
    description: multilineStringPredefinedFieldSchema,
    cat_code_name: dynamicSelectPredefinedFieldSchema,
    loss_location: locationPredefinedFieldSchema,
    is_notification_only: booleanPredefinedFieldSchema,
    were_there_any_witnesses: yesNoNaUnknownPredefinedFieldSchema,
    witnesses_note: stringOrSelectPredefinedFieldSchema,
    is_other_policy_covering: yesNoNaUnknownPredefinedFieldSchema,
    other_policy_description: stringOrSelectPredefinedFieldSchema,
    was_cctv_footage: yesNoNaUnknownPredefinedFieldSchema,
    can_take_photos_of_damage: yesNoNaUnknownPredefinedFieldSchema,
    did_anyone_take_photos_or_video: yesNoNaUnknownPredefinedFieldSchema,
    photographer_contact_id: contactPredefinedFieldSchema,
    auto_incident_additional: lob === 'auto_claim' ? AutoIncidentAdditionalPredefinedFieldsSchema : undefined,
  });

const typesSchema = Yup.array()
  .of(incidentTypeSchema)
  .min(1, 'At least one type is required')
  .test('unique-ids', 'Keys must be unique', (value) => uniqBy(value, 'id')?.length === value?.length);

const incidentDetailsProductConfigBaseSchema = (lob) =>
  Yup.object({
    types: typesSchema,
    pre_defined_fields: incidentDetailsPredefinedFieldsBaseSchema(lob),
    configured_fields: Yup.array()
      .of(configuredFieldSchema)
      .test(
        'configured-fields-unique-ids',
        'Additional Fields keys must be unique',
        (value) => uniqBy(value, 'id')?.length === value?.length
      ),
  });

const GeneralClaimLossesPredefinedFieldsArray = predefinedFieldBaseSchema.shape({
  desc: Yup.string().nullable(),
  display_name: stringOrSelectPredefinedFieldSchema,
  claimed_amount: monetaryPredefinedFieldSchema,
  claimed_loss: stringOrSelectPredefinedFieldSchema,
  note: multilineStringPredefinedFieldSchema,
});

const GeneralClaimInvolvedRelatedPropertyLossItemsPredefinedFieldsArray = predefinedFieldBaseSchema.shape({
  desc: Yup.string().nullable(),
  description: stringOrSelectPredefinedFieldSchema,
  category: selectOrStringPredefinedFieldSchema,
  quantity: numberPredefinedFieldSchema,
  purchase_date: datePredefinedFieldSchema,
  purchase_price: monetaryPredefinedFieldSchema,
  claimed_amount: monetaryPredefinedFieldSchema,
  scheduled: selectOrStringPredefinedFieldSchema,
});

const GeneralClaimInvolvedRelatedPropertyLossPredefinedFieldsSchema = predefinedFieldBaseSchema.shape({
  location: locationPredefinedFieldSchema,
  damages: multiselectPredefinedFieldSchema,
  damages_description: stringOrSelectPredefinedFieldSchema,
  is_stolen: booleanPredefinedFieldSchema,
  items: GeneralClaimInvolvedRelatedPropertyLossItemsPredefinedFieldsArray,
  note: multilineStringPredefinedFieldSchema,
});

const GeneralClaimInvolvedPecuniaryLossObjectSchema = predefinedFieldBaseSchema.shape({
  desc: Yup.string().nullable(),
  losses: GeneralClaimLossesPredefinedFieldsArray,
});

const GeneralClaimInvolvedBodilyInjuryLossBodilyInjuriesPredefinedFieldsArray = predefinedFieldBaseSchema.shape({
  desc: Yup.string().nullable(),
  injury_type: selectOrStringPredefinedFieldSchema,
  body_part: selectOrStringPredefinedFieldSchema,
  other_body_part: stringOrSelectPredefinedFieldSchema,
  injury_severity: selectOrStringPredefinedFieldSchema,
  description: stringOrSelectPredefinedFieldSchema,
});

const AutoClaimInvolvedPropertyInheritProductConfigSchema = predefinedFieldBaseSchema.shape({
  desc: Yup.string().nullable(),
  owner_contact_id: contactPredefinedFieldSchema,
  attorney_contact_id: contactPredefinedFieldSchema,
  attorney_reference_number: stringOrSelectPredefinedFieldSchema,
  insurer_contact_id: contactPredefinedFieldSchema,
  insurer_reference_number: stringOrSelectPredefinedFieldSchema,
  insurer_policy_number: stringOrSelectPredefinedFieldSchema,
  location: locationPredefinedFieldSchema,
});

const AutoClaimInvolvedGenericPropertyProductConfig = AutoClaimInvolvedPropertyInheritProductConfigSchema.shape({
  damages: multiselectPredefinedFieldSchema,
  property_description: stringOrSelectPredefinedFieldSchema,
  damages_description: stringOrSelectPredefinedFieldSchema,
  is_stolen: booleanPredefinedFieldSchema,
  property_items: GeneralClaimInvolvedRelatedPropertyLossItemsPredefinedFieldsArray,
});

const AutoClaimInvolvedVehicleProductConfig = AutoClaimInvolvedPropertyInheritProductConfigSchema.shape({
  vehicle_location: locationPredefinedFieldSchema,
  is_vehicle_camera_installed: yesNoNaUnknownPredefinedFieldSchema,
  is_vehicle_camera_active: yesNoNaUnknownPredefinedFieldSchema,
  scheduling_inspection_contact_id: contactPredefinedFieldSchema,
  point_of_impact: selectOrStringPredefinedFieldSchema,
  damage_areas: multiselectPredefinedFieldSchema,
  damage_level: selectOrStringPredefinedFieldSchema,
  damage_description: stringOrSelectPredefinedFieldSchema,
  is_tow: yesNoNaUnknownPredefinedFieldSchema,
  tower_contact_id: contactPredefinedFieldSchema,
  is_drivable: yesNoNaUnknownPredefinedFieldSchema,
  is_car_safe_to_drive: yesNoNaUnknownPredefinedFieldSchema,
  auto_repair_facility_contact_id: contactPredefinedFieldSchema,
  appraiser_contact_id: contactPredefinedFieldSchema,
  note: multilineStringPredefinedFieldSchema,
  does_the_vehicle_have_any_modifications: yesNoNaUnknownPredefinedFieldSchema,
  modification_description: stringOrSelectPredefinedFieldSchema,
  did_vehicle_have_previous_damage: yesNoNaUnknownPredefinedFieldSchema,
  previous_damage_description: stringOrSelectPredefinedFieldSchema,
  previous_damage_level: selectOrStringPredefinedFieldSchema,
  vehicle_speed: stringOrSelectPredefinedFieldSchema,
  child_seats: yesNoNaUnknownPredefinedFieldSchema,
  is_total_loss: yesNoNaUnknownPredefinedFieldSchema,
});

const AutoClaimInvolvedPersonInheritProductConfig = predefinedFieldBaseSchema.shape({
  desc: Yup.string().nullable(),
  contact_id: contactPredefinedFieldSchema,
  relationship_to_policyholder: stringOrSelectPredefinedFieldSchema,
  was_ambulance_needed: yesNoNaUnknownPredefinedFieldSchema,
  was_hospitalized: yesNoNaUnknownPredefinedFieldSchema,
  hospital_contact_id: contactPredefinedFieldSchema,
  admission_datetime: datePredefinedFieldSchema,
  discharge_datetime: datePredefinedFieldSchema,
  attorney_contact_id: contactPredefinedFieldSchema,
  attorney_reference_number: stringOrSelectPredefinedFieldSchema,
  representative_contact_id: contactPredefinedFieldSchema,
  note: multilineStringPredefinedFieldSchema,
  legal_venue_state: usStateSelectPredefinedField,
  legal_venue_reason: stringOrSelectPredefinedFieldSchema,
  bodily_injuries: GeneralClaimInvolvedBodilyInjuryLossBodilyInjuriesPredefinedFieldsArray,
  injuries_description: multilineStringPredefinedFieldSchema,
  insurer_contact_id: contactPredefinedFieldSchema,
  insurer_reference_number: stringOrSelectPredefinedFieldSchema,
  insurer_policy_number: stringOrSelectPredefinedFieldSchema,
});

const AutoClaimInvolvedDriverProductConfig = AutoClaimInvolvedPersonInheritProductConfig.shape({
  was_seat_belt_worn: yesNoNaUnknownPredefinedFieldSchema,
  license_issuing_state: usStateSelectPredefinedField,
  license_number: stringOrSelectPredefinedFieldSchema,
  license_issue_date: datePredefinedFieldSchema,
  license_type: selectOrStringPredefinedFieldSchema,
  years_of_driving_experience: numberPredefinedFieldSchema,
  who_authorized_the_driver_to_drive_the_vehicle: stringOrSelectPredefinedFieldSchema,
  any_dvla_medical_conditions: yesNoNaUnknownPredefinedFieldSchema,
  dvla_medical_conditions_description: multilineStringPredefinedFieldSchema,
});

const AutoClaimInvolvedPassengerProductConfig = AutoClaimInvolvedPersonInheritProductConfig.shape({
  was_seat_belt_worn: yesNoNaUnknownPredefinedFieldSchema,
  position: selectOrStringPredefinedFieldSchema,
});

const AutoClaimInvolvedNonMotoristProductConfig = AutoClaimInvolvedPersonInheritProductConfig.shape({
  means_of_transportation: selectOrStringPredefinedFieldSchema,
});

const GeneralClaimInvolvedPartyBaseSchema = Yup.object().shape({
  active: Yup.boolean().required(),
  type: Yup.string().oneOf(['person', 'property']).required(),
  desc: Yup.string().required(),
  is_first_party: Yup.boolean().required(),
  is_multiple_first_parties: Yup.boolean().nullable(),
  configured_fields: Yup.array()
    .of(configuredFieldSchema)
    .test(
      'configured-fields-unique-ids',
      'Additional Fields keys must be unique',
      (value) => uniqBy(value, 'id')?.length === value?.length
    ),
  relationship_to_policyholder: stringOrSelectPredefinedFieldSchema,
  attorney_contact_id: contactPredefinedFieldSchema,
  attorney_reference_number: stringOrSelectPredefinedFieldSchema,
  representative_contact_id: contactPredefinedFieldSchema,
  insurer_contact_id: contactPredefinedFieldSchema,
  insurer_reference_number: stringOrSelectPredefinedFieldSchema,
  insurer_policy_number: stringOrSelectPredefinedFieldSchema,
  is_allowed_related_damaged_property: Yup.boolean(),
  is_related_property_loss: fieldDependentSchema(
    'is_allowed_related_damaged_property',
    yesNoNaUnknownPredefinedFieldSchema
  ),
  related_property_loss: fieldDependentSchema(
    'is_allowed_related_damaged_property',
    GeneralClaimInvolvedRelatedPropertyLossPredefinedFieldsSchema
  ),
  is_allowed_pecuniary_loss: Yup.boolean(),
  is_pecuniary_loss: fieldDependentSchema('is_allowed_pecuniary_loss', yesNoNaUnknownPredefinedFieldSchema),
  pecuniary_loss: fieldDependentSchema('is_allowed_pecuniary_loss', GeneralClaimInvolvedPecuniaryLossObjectSchema),
});

const GeneralClaimInvolvedBodilyInjuryLossPredefinedFields = predefinedFieldBaseSchema.shape({
  was_ambulance_needed: yesNoNaUnknownPredefinedFieldSchema,
  was_hospitalized: yesNoNaUnknownPredefinedFieldSchema,
  hospital_contact_id: contactPredefinedFieldSchema,
  admission_datetime: datePredefinedFieldSchema,
  discharge_datetime: datePredefinedFieldSchema,
  legal_venue_state: usStateSelectPredefinedField,
  legal_venue_reason: stringOrSelectPredefinedFieldSchema,
  bodily_injuries: GeneralClaimInvolvedBodilyInjuryLossBodilyInjuriesPredefinedFieldsArray,
  injuries_description: multilineStringPredefinedFieldSchema,
  note: multilineStringPredefinedFieldSchema,
});

const GeneralClaimInvolvedPropertyDamagePredefinedFields = predefinedFieldBaseSchema.shape({
  property_damages: multiselectPredefinedFieldSchema,
  property_damages_description: stringOrSelectPredefinedFieldSchema,
  location: locationPredefinedFieldSchema,
  is_property_stolen: booleanPredefinedFieldSchema,
  note: multilineStringPredefinedFieldSchema,
});

const PropertyGeneralClaimInvolvedPartySchema = GeneralClaimInvolvedPartyBaseSchema.shape({
  type: Yup.string().oneOf(['property']),
  property_description: stringOrSelectPredefinedFieldSchema,
  owner_contact_id: contactPredefinedFieldSchema,
  is_allowed_property_damage: Yup.boolean(),
  is_property_damaged: fieldDependentSchema('is_allowed_property_damage', yesNoNaUnknownPredefinedFieldSchema),
  property_damage: fieldDependentSchema(
    'is_allowed_property_damage',
    GeneralClaimInvolvedPropertyDamagePredefinedFields
  ),
});

const PersonGeneralClaimInvolvedPartySchema = GeneralClaimInvolvedPartyBaseSchema.shape({
  type: Yup.string().oneOf(['person']),
  contact_id: contactPredefinedFieldSchema,
  is_allowed_bodily_injury: Yup.boolean(),
  is_injured: fieldDependentSchema('is_allowed_bodily_injury', yesNoNaUnknownPredefinedFieldSchema),
  bodily_injury_loss: fieldDependentSchema(
    'is_allowed_bodily_injury',
    GeneralClaimInvolvedBodilyInjuryLossPredefinedFields
  ),
});

const involvedPartiesProductConfigSchema = (lob, configuration) => {
  if (lob === 'auto_claim') {
    return Yup.object({
      involved_vehicle: AutoClaimInvolvedVehicleProductConfig,
      involved_driver: AutoClaimInvolvedDriverProductConfig,
      involved_passenger: AutoClaimInvolvedPassengerProductConfig,
      involved_non_motorist: AutoClaimInvolvedNonMotoristProductConfig,
      involved_generic_property: AutoClaimInvolvedGenericPropertyProductConfig,
    });
  }
  return Yup.lazy((_values) =>
    Yup.object(
      Object.keys(configuration?.involved_parties || {}).reduce((acc, partyKey) => {
        switch (configuration?.involved_parties[partyKey]?.type) {
          case 'person':
            acc[partyKey] = PersonGeneralClaimInvolvedPartySchema;
            break;
          case 'property':
            acc[partyKey] = PropertyGeneralClaimInvolvedPartySchema;
            break;
          default:
            break;
        }
        return acc;
      }, {})
    )
  );
};

const fnolProductConfigSchema = Yup.object({
  submission_mode: selectOrStringPredefinedFieldSchema,
  fnol_policyholder_contact_id: contactPredefinedFieldSchema,
  fnol_handler_contact_id: contactPredefinedFieldSchema,
  reported_date: datePredefinedFieldSchema,
  instructions: multilineStringPredefinedFieldSchema,
});

const witnessesProductConfigSchema = Yup.object({
  active: Yup.boolean(),
  relationship_to_policyholder: stringOrSelectPredefinedFieldSchema,
});

const documentsProductConfigSchema = Yup.object({
  active: Yup.boolean(),
});

const coveredObjectSchema = Yup.object({
  id: Yup.string().required('Required'),
  desc: Yup.string().required('Required'),
  type: Yup.string().nullable().oneOf(['person', 'property']).required('Required'),
  configured_fields: Yup.array()
    .of(configuredFieldSchema)
    .test('configured-fields-unique-ids', 'Additional Fields keys must be unique', (value) =>
      value?.length ? uniqBy(value, 'id')?.length === value?.length : true
    ),
});

const policyProductConfigSchema = (lob) =>
  Yup.object({
    configured_fields: Yup.array()
      .of(configuredFieldSchema)
      .test('configured-fields-unique-ids', 'Additional Fields keys must be unique', (value) =>
        value?.length ? uniqBy(value, 'id')?.length === value?.length : true
      ),
    covered_objects: lob !== 'auto_claim' ? Yup.array().nullable().of(coveredObjectSchema) : undefined,
    covered_vehicle:
      lob === 'auto_claim'
        ? Yup.object({
            vehicle: Yup.object({
              year: yearPredefinedFieldSchema,
              make: stringOrSelectPredefinedFieldSchema,
              model: stringOrSelectPredefinedFieldSchema,
              series: stringOrSelectPredefinedFieldSchema,
              plate_number: stringOrSelectPredefinedFieldSchema,
              vehicle_plate_state: usStateSelectPredefinedField,
              vin: stringOrSelectPredefinedFieldSchema,
              color: stringOrSelectPredefinedFieldSchema,
              vehicle_type: stringOrSelectPredefinedFieldSchema,
              vehicle_owner_contact_id: contactPredefinedFieldSchema,
              ownership_status: stringOrSelectPredefinedFieldSchema,
              mileage: numberPredefinedFieldSchema,
              fuel_type: stringOrSelectPredefinedFieldSchema,
              vehicle_value: monetaryPredefinedFieldSchema,
              vehicle_cost_new: monetaryPredefinedFieldSchema,
              engine_type: stringOrSelectPredefinedFieldSchema,
              engine_capacity: numberPredefinedFieldSchema,
              number_of_seats: numberPredefinedFieldSchema,
              is_vehicle_camera_installed: booleanPredefinedFieldSchema,
              vehicle_weight: numberPredefinedFieldSchema,
              claim_free_years: numberPredefinedFieldSchema,
              existing_damage: stringOrSelectPredefinedFieldSchema,
              vehicle_use: stringOrSelectPredefinedFieldSchema,
            }),
          })
        : undefined,
  });

const configurationBaseSchema = (lob, configuration) =>
  Yup.object({
    version: Yup.number().nullable(),
    fnol: fnolProductConfigSchema,
    incident_details: incidentDetailsProductConfigBaseSchema(lob),
    involved_parties: involvedPartiesProductConfigSchema(lob, configuration),
    witnesses: witnessesProductConfigSchema,
    documents: documentsProductConfigSchema,
    policy: policyProductConfigSchema(lob),
  });

const fnolConfigurationSchema = ({ lob, configuration }) =>
  Yup.object({
    id: Yup.number().required('Required'),
    display_name: Yup.string().required('Required'),
    lob: Yup.string().required('Required'),
    organization_id: Yup.number().required('Required'),
    sub_organization_id: Yup.number().nullable(),
    configuration: configurationBaseSchema(lob, configuration),
  });

const incidentTypesAndSubTypesDialogSchema = Yup.object({
  configuration: Yup.object({
    categories: Yup.array().of(
      Yup.object({
        id: Yup.string().required('Required'),
        desc: Yup.string().required('Required'),
        active: Yup.boolean().default(true),
      })
    ),
    incident_details: Yup.object({
      types: typesSchema,
    }),
  }),
});

export { incidentTypesAndSubTypesDialogSchema };
export default fnolConfigurationSchema;
