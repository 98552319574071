import React from 'react';
import type { FormikValues } from 'formik';
import { useFormikContext } from 'formik';

import Grid from '~/components/core/Atomic/Grid/Grid';
import LabeledValue from '~/components/core/Molecules/LabledValue';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import BaseConfigurationSection from '~/components/SystemConfiguration/FnolConfiguration/FnolConfigurationScreen/Sections/BaseConfigurationSection';
import ConfiguredFieldsSection from '~/components/SystemConfiguration/FnolConfiguration/FnolConfigurationScreen/Sections/ConfiguredFieldsSection';

import { getLobDescription } from '../../../../../Utils/getLobDescription';
import useOrganization from '../../../../OrganizationContext';
import copies from '../copies.json';

import SystemFieldsSection from './SystemFieldsSection/SystemFieldsSection';

const ID_PREFIX = 'configuration.policy';

const PolicyDetailsSection: React.FC = () => {
  const { values } = useFormikContext<FormikValues>();
  const { lobConfigurationsDict } = useLobConfiguration();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { subOrganizationEnabled, subOrganizations } = useOrganization();
  const isAuto = values?.lob === 'auto_claim';

  return (
    <BaseConfigurationSection title="Policy Details" subtitle={<div className="p-12" />} isSection>
      <Grid item xs={3}>
        <LabeledValue label="Line of Business" value={getLobDescription(values?.lob, lobConfigurationsDict)} />
      </Grid>
      {subOrganizationEnabled && (
        <Grid item xs={3}>
          <LabeledValue
            label="Sub Organization"
            value={subOrganizations.find((so: { id: number }) => so.id === values?.sub_organization_id)?.name}
          />
        </Grid>
      )}
      <Grid item xs={subOrganizationEnabled ? 6 : 9} />
      <SystemFieldsSection fields={copies.system_fields} subtitle={copies.system_fields_subtitle} />
      <ConfiguredFieldsSection fieldId={`${ID_PREFIX}.configured_fields`} disabled={false} disableCategories />
      <div className="pl-16">
        <BaseConfigurationSection
          title="Custom Fields"
          subtitle={copies.custom_fields_subtitle}
          withActionsContainerFullWidth={false}
          isCollapsible={false}
        />
      </div>
      {isAuto && (
        <div className="pl-16">
          <BaseConfigurationSection
            title="Drivers List"
            subtitle={copies.drivers_list_subtitle}
            withActionsContainerFullWidth={false}
            isCollapsible={false}
          />
        </div>
      )}
    </BaseConfigurationSection>
  );
};

export default PolicyDetailsSection;
