import axios from 'axios';
import { getIn } from 'formik';
import { noop, pickBy } from 'lodash';

import { PERFORMANCE_QA } from '../../../../../../Types';
import { reportAxiosError } from '../../../../../../Utils';
import { CUSTOM_QUESTIONS_KEY, QUESTIONS_KEY } from '../constants';

/**
 * This function return the performance questions from the Enum
 * @param {string} type Performance QA Question type
 * @param {string} category Performance QA Question category
 * @return {object} Performance QA questions map
 * */
export const getPerformanceQuestions = (type, category) =>
  pickBy(PERFORMANCE_QA.QUESTIONS, (value) => value.type === type && value.category === category);

/**
 * This function return total number of selected questions
 * @param {object} values Performance QA Form Formik values
 * @return {number} total selected
 * */
export const getTotalSelected = (values) =>
  Object.values(getIn(values, QUESTIONS_KEY, {})).filter(({ selected }) => selected).length +
  Object.keys(getIn(values, CUSTOM_QUESTIONS_KEY, {})).length;

export const handleCreateNewForm = async ({ organizationId, userId, values, onSuccess = noop }) => {
  try {
    const data = {
      form_name: values.form_name.trim(),
      questions: values.questions,
      custom_questions: values.custom_questions,
    };

    await axios.post(getRoutes.performanceForm(organizationId, userId), data);
    await onSuccess();
  } catch (error) {
    await reportAxiosError(error);
  }
};

export const handleSetActive = async ({ organizationId, userId, formId, isActive }) => {
  try {
    const data = {
      form_id: formId,
      is_active: isActive,
    };

    await axios.put(getRoutes.activate(organizationId, userId), data);
  } catch (error) {
    await reportAxiosError(error);
  }
};

export const handleSetEnable = async ({ organizationId, userId, enabled }) => {
  try {
    await axios.put(getRoutes.enable(organizationId, userId), { enabled });
  } catch (error) {
    await reportAxiosError(error);
  }
};

export const getRoutes = {
  performanceForm: (organizationId, userId) => `/api/v1/qa_queries/${organizationId}/${userId}/performance_qa_form`,
  activate: (organizationId, userId) => `${getRoutes.performanceForm(organizationId, userId)}/activate`,
  enable: (organizationId, userId) => `${getRoutes.performanceForm(organizationId, userId)}/enable`,
};
