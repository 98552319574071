import React from 'react';

import { BODILY_INJURIES_FIELD_IDS, INVOLVED_DRIVER_FIELD_IDS, INVOLVED_PERSON_FIELD_IDS } from '../constants';

import InvolvedEntitySection from './InvolvedEntitySection';

const InvolvedDriverSection = (): JSX.Element => (
  <InvolvedEntitySection
    entityId="involved_driver"
    entityDesc="Involved Driver"
    baseInvolvedFields={INVOLVED_PERSON_FIELD_IDS}
    involvedFields={INVOLVED_DRIVER_FIELD_IDS}
    itemsIdPrefix="bodily_injuries"
    itemsFieldIds={BODILY_INJURIES_FIELD_IDS}
    withIcdCodes
  />
);

export default InvolvedDriverSection;
