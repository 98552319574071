import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { isUserLeaderOfParentUnits } from '~/components/SystemConfiguration/Users/OrganizationUnits/Utils';

import { getUnitFields, getUnitValidationFields } from '../../OrganizationUnits/Dialogs/UnitDialogUtils';
import { DIALOG_MODE, UNIT_FIELDS } from '../consts';

import { UnitDialogFormikWrapper } from './UnitDialogFormikWrapper';

export const AddUnitDialog = ({
  units,
  unitsDict,
  usersDict,
  memberToUnitDict,
  leaderToUnitsDict,
  onClose,
  onSave,
  isTopUnitOnly,
}) => {
  const areAllMembersValid = React.useCallback(
    ({ values, formikProps }) => {
      const { members = [] } = values;
      const foundInvalidMembers = members.some((memberId) => {
        if (isUserLeaderOfParentUnits({ userId: memberId, parentUnitId: values.parent_unit_id, unitsDict })) {
          const user = usersDict[memberId];
          formikProps.setFieldError(
            UNIT_FIELDS.parentUnitId,
            `The member "${user.username}" is the leader of one of this unit's parent units and therefore cannot be added to this unit. 
          \nEither remove the member from the current unit or choose a different parent unit.`
          );
          return true;
        }
      });

      return !foundInvalidMembers;
    },
    [unitsDict, usersDict]
  );

  const onSubmitHandler = React.useCallback(
    async (values, formikProps) => {
      if (!areAllMembersValid({ values, formikProps })) return;

      try {
        await onSave({ values });
        formikProps.resetForm();
      } catch {
        formikProps.setSubmitting(false);
      }
    },
    [onSave, areAllMembersValid]
  );

  return (
    <Formik
      initialValues={{ ...getUnitFields({}) }}
      validationSchema={Yup.object().shape({ ...getUnitValidationFields() })}
      enableReinitialize
      onSubmit={onSubmitHandler}
      validateOnChange={false}
    >
      <UnitDialogFormikWrapper
        dialogMode={isTopUnitOnly ? DIALOG_MODE.ADD_TOP_ONLY : DIALOG_MODE.ADD}
        units={units}
        unitsDict={unitsDict}
        usersDict={usersDict}
        memberToUnitDict={memberToUnitDict}
        leaderToUnitsDict={leaderToUnitsDict}
        onClose={onClose}
      />
    </Formik>
  );
};

AddUnitDialog.propTypes = {
  onClose: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  usersDict: PropTypes.shape({}),
  unitsDict: PropTypes.shape({}),
  memberToUnitDict: PropTypes.shape({}),
  leaderToUnitsDict: PropTypes.shape({}),
  isTopUnitOnly: PropTypes.bool,
};

const noop = () => {};
AddUnitDialog.defaultProps = {
  onClose: noop,
  units: [],
  usersDict: {},
  unitsDict: {},
  memberToUnitDict: {},
  leaderToUnitsDict: {},
  isTopUnitOnly: false,
};
