import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik, useFormikContext } from 'formik';

import { useStyles } from '~/assets/styles';
import LinksList from '~/components/core/Cards/LinksList';
import Stepper from '~/components/core/Stepper';
import { ADMIN_ROUTES } from '~/routesConstants';
import { CLAIM_TYPES_CONFIGURATION } from '~/Types';
import { concatRoutes, reportAxiosError } from '~/Utils';

import { LoadingSwitch } from '../../core';
import { ArrowDownwardIcon } from '../../icons';
import { LazyLoadedAllSetIllustration } from '../../illustrations';
import { useSysconfig } from '../../SystemConfiguration/SystemConfigurationScreen';
import useDataFetcher from '../../useDataFetcher';
import { POSITIONS, textWithIcon } from '../WizardUtils/Utils';

import { copies } from './Utils';
import { WizardSteps } from './WizardSteps';

import wizardStyles from '../WizardUtils/WizardUtils.module.scss';

const LobWizard = ({ onClose }) => {
  const { organization } = useSysconfig();
  const [currentStep, setCurrentStep] = useState(WizardSteps({})[0]);
  const {
    isLoading,
    isError,
    data: organizationSupportedLobs,
  } = useDataFetcher(`/api/v1/organizations/${organization.id}/available_lobs_with_hidden_indication`);

  return (
    <LoadingSwitch isLoading={isLoading} isError={isError}>
      <Formik
        initialValues={{
          lob: {
            name: '',
            claim_identifier: '',
            is_claims_made: false,
          },
          sub_orgs_with_claim_identifiers: organization.sub_organizations?.reduce(
            (acc, curr) => (
              (acc[curr.id] = {
                id: curr.id,
                claim_identifier: '',
                is_enabled: false,
                lob_iso_code: '',
                is_claims_made: false,
              }),
              acc
            ),
            {}
          ),
          coverages: [],
          shared_coverages: [],
        }}
        validationSchema={currentStep?.validationSchema}
        enableReinitialize
      >
        <LobWizardInner
          onClose={onClose}
          organizationSupportedLobs={organizationSupportedLobs}
          onStepChange={setCurrentStep}
        />
      </Formik>
    </LoadingSwitch>
  );
};

LobWizard.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const LobWizardInner = ({ onClose, organizationSupportedLobs, onStepChange }) => {
  const classes = useStyles();
  const { organization } = useSysconfig();
  const { values, setSubmitting } = useFormikContext();
  const [createdLob, setCreatedLob] = useState();

  const WIZARD_STEPS = WizardSteps({
    organizationSupportedLobs,
    formikContext: useFormikContext(),
    subOrgsWithClaimIdentifiers: values.sub_orgs_with_claim_identifiers,
  });

  const getAdminOrganizationRoute = (innerRoute) =>
    `/admin/organizations/${organization.id}/sysconfig/${innerRoute ? innerRoute : ''}`;

  return (
    <>
      <Stepper
        onComplete={async () => {
          try {
            setSubmitting(true);
            const { data } = await axios.post(`/api/v1/organizations/${organization.id}/lob`, values);
            setCreatedLob(data);
          } catch (error) {
            setSubmitting(false);
            reportAxiosError(error);
            return false;
          }
        }}
        stepsContainerClassName={classes.centeredFullScreenCardWrapper}
        headerClassName={wizardStyles.stepperHeader}
        backBtnText={textWithIcon(
          copies.BACK,
          <ArrowDownwardIcon className={wizardStyles.leftArrow} />,
          POSITIONS.START
        )}
        nextBtnText={textWithIcon(
          copies.NEXT,
          <ArrowDownwardIcon className={wizardStyles.rightArrow} />,
          POSITIONS.END
        )}
        finishBtnText={textWithIcon(
          copies.CREATE_LOB,
          <ArrowDownwardIcon className={wizardStyles.rightArrow} />,
          POSITIONS.END
        )}
        firstStepBackBtnText={copies.WELCOME_CANCEL}
        firstStepNextBtnText={textWithIcon(
          copies.WELCOME_START,
          <ArrowDownwardIcon className={wizardStyles.rightArrow} />,
          POSITIONS.END
        )}
        onFirstStepBack={onClose}
        cancelButtonsWithoutBorder
        withConfirmOnFirstBack
        withConfirmOnFirstBackProps={{
          title: 'Are you sure you want to exit the wizard?',
          contentText: 'You have started creating a Line of Business, all configurations will be lost when exiting.',
          secondaryButtonName: 'Cancel',
          primaryButtonName: 'OK',
        }}
        onStepChange={onStepChange}
      >
        {WIZARD_STEPS.map(({ content, key, ...stepProps }) => (
          <Stepper.Step key={key} {...stepProps}>
            <div className={wizardStyles.stepWrapper}>{content}</div>
          </Stepper.Step>
        ))}
        <Stepper.FinishScreen
          illustration={<LazyLoadedAllSetIllustration shouldLoad={!!createdLob} />}
          title={`${CLAIM_TYPES_CONFIGURATION[createdLob]?.desc || ''} LOB has been setup`}
          text={`Completed the new line of business wizard.
  See below a list of additional operations you can configure.`}
          btnText={copies.END_SCREEN_BUTTON}
          btnOnClick={onClose}
          footer={
            <LinksList
              title="Additional Operations You Can Perform"
              links={[
                {
                  label: 'Notifications',
                  href: getAdminOrganizationRoute(
                    concatRoutes(ADMIN_ROUTES.AUTOMATION.MAIN, ADMIN_ROUTES.AUTOMATION.NOTIFICATIONS)
                  ),
                },
                {
                  label: 'Assignments',
                  href: getAdminOrganizationRoute(
                    concatRoutes(ADMIN_ROUTES.AUTOMATION.MAIN, ADMIN_ROUTES.AUTOMATION.ASSIGNMENTS)
                  ),
                },
                { label: 'Document Templates', href: getAdminOrganizationRoute(ADMIN_ROUTES.CORRESPONDENCE) },
                {
                  label: 'Global Addresses',
                  href: getAdminOrganizationRoute(
                    concatRoutes(ADMIN_ROUTES.ORGANIZATION.MAIN, ADMIN_ROUTES.ORGANIZATION.ORGANIZATION_COUNTRIES)
                  ),
                },
              ]}
            />
          }
        />
      </Stepper>
    </>
  );
};

LobWizardInner.propTypes = {
  onClose: PropTypes.func.isRequired,
  organizationSupportedLobs: PropTypes.array,
  onStepChange: PropTypes.func,
};

export default LobWizard;
