import React from 'react';

import { arrayToObject, objectToArray } from '../../../../Utils';

import PaymentsConfigTab from './PaymentConfigTab/PaymentConfigTab';

const PaymentsConfigurationTab = () => {
  const processIncomingConfigValues = (values) => ({
    ...values,
    method_configuration: {
      ...values.method_configuration,
      indemnity_labels: values.method_configuration['indemnity_labels']
        ? objectToArray(values.method_configuration['indemnity_labels'], 'key')
        : null,
      expenses_labels: values.method_configuration['expenses_labels']
        ? objectToArray(values.method_configuration['expenses_labels'], 'key')
        : null,
    },
  });

  const processOutgoingConfigValues = (values) => ({
    ...values,
    method_configuration: {
      ...values.method_configuration,
      indemnity_labels: values.method_configuration['indemnity_labels']
        ? arrayToObject(values.method_configuration['indemnity_labels'], 'key')
        : null,
      expenses_labels: values.method_configuration['expenses_labels']
        ? arrayToObject(values.method_configuration['expenses_labels'], 'key')
        : null,
    },
  });

  return (
    <PaymentsConfigTab
      processIncomingConfigValues={processIncomingConfigValues}
      processOutgoingConfigValues={processOutgoingConfigValues}
    />
  );
};

export default PaymentsConfigurationTab;
