import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import ThreeDotsMenu from '~/components/core/ThreeDotsMenu';

import { FsIconButton } from '../../../../../core';
import { EyeIcon } from '../../../../../icons';
import { useDeleteProfileDialog } from '../../ProfileHooks/useDeleteProfileDialog';
import { useProfileStatus } from '../../ProfileHooks/useProfileStatus';
import { ProfileProptypesShape } from '../../types';
import { ProfilesTable } from '../ProfilesTable';

import styles from './cells.module.scss';

export const ActionsCell = ({ row, onDelete, onEdit, onViewProfile, onEditUsers }) => {
  const { profileStatus, reloadProfileStatus } = useProfileStatus(row);

  const deleteDialogProps = useDeleteProfileDialog({ profileStatus, profile: row });

  const menuOptions = React.useMemo(
    () =>
      [
        {
          onClick: profileStatus.number_of_users ? noop : () => onDelete(row),
          withConfirmProps: deleteDialogProps,
          node: 'Delete Profile',
        },
        {
          onClick: onEdit,
          node: 'Edit Profile',
        },
        {
          onClick: onEditUsers,
          node: 'Add Users',
        },
      ].map((option, i) => ({
        ...option,
        key: `profile_actions_${row[ProfilesTable.COLUMNS.id]}_${i}`,
      })),
    [deleteDialogProps, onDelete, onEdit, onEditUsers, profileStatus.number_of_users, row]
  );

  return (
    <div className={styles.actionCellContainer}>
      <FsIconButton icon={EyeIcon} onClick={onViewProfile} className={styles.viewIcon} />
      <ThreeDotsMenu options={menuOptions} onClick={() => reloadProfileStatus()} />
    </div>
  );
};

ActionsCell.propTypes = {
  row: ProfileProptypesShape,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onViewProfile: PropTypes.func.isRequired,
  onEditUsers: PropTypes.func.isRequired,
};
