import React from 'react';
import PropTypes from 'prop-types';

import InnerTabs from '~/components/core/Layout/InnerTabs/InnerTabs';

import CardDialog from '../CardDialog';
import { Heading, Text } from '../core';

import OperationsBreadcrumbs from './OperationsBreadcrumbs';

import styles from './systemConfiguration.module.scss';

const ConfigurationScreenWithTabs = ({ tabs, title, subtitle, operationTabName }) => {
  const Header = () => (
    <div className={styles.headerWrapper}>
      <Heading variant={Heading.TYPES.H2}>{title}</Heading>
      <Text weight="regular" variant="small" className={styles.subtitle}>
        {subtitle}
      </Text>
    </div>
  );

  return (
    <div className={styles.configurationScreen}>
      <CardDialog noCardTitle>
        <OperationsBreadcrumbs currentTab={operationTabName} />
        <Header />
        <InnerTabs tabs={tabs.filter((t) => t.isEnabled)} renderContainerClassName={styles.innerTabsContainer} />
      </CardDialog>
    </div>
  );
};

ConfigurationScreenWithTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      component: PropTypes.func.isRequired,
      isEnabled: PropTypes.bool.isRequired,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  operationTabName: PropTypes.string.isRequired,
};

export default ConfigurationScreenWithTabs;
