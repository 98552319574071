import React from 'react';
import { Prompt } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';

import copies from '../copies.json';

const FnolPrompt: React.FC = () => {
  const { touched } = useFormikContext();

  return <Prompt when={!isEmpty(touched)} message={copies.exit_confirm.text} />;
};

export default FnolPrompt;
