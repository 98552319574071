import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import { Card } from '~/components/core/Cards/Card';
import { ChipsPanel } from '~/components/core/ChipsPanel/ChipsPanel';
import SearchWithOptionsFormik from '~/components/core/Formik/SearchWithOptionsFormik/SearchWithOptionsFormik';
import { safeMixpanelTrack } from '~/components/SystemConfiguration/Tabs/PermissionsProfiles/MixpanelEvents';
import {
  DIALOG_MODE,
  ORGANIZATION_UNITS_MIXPANEL_EVENTS,
} from '~/components/SystemConfiguration/Users/OrganizationUnits/consts';
import { isUserSelected } from '~/components/SystemConfiguration/Users/OrganizationUnits/Dialogs/UnitDialogUtils';

import cn from '../../../../../../Utils/cn';
import { Text } from '../../../../../core';
import { unitShape } from '../../types';
import {
  buildLeaderToUnitsDict,
  buildSearchOption,
  getSubUnits,
  isUserLeaderOfParentUnits,
  isUserLeaderOfSubUnit,
} from '../../Utils';

export const UnitMembersCard = ({ unit, usersDict, memberToUnitDict, unitsDict, dialogMode }) => {
  const { setFieldValue, values } = useFormikContext();
  const { id, parent_unit_id, leader_user_id, members } = values;
  const isEditMode = dialogMode === DIALOG_MODE.EDIT;
  const isDeactivateMode = dialogMode === DIALOG_MODE.DEACTIVATE;
  const isEditingDeactivatedUnit = isEditMode && !unit.is_active;
  const isAddingMembersAllowed = !isDeactivateMode && !isEditingDeactivatedUnit;
  const unitHasAnyMembers = !!unit?.members?.length; // the current state of the unit in the DB
  const anyMembersAreSelected = !!members?.length; // the desired state of the unit according to the member selection

  const getMemberSubtitle = React.useCallback(
    (user) => {
      const parts = [];
      if (user.is_removed) {
        parts.push('Deactivated');
      }
      if (leader_user_id === user.id) {
        parts.push('Leader');
      } else if (user.id in memberToUnitDict) {
        parts.push(memberToUnitDict[user.id].name);
      }
      return parts.length ? `(${parts.join(', ')})` : '';
    },
    [memberToUnitDict, leader_user_id]
  );

  const isMemberCheckboxDisabled = React.useCallback(
    ({ userId, unitId }) => {
      const unitOfMember = memberToUnitDict[userId];
      const isMemberInThisUnit = unitOfMember && unitOfMember.id === unitId;
      const isMemberInAnotherUnit = unitOfMember && unitOfMember.id !== unitId;

      return !!(
        ((isDeactivateMode || isEditingDeactivatedUnit) && !isMemberInThisUnit) ||
        userId === leader_user_id ||
        isMemberInAnotherUnit ||
        isUserLeaderOfSubUnit({ userId, unitId, unitsDict }) ||
        // checks if user is leader of parent units but exclude members that are already selected to prevent
        // a situation where the checkbox became disabled and cannot be unchecked to solve the validation issue.
        (!isUserSelected({ members, userId }) &&
          isUserLeaderOfParentUnits({ userId, parentUnitId: parent_unit_id, unitsDict }))
      );
    },
    [memberToUnitDict, isDeactivateMode, isEditingDeactivatedUnit, members, unitsDict, parent_unit_id, leader_user_id]
  );

  const usersEntries = React.useMemo(() => {
    return Object.values(usersDict)
      .map((user) =>
        buildSearchOption({
          id: user.id,
          entryTitle: user.username,
          entrySubtitle: getMemberSubtitle(user),
          secondaryAction: '',
          isDisabled: isMemberCheckboxDisabled({ userId: user.id, unitId: id, unitsDict }),
        })
      )
      .sort((a, b) => a.entryTitle.localeCompare(b.entryTitle));
  }, [usersDict, getMemberSubtitle, isMemberCheckboxDisabled, id, unitsDict]);

  const options = React.useMemo(() => [{ entries: usersEntries, title: '', subtitle: '' }], [usersEntries]);

  const chips = React.useMemo(() => {
    const subUnits = getSubUnits({ unitId: id, unitsDict });
    const leaderToSubUnitsDict = buildLeaderToUnitsDict({ units: subUnits });
    return members
      ?.map((id) => {
        const subUnits = leaderToSubUnitsDict[id] || [];
        const isLeaderOfSubUnit = subUnits.length > 0;
        const user = usersDict?.[id];
        return {
          id,
          text: !user ? '' : `${user.username}${user.is_removed ? ' (Deactivated)' : ''}`,
          color: isLeaderOfSubUnit ? 'primary' : 'default',
          tooltip: isLeaderOfSubUnit
            ? `To remove this member, first remove them from the role of unit leader in unit ${subUnits
                .map((unit) => unit.name)
                .join(', ')}`
            : '',
          allowDelete: !isLeaderOfSubUnit,
        };
      })
      .sort((a, b) => a.text.localeCompare(b.text));
  }, [members, usersDict, unitsDict, id]);

  const onDelete = React.useCallback(
    ({ value }) => {
      setFieldValue(
        'members',
        members.filter((id) => id !== value.id)
      );
    },
    [setFieldValue, members]
  );

  const onUserSelectionChange = React.useCallback(({ checked }) => {
    safeMixpanelTrack(
      checked
        ? ORGANIZATION_UNITS_MIXPANEL_EVENTS.UNIT_MEMBER_ADD_CLICK
        : ORGANIZATION_UNITS_MIXPANEL_EVENTS.UNIT_MEMBER_REMOVE_CLICK,
      {}
    );
  }, []);

  const onSelectAllChange = React.useCallback(({ checked }) => {
    safeMixpanelTrack(
      checked
        ? ORGANIZATION_UNITS_MIXPANEL_EVENTS.UNIT_MEMBERS_SELECT_ALL_CLICKED
        : ORGANIZATION_UNITS_MIXPANEL_EVENTS.UNIT_MEMBERS_REMOVE_ALL_CLICKED,
      {}
    );
  }, []);

  const showNoMembers = !isAddingMembersAllowed && !unitHasAnyMembers;
  return (
    <Card cardType={Card.CARD_TYPES.PRIMARY} title="Members">
      <div className="flex flex-col gap-20">
        {showNoMembers && (
          <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
            No members.
          </Text>
        )}
        {!showNoMembers && (
          <>
            <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
              {!isAddingMembersAllowed
                ? 'Removing all members is recommended.'
                : 'A user can only be a member of one unit at a time.'}
            </Text>
            <Card cardType={Card.CARD_TYPES.PRIMARY} className={cn({ ['hidden']: !anyMembersAreSelected })}>
              <div className="flex flex-col gap-20">
                <Text variant={Text.VARIANTS.XS} weight={Text.WEIGHTS.SEMI_BOLD}>
                  Users Added
                </Text>

                <ChipsPanel chips={chips} onDelete={onDelete} />
              </div>
            </Card>
            <Card cardType={Card.CARD_TYPES.SECONDARY} className="h-[400px] overflow-auto">
              <SearchWithOptionsFormik
                mainFieldId="members"
                isSelectAllEnabled
                label="Search User"
                options={options}
                showOnly={false}
                onChange={onUserSelectionChange}
                onSelectAll={onSelectAllChange}
              />
            </Card>
          </>
        )}
      </div>
    </Card>
  );
};

UnitMembersCard.propTypes = {
  unit: PropTypes.shape(unitShape).isRequired,
  usersDict: PropTypes.shape({}),
  unitsDict: PropTypes.shape({}),
  memberToUnitDict: PropTypes.shape({}),
  dialogMode: PropTypes.oneOf(Object.values(DIALOG_MODE)).isRequired,
};

UnitMembersCard.deafultProps = {
  usersDict: {},
  unitsDict: {},
  memberToUnitDict: {},
};
