import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { isoUtcDateTimeToLocal } from '../../../../../DateTimeUtils';
import { reportAxiosError, reportErrorInProductionOrThrow } from '../../../../../Utils';
import { FsButton, LoadingSwitch, SortableTable, Text } from '../../../../core';
import { AddIcon } from '../../../../icons';
import useDataFetcher from '../../../../useDataFetcher';
import { useSysconfig } from '../../../SystemConfigurationScreen';
import { PERMISSIONS_MIXPANEL_EVENTS, PERMISSIONS_MIXPANEL_SOURCES } from '../MixpanelEvents';
import { CreateProfileDialog } from '../ProfileDialogs/CreateProfileDialog';
import { EditProfileDialog } from '../ProfileDialogs/EditProfileDialog';
import { EditUsersDialog } from '../ProfileDialogs/EditUsersDialog';
import { ViewProfileDialog } from '../ProfileDialogs/ViewProfileDialog';
import { PROFILES_ROUTES } from '../routes';

import { ActionsCell } from './Cells/ActionsCell';
import { NumberOfUsersCell } from './Cells/NumberOfUsersCell';
import { ProfileNameCell } from './Cells/ProfileNameCell';
import { UserSpecialCell } from './Cells/UserSpecialCell';

import { useStyles } from '../../../../../assets/styles';
import colors from '../../../../../assets/colors.module.scss';
import styles from '../styles.module.scss';

export const ProfilesTable = ({ active, drafts, title }) => {
  const { organization } = useSysconfig();
  const classes = useStyles();

  const [addProfileDialogOpen, setAddProfileDialogOpen] = React.useState(false);
  const [profileInEdit, setProfileInEdit] = React.useState(null);
  const [profileInView, setProfileInView] = React.useState(null);
  const [profileInEditUsers, setProfileInEditUsers] = React.useState(null);

  let url;
  if (active) {
    url = PROFILES_ROUTES.ACTIVE(organization.id);
  } else if (drafts) {
    reportErrorInProductionOrThrow('Profile drafts tab not implemented');
  }
  const { data, isLoading, isError, reloadData } = useDataFetcher(url);

  const handleDelete = async (row) => {
    const updatedData = await reloadData();
    const profileExists = updatedData.some((profile) => profile.id === row.id);

    if (!profileExists) {
      return;
    }

    setProfileInView(null);

    try {
      await axios.delete(`/sysconfig/api/v1/organizations/${organization.id}/permissions/profiles/${row.id}`);
    } catch (e) {
      await reportAxiosError(e);
    }
    await reloadData();
  };

  const reloadDataForViewer = async () => {
    const updatedData = await reloadData();
    const updatedProfile = updatedData.find((profile) => profile.id === profileInView.id);
    setProfileInView(updatedProfile);
  };

  const columns = [
    {
      id: ProfilesTable.COLUMNS.display_name,
      label: 'Profile Name',
      specialCell: (row) => <ProfileNameCell profileName={row.display_name} />,
    },
    {
      id: ProfilesTable.COLUMNS.created_by,
      label: 'Created By',
      specialCell: (row) => <UserSpecialCell userName={row.created_by} />,
    },
    {
      id: ProfilesTable.COLUMNS.last_updated,
      label: 'Last Updated',
      specialCell: (row) => (row.last_updated ? isoUtcDateTimeToLocal(row.last_updated) : ''),
    },
    {
      id: ProfilesTable.COLUMNS.number_of_users,
      label: 'Number of Users',
      specialCell: (row) => <NumberOfUsersCell row={row} />,
    },
    { id: ProfilesTable.COLUMNS.description, label: 'Description' },
    {
      id: ProfilesTable.COLUMNS.actions,
      label: '',
      specialCell: (row) => (
        <ActionsCell
          onDelete={handleDelete}
          onEdit={() => setProfileInEdit(row)}
          onViewProfile={() => setProfileInView(row)}
          onEditUsers={() => setProfileInEditUsers(row)}
          row={row}
        />
      ),
    },
  ];

  return (
    <div className={styles.innerCard}>
      <div className={styles.innerCardTitleContainer}>
        <Text variant={Text.VARIANTS.LG} weight={Text.WEIGHTS.SEMI_BOLD}>
          {title}
        </Text>
        {active && (
          <FsButton
            mixpanelEvent={PERMISSIONS_MIXPANEL_EVENTS.create_permissions_profile_clicked}
            color="primary"
            onClick={() => setAddProfileDialogOpen(true)}
          >
            <>
              <AddIcon className={classes.leftButtonIcon} iconColor={colors.buttonLink} />
              Add Permissions Profile
            </>
          </FsButton>
        )}
      </div>
      <div className={styles.tableContainer}>
        <LoadingSwitch isLoading={isLoading} isError={isError}>
          <SortableTable columns={columns} rows={data} />
        </LoadingSwitch>
      </div>
      {addProfileDialogOpen && (
        <CreateProfileDialog onClose={() => setAddProfileDialogOpen(false)} reloadData={reloadData} />
      )}
      {profileInEdit && (
        <EditProfileDialog
          onClose={() => setProfileInEdit(null)}
          reloadData={reloadData}
          profile={profileInEdit}
          source={PERMISSIONS_MIXPANEL_SOURCES.TABLE}
        />
      )}
      {profileInView && (
        <ViewProfileDialog
          onClose={() => setProfileInView(null)}
          reloadData={reloadDataForViewer}
          profile={profileInView}
          onDelete={handleDelete}
          source={PERMISSIONS_MIXPANEL_SOURCES.TABLE}
        />
      )}
      {profileInEditUsers && (
        <EditUsersDialog
          onClose={() => setProfileInEditUsers(null)}
          profile={profileInEditUsers}
          reloadData={reloadData}
          source={PERMISSIONS_MIXPANEL_SOURCES.TABLE}
        />
      )}
    </div>
  );
};

ProfilesTable.propTypes = {
  active: PropTypes.bool,
  drafts: PropTypes.bool,
  title: PropTypes.string,
};

ProfilesTable.COLUMNS = {
  id: 'id',
  display_name: 'display_name',
  created_by: 'created_by',
  last_updated: 'last_updated',
  description: 'description',
  actions: 'actions',
  number_of_users: 'number_of_users',
};
