import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { noop, orderBy } from 'lodash';

import Typography from '~/components/core/Atomic/Typography';
import EmptyState from '~/components/core/EmptyState';

import { serverDateTimeToLocal } from '../../../../../../../DateTimeUtils';
import { reportAxiosError } from '../../../../../../../Utils';
import { ContactEntity } from '../../../../../../Contact';
import { PencilIcon } from '../../../../../../icons';
import PlainTable from '../../../../../../PlainTable';
import { useSysconfig } from '../../../../../SystemConfigurationScreen';
import { getApiKey, getWebhookSecret, vendorApiProfile as vendorApiProfileAPI } from '../routes';

import { AsyncSwitch } from './AsyncSwitch';
import Secret from './SecretField';

import { useStyles } from '../../../../../../../assets/styles';

const VendorAPIProfilesTable = ({ vendorApiProfiles = [], onUpdate = noop, onActionClick = noop }) => {
  const { organization } = useSysconfig();
  const classes = useStyles();

  const handleSwitchChange = async (vendorApiProfileId, checked) => {
    try {
      await axios.patch(vendorApiProfileAPI(organization, vendorApiProfileId), {
        is_enabled: checked,
      });
      await onUpdate();
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const columns = [
    {
      id: 'name',
      label: 'Contact',
      specialCell: (vendor_api_profile) => (
        <ContactEntity
          contactId={vendor_api_profile.contact.id}
          contactDisplayName={vendor_api_profile.contact.full_name}
        />
      ),
    },
    {
      id: 'key',
      label: 'Service Account Key',
      disableSort: true,
      specialCell: (vendorApiProfile) => <Secret vendorApiProfileId={vendorApiProfile.id} getSecret={getApiKey} />,
    },
    {
      id: 'webhook_secret',
      label: 'Webhook Secret',
      disableSort: true,
      specialCell: (vendorApiProfile) => (
        <Secret vendorApiProfileId={vendorApiProfile.id} getSecret={getWebhookSecret} />
      ),
    },
    {
      id: 'last_updated_datetime',
      label: 'Last Updated',
      disableSort: true,
      specialCell: (vendorApiProfile) => serverDateTimeToLocal(vendorApiProfile.last_updated_datetime),
    },
    {
      id: 'last_updated_by_user',
      label: 'Last Updated By',
      disableSort: true,
    },
    {
      id: 'is_enabled',
      label: 'Enable',
      specialCell: (vendorApiProfile) => (
        <AsyncSwitch
          checked={vendorApiProfile.is_enabled}
          onChange={(e) => handleSwitchChange(vendorApiProfile.id, e.target.checked)}
        />
      ),
    },
    {
      id: 'actions',
      label: 'Actions',
      disableSort: true,
      specialCell: (vendorApiProfile) => (
        <PencilIcon className={classes.hoverableNonFilledIcon} onClick={() => onActionClick(vendorApiProfile)} />
      ),
    },
  ];

  return (
    <PlainTable
      rows={orderBy(vendorApiProfiles, ['contact.full_name', 'id'])}
      columns={columns}
      emptyStateComponent={
        <EmptyState
          subtitle={
            <Typography display="block" variant="subtitle1">
              No Service Provider Profiles.
            </Typography>
          }
        />
      }
      order="desc"
      keepRowsOrder
    />
  );
};

VendorAPIProfilesTable.propTypes = {
  vendorApiProfiles: PropTypes.array,
  onActionClick: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default VendorAPIProfilesTable;
