import React from 'react';
import { Collapse } from '@material-ui/core';
import { getIn, useFormikContext } from 'formik';

import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';

import CheckboxFormik from '../../../../../../../CheckboxFormik';
import { NumericTextFieldFormik } from '../../../../../../../TextFieldFormik';
import { FIELD_IDS } from '../..';

import { useStyles } from '../../../../../../../../assets/styles';
import styles from '../../claimSearchConfigurationDialog.module.scss';

const InitialNotification = () => {
  const { values, isSubmitting } = useFormikContext();
  const classes = useStyles();
  const isSelected = getIn(values, FIELD_IDS.INITIAL_REPORTS.NOTIFICATION_SELECTED);

  return (
    <div className={styles.notificationSetup}>
      <CheckboxFormik
        id={FIELD_IDS.INITIAL_REPORTS.NOTIFICATION_SELECTED}
        label="Send a notification to the claim owner if the claim has not been reported to ClaimSearch"
      />
      <Collapse in={isSelected} timeout="auto" unmountOnExit>
        <Grid container spacing={2} className={styles.timeSelectionContainer}>
          <Grid item xs={6}>
            <NumericTextFieldFormik
              id={FIELD_IDS.INITIAL_REPORTS.NOTIFICATION_DAYS}
              label="Reminder will be sent after"
              disabled={isSubmitting}
              className={classes.formTextField}
              decimalScale={0}
              fullWidth
            />
          </Grid>
          <Grid item xs={2} className={styles.daysSelection}>
            <Typography>days</Typography>
          </Grid>
        </Grid>
      </Collapse>
    </div>
  );
};

export default InitialNotification;
