import React, { useState } from 'react';
import axios from 'axios';

import CardDialog from '~/components/CardDialog';
import LoadingSwitch from '~/components/core/Loading/LoadingSwitch';
import type { ConfigurableOptionProps } from '~/components/core/Molecules/ConfigurableOption';
import ConfigurableOption from '~/components/core/Molecules/ConfigurableOption';
import useNotesConfiguration from '~/components/hooks/useNotesConfiguration';
import NoteEditTimeDialog from '~/components/SystemConfiguration/NotesConfiguration/NoteEditTimeDialog';
import type { NotesConfiguration } from '~/components/SystemConfiguration/NotesConfiguration/types';
import { reportAxiosError } from '~/Utils';

import Heading from '../../core/TextComponents/Heading';

const NotesConfigurationScreen: React.FC = () => {
  const { configuration, isLoading, isError, reloadData, notesConfigurationRoute } = useNotesConfiguration(true);

  const [isUpdatingConfiguration, setIsUpdatingConfiguration] = useState(false);

  const handleUpdateConfiguration = async (values: Partial<NotesConfiguration>, throwOnError = true) => {
    try {
      setIsUpdatingConfiguration(true);
      await axios.patch(notesConfigurationRoute, values);
      await reloadData();
    } catch (error) {
      await reportAxiosError(error);
      if (throwOnError) {
        throw error;
      }
    } finally {
      setIsUpdatingConfiguration(false);
    }
  };

  const configurationOptions: ConfigurableOptionProps<NotesConfiguration>[] = [
    {
      isEnabled: configuration?.is_edit_note_enabled,
      onChangeToggle: async (isEnabled: boolean) =>
        await handleUpdateConfiguration({ is_edit_note_enabled: isEnabled }, false),
      configurationView: <Heading variant={Heading.TYPES.H3}>Allow note editing</Heading>,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      EditConfigurationDialogComponent: NoteEditTimeDialog,
      configuration,
      onSubmitDialog: handleUpdateConfiguration,
    },
  ];

  return (
    <div className="bg-slate-100 pt-32">
      <CardDialog width="md" noCardTitle>
        <LoadingSwitch isError={isError} isLoading={isLoading}>
          {configurationOptions.map(
            (
              {
                isEnabled,
                onChangeToggle,
                configurationView,
                configuration,
                EditConfigurationDialogComponent,
                onSubmitDialog,
              },
              idx
            ) => (
              <ConfigurableOption<NotesConfiguration>
                key={idx}
                isEnabled={isEnabled}
                onChangeToggle={onChangeToggle}
                configurationView={configurationView}
                configuration={configuration}
                EditConfigurationDialogComponent={EditConfigurationDialogComponent}
                showOnly={isUpdatingConfiguration}
                onSubmitDialog={onSubmitDialog}
              />
            )
          )}
        </LoadingSwitch>
      </CardDialog>
    </div>
  );
};

export default NotesConfigurationScreen;
