import React from 'react';
import PropTypes from 'prop-types';

import InlineIconButton from '../../../../InlineIconButton';
import { RULE_TYPE_OPTIONS } from '../utils';

import { useStyles } from '../../../../../assets/styles';

export const NotificationRuleIcon = ({ type }) => {
  const classes = useStyles();

  return (
    <InlineIconButton
      icon={RULE_TYPE_OPTIONS[type].icon}
      tooltipTitle={`${RULE_TYPE_OPTIONS[type].title} Rule`}
      className={`${classes.textIcon} ${classes.marginedIcon}`}
      wrapWithSpan
      onClick={() => null}
      iconStyle={{ cursor: 'auto' }}
    />
  );
};

NotificationRuleIcon.propTypes = {
  type: PropTypes.string,
};
