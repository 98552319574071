import React from 'react';
import PropTypes from 'prop-types';

import SelectManagersConfigurationDialog from '~/components/SystemConfiguration/Tabs/PaymentsConfiguration/PaymentConfigTab/AdditionalDialogs/SelectManagersConfigurationDialog';
import { PAYMENTS_CONFIG_FIELDS } from '~/components/SystemConfiguration/Tabs/PaymentsConfiguration/PaymentConfigTab/constants';

const PresentApprovalScreenConfigurationDialog = ({ paymentsConfiguration, disabled = false, onCancel, onSubmit }) => {
  return (
    <SelectManagersConfigurationDialog
      paymentsConfiguration={paymentsConfiguration}
      disabled={disabled}
      onCancel={onCancel}
      onSubmit={onSubmit}
      configurationKey={PAYMENTS_CONFIG_FIELDS.ADDITIONAL_APPROVING_USERS}
      cardTitle="Present approval screen"
      inputLabel="Users with approval permissions"
      preselectManagers
    />
  );
};

PresentApprovalScreenConfigurationDialog.propTypes = {
  paymentsConfiguration: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default PresentApprovalScreenConfigurationDialog;
