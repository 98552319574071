import React from 'react';
import { getIn, useFormikContext } from 'formik';

import Button from '~/components/core/Atomic/Buttons/Button';
import Typography from '~/components/core/Atomic/Typography';
import { AddIcon } from '~/components/deprecatedMuiIcons';

import { TOTAL_ALLOWED_QUESTIONS } from '../../constants';
import { getTotalSelected } from '../../utils';

import CustomQuestion from './Question';

import { useStyles } from '../../../../../../../assets/styles';
import sectionStyles from '../sections.module.scss';
import styles from './customQuestions.module.scss';

let idCounterRef = 0;

const CustomSection = () => {
  const { values, setFieldValue } = useFormikContext();
  const classes = useStyles();

  const questions = getIn(values, 'custom_questions', {});
  const totalSelectedLength = getTotalSelected(values);

  return (
    <div>
      <Typography display="block" component="span">
        <div className={sectionStyles.sectionTitle}>
          Add free text questions that the answer is only Yes/No and choose the relevant category for each question.
        </div>
      </Typography>
      {Object.keys(questions).map((id) => (
        <CustomQuestion
          key={id}
          id={`custom_questions.${id}`}
          onDelete={() => {
            setFieldValue(`custom_questions.${id}`, undefined);
          }}
        />
      ))}

      <div className={styles.addQuestionBtn}>
        <Button
          color="primary"
          disabled={totalSelectedLength >= TOTAL_ALLOWED_QUESTIONS}
          onClick={() => {
            const generatedId = `custom_questions.id_${idCounterRef}`;
            idCounterRef++;

            setFieldValue(generatedId, {});
          }}
        >
          <AddIcon className={classes.leftButtonIcon} />
          ADD QUESTION
        </Button>
      </div>
    </div>
  );
};

export default CustomSection;
