import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';

import { reportAxiosError } from '../../../../../Utils';
import CardDialog from '../../../../CardDialog';
import DialogFooterActions from '../../../../core/DialogFooterActions';
import { useSysconfig } from '../../../SystemConfigurationScreen';
import { StatesConfigurationFormik } from '../NotificationRuleDialog/StatesConfigurationFormik';
import { statesValidationSchema } from '../utils';

export const EditStatesDialog = ({ notification, onClose, onSubmit }) => {
  const { organization } = useSysconfig();
  const handleSubmit = async (value, { setSubmitting }) => {
    try {
      await axios.patch(
        `/api/v1/organizations/${organization.id}/notifications/pre_defined/${notification.notification_key}`,
        value
      );
      onClose();
    } catch (error) {
      await reportAxiosError(error);
    } finally {
      setSubmitting(false);
      onSubmit();
    }
  };

  return (
    <Formik
      initialValues={{
        is_all_states: notification.is_all_states ?? true,
        states: notification.states ?? [],
      }}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape(statesValidationSchema)}
    >
      <EditStatesDialogInner onClose={onClose} />
    </Formik>
  );
};

EditStatesDialog.propTypes = {
  notification: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const EditStatesDialogInner = ({ onClose }) => {
  const { submitForm, isSubmitting } = useFormikContext();

  return (
    <CardDialog
      isDialog
      title="ConfigureStates"
      onClose={onClose}
      open
      width="lg"
      fullWidth
      footerActions={
        <DialogFooterActions onClickPrimary={submitForm} onClickSecondary={onClose} disabled={isSubmitting} />
      }
    >
      <StatesConfigurationFormik direction="column" />
    </CardDialog>
  );
};

EditStatesDialogInner.propTypes = {
  onClose: PropTypes.func.isRequired,
};
