import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '~/components/core';
import { Card } from '~/components/core/Cards/Card';
import LoadingIndicator from '~/components/LoadingIndicator';

import { useUnitRelatedConfigurations } from '../../../hooks/useUnitRelatedConfigurations';

const RELATED_CONFIGURATION_DISPLAY_NAMES = {
  notification_rule: 'Notification Rules',
};

export const UnitRelatedConfigurationsCard = () => {
  const { isUnitConfigsLoading, isUnitConfigsError, hasAnyConfigs, unitConfigs } = useUnitRelatedConfigurations();

  return (
    <Card cardType={Card.CARD_TYPES.PRIMARY} title="Related Configurations and Workflows">
      <div className="flex flex-col gap-20">
        {isUnitConfigsError ? (
          <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
            An error occurred while fetching related configurations and workflows.
          </Text>
        ) : isUnitConfigsLoading ? (
          <LoadingIndicator />
        ) : !hasAnyConfigs ? (
          <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
            No related configurations and workflows were found.
          </Text>
        ) : (
          Object.entries(unitConfigs).map(([type, configurations]) => (
            <div key={type}>
              <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.SEMIBOLD}>
                {RELATED_CONFIGURATION_DISPLAY_NAMES[type] || type} ({configurations?.length || 0}):
              </Text>
              <ul>
                {configurations.map((configuration) => (
                  <li key={configuration.name}>{configuration.name}</li>
                ))}
              </ul>
            </div>
          ))
        )}
      </div>
    </Card>
  );
};

UnitRelatedConfigurationsCard.propTypes = {
  onClick: PropTypes.func,
};
