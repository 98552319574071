import React from 'react';

import SupplementaryInformationSection from '../SupplementaryInformationSection';

import BaseAdditionalInformationSection from './BaseAdditionalInformationSection';

const AUTO_SPECIFIC_FIELDS = [
  {
    id: 'police_and_fire_department',
    title: 'Police and Fire Department',
    fieldsIds: [
      'were_police_involved',
      'pending_prosecutions_or_investigations_exists',
      'police_crime_reference_number',
      'police_department_contact_id',
      'police_report',
      'did_speak_with_officer',
      'was_ticket_received',
      'violation_title',
      'police_description',
      'were_fire_department_involved',
      'fire_department_description',
      'were_any_hazardous_good_being_carried',
    ],
  },
  {
    id: 'theft_follow_up',
    title: 'Theft Follow Up',
    fieldsIds: [
      'discovered_the_loss_contact_id',
      'crime_reference_number',
      'date_noticed_vehicle_missing',
      'time_noticed_vehicle_missing',
      'date_vehicle_last_seen',
      'time_vehicle_last_seen',
      'vehicle_last_seen_location',
      'were_belongings_stolen_from_vehicle',
      'belongings_stolen',
      'is_vehicle_for_sale',
      'person_liable_for_the_theft_is_known',
      'note',
      'num_of_keys_received_on_purchase',
      'current_num_of_keys',
      'keys_location',
      'was_vehicle_found_with_keys',
      'vehicle_windows_closed_and_secured',
      'vehicle_fitted_with_security_devices',
      'security_devices_description',
      'vehicle_fitted_with_tracking_devices',
      'tracking_devices_description',
    ],
  },
  {
    id: 'fault_assessment',
    title: 'Fault Assessment',
    fieldsIds: [
      'first_party_fault_assessment',
      'claim_handler_fault_assessment',
      'is_indemnity_review_needed',
      'is_fraud_review_needed',
      'percentage_of_insured_liability',
      'liability_summary',
    ],
  },
  {
    id: 'accident_follow_up',
    title: 'Accident Follow Up',
    fieldsIds: [
      'journey_purpose',
      'weather_conditions',
      'direction_ph_vehicle_was_going',
      'first_party_way_sign_or_a_headlight',
      'street_condition',
      'was_ambulance_called',
      'was_first_party_driver_using_the_phone',
      'were_first_party_vehicle_lights_on',
      'was_anyone_under_the_influence_of_drugs_alcohol',
    ],
  },
];

interface Section {
  id: string;
  title: string;
  node?: JSX.Element;
  children?: { id: string; title: string; node?: JSX.Element }[];
  isCollapsible?: boolean;
}

const getSupplementaryInformationSection = (): Section[] => [
  {
    id: 'supplementary_information',
    title: 'Supplementary Information',
    node: <SupplementaryInformationSection />,
  },
];

const getAutoSpecificSections = (): Section[] => [
  {
    id: 'additional_information',
    title: 'Additional Information',
    isCollapsible: true,
    children: [
      ...getSupplementaryInformationSection(),
      ...AUTO_SPECIFIC_FIELDS?.map((section) => ({
        id: section.id,
        title: section.title,
        node: (
          <BaseAdditionalInformationSection
            title={section.title}
            sectionKey={section.id}
            sectionKeyPrefix="auto_incident_additional"
            fieldIds={section.fieldsIds}
          />
        ),
      })),
    ],
  },
];

const getLobSpecificAdditionalInfoSections = (lob: string): Section[] =>
  lob === 'auto_claim' ? getAutoSpecificSections() : getSupplementaryInformationSection();

export { getLobSpecificAdditionalInfoSections };
