import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';

import { OverflowArrayTextDisplayWithTooltip } from '../../../../core';

import styles from './TableCellsStyles.module.scss';

export const StatesCell = ({ row }) => {
  if (row.is_all_states) {
    return 'All';
  }

  return (
    <div className={styles.statesCellContainer}>
      <OverflowArrayTextDisplayWithTooltip
        value={row.states}
        additionalLabelWidth={24}
        renderItem={(label) => (
          <span key={label} className={styles.chipContainer}>
            <Chip label={label} size="small" />
          </span>
        )}
      />
    </div>
  );
};

StatesCell.propTypes = {
  row: PropTypes.object.isRequired,
};
