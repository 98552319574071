import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from '~/assets/styles';
import { ArrowDownwardIcon, ArrowUpwardIcon } from '~/components/icons';
import InlineIconButton from '~/components/InlineIconButton';

const MoveArrows = ({ onMoveUp, onMoveDown, disabled }) => {
  const classes = useStyles();

  return (
    <div className="flex content-between items-center">
      <span className="mr-16">
        <InlineIconButton
          icon={ArrowUpwardIcon}
          tooltipTitle="Move Up"
          className={classes.hoverableIcon}
          onClick={(e) => {
            onMoveUp();
            e.stopPropagation();
          }}
          disabled={disabled}
        />
      </span>
      <InlineIconButton
        icon={ArrowDownwardIcon}
        tooltipTitle="Move Down"
        className={classes.hoverableIcon}
        onClick={(e) => {
          onMoveDown();
          e.stopPropagation();
        }}
        disabled={disabled}
      />
    </div>
  );
};

MoveArrows.propTypes = {
  onMoveUp: PropTypes.func,
  onMoveDown: PropTypes.func,
  disabled: PropTypes.bool,
};

export default MoveArrows;
