import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik } from 'formik';
import { omit } from 'lodash';

import AlertBanner from '~/components/core/AlertBanner';
import cn from '~/Utils/cn';

import { reportAxiosError } from '../../../../../Utils';
import mixpanel from '../../../../CmsMain/mixpanel';
import { useSysconfig } from '../../../SystemConfigurationScreen';
import ConfirmDialogContent from '../../../Users/UserDialogs/UserPermissionsSection/ConfirmDialogContent';
import { PERMISSIONS_MIXPANEL_EVENTS, PERMISSIONS_MIXPANEL_SOURCES } from '../MixpanelEvents';
import { PROFILES_ROUTES } from '../routes';

import { UsersSelectStep } from './UsersSelect/UsersSelectStep';
import { FORM_FIELDS, profileToInitialValues, saveProfileWithConfirmProps } from './formUtils';
import { ProfileDialogBase } from './ProfileDialogBase';

import styles from './styles.module.scss';

export const EditUsersDialog = ({ profile, onClose, reloadData, source }) => {
  const { organization: sysConfigOrganization } = useSysconfig();

  React.useEffect(() => {
    mixpanel.track(PERMISSIONS_MIXPANEL_EVENTS.permissions_profile_add_users, {
      source,
      profile_id: profile.id,
      profile_name: profile.display_name,
      default_permission: profile.defaults,
      number_of_users: profile.number_of_users,
    });
  }, [profile, source]);

  const handleSubmit = React.useCallback(
    async (data) => {
      await axios.patch(PROFILES_ROUTES.USERS(sysConfigOrganization.id, profile.id), data);
    },
    [profile.id, sysConfigOrganization.id]
  );

  const initialValues = omit(profileToInitialValues(profile), [
    FORM_FIELDS.display_name,
    FORM_FIELDS.description,
    FORM_FIELDS.default_permission,
    FORM_FIELDS.permissions,
  ]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await handleSubmit(values);
          await reloadData();
          onClose();
        } catch (e) {
          await reportAxiosError(e);
        }
        setSubmitting(false);
      }}
      validateOnChange={false}
      validateOnBlur={false}
    >
      <ProfileDialogBase
        onClose={onClose}
        title="Add users"
        withActions
        primaryWithConfirmProps={EditUsersDialog.confirmDialogProps}
        mixpanelConfig={{
          source: PERMISSIONS_MIXPANEL_SOURCES.TABLE,
          submitConfirmDialogPresentedEventName:
            PERMISSIONS_MIXPANEL_EVENTS.permission_profile_assign_users_pop_up_presented,
        }}
      >
        <div className={styles.viewDialogContent}>
          <div className={cn(styles.alertContainer, styles.inViewDialog)}>
            <AlertBanner
              note="This action will change the permissions setting for the assigned users"
              alertType={AlertBanner.ALERT_TYPES.WARNING}
              withIcon
            />
          </div>
          <UsersSelectStep title="" noBanner />
        </div>
      </ProfileDialogBase>
    </Formik>
  );
};

EditUsersDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  reloadData: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  source: PropTypes.string.isRequired,
};

EditUsersDialog.confirmDialogProps = {
  ...saveProfileWithConfirmProps,
  contentText: (
    <ConfirmDialogContent
      title="Assign Users?"
      subtitle="This action will change the permissions setting for the assigned users"
    />
  ),
};
