import React from 'react';
import PropTypes from 'prop-types';

import { Card } from '~/components/core/Cards/Card';

import { ShowOnlyTextField } from '../../../../../TextFieldFormik';
import { ORG_UNIT_TYPE_TITLE } from '../../consts';
import { unitShape } from '../../types';
import { getUnitMembersNames, getUnitType } from '../../Utils';

export const UnitSummaryCard = ({ unit, withMembers }) => {
  const unitType = ORG_UNIT_TYPE_TITLE[getUnitType({ unit })];
  const membersText = getUnitMembersNames({ unit }).join(', ');

  const fieldClass = 'w-1/2';
  return (
    <Card title="Details">
      <div className="flex w-full flex-col gap-12">
        <div className="flex w-full justify-between">
          <ShowOnlyTextField label="Unit Name" showOnlyValueComponent={unit.name} classes={{ showField: fieldClass }} />
          <ShowOnlyTextField
            label="Description"
            showOnlyValueComponent={unit.description}
            classes={{ showField: fieldClass }}
          />
        </div>

        <div className="flex w-full justify-between">
          <ShowOnlyTextField label="Type" showOnlyValueComponent={unitType} classes={{ showField: fieldClass }} />
          <ShowOnlyTextField
            label="Parent Unit"
            showOnlyValueComponent={unit.parent_unit_name}
            classes={{ showField: fieldClass }}
          />
        </div>

        <ShowOnlyTextField label="Unit Leader" showOnlyValueComponent={unit.leader_user_name} classes={{}} />

        {withMembers && <ShowOnlyTextField label="Members" showOnlyValueComponent={membersText} classes={{}} />}
      </div>
    </Card>
  );
};
UnitSummaryCard.propTypes = {
  unit: PropTypes.shape(unitShape).isRequired,
  withMembers: PropTypes.bool,
};
