import { number, object, oneOf, oneOfType, shape, string } from 'prop-types';

import { CLAIM_SEARCH } from '../../../../../Types';

export const configurationProps = shape({
  id: oneOfType([string, number]).isRequired,
  name: string.isRequired,
  organizationId: number.isRequired,
  subOrganizationId: number,
  trigger_method: oneOf(CLAIM_SEARCH.SUPPORTED_TRIGGER_METHODS),
  additional_initial_trigger: object,
  search_triggers: object,
  schedule_period: oneOf(Object.keys(CLAIM_SEARCH.SUPPORTED_SCHEDULE_PERIOD)),
  schedule_time_frame: oneOf(Object.keys(CLAIM_SEARCH.SCHEDULE_TIME_FRAME)),
  schedule_time: number,
});
