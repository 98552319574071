import React from 'react';
import { TextField } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import { getIn, useFormikContext } from 'formik';

import { useStyles } from '~/assets/styles';
import MenuItem from '~/components/core/Atomic/MenuItem';
import COUNTRY_TO_STATE_MAP from '~/server_shared/generated-types/COUNTRY_TO_STATE_MAP';
import colors from '~/theme/tailwind/colors';

import { ErrorHelperTextFormik } from '../../../core/Formik/ErrorHelperTextFormik';

const ALL_ITEM_VALUE = '__all__';

interface PartialValuesType {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [name: string]: any;
}

interface StateMultiselectFormikProps {
  statesFieldId: string;
  allSelectedFieldId?: string;
  label?: string;
  selectAllLabel?: string;
  showAllInRenderedSelectedOptions?: boolean;
  shouldDisplayAllOption?: boolean;
  disabled?: boolean;
}
const StateMultiselectFormik: React.FC<StateMultiselectFormikProps> = ({
  statesFieldId,
  allSelectedFieldId,
  label = 'States',
  selectAllLabel = 'All',
  showAllInRenderedSelectedOptions = false,
  shouldDisplayAllOption = false,
  disabled = false,
}) => {
  const { setFieldValue, values, errors, touched } = useFormikContext<PartialValuesType>();
  const classes = useStyles();

  const sortedFilteredStates = Object.fromEntries(
    Object.entries(COUNTRY_TO_STATE_MAP['US'])
      .filter(([key]) => key !== '')
      .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
  );

  const selectedStates = getIn(values, statesFieldId) || [];
  const isSelectAllChecked =
    shouldDisplayAllOption &&
    ((allSelectedFieldId && values[allSelectedFieldId]) || // selectAllField was passed in, get value
      (!allSelectedFieldId && selectedStates.length === 0) || // selectAllField was not passed in, is list empty
      false);

  const setAllSelectedFieldValue = (value: boolean) => {
    if (allSelectedFieldId) {
      setFieldValue(allSelectedFieldId, value);
    }
  };

  const handleSelectionChange = (event: { target: { value: string | string[] } }) => {
    if (!event.target.value?.includes(ALL_ITEM_VALUE)) {
      setFieldValue(statesFieldId, event.target.value);
      setAllSelectedFieldValue(false);
    }
  };

  const handleSelectAll = (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    const newSelectAllValue = !isSelectAllChecked;

    setAllSelectedFieldValue(newSelectAllValue);

    if (newSelectAllValue) {
      setFieldValue(statesFieldId, []);
    }
  };

  const isStateChecked = (state: string) => {
    return getIn(values, statesFieldId).indexOf(state) > -1;
  };

  const renderFieldValue = (states: unknown) => {
    if (showAllInRenderedSelectedOptions && isSelectAllChecked) {
      return selectAllLabel;
    }
    return (states as string[]).map((state: string) => sortedFilteredStates[state]).join(', ');
  };

  return (
    <>
      <TextField
        id={statesFieldId}
        label={label}
        className={classes.textField}
        value={selectedStates}
        onChange={handleSelectionChange}
        disabled={disabled}
        error={
          (getIn(errors, statesFieldId) && getIn(touched, statesFieldId)) ||
          (allSelectedFieldId && getIn(errors, allSelectedFieldId) && getIn(touched, allSelectedFieldId))
        }
        size="small"
        select
        fullWidth
        InputLabelProps={{
          shrink: (showAllInRenderedSelectedOptions && isSelectAllChecked) || !!selectedStates.length,
        }}
        SelectProps={{
          multiple: true,
          renderValue: renderFieldValue,
          displayEmpty: true,
        }}
      >
        {shouldDisplayAllOption ? (
          <MenuItem value={ALL_ITEM_VALUE}>
            <Checkbox
              checked={isSelectAllChecked}
              onChange={handleSelectAll}
              style={{ color: isSelectAllChecked ? colors.teal['700'] : '' }}
            />
            <ListItemText primary={selectAllLabel} onClick={handleSelectAll} />
          </MenuItem>
        ) : null}
        {Object.keys(sortedFilteredStates).map((state) => (
          <MenuItem key={state} value={state}>
            <Checkbox
              checked={isStateChecked(state)}
              style={{ color: isStateChecked(state) ? colors.teal['700'] : '' }}
            />
            <ListItemText primary={sortedFilteredStates[state]} />
          </MenuItem>
        ))}
      </TextField>
      {allSelectedFieldId && <ErrorHelperTextFormik id={allSelectedFieldId} />}
      <ErrorHelperTextFormik id={statesFieldId} />
    </>
  );
};

export default StateMultiselectFormik;
