import PropTypes from 'prop-types';

export const permissionPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  type: PropTypes.string.isRequired,
  is_read_only: PropTypes.bool,
});

export const permissionSetPropType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  permissions: PropTypes.arrayOf(permissionPropType),
});
