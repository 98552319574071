import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import Button from '~/components/core/Atomic/Buttons/Button';

const EnableSubOrganizationsButton = ({ onClose = noop, organization, handleEnableSubOrgs }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const enableSubOrgs = async () => {
    try {
      setIsSubmitting(true);
      await handleEnableSubOrgs();
      onClose();
    } catch {
      // handled by the parent
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Button
      color="primary"
      variant="contained"
      disabled={organization.sub_organizations_enabled || isSubmitting}
      onClick={enableSubOrgs}
      style={{ marginTop: 10, marginRight: 10 }}
    >
      Enable Sub Organizations
    </Button>
  );
};

EnableSubOrganizationsButton.propTypes = {
  onClose: PropTypes.func,
  organization: PropTypes.object.isRequired,
  handleEnableSubOrgs: PropTypes.func.isRequired,
};

export default EnableSubOrganizationsButton;
