import React from 'react';

import { INVOLVED_OTHER_PROPERTY_FIELD_IDS, INVOLVED_PROPERTY_FIELD_IDS, ITEMS_FIELD_IDS } from '../constants';

import InvolvedEntitySection from './InvolvedEntitySection';

const InvolvedPropertySection = (): JSX.Element => (
  <InvolvedEntitySection
    entityId="involved_generic_property"
    entityDesc="Involved Property"
    baseInvolvedFields={INVOLVED_PROPERTY_FIELD_IDS}
    involvedFields={INVOLVED_OTHER_PROPERTY_FIELD_IDS}
    itemsIdPrefix="property_items"
    itemsFieldIds={ITEMS_FIELD_IDS}
  />
);

export default InvolvedPropertySection;
