import React from 'react';

import { useRoleRestriction } from '~/components/hooks/useRoleRestriction';

import { isUserSuperUser } from '../../UserUtils';

import OrganizationDocumentsTemplates from './OrganizationDocumentsTemplates/OrganizationDocumentsTemplates';
import { useSysconfig } from './SystemConfigurationScreen';

function CorrespondenceTab() {
  const { organization } = useSysconfig();

  useRoleRestriction({
    errorMessage: 'tried accessing CorrespondenceTab',
    roleValidators: [isUserSuperUser],
  });

  return <OrganizationDocumentsTemplates orgId={organization.id} />;
}

export default CorrespondenceTab;
