import React from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';

import AlertBanner from '~/components/core/AlertBanner';
import cn from '~/Utils/cn';

import { Text } from '../../../../../core';
import { PERMISSION_VERBS_CONFIG } from '../../../../../core/Permissions/PermissionUtils';
import { FORM_FIELDS } from '../formUtils';

import styles from './profileSummary.module.scss';

const SUMMARY_VARIANTS = {
  SLIM: 'slim',
  FULL: 'full',
};

export const ProfileSummary = ({ variant }) => {
  const { values, errors, isValid } = useFormikContext();

  const summaryItems = [
    {
      title: 'Profile Name',
      value: getIn(values, FORM_FIELDS.display_name),
      slim: true,
      full: 'primary',
    },
    {
      title: 'Deafult Permission',
      value: PERMISSION_VERBS_CONFIG.getVerbByOptionValue(getIn(values, FORM_FIELDS.default_permission))?.displayName,
      slim: true,
      full: 'primary',
    },
    {
      title: 'Number of Users',
      value: getIn(values, FORM_FIELDS.users)?.length,
      slim: false,
      full: 'primary',
    },
    {
      title: 'Descritpion',
      value: getIn(values, FORM_FIELDS.description),
      slim: true,
      full: 'secondary',
    },
  ];

  const errorMessage = React.useMemo(() => {
    if (isValid) {
      return null;
    } else if (errors[FORM_FIELDS.display_name]?.toLowerCase()?.includes('unique')) {
      return 'Profile name must be unique. Please go back and choose a different name';
    } else {
      return 'The profile configuration contains errors. please go back and fix them before creating the profile.';
    }
  }, [errors, isValid]);

  if (variant === SUMMARY_VARIANTS.SLIM) {
    return <ProfileSummarySlim summaryItems={summaryItems} />;
  } else {
    return <ProfileSummaryFull summaryItems={summaryItems} errorMessage={errorMessage} />;
  }
};

ProfileSummary.propTypes = {
  variant: PropTypes.oneOf(Object.values(SUMMARY_VARIANTS)),
};

ProfileSummary.VARIANTS = SUMMARY_VARIANTS;

const ProfileSummaryFull = ({ summaryItems, errorMessage }) => (
  <div className={styles.summaryStep}>
    <div className={styles.stepTitleContainer}>
      <Text variant={Text.VARIANTS.XL} weight={Text.WEIGHTS.SEMI_BOLD} colorVariant={Text.COLOR_VARIANTS.BLACK}>
        Create Profile
      </Text>
      <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR} colorVariant={Text.COLOR_VARIANTS.BLACK}>
        Please review the Permission Profile details before creating the profile.
      </Text>
    </div>
    <div className={styles.summaryContainer}>
      <Text variant={Text.VARIANTS.XL} weight={Text.WEIGHTS.SEMI_BOLD} colorVariant={Text.COLOR_VARIANTS.BLACK}>
        Summary
      </Text>
      <div className={styles.summaryItemsContainer}>
        {summaryItems
          .filter((item) => item.full === 'primary')
          .map((summaryItem) => (
            <SummaryItem key={summaryItem.title} title={summaryItem.title} value={summaryItem.value} withBorder />
          ))}
      </div>
      <div className={cn(styles.summaryItemsContainer, styles.withMargin)}>
        {summaryItems
          .filter((item) => item.full === 'secondary')
          .map((summaryItem) => (
            <SummaryItem key={summaryItem.title} title={summaryItem.title} value={summaryItem.value} />
          ))}
      </div>
    </div>
    <div className={styles.errorContainer}>
      {errorMessage && <AlertBanner withIcon note={errorMessage} alertType={AlertBanner.ALERT_TYPES.ERROR} />}
    </div>
  </div>
);

ProfileSummaryFull.propTypes = {
  summaryItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  errorMessage: PropTypes.string,
};

const ProfileSummarySlim = ({ summaryItems }) => (
  <div className={cn(styles.summaryContainer, styles.summaryContainerSlim)}>
    <div className={styles.summaryItemsContainer}>
      {summaryItems
        .filter((item) => item.slim)
        .map((summaryItem) => (
          <SummaryItem key={summaryItem.title} title={summaryItem.title} value={summaryItem.value} slim />
        ))}
    </div>
  </div>
);

ProfileSummarySlim.propTypes = {
  summaryItems: PropTypes.arrayOf(PropTypes.object).isRequired,
};
const SummaryItem = ({ title, value, withBorder, slim }) => {
  if (value === undefined) {
    return null;
  }

  return (
    <div
      className={cn(styles.summaryItemContainer, {
        [styles.withBorder]: withBorder,
        [styles.slimItem]: slim,
      })}
    >
      <Text variant={Text.VARIANTS.XS} weight={Text.WEIGHTS.SEMI_BOLD} colorVariant={Text.COLOR_VARIANTS.SECONDARY}>
        {title}
        {slim ? ':' : ''}
      </Text>
      <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.SEMI_BOLD} colorVariant={Text.COLOR_VARIANTS.PRIMARY}>
        {value}
      </Text>
    </div>
  );
};

SummaryItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.any,
  withBorder: PropTypes.bool,
  slim: PropTypes.bool,
};
