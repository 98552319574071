const DEFAULT_MOI_METHODS = {
  all: {
    damage_assessment: {
      desc: 'Damage Assessment',
      coverages: [],
      expertises: ['field_adjuster', 'appraiser'],
    },
    fraud_detection: {
      desc: 'Fraud Detection',
      coverages: [],
      expertises: ['forensics', 'investigator', 'siu'],
    },
    legal_services: {
      desc: 'Legal Services',
      coverages: [],
      expertises: ['legal_services', 'attorney'],
    },
    medical_services: {
      desc: 'Medical Services',
      coverages: [],
      expertises: ['medical_services', 'medical_center', 'medical_examiner'],
    },
    outsourcing: {
      desc: 'Outsourcing',
      coverages: [],
      expertises: ['claim_adjusting'],
    },
    other: {
      desc: 'Other',
      coverages: [],
      expertises: ['other'],
    },
    xactanalysis: {
      desc: 'XactAnalysis',
      expertises: ['appraiser', 'engineer', 'contractor', 'field_adjuster'],
    },
  },
  auto_claim: {
    body_shop: {
      desc: 'Body Shop',
      coverages: [],
      expertises: ['approved_body_shop', 'unapproved_body_shop'],
    },
    tow_lot: {
      desc: 'Tow Lot',
      coverages: [],
      expertises: ['tow_lot'],
    },
    car_rental: {
      desc: 'Car Rental',
      coverages: [],
      expertises: ['car_rental'],
    },
    windscreen: {
      desc: 'Windscreen',
      coverages: [],
      expertises: ['windscreen'],
    },
    road_services: {
      desc: 'Road Services',
      coverages: [],
      expertises: ['road_services'],
    },
  },
  home_claim: {
    mitigation_services: {
      desc: 'Mitigation Services',
      coverages: [],
      expertises: ['leak_detection_expert', 'mitigation_services'],
    },
    damage_assessment: {
      expertises: ['engineer', 'contractor'],
    },
  },
} as const;

export default DEFAULT_MOI_METHODS;
