import React from 'react';

import SvgIcon from '../core/SvgIcon';

const QuestionIcon = (props) => (
  <SvgIcon viewBox="0 0 25 24" fill="none" {...props}>
    <path
      d="M5.60938 18.8906C4.74688 18.0281 5.31875 16.2187 4.87813 15.1594C4.4375 14.1 2.75 13.1719 2.75 12C2.75 10.8281 4.41875 9.9375 4.87813 8.84063C5.3375 7.74375 4.74688 5.97187 5.60938 5.10938C6.47187 4.24688 8.28125 4.81875 9.34063 4.37813C10.4 3.9375 11.3281 2.25 12.5 2.25C13.6719 2.25 14.5625 3.91875 15.6594 4.37813C16.7562 4.8375 18.5281 4.24688 19.3906 5.10938C20.2531 5.97187 19.6813 7.78125 20.1219 8.84063C20.5625 9.9 22.25 10.8281 22.25 12C22.25 13.1719 20.5813 14.0625 20.1219 15.1594C19.6625 16.2562 20.2531 18.0281 19.3906 18.8906C18.5281 19.7531 16.7187 19.1813 15.6594 19.6219C14.6 20.0625 13.6719 21.75 12.5 21.75C11.3281 21.75 10.4375 20.0813 9.34063 19.6219C8.24375 19.1625 6.47187 19.7531 5.60938 18.8906Z"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.875 16.875C12.875 17.0821 12.7071 17.25 12.5 17.25C12.2929 17.25 12.125 17.0821 12.125 16.875C12.125 16.6679 12.2929 16.5 12.5 16.5C12.7071 16.5 12.875 16.6679 12.875 16.875Z"
      fill="none"
      stroke="inherit"
      strokeWidth="1.5"
    />
    <path
      d="M12.5 13.5V12.75C13.0192 12.75 13.5267 12.596 13.9584 12.3076C14.3901 12.0192 14.7265 11.6092 14.9252 11.1295C15.1239 10.6499 15.1758 10.1221 15.0746 9.61289C14.9733 9.10369 14.7233 8.63596 14.3562 8.26885C13.989 7.90173 13.5213 7.65173 13.0121 7.55044C12.5029 7.44915 11.9751 7.50114 11.4955 7.69982C11.0158 7.8985 10.6058 8.23495 10.3174 8.66663C10.029 9.09831 9.875 9.60583 9.875 10.125"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default QuestionIcon;
