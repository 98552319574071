import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import axios from 'axios';

import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';
import cn from '~/Utils/cn';

import { reportAxiosError } from '../../../../../../Utils';
import { EyeIcon } from '../../../../../icons';
import InlineIconButton from '../../../../../InlineIconButton';
import { useSysconfig } from '../../../../SystemConfigurationScreen';

import { useStyles } from '../../../../../../assets/styles';
import styles from './EventSettings.module.scss';

const SECRET_LENGTH = 44;

const WebhooksClientSecret = () => {
  const classes = useStyles();
  const { organization } = useSysconfig();
  const [showSecret, setShowSecret] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getDecryptedSecret = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(`/api/v1/claim_events_webhook/organizations/${organization.id}/secret`);
      setClientSecret(res.data);
    } catch (e) {
      await reportAxiosError(e);
    }
    {
      setIsLoading(false);
    }
  };

  const handleShowSecret = async () => {
    if (!clientSecret) {
      await getDecryptedSecret();
    }
    setShowSecret(!showSecret);
  };

  const getSecretText = () => {
    if (clientSecret) {
      return showSecret ? clientSecret : '• '.repeat(SECRET_LENGTH);
    }
    return '• '.repeat(SECRET_LENGTH);
  };

  return (
    <Grid
      container
      direction="row"
      spacing={2}
      className={styles.clientSecret}
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item>
        <Typography variant="overline" className={styles.buttonOverline}>
          Client Secret
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" className={styles.secretText}>
          {getSecretText()}
        </Typography>
      </Grid>
      <Grid xs={1}>
        {isLoading ? (
          <CircularProgress size={20} className={cn(classes.inlineEditIcon, styles.eyeButton)} />
        ) : (
          <InlineIconButton
            className={cn(classes.inlineEditIcon, styles.eyeButton)}
            icon={EyeIcon}
            onClick={handleShowSecret}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default WebhooksClientSecret;
