import { useEffect, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';

import { reportAxiosError } from '../../../../Utils';

const useCreateSubOrgConfigIfNeeded = ({
  organization,
  existingNavigatorConfiguration,
  reloadConfigurations,
  isNavigatorEnabled,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState();

  useEffect(() => {
    const initializeSubOrgs = async (sub_organizations_id) => {
      try {
        setIsLoading(true);
        await axios.post(`/api/v1/navigator/organizations/${organization.id}/initialize_sub_orgs`, {
          sub_organizations_id,
        });
        await reloadConfigurations();
      } catch (error) {
        reportAxiosError(error);
        setIsError(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (organization.sub_organizations && existingNavigatorConfiguration && isNavigatorEnabled) {
      const subOrgsWithMissingNavigatorConfiguration = _.difference(
        organization.sub_organizations.map((sub_org) => sub_org.id),
        existingNavigatorConfiguration.map((config) => config.sub_organization_id)
      );

      if (subOrgsWithMissingNavigatorConfiguration.length && !isError) {
        initializeSubOrgs(subOrgsWithMissingNavigatorConfiguration);
      } else {
        setIsLoading(false);
      }
    }
  }, [
    organization.id,
    organization.sub_organizations,
    existingNavigatorConfiguration,
    reloadConfigurations,
    isError,
    isNavigatorEnabled,
  ]);

  return { isLoading, isError };
};

export default useCreateSubOrgConfigIfNeeded;
