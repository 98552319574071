import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { noop } from 'lodash';
import { LockClock } from 'mdi-material-ui';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';
import { ChipsPanel } from '~/components/core/ChipsPanel/ChipsPanel';
import EmailsDisplay from '~/components/core/EmailsDisplay/EmailsDisplay';
import SuccessMessageDialog from '~/components/core/SuccessMessageDialog';
import { AddIcon } from '~/components/deprecatedMuiIcons';
import { isOrgUnitsConfigurationFeatureEnabled } from '~/components/SystemConfiguration/Users/OrganizationUnits/Utils';
import { ROLES_TYPES_DICT } from '~/Types';
import { isUserSuperUser } from '~/UserUtils';
import { stringCmp, subOrgIdToNameDict } from '~/Utils';

import CardDialog from '../../CardDialog';
import WithConfirm from '../../ConfirmModal';
import { FsTooltip, SortableTable } from '../../core';
import { isPermissionsEnabled } from '../../core/Permissions/PermissionUtils';
import {
  DownloadIcon,
  ErrorIcon,
  LockedIcon,
  PencilIcon as OurEditIcon,
  ResetPasswordIcon,
  UnlockedIcon,
  UploadIcon,
} from '../../icons';
import InlineIconButton from '../../InlineIconButton';
import UploadFileDialog from '../../UploadFileDialog';
import { getStateName, isLicensesEnabled } from '../Tabs/AdjusterManagement/utils';

import { UserPasswordDialog } from './UserDialogs/UserPassword';
import { UploadDialogContent } from './UploadDialogContent';
import { UserAddDialog, UserEditDialog } from './UserDialogs';

import colors from '../../../assets/colors.module.scss';
import styles from './Users.module.scss';

const ErrorMessage = ({ classes, onClose, errorMessage }) => (
  <CardDialog isDialog onClose={onClose} fullWidth maxWidth="sm">
    <Grid container style={{ display: 'block' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <Grid>
          <ErrorIcon size={64} iconColor="#F44336" />
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ padding: '8px', fontSize: '20px', fontWeight: 'bold' }}>{errorMessage}</Typography>
        </Grid>
        <Grid item xs={12}>
          Please update the CSV file and upload it again or contact Five Sigma support team for assistance.
        </Grid>
      </div>
      <Grid item xs={12}>
        <div className={classes.buttonsContainer}>
          <Button variant="contained" color="primary" onClick={onClose}>
            OK
          </Button>
        </div>
      </Grid>
    </Grid>
  </CardDialog>
);

ErrorMessage.propTypes = {
  classes: PropTypes.object,
  onClose: PropTypes.func,
  errorMessage: PropTypes.string,
};

class OrganizationUsers extends Component {
  constructor(props) {
    super(props);
    this.createChip = this.createChip.bind(this);
    this.getUnitCell = this.getUnitCell.bind(this);
  }

  state = {
    userEditing: null,
    resetPasswordUser: null,
    addUserOpen: false,
    importUsersOpen: false,
    boldRowsIds: [],
    isImportUsersError: false,
    isImportUsersSuccess: false,
    ignoredUsersCount: 0,
    uploadCsvErrorMessage: '',
  };

  handleAddUser = (values) => {
    const { onAddUser } = this.props;

    return onAddUser(values).then(() => this.setState({ addUserOpen: false }));
  };
  handleEditUserRequest = (user) => {
    this.setState({ userEditing: user });
  };

  handleEditUserCancel = () => {
    this.setState({ userEditing: null });
  };

  handleEditUserSave = (values) => {
    const { onUpdateUser } = this.props;
    const { userEditing } = this.state;

    return onUpdateUser(userEditing.id, values).then(() => this.setState({ userEditing: null }));
  };

  handleResetPassword = (values) => {
    const { onResetPassword } = this.props;
    const { resetPasswordUser } = this.state;

    return onResetPassword(resetPasswordUser.id, values).then(() => this.setState({ resetPasswordUser: null }));
  };

  handleImportUsersSuccess = async (response) => {
    const { data = {} } = response;
    const { total_users, users_created = [] } = data;
    const { reloadUsers = noop } = this.props;
    const ignoredUsersCount = Math.max(0, Number(total_users) - users_created.length);

    await reloadUsers();

    this.setState({
      importUsersOpen: false,
      boldRowsIds: users_created.map((user) => user.id),
      ignoredUsersCount,
      isImportUsersSuccess: true,
      uploadCsvErrorMessage: '',
    });
  };

  handleImportUsersError = async (response) => {
    const { data = {} } = response;
    const { message = '' } = data;
    const { reloadUsers = noop } = this.props;

    await reloadUsers();

    this.setState({
      importUsersOpen: false,
      boldRowsIds: [],
      ignoredUsersCount: 0,
      isImportUsersError: true,
      uploadCsvErrorMessage: message,
    });
  };

  onCsvTemplateClick = () => {
    const organizationId = this.props.user.organization_id;
    const link = document.createElement('a');
    link.download = 'template_users.csv';
    link.href = `/sysconfig/api/v1/organizations/${organizationId}/import_users`;
    link.click();
  };

  getUserProfileCell(user) {
    if (!user.profile.id) {
      return (
        <FsTooltip
          title="No permissions profile assigned to this user. Users without a profile will not be able to access the system"
          placement="top"
        >
          <span className={styles.notAssignedContainer}>
            <ErrorIcon className={styles.notAssignedIcon} />
            <span className={styles.notAssignedText}>Not Assigned</span>
          </span>
        </FsTooltip>
      );
    }

    return user.profile.display_name;
  }

  createChip({ unit, isLeader = false }) {
    if (!unit) return null;

    return {
      id: unit.id,
      text: isLeader ? `Leader of ${unit.name}` : unit.name,
      color: isLeader ? 'primary' : 'default',
      allowDelete: false,
    };
  }

  getUnitCell(user) {
    const unitChip = this.createChip({ unit: user.unit });
    const leaderChip = this.createChip({ unit: user.unitsLedByUser?.[0], isLeader: true });
    const chips = [unitChip, leaderChip].filter((chip) => !!chip);
    if (user.unitsLedByUser?.length > 1) {
      chips.push({
        id: 'counter',
        text: `+${user.unitsLedByUser.length - 1}`,
        tooltip: `Leader of ${user.unitsLedByUser.map((unit) => unit.name).join(', ')}.`,
      });
    }
    return <ChipsPanel chips={chips} size="small" />;
  }

  render() {
    const { classes, organization, user, users, roles, units, onUnlockUser, onLockUser, withoutTitle } = this.props;
    const {
      userEditing,
      addUserOpen,
      resetPasswordUser,
      importUsersOpen,
      boldRowsIds,
      isImportUsersError,
      isImportUsersSuccess,
      ignoredUsersCount,
      uploadCsvErrorMessage,
    } = this.state;

    const getUnlockUserIcon = (user) => {
      if (user.is_active) {
        return LockClock;
      }

      return UnlockedIcon;
    };

    const getUserRoleName = (user) => user.role.name;
    const getUserRoleDescription = (user) => ROLES_TYPES_DICT[user.role.role_type]['desc'];
    const getProfileDisplayName = (user) => user.profile?.display_name || '';
    const infIfNeg = (amount) => (amount < 0 ? 'INF' : amount);

    let columnData = [
      {
        id: 'username',
        numeric: false,
        label: 'Name',
        specialCell: (user) => (
          <span>
            {user.username}
            <span style={{ color: '#606060' }}>{user.is_user_locked ? ' (Locked)' : ''}</span>
          </span>
        ),
      },
      {
        id: 'type',
        numeric: false,
        label: 'Role Type',
        specialCell: getUserRoleDescription,
        specialCmpFunc: (user1, user2) => stringCmp(getUserRoleDescription(user1), getUserRoleDescription(user2)),
      },
      {
        id: 'role_name',
        numeric: false,
        label: 'Role Name',
        specialCell: getUserRoleName,
        specialCmpFunc: (user1, user2) => stringCmp(getUserRoleName(user1), getUserRoleName(user2)),
      },
      {
        id: 'permissions_profile',
        numeric: false,
        label: 'Permissions Profile',
        specialCell: this.getUserProfileCell,
        is_visible: isPermissionsEnabled(organization),
        specialCmpFunc: (user1, user2) => stringCmp(getProfileDisplayName(user1), getProfileDisplayName(user2)),
      },
      {
        id: 'unit',
        numeric: false,
        label: 'Unit',
        width: '320px',
        specialCell: this.getUnitCell,
      },
      { id: 'email', numeric: false, label: 'Email', maxWidthPercentage: 20, overflow: true },
      { id: 'mobile_phone_number', numeric: false, label: 'Mobile Phone' },
      { id: 'work_phone_number', numeric: false, label: 'Claims Phone' },
      {
        id: 'claims_email',
        numeric: false,
        label: 'Claims Email',
        specialCell: (user) => (
          <EmailsDisplay
            email_addresses={user.claim_emails}
            colorVariant={EmailsDisplay.COLOR_VARIANTS.PRIMARY}
            variant={EmailsDisplay.VARIANTS.SM}
            weight={EmailsDisplay.WEIGHTS.REGULAR}
          />
        ),
      },
      {
        id: 'is_sys_config_editor',
        numeric: false,
        label: 'System Configuration Editor',
        specialCell: (user) => (user.is_sys_config_editor ? 'yes' : 'no'),
      },
      { id: 'sub_org', numeric: false, label: 'Sub Organization' },
      { id: 'last_login', numeric: false, label: 'Last Login', rightPaddingOnly: true },
      {
        id: 'actions',
        label: 'Actions',
        width: '100px',
        disableSort: true,
        specialCell: (user) => (
          <div>
            <InlineIconButton
              icon={ResetPasswordIcon}
              tooltipTitle="Reset Password"
              className={`${classes.textIcon} ${classes.marginedIcon} ${classes.hoverableNonFilledIcon}`}
              onClick={(e) => {
                e.stopPropagation();
                this.setState({ resetPasswordUser: user });
              }}
              wrapWithSpan
            />
            {user.is_user_locked ? (
              <WithConfirm title="Unlock User?" primaryButtonName="Unlock" shouldCloseOnPrimary={true}>
                <InlineIconButton
                  icon={getUnlockUserIcon(user)}
                  tooltipTitle="Unlock User"
                  className={`${classes.textIcon} ${classes.marginedIcon} ${classes.hoverableNonFilledIcon}`}
                  onClick={async () => await onUnlockUser(user.id)}
                  wrapWithSpan
                />
              </WithConfirm>
            ) : (
              <WithConfirm
                title="Lock User?"
                contentText="The user will be logged out and not be able to log in"
                primaryButtonName="Lock"
                shouldCloseOnPrimary={true}
              >
                <InlineIconButton
                  icon={LockedIcon}
                  tooltipTitle="Lock User"
                  className={`${classes.textIcon} ${classes.marginedIcon} ${classes.hoverableNonFilledIcon}`}
                  onClick={async () => await onLockUser(user.id)}
                  wrapWithSpan
                />
              </WithConfirm>
            )}
            <span>
              <InlineIconButton
                icon={OurEditIcon}
                className={`${classes.textIcon} ${classes.marginedIcon} ${classes.hoverableNonFilledIcon}`}
                onClick={(e) => {
                  e.stopPropagation();
                  this.handleEditUserRequest(user);
                }}
                tooltipTitle="Edit"
                wrapWithSpan
              />
            </span>
          </div>
        ),
      },
    ];

    // Hide this feature from users for now
    if (!isUserSuperUser(user)) {
      columnData = columnData.filter((col) => col.id !== 'is_sys_config_editor');
    }

    if (isPermissionsEnabled(organization)) {
      columnData = columnData.filter((col) => col.id !== 'role_type');
    }

    if (!isOrgUnitsConfigurationFeatureEnabled({ organization })) {
      columnData = columnData.filter((col) => col.id !== 'unit');
    }

    columnData = columnData.filter((col) => col.is_visible !== false);

    if (!organization.sub_organizations_enabled) {
      columnData = columnData.filter((col) => !['sub_org'].includes(col.id));
    }
    const usersCount = boldRowsIds.length;

    const displaySuperUser = isUserSuperUser(user) && organization.is_super_organization;
    const displaySystemConfig = isUserSuperUser(user);

    const tableRows = users.reduce((acc, user) => {
      if (!ROLES_TYPES_DICT[user.role.role_type].is_hidden) {
        const subOrgIdToName = subOrgIdToNameDict(organization);
        const sub_org = user.sub_organization_ids.map((soId) => subOrgIdToName[soId]).join(', ');
        acc.push({ ...user, sub_org });
      }
      return acc;
    }, []);

    const headers = [
      { label: 'User Id', key: 'id', active: true },
      { label: 'Name', key: 'username', active: true },
      { label: 'First Name', key: 'first_name', active: true },
      { label: 'Last Name', key: 'last_name', active: true },
      { label: 'Role Type', key: 'type', active: true },
      { label: 'Role Name', key: 'role_name', active: true },
      { label: 'Permissions Profile', key: 'permissions_profile', active: isPermissionsEnabled(organization) },
      { label: 'Email', key: 'email', active: true },
      { label: 'Mobile Phone', key: 'mobile_phone_number', active: true },
      { label: 'Claims Phone', key: 'work_phone_number', active: true },
      { label: 'Claims Email', key: 'claims_email', active: true },
      { label: 'Supervisor Name', key: 'supervisor_name', active: true },
      {
        label: 'Indemnity Reserves Limit',
        key: 'indemnity_reserves_limit',
        active: !!organization?.are_payment_limits_split,
      },
      {
        label: 'Indemnity Payments Limit',
        key: 'indemnity_payments_limit',
        active: !!organization?.are_payment_limits_split,
      },
      {
        label: 'Expenses Reserves Limit',
        key: 'expenses_reserves_limit',
        active: !!organization?.are_payment_limits_split,
      },
      {
        label: 'Expenses Payments Limit',
        key: 'expenses_payments_limit',
        active: !!organization?.are_payment_limits_split,
      },
      { label: 'Reserves Limit', key: 'reserves_limit', active: !organization?.are_payment_limits_split },
      { label: 'Payments Limit', key: 'payments_limit', active: !organization?.are_payment_limits_split },
      { label: 'Is Org level Supervisor?', key: 'is_org_level_supervisor', active: true },
      { label: 'Sub Organizations', key: 'sub_organizations', active: organization?.sub_organizations_enabled },
      { label: 'Licenses', key: 'licenses', active: isLicensesEnabled(organization) },
      { label: 'Is Out of Office?', key: 'is_out_of_office', active: true },
      { label: 'Notify on new assignments via email', key: 'notify_on_new_assignments_via_email', active: true },
      { label: 'Last Login', key: 'last_login', active: true },
      { label: 'Is User Locked?', key: 'is_user_locked', active: true },
      { label: 'Is User Active?', key: 'is_active', active: true },
    ]?.filter((header) => !!header.active);

    const data = users
      ?.filter((user) => !user.is_removed)
      ?.map((user) => ({
        id: user.id,
        username: user.username,
        first_name: user.first_name,
        last_name: user.last_name,
        type: getUserRoleDescription(user),
        role_name: getUserRoleName(user),
        permissions_profile: getProfileDisplayName(user),
        email: user.email,
        mobile_phone_number: user.mobile_phone_number,
        work_phone_number: user.work_phone_number,
        claims_email: user.claim_emails?.join(', '),
        supervisor_name: user.supervisor_name,
        indemnity_reserves_limit: infIfNeg(user.indemnity_reserves_limit),
        indemnity_payments_limit: infIfNeg(user.indemnity_payments_limit),
        expenses_reserves_limit: infIfNeg(user.expenses_reserves_limit),
        expenses_payments_limit: infIfNeg(user.expenses_payments_limit),
        reserves_limit: infIfNeg(user.reserves_limit),
        payments_limit: infIfNeg(user.payments_limit),
        is_org_level_supervisor: user.is_org_level_supervisor,
        sub_organizations: user?.sub_organization_ids
          ?.map((subOrgId) => subOrgIdToNameDict(organization)[subOrgId])
          .join(', '),
        licenses: user?.licenses?.map((license) => getStateName(license.state)).join(', '),
        is_out_of_office: !!user?.is_out_of_office,
        notify_on_new_assignments_via_email: !!user?.extra_settings?.email_notification_assignment,
        last_login: user.last_login,
        is_user_locked: user.is_user_locked,
        is_active: user.is_active,
      }));

    return (
      <>
        <CardDialog
          title={withoutTitle ? '' : 'Users'}
          action={
            <div className={classes.buttonsContainer}>
              <CSVLink data={data} headers={headers} filename="users.csv" target="_blank">
                <Button color="primary">
                  <DownloadIcon size={16} iconColor={colors.buttonLink} className={classes.leftButtonIcon} />
                  Export Users
                </Button>
              </CSVLink>
              <Button color="primary" onClick={() => this.setState({ importUsersOpen: true })}>
                <UploadIcon size={16} iconColor={colors.buttonLink} className={classes.leftButtonIcon} />
                Import Multiple Users
              </Button>
              <Button color="primary" onClick={() => this.setState({ addUserOpen: true })}>
                <AddIcon className={classes.leftButtonIcon} />
                Add User
              </Button>
            </div>
          }
        >
          <SortableTable
            columns={columnData}
            rows={tableRows}
            stickyHeader
            maxHeight="50vh"
            boldRowsIds={boldRowsIds}
            autoPaginateRowsPerPage={15}
          />
        </CardDialog>
        {userEditing && ( // otherwise, we'll pass user==null to UserEditDialog
          <UserEditDialog
            user={userEditing}
            roles={roles}
            units={units}
            onSaveUserDetails={this.handleEditUserSave}
            onEditCancel={this.handleEditUserCancel}
            users={users}
            organizationExternalId={organization.external_id}
            displayLobs
            displaySuperUser={displaySuperUser}
            displaySystemConfig={displaySystemConfig}
          />
        )}
        {addUserOpen && (
          <UserAddDialog
            roles={roles}
            units={units}
            onAddUser={this.handleAddUser}
            onCancel={() => this.setState({ addUserOpen: false })}
            displayLobs
            displaySuperUser={displaySuperUser}
            displaySystemConfig={displaySystemConfig}
            users={users}
            organizationExternalId={organization.external_id}
          />
        )}
        {importUsersOpen && (
          <UploadFileDialog
            title="Import Multiple Users"
            onClose={() => this.setState({ importUsersOpen: false })}
            onUpload={this.handleImportUsersSuccess}
            onUploadError={this.handleImportUsersError}
            postUrl={`/sysconfig/api/v1/organizations/${organization.id}/import_users`}
            upperExplanation={
              <UploadDialogContent
                note={
                  "Importing multiple users from CSV file will add only new users accounts. Existing accounts won't be updated."
                }
                templateTooltip="Use this template and fill in the required fields to import multiple users’ details."
                classes={classes}
                onCsvTemplateClick={this.onCsvTemplateClick}
              />
            }
            openDialogError={false}
          />
        )}
        {isImportUsersError && (
          <ErrorMessage
            classes={classes}
            onClose={() => this.setState({ isImportUsersError: false })}
            errorMessage={uploadCsvErrorMessage}
          />
        )}
        {isImportUsersSuccess && (
          <SuccessMessageDialog
            classes={classes}
            onClose={() => this.setState({ isImportUsersSuccess: false })}
            title={usersCount > 0 ? `${usersCount} new users were added successfully` : 'No users were added'}
            subtitle={usersCount > 0 ? `${ignoredUsersCount} existing users have not been updated.` : ''}
          />
        )}
        <UserPasswordDialog
          open={!!resetPasswordUser}
          onResetPassword={this.handleResetPassword}
          onCancel={() => this.setState({ resetPasswordUser: null })}
        />
      </>
    );
  }
}

OrganizationUsers.propTypes = {
  classes: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  units: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
  onAddUser: PropTypes.func.isRequired,
  onUpdateUser: PropTypes.func.isRequired,
  onResetPassword: PropTypes.func.isRequired,
  onUnlockUser: PropTypes.func.isRequired,
  onLockUser: PropTypes.func.isRequired,
  withoutTitle: PropTypes.bool,
  reloadUsers: PropTypes.func,
};

export default withStyles(styles)(OrganizationUsers);
