import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { noop } from 'lodash';
import * as Yup from 'yup';

import { UnitRelatedConfigurationsProvider } from '../../hooks/useUnitRelatedConfigurations';
import { getUnitFields, getUnitValidationFields } from '../../OrganizationUnits/Dialogs/UnitDialogUtils';
import { DIALOG_MODE } from '../consts';
import { unitShape } from '../types';

import { UnitDialogFormikWrapper } from './UnitDialogFormikWrapper';

const EditUnitDialogInner = ({
  unit,
  isDeactivating,
  units,
  unitsDict,
  usersDict,
  memberToUnitDict,
  leaderToUnitsDict,
  onClose,
  onSave,
}) => {
  const onSubmitHandler = React.useCallback(
    async (values, formikProps) => {
      try {
        await onSave({ values });
        formikProps.resetForm();
      } catch {
        formikProps.setSubmitting(false);
      }
    },
    [onSave]
  );

  return (
    <Formik
      initialValues={{ ...getUnitFields({ unit }) }}
      validationSchema={Yup.object().shape({ ...getUnitValidationFields() })}
      enableReinitialize
      validateOnChange={false}
      onSubmit={onSubmitHandler}
    >
      <UnitDialogFormikWrapper
        unit={unit}
        units={units}
        unitsDict={unitsDict}
        usersDict={usersDict}
        memberToUnitDict={memberToUnitDict}
        leaderToUnitsDict={leaderToUnitsDict}
        onClose={onClose}
        dialogMode={isDeactivating ? DIALOG_MODE.DEACTIVATE : DIALOG_MODE.EDIT}
      />
    </Formik>
  );
};

export const EditUnitDialog = ({ unit, dialogMode, ...props }) => {
  const inner = <EditUnitDialogInner unit={unit} dialogMode={dialogMode} {...props} />;
  return dialogMode === DIALOG_MODE.EDIT ? (
    inner
  ) : (
    <UnitRelatedConfigurationsProvider unitId={unit.id}>{inner}</UnitRelatedConfigurationsProvider>
  );
};

EditUnitDialog.propTypes = EditUnitDialogInner.propTypes = {
  unit: PropTypes.shape(unitShape).isRequired,
  isDeactivating: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  units: PropTypes.arrayOf(PropTypes.shape(unitShape)),
  usersDict: PropTypes.shape({}),
  unitsDict: PropTypes.shape({}),
  memberToUnitDict: PropTypes.shape({}),
  leaderToUnitsDict: PropTypes.shape({}),
};

EditUnitDialog.defaultProps = EditUnitDialogInner.defaultProps = {
  onClose: noop,
  units: [],
  usersDict: {},
  unitsDict: {},
  memberToUnitDict: {},
  leaderToUnitsDict: {},
};
