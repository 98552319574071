import React from 'react';
import { isEmpty } from 'lodash';

import { useStyles } from '~/assets/styles';
import EmptyState from '~/components/core/EmptyState';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import InternalAdminVitesse from '~/components/Vitesse/InternalAdmin';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled, isLocaleRegionIsUK, isLocaleRegionIsUs } from '~/Utils';

import CardDialog from '../../../CardDialog';
import InnerTabs from '../../../core/Layout/InnerTabs/InnerTabs';
import InternalAdminCCCWrapper from '../CCC/InternalAdmin';
import InternalAdminClaimSearchWrapper from '../ClaimSearch/InternalAdmin';
import InternalAdminNavigatorWrapper from '../Navigator/InternalAdmin';
import InternalAdminOneIncWrapper from '../OneInc/InternalAdmin';
import InternalAdminXactAnalysisWrapper from '../XactAnalysis/InternalAdmin';

const IntegrationTab = () => {
  const classes = useStyles();
  const { organization } = useSysconfig();

  const tabs = [
    {
      label: 'Navigator',
      url: 'navigator',
      isTabHidden: !isLocaleRegionIsUs(),
      component: InternalAdminNavigatorWrapper,
      props: {},
    },
    {
      label: 'ClaimSearch',
      url: 'claim_search',
      isTabHidden: !isLocaleRegionIsUs(),
      component: InternalAdminClaimSearchWrapper,
      props: {},
    },
    {
      label: 'XactAnalysis',
      url: 'xactanalysis',
      isTabHidden: !isLocaleRegionIsUs(),
      component: InternalAdminXactAnalysisWrapper,
      props: {},
    },
    {
      label: 'OneInc',
      url: 'one-inc',
      isTabHidden: !isLocaleRegionIsUs(),
      component: InternalAdminOneIncWrapper,
      props: {},
    },
    {
      label: 'Vitesse',
      url: 'vitesse',
      isTabHidden:
        !isLocaleRegionIsUK() || !isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.VITESSE_PAYMENT),
      component: InternalAdminVitesse,
      props: {},
    },
    {
      label: 'CCC',
      url: 'ccc',
      isTabHidden:
        !isLocaleRegionIsUs() ||
        !isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.CCC_INTEGRATION_DEVELOPMENT),
      component: InternalAdminCCCWrapper,
      props: {},
    },
  ];

  const availableTabs = tabs.filter((tab) => !tab.isTabHidden);
  availableTabs.sort((a, b) => (a.label > b.label ? 1 : -1));

  return (
    <div className={classes.cardDiv}>
      <CardDialog title="Integrations">
        <div className={classes.cardDivRow}>
          {isEmpty(availableTabs) ? (
            <EmptyState title="No Available Integrations" />
          ) : (
            <InnerTabs tabs={availableTabs} />
          )}
        </div>
      </CardDialog>
    </div>
  );
};

export default IntegrationTab;
