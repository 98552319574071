import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import Chip from '~/components/core/Atomic/Chip/Chip';
import EmptyState from '~/components/core/EmptyState';
import { AddIcon } from '~/components/deprecatedMuiIcons';

import { isoDateToUs } from '../../../../../DateTimeUtils';
import { subOrgIdToNameDict } from '../../../../../Utils';
import { FsButton, SortableTable } from '../../../../core';
import useOrganization from '../../../../OrganizationContext';
import { useSysconfig } from '../../../SystemConfigurationScreen';

import { useStyles } from '../../../../../assets/styles';

/**
 * @param {{ configurations: XactAnalysisConfig }} props
 */
const XactAnalysisConfigurationTable = ({
  configurations = [],
  additionalColumnsArray = [],
  setConfigDialogOpen = noop,
}) => {
  const { organization } = useSysconfig();
  const { subOrganizationEnabled, subOrganizations } = useOrganization();
  const classes = useStyles();

  const columns = [
    {
      id: 'name',
      numeric: false,
      label: 'Carrier Profile Name',
      specialCell: (config) => config.name,
    },
    {
      id: 'sub_organization_ids',
      numeric: false,
      label: 'Sub-Organizations',
      specialCell: (config) => {
        if (config.sub_organization_ids?.length === subOrganizations?.length) {
          return <Chip size="small" label="All" className={classes.chip} />;
        }

        return (
          <>
            {config.sub_organization_ids?.map((subOrgId) => (
              <Chip
                key={subOrgId}
                size="small"
                label={subOrgIdToNameDict(organization)[subOrgId]}
                className={classes.chip}
              />
            ))}
          </>
        );
      },
    },
    {
      id: 'last_updated',
      numeric: false,
      label: 'Last Update',
      disableSort: true,
      specialCell: ({ last_updated }) => (last_updated ? isoDateToUs(last_updated) : undefined),
    },
  ];

  !subOrganizationEnabled && columns.splice(1, 1);
  columns.push(...additionalColumnsArray);

  return (
    <SortableTable
      rows={configurations}
      columns={columns}
      defaultOrderColumn={columns.findIndex((column) => column.id === 'name')}
      order="desc"
      stickyHeader
      autoPaginateRowsPerPage={5}
      emptyStateComponent={
        <EmptyState
          subtitle="No carrier profile was defined yet."
          buttonComponent={
            <FsButton variant="contained" color="primary" onClick={() => setConfigDialogOpen()}>
              <span style={{ display: 'flex' }}>
                <AddIcon className={classes.leftButtonIcon} />
                ADD CARRIER PROFILE
              </span>
            </FsButton>
          }
        />
      }
    />
  );
};

XactAnalysisConfigurationTable.propTypes = {
  configurations: PropTypes.array,
  additionalColumnsArray: PropTypes.array,
  setConfigDialogOpen: PropTypes.func,
};

export default XactAnalysisConfigurationTable;
