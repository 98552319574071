import React from 'react';
import { TextField } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { get } from 'lodash';

import Grid from '~/components/core/Atomic/Grid/Grid';

import TextFieldFormik from '../../../../TextFieldFormik';

import BodilyInjurySubSection from './GeneralInvolvedSubSections/BodilyInjurySubSection';
import GeneralDetailsSubSection from './GeneralInvolvedSubSections/GeneralDetailsSubSection';
import PecuniaryLossSubSection from './GeneralInvolvedSubSections/PecuniaryLossSubSection';
import PropertyLossSubSection from './GeneralInvolvedSubSections/PropertyLossSubSection';
import RelatedPropertySubSection from './GeneralInvolvedSubSections/RelatedPropertySubSection';
import BaseConfigurationSection from './BaseConfigurationSection';

const GeneralInvolvedPartySection = ({ partyKey }: { partyKey: string }): JSX.Element => {
  const { values } = useFormikContext();

  const id = `configuration.involved_parties.${partyKey}`;
  const isFirstParty = get(values, `${id}.is_first_party`, false);
  const titlePrefix = isFirstParty ? 'First Party' : 'Third Party';
  const titleSuffix = get(values, `${id}.desc`);
  const title = `${titlePrefix} - ${titleSuffix}`;

  return (
    <BaseConfigurationSection title={title} subtitle={`Key: ${partyKey}`} isHidden={false} isSection>
      <Grid item xs={6}>
        <TextFieldFormik id={`${id}.desc`} label="Display Name" fullWidth />
      </Grid>
      <Grid item xs={6}>
        <TextField id={partyKey} value={partyKey} label="Key" fullWidth disabled />
      </Grid>
      <GeneralDetailsSubSection partyKey={partyKey} />
      <PropertyLossSubSection partyKey={partyKey} />
      <BodilyInjurySubSection partyKey={partyKey} />
      <RelatedPropertySubSection partyKey={partyKey} />
      <PecuniaryLossSubSection partyKey={partyKey} />
    </BaseConfigurationSection>
  );
};

export default GeneralInvolvedPartySection;
