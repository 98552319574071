import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Grid from '~/components/core/Atomic/Grid/Grid';

import Text from '../../../core/TextComponents/Text';
import { ArrowDownwardIcon } from '../../../icons';

const linkClass =
  'flex justify-between items-center bg-white border-1 border-solid border-slate-600 rounded-md p-16 w-[315px] ' +
  'm-4 border-box no-underline';
const arrowClass = '-rotate-90 fill-teal-700';

const LinksList = ({ title, links, useLinkComponent }) => {
  return (
    <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
      {title ? (
        <Grid item xs={12}>
          <div className="font-xl p-4 text-center font-semibold">{title}</div>
        </Grid>
      ) : (
        <></>
      )}

      {links.map(({ label, href }) => (
        <Grid key={label} item>
          {useLinkComponent ? (
            <Link to={href} className={linkClass}>
              <Text variant={Text.VARIANTS.LG} weight={Text.WEIGHTS.MEDIUM}>
                {label}
              </Text>
              <ArrowDownwardIcon className={arrowClass} />
            </Link>
          ) : (
            <a href={href} className={linkClass} key={label} target="_blank" rel="noopener noreferrer">
              <Text variant={Text.VARIANTS.LG} weight={Text.WEIGHTS.MEDIUM}>
                {label}
              </Text>
              <ArrowDownwardIcon className={arrowClass} />
            </a>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

LinksList.propTypes = {
  title: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    })
  ).isRequired,
  useLinkComponent: PropTypes.bool,
};

export default LinksList;
