import React from 'react';
import axios from 'axios';

import { reportAxiosError } from '../../../Utils';
import { LoadingSwitch } from '../../core';
import useDataFetcher from '../../useDataFetcher';
import { useSysconfig } from '../SystemConfigurationScreen';

import SubOrganizationsInner from './SubOrganizationsInner';

const SubOrganizations = () => {
  const { organization, reloadOperationalDetails, reloadOrganization, organizationOperationalDetails } = useSysconfig();
  const {
    isLoading: isLoadingSubOrgs,
    isError: isErrorSubOrgs,
    data: subOrganizations,
    reloadData: reloadSubOrganizations,
  } = useDataFetcher(`/api/v1/organizations/${organization.id}/sub_organizations`);
  const {
    isLoading: isLoadingLobsWithHiddenIndication,
    isError: isErrorLobsWithHiddenIndication,
    data: lobsWithHiddenIndication,
  } = useDataFetcher(`/api/v1/organizations/${organization.id}/available_lobs_with_hidden_indication`);

  const subOrgsClaimIdentifiers = organization.sub_organizations.map((subOrg) => subOrg.claim_identifier).flat();

  const {
    isLoading: isClaimNumberingLoading,
    isError: isClaimNumberingError,
    data: claimNumberingScheme,
  } = useDataFetcher(`/sysconfig/api/v1/organizations/${organization.id}/claim_numbering_scheme`);

  const emailDomains = organizationOperationalDetails.emailDomains;

  const isLoading = isLoadingSubOrgs || isLoadingLobsWithHiddenIndication || isClaimNumberingLoading;
  const isError = isErrorSubOrgs || isErrorLobsWithHiddenIndication || isClaimNumberingError;

  const handleCreateSubOrganization = async (values) => {
    try {
      await axios.post(`/api/v1/organizations/${organization.id}/sub_organizations`, values);
      await reloadSubOrganizations();
      await reloadOperationalDetails();
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
  };

  const handleUpdateSubOrganization = async (values, subOrganizationId) => {
    try {
      await axios.post(`/api/v1/organizations/${organization.id}/sub_organizations/${subOrganizationId}`, values);
      await reloadSubOrganizations();
      await reloadOperationalDetails();
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
  };

  const handleEnableSubOrgs = async () => {
    try {
      await axios.put(`/sysconfig/api/v1/organizations/${organization.id}`, { sub_organizations_enabled: true });
      await reloadOrganization();
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
  };

  return (
    <LoadingSwitch isLoading={isLoading} isError={isError}>
      <SubOrganizationsInner
        organization={organization}
        subOrganizations={subOrganizations}
        lobsWithHiddenIndication={lobsWithHiddenIndication}
        handleCreateSubOrganization={handleCreateSubOrganization}
        handleUpdateSubOrganization={handleUpdateSubOrganization}
        handleEnableSubOrgs={handleEnableSubOrgs}
        claimNumberingScheme={claimNumberingScheme}
        emailDomains={emailDomains}
        subOrgsClaimIdentifiers={subOrgsClaimIdentifiers}
      />
    </LoadingSwitch>
  );
};

export default SubOrganizations;
