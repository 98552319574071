import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Button from '~/components/core/Atomic/Buttons/Button';
import { AddIcon } from '~/components/deprecatedMuiIcons';

import CardDialog from '../CardDialog';
import { SortableTable } from '../core';
import PencilIcon from '../icons/PencilIcon';
import InlineIconButton from '../InlineIconButton';
import LoadingIndicator from '../LoadingIndicator';

import { RoleAddDialog, RoleEditDialog } from './Users/Role';

import styles from '../../assets/styles';

class OrganizationRoles extends Component {
  state = {
    roleEditing: null,
    addRoleOpen: false,
  };

  handleAddRole = (values) => {
    const { onAddRole } = this.props;

    return onAddRole(values).then(() => this.setState({ addRoleOpen: false }));
  };

  handleEditRoleRequest = (role) => {
    this.setState({ roleEditing: role });
  };

  handleEditRoleCancel = () => {
    this.setState({ roleEditing: null });
  };

  handleEditRoleSave = (values) => {
    const { onUpdateRole } = this.props;
    const { roleEditing } = this.state;

    return onUpdateRole(roleEditing.id, values).then(() => this.setState({ roleEditing: null }));
  };

  handleRoleDelete = async () => {
    const { onDeleteRole } = this.props;
    const { roleEditing } = this.state;

    await onDeleteRole(roleEditing.id);
    this.setState({ roleEditing: undefined });
  };

  render() {
    const { classes, roles } = this.props;
    const { isLoading, isError, roleEditing, addRoleOpen } = this.state;

    const columnData = [
      { id: 'name', numeric: false, label: 'Name' },
      { id: 'role_type', numeric: false, label: 'Type' },
      { id: 'users_count', numeric: false, label: 'Users' },
      {
        id: 'edit_role',
        disableSort: true,
        specialCell: (role, isHover) => (
          <span style={{ visibility: isHover ? 'visible' : 'hidden' }}>
            <InlineIconButton
              icon={PencilIcon}
              className={classes.textIcon}
              onClick={(e) => {
                e.stopPropagation();
                this.handleEditRoleRequest(role);
              }}
            />
          </span>
        ),
      },
    ];

    if (isLoading) {
      return <LoadingIndicator isError={isError} />;
    }

    const usersCountColumnIndex = columnData.findIndex((column) => column.id === 'users_count');

    return (
      <>
        <CardDialog
          title="Roles"
          action={
            <Button color="primary" onClick={() => this.setState({ addRoleOpen: true })}>
              <AddIcon className={classes.leftButtonIcon} />
              Add Role
            </Button>
          }
        >
          <SortableTable
            columns={columnData}
            defaultOrderColumn={usersCountColumnIndex}
            order="desc"
            rows={roles}
            maxHeight="40vh"
            stickyHeader
          />
        </CardDialog>
        {roleEditing && ( // otherwise, we'll pass role==null to RoleEditDialog
          <RoleEditDialog
            role={roleEditing}
            onSaveRoleDetails={this.handleEditRoleSave}
            onEditCancel={this.handleEditRoleCancel}
            onDeleteRole={this.handleRoleDelete}
          />
        )}
        {addRoleOpen && (
          <RoleAddDialog
            open={addRoleOpen}
            onAddRole={this.handleAddRole}
            onCancel={() => this.setState({ addRoleOpen: false })}
          />
        )}
      </>
    );
  }
}

OrganizationRoles.propTypes = {
  classes: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  onAddRole: PropTypes.func.isRequired,
  onUpdateRole: PropTypes.func.isRequired,
  onDeleteRole: PropTypes.func.isRequired,
};

export default withStyles(styles)(OrganizationRoles);
