import React from 'react';
import PropTypes from 'prop-types';

import StatusChip from '~/components/core/StatusChip';
import { stringCmp } from '~/Utils';

import { serverDateTimeToLocalDate } from '../../../../DateTimeUtils';
import { INTEGRATION_STATUS_DICT } from '../../../../Types';
import { SortableTable } from '../../../core';
import useOrganization from '../../../OrganizationContext';
import { useSysconfig } from '../../SystemConfigurationScreen';

const DEFAULT_ID = 'default';

const ConfigurationTable = ({ configurations = [], additionalColumnsArray = [], forceOrganizationLevel = false }) => {
  const { organization } = useSysconfig();
  const { subOrganizationEnabled, subOrganizations } = useOrganization();

  const displayOrganizationLevel = forceOrganizationLevel || !subOrganizationEnabled;

  const data = createData(organization, subOrganizations, displayOrganizationLevel, configurations);

  const columns = [
    {
      id: 'name',
      numeric: false,
      label: !displayOrganizationLevel ? 'Sub Organization Name' : 'Organization Name',
      specialCell: (config) => config.name,
    },
    {
      id: 'lastUpdate',
      numeric: false,
      label: 'Last Update',
      disableSort: true,
      specialCell: ({ last_updated_datetime }) =>
        last_updated_datetime ? serverDateTimeToLocalDate(last_updated_datetime) : undefined,
    },
    {
      id: 'status',
      numeric: false,
      label: 'Status',
      specialCell: ({ status }) => (
        <StatusChip withIcon status={(status || INTEGRATION_STATUS_DICT.not_active).toUpperCase()} />
      ),
      specialCmpFunc: (row1, row2) =>
        stringCmp(row1.status || INTEGRATION_STATUS_DICT.not_active, row2.status || INTEGRATION_STATUS_DICT.not_active),
    },
  ];

  columns.push(...additionalColumnsArray);

  return (
    <SortableTable
      rows={data}
      columns={columns}
      defaultOrderColumn={columns.findIndex((column) => column.id === 'name')}
      order="desc"
      stickyHeader
      autoPaginateRowsPerPage={5}
    />
  );
};

const createData = (organization, subOrganizations, displayOrganizationLevel, configurations) => {
  const getDataEntry = ({ name, sub_organization_id, config = {} }) => {
    return {
      name,
      ...config,
      organization_id: organization.id,
      sub_organization_id,
      id: sub_organization_id || DEFAULT_ID,
    };
  };

  if (displayOrganizationLevel) {
    return [
      getDataEntry({
        name: organization.name,
        config: configurations.find(({ sub_organization_id }) => !sub_organization_id),
      }),
    ];
  }

  const configurationBySubOrgId = {};
  configurations.forEach((config) => {
    configurationBySubOrgId[config.sub_organization_id] = config;
  });

  return subOrganizations.map(({ id, name }) =>
    getDataEntry({
      name,
      sub_organization_id: id,
      config: configurationBySubOrgId[id],
    })
  );
};

ConfigurationTable.propTypes = {
  configurations: PropTypes.array,
  additionalColumnsArray: PropTypes.array,
  forceOrganizationLevel: PropTypes.bool,
};

export default ConfigurationTable;
