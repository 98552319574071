import React from 'react';
import { arrayOf, bool, func, object } from 'prop-types';

import Card from '../Card';

import styles from './cards.module.scss';

const Cards = ({ items = [], disabled = false, onActiveChange }) => (
  <div className={styles.container}>
    {items.map((card) => (
      <Card key={card.id} onActiveChange={() => onActiveChange(card, !card.is_active)} disabled={disabled} {...card} />
    ))}
  </div>
);

Cards.propTypes = {
  items: arrayOf(object),
  disabled: bool,
  onActiveChange: func,
};

export default Cards;
