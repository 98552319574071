import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import CancelButton from '~/components/core/Buttons/CancelButton';

import CardDialog from '../../../../../CardDialog';
import TextFieldFormik from '../../../../../TextFieldFormik';

import { useStyles } from '../../../../../../assets/styles';
import styles from '../oneIncInternalAdmin.module.scss';

const OneIncSetupDialog = ({ onSubmit, onClose, configuration }) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        username: configuration?.username || '',
        password: configuration?.password || '',
        url: configuration?.url || '',
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string().required('Required'),
        password: Yup.string().required('Required'),
        url: Yup.string().url('Must be a valid URL'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
        } catch (error) {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, handleSubmit }) => (
        <CardDialog
          containerClassName={styles.claimSearchDialog}
          title="OneInc Integration Details"
          isDialog
          preventClose={isSubmitting}
          maxWidth="sm"
          fullWidth
          onClose={onClose}
        >
          <div className={styles.inputsContainer}>
            <TextFieldFormik id="username" label="Username" className={classes.textField} fullWidth />
            <TextFieldFormik id="password" label="Password" type="password" className={classes.textField} fullWidth />
            <TextFieldFormik id="url" label="URL" className={classes.textField} fullWidth />
          </div>
          <div className={classes.buttonsContainer}>
            <CancelButton disabled={isSubmitting} onClick={onClose} />
            <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </CardDialog>
      )}
    </Formik>
  );
};

OneIncSetupDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  configuration: PropTypes.shape({
    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
};

export default OneIncSetupDialog;
