import React from 'react';

import Grid from '~/components/core/Atomic/Grid/Grid';
import useTableauAccounts from '~/components/SystemConfiguration/TableauAccounts/useTableauAccounts';

import { Heading, LoadingSwitch, Text } from '../../../core';
import OperationsBreadcrumbs from '../../OperationsBreadcrumbs';
import { HEADER, SUB_HEADER } from '../index';
import TableauAccountsTable from '../TableauAccountsTable';

import styles from '../index.module.scss';

const TableauAccountsExternalAdmin = () => {
  const { isLoading, maxLimit } = useTableauAccounts({ isInternalAdmin: false });

  return (
    <LoadingSwitch isLoading={isLoading}>
      <Grid container>
        <Grid item xs={12} container spacing={2} className={styles.externalHeaderContainer}>
          <Grid item xs={12}>
            <OperationsBreadcrumbs currentTab="Tableau Accounts" />
          </Grid>
          <Grid item xs={12}>
            <Heading>{HEADER}</Heading>
          </Grid>
          <Grid item xs={12}>
            <Text className={styles.headerLine} variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
              {SUB_HEADER}
            </Text>
          </Grid>
        </Grid>
        <TableauAccountsTable maxLimit={maxLimit} />
      </Grid>
    </LoadingSwitch>
  );
};

export default TableauAccountsExternalAdmin;
