import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { isUserSuperUser } from '../../../UserUtils';
import { reportErrorInProductionOrThrow } from '../../../Utils';
import CardDialog from '../../CardDialog';
import { ContactShowOnlyTextField } from '../../ContactTextFieldFormik';
import { useCms } from '../../hooks/useCms';
import LoadingIndicator from '../../LoadingIndicator';
import useDataFetcher from '../../useDataFetcher';
import { useSysconfig } from '../SystemConfigurationScreen';

function OrganizationSpecialContacts() {
  const { organization } = useSysconfig();
  const { user } = useCms();

  const {
    isLoading,
    isError,
    data: organizationSpecialContacts,
  } = useDataFetcher(`/admin/api/v1/organizations/${organization.id}/special_contacts`);

  if (!isUserSuperUser(user)) {
    reportErrorInProductionOrThrow(
      `User: ${user.username} -  id:${user.id} tried accessing OrganizationSpecialContacts`
    );
    return <Redirect to="/" />;
  }

  if (isLoading || isError) {
    return <LoadingIndicator isError={isError} />;
  }

  return (
    <CardDialog title="Organization Special Contacts">
      {organizationSpecialContacts.map((specialContact) => (
        <div key={specialContact.special_role}>
          <span style={{ display: 'inline-flex', alignItems: 'center', flexWrap: 'wrap' }}>
            <strong>{`${specialContact.description}: `}</strong>
            <span>
              <SpecialContactContainer contact={specialContact.contact} />
            </span>
          </span>
        </div>
      ))}
    </CardDialog>
  );
}

function SpecialContactContainer(props) {
  const { contact } = props;

  return (
    <ContactShowOnlyTextField
      contactId={contact ? contact.id : ''}
      contactDisplayName={contact ? contact.full_name : ''}
      showOnly
    />
  );
}

SpecialContactContainer.propTypes = {
  contact: PropTypes.object,
};

export default OrganizationSpecialContacts;
