import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';
import CancelButton from '~/components/core/Buttons/CancelButton';

import { isUserSuperUser } from '../../../../UserUtils';
import CardDialog from '../../../CardDialog';
import CheckboxFormik from '../../../CheckboxFormik';
import { useCms } from '../../../hooks/useCms';
import TextFieldFormik from '../../../TextFieldFormik';

import styles from '../../../../assets/styles';

const userPasswordFields = {
  password: '',
  password_confirmation: '',
  force_updating_password: true,
};

const userPasswordValidationFields = {
  password: Yup.string()
    .required('Required')
    .min(5)
    .test('no-spaces', "Password can't start or end with a blank space", (pass) => pass && pass.trim() === pass),
  force_updating_password: Yup.boolean().required('Required'),
};

const UserPasswordDialog = (props) => {
  const { classes, open, onResetPassword, onCancel } = props;
  const { user } = useCms();

  return (
    <Formik
      initialValues={{ ...userPasswordFields }}
      validationSchema={Yup.object().shape({
        ...userPasswordValidationFields,
      })}
      enableReinitialize
      onSubmit={(values, formikProps) => {
        onResetPassword(values)
          .then(() => {
            formikProps.resetForm();
          })
          .catch(() => {
            formikProps.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog
            isDialog={true}
            open={open}
            title="Reset Password"
            maxWidth="sm"
            onClose={onCancel}
            preventClose={isSubmitting}
          >
            <Grid container spacing={1}>
              <>
                {isUserSuperUser(user) ? (
                  <Grid item xs={12}>
                    <CheckboxFormik id="force_updating_password" label="Force Updating Password" fullWidth />
                  </Grid>
                ) : (
                  <Typography>The user will need to update a password at the next login</Typography>
                )}
              </>
              <Grid item xs={12}>
                <TextFieldFormik id="password" label="Password" />
              </Grid>
              <Grid item xs={12}>
                <div className={classes.buttonsContainer}>
                  <CancelButton disabled={isSubmitting} onClick={onCancel} />
                  <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                    Reset Password
                  </Button>
                </div>
              </Grid>
            </Grid>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

UserPasswordDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onResetPassword: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const UserPasswordDialogStyled = withStyles(styles)(UserPasswordDialog);

export { UserPasswordDialogStyled as UserPasswordDialog };
