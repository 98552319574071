import React from 'react';
import { TextField } from '@material-ui/core';

interface SearchEvent {
  target: { value: string };
}

interface GenericTemplateBaseSearchContainerProps {
  handleChange: (event: SearchEvent) => void;
}

const GenericTemplateBaseSearchContainer: React.FC<GenericTemplateBaseSearchContainerProps> = ({ handleChange }) => {
  return (
    <div className="grid w-full grid-cols-2">
      <div className="col-span-1">
        <TextField fullWidth onChange={handleChange} label="Template Name" />
      </div>
    </div>
  );
};

export default GenericTemplateBaseSearchContainer;
