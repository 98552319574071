import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';

import CardDialog from '../../CardDialog';
import { FsButton } from '../../core';
import { TextFieldFormik } from '../../TextFieldFormik';

import { useStyles } from '../../../assets/styles';

const GeneralEmailDialog = ({ onCancel, onSubmit, emailDomain }) => {
  const classes = useStyles();

  const email_domain = `@${emailDomain.domain}`;

  return (
    <Formik
      initialValues={{
        email_username: '',
        email_domain,
      }}
      validationSchema={Yup.object().shape({
        email_username: Yup.string().test(
          'validate_format',
          'Invalid format - avoid spaces and special symbols',
          (value) => {
            const usernameRegex = /^[a-zA-Z0-9_]+$/;

            return usernameRegex.test(value);
          }
        ),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const emailAddress = `${values.email_username}${email_domain}`;
          await onSubmit(emailAddress, emailDomain.id);
          setSubmitting(false);
        } catch (error) {
          setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog
            isDialog={true}
            title="Add General Email Queue"
            fullWidth
            onClose={onCancel}
            preventClose={isSubmitting}
          >
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextFieldFormik
                  id="email_username"
                  label="Email Name"
                  className={classes.textField}
                  fullWidth
                  disabled={isSubmitting}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldFormik id="email_domain" label="Domain" className={classes.textField} fullWidth showOnly />
              </Grid>
            </Grid>
            <div className={classes.buttonsContainer}>
              <CancelButton disabled={isSubmitting} onClick={onCancel} />
              <FsButton variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Save
              </FsButton>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

GeneralEmailDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  emailDomain: PropTypes.object.isRequired,
};

export default GeneralEmailDialog;
