import React from 'react';

import cn from '~/Utils/cn';

export interface ButtonsContainerProps {
  className?: string;
  children: React.ReactNode;
}

const ButtonsContainer: React.FC<ButtonsContainerProps> = ({ children, className }) => (
  <div className={cn('mt-20 flex w-full justify-end', className)}>{children}</div>
);

export default ButtonsContainer;
