import { CLOSING_REASON_CATEGORIES } from './Types';

export function getSubCategoriesDict() {
  return Object.keys(CLOSING_REASON_CATEGORIES).reduce((acc, category) => {
    return {
      ...acc,
      ...CLOSING_REASON_CATEGORIES[category]['sub_categories'],
    };
  }, {});
}
