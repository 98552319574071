import React from 'react';
import { TextField } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import { getIn, useFormikContext } from 'formik';

import { useStyles } from '~/assets/styles';
import MenuItem from '~/components/core/Atomic/MenuItem';
import colors from '~/theme/tailwind/colors';

import { ErrorHelperTextFormik } from '../../core/Formik/ErrorHelperTextFormik';
import useOrganization from '../../OrganizationContext';

const ALL_ITEM_VALUE = '__all__';

interface PartialValuesType {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [name: string]: any;
}

interface SubOrganizationMultiselectFormikProps {
  subOrganizationsFieldId: string;
  allSelectedFieldId?: string;
  label?: string;
  selectAllLabel?: string;
  showAllInRenderedSelectedOptions?: boolean;
  shouldDisplayAllOption?: boolean;
  onChange?: (p: { subOrgs: string | string[]; selectAll: boolean }) => void;
  disabled?: boolean;
}

const SubOrganizationMultiselectFormik: React.FC<SubOrganizationMultiselectFormikProps> = ({
  subOrganizationsFieldId,
  allSelectedFieldId,
  onChange,
  label = 'Sub-Organizations',
  selectAllLabel = 'All',
  showAllInRenderedSelectedOptions = false,
  shouldDisplayAllOption = false,
  disabled = false,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { subOrganizationEnabled, subOrganizations } = useOrganization();
  const { setFieldValue, values, errors, touched } = useFormikContext<PartialValuesType>();
  const classes = useStyles();

  if (!subOrganizationEnabled) {
    return null;
  }

  const selectedSubOrgs = getIn(values, subOrganizationsFieldId) || [];
  const isSelectAllChecked =
    shouldDisplayAllOption &&
    ((allSelectedFieldId && values[allSelectedFieldId]) || // selectAllField was passed in, get value
      (!allSelectedFieldId && selectedSubOrgs.length === 0) || // selectAllField was not passed in, is list empty
      false);

  const callOnChange = (subOrgs: string | string[], selectAll: boolean) => {
    if (onChange) {
      onChange({ subOrgs, selectAll });
    }
  };

  const setAllSelectedFieldValue = (value: boolean) => {
    if (allSelectedFieldId) {
      setFieldValue(allSelectedFieldId, value);
    }
  };

  const handleSelectionChange = (event: { target: { value: string | string[] } }) => {
    if (!event.target.value?.includes(ALL_ITEM_VALUE)) {
      setFieldValue(subOrganizationsFieldId, event.target.value);
      setAllSelectedFieldValue(false);
      callOnChange(event.target.value, false);
    }
  };

  const handleSelectAll = (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    const newSelectAllValue = !isSelectAllChecked;

    setAllSelectedFieldValue(newSelectAllValue);

    if (newSelectAllValue) {
      setFieldValue(subOrganizationsFieldId, []);
    }

    callOnChange(newSelectAllValue ? [] : selectedSubOrgs, newSelectAllValue);
  };

  const isSubOrgChecked = (id: number) => {
    return getIn(values, subOrganizationsFieldId).indexOf(id) > -1;
  };

  const renderFieldValue = (ids: unknown) => {
    if (showAllInRenderedSelectedOptions && isSelectAllChecked) {
      return selectAllLabel;
    }
    return (ids as number[])
      .map((id) => subOrganizations.find((subOrg: { id: number }) => id === subOrg.id)?.name)
      .join(', ');
  };

  return (
    <>
      <TextField
        id={subOrganizationsFieldId}
        label={label}
        className={classes.textField}
        value={selectedSubOrgs}
        onChange={handleSelectionChange}
        disabled={disabled}
        error={
          (getIn(errors, subOrganizationsFieldId) && getIn(touched, subOrganizationsFieldId)) ||
          (allSelectedFieldId && getIn(errors, allSelectedFieldId) && getIn(touched, allSelectedFieldId))
        }
        size="small"
        select
        fullWidth
        InputLabelProps={{
          shrink: (showAllInRenderedSelectedOptions && isSelectAllChecked) || !!selectedSubOrgs.length,
        }}
        SelectProps={{
          multiple: true,
          renderValue: renderFieldValue,
          displayEmpty: true,
        }}
      >
        {shouldDisplayAllOption ? (
          <MenuItem value={ALL_ITEM_VALUE}>
            <Checkbox
              checked={isSelectAllChecked}
              onChange={handleSelectAll}
              style={{ color: isSelectAllChecked ? colors.teal['700'] : '' }}
            />
            <ListItemText primary={selectAllLabel} onClick={handleSelectAll} />
          </MenuItem>
        ) : null}
        {subOrganizations.map((subOrg: { id: number; name: string }) => (
          <MenuItem key={subOrg.id} value={subOrg.id}>
            <Checkbox
              checked={isSubOrgChecked(subOrg.id)}
              style={{ color: isSubOrgChecked(subOrg.id) ? colors.teal['700'] : '' }}
            />
            <ListItemText primary={subOrg.name} />
          </MenuItem>
        ))}
      </TextField>
      {allSelectedFieldId && <ErrorHelperTextFormik id={allSelectedFieldId} />}
      <ErrorHelperTextFormik id={subOrganizationsFieldId} />
    </>
  );
};

export default SubOrganizationMultiselectFormik;
