import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik, useFormikContext } from 'formik';

import { reportAxiosError } from '../../../../../Utils';
import { useSysconfig } from '../../../SystemConfigurationScreen';
import { PERMISSIONS_MIXPANEL_EVENTS, PERMISSIONS_MIXPANEL_SOURCES } from '../MixpanelEvents';
import { PROFILES_ROUTES } from '../routes';

import { FORM_FIELDS, formInitialValues, getFormValidationScheme } from './formUtils';
import { ProfileDialogBase } from './ProfileDialogBase';
import { ProfileDialogStepper } from './ProfileDialogStepper';

export const CreateProfileDialog = ({ onClose, reloadData }) => {
  const { organization: sysConfigOrganization } = useSysconfig();

  const handleSubmit = React.useCallback(
    async (data) => {
      await axios.post(PROFILES_ROUTES.CREATE(sysConfigOrganization.id), data);
    },
    [sysConfigOrganization.id]
  );

  const validationScheme = React.useRef(getFormValidationScheme({ organizationId: sysConfigOrganization.id }));

  return (
    <Formik
      initialValues={formInitialValues()}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await handleSubmit(values);
          await reloadData();
          onClose();
        } catch (e) {
          await reportAxiosError(e);
        }
        setSubmitting(false);
      }}
      validationSchema={validationScheme.current}
      validateOnChange={false}
      validateOnBlur={false}
    >
      <CreateProfileDialogInner onClose={onClose} />
    </Formik>
  );
};

CreateProfileDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  reloadData: PropTypes.func.isRequired,
};

const CreateProfileDialogInner = ({ onClose }) => {
  const [currentStep, setCurrentStep] = React.useState(0);
  const { values } = useFormikContext();

  const mixpanelCloseConfirmAdditionalData = React.useMemo(
    () => ({
      profile_name: values[FORM_FIELDS.display_name],
      default_permissions: values[FORM_FIELDS.default_permission],
      source: PERMISSIONS_MIXPANEL_SOURCES.CREATE_PROFILE,
      stage: ProfileDialogStepper.getStepByIndex(currentStep),
    }),
    [currentStep, values]
  );
  const handleStepChange = (_, nextStepIndex) => {
    setCurrentStep(nextStepIndex);
  };

  return (
    <ProfileDialogBase
      onClose={onClose}
      onCloseConfirm={onClose}
      title="Create Permissions Profile"
      mixpanelConfig={{
        source: PERMISSIONS_MIXPANEL_SOURCES.CREATE_PROFILE,
        closeConfirmAdditionalData: mixpanelCloseConfirmAdditionalData,
      }}
    >
      <ProfileDialogStepper
        onClose={onClose}
        onStepChange={handleStepChange}
        mixpanelConfig={{
          source: PERMISSIONS_MIXPANEL_SOURCES.CREATE_PROFILE,
          stepChangeEvent: PERMISSIONS_MIXPANEL_EVENTS.create_permissions_profile_back_next_clicked,
          closeConfirmEventName: PERMISSIONS_MIXPANEL_EVENTS.cancel_create_permissions_profile,
        }}
      />
    </ProfileDialogBase>
  );
};

CreateProfileDialogInner.propTypes = {
  onClose: PropTypes.func.isRequired,
};
