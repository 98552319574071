import React from 'react';
import axios from 'axios';

import { reportAxiosError } from '../../../Utils';
import useDataFetcher from '../../useDataFetcher';
import { useSysconfig } from '../SystemConfigurationScreen';

import { MoiConfigurationTool } from './MoiConfigurationTool';

const MoiConfigurationToolBackendWrapper = () => {
  const {
    organization,
    organizationOperationalDetails: { supportedClaimTypes, moiConfiguration },
    reloadOperationalDetails,
  } = useSysconfig();

  const baseCoveragesRoute = `/sysconfig/api/v1/organizations/${organization.id}/moi_methods`;
  const moiConfigBaseRoute = `/sysconfig/api/v1/organizations/${organization.id}/moi_configurations`;

  const { isLoading, isError, data: moiMethods, reloadData: reloadCoverages } = useDataFetcher(baseCoveragesRoute);

  const callAPI = async (method, url, values) => {
    try {
      await axios({ method, url, data: values });
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
  };

  const handleToggleClaimLevelMoi = async () => {
    const claimLevelMoiEnabled = moiConfiguration?.claim_level_moi_enabled;
    await callAPI('post', moiConfigBaseRoute, { claim_level_moi_enabled: !claimLevelMoiEnabled });
    await reloadOperationalDetails();
  };

  const handleToggleMultipleExposuresMoi = async () => {
    const multipleExposuresMoiEnabled = moiConfiguration?.multiple_exposures_moi_enabled;
    await callAPI('post', moiConfigBaseRoute, { multiple_exposures_moi_enabled: !multipleExposuresMoiEnabled });
    await reloadOperationalDetails();
  };

  const handleCreateMoiMethod = async (values) => {
    await callAPI('post', baseCoveragesRoute, values);
    await reloadCoverages();
  };

  const handleUpdateMoiMethod = async (values, id) => {
    await callAPI('patch', `${baseCoveragesRoute}/${id}`, values);
    await reloadCoverages();
  };

  const handleFetchUsedMoiMethodKeys = () => moiMethods.map((m) => m.key);

  return (
    <MoiConfigurationTool
      isLoading={isLoading && !moiMethods}
      isError={isError}
      organization={organization}
      supportedClaimTypes={supportedClaimTypes}
      moiConfiguration={moiConfiguration}
      moiMethods={moiMethods}
      onUpdateMoiMethod={handleUpdateMoiMethod}
      onCreateMoiMethod={handleCreateMoiMethod}
      onToggleClaimLevelMoi={handleToggleClaimLevelMoi}
      onToggleMultipleExposuresMoi={handleToggleMultipleExposuresMoi}
      fetchUsedMoiMethodKeys={handleFetchUsedMoiMethodKeys}
      errorHandler={reportAxiosError}
    />
  );
};
export { MoiConfigurationToolBackendWrapper };
