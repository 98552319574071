import React from 'react';
import PropTypes from 'prop-types';

import ClosedExposureLimitDialogFormik from '~/components/SystemConfiguration/Tabs/PaymentsConfiguration/PaymentConfigTab/AdditionalDialogs/ClosedExposureLimitDialog/ClosedExposureLimitDialogFormik';
import { PAYMENTS_CONFIG_FIELDS } from '~/components/SystemConfiguration/Tabs/PaymentsConfiguration/PaymentConfigTab/constants';

const FIELD_IDS = {
  IS_INDEMNITY_LIMIT_FOR_CLOSED_EXPOSURE_ENABLED: PAYMENTS_CONFIG_FIELDS.IS_INDEMNITY_LIMIT_FOR_CLOSED_EXPOSURE_ENABLED,
  INDEMNITY_LIMIT_FOR_CLOSED_EXPOSURE: PAYMENTS_CONFIG_FIELDS.INDEMNITY_LIMIT_FOR_CLOSED_EXPOSURE,
};

const ClosedExposureIndemnityLimitDialog = ({ paymentsConfiguration, disabled = false, onCancel, onSubmit }) => {
  return (
    <ClosedExposureLimitDialogFormik
      paymentsConfiguration={paymentsConfiguration}
      limitType="indemnity"
      switchFormikId={FIELD_IDS.IS_INDEMNITY_LIMIT_FOR_CLOSED_EXPOSURE_ENABLED}
      limitFormikId={FIELD_IDS.INDEMNITY_LIMIT_FOR_CLOSED_EXPOSURE}
      disabled={disabled}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  );
};

ClosedExposureIndemnityLimitDialog.propTypes = {
  paymentsConfiguration: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default ClosedExposureIndemnityLimitDialog;
