import React from 'react';
import PropTypes from 'prop-types';

import { FsMenuItem } from '../../../../../core';
import { PERMISSION_VERBS_CONFIG } from '../../../../../core/Permissions/PermissionUtils';
import TextFieldFormik from '../../../../../TextFieldFormik';
import { FORM_FIELDS } from '../formUtils';

const SORTED_VERBS = Object.values(PERMISSION_VERBS_CONFIG).sort((a, b) => a.index - b.index);

export const DefaultVerbSelectField = ({ viewOnly }) => (
  <TextFieldFormik id={FORM_FIELDS.default_permission} label="Default Permission" fullWidth select showOnly={viewOnly}>
    {SORTED_VERBS.map((verbConfig) => (
      <FsMenuItem key={verbConfig.id} value={verbConfig.optionValue}>
        {verbConfig.displayName}
      </FsMenuItem>
    ))}
  </TextFieldFormik>
);

DefaultVerbSelectField.propTypes = {
  viewOnly: PropTypes.bool,
};
