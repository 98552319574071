export const buildGetTemplateTokensQueryParams = (params: {
  lobs: string[];
  is_claim_level: boolean;
  feature_specific?: string;
}): string => {
  const searchParams = new URLSearchParams();
  searchParams.append('is_claim_level', params.is_claim_level.toString());

  if (params.lobs) {
    params.lobs.forEach((lob) => searchParams.append('lobs', lob));
  }
  if (params.feature_specific) {
    searchParams.append('feature_specific', params.feature_specific);
  }
  return searchParams.toString();
};
