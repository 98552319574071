import React from 'react';

import cn from '../../../../../Utils/cn';
import { Text } from '../../../../core';
import { TrashIcon } from '../../../../icons';
import InlineIconButton from '../../../../InlineIconButton';

import { useStyles } from '../../../../../assets/styles';

interface ConditionCardWrapperProps {
  idx: number;
  onDelete: (idx: number) => void;
}

export const ConditionCardWrapper: React.FC<ConditionCardWrapperProps> = ({ children, idx, onDelete }) => {
  const classes = useStyles();

  return (
    <div className={cn('flex flex-grow flex-col space-y-20 rounded-md border-1 border-solid border-slate-600 p-20')}>
      <div className={cn('flex flex-grow flex-row justify-between')}>
        <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.SEMI_BOLD}>
          Condition #{idx + 1}
        </Text>
        <InlineIconButton
          icon={TrashIcon}
          tooltipTitle="Remove"
          iconColor="currentColor"
          className={`${classes.textIcon} ${classes.hoverableIconNew}`}
          onClick={() => onDelete(idx)}
          wrapWithSpan
          defaultColor={undefined}
          disabled={undefined}
          iconStyle={undefined}
          useIconButton={undefined}
          ignorePermissions={undefined}
          ariaLabel={undefined}
        />
      </div>
      <div className={cn('flex-grow flex-row')}>{children}</div>
    </div>
  );
};
