import React from 'react';

import ConfirmDialogContent from '../../../Users/UserDialogs/UserPermissionsSection/ConfirmDialogContent';
import { PERMISSIONS_MIXPANEL_EVENTS, PERMISSIONS_MIXPANEL_SOURCES, safeMixpanelTrack } from '../MixpanelEvents';
import { ProfilesTable } from '../ProfilesTable/ProfilesTable';

const DELETE_DIALOG_MESSAGES = {
  isDeleted: 'please refresh your screen',
  hasUsers:
    'Active users are currently assigned to this profile. Before deleting the profile, you must reassign all users to a new profile.',
  deleteAllowed: 'Are you sure you want to delete this profile?',
};

export const useDeleteProfileDialog = ({ profileStatus, profile }) => {
  return React.useMemo(() => {
    const hasUsersAssigned = profileStatus.has_users;
    const isDeleted = profileStatus.is_deleted;

    const title = isDeleted ? 'This profile has been deleted.' : 'Delete Permission Profile?';

    let subtitle, onOpenEvent;
    if (isDeleted) {
      subtitle = DELETE_DIALOG_MESSAGES.isDeleted;
      onOpenEvent = PERMISSIONS_MIXPANEL_EVENTS.delete_permission_profile_already_deleted_error_presented;
    } else {
      subtitle = hasUsersAssigned
        ? 'Active users are currently assigned to this profile. Before deleting the profile, you must reassign all users to a new profile.'
        : 'Are you sure you want to delete this profile?';
      onOpenEvent = hasUsersAssigned
        ? PERMISSIONS_MIXPANEL_EVENTS.delete_permission_profile_assigned_users_error_presented
        : PERMISSIONS_MIXPANEL_EVENTS.delete_permission_profile_confirm_presented;
    }

    const contentText = <ConfirmDialogContent title={title} subtitle={subtitle} />;

    const deleteButtonMixpanelParams = {
      profile_name: profile[ProfilesTable.COLUMNS.profile_name],
      default_permissions: profile[ProfilesTable.COLUMNS.default_permissions],
      number_of_users: profileStatus.number_of_users,
      source: PERMISSIONS_MIXPANEL_SOURCES.TABLE,
    };

    return {
      contentText,
      primaryButtonName: hasUsersAssigned ? '' : 'Delete',
      secondaryButtonName: hasUsersAssigned || isDeleted ? 'Close' : 'Cancel',
      secondaryButtonProps:
        hasUsersAssigned || isDeleted
          ? {
              color: 'primary',
              variant: 'contained',
            }
          : undefined,
      maxWidth: 'lg',
      title: '',
      removeSecondaryButton: profileStatus.has_users,
      onOpenConfirmModal: () => {
        safeMixpanelTrack(PERMISSIONS_MIXPANEL_EVENTS.delete_permission_profile_clicked, deleteButtonMixpanelParams);
        safeMixpanelTrack(onOpenEvent, deleteButtonMixpanelParams);
      },
      postOnClick: () => {
        safeMixpanelTrack(PERMISSIONS_MIXPANEL_EVENTS.permission_profile_deleted, deleteButtonMixpanelParams);
      },
    };
  }, [profile, profileStatus.has_users, profileStatus.is_deleted, profileStatus.number_of_users]);
};
