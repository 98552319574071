import React from 'react';

import { useSysconfig } from '../SystemConfigurationScreen';

import SpecialContactsContainer from './SpecialContactsContainer';

const OrganizationSpecialContacts = () => {
  const { organization } = useSysconfig();
  const url = `/admin/api/v1/organizations/${organization.id}/special_contacts`;

  return (
    <SpecialContactsContainer
      generateFetchUrl={() => url}
      editable
      entityLabelSingular="Special Contact"
      entityLabelPlural="Special Contacts"
    />
  );
};

export default OrganizationSpecialContacts;
