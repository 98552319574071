import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import { configurationProps } from '../../propTypes';

const ConfigurationContext = createContext({});

const ClaimSearchConfigurationContextProvider = ({ configuration, children }) => {
  return <ConfigurationContext.Provider value={{ configuration }}>{children}</ConfigurationContext.Provider>;
};

ClaimSearchConfigurationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  configuration: configurationProps,
};

const useConfiguration = () => {
  const { configuration } = useContext(ConfigurationContext);
  return configuration;
};

export { ClaimSearchConfigurationContextProvider, useConfiguration };
