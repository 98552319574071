const LOB_ISO_CODES = {
  CCGL: 'Commercial General Liability',
  CLBO: 'Commercial Liability Business Owners',
  OLIB: 'Other Liability',
  PLIB: 'Professional Liability',
  PPHO: 'Personal Property Homeowners',
  PPOP: 'Personal Property Other',
  CPMP: 'Commercial Property Multiperil',
  PAPP: 'Personal Auto',
  CAPP: 'Commercial Auto',
  WCEL: 'Workers Compensations and Employers Liability',
} as const;

export default LOB_ISO_CODES;
