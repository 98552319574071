import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { orderBy } from 'lodash';
import * as Yup from 'yup';

import CancelButton from '~/components/core/Buttons/CancelButton';

import { isHiddenRole } from '../../../Utils';
import CardDialog from '../../CardDialog';
import { FsButton } from '../../core';
import { MultiSelectTextFieldFormik } from '../../TextFieldFormik';
import { useSysconfig } from '../SystemConfigurationScreen';

import { useStyles } from '../../../assets/styles';

const AuthorizedUsersDialog = ({ onCancel, onSubmit, existingUsers }) => {
  const classes = useStyles();
  const { users } = useSysconfig();

  const sortedUsers = orderBy(users, ['username']).filter((user) => !isHiddenRole(user.role.role_type));
  const userIds = existingUsers.map((user) => user.id);

  return (
    <Formik
      initialValues={{
        user_ids: userIds || [],
      }}
      validationSchema={Yup.object().shape({
        user_ids: Yup.array().min(1),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit(values);
        setSubmitting(false);
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog
            isDialog={true}
            title="Edit Authorizations"
            fullWidth
            onClose={onCancel}
            preventClose={isSubmitting}
          >
            <MultiSelectTextFieldFormik
              id="user_ids"
              placeholder="Select authorized users"
              disabled={isSubmitting}
              className={classes.textField}
              fullWidth
              renderOption={(userId) => sortedUsers.find((user) => user.id === userId)?.username}
              options={sortedUsers.map((user) => user.id)}
              renderValue={() => ''}
              withOptionChips
            />
            <div className={classes.buttonsContainer}>
              <CancelButton disabled={isSubmitting} onClick={onCancel} />
              <FsButton variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Save
              </FsButton>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

AuthorizedUsersDialog.propTypes = {
  existingUsers: PropTypes.array,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AuthorizedUsersDialog;
