import React, { useEffect } from 'react';

import { ADMIN_ROUTES } from '../../../../routesConstants';
import { isUserFiveSigma } from '../../../../UserUtils';
import CardDialog from '../../../CardDialog';
import InnerTabs from '../../../core/Layout/InnerTabs/InnerTabs';
import { useCms } from '../../../hooks/useCms';

import EventSettings from './sections/EventSettings/EventSettings';
import PolicyAPIConfig from './sections/PolicyAPIConfig/PolicyAPIConfig';
import ServiceAccounts from './sections/ServiceAccounts';
import VendorAPIProfiles from './sections/VendorAPIProfiles';

import { useStyles } from '../../../../assets/styles';

const DeveloperToolsTab = () => {
  const { setPageTitle, user } = useCms();
  const classes = useStyles();

  useEffect(() => {
    setPageTitle('Developer Tools');
  }, [setPageTitle]);

  const tabs = [
    { label: 'Service Accounts', url: ADMIN_ROUTES.DEVELOPER_TOOLS.SERVICE_ACCOUNTS, component: ServiceAccounts },
    { label: 'Event Settings', url: ADMIN_ROUTES.DEVELOPER_TOOLS.EVENT_SETTINGS, component: EventSettings },
  ];

  if (isUserFiveSigma(user)) {
    tabs.push(
      {
        label: 'Policy API Config',
        url: ADMIN_ROUTES.DEVELOPER_TOOLS.POLICY_API_CONFIG,
        component: PolicyAPIConfig,
      },
      {
        label: 'Service Provider Profiles',
        url: ADMIN_ROUTES.DEVELOPER_TOOLS.VENDOR_API_PROFILES,
        component: VendorAPIProfiles,
      }
    );
  }

  return (
    <div className={classes.cardDivRow}>
      <CardDialog title="Developer Tools">
        <InnerTabs tabs={tabs} />
      </CardDialog>
    </div>
  );
};

DeveloperToolsTab.propTypes = {};

export default DeveloperToolsTab;
