export const TOTAL_ALLOWED_QUESTIONS = 25;
export const SLA_TIME_PLACEHOLDER = '$sla_time';
export const SLA_VALUE_PLACEHOLDER = '$sla_value';
export const QUESTIONS_KEY = 'questions';
export const CUSTOM_QUESTIONS_KEY = 'custom_questions';
export const FORM_NAME_KEY = 'form_name';
export const AUTOMATIC_TYPE = 'AUTOMATIC';
export const PREDEFINED_TYPE = 'PREDEFINED';
export const TIMELINESS_CATEGORY = 'TIMELINESS';
export const PAYMENTS_CATEGORY = 'PAYMENTS';
export const ADJUDICATION_CATEGORY = 'ADJUDICATION';
