import React from 'react';
import axios from 'axios';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';

import { PERMISSION_ACTIONS_CONFIG, PERMISSION_VERBS_CONFIG } from '../../../../core/Permissions/PermissionUtils';
import ConfirmDialogContent from '../../../Users/UserDialogs/UserPermissionsSection/ConfirmDialogContent';
import { PROFILES_ROUTES } from '../routes';

export const getPermissionsFieldId = (id) => `permissions.${id}`;

export const FORM_FIELDS = {
  id: 'id',
  display_name: 'display_name',
  description: 'description',
  default_permission: 'default_permission',
  permissions: 'permissions',
  users: 'users',
};

export const formInitialValues = (isTest = false) => ({
  [FORM_FIELDS.display_name]: '',
  [FORM_FIELDS.description]: '',
  [FORM_FIELDS.default_permission]: '',
  [FORM_FIELDS.permissions]: Object.fromEntries(
    Object.keys(PERMISSION_ACTIONS_CONFIG).map((permissionId) => [
      permissionId,
      isTest ? PERMISSION_VERBS_CONFIG.FORBIDDEN.optionValue : '',
    ])
  ),
  [FORM_FIELDS.users]: [],
});

export const profileToInitialValues = (profile) => {
  // To improve performance, we use the pointer to the option value as the actual value,
  // sp the array coming from the backend needs to be replaced with the reference
  const permissions = Object.fromEntries(
    profile.profile_permissions.map(({ permission_action, permission_verbs }) => [
      permission_action,
      PERMISSION_VERBS_CONFIG.getVerbByOptionValue(permission_verbs.sort())?.optionValue, // verbs are sorted as a hack to account for db entries that have "WRITE,READ" instead of "READ,WRITE"
    ])
  );

  return {
    [FORM_FIELDS.id]: profile.id,
    [FORM_FIELDS.display_name]: profile.display_name,
    [FORM_FIELDS.description]: profile.description ?? '',
    [FORM_FIELDS.default_permission]: PERMISSION_VERBS_CONFIG.getVerbByOptionValue(profile.defaults.sort())
      ?.optionValue,
    [FORM_FIELDS.permissions]: permissions,
    [FORM_FIELDS.users]: profile.user_ids,
  };
};

export const getInitialTouched = () => ({
  [FORM_FIELDS.display_name]: false,
  [FORM_FIELDS.description]: false,
  [FORM_FIELDS.default_permission]: false,
  [FORM_FIELDS.permissions]: {},
});

export const getFormValidationScheme = ({ organizationId, currentProfileName }) => {
  return Yup.object().shape({
    [FORM_FIELDS.display_name]: Yup.string()
      .test({
        name: 'unique_profile_name',
        message: 'Profile name must be unique',
        test: async (value) => {
          const { data } = await axios.get(PROFILES_ROUTES.ACTIVE(organizationId));
          const currentProfileNames = data.map(({ display_name }) => display_name);
          return !currentProfileNames.includes(value) || (currentProfileName && value === currentProfileName);
        },
      })
      .required('Required'),
    [FORM_FIELDS.description]: Yup.string().required('Required'),
    [FORM_FIELDS.default_permission]: Yup.array().required('Required'),
    [FORM_FIELDS.permissions]: Yup.object().shape(
      Object.fromEntries(
        Object.values(PERMISSION_ACTIONS_CONFIG).map((permissionAction) => {
          let baseValidation = Yup.array().min(1, 'Required').required('Required');
          if (permissionAction.preconditions) {
            permissionAction.preconditions.forEach(
              ({ field, needsToBeAtLeast, whenVerbIsEqualOrGreaterThan, errorMessage }) => {
                baseValidation = baseValidation.test({
                  name: `precondition_${permissionAction.id}__${field.id}`,
                  message: errorMessage,
                  test: (value, testContext) => {
                    if (!value) {
                      return true;
                    }
                    const currentValueVerbConfig = PERMISSION_VERBS_CONFIG.getVerbByOptionValue(value);
                    if (currentValueVerbConfig.isEqualOrGreaterThan(whenVerbIsEqualOrGreaterThan)) {
                      const preconditionFieldValue = testContext.parent[field.id];
                      if (!preconditionFieldValue) {
                        return true;
                      }
                      const currentPreconditionFieldVerbConfig =
                        PERMISSION_VERBS_CONFIG.getVerbByOptionValue(preconditionFieldValue);
                      return currentPreconditionFieldVerbConfig.isEqualOrGreaterThan(needsToBeAtLeast);
                    }

                    return true;
                  },
                });
              }
            );
          }
          return [permissionAction.id, baseValidation];
        })
      )
    ),
  });
};

export const SECTIONS = Object.values(PERMISSION_ACTIONS_CONFIG)
  .filter((permission) => permission.isSectionHeader)
  .sort((a, b) => a.sectionHeaderIndex - b.sectionHeaderIndex);

export const SECTION_TO_ITEMS = Object.fromEntries(
  Object.keys(PERMISSION_ACTIONS_CONFIG).map((sectionId) => [
    sectionId,
    Object.values(PERMISSION_ACTIONS_CONFIG).filter((item) => item.section?.id === sectionId),
  ])
);

export const doesSectionHaveInnerItems = (section) => SECTION_TO_ITEMS[section.id].length > 0;
export const getSectionItems = (section) => SECTION_TO_ITEMS[section.id];

export const useSetAllTouched = () => {
  const { setTouched, initialValues } = useFormikContext();

  return React.useCallback(() => {
    setTouched({
      ...Object.fromEntries(Object.keys(initialValues).map((key) => [key, true])),
      permissions: Object.fromEntries(Object.keys(initialValues.permissions).map((key) => [key, true])),
    });
  }, [initialValues, setTouched]);
};

export const saveProfileWithConfirmProps = {
  title: '',
  primaryButtonName: 'Yes',
  secondaryButtonName: 'Cancel',
  maxWidth: 'lg',
  contentText: (
    <ConfirmDialogContent
      title="Edit Permission Profile?"
      subtitle="This action will update the permission profile for all users assigned to it"
    />
  ),
};

export const getMissingActionsValues = (profile) => {
  const existingPermissions = profile.profile_permissions.map(({ permission_action }) => permission_action);
  const missingPermissions = {};
  Object.values(PERMISSION_ACTIONS_CONFIG)
    .filter((action) => !existingPermissions.includes(action.id))
    .forEach(
      ({ id }) => (missingPermissions[id] = PERMISSION_VERBS_CONFIG.getVerbByOptionValue(profile.defaults)?.optionValue)
    );

  return missingPermissions;
};
