import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import AlertBanner from '~/components/core/AlertBanner';
import DialogFooterActions from '~/components/core/DialogFooterActions';

import CardDialog from '../../../../../components/CardDialog';
import {
  DEACTIVATE_UNIT_WARNING_MESSAGE,
  DIALOG_MODE,
  DIALOG_TITLE,
  EDIT_DEACTIVATED_UNIT_WARNING_MESSAGE,
  PARENT_UNIT_CHANGE_WARNING_MESSAGE,
  UNIT_FIELDS,
} from '../consts';
import { unitShape } from '../types';

import { UnitDetailsCard } from './DialogCards/UnitDetailsCard';
import { UnitLeaderCard } from './DialogCards/UnitLeaderCard';
import { UnitMembersCard } from './DialogCards/UnitMembersCard';
import { UnitRelatedConfigurationsCard } from './DialogCards/UnitRelatedConfigurationsCard';
import { UnitSummaryCard } from './DialogCards/UnitSummaryCard';

const alertBanner = (note) => (
  <div className="mb-16">
    <AlertBanner title="Please Note" withIcon alertType={AlertBanner.ALERT_TYPES.WARNING} note={note} />
  </div>
);

export const UnitDialogFormikWrapper = ({
  onClose,
  unit,
  units,
  memberToUnitDict,
  leaderToUnitsDict,
  usersDict,
  unitsDict,
  dialogMode,
}) => {
  const { isSubmitting, handleSubmit, values, initialValues } = useFormikContext();
  const isEditMode = dialogMode === DIALOG_MODE.EDIT;
  const isDeactivateMode = dialogMode === DIALOG_MODE.DEACTIVATE;
  const isEditingDeactivatedUnit = isEditMode && !unit.is_active;

  const title = isDeactivateMode
    ? `${DIALOG_TITLE[DIALOG_MODE.DEACTIVATE]} ${unit.name}`
    : isEditingDeactivatedUnit
    ? `${DIALOG_TITLE[DIALOG_MODE.EDIT]} ${unit.name} (Deactivated)`
    : `${DIALOG_TITLE[dialogMode]} ${unit?.name || ''}`.trim();

  const isParentUnitChanged =
    isEditMode && initialValues[UNIT_FIELDS.parentUnitId] !== values[UNIT_FIELDS.parentUnitId];
  const unitMemberIds = React.useMemo(() => (unit?.members ?? []).map((member) => member.member_user_id), [unit]);
  const relevantUsersById = React.useMemo(
    () =>
      Object.fromEntries(
        Object.entries(usersDict).filter(([userId, user]) => !user.is_removed || unitMemberIds.includes(userId))
      ),
    [unitMemberIds, usersDict]
  );

  return (
    <CardDialog
      isDialog={true}
      title={title}
      maxWidth="sm"
      onClose={onClose}
      fullWidth
      footerActions={
        <DialogFooterActions
          disabled={isSubmitting}
          onClickPrimary={handleSubmit}
          onClickSecondary={onClose}
          primaryLabel={isDeactivateMode && unit.is_active ? 'Yes, Deactivate' : 'Save'}
        />
      }
    >
      {isParentUnitChanged && alertBanner(PARENT_UNIT_CHANGE_WARNING_MESSAGE)}
      {isDeactivateMode && alertBanner(DEACTIVATE_UNIT_WARNING_MESSAGE)}
      {isEditingDeactivatedUnit && alertBanner(EDIT_DEACTIVATED_UNIT_WARNING_MESSAGE)}

      {isDeactivateMode || isEditingDeactivatedUnit ? (
        <>
          <UnitSummaryCard unit={unit} />
          <UnitRelatedConfigurationsCard />
        </>
      ) : (
        <>
          <UnitDetailsCard unitsDict={unitsDict} dialogMode={dialogMode} />
          <UnitLeaderCard
            unitsDict={unitsDict}
            usersDict={relevantUsersById}
            memberToUnitDict={memberToUnitDict}
            leaderToUnitsDict={leaderToUnitsDict}
            dialogMode={dialogMode}
          />
        </>
      )}

      <UnitMembersCard
        unit={unit}
        usersDict={relevantUsersById}
        memberToUnitDict={memberToUnitDict}
        units={units}
        unitsDict={unitsDict}
        dialogMode={dialogMode}
      />
    </CardDialog>
  );
};

UnitDialogFormikWrapper.propTypes = {
  onClose: PropTypes.func,
  unit: PropTypes.shape(unitShape),
  units: PropTypes.arrayOf(PropTypes.shape(unitShape)),
  usersDict: PropTypes.shape({}),
  unitsDict: PropTypes.shape({}),
  memberToUnitDict: PropTypes.shape({}),
  leaderToUnitsDict: PropTypes.shape({}),
  dialogMode: PropTypes.oneOf(Object.values(DIALOG_MODE)).isRequired,
};

UnitDialogFormikWrapper.defaultProps = {
  unit: null,
  units: [],
  usersDict: {},
  unitsDict: {},
  memberToUnitDict: {},
  leaderToUnitsDict: {},
};
