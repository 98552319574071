import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';

import AlertBanner from '~/components/core/AlertBanner';

import { Text } from '../../../../core';
import { PERMISSION_ACTIONS_CONFIG } from '../../../../core/Permissions/PermissionUtils';
import TextFieldFormik from '../../../../TextFieldFormik';

import { DefaultVerbSelectField } from './DefaultVerbSelect/VerbSelectField';
import { InnerSectionContainer } from './ProfilePermissionCollapsibleTile/InnerSectionContainer';
import { InnerSectionItemTile } from './ProfilePermissionCollapsibleTile/InnerSectionItemTile';
import { SectionHeaderCollapsibleTile } from './ProfilePermissionCollapsibleTile/SectionHeaderCollapsibleTile';
import { ProfileSummary } from './ProfileSummary/ProfileSummary';
import {
  doesSectionHaveInnerItems,
  FORM_FIELDS,
  getMissingActionsValues,
  getPermissionsFieldId,
  SECTIONS,
} from './formUtils';

import styles from './styles.module.scss';

const DEFAULT_VERB_MESSAGE =
  'Select a default permission for all other functions that are not explicitly configured. The explicit permissions (displayed below) override the Default Permission.';

export const ProfilePermissionsForm = ({ viewOnly, profile }) => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (profile) {
      const missingValues = getMissingActionsValues(profile);
      if (!isEmpty(missingValues)) {
        // we use the less performant setFieldValue tp avoid having to use the initialValues,
        // and because we expect to have few missing values
        Object.entries(missingValues).forEach(([key, value]) => {
          setFieldValue(getPermissionsFieldId(key), value);
        });
      }
    }
  }, [profile, setFieldValue]);

  const INNER_SECTION_TO_ITEMS = React.useMemo(() => {
    const getInnerSectionItems = (sectionId, innerSectionId) =>
      Object.values(PERMISSION_ACTIONS_CONFIG)
        .filter(
          (item) => !item.isSectionHeader && item.section?.id === sectionId && item.innerSection?.id === innerSectionId
        )
        .sort((a, b) => a.index - b.index);

    return SECTIONS.reduce(
      (acc, section) => ({
        ...acc,
        ...Object.fromEntries(
          Object.keys(section.innerSections).map((innerSectionKey) => {
            const innerSectionItems = getInnerSectionItems(section.id, innerSectionKey);
            return [`${section.id}_${innerSectionKey}`, innerSectionItems];
          })
        ),
      }),
      {}
    );
  }, []);

  const getInnerSectionItems = React.useCallback(
    (section, innerSection) => INNER_SECTION_TO_ITEMS[`${section.id}_${innerSection.id}`],
    [INNER_SECTION_TO_ITEMS]
  );

  const memoizedCollapsibleTiles = React.useMemo(
    () =>
      SECTIONS.map((section) => (
        <SectionHeaderCollapsibleTile
          key={section.id}
          sectionHeader={section}
          isCollapsible={doesSectionHaveInnerItems(section)}
          viewOnly={viewOnly}
        >
          {Object.values(section.innerSections).map((innerSection) => (
            <InnerSectionContainer title={innerSection.title} key={innerSection.id}>
              {getInnerSectionItems(section, innerSection).map((innerSectionItem) => (
                <InnerSectionItemTile
                  key={innerSectionItem.id}
                  innerSectionItem={innerSectionItem}
                  viewOnly={viewOnly}
                />
              ))}
            </InnerSectionContainer>
          ))}
        </SectionHeaderCollapsibleTile>
      )),
    [getInnerSectionItems, viewOnly]
  );

  return (
    <div className={styles.profileFormContainer}>
      <div className={styles.profileDetailsContainer}>
        {!viewOnly && (
          <>
            <Text variant={Text.VARIANTS.LG} weight={Text.WEIGHTS.SEMI_BOLD} colorVariant={Text.COLOR_VARIANTS.BLACK}>
              Permission Profile Details
            </Text>
            <div className={styles.profileDetailsFieldsContainer}>
              <TextFieldFormik id={FORM_FIELDS.display_name} label="Profile Name" fullWidth />
              <TextFieldFormik id={FORM_FIELDS.description} label="Description" fullWidth />
            </div>
            <div className={styles.defaultPermissionSectionContainer}>
              <Text variant={Text.VARIANTS.LG} weight={Text.WEIGHTS.SEMI_BOLD} colorVariant={Text.COLOR_VARIANTS.BLACK}>
                Default Permission
              </Text>
              <AlertBanner note={DEFAULT_VERB_MESSAGE} alertType={AlertBanner.ALERT_TYPES.INFO} withIcon />
              <div className={styles.defaultPermissionFieldContainer}>
                <DefaultVerbSelectField viewOnly={viewOnly} />
              </div>
            </div>
          </>
        )}
        {viewOnly && (
          <div className={styles.formSummaryContainer}>
            <ProfileSummary variant={ProfileSummary.VARIANTS.SLIM} />
          </div>
        )}
      </div>
      <div className={styles.sectionsContainer}>
        <Text variant={Text.VARIANTS.LG} weight={Text.WEIGHTS.SEMI_BOLD} colorVariant={Text.COLOR_VARIANTS.BLACK}>
          Permission Sets
        </Text>
        {memoizedCollapsibleTiles}
      </div>
      <FormError />
    </div>
  );
};

ProfilePermissionsForm.propTypes = {
  viewOnly: PropTypes.bool,
  profile: PropTypes.object,
};

const FormError = () => {
  const { errors } = useFormikContext();
  const isValid = React.useMemo(() => Object.keys(errors).length === 0, [errors]);

  const ref = React.useRef(null);
  const executeScroll = () => ref.current?.scrollIntoView();

  useEffect(() => {
    if (!isValid) {
      executeScroll();
    }
  }, [isValid]);

  return (
    <div ref={ref} className={styles.formErrorContainer}>
      {!isValid ? (
        <AlertBanner
          withIcon
          note="Please fix the errors above before going forward."
          alertType={AlertBanner.ALERT_TYPES.ERROR}
        />
      ) : null}
    </div>
  );
};
