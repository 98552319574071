import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, useFormikContext } from 'formik';

import Button from '~/components/core/Atomic/Buttons/Button';
import BackTextButton from '~/components/core/Buttons/BackTextButton';
import CancelButton from '~/components/core/Buttons/CancelButton';
import CheckBanner, { CHECK_BANNER_ALLOWED_COLORS } from '~/components/core/CheckBanner';
import cn from '~/Utils/cn';

import CardDialog from '../../../../../CardDialog';
import { LoadingSwitch } from '../../../../../core';
import { configurationProps } from '../propTypes';

import { useCauseLossCodes } from './hooks/useCauseLossCodes';
import { ClaimSearchConfigurationContextProvider, useConfiguration } from './hooks/useConfiguration';
import steps from './Steps/steps';
import CheckBannerStepDialogContent from './CheckBannerStepDialogContent';

import { useStyles } from '../../../../../../assets/styles';
import styles from './claimSearchConfigurationDialog.module.scss';

const MAIN_DIALOG_TITLE = 'Set ClaimSearch';

export const FIELD_IDS = {
  INITIAL_REPORTS: {
    METHOD: 'initial_reports.trigger_method',
    FOLLOWING_INFORMATION: 'initial_reports.following_information',
    NOTIFICATION_SELECTED: 'initial_reports.notification.selected',
    NOTIFICATION_DAYS: 'initial_reports.notification.days',
  },
  UPDATE_REPORTS: {
    SEARCH_TRIGGERS: 'update_reports.search_triggers',
    SCHEDULE_PERIOD: 'update_reports.schedule_period',
    SCHEDULE_TIME: 'update_reports.schedule_time',
    SCHEDULE_TIME_FRAME: 'update_reports.schedule_time_frame',
  },
  CAUSE_LOSS_CODES: {
    BASE_ID: 'cause_of_loss_codes',
  },
};

const ClaimSearchConfigurationDialogInner = ({ onCancel }) => {
  const classes = useStyles();
  const { values, isSubmitting, handleSubmit, setValues } = useFormikContext();
  const configuration = useConfiguration();
  const [currentStep, setCurrentStep] = useState(null);

  const handleInnerStepClose = () => {
    setCurrentStep(null);
  };

  const handleInnerStepSubmit = (values) => {
    setValues(values);
    const afterStepToDisplayId = currentStep.displayAfterStep && currentStep.displayAfterStep({ configuration });

    afterStepToDisplayId ? setCurrentStep(steps.find(({ id }) => id === afterStepToDisplayId)) : handleInnerStepClose();
  };

  const isStepChecked = (step) => step.isChecked(values);

  const getDialogTitle = () => {
    if (currentStep) {
      return <BackTextButton onClick={handleInnerStepClose} />;
    }
    return MAIN_DIALOG_TITLE;
  };

  return (
    <CardDialog
      isDialog
      title={getDialogTitle()}
      containerClassName={styles.mainDialogContainer}
      onClose={currentStep ? handleInnerStepClose : onCancel}
      maxWidth="sm"
      fullWidth
    >
      {!currentStep && (
        <div className={styles.checkBannersContainer}>
          {steps
            .filter(({ hideCheckBanner }) => !hideCheckBanner)
            .map((step) => (
              <CheckBanner
                key={`check-banner-${step.id}`}
                onClick={() => setCurrentStep(step)}
                checked={isStepChecked(step)}
                disableWhenChecked={false}
                note={step.title}
                disabled={isSubmitting}
                cardClassName={cn(styles.checkBanner, { [styles.checked]: isStepChecked(step) })}
                checkboxClassNames={CHECK_BANNER_ALLOWED_COLORS.BUTTON_LINK}
              />
            ))}
        </div>
      )}
      {currentStep && (
        <CheckBannerStepDialogContent
          step={currentStep}
          initialValues={values}
          onSubmit={handleInnerStepSubmit}
          onCancel={handleInnerStepClose}
          configuration={configuration}
        />
      )}
      {!currentStep && (
        <div className={classes.buttonsContainer}>
          <CancelButton disabled={isSubmitting} onClick={onCancel} />
          <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
            Save
          </Button>
        </div>
      )}
    </CardDialog>
  );
};

const ConfigurationDialog = ({ configurationValues, onCancel, onSubmit }) => {
  return (
    <ClaimSearchConfigurationContextProvider configuration={configurationValues}>
      <ConfigurationDialogFormik onCancel={onCancel} onSubmit={onSubmit} />
    </ClaimSearchConfigurationContextProvider>
  );
};

const ConfigurationDialogFormik = ({ onCancel, onSubmit }) => {
  const { supportedLobs, coverages, subTypesDict, causeOfLossData, isLoading, isError } = useCauseLossCodes();
  const configurationValues = useConfiguration();

  const initialValues = configurationToInitialValues(configurationValues, {
    supportedLobs,
    coverages,
    subTypesDict,
    causeOfLossData,
  });
  return (
    <LoadingSwitch isLoading={isLoading} isError={isError}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, formikProps) => {
          try {
            await onSubmit(values);
          } catch (err) {
            formikProps.setSubmitting(false);
          }
        }}
      >
        <ClaimSearchConfigurationDialogInner onCancel={onCancel} />
      </Formik>
    </LoadingSwitch>
  );
};

ConfigurationDialogFormik.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const configurationToInitialValues = (configurationValues, isoConfig) => {
  const initialValues = {};

  initialValues[FIELD_IDS.INITIAL_REPORTS.METHOD] = configurationValues?.trigger_method;
  initialValues[FIELD_IDS.INITIAL_REPORTS.FOLLOWING_INFORMATION] = configurationValues?.additional_initial_trigger;

  initialValues[FIELD_IDS.UPDATE_REPORTS.SCHEDULE_PERIOD] = configurationValues?.schedule_period || null;
  initialValues[FIELD_IDS.UPDATE_REPORTS.SCHEDULE_TIME_FRAME] = configurationValues?.schedule_time_frame || null;
  initialValues[FIELD_IDS.UPDATE_REPORTS.SCHEDULE_TIME] = configurationValues?.schedule_time || null;
  initialValues[FIELD_IDS.UPDATE_REPORTS.SEARCH_TRIGGERS] = configurationValues?.search_triggers;

  initialValues[FIELD_IDS.INITIAL_REPORTS.NOTIFICATION_SELECTED] = false;
  initialValues[FIELD_IDS.INITIAL_REPORTS.NOTIFICATION_DAYS] = null;

  isoConfig.supportedLobs.forEach((lob) => {
    isoConfig.coverages.forEach(({ coverage_key }) => {
      isoConfig.subTypesDict[lob][coverage_key].forEach(({ id }) => {
        const key = `${FIELD_IDS.CAUSE_LOSS_CODES.BASE_ID}.${lob}.${coverage_key}.${id}.iso`;
        initialValues[key] = isoConfig.causeOfLossData[lob]?.[coverage_key]?.[id]?.iso || '';
      });
    });
  });
  return convertKeysWithDots(initialValues);
};

const convertKeysWithDots = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    const keys = key.split('.');
    const lastKey = keys.pop();
    let current = acc;

    keys.forEach((key) => {
      if (!current[key]) {
        current[key] = {};
      }
      current = current[key];
    });

    current[lastKey] = obj[key];
    return acc;
  }, {});
};

ConfigurationDialog.propTypes = {
  configurationValues: configurationProps.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

ClaimSearchConfigurationDialogInner.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default ConfigurationDialog;
