import React, { useEffect } from 'react';
import { arrayOf, bool, func, string } from 'prop-types';
import { Formik, getIn, useFormikContext } from 'formik';
import { get, isEmpty, noop, pickBy } from 'lodash';

import { PERFORMANCE_QA } from '../../../../../Types';
import CardDialog from '../../../../CardDialog';
import Stepper from '../../../../core/Stepper';

import AutomaticSection from './Sections/Automatic';
import CustomSection from './Sections/CustomQuestions';
import PredefinedSection from './Sections/Predefined';
import PreviewSection from './Sections/Preview';
import Welcome from './Sections/Welcome';
import { AUTOMATIC_TYPE, CUSTOM_QUESTIONS_KEY, FORM_NAME_KEY, PREDEFINED_TYPE, QUESTIONS_KEY } from './constants';
import Footer from './Footer';
import { getSchema } from './validationSchema';

import styles from './form.module.scss';

const PerformanceQADialog = ({ open, onClose, onSubmit }) => {
  const { values, errors, setFieldTouched, setFieldValue, validateForm, resetForm } = useFormikContext();
  const formName = getIn(values, FORM_NAME_KEY);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    validateForm();
  }, [validateForm]);

  const touchQuestions = () => {
    Object.entries(get(errors, QUESTIONS_KEY, {})).forEach(([questionId, value]) => {
      Object.keys(value).forEach((customQuestionField) => {
        setFieldTouched(`${QUESTIONS_KEY}.${questionId}.${customQuestionField}`, true);
      });
    });

    // Update footer errors
    setFieldTouched('total', true);
  };

  const touchCustomQuestions = () => {
    Object.entries(get(errors, CUSTOM_QUESTIONS_KEY, {})).forEach(([questionId, value]) => {
      Object.keys(value).forEach((customQuestionField) => {
        setFieldTouched(`${CUSTOM_QUESTIONS_KEY}.${questionId}.${customQuestionField}`, true);
      });
    });
  };

  const createStepLabel = (name, selectedLength) => `${name}${selectedLength ? ` (${selectedLength})` : ''}`;

  const getTotalSelectedQuestions = (type) =>
    pickBy(values[QUESTIONS_KEY], ({ selected }, key) => selected && PERFORMANCE_QA.QUESTIONS[key].type === type);

  const automaticQuestionsSelected = Object.keys(getTotalSelectedQuestions(AUTOMATIC_TYPE)).length;
  const predefinedQuestionsSelected = Object.keys(getTotalSelectedQuestions(PREDEFINED_TYPE)).length;
  const customQuestionsSelected = Object.keys(values[CUSTOM_QUESTIONS_KEY]).length;

  return (
    <CardDialog
      className={styles.dialog}
      title={`Create new QA Form${formName ? ` - ${formName}` : ''}`}
      onClose={onClose}
      open={open}
      isDialog
      containerClassName={styles.container}
      fullScreen
    >
      <Stepper
        footerComponent={<Footer />}
        contentClassName={styles.content}
        headerClassName={styles.header}
        stepsContainerClassName={styles.stepsContainer}
        onComplete={async () => {
          await onSubmit({ values });
          onClose();
          resetForm();
        }}
      >
        <Stepper.Step
          label="Welcome"
          onNext={() => {
            setFieldTouched(FORM_NAME_KEY, true);

            return isEmpty(errors[FORM_NAME_KEY]);
          }}
        >
          <Welcome />
        </Stepper.Step>
        <Stepper.Step
          label={createStepLabel('Automatic', automaticQuestionsSelected)}
          onNext={() => {
            touchQuestions();
            return isEmpty(errors);
          }}
          onBack={() => {
            touchQuestions();
            return isEmpty(errors);
          }}
        >
          <AutomaticSection />
        </Stepper.Step>
        <Stepper.Step
          label={createStepLabel('Predefined Questions', predefinedQuestionsSelected)}
          onNext={() => {
            touchQuestions();
            return isEmpty(errors);
          }}
          onBack={() => {
            touchQuestions();
            return isEmpty(errors);
          }}
        >
          <PredefinedSection />
        </Stepper.Step>
        <Stepper.Step
          label={createStepLabel('Custom Questions', customQuestionsSelected)}
          onNext={async () => {
            touchCustomQuestions();

            // Update preview
            await setFieldValue('preview_selected', true);
            return isEmpty(await validateForm());
          }}
          onBack={() => {
            touchCustomQuestions();
            return isEmpty(errors);
          }}
        >
          <CustomSection />
        </Stepper.Step>
        <Stepper.Step label="Preview">
          <PreviewSection />
        </Stepper.Step>
      </Stepper>
    </CardDialog>
  );
};

PerformanceQADialog.propTypes = {
  open: bool,
  onClose: func,
  onSubmit: func,
};

const PerformanceQAForm = ({ formNames, open, onClose, onSubmit }) => (
  <Formik
    initialValues={{
      [FORM_NAME_KEY]: '',
      [QUESTIONS_KEY]: {},
      [CUSTOM_QUESTIONS_KEY]: {},
      preview_selected: false,
    }}
    validationSchema={getSchema({ formNames })}
    // Submitting is done from the Stepper
    onSubmit={noop}
  >
    <PerformanceQADialog open={open} onClose={onClose} onSubmit={onSubmit} />
  </Formik>
);

PerformanceQAForm.propTypes = {
  formNames: arrayOf(string),
  open: bool,
  onSubmit: func,
  onClose: func,
};

export default PerformanceQAForm;
