import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import AlertBanner from '~/components/core/AlertBanner';
import DialogFooterActions from '~/components/core/DialogFooterActions';

import CardDialog from '../../../../../components/CardDialog';
import {
  UnitRelatedConfigurationsProvider,
  useUnitRelatedConfigurations,
} from '../../hooks/useUnitRelatedConfigurations';
import { DELETE_UNIT_WARNING_MESSAGE, UNIT_FIELDS } from '../consts';
import { unitShape } from '../types';

import { UnitRelatedConfigurationsCard } from './DialogCards/UnitRelatedConfigurationsCard';
import { UnitSummaryCard } from './DialogCards/UnitSummaryCard';

export const DeleteUnitDialogInner = ({ unit, onClose, onSubmit }) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { isUnitConfigsLoading, isUnitConfigsError, hasAnyConfigs } = useUnitRelatedConfigurations();

  const handleSubmit = React.useCallback(async () => {
    setIsSubmitting(true);
    await onSubmit({ unitId: unit.id });
    setIsSubmitting(false);
  }, [onSubmit, unit.id]);

  return (
    <CardDialog
      isDialog={true}
      title={`Delete Unit ${unit[UNIT_FIELDS.name]}`}
      maxWidth="sm"
      onClose={onClose}
      fullWidth
      containerClassName="flex flex-column gap-20"
      footerActions={
        <DialogFooterActions
          disabled={isSubmitting}
          primaryDisabled={isSubmitting || isUnitConfigsLoading || isUnitConfigsError || hasAnyConfigs}
          primaryTooltip={hasAnyConfigs ? 'Please remove related configurations and workflows first' : undefined}
          onClickPrimary={handleSubmit}
          onClickSecondary={onClose}
          primaryLabel="Yes, Delete"
        />
      }
    >
      <AlertBanner
        title="Please Note"
        withIcon
        alertType={AlertBanner.ALERT_TYPES.WARNING}
        note={DELETE_UNIT_WARNING_MESSAGE}
      />

      <UnitSummaryCard unit={unit} withMembers />
      <UnitRelatedConfigurationsCard />
    </CardDialog>
  );
};

export const DeleteUnitDialog = ({ unit, ...props }) => (
  <UnitRelatedConfigurationsProvider unitId={unit.id}>
    <DeleteUnitDialogInner unit={unit} {...props} />
  </UnitRelatedConfigurationsProvider>
);

DeleteUnitDialog.propTypes = DeleteUnitDialogInner.propTypes = {
  unit: PropTypes.shape(unitShape).isRequired,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

DeleteUnitDialog.defaultProps = DeleteUnitDialogInner.defaultProps = {
  onClose: noop,
  onSubmit: noop,
};
