import React from 'react';

import mixpanel from '~/components/CmsMain/mixpanel';
import ConfigurationPage, {
  InternalConfiguration,
} from '~/components/SystemConfiguration/ConfigurationPage/ConfigurationPage';
import { FNOL_MIXPANEL_EVENTS } from '~/pocs/mixpanel';

import type { IncidentConfigurationSummary } from '../ConfigurationPage/types';

import copies from './PolicyConfigurationScreen/copies.json';
import PolicyConfigurationScreen from './PolicyConfigurationScreen/PolicyConfigurationScreen';

const trackMixpanelEvent = (IncidentConfiguration: IncidentConfigurationSummary) => {
  mixpanel.track(FNOL_MIXPANEL_EVENTS.EDIT_POLICY_CONFIGURATION_CLICKED, {
    incident_configuration_id: IncidentConfiguration?.id,
    sub_organization_id: IncidentConfiguration?.sub_organization_id,
  });
};

const PolicyConfiguration: React.FC = () => {
  return (
    <ConfigurationPage
      configurationTitle="Policy Configuration"
      configurationSubtitle={copies.configuration_subtitle}
      externalAdminRoute="/policy_configuration"
      ConfigurationScreen={PolicyConfigurationScreen}
      searchPlaceholder="Search Policy Configuration"
      counterSuffix="Policies"
      displayName="Name"
      trackMixpanelEvent={trackMixpanelEvent}
    />
  );
};

const InternalPolicyConfiguration: React.FC = () => (
  <div className="absolute inset-0 overflow-scroll">
    <InternalConfiguration
      configurationTitle="Policy Configuration"
      configurationSubtitle={copies.configuration_subtitle}
      externalAdminRoute="/policy_configuration"
      ConfigurationScreen={PolicyConfigurationScreen}
      searchPlaceholder="Search Policy Configuration"
      counterSuffix="Policies"
      displayName="Name"
      trackMixpanelEvent={trackMixpanelEvent}
    />
  </div>
);

export { InternalPolicyConfiguration };
export default PolicyConfiguration;
