import React from 'react';
import PropTypes from 'prop-types';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';

import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';
import InnerCard from '~/components/core/Cards/InnerCard';
import SwitchFormik from '~/components/core/Formik/SwitchFormik';
import cn from '~/Utils/cn';

import useOrganization from '../../../../..//OrganizationContext';
import CardDialog from '../../../../../CardDialog';
import { FsButton, Text } from '../../../../../core';
import TextFieldFormik from '../../../../../TextFieldFormik';
import SubOrganizationMultiselectFormik from '../../../../../TPA/SubOrganizations/SubOrganizationMultiselectFormik.tsx';

import { useStyles } from '../../../../../../assets/styles';
import styles from '../index.module.scss';

const FIELD_IDS = {
  IS_ENABLED: 'is_enabled',
  NAME: 'name',
  CARRIER_ID: 'carrier_id',
  SUB_ORG_IDS: 'sub_organization_ids',
  IS_ALL_SUB_ORGS: 'is_all_sub_orgs',
  IMPORT_USERNAME: 'import_username',
  IMPORT_PASSWORD: 'import_password',
};

const XactAnalysisConfigurationDialogInner = ({ onClose, isUpdate }) => {
  const classes = useStyles();
  const { isSubmitting, handleSubmit, values } = useFormikContext();
  const { subOrganizationEnabled } = useOrganization();
  const isTextFieldDisabled = isSubmitting || !values[FIELD_IDS.IS_ENABLED];

  const Actions = (
    <div className={styles.actionsContainer}>
      <CancelButton disabled={isSubmitting} onClick={onClose} />
      <FsButton variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
        {isUpdate ? 'Update' : 'Save'}
      </FsButton>
    </div>
  );

  return (
    <CardDialog
      containerClassName={styles.xactAnalysisDialog}
      title={`${isUpdate ? 'Edit' : 'Add'} Carrier Profile`}
      isDialog
      preventClose={isSubmitting}
      maxWidth="sm"
      fullWidth
      onClose={onClose}
      footerActions={Actions}
    >
      <InnerCard className={styles.withoutBackground}>
        <Grid container>
          <Text variant={Text.VARIANTS.LG} weight={Text.WEIGHTS.SEMI_BOLD} className={styles.subtitle}>
            XactAnalysis Profile
          </Text>
          <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR} className={styles.subtitle}>
            To create an XactAnalysis profile, enter a Carrier ID and Authentication Secret
          </Text>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextFieldFormik
                id={FIELD_IDS.NAME}
                label="Carrier Profile Name"
                className={classes.textField}
                fullWidth
                disabled={isTextFieldDisabled}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextFieldFormik
                id={FIELD_IDS.IMPORT_USERNAME}
                label="Import Username"
                className={cn(classes.textField, styles.textField)}
                disabled={isTextFieldDisabled}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldFormik
                id={FIELD_IDS.IMPORT_PASSWORD}
                label="Import Password"
                className={cn(classes.textField, styles.textField)}
                disabled={isTextFieldDisabled}
                type="password"
                fullWidth
              />
            </Grid>
            {subOrganizationEnabled && (
              <Grid item xs={12}>
                <SubOrganizationMultiselectFormik
                  subOrganizationsFieldId={FIELD_IDS.SUB_ORG_IDS}
                  disabled={isTextFieldDisabled}
                  allSelectedFieldId={FIELD_IDS.IS_ALL_SUB_ORGS}
                  selectAllLabel="All"
                  showAllInRenderedSelectedOptions
                  shouldDisplayAllOption
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </InnerCard>
      <InnerCard className={cn(styles.sectionContainer, styles.fixAccessibilityPadding)}>
        <Grid container>
          <Grid item xs={12}>
            <Text variant={Text.VARIANTS.LG} weight={Text.WEIGHTS.SEMI_BOLD} className={styles.subtitle}>
              Activate XactAnalysis
            </Text>
            <Grid item xs={12}>
              <SwitchFormik
                id={FIELD_IDS.IS_ENABLED}
                className={classes.formsSwitch}
                checked={!!values[FIELD_IDS.IS_ENABLED]}
                label="Enable XactAnalysis Setup"
              />
            </Grid>
          </Grid>
        </Grid>
      </InnerCard>
    </CardDialog>
  );
};

XactAnalysisConfigurationDialogInner.propTypes = {
  isUpdate: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

/**
 * @param {{ data: XactAnalysisConfig, configuredSubOrgIds: Set<int>, handleSubmit: (config: XactAnalysisConfig) => undefined, onClose: () => undefined }} props
 */
const XactAnalysisConfigurationDialog = ({ data, configuredSubOrgIds, handleSubmit, onClose }) => {
  const { subOrganizationEnabled, subOrganizations } = useOrganization();

  const initialValues = {
    [FIELD_IDS.IS_ENABLED]: data.is_enabled,
    [FIELD_IDS.NAME]: data.name,
    [FIELD_IDS.IMPORT_USERNAME]: data.import_username || '',
    [FIELD_IDS.IMPORT_PASSWORD]: data.import_password || '',
    [FIELD_IDS.SUB_ORG_IDS]: data?.sub_organization_ids || [],
    [FIELD_IDS.IS_ALL_SUB_ORGS]: data?.sub_organization_ids?.length === subOrganizations?.length,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        [FIELD_IDS.NAME]: Yup.string().required('Required'),
        [FIELD_IDS.IS_ENABLED]: Yup.boolean(),
        [FIELD_IDS.IMPORT_USERNAME]: Yup.string().required('Required'),
        [FIELD_IDS.IMPORT_PASSWORD]: Yup.string().required('Required'),
        [FIELD_IDS.SUB_ORG_IDS]: subOrganizationEnabled
          ? Yup.array().when(FIELD_IDS.IS_ALL_SUB_ORGS, {
              is: true,
              then: Yup.array().test({
                name: 'suborgValidation',
                message: 'XactAnalysis configuration already exists for the selected sub-organization(s)',
                test: () => {
                  return !subOrganizations.some((suborg) => configuredSubOrgIds.has(suborg.id));
                },
              }),
              otherwise: Yup.array()
                .min(1, 'Select at least one sub organization')
                .test({
                  name: 'suborgValidation',
                  message: 'XactAnalysis configuration already exists for the selected sub-organization(s)',
                  test: (selectedSuborgIds) => {
                    return !selectedSuborgIds.some((suborgId) => configuredSubOrgIds.has(suborgId));
                  },
                }),
            })
          : undefined,
      })}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await handleSubmit(values);
        } catch (error) {
          setSubmitting(false);
        }
      }}
    >
      <XactAnalysisConfigurationDialogInner onClose={onClose} data={data} isUpdate={!!data?.id} />
    </Formik>
  );
};

XactAnalysisConfigurationDialog.propTypes = {
  data: PropTypes.object.isRequired,
  configuredSubOrgIds: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default XactAnalysisConfigurationDialog;
