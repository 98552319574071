import React from 'react';

import Grid from '~/components/core/Atomic/Grid/Grid';

import OrganizationDefaultReserves from './OrganizationDefaultReserves';
import OrganizationTriage from './OrganizationTriage';
import { useSysconfig } from './SystemConfigurationScreen';

import { useStyles } from '../../assets/styles';

function TriageAndReservesTab() {
  const { organization } = useSysconfig();
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <div className={classes.cardDivRow}>
          <OrganizationTriage organization={organization} />
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.cardDivRow}>
          <OrganizationDefaultReserves />
        </div>
      </Grid>
    </Grid>
  );
}

export default TriageAndReservesTab;
