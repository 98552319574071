import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Grid from '~/components/core/Atomic/Grid/Grid';
import DialogFooterActions from '~/components/core/DialogFooterActions';

import { reportAxiosError } from '../../../Utils';
import CardDialog from '../../CardDialog';
import { PencilIcon } from '../../icons';
import { NumericTextFieldFormik } from '../../TextFieldFormik';
import { FsIconButton, Text } from '..';

import { useStyles } from '../../../assets/styles';
import styles from './index.module.scss';

const SetNumericValueDialog = ({ onClose, onSubmit, id, value, label, title }) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        [id]: value,
      }}
      validationSchema={Yup.object().shape({
        [id]: Yup.number().required('Required'),
      })}
      onSubmit={async (values) => {
        try {
          await onSubmit(values);
          onClose();
        } catch (error) {
          await reportAxiosError(error);
        }
      }}
    >
      {({ isSubmitting, handleSubmit }) => (
        <CardDialog
          isDialog
          dontTrack
          title={title}
          fullWidth
          maxWidth="sm"
          onClose={onClose}
          footerActions={
            <DialogFooterActions onClickPrimary={handleSubmit} onClickSecondary={onClose} disabled={isSubmitting} />
          }
        >
          <Grid container>
            <Grid item xs={12}>
              <NumericTextFieldFormik
                id={id}
                label={label}
                className={classes.textField}
                fullWidth
                disabled={isSubmitting}
              />
            </Grid>
          </Grid>
        </CardDialog>
      )}
    </Formik>
  );
};

SetNumericValueDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.number,
};

const SetNumericValueInnerCard = ({ onSubmit, id, value, label, title }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className={styles.containerWithBorder}>
        <div className={styles.containerTitle}>
          <Text>{title}</Text>
          <FsIconButton
            onClick={() => setIsOpen(true)}
            disabled={false}
            icon={PencilIcon}
            ignorePermissions
            className={styles.btn}
          />
        </div>
        <div className={styles.value}>
          <Text variant="small" weight="regular">
            {value || '-'}
          </Text>
        </div>
      </div>

      {isOpen && (
        <SetNumericValueDialog
          id={id}
          label={label}
          title={title}
          value={value}
          onClose={() => setIsOpen(false)}
          onSubmit={async (values) => {
            await onSubmit(values);
          }}
        />
      )}
    </>
  );
};

SetNumericValueInnerCard.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.number,
};

export default SetNumericValueInnerCard;
