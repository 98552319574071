import React from 'react';
import PropTypes from 'prop-types';

import Switch from '../Atomic/Switch';

import ConfigPanel from './ConfigPanel';

const SwitchPanel = ({ checked, label, onChange, showOnly, className }) => {
  return (
    <ConfigPanel
      label={label}
      className={className}
      mainActionChild={<Switch checked={checked} onChange={onChange} disabled={showOnly} />}
    />
  );
};

SwitchPanel.propTypes = {
  label: PropTypes.node,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  showOnly: PropTypes.bool,
  className: PropTypes.string,
};

export default SwitchPanel;
