import React from 'react';
import * as Yup from 'yup';

import Grid from '~/components/core/Atomic/Grid/Grid';

import TextFieldFormik, { MultiSelectTextFieldFormik } from '../TextFieldFormik';

import { ONE_INC_CLAIMSPAY_TYPES } from './consts';

import { useStyles } from '../../assets/styles';

const getMethodSpecificPaymentConfig = () => {
  const methodSpecificInitialValues = {
    one_inc_carrier_id: '',
    one_inc_claimspay_types: [],
  };

  const methodSpecificValidationSchema = Yup.object().shape({
    one_inc_carrier_id: Yup.string().required('Required'),
    one_inc_claimspay_types: Yup.array().required('Required').min(1, 'Required'),
  });

  const MethodSpecificPaymentConfigComponent = () => {
    const classes = useStyles();

    return (
      <>
        <Grid item xs={12}>
          <TextFieldFormik
            id="method_configuration.method_specific.one_inc_carrier_id"
            label="Carrier ID"
            className={classes.textField}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <MultiSelectTextFieldFormik
            id="method_configuration.method_specific.one_inc_claimspay_types"
            label="ClaimsPay Type"
            options={Object.values(ONE_INC_CLAIMSPAY_TYPES)}
            renderValue={() => ''}
            renderOption={(k) => k}
            className={`${classes.formTextFieldNoErrorSpacing} ${classes.textFieldRow}`}
            withOptionChips
            fullWidth
          />
        </Grid>
      </>
    );
  };

  return {
    methodSpecificInitialValues,
    methodSpecificValidationSchema,
    MethodSpecificPaymentConfigComponent,
  };
};

export default getMethodSpecificPaymentConfig;
