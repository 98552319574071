import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Breadcrumbs } from '@material-ui/core';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import Typography from '~/components/core/Atomic/Typography';

const OperationsBreadcrumbs = ({ currentTab, addPadding = false }) => {
  const match = useRouteMatch();

  const isInsideOperationsTab = match.url.split('/').includes('operations');

  return isInsideOperationsTab ? (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextIcon fontSize="small" />}
      style={addPadding ? { padding: '10px 0px 0px 30px' } : null}
    >
      <Link
        underline="hover"
        color="inherit"
        to="/sysconfig/operations"
        style={{ textDecoration: 'none', color: '#202020', fontSize: '14px' }}
      >
        Operations
      </Link>
      <Typography color="textPrimary" style={{ fontWeight: 600 }}>
        {currentTab}
      </Typography>
    </Breadcrumbs>
  ) : null;
};

OperationsBreadcrumbs.propTypes = {
  currentTab: PropTypes.string.isRequired,
  addPadding: PropTypes.bool,
};

export default OperationsBreadcrumbs;
