import React from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';

import { Text } from '../../../../../core';
import { FORM_FIELDS, getPermissionsFieldId } from '../formUtils';

import styles from './styles.module.scss';

const NewPermissionActionTag = ({ permissionActionConfig }) => {
  const { initialValues } = useFormikContext();

  const permissionFormikId = getPermissionsFieldId(permissionActionConfig.id);
  const isNew = !getIn(initialValues, permissionFormikId) && getIn(initialValues, FORM_FIELDS.id);

  if (!isNew) {
    return null;
  }

  return (
    <div className={styles.newActionTag}>
      <Text variant={Text.VARIANTS.XS} weight={Text.WEIGHTS.REGULAR}>
        New
      </Text>
    </div>
  );
};

NewPermissionActionTag.propTypes = {
  permissionActionConfig: PropTypes.object,
};

export default NewPermissionActionTag;
