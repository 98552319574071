import React from 'react';
import PropTypes from 'prop-types';
import { Formik, useFormikContext } from 'formik';

import CardDialog from '../../../../../CardDialog';
import DialogFooterActions from '../../../../../core/DialogFooterActions/index';
import { incidentTypesAndSubTypesDialogSchema } from '../../../fnolConfigurationValidationSchema';

import CategoriesSection from './CategoriesSection';
import IncidentTypesSubTypesConfigurationArray from './IncidentTypesSubTypesConfigurationArray';

const IncidentTypesAndSubTypesDialogInner = ({ onClose }) => {
  const { handleSubmit } = useFormikContext();

  return (
    <CardDialog
      isDialog
      onClose={onClose}
      open
      title="Incident Types And SubTypes"
      maxWidth="md"
      fullWidth
      footerActions={<DialogFooterActions onClickSecondary={onClose} onClickPrimary={handleSubmit} />}
    >
      <CategoriesSection />
      <IncidentTypesSubTypesConfigurationArray />
    </CardDialog>
  );
};

IncidentTypesAndSubTypesDialogInner.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const IncidentTypesAndSubTypesDialog = ({ onClose }) => {
  const { values, setValues } = useFormikContext();
  return (
    <Formik
      initialValues={values}
      validationSchema={incidentTypesAndSubTypesDialogSchema}
      onSubmit={async (values, formikProps) => {
        const { setSubmitting } = formikProps;
        try {
          setSubmitting(true);
          await setValues(values);
          onClose();
        } catch (error) {
          setSubmitting(false);
        }
      }}
    >
      <IncidentTypesAndSubTypesDialogInner onClose={onClose} />
    </Formik>
  );
};

IncidentTypesAndSubTypesDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default IncidentTypesAndSubTypesDialog;
