import React from 'react';
import PropTypes from 'prop-types';
import { filter, isEmpty } from 'lodash';

import Typography from '~/components/core/Atomic/Typography';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';

import { getLobDescription, getLobIcon } from '../../../../../Utils/lobUtils';
import { LoadingSwitch } from '../../../../core';
import useLobOptions from '../../../../TPA/LOB/hooks/useLobOptions';
import LocationTable from '../LocationTable';
import propTypes from '../propTypes';

import styles from '../supportedLocations.module.scss';

const PerLineOfBusiness = ({
  subOrganizationId,
  countriesConfiguration = {
    countries_configuration_for_locations: [],
  },
  onUpdate,
}) => {
  const { lobConfigurationsDict } = useLobConfiguration();
  const { lobOptions, isLoading } = useLobOptions({
    subOrganizationIds: !isEmpty(subOrganizationId) ? [subOrganizationId] : null,
  });

  return (
    <LoadingSwitch isLoading={isLoading}>
      {lobOptions.map((supportedClaimType) => {
        const supportedLocations = filter(
          countriesConfiguration.countries_configuration_for_locations,
          ({ lob }) => supportedClaimType === lob
        );

        return (
          <div key={supportedClaimType} className={styles.section}>
            <div className={styles.countriesSectionTitle}>
              <span className={styles.sectionIconContainer}>
                {getLobIcon({ lob: supportedClaimType, lobConfigurationsDict })}
              </span>
              <Typography variant="subtitle1">
                {getLobDescription(supportedClaimType, lobConfigurationsDict)}
              </Typography>
            </div>
            <LocationTable supportedLocations={supportedLocations} onUpdate={onUpdate} />
          </div>
        );
      })}
    </LoadingSwitch>
  );
};

PerLineOfBusiness.propTypes = {
  subOrganizationId: PropTypes.number,
  countriesConfiguration: PropTypes.shape(propTypes.countriesConfiguration),
  onUpdate: PropTypes.func,
  supportedClaimTypes: PropTypes.arrayOf(PropTypes.string),
};

export default PerLineOfBusiness;
