import React from 'react';
import PropTypes from 'prop-types';

import { Text, TooltipIcon } from '../../../core';
import { InfoIcon } from '../../../icons';

import colors from '../../../../assets/colors.module.scss';
import styles from './AssignmentsTab.module.scss';

export const ConfigPanelLabel = ({ title, tooltip, info, action }) => {
  return (
    <div className={styles.configPanelLabel}>
      <div className={styles.configPanelLabelText}>
        <div className={styles.configPanelLabelTitle}>
          <Text variant={Text.VARIANTS.XS} colorVariant={Text.COLOR_VARIANTS.SECONDARY} weight={Text.WEIGHTS.REGULAR}>
            {title}
          </Text>
          <TooltipIcon title={tooltip}>
            <InfoIcon size={20} iconColor={colors.textDisabled} />
          </TooltipIcon>
        </div>
        <Text variant={Text.VARIANTS.XS} weight={Text.WEIGHTS.SEMI_BOLD}>
          {info ?? <br />}
        </Text>
      </div>
      {action ? <div className={styles.configPanelLabelAction}>{action}</div> : null}
    </div>
  );
};

ConfigPanelLabel.propTypes = {
  title: PropTypes.string,
  tooltip: PropTypes.string,
  info: PropTypes.string,
  action: PropTypes.node,
};
