import React from 'react';

import Grid from '~/components/core/Atomic/Grid/Grid';
import cn from '~/Utils/cn';

import { Heading, Text } from '../../../core';
import InnerTabs from '../../../core/Layout/InnerTabs/InnerTabs';
import { isPermissionsEnabled } from '../../../core/Permissions/PermissionUtils';
import OperationsBreadcrumbs from '../../OperationsBreadcrumbs';
import { useSysconfig } from '../../SystemConfigurationScreen';

import { ProfilesTable } from './ProfilesTable/ProfilesTable';

import styles from './styles.module.scss';

export const PermissionsProfilesPage = () => {
  const { organization: SysconfigOrganization } = useSysconfig();
  const permissionsEnabled = isPermissionsEnabled(SysconfigOrganization);

  const tabs = [
    {
      label: 'Permission Profiles',
      url: '/active',
      component: ProfilesTable,
      props: {
        active: true,
        title: 'Permission Profiles',
      },
    },
  ];

  return (
    <Grid container direction="column" className={styles.pageContainer}>
      <Grid item xs={12}>
        <div className={styles.pageHeaderContainer}>
          <OperationsBreadcrumbs currentTab={PermissionsProfilesPage.title} />
          <Heading variant={Heading.TYPES.H2_CAPS} className={styles.pageTitle}>
            {PermissionsProfilesPage.title}
          </Heading>
          <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.SM}>
            {PermissionsProfilesPage.subtitle}
          </Text>
        </div>
        {permissionsEnabled ? (
          <InnerTabs
            tabs={tabs}
            tabsContainerClassName={styles.tabsContainer}
            renderContainerClassName={styles.tabsContentContainer}
          />
        ) : (
          <div className={cn(styles.tabsContainer, styles.noTabs)}>
            <Heading variant={Heading.TYPES.H3}>Permissions are not enabled for this organization.</Heading>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

PermissionsProfilesPage.title = 'Permission Profiles';
PermissionsProfilesPage.subtitle = 'View and create user permission profiles';
