import React from 'react';
import { useFormikContext } from 'formik';
import { get, isEmpty } from 'lodash';

import { ITEMS_FIELD_IDS } from '../constants';

import SubSectionWrapper from './SubSectionWrapper';

const ID_PREFIX = 'configuration.involved_parties';
const LOSS_OBJECT_ID = 'related_property_loss';
const LOSS_OBJECT_FIELD_IDS = ['location', 'damages', 'is_stolen', 'damages_description', 'note'];
const ITEMS_KEY = 'items';

const RelatedPropertySubSection = ({ partyKey }: { partyKey: string }): JSX.Element => {
  const { values } = useFormikContext();

  const isAllowed = get(values, `${ID_PREFIX}.${partyKey}.is_allowed_related_damaged_property`, false);
  const id = `${ID_PREFIX}.${partyKey}.${LOSS_OBJECT_ID}`;
  const title = get(values, `${id}.desc`);

  if (!isAllowed || isEmpty(get(values, id, {}))) {
    return <></>;
  }

  return (
    <SubSectionWrapper
      id={id}
      idPrefix={`${ID_PREFIX}.${partyKey}`}
      title={title}
      sectionKey={LOSS_OBJECT_ID}
      lossSubSectionQuestionId="is_related_property_loss"
      lossObjectFieldIds={LOSS_OBJECT_FIELD_IDS}
      lossItemsFieldIds={ITEMS_FIELD_IDS}
      lossItemsFieldIdPrefix={ITEMS_KEY}
      lossItemsSectionDesc="Items"
    />
  );
};

export default RelatedPropertySubSection;
