import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { AUTO_COVERAGES_DICT } from '../../Types';
import { reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import { SortableTable } from '../core';
import LoadingIndicator from '../LoadingIndicator';
import useDataFetcher from '../useDataFetcher';

import OrganizationAdjusterSelect from './OrganizationSettings/OrganizationAdjusterSelect';

import { useStyles } from '../../assets/styles';

function TscTriage(props) {
  const { organization, specialUsers, onChangeSpecialUser } = props;
  const classes = useStyles();

  const columnData = [
    { id: 'coverage_display', label: 'Coverage' },
    { id: 'insured_last_name', label: "Insured's Last Name" },
    // eslint-disable-next-line react/display-name
    {
      id: 'adjuster',
      label: 'Adjuster',
      disableSort: true,
      specialCell: (entry) => (
        <OrganizationAdjusterSelect
          id="adjuster"
          label="Adjuster"
          hideLabel
          // eslint-disable-next-line react/prop-types
          adjuster={specialUsers.find((specialUser) => specialUser.special_role === entry.special_role).user}
          organization={organization}
          onSelectAdjuster={(adjusterId) => onChangeSpecialUser(entry.special_role, adjusterId)}
        />
      ),
    },
  ];

  function formatAutoTriageAdjusters() {
    const supportedAutoCoverages = [
      'coverage_comprehensive',
      'coverage_collision',
      'coverage_physical_damage',
      'coverage_pip',
      'coverage_bodily_injury',
      'coverage_um',
      'coverage_glass',
      'coverage_tow',
    ];

    let triageRows = [];

    supportedAutoCoverages.forEach((cov) => {
      triageRows.push({
        coverage_display: AUTO_COVERAGES_DICT[cov].desc,
        insured_last_name: 'A-L',
        special_role: `a_to_l_${cov}`,
      });
      triageRows.push({
        coverage_display: AUTO_COVERAGES_DICT[cov].desc,
        insured_last_name: 'M-Z',
        special_role: `m_to_z_${cov}`,
      });
    });

    return triageRows.map((row, idx) => ({ id: idx, ...row }));
  }

  function formatHomeTriageAdjusters() {
    return [
      { coverage_display: 'Coverage E - BI', insured_last_name: 'A-L', special_role: 'a_to_l_coverage_e_bi' },
      { coverage_display: 'Coverage E - BI', insured_last_name: 'M-Z', special_role: 'm_to_z_coverage_e_bi' },
      { coverage_display: 'Other', insured_last_name: 'Any', special_role: 'home_claim_not_e_bi' },
    ].map((row, idx) => ({ id: idx, ...row }));
  }

  return (
    <>
      <div className={classes.cardDivRow}>
        <CardDialog title="Auto">
          <SortableTable rows={formatAutoTriageAdjusters()} columns={columnData} />
        </CardDialog>
      </div>
      <div className={classes.cardDivRow}>
        <CardDialog title="Home">
          <SortableTable rows={formatHomeTriageAdjusters()} columns={columnData} />
        </CardDialog>
      </div>
    </>
  );
}

TscTriage.propTypes = {
  organization: PropTypes.object.isRequired,
  specialUsers: PropTypes.array.isRequired,
  onChangeSpecialUser: PropTypes.func.isRequired,
};

function KinTriage(props) {
  const { organization, specialUsers, onChangeSpecialUser } = props;

  const columnData = [
    { id: 'loss_type', label: 'Coverage' },
    // eslint-disable-next-line react/display-name
    {
      id: 'adjuster',
      label: 'Adjuster',
      disableSort: true,
      specialCell: (entry) => (
        <OrganizationAdjusterSelect
          id="adjuster"
          label="Adjuster"
          hideLabel
          // eslint-disable-next-line react/prop-types
          adjuster={specialUsers.find((specialUser) => specialUser.special_role === entry.special_role).user}
          organization={organization}
          onSelectAdjuster={(adjusterId) => onChangeSpecialUser(entry.special_role, adjusterId)}
        />
      ),
    },
  ];

  function formatTriageAdjusters() {
    return [{ id: 0, loss_type: 'All', special_role: 'ordinary_claims_handling_adjuster' }];
  }

  return <SortableTable rows={formatTriageAdjusters()} columns={columnData} />;
}

KinTriage.propTypes = {
  organization: PropTypes.object.isRequired,
  specialUsers: PropTypes.array.isRequired,
  onChangeSpecialUser: PropTypes.func.isRequired,
};

function OrganizationTriage(props) {
  const { organization } = props;

  const {
    isLoading,
    isError,
    data: specialUsers,
    reloadData: reloadSpecialUsers,
  } = useDataFetcher(`/sysconfig/api/v1/organizations/${organization.id}/special_users`);

  async function handleChangeSpecialUser(specialRole, userId) {
    try {
      await axios.put(`/sysconfig/api/v1/organizations/${organization.id}/special_users`, {
        special_role: specialRole,
        user_id: userId,
      });
      await reloadSpecialUsers();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  if (isLoading || isError) {
    return <LoadingIndicator isError={isError} />;
  }

  let OrgTriageComponent = undefined;
  switch (organization.name.toLowerCase()) {
    case 'tsc':
      OrgTriageComponent = TscTriage;
      break;
    case 'kin':
      OrgTriageComponent = KinTriage;
      break;
    default:
      OrgTriageComponent = undefined;
  }

  if (!OrgTriageComponent) {
    return null;
  }

  return (
    <CardDialog title="Triage">
      <OrgTriageComponent
        organization={organization}
        specialUsers={specialUsers}
        onChangeSpecialUser={handleChangeSpecialUser}
      />
    </CardDialog>
  );
}

OrganizationTriage.propTypes = {
  organization: PropTypes.object.isRequired,
};

export default OrganizationTriage;
