import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { reportAxiosError, subOrgIdToNameDict } from '../../../../../Utils';
import { useSysconfig } from '../../../SystemConfigurationScreen';
import {
  convertFormikValuesToServerValues,
  getValidationSchemaShape,
  ruleToInitialValue,
  useNotificationConfigurationTool,
} from '../utils';

import { NotificationDialogInner } from './NotificationDialogInner';

export const NotificationDialog = ({ rule, onSubmit, onClose, existingRuleNames, conditions, events }) => {
  const { organization } = useSysconfig();
  const subOrgIdToName = subOrgIdToNameDict(organization);
  const subOrgsRequired = organization.sub_organizations_enabled;
  const { recipientTypes } = useNotificationConfigurationTool();

  return (
    <Formik
      initialValues={ruleToInitialValue(rule)}
      validationSchema={Yup.object().shape(
        getValidationSchemaShape(existingRuleNames, conditions, subOrgsRequired, recipientTypes)
      )}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(convertFormikValuesToServerValues(values, organization), rule?.id);
          onClose();
        } catch (error) {
          await reportAxiosError(error);
          setSubmitting(false);
        }
      }}
    >
      <NotificationDialogInner
        rule={rule}
        onClose={onClose}
        subOrgIdToName={subOrgIdToName}
        events={events}
        conditions={conditions}
      />
    </Formik>
  );
};

NotificationDialog.propTypes = {
  rule: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  existingRuleNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  conditions: PropTypes.object.isRequired,
  events: PropTypes.array.isRequired,
};
