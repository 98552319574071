import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import Grid from '~/components/core/Atomic/Grid/Grid';
import EmptyState from '~/components/core/EmptyState';
import { AddIcon } from '~/components/deprecatedMuiIcons';

import { isoDateToUs } from '../../../../DateTimeUtils';
import { reportAxiosError } from '../../../../Utils';
import CardDialog from '../../../CardDialog';
import WithConfirm from '../../../ConfirmModal';
import { FsButton, FsIconButton, LoadingSwitch, SortableTable, Text } from '../../../core';
import { TrashIcon } from '../../../icons';
import useOrganization from '../../../OrganizationContext';
import useDataFetcher from '../../../useDataFetcher';
import { useSysconfig } from '../../SystemConfigurationScreen';
import TableauAccountsDialog from '../TableauAccountsDialog';

import { useStyles } from '../../../../assets/styles';
import styles from './index.module.scss';

const tableauAccountsUrl = (organizationId) =>
  `/sysconfig/api/v1/organizations/${organizationId}/tableau/tableau_accounts`;

const SITE_ROLES_NAMES_MAP = {
  Viewer: 'Viewer',
  ExplorerCanPublish: 'Explorer',
};

const getSiteRoleName = (siteRole) => SITE_ROLES_NAMES_MAP[siteRole];

const TableauAccountsTable = ({ maxLimit }) => {
  const classes = useStyles();
  const { users } = useSysconfig();
  const { organizationId } = useOrganization();
  const {
    data: tableauAccounts = [],
    reloadData,
    isLoading: isTableauAccountsLoading = false,
    isError = false,
  } = useDataFetcher(tableauAccountsUrl(organizationId));

  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const createTableauAccount = async ({ user_id, site_role }) => {
    try {
      setIsLoading(true);
      await axios.post(tableauAccountsUrl(organizationId), { user_id, site_role });
      await reloadData();
      setIsLoading(false);
      setIsDialogOpen(false);
    } catch (error) {
      setIsLoading(false);
      reportAxiosError(error);
    }
  };

  const deleteTableauAccount = async ({ user_id }) => {
    try {
      setIsLoading(true);
      await axios.delete(`${tableauAccountsUrl(organizationId)}/${user_id}`);
      await reloadData();
      setIsLoading(false);
      setIsDialogOpen(false);
    } catch (error) {
      setIsLoading(false);
      reportAxiosError(error);
    }
  };

  const columns = [
    {
      id: 'username',
      numeric: false,
      label: 'Name',
    },
    {
      id: 'created_datetime',
      numeric: false,
      label: 'Added On',
      disableSort: true,
      specialCell: ({ created_datetime }) => (created_datetime ? isoDateToUs(created_datetime) : undefined),
    },
    {
      id: 'created_by_username',
      numeric: false,
      label: 'Added by',
    },
    {
      id: 'site_role',
      numeric: false,
      label: 'Site Role',
      specialCell: (row) => getSiteRoleName(row?.site_role),
    },
    {
      id: 'actions',
      label: 'Actions',
      numeric: false,
      disableSort: true,
      align: 'center',
      width: '60px',
      specialCell: (tableauAccount) => (
        <div className={styles.actionsContainer}>
          <WithConfirm
            key={tableauAccount.id}
            centerDialog
            newSecondaryButton
            title="Delete Tableau Accounts?"
            contentText="Deleting this user from account access list will disable the user from seeing Tableau features."
            primaryButtonName="Yes"
            shouldCloseOnPrimary={true}
          >
            <FsIconButton
              icon={TrashIcon}
              tooltipText="Delete"
              className={styles.deleteBtn}
              onClick={async () => await deleteTableauAccount(tableauAccount)}
            />
          </WithConfirm>
        </div>
      ),
    },
  ];

  return (
    <LoadingSwitch isLoading={isLoading || isTableauAccountsLoading} isError={isError}>
      <Grid item xs={12}>
        <CardDialog
          title="Account Access Users List"
          subheader={
            tableauAccounts?.length > 0 ? (
              <Text
                variant={Text.VARIANTS.SM}
                weight={Text.WEIGHTS.SEMI_BOLD}
                colorVariant={Text.COLOR_VARIANTS.SECONDARY}
              >
                {`${tableauAccounts?.length} out of ${maxLimit} configured`}
              </Text>
            ) : (
              'No users were added yet'
            )
          }
          action={
            <div className={styles.btnContainer}>
              <FsButton
                color={FsButton.COLORS.primary}
                className={styles.btn}
                onClick={() => setIsDialogOpen(true)}
                disabled={
                  isTableauAccountsLoading ||
                  isLoading ||
                  !maxLimit ||
                  tableauAccounts?.length === maxLimit ||
                  tableauAccounts?.length === users?.length
                }
              >
                <AddIcon className={classes.rightButtonIcon} />
                ADD ACCOUNT ACCESS
              </FsButton>
            </div>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <SortableTable
          rows={tableauAccounts}
          columns={columns}
          defaultOrderColumn={columns.findIndex((column) => column.id === 'username')}
          order="asc"
          stickyHeader
          autoPaginateRowsPerPage={5}
          emptyStateComponent={
            <EmptyState
              subtitle="No account access users were added yet"
              buttonComponent={
                <FsButton
                  variant="contained"
                  color="primary"
                  onClick={() => setIsDialogOpen(true)}
                  disabled={isTableauAccountsLoading || isLoading || !maxLimit}
                >
                  <span style={{ display: 'flex' }}>
                    <AddIcon className={classes.leftButtonIcon} />
                    ADD ACCOUNT ACCESS
                  </span>
                </FsButton>
              }
            />
          }
        />
      </Grid>
      {isDialogOpen && (
        <TableauAccountsDialog
          tableauAccounts={tableauAccounts}
          onClose={() => setIsDialogOpen(false)}
          handleSubmit={createTableauAccount}
        />
      )}
    </LoadingSwitch>
  );
};

export default TableauAccountsTable;

TableauAccountsTable.propTypes = {
  maxLimit: PropTypes.number,
};

TableauAccountsTable.defaultProps = {
  maxLimit: 0,
};
