import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import CachedIcon from '@material-ui/icons/Cached';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import { Formik, getIn, useFormikContext } from 'formik';
import { debounce, isEmpty } from 'lodash';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Chip from '~/components/core/Atomic/Chip/Chip';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';
import CancelButton from '~/components/core/Buttons/CancelButton';
import SearchWithOptionsFormik from '~/components/core/Formik/SearchWithOptionsFormik/SearchWithOptionsFormik';
import SimpleSearchField from '~/components/core/SimpleSearchField';
import { AddIcon } from '~/components/deprecatedMuiIcons';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';

import { serverDateTimeToLocal } from '../../../DateTimeUtils';
import {
  AUTO_COVERAGES_DICT,
  BIKE_COVERAGES_DICT,
  COUNTRY_TO_STATE_MAP,
  GL_COVERAGE_DICT,
  HOME_COVERAGES_DICT,
  PET_COVERAGE_DICT,
  TRAVEL_COVERAGE_DICT,
  WC_COVERAGE_DICT,
} from '../../../Types';
import {
  allCoveragesListWithGeneralKeys,
  isInshurOrganization,
  isOrganizationUs,
  reportAxiosError,
} from '../../../Utils';
import { getLobDescription } from '../../../Utils/lobUtils';
import AutocompleteFormik from '../../AutocompleteFormik';
import CardDialog from '../../CardDialog';
import { localeDetails } from '../../CmsMain/globals';
import { SortableTable, Text } from '../../core';
import FileDropZone from '../../FileDropZone';
import HoverActionField from '../../HoverActionField';
import { DownloadIcon, InfoIcon, PlusIcon, UploadIcon } from '../../icons';
import InlineIconButton from '../../InlineIconButton';
import LoadingIndicator from '../../LoadingIndicator';
import useOrganization from '../../OrganizationContext';
import OverflowTextWithToolTip from '../../OverflowTextWithToolTip';
import TextFieldFormik, { MultiSelectTextFieldFormik } from '../../TextFieldFormik';
import { CoveragesMultiselectFormik } from '../../TPA/Coverages/CoveragesMultiselectFormik';
import { LobCheckboxMultiselectFormik } from '../../TPA/LOB/LobCheckboxMultiselectFormik';
import SubOrganizationMultiselectFormik from '../../TPA/SubOrganizations/SubOrganizationMultiselectFormik';
import useDataFetcher from '../../useDataFetcher';
import { useSysconfig } from '../SystemConfigurationScreen';

import ImportDocumentTemplatesZip from './ImportDocumentTemplatesZip';

import { colorPalette, useStyles } from '../../../assets/styles';
import colors from '../../../assets/colors.module.scss';
import styles from './OrganizationDocumentsTemplates.module.scss';

const subOrganizationsText = (isAll, subOrgs) => {
  if (isAll) {
    return 'All';
  } else if (subOrgs?.length) {
    return subOrgs.map((s) => s.name).join(', ');
  } else {
    return '';
  }
};

const ALL_OPTION = 'All';
const statesWithoutEmpty = Object.keys(COUNTRY_TO_STATE_MAP['US']).filter((state) => !!state);

const renderCoverageName = (coverage) => {
  if (coverage in HOME_COVERAGES_DICT) {
    return HOME_COVERAGES_DICT[coverage];
  } else if (coverage in AUTO_COVERAGES_DICT) {
    return AUTO_COVERAGES_DICT[coverage].desc;
  } else if (coverage in WC_COVERAGE_DICT) {
    return WC_COVERAGE_DICT[coverage].desc;
  } else if (coverage in GL_COVERAGE_DICT) {
    return GL_COVERAGE_DICT[coverage].desc;
  } else if (coverage in PET_COVERAGE_DICT) {
    return PET_COVERAGE_DICT[coverage].desc;
  } else if (coverage in TRAVEL_COVERAGE_DICT) {
    return TRAVEL_COVERAGE_DICT[coverage].desc;
  } else if (coverage in BIKE_COVERAGES_DICT) {
    return BIKE_COVERAGES_DICT[coverage].desc; // TODO: customClaimTODO add custom claim support
  } else if (coverage === 'general') {
    return 'General';
  }
  return coverage;
};

const getDocumentTemplateValidationScheme = (documentTypesDict, forbiddenNames, subOrganizationEnabled) => {
  return Yup.object().shape({
    is_file_required: Yup.bool(),
    file_size: Yup.number().when('is_file_required', {
      is: true,
      then: Yup.number().required('Required').min(1, 'Required'),
    }),
    filename: Yup.string().required('Required').notOneOf(forbiddenNames, 'Filename already exists'),
    display_name: Yup.string().required('Required'),
    coverages: Yup.array().min(1, 'At least one coverage required'),
    states: localeDetails.locale.region === 'US' ? Yup.array().min(1, 'At least one state required') : undefined,
    comment: Yup.string().required('Required'),
    document_type: Yup.string().oneOf(Object.keys(documentTypesDict)).required('Required'),
    is_all_sub_orgs: Yup.bool(),
    sub_organization_ids: subOrganizationEnabled
      ? Yup.array().when('is_all_sub_orgs', {
          is: true,
          then: Yup.array().max(0, 'Cannot select sub-organizations when All is also selected'),
          otherwise: Yup.array().min(1, 'At least one sub-organization required'),
        })
      : Yup.array().max(0, 'Sub-organizations must be empty'),
    is_all_lobs: Yup.bool(),
    lobs: Yup.array().when('is_all_lobs', {
      is: true,
      then: Yup.array().max(0, 'Cannot select lines of business when All is also selected'),
      otherwise: Yup.array().min(1, 'At least one line of business is required'),
    }),
  });
};

function DocumentDownloadLink(props) {
  const { documentTemplate, orgId, displayIcon } = props;
  const classes = useStyles();

  const documentLink = `/api/v1/document_templates/organizations/${orgId}/document_template/${documentTemplate.id}`;
  return (
    <Link href={documentLink} rel="noopener noreferrer">
      <div style={{ visibility: displayIcon ? 'visible' : 'hidden' }} className={classes.containerCentered}>
        <InlineIconButton className={classes.inlineEditIcon} icon={CloudDownloadIcon} onClick={() => {}} />
      </div>
    </Link>
  );
}

DocumentDownloadLink.propTypes = {
  documentTemplate: PropTypes.object.isRequired,
  orgId: PropTypes.number.isRequired,
  displayIcon: PropTypes.bool.isRequired,
};

function DeleteTemplateDocumentDialog(props) {
  const { documentTemplate, orgId, displayIcon, onDeleteDocument } = props;
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [disableDelete, setDisableDelete] = useState(false);
  const classes = useStyles();

  const onDelete = async () => {
    setDisableDelete(true);
    try {
      await axios.delete(
        `/api/v1/document_templates/organizations/${orgId}/delete_document_template/${documentTemplate.id}`
      );
      await onDeleteDocument();
      setConfirmDelete(false);
      setDisableDelete(false);
    } catch (error) {
      reportAxiosError(error);
      setDisableDelete(false);
    }
  };

  return (
    <>
      <HoverActionField onAction={() => setConfirmDelete(true)} permanent={displayIcon} icon={DeleteIcon} />
      <CardDialog
        isDialog={true}
        open={confirmDelete}
        maxWidth="sm"
        title="Please confirm delete"
        onClose={() => setConfirmDelete(false)}
        preventClose={disableDelete}
      >
        <div className={classes.buttonsContainer}>
          <Button
            className={classes.leftButtonDialog}
            variant="contained"
            color="secondary"
            disabled={disableDelete}
            onClick={onDelete}
          >
            Confirm delete!
          </Button>
          <Button variant="contained" color="primary" disabled={disableDelete} onClick={() => setConfirmDelete(false)}>
            Cancel
          </Button>
        </div>
      </CardDialog>
    </>
  );
}

DeleteTemplateDocumentDialog.propTypes = {
  documentTemplate: PropTypes.object.isRequired,
  orgId: PropTypes.number.isRequired,
  displayIcon: PropTypes.bool.isRequired,
  onDeleteDocument: PropTypes.func.isRequired,
};

function DocumentTemplateFormInner(props) {
  const {
    uploadPercent,
    values,
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
    handleSubmit,
    onCancel,
    uploadDocument,
  } = props;
  const [showUploadFile, setShowUploadFile] = useState(false);
  const classes = useStyles();
  const { subOrganizations, supportedClaimTypes } = useOrganization();
  const { documentTypesDict, subOrganizationEnabled } = useOrganization();

  const onFileSelect = (file) => {
    setFieldValue('file', file);
    setFieldTouched('file', true);
    setFieldValue('file_size', file.size);
    setFieldTouched('file_size', true);
    setFieldValue('filename', file.name);
    setFieldTouched('filename', true);
    setFieldValue('display_name', file.name.split('.')[0]);
    setFieldTouched('display_name', true);
  };

  const onCancelFileSelect = () => {
    setFieldValue('file', undefined);
    setFieldValue('file_size', undefined);
    setFieldValue('filename', '');
    setFieldValue('display_name', '');
  };

  const onShowUploadFile = () => {
    setShowUploadFile(true);
    setFieldValue('is_file_required', true);
  };

  if (!showUploadFile && uploadDocument) {
    onShowUploadFile();
  }

  return (
    <Grid container>
      {!showUploadFile && (
        <Grid item xs={12}>
          <div className={classes.containerCentered}>
            <CachedIcon color="primary" className={classes.leftButtonIcon} />
            <Button variant="outlined" color="primary" disabled={isSubmitting} onClick={onShowUploadFile}>
              Update document template content
            </Button>
          </div>
        </Grid>
      )}

      {showUploadFile && (
        <Grid item xs={12}>
          <FileDropZone
            onFileSelect={onFileSelect}
            onCancelFileSelect={onCancelFileSelect}
            file={values['file']}
            uploadPercent={uploadPercent}
            error={getIn(errors, 'file_size') && getIn(touched, 'file_size')}
            errorMessage={getIn(errors, 'file_size')}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <TextFieldFormik id="display_name" label="Display Name" className={classes.textField} fullWidth />
      </Grid>
      {subOrganizationEnabled && (
        <Grid item xs={12}>
          <SubOrganizationMultiselectFormik
            subOrganizationsFieldId="sub_organization_ids"
            allSelectedFieldId="is_all_sub_orgs"
            selectAllLabel="All (inc. future sub-orgs)"
            showAllInRenderedSelectedOptions
            shouldDisplayAllOption
          />
        </Grid>
      )}
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <LobCheckboxMultiselectFormik
          lobsFieldId="lobs"
          allSelectedFieldId="is_all_lobs"
          subOrganizationIds={values.is_all_sub_orgs ? subOrganizations.map((s) => s.id) : values.sub_organization_ids}
          shouldIncludeLabel
        />
      </Grid>
      {localeDetails.locale.region === 'US' && (
        <Grid item xs={12}>
          <MultiSelectTextFieldFormik
            id="states"
            label="States"
            options={Object.keys(COUNTRY_TO_STATE_MAP['US']).filter((state) => !!state)} // remove empty string from the states list
            renderValue={(selected) => selected.join(', ')}
            className={classes.textField}
            fullWidth
            renderOption={(state) => COUNTRY_TO_STATE_MAP['US'][state]}
            addAllOption
            sortAlphabetic
            allOptionValue={ALL_OPTION}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <CoveragesMultiselectFormik
          coveragesFieldId="coverages"
          subOrganizationIds={values.is_all_sub_orgs ? subOrganizations.map((s) => s.id) : values.sub_organization_ids}
          lobs={values.is_all_lobs ? supportedClaimTypes : values.lobs}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormik id="filename" label="File Name" className={classes.textField} fullWidth />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormik id="comment" label="Comment" className={classes.textField} fullWidth />
      </Grid>
      <Grid item xs={12}>
        <AutocompleteFormik
          id="document_type"
          label="Document Type"
          options={Object.keys(documentTypesDict)}
          getOptionLabel={(option) => documentTypesDict[option]['desc']}
          sortAlphabetic
        />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.buttonsContainer}>
          <CancelButton disabled={isSubmitting} onClick={onCancel} />
          <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
            Save
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}

DocumentTemplateFormInner.propTypes = {
  touched: PropTypes.object.isRequired, // From Formik
  errors: PropTypes.object.isRequired, // From Formik
  values: PropTypes.object.isRequired, // From Formik
  setFieldValue: PropTypes.func.isRequired, // From Formik
  isSubmitting: PropTypes.bool.isRequired, // From Formik
  handleSubmit: PropTypes.func.isRequired, // From Formik
  resetForm: PropTypes.func.isRequired, // From Formik
  onCancel: PropTypes.func.isRequired, // From Formik
  setFieldTouched: PropTypes.func.isRequired, // From Formik
  uploadPercent: PropTypes.number.isRequired,
  uploadDocument: PropTypes.bool,
};

const getDocumentUploadConfig = (setUploadPercent) => {
  const config = {
    onUploadProgress: (progressEvent) => {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setUploadPercent(percentCompleted);
    },
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return config;
};

const getDocumentUploadFormData = (values) => {
  const formData = new FormData();
  if (values.is_file_required) {
    formData.append('file', values['file']);
  }
  formData.append('filename', values['filename']);
  formData.append('display_name', values['display_name']);
  formData.append('is_all_sub_orgs', values['is_all_sub_orgs']);
  formData.append('sub_organization_ids', values['sub_organization_ids']);
  formData.append('is_all_lobs', values['is_all_lobs']);
  formData.append('lobs', values['lobs']);
  formData.append('coverages', values['coverages']);
  formData.append(
    'states',
    values['states'].length === 1 && values['states'][0] === 'All' ? statesWithoutEmpty : values['states']
  );
  formData.append('comment', values['comment']);
  formData.append('document_type', values['document_type']);
  return formData;
};

function UpdateDocumentTemplateHover(props) {
  const { documentTemplate, displayIcon, orgId, onDocumentUpdate, documentTemplates } = props;
  const [updateTemplateContent, setUpdateTemplateContent] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(0);
  const {
    organizationOperationalDetails: { documentTypesDict, subOrganizationEnabled },
  } = useSysconfig();

  const handleDocumentSubmit = async (values, formikProps) => {
    const { setSubmitting, resetForm } = formikProps;

    const formData = getDocumentUploadFormData(values);
    const config = getDocumentUploadConfig(setUploadPercent);

    try {
      await axios.patch(
        `/api/v1/document_templates/organizations/${orgId}/update_document_template/${documentTemplate.id}`,
        formData,
        config
      );
      await onDocumentUpdate();
      resetForm();
      setUpdateTemplateContent(false);
    } catch (error) {
      reportAxiosError(error);
    }
    setUploadPercent(0);
    setSubmitting(false);
  };

  const handleCancel = (resetForm) => {
    setUpdateTemplateContent(false);
    resetForm();
  };
  const forbiddenNames = documentTemplates
    .map((doc) => doc.filename)
    .filter((filename) => filename !== documentTemplate.filename);

  return (
    <>
      <HoverActionField onAction={() => setUpdateTemplateContent(true)} permanent={displayIcon} />
      <Formik
        initialValues={{
          ...documentTemplate,
          sub_organization_ids: documentTemplate.sub_organizations.map((docTemplate) => docTemplate.id),
          file_size: '',
          is_file_required: false,
          comment: '',
          states:
            documentTemplate.states && documentTemplate.states.length === statesWithoutEmpty.length
              ? [ALL_OPTION]
              : documentTemplate.states,
        }}
        validationSchema={getDocumentTemplateValidationScheme(
          documentTypesDict,
          forbiddenNames,
          subOrganizationEnabled
        )}
        onSubmit={handleDocumentSubmit}
        enableReinitialize
      >
        {(formikProps) => {
          const { isSubmitting, resetForm } = formikProps;

          return (
            <CardDialog
              isDialog={true}
              open={updateTemplateContent}
              maxWidth="sm"
              title="Update Document Template content"
              onClose={() => handleCancel(resetForm)}
              preventClose={isSubmitting}
            >
              <DocumentTemplateFormInner
                {...formikProps}
                uploadPercent={uploadPercent}
                onCancel={() => handleCancel(resetForm)}
              />
            </CardDialog>
          );
        }}
      </Formik>
    </>
  );
}

UpdateDocumentTemplateHover.propTypes = {
  documentTemplate: PropTypes.object.isRequired,
  displayIcon: PropTypes.bool,
  orgId: PropTypes.number.isRequired,
  onDocumentUpdate: PropTypes.func.isRequired,
  documentTemplates: PropTypes.array.isRequired,
};

const EXPORT_DOCUMENTS_MAIN_ID = 'document_template_ids';

function ExportDocumentTemplates({ onCancel, documentTemplates }) {
  const { organizationId } = useOrganization();

  const handleDocumentExport = async (values, formikProps) => {
    const { setSubmitting } = formikProps;

    const params = {
      document_template_ids: values[EXPORT_DOCUMENTS_MAIN_ID],
    };

    try {
      const response = await axios.post(
        `/api/v1/document_templates/organizations/${organizationId}/export_document_templates`,
        params,
        {
          responseType: 'blob',
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'document_templates.zip');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      onCancel();
    } catch (error) {
      reportAxiosError(error);
    }
    setSubmitting(false);
  };

  const options = [
    {
      title: 'Document Templates',
      subtitle: 'Select document templates to export',
      entries: documentTemplates.map((template) => ({
        id: `${template.id}`,
        entryTitle: `${template.display_name} (${template.filename})`,
        secondaryAction: (
          <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR} colorVariant={Text.COLOR_VARIANTS.SECONDARY}>
            {serverDateTimeToLocal(template.last_updated_datetime)}
          </Text>
        ),
        isDisabled: false,
      })),
    },
  ];

  return (
    <Formik
      initialValues={{
        [EXPORT_DOCUMENTS_MAIN_ID]: [],
      }}
      onSubmit={handleDocumentExport}
      validationSchema={Yup.object().shape({
        [EXPORT_DOCUMENTS_MAIN_ID]: Yup.array().min(1, 'At least one document template must be selected'),
      })}
    >
      {({ isSubmitting, handleSubmit }) => {
        return (
          <CardDialog
            containerClassName={styles.exportCardContainer}
            isDialog
            maxWidth="sm"
            title="Export Document Templates"
            onClose={onCancel}
            preventClose={isSubmitting}
            fullWidth
          >
            <SearchWithOptionsFormik
              mainFieldId={EXPORT_DOCUMENTS_MAIN_ID}
              options={options}
              isSelectAllEnabled
              label="Search Document"
              textMaxWidth="30ch"
            />
            <div>
              <div className={styles.exportCardDialogButtonsContainer}>
                <CancelButton withMarginRight onClick={onCancel} />
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  Export
                </Button>
              </div>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

ExportDocumentTemplates.propTypes = {
  onCancel: PropTypes.func.isRequired,
  documentTemplates: PropTypes.array.isRequired,
};

function AddDocumentTemplate(props) {
  const { orgId, onDocumentUpload, onCancel, documentTemplates } = props;
  const [uploadPercent, setUploadPercent] = useState(0);
  const {
    organizationOperationalDetails: { documentTypesDict, subOrganizationEnabled },
  } = useSysconfig();

  const handleDocumentSubmit = async (values, formikProps) => {
    const { setSubmitting } = formikProps;

    const formData = getDocumentUploadFormData(values);
    const config = getDocumentUploadConfig(setUploadPercent);

    try {
      await axios.post(`/api/v1/document_templates/organizations/${orgId}/upload_document_template`, formData, config);
      await onDocumentUpload();
    } catch (error) {
      reportAxiosError(error);
    }
    setSubmitting(false);
  };

  const forbiddenNames = documentTemplates.map((doc) => doc.filename);

  return (
    <>
      <Formik
        initialValues={{
          file_size: '',
          filename: '',
          display_name: '',
          coverages: [],
          states: [],
          comment: '',
          is_file_required: true,
          document_type: '',
          is_all_sub_orgs: false,
          sub_organization_ids: [],
          is_all_lobs: false,
          lobs: [],
        }}
        validationSchema={getDocumentTemplateValidationScheme(
          documentTypesDict,
          forbiddenNames,
          subOrganizationEnabled
        )}
        onSubmit={handleDocumentSubmit}
        enableReinitialize
      >
        {(formikProps) => {
          const { isSubmitting } = formikProps;

          return (
            <CardDialog
              isDialog={true}
              maxWidth="sm"
              title="Upload Document Template"
              onClose={onCancel}
              preventClose={isSubmitting}
            >
              <DocumentTemplateFormInner
                {...formikProps}
                uploadPercent={uploadPercent}
                onCancel={onCancel}
                uploadDocument
              />
            </CardDialog>
          );
        }}
      </Formik>
    </>
  );
}

AddDocumentTemplate.propTypes = {
  displayIcon: PropTypes.bool,
  orgId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDocumentUpload: PropTypes.func.isRequired,
  documentTemplates: PropTypes.array.isRequired,
};

const TableWithSearch = ({ columnData, displayNameColumnIndex, documentTemplates }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredDocumentTemplates, setFilteredDocumentTemplates] = useState(documentTemplates);

  const performSearch = debounce(() => {
    const filteredResults = documentTemplates.filter((doc) => {
      const fileName = doc.filename.toLowerCase();
      const displayName = doc.display_name.toLowerCase();
      const searchTextLower = searchTerm.toLowerCase();

      return fileName.includes(searchTextLower) || displayName.includes(searchTextLower);
    });

    setFilteredDocumentTemplates(filteredResults);
  }, 300);

  React.useEffect(() => {
    performSearch();
  }, [performSearch, searchTerm]);

  return (
    <>
      <div className={styles.searchContainer}>
        <SimpleSearchField renderClearButton label="Search" onChange={setSearchTerm} />
      </div>

      <SortableTable
        columns={columnData}
        defaultOrderColumn={displayNameColumnIndex}
        rows={filteredDocumentTemplates}
        autoPaginateRowsPerPage={15}
      />
    </>
  );
};

TableWithSearch.propTypes = {
  columnData: PropTypes.array.isRequired,
  displayNameColumnIndex: PropTypes.number.isRequired,
  documentTemplates: PropTypes.array.isRequired,
};

function OrganizationDocumentsTemplates(props) {
  const { orgId } = props;

  const {
    isLoading: isLoadingDocumentTemplates,
    isError: isErrorDocumentTemplates,
    data: documentTemplates,
    reloadData: reloadDataDocumentTemplates,
  } = useDataFetcher(`/api/v1/document_templates/organizations/${orgId}/document_templates`);
  const {
    isLoading: isLoadingTemplatesBundles,
    isError: isErrorTemplatesBundles,
    data: templatesBundles,
    reloadData: reloadDataTemplatesBundles,
  } = useDataFetcher(`/api/v1/document_templates/organizations/${orgId}/templates_bundles`);
  const classes = useStyles();
  const {
    organization,
    organizationOperationalDetails: { documentTypesDict, subOrganizationEnabled },
  } = useSysconfig();

  const { lobConfigurationsDict } = useLobConfiguration();

  const lobsText = (isAll, lobs) => {
    if (isAll) {
      return 'All';
    } else if (lobs?.length) {
      return lobs.map((lob) => getLobDescription(lob, lobConfigurationsDict)).join(', ');
    } else {
      return '';
    }
  };
  const isUSOrg = isOrganizationUs(organization);

  const errorColumn = (text) => (
    <div style={{ display: 'flex' }}>
      <InfoIcon size={20} iconColor={colorPalette.error} style={{ marginRight: 5 }} />
      <span style={{ color: colorPalette.error }}>{text}</span>
    </div>
  );

  const subOrganizationCell = (docTemplate) => {
    return (
      subOrganizationsText(docTemplate.is_all_sub_orgs, docTemplate.sub_organizations) ||
      errorColumn('Sub-organizations not set')
    );
  };

  const lobsCell = (docTemplate) => {
    return lobsText(docTemplate.is_all_lobs, docTemplate.lobs) || errorColumn('Lines of business not set');
  };

  let columnData = [
    // eslint-disable-next-line react/display-name
    {
      id: 'coverages',
      label: 'Coverages',
      width: '600px',
      leftPaddingOnly: true,
      disableSort: true,
      specialCell: (documentTemplate) => (
        <div className={classes.chips}>
          {documentTemplate.coverages.map((coverage, index) => (
            <Chip size="small" style={{ margin: '3px' }} key={index} label={renderCoverageName(coverage)} />
          ))}
        </div>
      ),
    },
    { id: 'filename', label: 'Filename' },
    { id: 'display_name', label: 'Display name' },
    {
      id: 'sub_organizations',
      label: 'Sub-Organizations',
      isHidden: !subOrganizationEnabled,
      specialCell: subOrganizationCell,
    },
    { id: 'lobs', label: 'Lines of Business', specialCell: lobsCell },
    // eslint-disable-next-line react/display-name
    ...(isUSOrg
      ? [
          {
            id: 'states',
            label: 'States',
            specialCell: (documentTemplate) =>
              documentTemplate.states.length === statesWithoutEmpty.length ? (
                ALL_OPTION
              ) : (
                <OverflowTextWithToolTip maxWidth={220}>
                  {documentTemplate.states.map((state) => COUNTRY_TO_STATE_MAP['US'][state]).join(', ')}
                </OverflowTextWithToolTip>
              ),
          },
        ]
      : []),
    // eslint-disable-next-line react/display-name
    {
      id: 'document_type',
      label: 'Document type',
      specialCell: (docTemplate) => (
        <Typography>{documentTypesDict?.[docTemplate.document_type]?.desc || docTemplate.document_type}</Typography>
      ),
    },
    { id: 'comment', label: 'Comment' },
    {
      id: 'last_updated_datetime',
      numeric: false,
      width: '150px',
      label: 'Last updated',
      specialCell: (documentTemplate) =>
        documentTemplate.last_updated_datetime ? serverDateTimeToLocal(documentTemplate.last_updated_datetime) : 'N/A',
    },
    { id: 'last_updated_by_username', numeric: false, label: 'Last updated by' },
    // eslint-disable-next-line react/display-name
    {
      id: 'update_template_content',
      disablePadding: true,
      width: '20px',
      disableSort: true,
      specialCell: (documentTemplate, isHover) => (
        <UpdateDocumentTemplateHover
          classes={classes}
          documentTemplate={documentTemplate}
          displayIcon={isHover}
          orgId={orgId}
          onDocumentUpdate={reloadDataDocumentTemplates}
          documentTemplates={documentTemplates}
        />
      ),
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'download_template',
      disablePadding: true,
      width: '20px',
      specialCell: (documentTemplate, isHover) => (
        <DocumentDownloadLink
          classes={classes}
          documentTemplate={documentTemplate}
          displayIcon={isHover}
          orgId={orgId}
        />
      ),
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'delete_template',
      disablePadding: true,
      width: '20px',
      specialCell: (documentTemplate, isHover) => (
        <DeleteTemplateDocumentDialog
          classes={classes}
          documentTemplate={documentTemplate}
          displayIcon={isHover}
          orgId={orgId}
          onDeleteDocument={reloadDataDocumentTemplates}
        />
      ),
    },
  ];

  if (isLoadingDocumentTemplates || isLoadingTemplatesBundles) {
    return <LoadingIndicator isError={isErrorDocumentTemplates || isErrorTemplatesBundles} />;
  }

  const displayNameColumnIndex = columnData.findIndex((column) => column.id === 'display_name');

  return (
    <>
      <div className={classes.cardDivRow}>
        <CardDialog containerStyle={{ paddingTop: '24px' }} title="Documents templates">
          <OrganizationDocumentsTemplatesActions
            orgId={orgId}
            reloadDataDocumentTemplates={reloadDataDocumentTemplates}
            reloadDataTemplatesBundles={reloadDataTemplatesBundles}
            documentTemplates={documentTemplates}
            templatesBundles={templatesBundles}
          />
          <TableWithSearch
            columnData={columnData}
            displayNameColumnIndex={displayNameColumnIndex}
            documentTemplates={documentTemplates}
          />
        </CardDialog>
      </div>
    </>
  );
}

OrganizationDocumentsTemplates.propTypes = {
  orgId: PropTypes.number.isRequired,
};

const OrganizationDocumentsTemplatesActions = ({
  orgId,
  documentTemplates,
  reloadDataDocumentTemplates,
  templatesBundles,
  reloadDataTemplatesBundles,
}) => {
  const classes = useStyles();
  const { organization } = useSysconfig();

  const [newDocumentOpen, setNewDocumentOpen] = useState(false);
  const [exportOpen, setExportOpen] = useState(false);
  const [importZipOpen, setImportZipOpen] = useState(false);

  const divStyle = {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-40px',
    marginBottom: '16px',
  };

  return (
    <>
      <div style={divStyle}>
        <Button
          color="primary"
          onClick={() => setNewDocumentOpen(true)}
          startIcon={<PlusIcon iconColor={colors.buttonLink} size={12} />}
        >
          New Document
        </Button>
        <Button
          color="primary"
          onClick={() => setExportOpen(true)}
          disabled={isEmpty(documentTemplates)}
          startIcon={<DownloadIcon iconColor={colors.buttonLink} size={12} />}
        >
          Export
        </Button>
        <Button
          color="primary"
          onClick={() => setImportZipOpen(true)}
          startIcon={<UploadIcon iconColor={colors.buttonLink} size={12} />}
        >
          Import
        </Button>
      </div>
      {exportOpen ? (
        <ExportDocumentTemplates onCancel={() => setExportOpen(false)} documentTemplates={documentTemplates} />
      ) : null}
      {importZipOpen && (
        <ImportDocumentTemplatesZip
          reloadDataTemplatesBundles={reloadDataTemplatesBundles}
          reloadDataDocumentTemplates={reloadDataDocumentTemplates}
          onCancel={() => {
            setImportZipOpen(false);
          }}
          documentTemplates={documentTemplates}
        />
      )}
      {newDocumentOpen && (
        <AddDocumentTemplate
          classes={classes}
          orgId={orgId}
          onCancel={() => setNewDocumentOpen(false)}
          onDocumentUpload={async () => {
            await reloadDataDocumentTemplates();
            setNewDocumentOpen(false);
          }}
          documentTemplates={documentTemplates}
        />
      )}

      {isInshurOrganization(organization) && (
        <DocumentTemplatesBundlesCard
          organizationId={orgId}
          documentTemplates={documentTemplates}
          documentTemplatesBundles={templatesBundles}
          onUpdate={reloadDataTemplatesBundles}
        />
      )}
    </>
  );
};

OrganizationDocumentsTemplatesActions.propTypes = {
  orgId: PropTypes.number.isRequired,
  reloadDataDocumentTemplates: PropTypes.func.isRequired,
  reloadDataTemplatesBundles: PropTypes.func.isRequired,
  documentTemplates: PropTypes.array.isRequired,
  templatesBundles: PropTypes.array.isRequired,
};

function DocumentTemplatesBundlesCard({ organizationId, documentTemplates, documentTemplatesBundles, onUpdate }) {
  const [addNewBundleOpen, setAddNewBundleOpen] = useState(false);
  const classes = useStyles();
  const {
    organizationOperationalDetails: { documentTypesDict },
  } = useSysconfig();

  const columnData = [
    // eslint-disable-next-line react/display-name
    {
      id: 'coverages',
      label: 'Coverages',
      leftPaddingOnly: true,
      disableSort: true,
      specialCell: (documentTemplatesBundle) => (
        <div className={classes.chips}>
          {documentTemplatesBundle.coverages.map((coverage, index) => (
            <Chip size="small" style={{ margin: '3px' }} key={index} label={renderCoverageName(coverage)} />
          ))}
        </div>
      ),
    },
    {
      id: 'document_templates',
      label: 'Document Templates',
      leftPaddingOnly: true,
      disableSort: true,
      specialCell: (documentTemplatesBundle) =>
        documentTemplatesBundle.bundled_document_templates
          .map((bundled_document_template) => bundled_document_template.document_template.display_name)
          .join(', '),
    },
    { id: 'display_name', label: 'Display name' },
    // eslint-disable-next-line react/display-name
    {
      id: 'states',
      label: 'States',
      specialCell: (documentTemplate) => (
        <Typography>{documentTemplate.states.map((state) => COUNTRY_TO_STATE_MAP['US'][state]).join(',  ')}</Typography>
      ),
    },
    { id: 'comment', label: 'Comment' },
    // eslint-disable-next-line react/display-name
    {
      id: 'document_type',
      label: 'Document type',
      specialCell: (bundled_document_template) => (
        <Typography>{documentTypesDict[bundled_document_template.document_type].desc}</Typography>
      ),
    },
    {
      id: 'last_updated_datetime',
      numeric: false,
      width: '150px',
      label: 'Last updated',
      specialCell: (documentTemplatesBundles) =>
        documentTemplatesBundles.last_updated_datetime
          ? serverDateTimeToLocal(documentTemplatesBundles.last_updated_datetime)
          : 'N/A',
    },
    //eslint-disable-next-line react/display-name
    {
      id: 'update_bundle',
      disablePadding: true,
      width: '20px',
      disableSort: true,
      specialCell: (documentTemplatesBundle, isHover) => (
        <UpdateDocumentTemplatesBundleHover
          documentTemplatesBundle={documentTemplatesBundle}
          displayIcon={isHover}
          organizationId={organizationId}
          onUpdate={onUpdate}
          documentTemplatesBundles={documentTemplatesBundles}
          documentTemplates={documentTemplates}
        />
      ),
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'delete_template',
      disablePadding: true,
      width: '20px',
      specialCell: (documentTemplatesBundle, isHover) => (
        <DeleteTemplateBundleHover
          documentTemplatesBundle={documentTemplatesBundle}
          displayIcon={isHover}
          organizationId={organizationId}
          onDelete={onUpdate}
        />
      ),
    },
  ];

  const displayNameColumnIndex = columnData.findIndex((column) => column.id === 'display_name');
  return (
    <>
      <div className={classes.cardDivRow}>
        <CardDialog
          title="Template Bundles"
          action={
            <div className={classes.buttonsContainer}>
              <Button color="primary" onClick={() => setAddNewBundleOpen(true)}>
                <CloudUploadIcon className={classes.leftButtonIcon} />
                Add Template Bundle
              </Button>
            </div>
          }
        >
          <SortableTable
            columns={columnData}
            defaultOrderColumn={displayNameColumnIndex}
            rows={documentTemplatesBundles}
            maxHeight="30vh"
            stickyHeader
          />
        </CardDialog>
      </div>
      {addNewBundleOpen && (
        <AddNewBundleDialog
          organizationId={organizationId}
          onSubmit={async () => {
            await onUpdate();
            setAddNewBundleOpen(false);
          }}
          documentTemplates={documentTemplates}
          templatesBundles={documentTemplatesBundles}
          onClose={() => setAddNewBundleOpen(false)}
        />
      )}
    </>
  );
}

DocumentTemplatesBundlesCard.propTypes = {
  organizationId: PropTypes.number.isRequired,
  documentTemplates: PropTypes.array.isRequired,
  documentTemplatesBundles: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

const getTemplateBundleValidationScheme = (documentTypesDict, forbiddenNames) => {
  return Yup.object().shape({
    display_name: Yup.string().required('Required').notOneOf(forbiddenNames, 'Display already exists'),
    coverages: Yup.array().min(1, 'At least one coverage required'),
    states: Yup.array().min(1, 'At least one state required'),
    comment: Yup.string().required('Required'),
    document_type: Yup.string().oneOf(Object.keys(documentTypesDict)).required('Required'),
  });
};

function AddNewBundleDialog({ organizationId, onClose, onSubmit, documentTemplates, templatesBundles }) {
  const classes = useStyles();
  const allExistingDisplayNames = documentTemplates
    .map((template) => template.display_name)
    .concat(templatesBundles ? templatesBundles.map((bundle) => bundle.display_name) : []);
  const {
    organizationOperationalDetails: { documentTypesDict },
  } = useSysconfig();

  const handleSubmitBundle = async (values, { setSubmitting }) => {
    try {
      await axios.post(`/api/v1/document_templates/organizations/${organizationId}/templates_bundles`, values);
      await onSubmit();
    } catch (error) {
      reportAxiosError(error);
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        file_size: '',
        filename: '',
        display_name: '',
        document_templates_ids: [''],
        coverages: [],
        states: [],
        comment: '',
        is_file_required: true,
        document_type: '',
      }}
      validationSchema={getTemplateBundleValidationScheme(documentTypesDict, allExistingDisplayNames)}
      onSubmit={handleSubmitBundle}
      enableReinitialize
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog
            isDialog
            maxWidth="xs"
            title="Add New Bundle"
            onClose={onClose}
            fullWidth
            preventClose={isSubmitting}
          >
            <DocumentTemplatesBundleFields documentTemplates={documentTemplates} />
            <div className={classes.buttonsContainer}>
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Create New
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

AddNewBundleDialog.propTypes = {
  organizationId: PropTypes.number.isRequired,
  documentTemplates: PropTypes.array.isRequired,
  templatesBundles: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

function UpdateDocumentTemplatesBundleHover(props) {
  const {
    documentTemplatesBundle,
    documentTemplates,
    displayIcon,
    organizationId,
    onUpdate,
    documentTemplatesBundles,
  } = props;
  const [openEditBundle, setOpenEditBundle] = useState(false);
  const classes = useStyles();
  const {
    organizationOperationalDetails: { documentTypesDict },
  } = useSysconfig();

  const handleDocumentSubmit = async (values, { setSubmitting }) => {
    try {
      await axios.put(
        `/api/v1/document_templates/organizations/${organizationId}/templates_bundles/${documentTemplatesBundle.id}`,
        values
      );
      onUpdate();
      setOpenEditBundle(false);
    } catch (error) {
      reportAxiosError(error);
      setSubmitting(false);
    }
  };

  const handleCancel = (resetForm) => {
    setOpenEditBundle(false);
    resetForm();
  };
  const forbiddenNames = documentTemplatesBundles
    .map((bundle) => bundle.display_name)
    .filter((display_name) => display_name !== documentTemplatesBundle.display_name)
    .concat(documentTemplates.map((template) => template.display_name));

  const documentTemplatesIds = documentTemplatesBundle.bundled_document_templates
    .sort((d1, d2) => d1.position - d2.position)
    .map((bundledTemplated) => bundledTemplated.document_template.id);
  return (
    <>
      <HoverActionField onAction={() => setOpenEditBundle(true)} permanent={displayIcon} />
      <Formik
        initialValues={{ ...documentTemplatesBundle, document_templates_ids: documentTemplatesIds, comment: '' }}
        validationSchema={getTemplateBundleValidationScheme(documentTypesDict, forbiddenNames)}
        onSubmit={handleDocumentSubmit}
        enableReinitialize
      >
        {(formikProps) => {
          const { isSubmitting, resetForm, handleSubmit } = formikProps;

          return (
            <CardDialog
              isDialog={true}
              open={openEditBundle}
              title="Update Document Templates Bundle"
              onClose={() => handleCancel(resetForm)}
              preventClose={isSubmitting}
            >
              <DocumentTemplatesBundleFields documentTemplates={documentTemplates} />
              <div className={classes.buttonsContainer}>
                <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                  Update
                </Button>
              </div>
            </CardDialog>
          );
        }}
      </Formik>
    </>
  );
}

UpdateDocumentTemplatesBundleHover.propTypes = {
  documentTemplatesBundle: PropTypes.object.isRequired,
  displayIcon: PropTypes.bool,
  organizationId: PropTypes.number.isRequired,
  onUpdate: PropTypes.func.isRequired,
  documentTemplatesBundles: PropTypes.array.isRequired,
  documentTemplates: PropTypes.array.isRequired,
};

function DocumentTemplatesBundleFields({ documentTemplates }) {
  const classes = useStyles();

  const { values, setFieldValue } = useFormikContext();
  const documentTemplateIdsList = values.document_templates_ids;
  const {
    organizationOperationalDetails: { documentTypesDict },
  } = useSysconfig();

  const getTemplateDisplayName = (templateId) => {
    if (!templateId) {
      return '';
    }

    return documentTemplates.find((template) => template.id === templateId).display_name;
  };

  const getFilteredTemplates = (_, value) => {
    return documentTemplates
      .filter((template) => template.display_name.toLowerCase().includes(value.inputValue.toLowerCase()))
      .map((template) => template.id);
  };

  const allDocumentTemplatesIds = documentTemplates.map((template) => template.id);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextFieldFormik id="display_name" label="Display Name" className={classes.textField} fullWidth />
      </Grid>
      {documentTemplateIdsList.map((documentTemplateEntry, idx) => (
        <React.Fragment key={documentTemplateEntry.document_template_id}>
          <Grid item xs={11}>
            <Autocomplete
              id="document_template_id"
              filterOptions={getFilteredTemplates}
              options={allDocumentTemplatesIds}
              getOptionLabel={getTemplateDisplayName}
              onChange={(_, newVal) => setFieldValue(`document_templates_ids[${idx}]`, newVal || '')}
              value={documentTemplateIdsList[idx] || null}
              renderInput={(params) => <TextField label={`Document template - ${idx + 1}`} {...params} fullWidth />}
            />
          </Grid>
          <Grid item xs={1}>
            {documentTemplateIdsList.length > 1 && (
              <IconButton
                onClick={() =>
                  setFieldValue(
                    'document_templates_ids',
                    documentTemplateIdsList.filter((_, currIdx) => currIdx !== idx)
                  )
                }
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Grid>
        </React.Fragment>
      ))}
      <Button
        onClick={() => setFieldValue('document_templates_ids', [...documentTemplateIdsList, ''])}
        disabled={documentTemplateIdsList[documentTemplateIdsList.length - 1] === ''}
      >
        <AddIcon />
        Add Document Template
      </Button>

      <Grid item xs={12}>
        <TextFieldFormik id="comment" label="Comment" className={classes.textField} fullWidth />
      </Grid>
      <Grid item xs={12}>
        <AutocompleteFormik
          id="document_type"
          label="Document Type"
          options={Object.keys(documentTypesDict)}
          getOptionLabel={(option) => documentTypesDict[option]['desc']}
          sortAlphabetic
        />
      </Grid>
      <Grid item xs={12}>
        <MultiSelectTextFieldFormik
          id="states"
          label="States"
          options={Object.keys(COUNTRY_TO_STATE_MAP['US']).filter((state) => !!state)} // remove empty string from the states list
          renderValue={(selected) => selected.join(', ')}
          className={classes.textField}
          fullWidth
          renderOption={(state) => COUNTRY_TO_STATE_MAP['US'][state]}
        />
      </Grid>
      <Grid item xs={12}>
        <MultiSelectTextFieldFormik
          id="coverages"
          label="Coverages"
          options={allCoveragesListWithGeneralKeys}
          renderValue={(selected) => selected.join(', ')}
          className={classes.textField}
          fullWidth
          renderOption={renderCoverageName}
        />
      </Grid>
    </Grid>
  );
}

DocumentTemplatesBundleFields.propTypes = {
  documentTemplates: PropTypes.array.isRequired,
};

function DeleteTemplateBundleHover(props) {
  const { documentTemplatesBundle, organizationId, displayIcon, onDelete } = props;
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [disableDelete, setDisableDelete] = useState(false);
  const classes = useStyles();

  const handleDelete = async () => {
    setDisableDelete(true);
    try {
      await axios.delete(
        `/api/v1/document_templates/organizations/${organizationId}/templates_bundles/${documentTemplatesBundle.id}`
      );
      await onDelete();
      setConfirmDelete(false);
      setDisableDelete(false);
    } catch (error) {
      reportAxiosError(error);
      setDisableDelete(false);
    }
  };

  return (
    <>
      <HoverActionField onAction={() => setConfirmDelete(true)} permanent={displayIcon} icon={DeleteIcon} />
      <CardDialog
        isDialog={true}
        open={confirmDelete}
        maxWidth="sm"
        title="Please confirm delete"
        onClose={() => setConfirmDelete(false)}
        preventClose={disableDelete}
      >
        <div className={classes.buttonsContainer}>
          <Button
            className={classes.leftButtonDialog}
            variant="contained"
            color="secondary"
            disabled={disableDelete}
            onClick={handleDelete}
          >
            Confirm delete!
          </Button>
        </div>
      </CardDialog>
    </>
  );
}

DeleteTemplateBundleHover.propTypes = {
  documentTemplatesBundle: PropTypes.object.isRequired,
  organizationId: PropTypes.number.isRequired,
  displayIcon: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default OrganizationDocumentsTemplates;
