import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import Grid from '~/components/core/Atomic/Grid/Grid';
import { FeatureDisabled, FeatureEnabled } from '~/components/core/FeatureFlagLayoutSwitch/FeatureFlagSwitch';

import { CONFIGURATION_FEATURES_NAMES, ROLES_TYPES_DICT } from '../../../../../Types';
import { isFeatureEnabled } from '../../../../../Utils';
import { MainCard } from '../../../../core';
import { useSysconfig } from '../../../SystemConfigurationScreen';

import { SuperUserCheckbox, SystemConfigCheckbox } from './PermissionsCheckboxes';
import { PermissionsProfileSelect } from './PermissionsProfileSelect';
import { RoleSelect } from './RoleSelect';
import { SubOrgsSelect } from './SubOrgsSelect';

export const UserPermissionsSection = ({
  roles,
  displaySuperUser,
  displaySystemConfig,
  overrideOrganizationValue,
  isWizardUser,
}) => {
  const { values } = useFormikContext();
  const { organization: sysConfigOrganization } = useSysconfig();

  const organization = overrideOrganizationValue ?? sysConfigOrganization;

  const subOrganizationEnabled = organization?.sub_organizations_enabled;
  const assignUserToSubOrgFeatureEnabled =
    organization && isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.ASSIGN_USER_TO_SUB_ORG);

  const allRoles = roles.filter((role) => !ROLES_TYPES_DICT[role.role_type].is_hidden);

  const filteredRoles =
    assignUserToSubOrgFeatureEnabled || !values.is_part_of_sub_organization
      ? allRoles
      : allRoles.filter((role) => role.role_type === 'read_only');

  return (
    <>
      <FeatureDisabled featureFlag={CONFIGURATION_FEATURES_NAMES.PERMISSIONS_ENFORCEMENT} organization={organization}>
        {subOrganizationEnabled && (
          <SubOrgsSelect roles={roles} organization={organization} isWizardUser={isWizardUser} />
        )}
        <RoleSelect filteredRoles={filteredRoles} />
      </FeatureDisabled>
      <FeatureEnabled featureFlag={CONFIGURATION_FEATURES_NAMES.PERMISSIONS_ENFORCEMENT} organization={organization}>
        <MainCard title="Set Permissions" type="contained">
          <Grid container spacing={2} direction="column">
            <RoleSelect filteredRoles={filteredRoles} fullWidth />
            <PermissionsProfileSelect useDefaults={isWizardUser} />
            {subOrganizationEnabled && (
              <SubOrgsSelect roles={roles} organization={organization} isWizardUser={isWizardUser} />
            )}
            <FeatureDisabled
              featureFlag={CONFIGURATION_FEATURES_NAMES.PERMISSIONS_ENFORCEMENT}
              organization={organization}
            >
              {displaySystemConfig && (
                <div className="w-full">
                  <SystemConfigCheckbox disabled={!displaySystemConfig} />
                </div>
              )}
            </FeatureDisabled>
            {displaySuperUser && (
              <Grid item xs={12}>
                <SuperUserCheckbox />
              </Grid>
            )}
          </Grid>
        </MainCard>
      </FeatureEnabled>
    </>
  );
};

UserPermissionsSection.propTypes = {
  roles: PropTypes.array.isRequired,
  displaySuperUser: PropTypes.bool,
  displaySystemConfig: PropTypes.bool,
  overrideOrganizationValue: PropTypes.object,
  isWizardUser: PropTypes.bool,
};
