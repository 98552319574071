import React from 'react';
import PropTypes from 'prop-types';

import Grid from '~/components/core/Atomic/Grid/Grid';
import CollapsibleWrapper from '~/components/core/Collapsible/CollapsibleWrapper';

import CardDialog from '../../../../CardDialog';
import HiddenIndication from '../HiddenIndication/HiddenIndication';

const BaseConfigurationSection = ({
  title,
  subtitle,
  isHidden = false,
  children,
  noGridContainer = false,
  disabled = false,
  actions = undefined,
  isSection = false,
  hideActionsOnCollapse = false,
  isCollapsible = true,
  withActionsContainerFullWidth = true,
}) => {
  return (
    <CollapsibleWrapper
      title={title}
      subtitle={subtitle}
      backgroundColor={CollapsibleWrapper.BACKGROUND_COLOR.WHITE}
      actionCard
      noBorderLine
      noDivider
      defaultState={false}
      actions={actions ? actions : <HiddenIndication isActive={!isHidden} />}
      disabled={disabled}
      isCardShadow={isSection}
      hideActionsOnCollapse={hideActionsOnCollapse}
      isCollapsible={isCollapsible}
      withActionsContainerFullWidth={withActionsContainerFullWidth}
    >
      <CardDialog noCardTitle dontTrack>
        {noGridContainer ? (
          <>{children}</>
        ) : (
          <Grid container spacing={3}>
            {children}
          </Grid>
        )}
      </CardDialog>
    </CollapsibleWrapper>
  );
};
BaseConfigurationSection.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isHidden: PropTypes.bool,
  children: PropTypes.node,
  noGridContainer: PropTypes.bool,
  disabled: PropTypes.bool,
  actions: PropTypes.node,
  isSection: PropTypes.bool,
  hideActionsOnCollapse: PropTypes.bool,
  isCollapsible: PropTypes.bool,
  withActionsContainerFullWidth: PropTypes.bool,
};

export default BaseConfigurationSection;
