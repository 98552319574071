import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Switch } from '@material-ui/core';
import axios from 'axios';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import WEBHOOK_CATEGORIES from '~/server_shared/generated-types/WEBHOOK_CATEGORIES';

import { reportAxiosError } from '../../../../../../Utils';
import CardDialog from '../../../../../CardDialog';
import { Heading, LoadingSwitch } from '../../../../../core';
import useDataFetcher from '../../../../../useDataFetcher';
import { useSysconfig } from '../../../../SystemConfigurationScreen';

import RetryNotification from './RetryNotification';
import WebhookEventCategory from './WebhookEventCategory';
import WebhooksClientSecret from './WebhooksClientSecret';

import { useStyles } from '../../../../../../assets/styles';
import styles from './EventSettings.module.scss';

const getWebhookEventCategory = (webhookEventCategories, category) =>
  webhookEventCategories.filter((webhookEventCategory) => webhookEventCategory.category === category)[0];

const EventSettings = () => {
  const { organization, isLoading: isOrganizationLoading, isError: isOrganizationError } = useSysconfig();
  const [isCreatingClient, setCreatingClient] = useState(false);

  const {
    isLoading: isClientLoading,
    isError: isClientError,
    data: client,
    reloadData: reloadClient,
  } = useDataFetcher(`/api/v1/claim_events_webhook/organizations/${organization.id}/client`);

  const {
    isLoading: isEventsLoading,
    isError: isEventsError,
    data: eventsData,
  } = useDataFetcher('/api/v1/claim_events_webhook/events');

  const {
    isLoading: isWebhookCategoriesLoading,
    isError: isWebhookCategoriesError,
    data: webhookEventCategories,
    reloadData: reloadCategories,
  } = useDataFetcher(`/api/v1/claim_events_webhook/organizations/${organization.id}/event_categories`);

  const upsertWebhookCategories = async (data, category) => {
    try {
      await axios(`/api/v1/claim_events_webhook/organizations/${organization.id}/event_categories/${category}`, {
        method: getWebhookEventCategory(webhookEventCategories, category) ? 'patch' : 'post',
        data,
      });
    } catch (e) {
      await reportAxiosError(e);
      throw e;
    }
    await reloadCategories();
  };

  const createClient = async () => {
    if (!areEventsEnabledForOrg) {
      setCreatingClient(true);
    }

    try {
      await axios.post(`/api/v1/claim_events_webhook/organizations/${organization.id}/client`, {});
    } catch (e) {
      await reportAxiosError(e);
      setCreatingClient(false);
      throw e;
    }

    await reloadClient();
    setCreatingClient(false);
  };

  const areEventsEnabledForOrg = client?.id;
  const isOuterDataLoading = [isOrganizationLoading, isClientLoading].some(Boolean);
  const isOuterDataError = [isOrganizationError, isClientError].some(Boolean);
  const isInnerDataLoading = [isCreatingClient, isEventsLoading, isWebhookCategoriesLoading].some(Boolean);
  const isInnerDataError = [isEventsError, isWebhookCategoriesError].some(Boolean);

  return (
    <CardDialog noCardTitle>
      <Grid container spacing={4} direction="column" xs={12} className={styles.mainContainer}>
        <LoadingSwitch isLoading={isOuterDataLoading} isError={isOuterDataError}>
          {!areEventsEnabledForOrg ? (
            <Grid item xs={6} className={styles.enableEventsWrapper}>
              <Button
                color="primary"
                variant="contained"
                className={styles.button}
                onClick={createClient}
                disabled={isCreatingClient}
              >
                Enable Events
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <LoadingSwitch isLoading={isInnerDataLoading} isError={isInnerDataError}>
                {areEventsEnabledForOrg ? (
                  <EventsSettingsInner
                    client={client}
                    reloadClient={reloadClient}
                    eventsData={eventsData}
                    onUpdate={upsertWebhookCategories}
                    webhookEventCategories={webhookEventCategories}
                  />
                ) : null}
              </LoadingSwitch>
            </Grid>
          )}
        </LoadingSwitch>
      </Grid>
    </CardDialog>
  );
};

const EventsSettingsInner = ({ client, eventsData, onUpdate, webhookEventCategories, reloadClient }) => {
  const classes = useStyles();
  const { organization } = useSysconfig();

  const patchClient = async (values) => {
    try {
      await axios.patch(`/api/v1/claim_events_webhook/organizations/${organization.id}/client`, values);
      await reloadClient();
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
  };

  const handleIsActiveChanged = async () => {
    await patchClient({ is_active: !client.is_active });
  };

  return (
    <>
      <Grid className={styles.clientSecret} container spacing={6} direction="column" wrap="nowrap">
        <Grid item xs={12} style={{ paddingLeft: 0 }} className={styles.activeSwitchWrapper}>
          <Heading variant={Heading.TYPES.H3}>
            <Switch onClick={handleIsActiveChanged} checked={client.is_active} className={classes.formsSwitch} />
            Enabled
          </Heading>
        </Grid>
        <Grid item xs={12}>
          <RetryNotification client={client} onSubmit={patchClient} />
        </Grid>
        <Grid item xs={12}>
          <WebhooksClientSecret />
        </Grid>
        {webhookEventCategories &&
          Object.entries(WEBHOOK_CATEGORIES).map(([category, description]) => (
            <Grid item xs={12} key={category}>
              <WebhookEventCategory
                heading={`${description} Events`}
                eventsData={eventsData}
                category={category}
                onUpdate={onUpdate}
                webhookEventCategory={getWebhookEventCategory(webhookEventCategories, category)}
              />
            </Grid>
          ))}
      </Grid>
    </>
  );
};

EventsSettingsInner.propTypes = {
  client: PropTypes.object.isRequired,
  reloadClient: PropTypes.func.isRequired,
  eventsData: PropTypes.array.isRequired,
  onUpdate: PropTypes.func,
  webhookEventCategories: PropTypes.object,
};

export default EventSettings;
