import React from 'react';

import SvgIcon from '../core/SvgIcon';

const UnlockedIcon = (props) => (
  <SvgIcon viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M19.5 9.25H4.5C4.08579 9.25 3.75 9.58579 3.75 10V20.5C3.75 20.9142 4.08579 21.25 4.5 21.25H19.5C19.9142 21.25 20.25 20.9142 20.25 20.5V10C20.25 9.58579 19.9142 9.25 19.5 9.25Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.625 9.25V5.875C8.625 4.97989 8.98058 4.12145 9.61351 3.48851C10.2465 2.85558 11.1049 2.5 12 2.5C12.8951 2.5 13.7535 2.85558 14.3865 3.48851C15.0194 4.12145 15.375 4.97989 15.375 5.875"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default UnlockedIcon;
