import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';

import WithConfirm from '~/components/ConfirmModal';
import Button from '~/components/core/Atomic/Buttons/Button';
import CancelButton from '~/components/core/Buttons/CancelButton';

import LoadingDialog from '../../../LoadingDialog';

import copies from './copies.json';

const FnolConfigurationFooter = ({ onCancel }) => {
  const { handleSubmit, isSubmitting, touched } = useFormikContext();

  return (
    <>
      <div className="h-80 fixed bottom-0 left-0 w-full bg-white shadow-xl">
        <div className="mx-20 flex h-full items-stretch justify-center">
          <div>
            <WithConfirm
              title={copies.exit_confirm.title}
              contentText={copies.exit_confirm.text}
              primaryButtonName="YES, LEAVE"
              shouldCloseOnPrimary
              centerDialog
              disableConfirm={isEmpty(touched)}
            >
              <CancelButton onClick={onCancel} disabled={isSubmitting} classNameOverride="my-20" />
            </WithConfirm>
            <Button
              disabled={isSubmitting}
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              className="my-20"
            >
              SAVE
            </Button>
          </div>
        </div>
      </div>
      {isSubmitting && <LoadingDialog text="Saving FNOL Config..." isError={false} track="Save FNOL Config" />}
    </>
  );
};

FnolConfigurationFooter.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default FnolConfigurationFooter;
