import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import useDataFetcher from '../useDataFetcher';

import { useSysconfig } from './SystemConfigurationScreen';

const AssignmentsContext = React.createContext({});

const AssignmentsContextProvider = ({ children }) => {
  const { organization } = useSysconfig();

  const {
    isLoading: areDefaultsLoading,
    isError: isDefaultsError,
    data: assignmentsConfigurations,
    reloadData: reloadDefaults,
  } = useDataFetcher(`/api/v1/assignments/${organization.id}/defaults`);

  const {
    isLoading: isEnabledLoading,
    isError: isEnabledError,
    data: isEnabled,
    reloadData: reloadEnabled,
  } = useDataFetcher(`/api/v1/assignments/${organization.id}/enabled`);

  const {
    isLoading: areAdjustersLoading,
    isError: isAdjustersError,
    data: adjusters,
  } = useDataFetcher(`/api/v1/assignments/${organization.id}/adjusters`);

  const adjustersDict = _.keyBy(adjusters, 'id');

  const contextValues = {
    isLoading: areDefaultsLoading || isEnabledLoading || areAdjustersLoading,
    isError: isDefaultsError || isEnabledError || isAdjustersError,
    areAssignmentsEnabled: isEnabled,
    assignmentsConfigurations,
    reloadDefaults,
    reloadEnabled,
    adjusters,
    adjustersDict,
    isLicenseCorrelationEnabled: assignmentsConfigurations?.is_license_correlation_enabled,
    defaultAdjusterName: adjustersDict[assignmentsConfigurations?.fallback_user_id]?.username,
  };

  return <AssignmentsContext.Provider value={contextValues}>{children}</AssignmentsContext.Provider>;
};

AssignmentsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const withAssignments = (WrappedComponent) => {
  class withAssignments extends React.Component {
    render() {
      return (
        <AssignmentsContext.Consumer>
          {(assignmentsContext) => <WrappedComponent {...assignmentsContext} {...this.props} />}
        </AssignmentsContext.Consumer>
      );
    }
  }

  withAssignments.displayName = `withAssignments(${getDisplayName(WrappedComponent)})`;
  return withAssignments;
};

const getDisplayName = (WrappedComponent) => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};

const useAssignments = () => {
  return useContext(AssignmentsContext);
};

export default useAssignments;
export { AssignmentsContextProvider, withAssignments };
