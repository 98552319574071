import React from 'react';

import CardDialog from '~/components/CardDialog';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import SkeletonTable from '~/components/core/Skeletons/SkeletonTable';
import SortableTable from '~/components/core/Tables/SortableTable';
import useCurrencyFormatter from '~/components/CurrencyFormatterContext';
import type { StatReserveConfiguration } from '~/components/SystemConfiguration/ClaimConfiguration/StatReserveConfiguration/StatReserveConfigurationPage';

import PencilIcon from '../../../icons/PencilIcon';

export interface StatReserveConfigurationTableProps {
  statReserveConfigurations?: StatReserveConfiguration[];
  isLoading: boolean;
  isError: boolean;
}
const StatReserveConfigurationTable: React.FC<StatReserveConfigurationTableProps> = ({
  statReserveConfigurations,
  isError,
  isLoading,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { currencyFormatter } = useCurrencyFormatter();

  const handleEditClick = (row: StatReserveConfiguration) => {
    console.log('Edit clicked', row.coverage_key);
    // https://fivesigma.atlassian.net/browse/NGTPA-16463
  };

  const columnData = [
    {
      id: coverageDisplayNameKey,
      label: StatReserveConfigurationTableColumns.coverage,
      numeric: false,
    },
    {
      id: StatReserveConfigurationTableColumns.defaultIndemnityReserve,
      label: StatReserveConfigurationTableColumns.defaultIndemnityReserve,
      numeric: false,
      specialCell: (row: StatReserveConfiguration) => currencyFormatter.format(row.indemnity_reserve_amount),
    },
    {
      id: StatReserveConfigurationTableColumns.defaultExpenseReserve,
      label: StatReserveConfigurationTableColumns.defaultExpenseReserve,
      numeric: false,
      specialCell: (row: StatReserveConfiguration) => currencyFormatter.format(row.expenses_reserve_amount),
    },
    {
      id: StatReserveConfigurationTableColumns.edit,
      label: '',
      numeric: false,
      specialCell: (row: StatReserveConfiguration) => {
        return (
          <IconButton onClick={() => handleEditClick(row)} className="p-12 text-slate-900 hover:text-teal-700">
            <PencilIcon iconColor="currentColor" />
          </IconButton>
        );
      },
    },
  ];

  return (
    <div>
      <CardDialog title="Coverages">
        {isLoading || isError || !statReserveConfigurations ? (
          <SkeletonTable columnsCount={4} rowsCount={7} isError={isError} />
        ) : (
          <SortableTable columns={columnData} rows={statReserveConfigurations} defaultOrderColumn={0} />
        )}
      </CardDialog>
    </div>
  );
};

const coverageDisplayNameKey: keyof StatReserveConfiguration = 'coverage_display_name';
enum StatReserveConfigurationTableColumns {
  coverage = 'Coverage',
  defaultIndemnityReserve = 'Default Indemnity Reserve',
  defaultExpenseReserve = 'Default Expense Reserve',
  states = 'States',
  subOrganizations = 'Sub-Organizations',
  edit = 'Edit',
}
export default StatReserveConfigurationTable;
