import React, { useMemo, useState } from 'react';
import axios from 'axios';
import { intersection, isEmpty } from 'lodash';

import { useStyles } from '~/assets/styles';
import Button from '~/components/core/Atomic/Buttons/Button';
import Checkbox from '~/components/core/Atomic/Checkboxes/Checkbox';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Switch from '~/components/core/Atomic/Switch';
import TextField from '~/components/core/Molecules/Fields/TextField/TextField';
import SimpleSearchField from '~/components/core/SimpleSearchField';
import ThreeDotsMenu from '~/components/core/ThreeDotsMenu';
import LobCell from '~/components/SystemConfiguration/AutomaticRulesConfiguration/Table/LobCell';
import { LobMultiSelect } from '~/components/TPA/LOB/LobMultiselect';
import SubOrganizationMultiSelect from '~/components/TPA/SubOrganizations/SubOrganizationMultiSelect';
import COMMUNICATION_TEMPLATE_ACTION from '~/server_shared/generated-types/COMMUNICATION_TEMPLATE_ACTION';
import { reportAxiosError, subOrgIdToNameDict } from '~/Utils';

import CardDialog from '../../../CardDialog';
import { Heading, SortableTable } from '../../../core';
import PlusIcon from '../../../icons/PlusIcon';
import LoadingIndicator from '../../../LoadingIndicator';
import useDataFetcher from '../../../useDataFetcher';
import { useSysconfig } from '../../SystemConfigurationScreen';
import ChipsCell from '../Table/ChipsCell';

import AddOrEditRuleDialog from './AddOrEditRuleDialog';

const AutomaticCommunications = () => {
  const { organization } = useSysconfig();
  const classes = useStyles();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isRuleDialogOpen, setIsRuleDialogOpen] = useState(false);
  const [filteredParams, setFilteredParams] = useState({ subOrgIds: [], lobs: [], channels: [], searchTerm: '' });

  const {
    isLoading: isLoadingRules,
    isError: isErrorRules,
    data: rules,
    reloadData: reloadRules,
  } = useDataFetcher(`/api/v1/automatic_communications/${organization.id}/rules`);

  const {
    isLoading: isLoadingEvents,
    isError: isErrorEvents,
    data: supportedEvents,
  } = useDataFetcher(`/api/v1/automatic_communications/${organization.id}/supported_events`);

  const isLoading = isLoadingRules || isLoadingEvents;
  const isError = isErrorRules || isErrorEvents;

  const handleSubOrgChanged = (subOrgIds) => {
    setFilteredParams((params) => ({ ...params, subOrgIds }));
  };

  const handleLobsChanged = (lobs) => {
    setFilteredParams((params) => ({ ...params, lobs }));
  };

  const handleChannelChanged = ({ target: { value: channels } }) => {
    setFilteredParams((params) => ({ ...params, channels }));
  };

  const handleSearchTermChanged = (searchTerm) => {
    setFilteredParams((params) => ({ ...params, searchTerm }));
  };

  const handleSubmitRule = async (values) => {
    await callAPI('post', `/api/v1/automatic_communications/${organization.id}/rules`, values);
  };

  const handleCloseRuleDialog = () => {
    setIsRuleDialogOpen(false);
  };

  const handAddRuleClicked = () => {
    setIsRuleDialogOpen(true);
  };

  const filteredRules = useMemo(() => {
    let filteredRules = rules || [];

    if (rules) {
      if (!isEmpty(filteredParams.subOrgIds)) {
        filteredRules = filteredRules.filter(
          (rule) => rule.is_all_sub_orgs || !isEmpty(intersection(rule.sub_organization_ids, filteredParams.subOrgIds))
        );
      }

      if (!isEmpty(filteredParams.lobs)) {
        filteredRules = filteredRules.filter((rule) => filteredParams.lobs.includes(rule.lob));
      }

      if (!isEmpty(filteredParams.channels)) {
        filteredRules = filteredRules.filter((rule) => filteredParams.channels.includes(rule.channel));
      }

      if (filteredParams.searchTerm) {
        filteredRules = filteredRules.filter((rule) =>
          rule.display_name.toLowerCase().includes(filteredParams.searchTerm.toLowerCase())
        );
      }
    }

    return filteredRules;
  }, [filteredParams.lobs, filteredParams.searchTerm, filteredParams.subOrgIds, filteredParams.channels, rules]);

  const callAPI = async (method, url, values) => {
    try {
      setIsProcessing(true);
      await axios({ method, url, data: values });
      await reloadRules();
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    } finally {
      setIsProcessing(false);
    }
  };

  const handleDeleteRule = async (rule) => {
    await callAPI('delete', `/api/v1/automatic_communications/${organization.id}/rules/${rule.id}`);
  };

  const handleEnabledChanged = async (rule) => {
    await callAPI('post', `/api/v1/automatic_communications/${organization.id}/rules/${rule.id}/enable`, {
      is_enabled: !rule.is_enabled,
    });
  };

  const actionOptions = (rule) => [
    {
      onClick: () => handleDeleteRule(rule),
      withConfirmProps: {
        title: 'Delete Rule?',
        contentText: `Are you sure you want to delete "${rule.display_name}"?`,
        primaryButtonName: 'Delete',
      },
      node: 'Delete',
      key: 'deleteRule',
    },
  ];

  const enabledCell = (rule) => (
    <Switch
      onChange={() => handleEnabledChanged(rule)}
      size="small"
      disabled={isProcessing}
      checked={rule.is_enabled}
    />
  );

  const actionsCell = (rule) => (
    <div>
      <ThreeDotsMenu options={actionOptions(rule)} />
    </div>
  );

  const columns = [
    { id: 'display_name', label: 'Rule Name' },
    { id: 'lob', label: 'LOB', specialCell: (rule) => <LobCell lob={rule.lob} /> },
    { id: 'context', label: 'Level' },
    {
      id: 'conditions_summary',
      label: 'Conditions Summary',
      specialCell: (rule) => <ChipsCell values={rule.conditions_summary} />,
    },
    {
      id: 'recipient',
      label: 'Recipient',
      specialCell: (rule) => COMMUNICATION_TEMPLATE_ACTION.RECIPIENT[rule.recipient],
    },
    {
      id: 'enable',
      numeric: false,
      label: 'Enable',
      specialCell: enabledCell,
      disableSort: true,
    },
    {
      id: 'actions',
      label: 'Actions',
      width: '90px',
      specialCell: actionsCell,
    },
  ];

  if (organization.sub_organizations_enabled) {
    columns.splice(1, 0, {
      id: 'sub_organization_ids',
      label: 'Sub Organizations',
      specialCell: (rule) => {
        let res;
        const subOrgIdToName = subOrgIdToNameDict(organization);
        if (rule.is_all_sub_orgs) {
          res = Object.values(subOrgIdToName);
        } else {
          res = rule.sub_organization_ids.map((id) => subOrgIdToName[id]);
        }

        return res.join(', ');
      },
    });
  }

  if (isLoading || isError) {
    return <LoadingIndicator isError={isError} />;
  }

  const onSubmit = async (values) => {
    await handleSubmitRule(values);
    handleCloseRuleDialog();
  };

  return (
    <CardDialog noCardTitle>
      <Grid container className="p-20">
        <Grid item xs={12} className="pb-12">
          <Heading variant={Heading.TYPES.H3}>Communications Rules</Heading>
        </Grid>
        <Grid item xs={12} className="inline-flex justify-end">
          <Button
            color="primary"
            onClick={handAddRuleClicked}
            startIcon={<PlusIcon className="text-teal-800" size={14} iconColor="currentColor" />}
          >
            Add Rule
          </Button>
        </Grid>
        <Grid container spacing={2} className="inline-flex items-baseline pb-40">
          <Grid item xs={3} className="inline-flex">
            <SimpleSearchField
              label="Search Rule"
              placeholder="Search"
              onChange={handleSearchTermChanged}
              defaultValue=""
            />
          </Grid>
          {organization.sub_organizations_enabled && (
            <Grid item xs={3} className="inline-flex">
              <SubOrganizationMultiSelect onChange={handleSubOrgChanged} />
            </Grid>
          )}
          <Grid item xs={3} className="inline-flex">
            <LobMultiSelect subOrganizationIds={filteredParams.subOrgIds} onChange={handleLobsChanged} />
          </Grid>
          <Grid item xs={3} className="inline-flex">
            <TextField
              value={filteredParams.channels}
              label="Types"
              className={classes.textField}
              fullWidth
              select
              onChange={handleChannelChanged}
              SelectProps={{
                multiple: true,
                renderValue: (values) => values.map((value) => COMMUNICATION_TEMPLATE_ACTION.CHANNEL[value]).join(', '),
              }}
            >
              {Object.keys(COMMUNICATION_TEMPLATE_ACTION.CHANNEL).map((channelKey) => (
                <MenuItem key={channelKey} value={channelKey}>
                  <Checkbox checked={filteredParams.channels.includes(channelKey)} />
                  {COMMUNICATION_TEMPLATE_ACTION.CHANNEL[channelKey]}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SortableTable columns={columns} rows={filteredRules} disableSortByUser keepRowsOrder />
        </Grid>
      </Grid>
      {isRuleDialogOpen ? (
        <AddOrEditRuleDialog supportedEvents={supportedEvents} onCancel={handleCloseRuleDialog} onSubmit={onSubmit} />
      ) : null}
    </CardDialog>
  );
};

export default AutomaticCommunications;
