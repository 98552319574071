import PropTypes from 'prop-types';

const permissionVerbEnumMemberProptype = PropTypes.shape({
  id: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  hierarchyIndex: PropTypes.number.isRequired,
  optionValue: PropTypes.array.isRequired,
});

const preconditionShape = {
  field: PropTypes.object.isRequired,
  needsToBeAtLeast: permissionVerbEnumMemberProptype,
  whenVerbIsEqualOrGreaterThan: permissionVerbEnumMemberProptype,
  errorMessage: PropTypes.string.isRequired,
};

const innerSectionProptype = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
});

const actionsEnumMemberBaseShape = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  allowedVerbs: PropTypes.arrayOf(PropTypes.string),
  isSectionHeader: PropTypes.bool,
  preconditions: PropTypes.arrayOf(PropTypes.shape(preconditionShape)),
};

const sectionHeaderProptype = PropTypes.shape({
  ...actionsEnumMemberBaseShape,
  sectionHeaderIndex: PropTypes.number.isRequired,
  innerSections: PropTypes.object,
});

const innerSectionItemProptype = PropTypes.shape({
  ...actionsEnumMemberBaseShape,
  section: sectionHeaderProptype.isRequired,
  innerSection: innerSectionProptype.isRequired,
  index: PropTypes.number.isRequired,
});

export { innerSectionItemProptype, sectionHeaderProptype };
