import React from 'react';
import { isEmpty } from 'lodash';

import Chip from '~/components/core/Atomic/Chip/Chip';

const ChipsCell: React.FC<{ values: string }> = ({ values }) => {
  if (isEmpty(values)) {
    return null;
  }

  return (
    <div>
      {values.split(',').map((value) => (
        <Chip key={value} size="small" label={value} />
      ))}
    </div>
  );
};

export default ChipsCell;
