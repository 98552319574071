import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import Grid from '~/components/core/Atomic/Grid/Grid';

import { getAllOrganizationWideRoles } from '../../communications/ContactUtils';
import { ContactEntity } from '../../Contact';
import ContactTextFieldFormik from '../../ContactTextFieldFormik';
import TextFieldFormik from '../../TextFieldFormik';
import { useSysconfig } from '../SystemConfigurationScreen';

import OrganizationEntityConfiguration from './OrganizationEntityConfiguration';

import { useStyles } from '../../../assets/styles';

const SpecialContactsContainer = ({
  generateFetchUrl,
  generateDeleteUrl = generateFetchUrl,
  entityLabelSingular,
  entityLabelPlural,
  editable = false,
}) => {
  const classes = useStyles();

  const { organization, organizationOperationalDetails } = useSysconfig();

  const getExtractedData = (values) => ({ special_role: values.special_role, contact_id: values.contact_id });

  const columnData = [
    { id: 'special_role', numeric: false, label: 'Role' },
    {
      id: 'contact',
      numeric: false,
      label: 'User',
      specialCell: (specialContact) => (
        <ContactEntity contactDisplayName={specialContact.contact.full_name} contactId={specialContact.contact.id} />
      ),
    },
  ];

  const getEntityInitialValues = (specialContact) => ({
    special_role: specialContact?.special_role || '',
    contact_id: specialContact?.contact.id || '',
  });

  const getValidationSchema = (_) => ({
    special_role: Yup.string().required('Required'),
    contact_id: Yup.string().required('Required'),
  });

  const getEditInputs = (specialContact) => (
    <>
      <Grid item xs={12}>
        <TextFieldFormik
          id="special_role"
          label="Role"
          className={classes.textField}
          fullWidth
          disabled={specialContact}
        />
      </Grid>
      <Grid item xs={12}>
        <ContactTextFieldFormik
          id="contact"
          label="contact"
          className={classes.textField}
          acceptedRoles={getAllOrganizationWideRoles(organizationOperationalDetails?.organizationContactRolesDict)}
          overrideOfContactsContextSearchOrganizationId={organization.id}
          outOfContactsContextSearch
          fixedSearchResults
          fullWidth
        />
      </Grid>
    </>
  );

  return (
    <OrganizationEntityConfiguration
      columnData={columnData}
      generateFetchUrl={generateFetchUrl}
      generateDeleteUrl={generateDeleteUrl}
      editable={editable}
      entityLabelSingular={entityLabelSingular}
      entityLabelPlural={entityLabelPlural}
      getEditInputs={getEditInputs}
      getEntityInitialValues={getEntityInitialValues}
      getExtractedData={getExtractedData}
      getValidationSchema={getValidationSchema}
    />
  );
};

SpecialContactsContainer.propTypes = {
  generateFetchUrl: PropTypes.func.isRequired,
  generateDeleteUrl: PropTypes.func,
  entityLabelSingular: PropTypes.string.isRequired,
  entityLabelPlural: PropTypes.string.isRequired,
  editable: PropTypes.bool,
};

export default SpecialContactsContainer;
