import React from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import { forEach, isEmpty, isObject } from 'lodash';
import * as Yup from 'yup';

import { FIELD_IDS } from '..';

import CauseLossCodes from './CauseLossCodes';
import InitialNotification from './InitialNotification';
import InitialTriggers from './InitialTriggers';
import UpdateTriggers from './UpdateTriggers';

const STEP_IDS = {
  INITIAL_TRIGGERS: 'initial_report_triggers',
  INITIAL_NOTIFICATION: 'initial_notification',
  SEARCH_TRIGGERS: 'search_triggers',
  ISO_CODES_CONFIGURATION: 'col_iso_codes_configuration',
};

const steps = [
  {
    title: 'Configure initial report triggers',
    id: STEP_IDS.INITIAL_TRIGGERS,
    dialogContentComponent: <InitialTriggers />,
    validationSchema: Yup.object(),
    isChecked: (values) => !!getIn(values, FIELD_IDS.INITIAL_REPORTS.METHOD),
    displayAfterStep: ({ configuration }) => (configuration?.trigger_method ? null : STEP_IDS.INITIAL_NOTIFICATION),
  },
  {
    title: 'Configure update triggers',
    subtitle: 'Update will initiate new search in ClaimSearch',
    id: STEP_IDS.SEARCH_TRIGGERS,
    dialogContentComponent: <UpdateTriggers />,
    validationSchema: Yup.object().shape({
      update_reports: Yup.object().shape({
        schedule_period: Yup.string().nullable(),
        schedule_time_frame: Yup.string()
          .when(['schedule_period'], {
            is: (schedule_period) => schedule_period !== null,
            then: Yup.string().required('Required').nullable(),
          })
          .nullable(),
        schedule_time: Yup.number()
          .when(['schedule_period'], {
            is: (schedule_period) => schedule_period !== null,
            then: Yup.number()
              .required('Required')
              .min(1, 'The time must be a positive number')
              .max(1000, 'Please select a smaller time period')
              .nullable(),
          })
          .nullable(),
      }),
    }),
    isChecked: (values) => {
      const searchTriggers = getIn(values, FIELD_IDS.UPDATE_REPORTS.SEARCH_TRIGGERS) || {};

      const value =
        (getIn(values, FIELD_IDS.UPDATE_REPORTS.SCHEDULE_PERIOD) &&
          getIn(values, FIELD_IDS.UPDATE_REPORTS.SCHEDULE_TIME) &&
          getIn(values, FIELD_IDS.UPDATE_REPORTS.SCHEDULE_TIME_FRAME)) ||
        // if any of the search triggers exist then should be checked
        Object.values(searchTriggers).some((value) => !!value);
      return !!value;
    },
  },
  {
    title: 'Configure Cause of Loss ISO codes',
    subtitle: 'Configure the CoL ISO codes for your organization',
    id: STEP_IDS.ISO_CODES_CONFIGURATION,
    dialogContentComponent: <CauseLossCodes />,
    initialValues: {},
    validationSchema: Yup.object(),
    isChecked: (values) => {
      const causeOfLoss = getIn(values, FIELD_IDS.CAUSE_LOSS_CODES.BASE_ID, {});
      const isoValues = getLeafNodes(causeOfLoss);

      return !!isoValues.find((value) => isEmpty(value));
    },
  },
  {
    title: 'Would you like to set a notification?',
    subtitle: 'You can always go later to the notification builder and set up a notification there.',
    id: STEP_IDS.INITIAL_NOTIFICATION,
    hideCheckBanner: true,
    dialogContentComponent: <InitialNotification />,
    validationSchema: Yup.object().shape({
      initial_reports: Yup.object().shape({
        notification: Yup.object().shape({
          days: Yup.number()
            .when('selected', {
              is: true,
              then: Yup.number()
                .nullable()
                .required('Required')
                .min(1, 'Please enter duration long than 1')
                .max(1000, 'Please enter smaller duration'),
            })
            .nullable(),
        }),
      }),
    }),
    isChecked: () => true,
  },
];

const getLeafNodes = (obj) => {
  let leafNodes = [];

  forEach(obj, function (value) {
    if (isObject(value)) {
      leafNodes = leafNodes.concat(getLeafNodes(value));
    } else {
      leafNodes.push(value);
    }
  });

  return leafNodes;
};

export default steps;

export const stepPropTypesShape = PropTypes.shape({
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  dialogContentComponent: PropTypes.node.isRequired,
  validationSchema: PropTypes.object.isRequired,
  isChecked: PropTypes.func.isRequired,
  displayAfterStep: PropTypes.func,
  hideCheckBanner: PropTypes.bool,
});
