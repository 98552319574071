import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as Yup from 'yup';

import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';

import TextFieldFormik from '../../TextFieldFormik';
import { useSysconfig } from '../SystemConfigurationScreen';

import OrganizationEntityConfiguration from './OrganizationEntityConfiguration';

import { useStyles } from '../../../assets/styles';

const SpecialUsersContainer = ({
  generateFetchUrl,
  generateDeleteUrl = generateFetchUrl,
  entityLabelSingular,
  entityLabelPlural,
  editable = false,
}) => {
  const classes = useStyles();
  const { users } = useSysconfig();
  const sortedUsers = _.orderBy(users, ['username']);

  const getExtractedData = (values) => ({
    special_role: values.special_role,
    user_id: values.user_id || values.user?.id,
  });

  const columnData = [
    { id: 'special_role', numeric: false, label: 'Role' },
    { id: 'user', numeric: false, label: 'User', specialCell: (specialUser) => specialUser.user.username },
  ];

  const getEntityInitialValues = (specialUser) => ({
    special_role: specialUser ? specialUser.special_role : '',
    user_id: specialUser ? specialUser.user.id : '',
  });

  const getValidationSchema = (_) => ({
    special_role: Yup.string().required('Required'),
    user_id: Yup.string().required('Required'),
  });

  const getEditInputs = (specialUser) => (
    <>
      <Grid item xs={12}>
        <TextFieldFormik
          id="special_role"
          label="Role"
          className={classes.textField}
          fullWidth
          disabled={specialUser}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormik id="user_id" label="User" fullWidth className={classes.textField} select>
          {sortedUsers.map((user) => (
            <MenuItem key={user.id} value={user.id}>
              {user.username}
            </MenuItem>
          ))}
        </TextFieldFormik>
      </Grid>
    </>
  );

  return (
    <OrganizationEntityConfiguration
      columnData={columnData}
      generateFetchUrl={generateFetchUrl}
      generateDeleteUrl={generateDeleteUrl}
      editable={editable}
      entityLabelSingular={entityLabelSingular}
      entityLabelPlural={entityLabelPlural}
      getEditInputs={getEditInputs}
      getEntityInitialValues={getEntityInitialValues}
      getExtractedData={getExtractedData}
      getValidationSchema={getValidationSchema}
    />
  );
};

SpecialUsersContainer.propTypes = {
  generateFetchUrl: PropTypes.func.isRequired,
  generateDeleteUrl: PropTypes.func,
  entityLabelSingular: PropTypes.string.isRequired,
  entityLabelPlural: PropTypes.string.isRequired,
  editable: PropTypes.bool,
};

export default SpecialUsersContainer;
