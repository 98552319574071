import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { get, isEmpty } from 'lodash';

import { useStyles } from '~/assets/styles';
import { SortIcon } from '~/components/icons';
import InlineIconButton from '~/components/InlineIconButton';
import { stringCmp } from '~/Utils';
import cn from '~/Utils/cn';

const SortAzButton = ({ fieldId, sortKey, disabled }) => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const currentValue = get(values, fieldId);

  const onSortAz = (e) => {
    if (!Array.isArray(currentValue)) return;
    currentValue.sort((a, b) => !isEmpty(a?.[sortKey]) && stringCmp(a?.[sortKey], b?.[sortKey]));
    setFieldValue(fieldId, currentValue);
    e.stopPropagation();
  };

  return (
    <InlineIconButton
      icon={SortIcon}
      tooltipTitle="Sort A-Z"
      iconStyle={{ display: 'flex', width: 24, height: 24 }}
      className={cn(classes.textIcon, classes.hoverableIcon)}
      onClick={onSortAz}
      disabled={disabled}
    />
  );
};

SortAzButton.propTypes = {
  fieldId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  sortKey: PropTypes.string,
};

export default SortAzButton;
