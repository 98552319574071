import React from 'react';

import SvgIcon from '../core/SvgIcon';

const SortIcon = (props) => (
  <SvgIcon stroke="none" strokeWidth={0.2} {...props}>
    <path d="M13.9544 6.52174C14.0578 6.73579 13.9803 7.00334 13.7735 7.11037C13.515 7.24415 13.2824 7.08361 13.2048 6.92308L12.9205 6.28094H10.1031L9.87052 6.92308C9.76713 7.11037 9.50866 7.21739 9.30188 7.11037C9.0951 7.00334 9.01756 6.73579 9.12095 6.52174L11.1629 2.2408C11.318 1.94649 11.7574 1.94649 11.9125 2.2408L13.9544 6.52174ZM10.5167 5.42475H12.507L11.5248 3.3913L10.5167 5.42475ZM13.1531 13.1304C13.3858 13.1304 13.515 13.3445 13.515 13.5585C13.515 13.7993 13.3599 13.9866 13.1273 13.9866H9.84467C9.68959 13.9866 9.56035 13.9064 9.45696 13.7458C9.40527 13.612 9.40527 13.4247 9.50866 13.291L12.2743 9.70569H9.84467C9.61205 9.70569 9.43112 9.51839 9.43112 9.27759C9.43112 9.06355 9.61205 8.8495 9.84467 8.8495H13.1531C13.3341 8.8495 13.4633 8.95652 13.515 9.11706C13.5925 9.25084 13.5667 9.43813 13.4633 9.54515L10.6976 13.1304H13.1531ZM7.38917 10.5619C7.49256 10.5619 7.59595 10.6154 7.6735 10.6957C7.82858 10.8562 7.82858 11.1505 7.6735 11.311L5.19215 13.8796C5.03707 14.0401 4.75274 14.0401 4.59766 13.8796L2.11631 11.311C1.96123 11.1505 1.96123 10.8562 2.11631 10.6957C2.2714 10.5351 2.55572 10.5351 2.7108 10.6957L4.49427 12.5418V2.42809C4.49427 2.21405 4.6752 2 4.90783 2C5.11461 2 5.32139 2.21405 5.32139 2.42809V12.5418L7.07901 10.6957C7.15655 10.6154 7.25994 10.5619 7.38917 10.5619Z" />
  </SvgIcon>
);

export default SortIcon;
