import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';

import CancelButton from '~/components/core/Buttons/CancelButton';

import FileDropZone from '../../../../FileDropZone';

import styles from './voiceMessageConfiguration.module.scss';

const VoicePlayer = ({ audioSrc }) => {
  if (!audioSrc) return null;

  return <audio className={styles.audioPlayer} controls src={audioSrc} />;
};

VoicePlayer.propTypes = {
  audioSrc: PropTypes.string.isRequired,
};

function VoiceFileUploaderFormik({ voiceFileInputId, existingVoiceFileUrl, onReplaceExistingRecording }) {
  const [audioSrc, setAudioSrc] = useState('');

  const { errors, touched, setFieldValue } = useFormikContext();
  const [voiceFile, setVoiceFile] = useState(undefined);
  const [replaceExistingVoiceFile, setReplaceExistingVoiceFile] = React.useState(false);

  const handleFileSelect = (file) => {
    setVoiceFile(file);
    setFieldValue(voiceFileInputId, file);

    const reader = new FileReader();

    reader.onload = (event) => {
      setAudioSrc(event.target.result);
    };

    reader.readAsDataURL(file);
  };

  const handleCancelFileSelect = () => {
    setVoiceFile(undefined);
  };

  if (existingVoiceFileUrl && !replaceExistingVoiceFile) {
    return (
      <div className={styles.existingVoiceRecordingContainer}>
        <VoicePlayer audioSrc={existingVoiceFileUrl} />
        <CancelButton
          onClick={() => {
            setReplaceExistingVoiceFile(true);
            onReplaceExistingRecording();
          }}
          content="Replace Existing Recording"
        />
      </div>
    );
  }

  return (
    <FileDropZone
      uploadPercent={0}
      onFileSelect={handleFileSelect}
      onCancelFileSelect={handleCancelFileSelect}
      file={voiceFile}
      fileDisplayComponent={<VoicePlayer audioSrc={audioSrc} />}
      error={getIn(errors, voiceFileInputId) && getIn(touched, voiceFileInputId)}
      errorMessage={getIn(errors, voiceFileInputId)}
      dropZoneOptions={{ accept: '.mp3,.wave' }}
      baseStyleOverride={{
        width: '100%',
        height: '100%',
        paddingTop: '25px',
        paddingBottom: '25px',
      }}
      gridContainerAlignItems="stretch"
    />
  );
}

VoiceFileUploaderFormik.propTypes = {
  onCancel: PropTypes.func.isRequired,
  voiceFileInputId: PropTypes.string.isRequired,
  existingVoiceFileUrl: PropTypes.string,
  onReplaceExistingRecording: PropTypes.func.isRequired,
};

export default VoiceFileUploaderFormik;
