import React from 'react';

import SvgIcon from '../core/SvgIcon';

const OrganizationIcon = (props) => (
  <SvgIcon width="32" height="32" viewBox="0 0 32 28" fill="none" stroke="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.76256 0.762563C3.09075 0.434375 3.53587 0.25 4 0.25H16C16.4641 0.25 16.9092 0.434375 17.2374 0.762563C17.5656 1.09075 17.75 1.53587 17.75 2V8.25H26C26.4641 8.25 26.9092 8.43437 27.2374 8.76256C27.5656 9.09075 27.75 9.53587 27.75 10V23.25H29C29.4142 23.25 29.75 23.5858 29.75 24C29.75 24.4142 29.4142 24.75 29 24.75H1C0.585786 24.75 0.25 24.4142 0.25 24C0.25 23.5858 0.585786 23.25 1 23.25H2.25V2C2.25 1.53587 2.43437 1.09075 2.76256 0.762563ZM3.75 23.25H16.25V2C16.25 1.93369 16.2237 1.87011 16.1768 1.82322C16.1299 1.77634 16.0663 1.75 16 1.75H4C3.9337 1.75 3.87011 1.77634 3.82322 1.82322C3.77634 1.87011 3.75 1.9337 3.75 2V23.25ZM17.75 9.75V23.25H26.25V10C26.25 9.93369 26.2237 9.87011 26.1768 9.82322C26.1299 9.77634 26.0663 9.75 26 9.75H17.75ZM6.25 6C6.25 5.58579 6.58579 5.25 7 5.25H11C11.4142 5.25 11.75 5.58579 11.75 6C11.75 6.41421 11.4142 6.75 11 6.75H7C6.58579 6.75 6.25 6.41421 6.25 6ZM8.25 14C8.25 13.5858 8.58579 13.25 9 13.25H13C13.4142 13.25 13.75 13.5858 13.75 14C13.75 14.4142 13.4142 14.75 13 14.75H9C8.58579 14.75 8.25 14.4142 8.25 14ZM20.25 14C20.25 13.5858 20.5858 13.25 21 13.25H23C23.4142 13.25 23.75 13.5858 23.75 14C23.75 14.4142 23.4142 14.75 23 14.75H21C20.5858 14.75 20.25 14.4142 20.25 14ZM6.25 19C6.25 18.5858 6.58579 18.25 7 18.25H11C11.4142 18.25 11.75 18.5858 11.75 19C11.75 19.4142 11.4142 19.75 11 19.75H7C6.58579 19.75 6.25 19.4142 6.25 19ZM20.25 19C20.25 18.5858 20.5858 18.25 21 18.25H23C23.4142 18.25 23.75 18.5858 23.75 19C23.75 19.4142 23.4142 19.75 23 19.75H21C20.5858 19.75 20.25 19.4142 20.25 19Z"
    />
  </SvgIcon>
);

export default OrganizationIcon;
