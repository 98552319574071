import React from 'react';
import PropTypes from 'prop-types';

import Grid from '~/components/core/Atomic/Grid/Grid';
import SimpleSearchField from '~/components/core/SimpleSearchField';

const SearchRules = ({ onSearchChanged }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <SimpleSearchField label="Search Rule" placeholder="Search" onChange={onSearchChanged} defaultValue="" />
      </Grid>
      <Grid item xs={8} />
    </Grid>
  );
};

SearchRules.propTypes = {
  onSearchChanged: PropTypes.func.isRequired,
};

export default SearchRules;
