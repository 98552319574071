import React from 'react';
import type { FormikValues } from 'formik';
import { useFormikContext } from 'formik';
import { get } from 'lodash';

import FieldConfigurationCard from '~/components/SystemConfiguration/FnolConfiguration/FnolConfigurationScreen/FieldConfigurationCard/FieldConfigurationCard';
import BaseConfigurationSection from '~/components/SystemConfiguration/FnolConfiguration/FnolConfigurationScreen/Sections/BaseConfigurationSection';

const ID_PREFIX = 'configuration.policy.covered_vehicle.vehicle';

const CoveredVehicleSection: React.FC = () => {
  const { values } = useFormikContext<FormikValues>();
  const coveredVehicleFields = get(values, ID_PREFIX, {});
  const coveredVehicleFieldsKeys = Object.keys(coveredVehicleFields);

  return (
    <BaseConfigurationSection title="Covered Vehicle" subtitle="Key: covered_vehicle" isSection noGridContainer>
      {coveredVehicleFieldsKeys.map((fieldId) => (
        <FieldConfigurationCard key={fieldId} fieldId={fieldId} idPrefix={ID_PREFIX} />
      ))}
    </BaseConfigurationSection>
  );
};

export default CoveredVehicleSection;
