import React, { useState } from 'react';
import { Container } from '@material-ui/core';
import axios from 'axios';

import Typography from '~/components/core/Atomic/Typography';
import ConfigurableOption from '~/components/core/Molecules/ConfigurableOption';
import { reportAxiosError } from '~/Utils';

import CardDialog from '../../../CardDialog';
import { useSysconfig } from '../../SystemConfigurationScreen';

import {
  AutomaticCallRecordingConfigView,
  EditAutomaticCallRecordingConfigDialog,
} from './PhoneConfigurations/AutomaticCallRecordingConfig';
import {
  EditOutboundGreetingConfigDialog,
  OutboundGreetingConfigView,
} from './PhoneConfigurations/OutboundGreetingConfig';
import { EditVoicemailConfigDialog, VoicemailConfigView } from './PhoneConfigurations/VoicemailConfig';
import {
  EditWorkingHoursDialog,
  WorkingHoursConfigView,
} from './PhoneConfigurations/WorkingHoursConfig/WorkingHoursConfig';

const FIELD_IDS = {
  AUTO_RECORD_CALLS: 'auto_record_calls',
  AUTO_RECORD_CALLS_INITIATED: 'auto_record_calls_initiated',
  IS_AUTO_RECORD_FOR_ALL_ROLES: 'is_auto_record_for_all_roles',
  AUTO_RECORD_CALLS_ROLES: 'auto_record_calls_roles',
  CALLER_ID_ENABLED: 'caller_id_enabled',

  OUTBOUND_CALL_MESSAGE_ENABLED: 'outbound_call_message_enabled',
  OUTBOUND_CALL_MESSAGE_CONFIGURATION: 'outbound_call_message_configuration',
  OUTBOUND_CALL_MESSAGE_INITIATED: 'outbound_call_message_initiated',
  VOICEMAIL_MESSAGE_ENABLED: 'voicemail_message_enabled',
  VOICEMAIL_MESSAGE_CONFIGURATION: 'voicemail_message_configuration',
  VOICEMAIL_MESSAGE_INITIATED: 'voicemail_message_initiated',
  OUT_OF_OFFICE_MESSAGE_CONFIGURATION: 'out_of_office_message_configuration',

  BUSINESS_HOURS_CONFIGURATION_INITIATED: 'business_hours_configuration_initiated',
  BUSINESS_HOURS_CONFIGURATION_ENABLED: 'business_hours_configuration_enabled',
  BUSINESS_HOURS_CONFIGURATION: 'business_hours_configuration',
};

const PhoneConfigurationTab = () => {
  const { organization, organizationOperationalDetails, reloadOperationalDetails, roles } = useSysconfig();
  const { twilioConfiguration } = organizationOperationalDetails;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleUpdateConfiguration = async (values, throwOnError = true) => {
    try {
      setIsSubmitting(true);
      await axios.patch(`/api/v1/twilio_configuration/organizations/${organization.id}`, values);
      await reloadOperationalDetails();
    } catch (error) {
      reportAxiosError(error);
      if (throwOnError) {
        throw error;
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUpdateAutomaticRecordingConfiguration = async ({
    is_auto_record_for_all_roles,
    auto_record_calls_roles,
  }) => {
    await handleUpdateConfiguration(
      {
        [FIELD_IDS.IS_AUTO_RECORD_FOR_ALL_ROLES]: is_auto_record_for_all_roles,
        [FIELD_IDS.AUTO_RECORD_CALLS_ROLES]: auto_record_calls_roles,
        [FIELD_IDS.AUTO_RECORD_CALLS]: true,
      },
      true
    );
  };

  const handleUpdateOutboundGreetingConfiguration = async (formData, throwOnError = true) => {
    try {
      await axios.put(
        `/api/v1/twilio_configuration/organizations/${organization.id}/outbound_phone_message_configuration`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      await reloadOperationalDetails();
    } catch (error) {
      reportAxiosError(error);
      if (throwOnError) {
        throw error;
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUpdateVoicemailConfiguration = async (formData, throwOnError = true) => {
    try {
      await axios.put(
        `/api/v1/twilio_configuration/organizations/${organization.id}/voicemail_configuration`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      await reloadOperationalDetails();
    } catch (error) {
      reportAxiosError(error);
      if (throwOnError) {
        throw error;
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUpdateWorkingHoursConfiguration = async (values, throwOnError = true) => {
    try {
      await axios.patch(`/api/v1/twilio_configuration/organizations/${organization.id}`, values);
      await reloadOperationalDetails();
    } catch (error) {
      reportAxiosError(error);
      if (throwOnError) {
        throw error;
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const configurationOptions = [
    {
      isEnabled: twilioConfiguration[FIELD_IDS.AUTO_RECORD_CALLS],
      onChangeToggle: async (newValue) =>
        await handleUpdateConfiguration({ [FIELD_IDS.AUTO_RECORD_CALLS]: newValue }, false),
      configurationView: <AutomaticCallRecordingConfigView twilioConfiguration={twilioConfiguration} roles={roles} />,
      configuration: twilioConfiguration[FIELD_IDS.AUTO_RECORD_CALLS_INITIATED]
        ? {
            [FIELD_IDS.IS_AUTO_RECORD_FOR_ALL_ROLES]: twilioConfiguration[FIELD_IDS.IS_AUTO_RECORD_FOR_ALL_ROLES],
            [FIELD_IDS.AUTO_RECORD_CALLS_ROLES]: twilioConfiguration[FIELD_IDS.AUTO_RECORD_CALLS_ROLES],
          }
        : {},
      EditConfigurationDialogComponent: EditAutomaticCallRecordingConfigDialog,
      onSubmitDialog: async (params) => {
        await handleUpdateAutomaticRecordingConfiguration(params);
      },
    },

    {
      isEnabled: twilioConfiguration[FIELD_IDS.CALLER_ID_ENABLED],
      onChangeToggle: async (newValue) =>
        await handleUpdateConfiguration({ [FIELD_IDS.CALLER_ID_ENABLED]: newValue }, false),
      configurationView: (
        <>
          <Typography variant="subtitle1">Enable caller id</Typography>
          <Typography variant="caption">
            Display the caller name on incoming calls, including claims related to the contact
          </Typography>
        </>
      ),
    },
    {
      isEnabled: twilioConfiguration[FIELD_IDS.OUTBOUND_CALL_MESSAGE_ENABLED],
      onChangeToggle: async (newValue) =>
        await handleUpdateConfiguration({ [FIELD_IDS.OUTBOUND_CALL_MESSAGE_ENABLED]: newValue }, false),
      configurationView: <OutboundGreetingConfigView twilioConfiguration={twilioConfiguration} />,
      configuration: twilioConfiguration[FIELD_IDS.OUTBOUND_CALL_MESSAGE_INITIATED]
        ? twilioConfiguration[FIELD_IDS.OUTBOUND_CALL_MESSAGE_CONFIGURATION]
        : {},
      EditConfigurationDialogComponent: EditOutboundGreetingConfigDialog,
      onSubmitDialog: async (params) => {
        await handleUpdateOutboundGreetingConfiguration(params);
      },
    },
    {
      isEnabled: twilioConfiguration[FIELD_IDS.VOICEMAIL_MESSAGE_ENABLED],
      onChangeToggle: async (newValue) =>
        await handleUpdateConfiguration({ [FIELD_IDS.VOICEMAIL_MESSAGE_ENABLED]: newValue }, false),
      configurationView: <VoicemailConfigView twilioConfiguration={twilioConfiguration} />,
      configuration: twilioConfiguration[FIELD_IDS.VOICEMAIL_MESSAGE_INITIATED]
        ? {
            voicemail: twilioConfiguration[FIELD_IDS.VOICEMAIL_MESSAGE_CONFIGURATION],
            outOfOffice: twilioConfiguration[FIELD_IDS.OUT_OF_OFFICE_MESSAGE_CONFIGURATION],
          }
        : {},
      EditConfigurationDialogComponent: EditVoicemailConfigDialog,
      onSubmitDialog: async (params) => {
        await handleUpdateVoicemailConfiguration(params);
      },
    },
    {
      isEnabled: twilioConfiguration[FIELD_IDS.BUSINESS_HOURS_CONFIGURATION_ENABLED],
      onChangeToggle: async (newValue) =>
        await handleUpdateConfiguration({ [FIELD_IDS.BUSINESS_HOURS_CONFIGURATION_ENABLED]: newValue }, false),
      configurationView: <WorkingHoursConfigView twilioConfiguration={twilioConfiguration} />,
      configuration: twilioConfiguration[FIELD_IDS.BUSINESS_HOURS_CONFIGURATION_INITIATED]
        ? twilioConfiguration[FIELD_IDS.BUSINESS_HOURS_CONFIGURATION]
        : {},
      EditConfigurationDialogComponent: EditWorkingHoursDialog,
      onSubmitDialog: async (params) => {
        await handleUpdateWorkingHoursConfiguration(params);
      },
    },
  ];

  return (
    <div className="bg-slate-100 pt-32">
      <Container maxWidth="md">
        <CardDialog width="md" noCardTitle>
          {configurationOptions.map(
            (
              {
                isEnabled,
                onChangeToggle,
                configurationView,
                configuration,
                EditConfigurationDialogComponent,
                onSubmitDialog,
              },
              idx
            ) => (
              <ConfigurableOption
                key={idx}
                isEnabled={isEnabled}
                onChangeToggle={onChangeToggle}
                configurationView={configurationView}
                configuration={configuration}
                EditConfigurationDialogComponent={EditConfigurationDialogComponent}
                showOnly={isSubmitting}
                onSubmitDialog={onSubmitDialog}
              />
            )
          )}
        </CardDialog>
      </Container>
    </div>
  );
};

export default PhoneConfigurationTab;
export { FIELD_IDS };
