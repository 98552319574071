import React, { useState } from 'react';
import { Switch } from '@material-ui/core';
import { isEmpty } from 'lodash';

import Button from '~/components/core/Atomic/Buttons/Button';
import Typography from '~/components/core/Atomic/Typography';
import EmptyState from '~/components/core/EmptyState';
import { AddIcon } from '~/components/deprecatedMuiIcons';
import cn from '~/Utils/cn';

import { reportAxiosError } from '../../../../Utils';
import CardDialog from '../../../CardDialog';
import WithConfirm from '../../../ConfirmModal';
import { LoadingSwitch } from '../../../core';
import { useCms } from '../../../hooks/useCms';
import useOrganization from '../../../OrganizationContext';
import useDataFetcher from '../../../useDataFetcher';
import OperationsBreadcrumbs from '../../OperationsBreadcrumbs';

import { getRoutes, handleCreateNewForm, handleSetActive, handleSetEnable } from './Form/utils';
import Cards from './Cards';
import PerformanceQAForm from './Form';

import { useStyles } from '../../../../assets/styles';
import styles from './performanceQA.module.scss';

const PerformanceQATab = () => {
  const { isPerformanceQaEnabled, reloadOperationalDetails } = useOrganization();
  const {
    user: { id: userId },
    userOrganization: { id: organizationId },
  } = useCms();
  const {
    isLoading,
    isError,
    data: performanceForms = [],
    reloadData: reloadPerformanceForms,
  } = useDataFetcher(getRoutes.performanceForm(organizationId, userId));

  const [openPerformanceQaForm, setOpenPerformanceQaForm] = useState(false);

  const classes = useStyles();
  const activeCards = performanceForms.filter(({ is_active }) => is_active);
  const inactiveCards = performanceForms.filter((card) => !activeCards?.includes(card));
  const openCreateNewForm = () => {
    setOpenPerformanceQaForm(true);
  };

  const handleIsActiveChanged = async ({ id }, isActive) => {
    try {
      await handleSetActive({ formId: id, isActive, userId, organizationId });
      await reloadPerformanceForms();
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const EnableSwitch = (
    <Switch
      checked={isPerformanceQaEnabled}
      onClick={async () => {
        try {
          await handleSetEnable({ organizationId, userId, enabled: !isPerformanceQaEnabled });
          await Promise.all([reloadPerformanceForms(), reloadOperationalDetails()]);
        } catch (error) {
          await reportAxiosError(error);
        }
      }}
      className={classes.formsSwitch}
    />
  );
  return (
    <LoadingSwitch isLoading={isLoading} isError={isError}>
      <CardDialog noCardTitle>
        <OperationsBreadcrumbs currentTab="Performance QA Builder" />
        <Typography variant="subtitle1" className={styles.tabTitle}>
          PERFORMANCE QA BUILDER
        </Typography>
        <div className={styles.tabSubtitle}>Set up a QA questionnaire form</div>
        <div className={cn(classes.spaceBetweenButtonsContainer, styles.activeCards)}>
          <div className={styles.actions}>
            {isPerformanceQaEnabled ? (
              <WithConfirm
                title={TEXTS.disableToggle.title}
                contentText={TEXTS.disableToggle.content}
                primaryButtonName="Ok"
                shouldCloseOnPrimary
              >
                {EnableSwitch}
              </WithConfirm>
            ) : (
              <>{EnableSwitch}</>
            )}
            <span>Enable the performance QA feature</span>
          </div>
          {!isEmpty(activeCards) && (
            <Button color="primary" onClick={openCreateNewForm}>
              <AddIcon className={classes.leftButtonIcon} />
              CREATE NEW QA FORM
            </Button>
          )}
        </div>
        {isEmpty(activeCards) ? (
          <EmptyState
            buttonComponent={
              <Button variant="contained" color="primary" onClick={openCreateNewForm}>
                <span className={styles.newFormButton}>
                  <AddIcon className={classes.leftButtonIcon} />
                  CREATE NEW QA FORM
                </span>
              </Button>
            }
            subtitle={
              <span className={styles.emptyStateSubtitle}>
                No active QA form.
                <br />
                Choose from the inactive forms or create a new one.
              </span>
            }
          />
        ) : (
          <Cards items={activeCards} onActiveChange={handleIsActiveChanged} disabled={!isPerformanceQaEnabled} />
        )}
        {!isEmpty(inactiveCards) && (
          <div className={styles.inactiveCards}>
            <h4>Inactive QA Forms</h4>
            <Cards items={inactiveCards} onActiveChange={handleIsActiveChanged} disabled={!isPerformanceQaEnabled} />
          </div>
        )}
        <PerformanceQAForm
          formNames={performanceForms.map(({ name }) => name)}
          open={openPerformanceQaForm}
          onSubmit={({ values }) =>
            handleCreateNewForm({
              organizationId,
              userId,
              handleCreateNewForm,
              values,
              onSuccess: reloadPerformanceForms,
            })
          }
          onClose={() => setOpenPerformanceQaForm(false)}
        />
      </CardDialog>
    </LoadingSwitch>
  );
};

const TEXTS = {
  disableToggle: {
    title: 'Disable Performance QA',
    content:
      'Disabling the toggle will deactivate the active QA form and remove access to the QA Dashboard from the main sidebar.' +
      ' In case your organization already has QA forms filled in, users can still access the QA Dashboard and view data,' +
      ' but they cannot perform new QA queries to evaluate adjuster performance.' +
      ' Before disabling the toggle, check with your company director.',
  },
};

PerformanceQATab.propTypes = {};

export default PerformanceQATab;
