import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { get, isEmpty, isNil } from 'lodash';

import SubSectionWrapper from './SubSectionWrapper';

const ID_PREFIX = 'configuration.involved_parties';
const LOSS_OBJECT_ID = 'pecuniary_loss';
const LOSSES_KEY = 'losses';
const LOSS_OBJECT_FIELD_IDS = ['display_name', 'claimed_amount', 'claimed_loss', 'note'];

const PecuniaryLossSubSection = ({ partyKey }: { partyKey: string }): JSX.Element => {
  const { values, setFieldValue } = useFormikContext();

  const isAllowed = get(values, `${ID_PREFIX}.${partyKey}.is_allowed_pecuniary_loss`, false);
  const id = `${ID_PREFIX}.${partyKey}.${LOSS_OBJECT_ID}`;
  const title = get(values, `${id}.desc`) || 'Pecuniary Losses';
  const lossesTitle = get(values, `${id}.losses.desc`) || 'Losses';
  const isActive = get(values, `${id}.active`);

  useEffect(() => {
    if (isNil(isActive)) {
      setFieldValue(`${id}.active`, true);
    }
  }, [isActive, setFieldValue, id]);

  if (!isAllowed || isEmpty(get(values, id, {}))) {
    return <></>;
  }

  return (
    <SubSectionWrapper
      id={id}
      idPrefix={`${ID_PREFIX}.${partyKey}`}
      title={title}
      sectionKey={LOSS_OBJECT_ID}
      lossSubSectionQuestionId="is_pecuniary_loss"
      lossObjectFieldIds={[]}
      lossItemsFieldIds={LOSS_OBJECT_FIELD_IDS}
      lossItemsFieldIdPrefix={LOSSES_KEY}
      lossItemsSectionDesc={lossesTitle}
    />
  );
};

export default PecuniaryLossSubSection;
